import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "../OrderTaker/OrderTakerStylesheet.css";
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import GlobalFunction from "../../API/APIServices/GlobalFunction";
import html2pdf from 'html2pdf.js';
import Order from "../../API/APIServices/Order";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
//import { WebView } from 'react-native-webview';

function KotPrintPage(props) {
    // const location = useLocation();
    // const { ord2Id } = location.state || {};
    // console.log(ord2Id);

    const navigate = useNavigate();

    const backButton = () => {
        navigate(-1);
    };

    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const orderNo = searchParams.get('orderNo');
    // const d2ItemName = searchParams.get('d2ItemName');
    // const preferenceNo = searchParams.get('preferenceNo');
    // const qty = searchParams.get('qty');
    // const sku = searchParams.get('sku');

    let orderNo, itemName, preferenceNo, qty, sku, ord2Id;

    if (location.state && location.state.orderNo) {
        ({ orderNo, itemName, preferenceNo, qty, sku, ord2Id } = location.state);
    } else {
        ({ orderNo, itemName, preferenceNo, qty, sku, ord2Id } = props);
    }

    console.log("order", orderNo)
    console.log("item", itemName)
    console.log("preference", preferenceNo)
    console.log("qty", qty)
    console.log("sku", sku)
    console.log("ord2Id", ord2Id)

    const [orderData, setOrderData] = useState([]);
    const [serviceTypeId, setServiceTypeId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const filteredData = {
                    fieldName: "ord2Id",
                    operatorName: "Equal",
                    compareValue: ord2Id
                }
                // const data = await Order.getOrderListWithFilter(filteredData);
                const data = await Order.getRawOrderList(filteredData, 0);
                setOrderData(data);
                //alert(data.length)
                if (data.length > 0) {
                    setServiceTypeId(data[0].serviceTypeId)
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching banner images:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [])

    const [loading, setLoading] = useState(true);

    const [companyData, setCompanyData] = useState({});

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId);
                setCompanyData(data || {}); // If data is undefined, set an empty object
                console.log("dataaaa", data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const isoString = await CurrentDateAndTime.getCurrentDateTime();
            const currentDate = new Date(isoString);

            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const date = currentDate.getDate();
            let hours = currentDate.getHours();
            const minutes = currentDate.getMinutes();
            const seconds = currentDate.getSeconds();

            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            hours = hours % 12 || 12;

            const formattedHours = hours < 10 ? '0' + hours : hours;
            const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
            const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

            const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amOrPm}`;

            const formattedDate = `${date}-${month}-${year}`;

            setCurrentDate(formattedDate);
            setCurrentTime(formattedTime);
        }
        fetchData();
    }, [])

    const webViewRef = useRef(null);

    const printDocument = () => {
        //GlobalFunction.generatePDF("displayBlock", "pdf-content");
        // Execute JavaScript code in the WebView to trigger printing
        //webViewRef.current.injectJavaScript('window.print();');
    };

    const generatePDF = async () => {
        document.getElementById("displayBlock").style.display = "block";
        const element = document.getElementById("pdf_content");

        const opt = {
            margin: 0,
            filename: 'myfile.pdf',
            image: { type: 'png' },
            html2canvas: { scale: 4 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
        };

        html2pdf()
            .from(element)
            .set(opt)
            .toPdf()
            .output('datauristring')
            .then(pdfDataUri => {
                const pdfBase64 = pdfDataUri.split(',')[1];

                // Check if running in a WebView
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(pdfBase64);
                } else {
                    // Create a blob from the base64 string
                    const byteCharacters = atob(pdfBase64);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'application/pdf' });

                    // Create a link element and click it to download the PDF
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'myfile.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Print the PDF directly from the web
                    window.open(URL.createObjectURL(blob));
                    // alert("Print");
                }
            })
            .catch(error => {
                alert('Error generating Print:', error);
            });
    };

    function convertTimeTo12HourFormat(timeString) {
        // Split the time string into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeString.split(':').map(num => parseInt(num, 10));

        // Determine if it's AM or PM
        const period = hours >= 12 ? 'pm' : 'am';

        // Convert hours to 12-hour format
        const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

        // Format the time in hh:mm am/pm format
        const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

        return formattedTime;
    }

    function formatDate(inputDate) {
        // Split the inputDate string into year, month, and day components
        const parts = inputDate.split('-');

        // Create a Date object with the components in yyyy-mm-dd format
        const dateObject = new Date(parts[0], parts[1] - 1, parts[2]);

        // Extract day, month, and year from the Date object
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = dateObject.getFullYear();

        // Return the formatted date string in dd-mm-yyyy format
        return `${day}-${month}-${year}`;
    }

    const renderTimeInformation = () => {
        // if (serviceTypeId === 752) {
        return (
            <div>
                <div className="space-between">
                    <div className="flex-start MHut-width-KOT">
                        <label className="ques-text-col">Time Slot :&nbsp;</label>
                        <label className="ans printLabel">{orderData && orderData != null && orderData != undefined && orderData.length > 0 && formatDate(orderData[0].deliveryDate)} , {orderData && orderData != null && orderData != undefined && orderData.length > 0 && orderData.timeSlotName}</label>{/*timeFormat(orderData[0].deliveryTime)}*/}
                    </div>
                </div>
                <div className="space-between">
                    <div className="flex-start MHut-width-KOT">
                        <label className="ques-text-col">KDTT Time :</label>
                        <label className="ans printLabel">{orderData && orderData != null && orderData != undefined && orderData.length > 0 && convertTimeTo12HourFormat(orderData[0].kotDispatchTargetTime)}</label>
                    </div>
                </div>
            </div>
        );
        // }
        return null; // Render nothing if serviceTypeId is not 752
    };


    return (
        <div>
            {/* <div className="heading">
                <div className="head-display">  
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title">Feedback</h2>
                    </div>
                </div>
            </div> */}
            {/* <WebView
                ref={webViewRef}
                source={{ uri: 'https://example.com/document-to-print' }} // Replace with your document URL
                style={{ flex: 1 }}
            /> */}
            <div style={{ padding: "0px", backgroundColor: "rgb(33, 46, 79)" }}>  {/*backgroundColor: "rgb(33, 46, 79)" */}
                <div className="flex-center-MHut" style={{ justifyContent: "space-between" }}>
                    <div>
                        <i class="fa-solid fa-arrow-left" style={{ display: props.backBtn == "none" ? "none" : "block", marginLeft: "5px", color: "white", }} onClick={backButton}></i>
                    </div>
                    <label className="MHut-text"><u className="MHut-underline" style={{ fontSize: "18px" }}>{companyData.printName ? companyData.printName : ''}</u></label>
                    <div>
                        <i class="fa-solid fa-print" onClick={generatePDF} style={{ display: props.backBtn == "none" ? "none" : "block", color: "white", marginRight: "5px" }}></i>
                    </div>
                </div>
                <div id="pdf_content">
                    <div style={{ display: "none" }} id="displayBlock">
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px", borderBottom: "1px solid black", backgroundColor: "White" }}>
                            <label className="MHut-text" style={{ color: "black", textAlign: "center", fontSize: "20px" }}>
                                <label className="MHut-underline" style={{ fontFamily: "calibri" }}>{companyData.printName}</label>
                            </label>
                        </div>
                    </div>
                    <div className="MHut-color-KOT">
                        <div className="space-between">
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">Order&nbsp;No&nbsp;:&nbsp;</label>
                                <label className="ans printLabel">{orderNo}</label>
                            </div>
                        </div>
                        <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                            <label className="ques-text-col">Item&nbsp;Name:&nbsp;</label>
                            <label className="ans printLabel">{itemName}</label>
                        </div>
                        {/***************/}
                        <div className="space-between">
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">Item&nbsp;Category:&nbsp;</label>
                                <label className="ans printLabel">Testing</label>
                            </div>
                            {/* <div className="flex-start MHut-width-KOT">
                            <label className="ques-text-col">Atr :&nbsp;</label>
                            <label className="ans"></label>
                        </div> */}
                        </div>
                        <div className="space-between">
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col printLabel-dark">SKU&nbsp;:&nbsp;</label>
                                <label className="ans printLabel">{sku}</label>
                            </div>
                        </div>
                        <div className="space-between">
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">Serve&nbsp;Pref.&nbsp;:&nbsp;</label>
                                <label className="ans printLabel">{preferenceNo}</label>
                            </div>
                        </div>
                        <div className="flex-start MHut-width-KOT">
                            <label className="ques-text-col">Qty&nbsp;:&nbsp;</label>
                            <label className="ans printLabel">{qty}</label>
                        </div>
                        <div className="flex-start MHut-width-KOT">
                            <label className="ques-text-col">Item Suggestion :&nbsp;</label>
                            <label className="ans printLabel">{orderData && orderData != null && orderData != undefined && orderData.length > 0 && orderData[0].itemSuggestion}</label>
                        </div>
                        <div className="flex-start MHut-width-KOT">
                            <label className="ques-text-col">KOT Manager Name :&nbsp;</label>
                            <label className="ans printLabel">{orderData && orderData != null && orderData != undefined && orderData.length > 0 && orderData[0].kitchenManagerName}</label>
                        </div>
                        <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                            <label className="ques-text-col">Print&nbsp;Date&nbsp;:&nbsp;</label>
                            <label className="ans printLabel">{currentDate}</label>
                        </div>
                        <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                            <label className="ques-text-col">&&nbsp;Time&nbsp;: &nbsp;</label>
                            <label className="ans printLabel">{currentTime}</label>
                        </div>
                        {renderTimeInformation()}
                        {/* <div className="flex-start MHut-width-KOT">
                        <label className="ques-text-col">Pickup Time : &nbsp;</label>
                        <label className="ans">{currentDate}</label>
                    </div> */}
                        {/*************************/}
                    </div>
                </div>
                {/**************************************************************************/}
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default KotPrintPage;
