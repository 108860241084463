import React from "react";
const AddComponentChild = (props) => {
  return (
    <div className="basics-component" onClick={props.handleClick}  onDrop={props.handleDrop} onDragOver={props.dragover} draggable="true" onDragStart={props.handleDrag} >
      <div className="addcomponent-icon-box">
        <i className={props.icon}></i>
        <div></div>
      </div>
      <div className="add-component-text-box">
        <p className="add-component-txt">{props.heading}</p>
      </div>
    </div>
  );
};
export default AddComponentChild;
