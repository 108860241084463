import React from 'react';
import './TextArea.css';

const FloatingLabelTextArea = ({ label, onChange, value }) => {


    const handleInputChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="floating-label-textarea">
            <textarea
                id="textarea"
                rows="1"
                value={value}
                onChange={handleInputChange}
                required
            ></textarea>
            <label className={`floating-label ${value ? 'floating' : ''}`} htmlFor="textarea">
                {label}
            </label>
        </div>
    );
};

export default FloatingLabelTextArea;
