import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css'
import ImageUploadWithPreview from "../../Components/ImagePreview";

function ItemGroupMaster1() {
    /***********title***************/
    useState(() => {
        document.title = 'Item Group Master';
    }, []);

    //**********show hide*****************//

    const [isInputVisiblam3, setInputVisiblam3] = useState(false);
    const handleToggleChangam3 = () => {
        setInputVisiblam3(!isInputVisiblam3);
    };

    //**********show hide Y/N*****************//
    const [isModalOpenEcom, setModalOpenEcom] = useState(false);
    // const [inputValue, setInputValue] = useState('');

    const [selectedOption5, sethandleInputChangeEcom] = useState(false);
    // const [, sethandleInputChangeEcom] = useState(false)
    const handleInputChangeEcom = (event) => {
        const selectedValue = event.target.value;
        sethandleInputChangeEcom(selectedValue);
        if (selectedValue === 'Yes') {
            setModalOpenEcom(true);
        }
        else if (selectedValue === 'No') {
            setModalOpenEcom(false);
        }
    }


    //*******************{modal box}******************/ /


    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    };

    //****************{modal box}***************/ /

    /****************{Category box}****************/
    const [rows, setRows] = useState(Array(10).fill({}));

    const handleAddRow = () => {
        setRows([...rows, {}]);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((row, i) => i !== index);
        setRows(updatedRows);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        console.log(value)
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    //*****************{Save Button}************************//

    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleModalClick = (e) => {
        // Prevent the event from propagating to parent elements
        e.stopPropagation();
      };
      const [isHovered3, setHovered3] = useState(false);
      const handleMouseEnter3 = () => {
          setHovered3(true);
      };
      const handleMouseLeave3 = () => {
          setHovered3(false);
      };
      const handleBack = () => {
        // Add your logic to go back or navigate to the previous page
        // You might want to use react-router's history or window.history.back()
        window.history.back();
    };


    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Item Group Master</h2>
                </div >
                {/* *******************Save Button****************** */}

                {/* *******************Top Buttons****************** */}
                <div className="panel-head-button">
                    <div className="col_1">
                        <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Update
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="button-m5">
                            <button className="save-button-main">Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>
                        <div onClick={showModal} className="button-m5">
                            <button className="save-button-main">Setting
                                <i id="step-2" className="fa-solid fa-gear im-icon-2" onClick={showModal}></i>
                            </button>
                        </div>
                        <div onClick={handleBack} className="button-m5">
                            <button className={`save-button-main ${isHovered3 ? 'hovered' : ''}`}
                                onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                                    <i id="step-2" className="fa-solid fa-arrow-left-long im-IconRight" onClick={handleBack}></i>
                                    Back
                                {isHovered3 && (
                                    <div className="hover-textSave">
                                     
                                        <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                        Back/ESC
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                {/* *******************Modal Box Open****************** */}
                <div>
                    {isModalOpen && (
                        <div onClick={hideModal} className="modal" >
                            <div onClick={handleModalClick} className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Item Group Setting</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam3} checked={isInputVisiblam3} />
                                                    <span></span>
                                                </label>
                                                <div className="ModalLabeltext2">Tax Category Date Wise Details<i className="fa-solid fa-circle-info ml-10"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* *******************Modal Box End****************** */}
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im PageActiveFlex">Group Details
                                        <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox"  />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1 input-height2">
                                                    <select className="modify_im_input mode_category"
                                                        value={selectedOption5} onChange={handleInputChangeEcom}
                                                        type="text" required>
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Use in e-commerce(Y/N)</label>
                                                </div>

                                                <div className="input-box-m1 ">
                                                    <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text2"> Print Name&nbsp;<span className="StarClr">*</span></label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Group Name</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text4"> Group Barcode </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1 input-height2">
                                                    <select className="modify_im_input mode_category"
                                                        type="text" required>
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Primary Group</label>
                                                </div>
                                                <div className="input-box-m1 ">
                                                    <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text2">Categ.1 limit upto(?)</label>
                                                </div>
                                            </div>
                                           
                                            <div className="input-box-Im input-height3">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Sale Discount</label>

                                                </div>
                                                <div className="input-box-m1 ">
                                                    <div className="input-box2 check_box_b1">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="modify_p_text_checkbox"> Apply for all products</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height3">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Puchase Discount</label>
                                                </div>
                                                <div className="input-box-m1 ">
                                                    <div className="input-box2 check_box_b1">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="modify_p_text_checkbox"> Apply for all products</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height3">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">HSN Code</label>
                                                </div>
                                                <div className="input-box-m1 ">
                                                    <div className="input-box2 check_box_b1">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="modify_p_text_checkbox"> Apply for all products</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height3">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Loyalty Point</label>

                                                </div>
                                                <div className="input-box-m1 ">
                                                    <div className="input-box2 check_box_b1">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="modify_p_text_checkbox"> Apply for all products</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height3">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                        <option value="select">--Select--</option>
                                                        <option value="saab">GST@ 5%</option>
                                                        <option value="saab">GST@ 12%</option>
                                                        <option value="saab">GST@ 18%</option>
                                                        <option value="saab">GST@ 28%</option>
                                                        <option value="saab">ODT</option>
                                                        <option value="saab">TAX @ 5</option>
                                                        <option value="saab">TAX @ 12</option>
                                                        <option value="saab">TAX @ 18</option>
                                                        <option value="saab">TAX @ 28</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Tax Category 1</label>
                                                </div>
                                                <div className="input-box-m1 ">
                                                    <div className="input-box2 check_box_b1">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="modify_p_text_checkbox"> Apply for all products</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="date" required />
                                                    <label className="modify_lbl_text3">Applied Tax Date</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                        <option value="select">--Select--</option>
                                                        <option value="saab">GST@ 5%</option>
                                                        <option value="saab">GST@ 12%</option>
                                                        <option value="saab">GST@ 18%</option>
                                                        <option value="saab">GST@ 28%</option>
                                                        <option value="saab">ODT</option>
                                                        <option value="saab">TAX @ 5</option>
                                                        <option value="saab">TAX @ 12</option>
                                                        <option value="saab">TAX @ 18</option>
                                                        <option value="saab">TAX @ 28</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">Tax Category 2</label>
                                                </div>
                                            </div>
                                            {isInputVisiblam3 && (
                                                <div>
                                                    <div className="company_info-text-im">Tax Category Date Wise Details</div>
                                                    <div className="tableOverflowItemGroup" >
                                                        <table className="table-t" >
                                                            <thead style={{ background: "#314989" }} className="thead-groupmaster">
                                                                <tr >
                                                                    <th style={{ minWidth: "45px" }} className="table-thIG1">Sn No</th>
                                                                    <th className="table-thIG1">Change From</th>
                                                                    <th className="table-thIG1">Tax Catg 1</th>
                                                                    <th className="table-thIG1">Range</th>
                                                                    <th className="table-thIG1">Tax Catg 2</th>
                                                                    <th style={{ minWidth: "40px" }} className="table-thIG1">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows.map((row, rowIndex) => (
                                                                    <tr key={rowIndex} >
                                                                        <td style={{ textAlign: "center" }} >
                                                                            {rowIndex + 1}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="table-input-12" type="text"
                                                                                name={`changeFrom-${rowIndex}`}
                                                                                value={row[`changeFrom-${rowIndex}`] || ""}
                                                                                onChange={(e) => handleInputChange(rowIndex, e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="table-input-12" type="text"
                                                                                name={`taxCatg1-${rowIndex}`}
                                                                                value={row[`taxCatg1-${rowIndex}`] || ""}
                                                                                onChange={(e) => handleInputChange(rowIndex, e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="table-input-12" type="text"
                                                                                name={`range-${rowIndex}`}
                                                                                value={row[`range-${rowIndex}`] || ""}
                                                                                onChange={(e) => handleInputChange(rowIndex, e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="table-input-12" type="text"
                                                                                name={`taxCatg2-${rowIndex}`}
                                                                                value={row[`taxCatg2-${rowIndex}`] || ""}
                                                                                onChange={(e) => handleInputChange(rowIndex, e)}
                                                                            />
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <button className="btn-table1" onClick={() => handleDeleteRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="btnTableIconIG" onClick={handleAddRow}><i className="fa-solid fa-square-plus"></i></div>
                                                        <div className="input-box2 check_box_b1">
                                                            <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                            <label className="modify_p_text_checkbox"> Alert Negative Qty</label>
                                                        </div>
                                                        <div className="input-box2 check_box_b1">
                                                            <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                            <label className="modify_p_text_checkbox"> Restrict Negative Qty</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        {isModalOpenEcom && (
                                            <div>
                                                <div className="company_info-text-im">Ecommerce Details</div>
                                                <div className="">
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category"
                                                                type="text" required />
                                                            <label className="modify_p_text2">Menu Name</label>
                                                        </div>

                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text4">Show In Menu </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="number" required />
                                                            <label className="modify_p_text2">Display Order</label>
                                                        </div>

                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text4">Show In Home Page  </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_category"
                                                                type="text" required>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                        <label className="modify_lbl_text3">Show In Collection (Y/N)</label>
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                                <option value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text4">Show In Ad. banner </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Name In Home Page/Collection</label>
                                                        </div>
                                                        <div className="input-box-m1 ">
                                                            <input className="modify_im_input mode_limit mode_category" type="number" required />
                                                            <label className="modify_lbl_text2">Priority To Show In Home Page</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Image show on error (?)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="company_info-text-im">Meta Details</div>
                                                <div className="">
                                                    <div className="input-box-tab-head">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                            <label className="modify_p_text-fix">title should less then 100 characters</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-tab-head">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                            <label className="modify_p_text-fix">20 different search keywords(Less then 1000 characters)</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-tab-head">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                            <label className="modify_p_text-fix">description should less then 200 characters</label>
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Search Engine Friendly PageName</label>
                                                        </div>
                                                        <div className="input-box-m1 ">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Menu Url Link</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im1">
                                                        <p className="modify_p_2" style={{ marginBottom: "17px" }}>Product Images</p>
                                                        <div className="radio-buttons-container1 m2 overstack">
                                                            < ImageUploadWithPreview id={"item9"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default ItemGroupMaster1;
