import ListSaveButton from "../Components/ListSaveButton";
import PageTabs from "../Components/PageTabs";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import XLS from "./Userdetails/XLS";

function ProductExchangeList() {
    useState(() => {
        document.title = 'Product Exchange List';
    }, []);

    //**********xls modal box*****************//
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };


    //**********xls modal box*****************//
    return (
        <div className="">
            < PageTabs />
            <div className="nav-1">
                <div className="panel-heading">
                    <h2 className="panel-title">Product Exchange List</h2>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1 mt-1">
                            <div className="search_panel_left_Acc_mstrlist">
                                <div className="col-sm-AML">
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Date From</label>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%"}}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Date To</label>
                                    </div>
                                </div>
                            </div>
                            <div className="search_panel_right_Acc_mstrlist">
                                <div className="search_panel_f1" >
                                    <div className="button-mp1">
                                        <button className="table_search_btn">
                                            <i className="fa-solid fa-magnifying-glass im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp2" >
                                        <button className="table_reset_btn">
                                            <i className="fa-solid fa-rotate im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp3">
                                        <Link to="/ProductExchange" id="step-5" className="add_btn">
                                            <i className="fa-solid fa-square-plus im-icon-6"></i>
                                        </Link>
                                    </div>
                                    <div className="button-mp4" onClick={showModal} >
                                        <button className="xls_btn" onClick={showModal} >
                                            <i className="fa-solid fa-file-excel im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp5">
                                        <button className="Print_btn">
                                            <i className="fa-solid fa-print im-icon-6"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {isModalOpen && (
                                <div className="modal" >
                                    <div className="modal-content_xls">
                                        <div className="modal-title">
                                            <span className="close" onClick={hideModal}>
                                                &times;
                                            </span>
                                        </div>
                                        <div className="tab-content-1">
                                            <div className="company-info-top-right control_flex_m1 item_scroll">
                                                <XLS />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Series</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill No</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Date</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Party Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Type</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Exchange in Qty.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Exchange in Amt.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Exchange Out Amt.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Status</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Print</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">2</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">3</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">4</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">5</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">6</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">7</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>EXCHANGE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>15/12/2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Shehzaan Khan</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Lens</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>300.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Exchange Out</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-print"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1">
                                        <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                                    </p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2">
                                        <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                                    </p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1">
                                        {" "}
                                        <i className="fa-solid fa-clipboard icon_3"></i> Total
                                    </p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2">
                                        <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                                    </p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1">
                                        <i className="fa-solid fa-arrow-down icon_3"></i>Return
                                    </p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2">
                                        <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13">Recent History</p>
                                <div className="con_input_1">
                                    <input
                                        type="text"
                                        className="input_box_2"
                                        placeholder="Search..."
                                    />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4">
                                                <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                                                1000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p">
                                                <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                                                1000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductExchangeList;
