import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import DataTable from "../TableMaster/DataTable";
import TableButton from "./../../Props/TableButton";
import StatusMaster from "./StatusMaster";
import Status from "../../../API/APIServices/Status";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function StatusMasterList() {
  const { OpenAlertBoxDelete, alertBoxDelete, setAlertBoxDelete, input1, input2,
    input3,
    input4, } =
    useContext(MyContext);

  const navigate = useNavigate();
  const [mainData, setMainData] = useState([]);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSlider = (value) => {
    //   console.log(editData);
    setSliderOpen(value);
  };


  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await Status.GetStatus();
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          name: item.name,
          sendSMS: String(item.sendSMS),
          email: String(item.email),
          whatsappMessage: String(item.whatsappMessage),
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteAccount = (id) => {
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true)
    try {
      const val = await Status.deleteStatus(getdeleteId);
      if (Boolean(val) === true) {
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false)
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState("");

  const Edit = (id) => {
    const data = Status.GetStatusById(id);
    // console.log(data);
    setEditData(data);
    toggleSlider(true);
  };

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "NAME", accessor: "name" },
    { Header: "SMS", accessor: "sendSMS" },
    { Header: "EMAIL", accessor: "email" },
    { Header: "WhatsApp", accessor: "whatsappMessage" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "name" },
    {
      Header: <input type="text" placeholder="SMS" ref={input2} />,
      accessor: "sendSMS",
    },
    {
      Header: <input type="text" placeholder="EMAIL" ref={input3} />,
      accessor: "email",
    },
    {
      Header: <input type="text" placeholder="WhatsApp" ref={input4} />,
      accessor: "whatsappMessage",
    },
  ];

  return (
    <div className="">
      <PageTabs
        locationTab={"/StatusMaster"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Status Master List</h2>
          <TableButton
            filterData={filterData}
            columns={columns}
            PageCreate={"/StatusMaster"}
            data={mainData}
            PageName="Masters"
          />
        </div>
        <DataTable columns={columns} data={mainData} filterData={filterData} />
      </div>
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <StatusMaster
            data={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
          // toggleSlide={toggleSlider}
          />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </div>

  );
}

export default StatusMasterList;
