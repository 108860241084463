import React , {useState , useEffect} from 'react'


  const Typed = (props) => {



    let getId = props.keyValueId;  


  const [getValueAndStrg,setGetValueAndStrg] = useState([])
    
    
  // useEffect(() => {
  //   // Load saved items from localStorage on initial mount
  //   const savedItems = localStorage.getItem("style");
  //   if (savedItems) {
  //     setGetValueAndStrg(savedItems) ;
  //   }
  // }, []);
   
  // useEffect(() => {
  //   // Store only unique id values in localStorage
  //   // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
  //   // localStorage.setItem("style", getValueAndStrg);
  // }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };

  return (
    <div  style={{position:"relative" }} onDragStart={props.handleDrag}
    onDrop={props.handleDrop}
    onDragOver={props.dragover}
    draggable="true"  id={getId} onClick={deletebuttonshow}>typed</div>
  )
}

export default Typed