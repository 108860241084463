
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton"


function UnitConversionMasterList() {

    return (
        <div className="" >
            < PageTabs locationTab={"/UnitConversionMaster"} />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Unit Conversion List</h2>
                    <TableButton PageCreate={"/UnitConversionMaster"}/>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left">
                                <div className="col-sm-n1">
                                    <div className="group">
                                        <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                        <input placeholder="Search" type="search" className="search_panel_input" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Main Unit</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Sub Unit</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Con. Factor</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">2</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Akash</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">3</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Royal</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">4</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raju Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">5</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Ashish Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">6</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Kamal Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">7</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Sonu Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>123456789</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>State Bank of India</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default UnitConversionMasterList;
