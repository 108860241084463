import React, { useState, useEffect, useRef, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Inventory/Props/MainButton";
import AlertBox from "../../Inventory/Props/AlertBox";
import MyContext from "../../Inventory/MyContext";
import Loader from "../../Inventory/Props/Loader";
import LeaveMasterApi from "../../API/APIServices/LeaveMaster";
import PageTabs from "../../Inventory/Components/PageTabs";
import DateTime from '../../Inventory/Components/DateTime';

function LeaveMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    useState(() => {
        document.title = 'Leave Master';
    }, []);

    const [errorMessages, setErrorMessages] = useState({
        leaveName: '',
    });

    const [inputValues, setInputValues] = useState({
        leaveName: '',
        description: '',
    });

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['leaveName'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.isEditedData) === true) {
                const isSuccess = await LeaveMasterApi.updateLeaveMaster(inputValues);
                console.log(isSuccess);
                if (isSuccess) {
                    setLoading(false);
                    await OpenAlertBoxEdit();
                    props.fetchData();
                    props.close(false);
                } else {
                    alert("Error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = LeaveMasterApi.insertLeaveMaster(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                    props.fetchData();
                } else {
                    alert("Error Inserting data");
                }
            }
            setInputValues({
                leaveName: "",
                description: "",
            });
            props.fetchData();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props.editData) {
                try {
                    const data = await props.editData;
                    console.log(data);
                    setInputValues(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [props.editData]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.close();
            }
            if(event.key === 'F10'){
                SaveBtn();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div>
            {/* <PageTabs /> */}
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Leave Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} />
            </div>
            <div><DateTime /> </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">Leave Master</div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                        ref={input1Ref}
                                                        value={inputValues.leaveName}
                                                        name="leaveName"
                                                        type="text" required />
                                                    <label className="modify_p_text2">Leave Name <span className="StarClr">*
                                                        {errorMessages.leaveName && <label className="errorMessage">{errorMessages.leaveName}</label>}
                                                    </span></label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                        ref={input2Ref}
                                                        value={inputValues.description}
                                                        name="description"
                                                        type="text" required />
                                                    <label className="modify_p_text2">Description</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Leave Master is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Leave Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default LeaveMaster;
