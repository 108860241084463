import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import XLS from "../../Master/Userdetails/XLS";
import TableButton from "../../Props/TableButton";

function SaleVoucherList() {

    //**********xls modal box*****************//
    const [isModalOpen, setModalOpen] = useState(false);
    // const showModal = () => {
    //     setModalOpen(true);
    // };

    const hideModal = () => {
        setModalOpen(false);
    };


    //**********workshop modal box*****************//
    const [WorkshopModal, setWorkshopModal] = useState(false);

    const openWorkshopModal = () => {
        setWorkshopModal(true);
    };

    const closeWorkshopModal = () => {
        setWorkshopModal(false);
    };

    const [LabelField] = useState([
        {
            id: 1,
            name: 'Customer Card',
            type: "text",
            idName: "CustomerCard",
        },
        {
            id: 2,
            name: 'DIA',
            type: "text",
            idName: "Dia",
        },
        //{
        //    id: 3,
        //    name: 'PD R',
        //    type: "text",
        //    idName: "Pd-r",
        //},
        //{
        //    id: 4,
        //    name: 'PD L',
        //    type: "text",
        //    idName: "Pd-l",
        //},
        {
            id: 5,
            name: 'Fitting Height',
            type: "text",
            idName: "FittingHeight",
        },
        {
            id: 6,
            name: 'Fitting Type',
            type: "text",
            idName: "Fitting Type",
        },
        {
            id: 7,
            name: 'DIM R',
            type: "text",
            idName: "Dim-r",
        },
        {
            id: 8,
            name: 'DIM L',
            type: "text",
            idName: "Dim-l",
        },
        {
            id: 9,
            name: 'Base',
            type: "text",
            idName: "base",
        },
        {
            id: 10,
            name: 'Prism Remarks',
            type: "text",
            idName: "PrismRemarks",
        },
        {
            id: 11,
            name: 'TINT',
            type: "text",
            idName: "Tint",
        },
        {
            id: 12,
            name: 'Fitting',
            type: "text",
            idName: "Fitting",
        },
        {
            id: 13,
            name: 'Mirror',
            type: "text",
            idName: "Mirror",
        },
        {
            id: 14,
            name: 'Remarks',
            type: "text",
            idName: "Remarks",
        },
    ])

    const WithFrameChange = () => {
        const val = document.getElementById('withFrame').value;
        console.log(val);
        if (val === 'Y' || val === 'y') {
            document.getElementById('FrameName').style.display = "block";
        }
        else {
            document.getElementById('FrameName').style.display = "none";
        }
    }

    return (
        <div className="" >
            < PageTabs locationTab={"/SaleInvoice"} />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Sale Voucher List</h2>
                    <TableButton PageCreate="/SaleInvoice"/>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left">
                                <div className="col-sm-n1">
                                    <div className="group_head">
                                        <input placeholder="Bill Series" type="text" className="bill_panel_input svl_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date From" type="date" className="date_panel_input svl_date_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date To" type="date" className="date_panel_input svl_date_input" />
                                    </div>
                                    <div className="group_head">
                                        <svg className="search_panel_icon svl_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                        <input placeholder="Search" type="search" className="searchbar_panel_input " />
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div>
                            {isModalOpen && (
                                <div className="modal" >
                                    <div className="modal-content_xls">
                                        <div className="modal-title">
                                            <span className="close" onClick={hideModal}>
                                                &times;
                                            </span>
                                        </div>
                                        <div className="tab-content-1">
                                            <div className="company-info-top-right control_flex_m1 item_scroll">                                               
                                                <XLS />                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Date</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Series</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill No</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Party Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Net Amt</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Paid Amt</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Due Amt</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Status</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Dlvr Date</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Time</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Print</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Wtsapp</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }}> Workshop</th>

                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>401</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Pankaj Verma</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1600</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>600</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Pending</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>07:00 PM</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">2</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>402</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Ram Sharma</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Pending</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>08:00 PM</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">3</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>403</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>jayant</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>0.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Paid</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>06:00 PM</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">4</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>404</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Vijay singh</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>0.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Paid</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>27-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>08:00 PM</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">5</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>28-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>405</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Rahul Singh</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>4000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>3000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Pending</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>28-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>07:00 PM</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">6</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>28-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>406</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Sanjay Bhatt</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1600</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>1000</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>600</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Pending</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>28-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>06:00 PM</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">7</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Geeta Ratre</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>0.00</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Paid</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>28-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>07:00 PM</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square" style={{color:"#008080"}}></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash" style={{color:"red"}}></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print" style={{color:"darkblue"}}></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-brands fa-square-whatsapp"></i></button></td>
                                            <td><button className="workshop_btn" onClick={openWorkshopModal}><i className="fa-solid fa-shop"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/******************************************* For Mobile View ***********************************/}
            <div className="wrapper" style={{ display: "none" }}>
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>
            {/************************* Workshop Modal *****************************/}
            {WorkshopModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "35%", }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label >Workshop Details</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeWorkshopModal}>x</label>
                            </div>
                            <hr />
                            <div className="overflowY" style={{ height: "auto", maxHeight:"465px" }}>
                                <div>
                                    <div className="space-between">
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" required
                                                style={{ fontSize: "12px" }}>
                                                <option>Workshop A</option>
                                                <option>Workshop B</option>
                                                <option>Workshop C</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Workshop Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Order No.</label>
                                        </div>
                                    </div>
                                    <div className="space-between mt-1">
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Party Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Ord Ref.</label>
                                        </div>
                                    </div>

                                    <div className="" style={{}} >
                                        <div className="input-box-SI-2" >
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 "></label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>SPH</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>CYL</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>AXIS</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>V/N</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>Add</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>PD</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-SI-2" style={{ width: "100%" }}>
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 fs-131"> Right &nbsp;</label>
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-SI-2" >
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 fs-131"> Left &nbsp;</label>
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="labelFieldGrid mt-3">
                                        {LabelField.map((data) => (
                                            <div className="input-containerEffect mt-2" style={{ margin: "1%" }} key={data.id}>
                                                <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" style={{ width: "98%" }} required />
                                                <label id="input1" className="labelEffect1">{data.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="labelFieldGrid">
                                        <div className="space-between">
                                            <div className="input-containerEffect mt-2" style={{ margin: "1%", }} >
                                                <input id="withFrame" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" onChange={() => WithFrameChange()} required />
                                                <label id="input1" className="labelEffect1">With Frame (Y/N)</label>
                                            </div>
                                            {/*<label className=" fs-131 mt-2">Lens only</label>*/}
                                            <div id="" className="input-containerEffect mt-2" style={{ margin: "1%", }} >
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" placeholder="Lens Name" type="text" style={{ width: "98%" }} required />
                                                <label id="input1" className="labelEffect1">Lens Only &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div id="FrameName" className="input-containerEffect mt-2" style={{ margin: "1%", display: "none", width: "50%" }} >
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                            <label id="input1" className="labelEffect1">Frame Name</label>
                                        </div>
                                    </div>
                                </div>
                                {/********************** Repeated one ***********************/}
                                <hr />
                                <div>
                                    <div className="space-between">
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" required
                                                style={{ fontSize: "12px" }}>
                                                <option>Workshop A</option>
                                                <option>Workshop B</option>
                                                <option>Workshop C</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Workshop Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Order No.</label>
                                        </div>
                                    </div>
                                    <div className="space-between mt-1">
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Party Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{}} type="text" required />
                                            <label id="input1" className="labelEffect1">Ord Ref.</label>
                                        </div>
                                    </div>

                                    <div className="" style={{}} >
                                        <div className="input-box-SI-2" >
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 "></label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>SPH</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>CYL</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>AXIS</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>V/N</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>Add</label>
                                                    <label className="col-sm1-4 fs-131" style={{ width: "15%" }}>PD</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-SI-2" style={{ width: "100%" }}>
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 fs-131"> Right &nbsp;</label>
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-3 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-SI-2" >
                                            <div className="input-box-m6">
                                                <div className="row mt-2" style={{ width: "100%" }}>
                                                    <label className="col-sm-3 fs-131"> Left &nbsp;</label>
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                    <input className="pwrInput col-sm1-4 table-input box-size1" style={{ width: "15%" }} type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="labelFieldGrid mt-3">
                                        {LabelField.map((data) => (
                                            <div className="input-containerEffect mt-2" style={{ margin: "1%" }} key={data.id}>
                                                <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" style={{ width: "98%" }} required />
                                                <label id="input1" className="labelEffect1">{data.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="labelFieldGrid">
                                        <div className="space-between">
                                            <div className="input-containerEffect mt-2" style={{ margin: "1%", }} >
                                                <input id="withFrame" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" onChange={() => WithFrameChange()} required />
                                                <label id="input1" className="labelEffect1">With Frame (Y/N)</label>
                                            </div>
                                            {/*<label className=" fs-131 mt-2">Lens only</label>*/}
                                            <div id="" className="input-containerEffect mt-2" style={{ margin: "1%", }} >
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" placeholder="Lens Name" type="text" style={{ width: "98%" }} required />
                                                <label id="input1" className="labelEffect1">Lens Only &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div id="FrameName" className="input-containerEffect mt-2" style={{ margin: "1%", display: "none", width: "50%" }} >
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                            <label id="input1" className="labelEffect1">Frame Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/**************************************************/}
                            <hr className="mt-2" />
                            <div className="flex-end" style={{ padding: "0px" }}>
                                <button className="greenBtn" style={{ width: "18%" }}>Save</button>
                                <button className="redBtn" style={{ width: "18%" }} onClick={closeWorkshopModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >


    );
}

export default SaleVoucherList;
