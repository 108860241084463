import { useEffect, useState } from "react";
import godownApi from "../../API/APIServices/Godown"

function MultiGodown({ handleModalClick, hideModal6 , OpeningStock ,handleInputChange , setOpeningStock}) {
    // const [isBtnChallan,] = useState(props.isBtnChallan);

    //**************************{Table}*****************************
    const [GodownData , setGodownData] = useState([])
    console.log(GodownData)

    
    useEffect(()=>{
       const fetchdata= async ()=>{
        var data = await godownApi.getGodown()
        setGodownData(data)
       }
       fetchdata()
    }, [])

    const [taxRows, setTaxRows] = useState([
        { id: 1, TaxName: "", Percent: "", Amount: "" },
        { id: 2, TaxName: "", Percent: "", Amount: "" },
        { id: 3, TaxName: "", Percent: "", Amount: "" },
    ]);
    console.log(taxRows)

    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: taxRows.length + 1, content: '' };
            setTaxRows([...taxRows, newRow]);
            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);
            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    const handleCellChange = (event, rowIndex, colIndex) => {
        const updatedRows = [...taxRows];
        updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            content: event.target.value,
        };
        setTaxRows(updatedRows);
    };
    const deleteTaxRow = (rowIndex) => {
        const updatedRows = taxRows.filter((_, index) => index !== rowIndex);
        setTaxRows(updatedRows);
    }

    return (
        <div>
            <div onClick={hideModal6} className="modal-back">
                <div onClick={handleModalClick} className="MultiGOdownModal">
                    <div style={{ padding: "15px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Multi Godown Details</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={hideModal6} >x</label>
                        </div>

                        <div className="">
                            <div style={{ border: "1px solid gray" }}>
                                <table className="" style={{ width: "100%" }}>
                                    <thead className="table-tbody" style={{ backgroundColor: "#e9e5e5" }}>
                                        <tr className="ItemMastertableHead">
                                            <th className="TableTrFile7">Godown</th>
                                            <th className="TableTrFile7">Quantity</th>

                                        </tr>
                                    </thead>
                                    <tbody className=" table-container-tbody TableTbodyScroll"  >
                                        {taxRows.map((row, rowIndex) => (
                                            <tr style={{ height: "28px" }} className="table-tr1" key={rowIndex} >
                                                <td className="table-td-2">
                                                    <select style={{height:"29px"}}  className="modify_im_input  mode_category"
                                                        name={row.Godown}
                                                        value={row.Godown}
                                                        type="text" placeholder="" required>
                                                        <option>--Select--</option>
                                                       {GodownData.map((data) =>(
                                                        <option value={data.id}>{data.name}</option>
                                                       ))}
                                                    </select>
                                                </td>
                                                <td className="table-td-2">
                                                    <input
                                                        className="ItemMastertablebody "
                                                        type="text"
                                                        name={row.Quantity}
                                                        value={row.Quantity}
                                                        onChange={(e) => handleCellChange(e, rowIndex, 0)}
                                                        onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                    />
                                                </td>

                                                <td className="" style={{ textAlign: "center" }}>
                                                    {/* <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button> */}
                                                    <i onClick={() => deleteTaxRow(rowIndex)} className="fa-solid fa-xmark delete-btn"></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div style={{ display: "flex", justifyContent: "space-around", fontSize: "12px" }}>
                                    {/* <div>Count:-</div>
                                    <div>OP Stock:-</div> */}

                                </div>
                                <div className="TableDownDiv">
                                    <button >save</button>
                                    {/* <div><span>PTS = Price to Stock,</span></div>
                                    <div>Cost = Purchase Price (+Exp).</div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default MultiGodown;