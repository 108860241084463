import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import BillTypeApi from "../../../API/APIServices/BillType";
import DataTable from "../TableMaster/DataTable";
import BillType from "./BillType";
import { useNavigate } from 'react-router-dom';

function BillTypesList() {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "BILL TYPE", accessor: "billType" },
        { Header: "BILL TYPE NAME", accessor: "billTypeName" },
        { Header: "TYPE OF TAX", accessor: "typeOfTax" },
        { Header: "CALCULATE TAX ON", accessor: "calculatTaxOn" },
        { Header: "TAX REGION", accessor: "taxRegion" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Bill Type" ref={input1} />, accessor: "BillType" },
        { Header: <input type="text" placeholder="Bill Type Name" ref={input2} />, accessor: "BillTypeName" },
        { Header: <input type="text" placeholder="Type of Tax" ref={input3} />, accessor: "TypeOfTax" },
        { Header: <input type="text" placeholder="Calculate Tax On" ref={input4} />, accessor: "CalculateTaxOn" },
        { Header: <input type="text" placeholder="Tax Region" ref={input5} />, accessor: "TaxRegion" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await BillTypeApi.GetBillType();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    id: item.id,
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    billType: item.billType,
                    billTypeName: item.billTypeName,
                    typeOfTax: item.typeOfTax,
                    calculatTaxOn: item.calculatTaxOn,
                    taxRegion: item.taxRegion,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => EditBillType(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteBillType(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
        //this.forceUpdate(); 
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);

    const EditBillType = (id) => {
        const data = BillTypeApi.GetBillTypeById(id);
        console.log(data);
        setEditData(data);
        toggleSlider();
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        console.log(editData);
        setSliderOpen(!isSliderOpen);
    };

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteBillType = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteBillTypeConfirm = async () => {
        setLoading(true);
        try {
            const val = await BillTypeApi.deleteBillType(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
        console.log(data1);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                window.history.back();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div className="" >
            < PageTabs locationTab={"/BillType"} modelOpen={true} setSliderCallBack={setSliderOpen} />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Bill Type Master List</h2>
                    <TableButton PageCreate={"/BillType"} filterData={filterData} columns={columns} PageName="Masters" data={data1} selectedData={selectedData} />
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <DataTable filterData={filterData} columns={columns} data={data1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/******************************* Bill Type Master Modal*************************/}
            <div className={`slider-container100 ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <BillType editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} />
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteBillTypeConfirm}
                />
            )}
            {loading && <Loader />}
            {/* ************************************* Mobile View ******************************* */}
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            < ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default BillTypesList;
