import React, { useState } from 'react'
import WebTemplate from '../../../API/APIServices/WebTemplate'

const AddTemplate = (props) => {
    const { handleclick, fetchTemplates } = props
    const [name, setName] = useState("")

    const NameOFTemplate = (e) => {
        setName(e.target.value)
    }
    const AddNewTemplate = () => {
        var dataToSave = {
            name: name,
            textContents: "",
            jsonStructure: "",
            insertQuery: 0,
        }
        var saveTemplateData = WebTemplate.CreateCustomizedPageTemplate(dataToSave)
        if (saveTemplateData) {
            alert("Saved")
            fetchTemplates()
            handleclick()
        }
        else {
            alert("Error")
        }
    }


    return (
        <div className='modal-back'>
            <div className="addtemplate-modal">
                <i
                    id="modal_box_close"
                    onClick={handleclick}
                    className="fa-sharp fa-solid fa-xmark"
                ></i>
                <div className='add-template-div'>
                    <input type="text" onChange={(e) => NameOFTemplate(e)} />

                    <button className="save-temp-button" onClick={AddNewTemplate}>Add New Template</button>
                </div>
            </div>
        </div>
    )
}

export default AddTemplate