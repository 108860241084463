import React, { useEffect, useState } from 'react'
import User from '../../API/APIServices/User';
import { useNavigate } from 'react-router-dom';
import AppHeaderDetail from '../../Restaurant/AppHeaderDetail';

const Welcome = () => {

    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 550);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 550);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = async () => {
        await User.Logout();
        localStorage.clear()
        navigate("/loginpanel")
        window.location.reload();
    }

    const [loginData, setLoginData] = useState()

    useEffect(() => {
        const arrayString = localStorage.getItem('loginAccountUserData');
        if (arrayString) {
            const arrayToStore = JSON.parse(arrayString);
            setLoginData(arrayToStore)
        }
    }, [])

    const handleBackBtn = () => {
        navigate("/")
    }

    return (
        <div>
            {isMobile && (
                <div className="heading" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <i style={{ fontSize: '20px' }} className="fa-solid fa-arrow-left icon-white" onClick={handleBackBtn}></i>
                        <div className="" style={{ alignItems: "baseline", height: "25px", width: "100%" }}>
                            {/* <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <label className="head-title-ot">{title}</label>
                        </div> */}
                        </div>
                    </div>
                </div>
            )}
            <div className="welcome-container">
                <h1>Welcome, {loginData && loginData?.userName} !!</h1>
                <p>We are glad to have you here.</p>
                <div className="welcome-actions">
                    {/* <button>Go to Dashboard</button> */}
                    <button className="greenBtn" style={{ width: "100%" }} onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </div>
    )
}

export default Welcome