import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const BillLayoutSetting = {

    insertBillLayoutSetting: async (data, inputLayout2) => {
        console.log(inputLayout2)
        debugger;
        const updatedData = Object.keys(inputLayout2)
            .filter(key => !isNaN(key)) // Filter out non-numeric keys
            .map(key => {
                const data = inputLayout2[key];
                return {
                    masterType: Number(data.masterType),
                    seriesId: data.seriesId,
                    vchExFld: data.vchExFld,
                    fieldName: data.fieldName,
                    isActive: Boolean(data.isActive),
                };
            });

        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }

        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/BillLayoutSetting`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                billType: 186,
                seriesId: Number(data.seriesId),
                maxDiscLmt: data.maxDiscLmt,
                itmWiseSalePrcList: parseBoolean(data.itmWiseSalePrcList),
                itmWiseDiscrp: parseBoolean(data.itmWiseDiscrp),
                allowBrokrage: data.allowBrokrage,
                paymentType: data.paymentType,
                separateBillDtl: parseBoolean(data.separateBillDtl),
                eWayBillTrnsDtl: parseBoolean(data.eWayBillTrnsDtl),
                printVchrAftrSaving: parseBoolean(data.printVchrAftrSaving),
                disblMultplOptForPrint: parseBoolean(data.disblMultplOptForPrint),
                sendEmail: parseBoolean(data.sendEmail),
                sendSms: parseBoolean(data.sendSms),
                enblPymntSettlmnt: parseBoolean(data.enblPymntSettlmnt),
                extraVoucherFields: updatedData

            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
            return error.message;
        }
    },

    // ************************* Get By Id ****************************
    getBillLayoutSettingById: async (billSeriesId) => {
        //debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/BillLayoutSetting/getBillLayoutSettingById?billSeriesId=${billSeriesId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get All ***************************
    getExtraFields: async (billSeriesId) => {

        try {
            const apiUrl = `${WebApiUrl}/api/BillLayoutSetting/ExtraFields?billSeriesId=${billSeriesId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteBillLayoutSetting: async (billSeriesId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillLayoutSetting?billSeriesId=${billSeriesId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // *************************** Update ***************************

    updateBillLayoutSetting: async (data, inputLayout2) => {

        const updatedData = Object.keys(inputLayout2)
        .filter(key => !isNaN(key)) // Filter out non-numeric keys
        .map(key => {
            const data = inputLayout2[key];
            return {
                masterType: Number(data.masterType),
                seriesId: data.seriesId,
                vchExFld: data.vchExFld,
                fieldName: data.fieldName,
                isActive: Boolean(data.isActive),
            };
        });

        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }

        console.log(data.seriesId)
        try {
            const apiUrl = `${WebApiUrl}/api/BillLayoutSetting?billSeriesId=${data.seriesId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                billType: 186,
                seriesId: Number(data.seriesId),
                maxDiscLmt: data.maxDiscLmt,
                itmWiseSalePrcList: parseBoolean(data.itmWiseSalePrcList),
                itmWiseDiscrp: parseBoolean(data.itmWiseDiscrp),
                allowBrokrage: data.allowBrokrage,
                paymentType: data.paymentType,
                separateBillDtl: parseBoolean(data.separateBillDtl),
                eWayBillTrnsDtl: parseBoolean(data.eWayBillTrnsDtl),
                printVchrAftrSaving: parseBoolean(data.printVchrAftrSaving),
                disblMultplOptForPrint: parseBoolean(data.disblMultplOptForPrint),
                sendEmail: parseBoolean(data.sendEmail),
                sendSms: parseBoolean(data.sendSms),
                enblPymntSettlmnt: parseBoolean(data.enblPymntSettlmnt),
                extraVoucherFields: updatedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default BillLayoutSetting;
