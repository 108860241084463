import React, { useState } from 'react'
import InputBox from './InputBox'
import Dropdown from './Dropdown'


const CODsetting = () => {

    const [inputValues, setInputValues] = useState({
        codCharge: '0',
        codLimit: '0',
        orderMinAmount: '0',
        codExemptionAmount: '0',
        selectedAccount: '',
        serviceAccountName: ''
    });

    console.log(inputValues);

    const options = [
        { label: "Xyz", value: "xyz" },
        { label: "Abc", value: "abc" }
    ]
    return (
        <div>
            <div className='heading-setting'>COD Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <InputBox label={"Cash on Delivery Charge"} value={inputValues.codCharge} onChange={(newvalue) => setInputValues({ ...inputValues, codCharge: newvalue  })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Cash on Delivery Limit Upto"} value={inputValues.codLimit} onChange={(newvalue) => setInputValues({ ...inputValues, codLimit: newvalue  })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Order Min Amount Limit"} value={inputValues.orderMinAmount} onChange={(newvalue) => setInputValues({ ...inputValues, orderMinAmount: newvalue  })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"COD Exemption Amount Limit"} value={inputValues.codExemptionAmount} onChange={(newvalue) => setInputValues({ ...inputValues, codExemptionAmount: newvalue  })} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Select Account For COD"} options={options} selectedValue={inputValues.selectedAccount} onChange={(newvalue) => setInputValues({ ...inputValues, selectedAccount: newvalue })} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Service Based Account Name"} options={options} selectedValue={inputValues.serviceAccountName} onChange={(newvalue) => setInputValues({ ...inputValues, serviceAccountName: newvalue })} />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default CODsetting
