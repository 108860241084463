import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import LoginTypeApi from "../../../API/APIServices/LoginType";
import DataTable from "../TableMaster/DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function LoginTypeMaster() {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Login Type Master';
    }, []);

    // **************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });
    const [inputValues, setInputValues] = useState({
        name: '',
        description: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await LoginTypeApi.updateLoginType(inputValues);
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("Error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await LoginTypeApi.insertLoginType(inputValues);
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({ name: "", description: "" });
        }
    }

    // ********************************************List Part ******************************

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "DESCRIPTION", accessor: "description" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "Name" },
        { Header: <input type="text" placeholder="Description" ref={input2} />, accessor: "Description" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await LoginTypeApi.getLoginType();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    name: item.name,
                    description: item.description,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editLoginType(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteLoginType(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editLoginType = async (id) => {
        setEdit(true)
        const dataById = LoginTypeApi.getLoginTypeById(id);
        if (dataById) {
            try {
                const editData = await dataById;
                console.log("dataaaa" + editData);
                setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteLoginType = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteLoginTypeConfirm = async () => {
        setLoading(true);
        try {
            const val = await LoginTypeApi.deleteLoginType(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };
    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Login Type Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                < MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1 item_scroll">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Login Type Master
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required value={inputValues.name}
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} name="name" onChange={handleInputChange} />
                                                            <label className="modify_p_text2">LogIn Type
                                                                <span className="StarClr">*
                                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required
                                                                onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input2Ref}
                                                                value={inputValues.description} name="description" onChange={handleInputChange} />
                                                            <label className="modify_p_text2">Description</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* ************************************* List ************************************** */}
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Login Type List</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteLoginTypeConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Login Type is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Login Type is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default LoginTypeMaster;
