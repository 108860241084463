import "../../css/resposive.css";
import ListSaveButton from "./ListSaveButton";
import "../../css/style1.css";

function NavBar() {
    return (
        <div className="">
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="control_master">
                            <div className="class_col-2">
                                {/* <a><i className="fa-solid fa-arrow-left i-2"></i></a> */}
                            </div>
                            <div className="class_col-3">
                                <div className="class_h-2">
                                    <p className="class_h-p">Transaction</p>
                                </div>
                                <div className="class_h-date">
                                    <p className="class_h-p2">2:00 PM on 27/07/23</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13">Recent History</p>
                                <div className="con_input_1">
                                    <input
                                        type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>
                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4">
                                                <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                                                1000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p">
                                                <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                                                1000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
