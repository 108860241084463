import React, { useEffect, useState } from 'react'
import WebTemplate from '../API/APIServices/WebTemplate';
import { useParams } from 'react-router-dom';

const PreviewTemplate = () => {

    const [apiData, setApiData] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [styleArray, setStylesArray] = useState([]);
    const { id } = useParams();
    console.log(id);

    useEffect(() => {
        console.log("styleArray", styleArray);
        console.log("apiData", apiData);
        console.log("droppedItems", droppedItems);
    }, [apiData, droppedItems, styleArray]);

    const showTemplateData = async (e) => {
        var getdata = await WebTemplate.GetCustomizedPageTemplate(id);
        if (getdata) {
            if (getdata.jsonStructure) {
                try {
                    const decodedData = decodeURIComponent(getdata.jsonStructure)
                    const parsedData = JSON.parse(decodedData);
                    // getdata.jsonStructure = parsedData
                    setDroppedItems(parsedData);
                    if (getdata.textContents) {
                        const decodedData = decodeURIComponent(getdata.textContents)
                        const parsedData = JSON.parse(decodedData);
                        // getdata.jsonStructure = parsedData
                        setStylesArray(parsedData);
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setDroppedItems([]);
                }
            } else {
                setDroppedItems([]);
            }
            var templateInnerHtml = getdata.textContents;
            var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
            getdata.textContents = templateInnerHtmlDecode

            setApiData(getdata);

            // setDataHtml(getdata)

        } else {
            document.getElementById("drop-target-innerHtml").outerHTML = "";
            setDroppedItems([]);
        }
    }
    useEffect(() => {
        showTemplateData()
    }, []);
    
    useEffect(() => {
        // Use a for loop to iterate over stylesArray
        for (let index = 0; index < styleArray.length; index++) {
            const styleObject = styleArray[index];
            console.log(styleObject.id);

            const element = document.getElementById(styleObject.id);

            if (element) {
                for (const [key, value] of Object.entries(styleObject.style)) {
                    // Convert kebab-case to camelCase for inline styles
                    const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
                    element.style[camelCaseKey] = value;
                }
            }
        }
    }, [styleArray]);


    const renderDroppedItems = () => {
        return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
            droppedItems.map((item, index) => {
                if (item.items.length > 0) {
                    return (
                        <div key={index} dangerouslySetInnerHTML={{ __html: item.textContents }} />
                    );
                }
                return null;
            })
        ) : null;
    };

    return (
        <div>
            {renderDroppedItems()}
        </div>
    )
}

export default PreviewTemplate
