import { react } from 'react';
import html2pdf from 'html2pdf.js';

const GlobalFunction = {

    generatePDF: async(displayBlock, pdf_content) => {
        document.getElementById(displayBlock).style.display = "block";
        const element = document.getElementById(pdf_content);
        // Adjust the PDF options for thermal printing
        // const opt = {
        //   margin: 0,
        //   filename: 'myfile.pdf',
        //   image: { type: 'jpeg', quality: 0.98 },
        //   html2canvas: { scale: 2 },
        //   jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        // };
        const opt = {
            margin: 0,
            filename: 'myfile.pdf',
            image: { type: 'png' },
            // image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 4 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } //[58mm by 297mm] page width and page height 
        };

        // html2pdf()
        //   .from(element)
        //   .set(opt)
        //   .toPdf()
        //   .output('blob') // Change output to 'blob'
        //   .then(pdfBlob => {
        //     // Convert PDF blob to base64
        //     const reader = new FileReader();
        //     reader.readAsDataURL(pdfBlob);
        //     reader.onloadend = () => {
        //       const pdfBase64 = reader.result;
        //       // printPDF(pdfBase64);
        //       window.ReactNativeWebView.postMessage(pdfBase64);
        //       alert("Print")
        //     };
        //   })
        //   .catch(error => {
        //     console.error('Error generating PDF:', error);
        //   });

        html2pdf()
            .from(element)
            .set(opt)
            .toPdf()
            .output('datauristring')
            .then(pdf => {
                // Convert the PDF Data URI to base64
                const pdfBase64 = pdf.split(',')[1];

                // Send the base64 string to the WebView or to the printer
                window.ReactNativeWebView.postMessage(pdfBase64);
                //window.ReactNativeWebView.postMessage(element);
                alert("Print")
                // Optionally, send the PDF to the thermal printer if directly connected

                // printPDF(pdfBase64); // You'd need to implement this function based on your printer API
                // printPDF(element); 
            })
            .catch(error => {
                console.error('Error generating PDF:', error);
            });
    }
};

export default GlobalFunction;