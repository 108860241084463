import React, { useEffect, useState, useRef, useContext } from "react";
import MyContext from "../../../Inventory/MyContext";
import throttle from "lodash.throttle";
// import { useMyContext } from "../Components/MyContext";

const VerticalLine = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [clickVtLn, setClickVtLn] = useState(false);
  let id = props.keyValueId;
  const [position, setPosition] = useState({ x: 5, y: 10 });
  const [size, setSize] = useState({ width: 50, height: 100 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });
  const { setDroppedItems, setTemplateData, stylesArray, setStylesArray, droppedItems, handleStyleChange } = useContext(MyContext);




  useEffect(() => {
    debugger
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);
    if (matchingStyle) {
      const newPositionX = parseFloat(matchingStyle.style.left) || 0;
      const newPositionY = parseFloat(matchingStyle.style.top) || 0;
      const newWidth = parseFloat(matchingStyle.style.width) || 50;
      const newHeight = parseFloat(matchingStyle.style.height) || 100;

      setPosition({ x: newPositionX, y: newPositionY });
      setSize({ width: newWidth, height: newHeight });
    }
  }, [props.keyValueId, stylesArray]);





  const handleMouseDown = (e) => {

    e.preventDefault();
    if (e.button === 0) {
      // Left mouse button is pressed, start dragging
      const { clientX, clientY } = e;
      setIsDragging(true);
      setDragOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });
    } else if (e.button === 2) {
      // Right mouse button is clicked, start resizing
      e.preventDefault();
      const { clientX, clientY } = e;
      setIsResizing(true);
      setResizeOffset({
        x: clientX - position.x - size.width,
        y: clientY - position.y - size.height,
      });
    }
  };



  // const handleMouseMove = throttle((e) => {

  //   if (isDragging) {
  //     const { clientX, clientY } = e;


  //     const newPositionX = clientX - dragOffset.x;
  //     const newPositionY = clientY - dragOffset.y;


  //     const maxX = window.innerWidth - size.width;
  //     const maxY = window.innerHeight - size.height;

  //     const constrainedPositionX = Math.min(Math.max(newPositionX, 0), maxX);
  //     const constrainedPositionY = Math.min(Math.max(newPositionY, 0), maxY);

  //     setPosition({
  //       x: constrainedPositionX,
  //       y: constrainedPositionY,
  //     });

  //     handleStyleChange("left", constrainedPositionX, "px", id);
  //     handleStyleChange("top", constrainedPositionY, "px", id);

  //   } else if (isResizing) {
  //     const { clientX, clientY } = e;


  //     const newWidth = clientX - position.x - resizeOffset.x;
  //     const newHeight = clientY - position.y - resizeOffset.y;

  //     setSize({
  //       width: clientX - position.x - resizeOffset.x,
  //       height: clientY - position.y - resizeOffset.y,
  //     });

  //     handleStyleChange("width", newWidth, "px", id);
  //     handleStyleChange("height", newHeight, "px", id);
  //   }
  // }, 16);


  const handleMouseMove = throttle((e) => {
    if (isDragging) {
      const { clientX, clientY } = e;

      // Calculate new position based on mouse movement
      const newPositionX = clientX - dragOffset.x;
      const newPositionY = clientY - dragOffset.y;

      // Ensure the element stays within the boundaries
      const maxX = window.innerWidth - size.width;
      const maxY = window.innerHeight - size.height;

      const constrainedPositionX = Math.min(Math.max(newPositionX, 0), maxX);
      const constrainedPositionY = Math.min(Math.max(newPositionY, 0), maxY);

      // Update position state
      setPosition({
        x: constrainedPositionX,
        y: constrainedPositionY,
      });

    } else if (isResizing) {
      const { clientX, clientY } = e;

      // Calculate new size based on mouse movement
      const newWidth = clientX - position.x - resizeOffset.x;
      const newHeight = clientY - position.y - resizeOffset.y;

      // Update size state
      setSize({
        width: newWidth,
        height: newHeight,
      });
    }
  }, 16);



  // const handleMouseUp = () => {
  //   debugger
  //   setIsDragging(false);
  //   setIsResizing(false);


  //   requestAnimationFrame(() => {
  //     // Update styles through handleStyleChange
  //     handleStyleChange("left", position.x, "px", id);
  //     handleStyleChange("top", position.y, "px", id);
  //     handleStyleChange("width", size.width, "px", id);
  //     handleStyleChange("height", size.height, "px", id);

  //     // Save the outerHTML, just like in handleBlur
  //     if (clickRef.current) {
  //       const updatedOuterHTML = clickRef.current.outerHTML;
  //       console.log('Updated outerHTML:', updatedOuterHTML);

  //       const updatedX = position.x;
  //       const updatedY = position.y;

  //       const prevItem = droppedItems.find(item => item.id === id);
  //       const prevX = prevItem ? prevItem.positionX : null;
  //       const prevY = prevItem ? prevItem.positionY : null;

  //       // Update if position has changed
  //       if (updatedX !== prevX || updatedY !== prevY) {
  //         setDroppedItems((prevItems) => {
  //           const updatedItems = prevItems.map((item) => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 textContents: updatedOuterHTML,
  //                 positionX: updatedX,
  //                 positionY: updatedY,
  //               };
  //             }
  //             return item;
  //           });

  //           setTemplateData((prevState) => ({
  //             ...prevState,
  //             jsonStructure: updatedItems,
  //           }));

  //           return updatedItems;
  //         });
  //       }
  //     }
  //   });
  // }; old


  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);

    const updatedX = position.x;
    const updatedY = position.y;
    const updatedWidth = size.width;
    const updatedHeight = size.height;


    const prevItem = droppedItems.find(item => item.id === id);
    const prevX = prevItem ? prevItem.positionX : null;
    const prevY = prevItem ? prevItem.positionY : null;
    const prevWidth = prevItem ? parseFloat(prevItem.style?.width) : null;
    const prevHeight = prevItem ? parseFloat(prevItem.style?.height) : null;


    const positionChanged = updatedX !== prevX || updatedY !== prevY;
    const sizeChanged = updatedWidth !== prevWidth || updatedHeight !== prevHeight;

    if (positionChanged || sizeChanged) {
      requestAnimationFrame(() => {

        if (positionChanged) {
          handleStyleChange("left", updatedX, "px", id);
          handleStyleChange("top", updatedY, "px", id);
        }

        if (sizeChanged) {
          handleStyleChange("width", updatedWidth, "px", id);
          handleStyleChange("height", updatedHeight, "px", id);
        }


        if (clickRef.current) {
          const updatedOuterHTML = clickRef.current.outerHTML;
          console.log('Updated outerHTML:', updatedOuterHTML);


          setDroppedItems((prevItems) => {
            const updatedItems = prevItems.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  textContents: updatedOuterHTML,
                  positionX: updatedX,
                  positionY: updatedY,
                  style: {
                    ...item.style,
                    width: updatedWidth + 'px',
                    height: updatedHeight + 'px',
                  },
                };
              }
              return item;
            });


            setTemplateData((prevState) => ({
              ...prevState,
              jsonStructure: updatedItems,
            }));

            return updatedItems;
          });
        }
      });
    }
  };

  const toggleBorder = () => {
    setClickVtLn(!clickVtLn);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (clickVtLn) {
        if (event.key === "ArrowUp") {
          // Prevent the default behavior of arrow keys
          event.preventDefault();

          // Move up by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - moveAmount,
          }));
        } else if (event.key === "ArrowDown") {
          event.preventDefault();

          // Move down by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + moveAmount,
          }));
        } else if (event.key === "ArrowLeft") {
          event.preventDefault();

          // Move left by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x - moveAmount,
          }));
        } else if (event.key === "ArrowRight") {
          event.preventDefault();

          // Move right by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + moveAmount,
          }));
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [clickVtLn]);


  const clickRef = useRef(null);

  // Effect to add the global click event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        // Click occurred outside the component
        setClickVtLn(false);
      }
    };

    const handleEnterKey = (event) => {
      if (clickVtLn && event.key === "Enter") {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // Set clickVtLn to false when Enter key is pressed
        setClickVtLn(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [clickRef, clickVtLn]);


  const deletebuttonshow = () => {


    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };



  return (
    <div
      className={`draggable-resizable-component-web-template fieldbox-toolpanel-web-template ${clickVtLn ? "bordered-web-template" : ""
        } `}
      ref={clickRef}
      id={props.keyValueId}
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        // left: `${position.x / 8}%`,
        // top: `${position.y / 2}%`, 
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        cursor: isDragging
          ? "grabbing"
          : isResizing
            ? "nwse-resize"
            : "grab",


      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={(e) => {
        deletebuttonshow()
        toggleBorder();
        props.onComponentClick(props.keyValueId);
      }}

    >
      <div
        style={{ width: "1px", height: "100%", borderLeft: "1px solid black" }}
        onClick={toggleBorder}
      ></div>
      <button
        className="component-delete-web-template"
        id={props.keyValueId + "delete"}
        onClick={() => props.handleRemoveItem(props.keyValueId)}
      >
        <i className="fa-solid fa-trash"></i>
      </button>
    </div>
  );
};

export default VerticalLine;
