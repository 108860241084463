import React, { useState } from "react";
import './../../css/style1.css';
import PageTabs from "../Components/PageTabs";
//import Piechart from "./Piechart";

function PreviousOrder() {

    const [prevOrder] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }
    ]);

    return (
        <div className="height98vh" style={{ backgroundColor: "ghostwhite" }} >
            < PageTabs/>
            <div className="flex" style={{ backgroundColor: "ghostwhite" , justifyContent:"space-around" }}>
                <div className="orderBoxDiv" style={{ width: "22%", backgroundColor: "#f0f1fa", padding: "20px" }}>
                    <div className="space-between">
                        <h5 className="">Today's Sale</h5>
                        <label>1300</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Current Month Sales</h6>
                        <label className="fs-121">286408.65</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Last Month Sales</h6>
                        <label className="fs-121">321654.85</label>
                    </div>
                </div>
                <div className="orderBoxDiv" style={{ width: "22%", backgroundColor: "#f1efe0", padding: "20px" }}>
                    <div className="space-between">
                        <h5 className="">Today's Sale Order</h5>
                        <label>1300</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Current Month Sale Order</h6>
                        <label className="fs-121">286408.65</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Last Month Sale Order</h6>
                        <label className="fs-121">321654.85</label>
                    </div>
                </div>
                <div className="orderBoxDiv" style={{ width: "22%", backgroundColor: "#effaef", padding: "20px" }}>
                    <div className="space-between">
                        <h5>Today's Collection</h5>
                        <label>1300</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Current Month Collection</h6>
                        <label className="fs-121">286408.65</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Last Month Collection</h6>
                        <label className="fs-121">321654.85</label>
                    </div>
                </div>
                <div className="orderBoxDiv" style={{ width: "22%", backgroundColor: "rgba(255, 238, 220, 0.39)", padding: "20px" }}>
                    <div className="space-between">
                        <h5>Today's Dues</h5>
                        <label>1300</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Current Month Dues</h6>
                        <label className="fs-121">286408.65</label>
                    </div>
                    <div className="space-between">
                        <h6 className="fs-121">Last Month Dues</h6>
                        <label className="fs-121">321654.85</label>
                    </div>
                </div>
            </div>
            <hr />
            <div className="flex-start">&nbsp; &nbsp; &nbsp;
                <input type="search" /> &nbsp;
                <button className="ord-btn">All</button> &nbsp;
                <button className="ord-btn">Sales</button> &nbsp;
                <button className="ord-btn">Received Payment</button> &nbsp;
                <button className="ord-btn">Sales Order</button> &nbsp;
                <button className="ord-btn">Sales Challan</button> &nbsp;
                <button className="ord-btn">Sales Return</button> &nbsp;
                <button className="ord-btn">Purchase</button> &nbsp;
                <button className="ord-btn">Payment Out</button> &nbsp;
                <button className="ord-btn">Purchase Order</button> &nbsp;
                <button className="ord-btn">Purchase Challan</button> &nbsp;
                <button className="ord-btn">Purchase Return</button> &nbsp;
            </div>
            <hr />
            <div className="" style={{ width: "100%", display: "flex" }}>
                {/************************** Left Container ****************************/}
                <div className=""> {/*prevOrderLeftContainer*/}
                    <div className="flex">
                        <div className="orderGridView">
                            {prevOrder.map((order, index) => (
                                <div className="orderBoxDiv" key={order.id}>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="fw-600">HARIKI SHAN &nbsp; </label>
                                            <i className="fa-solid fa-phone fs-13" style={{ color: "darkgreen" }}></i>
                                            <label className="" style={{ color: "darkred" }}> &nbsp; 9876543210</label>
                                        </div>
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex">
                                            <label className="fs-131">Invoice Sale 514 &nbsp;</label>
                                            <label className="fs-131" style={{ color: "red" }}>27-11-2023</label>
                                        </div>
                                        <label className="fs-131">Rs.1500.00</label>
                                    </div>
                                    <div className="space-between">
                                        <label className="fs-131">Delivery 27-11-2023</label>
                                        <button className="pendingBtn">Pending <span><i className="fa-solid fa-pen font-s-12"></i></span></button>
                                    </div>
                                    <hr />
                                    <div className="space-between">
                                        <label className="fs-131" style={{ color: "darkblue" }}>Rs.54321.00</label>
                                        <label className="fs-131">F.Ronak 456 BLK - 1- 800</label>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <i className="fa-brands fa-whatsapp fs-13" style={{ color: "green" }}></i>
                                            <label className="fs-131" style={{ color: "green" }}>&nbsp; Send Reminder</label>
                                        </div>
                                        <label className="fs-131">LENS PROG PG - 1 - 500</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/************************** Right Container ****************************/}
                {/*<div className="prevOrderRightContainer">*/}
                {/*    */}{/*<Piechart />*/}
                {/*</div>*/}

            </div>
        </div>
    );
}


export default PreviousOrder;
