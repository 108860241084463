import { useState } from "react";
function ItemWiseBatchList({ handleModalClick, hideModal6 }) {
    // const [isBtnChallan,] = useState(props.isBtnChallan);

    //**************************{Table}*****************************

    const [taxRows, setTaxRows] = useState([
        { id: 1, TaxName: "", Percent: "", Amount: "" },
        { id: 2, TaxName: "", Percent: "", Amount: "" },
        { id: 3, TaxName: "", Percent: "", Amount: "" },
        { id: 4, TaxName: "", Percent: "", Amount: "" },
        { id: 5, TaxName: "", Percent: "", Amount: "" },
    ]);

    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: taxRows.length + 1, content: '' };
            setTaxRows([...taxRows, newRow]);
            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);
            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    const handleCellChange = (event, rowIndex, colIndex) => {
        const updatedRows = [...taxRows];
        updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            content: event.target.value,
        };
        setTaxRows(updatedRows);
    };
    const deleteTaxRow = (rowIndex) => {
        const updatedRows = taxRows.filter((_, index) => index !== rowIndex);
        setTaxRows(updatedRows);
    }

    const [ShowBatchNo, setShowBatchNo] = useState(true);
    const BatchNo = () => {
        setShowBatchNo(!ShowBatchNo);
    };

    const [ShowMCentre, setShowMCentre] = useState(false);
    const MCentre = () => {
        setShowMCentre(!ShowMCentre);
    };
    const [showMfgDt, setShowMfgDt] = useState(true);
    const MfgDt = () => {
        setShowMfgDt(!showMfgDt);
    }
    const [showExpDt, setShowExpDt] = useState(true);
    const ExpDt = () => {
        setShowExpDt(!showExpDt);
    }
    const [showPTS, setShowPTS] = useState(true);
    const PTS = () => {
        setShowPTS(!showPTS);
    }
    const [showPTR, setShowPTR] = useState(false);
    const PTR = () => {
        setShowPTR(!showPTR);
    }
    const [showMRP, setShowMRP] = useState(true);
    const MRP = () => {
        setShowMRP(!showMRP);
    }
    const [showCost, setShowCost] = useState(true);
    const Cost = () => {
        setShowCost(!showCost)
    }
    const [showOpPacks, setShowOpPacks] = useState(false);
    const OpPacks = () => {
        setShowOpPacks(!showOpPacks);
    }
    const [showOpLoose, setShowOpLoose] = useState(false);
    const OpLoose = () => {
        setShowOpLoose(!showOpLoose);
    }
    const [showOpValue, setShowOpValue] = useState(false);
    const OpValue = () => {
        setShowOpValue(!showOpValue)
    }


    const [TopSetting, setTopSetting] = useState(false);
    const showSetting = () => {
        setTopSetting(true);
    }
    const hideSetting = () => {
        setTopSetting(false);
    }



    return (
        <div>
            <div onClick={hideModal6} className="modal-back">
                <div onClick={handleModalClick} className="BatchWise-modal">
                    <div style={{ padding: "15px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Item Wise Batch Details</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={hideModal6} >x</label>
                        </div>

                        <div className="">
                            <div style={{ border: "1px solid gray" }}>
                                <table className="" style={{ width: "100%" }}>
                                    <thead className="table-tbody" style={{ backgroundColor: "#e9e5e5" }}>
                                        <tr className="ItemMastertableHead">
                                            {ShowBatchNo && <th className="TableTrFile5">Batch No</th>}
                                            {ShowMCentre && <th className="TableTrFile5">M Centre</th>}
                                            {showMfgDt && <th className="TableTrFile5">Mfg Dt</th>}
                                            {showExpDt && <th className="TableTrFile5">Exp Dt</th>}
                                            {showPTS && <th className="TableTrFile5">PTS</th>}
                                            {showPTR && <th className="TableTrFile5">PTR</th>}
                                            {showMRP && <th className="TableTrFile5">MRP</th>}
                                            {showCost && <th className="TableTrFile5">Cost</th>}
                                            {showOpPacks && <th className="TableTrFile5">Op Packs</th>}
                                            {showOpLoose && <th className="TableTrFile5">Op Loose</th>}
                                            {showOpValue && <th className="TableTrFile5">Op Value</th>}
                                            {/* <th className="table-th-4">Action</th> */}
                                            <th className="table-th table-btn table-btn1 c-w" onClick={showSetting}>
                                                <i className="fa-solid fa-gear c-w settingIcon" style={{ display: "block" }}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className=" table-container-tbody TableTbodyScroll"  >
                                        {taxRows.map((row, rowIndex) => (
                                            <tr style={{ height: "28px" }} className="table-tr1" key={rowIndex} >
                                                {/* <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                                    {rowIndex + 1}
                                                                                </td> */}
                                                {ShowBatchNo && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody input-box-td1"
                                                            type="text"
                                                            name={row.BatchNo}
                                                            value={row.BatchNo}
                                                        />
                                                    </td>
                                                )}
                                                {ShowMCentre && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody input-box-td1"
                                                            type="text"
                                                            name={row.MaterialCentre}
                                                            value={row.MaterialCentre}
                                                        />
                                                    </td>
                                                )}
                                                {showMfgDt && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="date"
                                                            name={row.MfgDt}
                                                            value={row.MfgDt}
                                                        />
                                                    </td>
                                                )}
                                                {showExpDt && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="date"
                                                            name={row.ExpDt}
                                                            value={row.ExpDt}
                                                        />
                                                    </td>
                                                )}
                                                {showPTS && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.PTS}
                                                            value={row.PTS}
                                                        />
                                                    </td>
                                                )}
                                                {showPTR && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.PTR}
                                                            value={row.PTR}
                                                        />
                                                    </td>
                                                )}
                                                {showMRP && (
                                                    <td className="table-td-2" style={{ width: '110px' }}>
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.MRP}
                                                            value={row.MRP}
                                                        />
                                                    </td>
                                                )}
                                                {showCost && (
                                                    <td className="table-td-2" style={{ width: '110px' }}>
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.Lost}
                                                            value={row.Lost}
                                                        />
                                                    </td>
                                                )}
                                                {showOpPacks && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.OpPacks}
                                                            value={row.OpPacks}
                                                        />
                                                    </td>
                                                )}
                                                {showOpLoose && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.OpLoose}
                                                            value={row.OpLoose}
                                                        />
                                                    </td>
                                                )}
                                                {showOpValue && (
                                                    <td className="table-td-2">
                                                        <input
                                                            className="ItemMastertablebody "
                                                            type="text"
                                                            name={row.OpValue}
                                                            value={row.OpValue}
                                                            onChange={(e) => handleCellChange(e, rowIndex, 0)}
                                                            onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                        />
                                                    </td>
                                                )}
                                                <td className="" style={{ textAlign: "center" }}>
                                                    {/* <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button> */}
                                                    <i onClick={() => deleteTaxRow(rowIndex)} className="fa-solid fa-xmark delete-btn"></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div style={{ display: "flex", justifyContent: "space-around", fontSize: "12px" }}>
                                    <div>Count:-</div>
                                    <div>OP Stock:-</div>
                                    <div> Value:-</div>
                                </div>
                                <div className="TableDownDiv">
                                    <div><span>PTS = Price to Stock,</span></div>
                                    <div>M Centre = Material Centre,</div>
                                    <div>PTR = Price to Retailer,</div>
                                    <div>MRP = Price Rate,</div>
                                    <div>Cost = Purchase Price (+Exp).</div>
                                </div>
                            </div>
                        </div>
                        {TopSetting && (

                            <div className="modal" >
                                <div className="ModalBoxProductSetting">
                                    <div className="modal-title">
                                        <h3 className="modal-h">Add Columns into Table</h3>
                                        <span className="close" onClick={hideSetting} >
                                            &times;
                                        </span>
                                    </div>
                                    <div className="ModalContentModalSettingP" style={{ paddingTop: "5px" }}>
                                        <div className="gridview2">
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={BatchNo} disabled={ShowBatchNo} checked={ShowBatchNo} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Batch No</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={MCentre} checked={ShowMCentre} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">M Centre</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={MfgDt} disabled={showMfgDt} checked={showMfgDt} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Mfg Dt</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={ExpDt} disabled={showExpDt} checked={showExpDt} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Exp Dt</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={PTS} disabled={showPTS} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">PTS</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={PTR} checked={showPTR} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">PTR</label>
                                                </div>

                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={MRP} disabled={showMRP} checked={showMRP} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">MRP</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={Cost} checked={showCost} disabled={showCost} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Cost</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={OpPacks} checked={showOpPacks} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Op Packs</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={OpLoose} checked={showOpLoose} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Op Loose</label>
                                                </div>
                                            </div>
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1">
                                                    <input onChange={OpValue} checked={showOpValue} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">Op Value</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ItemWiseBatchList;