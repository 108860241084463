// import { useState } from "react";
import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import TableButton from "./../../Props/TableButton";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
import DataTable from "../TableMaster/DataTable";
import AccountCategoryMaster from "./AccountCategoryMaster";
import MiscellaneousMaster from "./MiscellaneousMaster";
import { useNavigate } from 'react-router-dom';

function MiscellaneousMasterList() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    // input5,
  } = useContext(MyContext);

  const navigate = useNavigate();
  const [mainData, setMainData] = useState([]);
  // console.log(mainData);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [controlType, setControlType] = useState(168);


  const changetablevalue = (e) => {
    const value = e.target.value;
    console.log(value)
    setControlType(value);
    fetchData(value);
  };
  // console.log(controlType); 


  useEffect(() => {
    setControlType(controlType)
  }, [controlType]);


  const toggleSlider = (value) => {
    setSliderOpen(value);
  };

  const fetchData = async (controlTypevalue) => {
    // setLoading(true);
    try {
      const val = await MiscellaneousMasterApi.GetMiscellaneousMaster(
        controlTypevalue
      );
      // console.log(val);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          controlType: item.controlType,
          name: item.name,
          printName: item.printName,
          availableFrom: item.availableFrom === null ? "-----" : item.availableFrom,
          availableTo: item.availableTo === null ? "-----" : item.availableTo,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.id, controlTypevalue)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(controlType)
    fetchData(controlType);
  }, []);

  const Edit = async (id, controlTypevalue) => {
    const data = await MiscellaneousMasterApi.GetMiscellaneousMasterById(id, controlTypevalue);
    setEditData(data);
    toggleSlider(true);
  };

  const [getdeleteId, setDeleteId] = useState(null);
  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await MiscellaneousMasterApi.deleteMiscellaneousMaster(
        getdeleteId,
        controlType
      );
      if (Boolean(val) === true) {
        fetchData();
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const [data, setData] = useState({});
  const [editData, setEditData] = useState("");







  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNo", accessor: "sno" },
    { Header: "NAME", accessor: "name" },
    { Header: "Print Name", accessor: "printName" },
    { Header: "Available From", accessor: "availableFrom" },
    { Header: "Available To", accessor: "availableTo" },
    // { Header: "Phone", accessor: "phone" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    // { Header: "", accessor: "" },
    {
      Header: (
        // <input type="text" placeholder="Name" ref={input1} />
        <select onChange={(e) => changetablevalue(e)} value={controlType}>
          <option value="168">Balance Sheet</option>
          <option value="169">Profit Loss</option>
          <option value="170">Time Slot</option>
          <option value="171">Item Preparation Time</option>
          <option value="172">Work Shop</option>
          <option value="173">SMS Type</option>
        </select>
      ),
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Name" ref={input1} />,
      accessor: "",
    },
    {
      Header: <input type="text" placeholder="Print Name" ref={input2} />,
      accessor: "printName",
    },
    {
      Header: <input type="text" placeholder="Available From" ref={input3} />,
      accessor: "availableFrom",
    },
    {
      Header: <input type="text" placeholder="Available To" ref={input4} />,
      accessor: "availableTo",
    },
    // {
    //   Header: <input type="text" placeholder="Phone" ref={input5} />,
    //   accessor: "phone",
    // },
  ];

  return (
    <div className="">
      <PageTabs locationTab={"/MiscellaneousMaster"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen} />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Miscellaneous Master List</h2>
          <TableButton PageCreate={"/MiscellaneousMaster"} filterData={filterData}
            columns={columns}
            data={mainData} PageName="Masters" />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />

        <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
          <div style={{ padding: "15px" }}>
            {/* <MiscellaneousMaster
            data={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            // fetchData={fetchData}
          /> */}
            <MiscellaneousMaster
              data={editData}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
            />
          </div>
        </div>

        {alertBoxDelete && (
          <AlertBox
            HeadingTop="DELETE"
            Heading="Are You sure you want to delete this"
            OpenAlertBox={OpenAlertBoxDelete}
            onYesClick={DeleteAccountConfirm}
          />
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
}

export default MiscellaneousMasterList;
