import React, { useEffect, useState, useRef, useContext } from 'react';
import MyContext from '../../../Inventory/MyContext';
import throttle from 'lodash.throttle'

const HorizontalLine = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [clickVtLn, setClickVtLn] = useState(false);
  let id = props.keyValueId;
  const [position, setPosition] = useState({ x: 10, y: 20 });
  const [size, setSize] = useState({ width: 100, height: 40 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });

  const { stylesArray, handleStyleChange, droppedItems, setDroppedItems, setTemplateData, setStylesArray } = useContext(MyContext);



  const clickRef = useRef(null);


  useEffect(() => {
    debugger
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

    if (matchingStyle) {
      const newPositionX = parseFloat(matchingStyle.style.left) || 0;
      const newPositionY = parseFloat(matchingStyle.style.top) || 0;
      const newWidth = parseFloat(matchingStyle.style.width) || 100;
      const newHeight = parseFloat(matchingStyle.style.height) || 40;

      setPosition({ x: newPositionX, y: newPositionY });
      setSize({ width: newWidth, height: newHeight });
    }
  }, [props.keyValueId, stylesArray]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        // Click occurred outside the component
        setClickVtLn(false);
      }
    };

    const handleEnterKey = (event) => {
      if (clickVtLn && event.key === 'Enter') {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // Set clickVtLn to false when Enter key is pressed
        setClickVtLn(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [clickRef, clickVtLn]);



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (clickVtLn) {
        if (event.key === 'ArrowUp') {
          // Prevent the default behavior of arrow keys
          event.preventDefault();

          // Move up by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - moveAmount,
          }));
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();

          // Move down by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + moveAmount,
          }));
        } else if (event.key === 'ArrowLeft') {
          event.preventDefault();

          // Move left by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x - moveAmount,
          }));
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();

          // Move right by 1 pixel or 10 pixels if the Control key is pressed
          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + moveAmount,
          }));
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickVtLn]); // Listen to changes in clickVtLn



  const handleMouseDown = (e) => {

    if (e.button === 0) {
      e.preventDefault();

      const { clientX, clientY } = e;
      setIsDragging(true);
      setDragOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });
    } else if (e.button === 2) {
      e.preventDefault();

      const { clientX, clientY } = e;
      setIsResizing(true);
      setResizeOffset({
        x: clientX - position.x - size.width,
        y: clientY - position.y - size.height,
      });
    }
  };

  const handleMouseMove = throttle((e) => {

    if (isDragging) {
      const { clientX, clientY } = e;

      // Calculate the new position
      const newPositionX = clientX - dragOffset.x;
      const newPositionY = clientY - dragOffset.y;

      // Ensure the element stays within the boundaries
      const maxX = window.innerWidth - size.width;
      const maxY = window.innerHeight - size.height;

      const constrainedPositionX = Math.min(Math.max(newPositionX, 0), maxX);
      const constrainedPositionY = Math.min(Math.max(newPositionY, 0), maxY);

      setPosition({
        x: constrainedPositionX,
        y: constrainedPositionY,
      });

      // requestAnimationFrame(() => {
      //   handleStyleChange("left", constrainedPositionX, "px", id);
      //   handleStyleChange("top", constrainedPositionY, "px", id);
      // });

    } else if (isResizing) {
      const { clientX, clientY } = e;

      const newWidth = clientX - position.x - resizeOffset.x;
      const newHeight = clientY - position.y - resizeOffset.y;

      const minSize = 10;
      const maxWidth = window.innerWidth;
      const maxHeight = window.innerHeight;

      const constrainedWidth = Math.min(Math.max(newWidth, minSize), maxWidth);
      const constrainedHeight = Math.min(Math.max(newHeight, minSize), maxHeight);

      setSize({
        width: constrainedWidth,
        height: constrainedHeight,
      });

      // if (constrainedWidth !== size.width || constrainedHeight !== size.height) {
      //   requestAnimationFrame(() => {
      //     handleStyleChange("width", constrainedWidth, "px", id);
      //     handleStyleChange("height", constrainedHeight, "px", id);
      //   });
      // }
    }
  }, 16);

  // const handleMouseUp = () => {
  //   setIsDragging(false);
  //   setIsResizing(false);

  //   // Save the position and size after dragging or resizing
  //   requestAnimationFrame(() => {
  //     // Update the styles using handleStyleChange
  //     handleStyleChange("left", position.x, "px", id);
  //     handleStyleChange("top", position.y, "px", id);
  //     handleStyleChange("width", size.width, "px", id);
  //     handleStyleChange("height", size.height, "px", id);


  //     if (clickRef.current) {
  //       const updatedOuterHTML = clickRef.current.outerHTML;
  //       console.log('Updated outerHTML:', updatedOuterHTML);


  //       const updatedX = position.x;
  //       const updatedY = position.y;


  //       const prevItem = droppedItems.find(item => item.id === id);
  //       const prevX = prevItem ? prevItem.positionX : null;
  //       const prevY = prevItem ? prevItem.positionY : null;


  //       if (updatedX !== prevX || updatedY !== prevY) {
  //         console.log('Position changed, updating...');


  //         setDroppedItems((prevItems) => {
  //           const updatedItems = prevItems.map((item) => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 textContents: updatedOuterHTML,
  //                 positionX: updatedX,
  //                 positionY: updatedY,
  //               };
  //             }
  //             return item;
  //           });

  //           // Update the template data with new items
  //           setTemplateData((prevState) => ({
  //             ...prevState,
  //             jsonStructure: updatedItems,
  //           }));

  //           return updatedItems;
  //         });
  //       } else {
  //         console.log('Position not changed, no update needed.');
  //       }
  //     }
  //   });
  // };


  const handleMouseUp = () => {
    // Stop dragging and resizing
    setIsDragging(false);
    setIsResizing(false);

    // Extract the current position and size
    const updatedX = position.x;
    const updatedY = position.y;
    const updatedWidth = size.width;
    const updatedHeight = size.height;

    // Find the previous item for comparison
    const prevItem = droppedItems.find(item => item.id === id);
    const prevX = prevItem ? prevItem.positionX : null;
    const prevY = prevItem ? prevItem.positionY : null;
    const prevWidth = prevItem ? parseFloat(prevItem.style?.width) : null;
    const prevHeight = prevItem ? parseFloat(prevItem.style?.height) : null;

    // Determine if there are any changes in position or size
    const positionChanged = updatedX !== prevX || updatedY !== prevY;
    const sizeChanged = updatedWidth !== prevWidth || updatedHeight !== prevHeight;

    // Update styles and state only if there are changes
    if (positionChanged || sizeChanged) {
      requestAnimationFrame(() => {
        // If position changed, update the styles for left and top
        if (positionChanged) {
          handleStyleChange("left", updatedX, "px", id);
          handleStyleChange("top", updatedY, "px", id);
        }

        // If size changed, update the styles for width and height
        if (sizeChanged) {
          handleStyleChange("width", updatedWidth, "px", id);
          handleStyleChange("height", updatedHeight, "px", id);
        }

        // Save the updated outerHTML, just like in handleBlur
        if (clickRef.current) {
          const updatedOuterHTML = clickRef.current.outerHTML;
          console.log('Updated outerHTML:', updatedOuterHTML);

          // Only update droppedItems if position or size has changed
          if (positionChanged || sizeChanged) {
            setDroppedItems((prevItems) => {
              const updatedItems = prevItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    textContents: updatedOuterHTML,
                    positionX: updatedX,
                    positionY: updatedY,
                    style: {
                      ...item.style,
                      width: updatedWidth + 'px',
                      height: updatedHeight + 'px',
                    },
                  };
                }
                return item;
              });

              // Update the template data with new items
              setTemplateData((prevState) => ({
                ...prevState,
                jsonStructure: updatedItems,
              }));

              return updatedItems;
            });
          }
        }
      });
    } else {
      console.log('No changes detected in position or size.');
    }
  };

  const toggleBorder = () => {
    setClickVtLn(!clickVtLn);
  };


  const deletebuttonshow = () => {
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };



  return (
    <div
      className={`draggable-resizable-component-web-template fieldbox-toolpanel-web-template ${clickVtLn ? 'bordered-web-template' : ''
        } `}
      ref={clickRef}
      id={props.keyValueId}
      style={{
        display: "flex", alignItems: "center",
        position: 'absolute',
        left: position.x,
        top: position.y,
        // width: `${size.width / 5}%`,
        width: size.width,
        height: size.height,
        cursor: isDragging
          ? 'grabbing'
          : isResizing
            ? 'nwse-resize'
            : 'grab',

      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={(e) => {
        // getThItemValue(e, id);
        deletebuttonshow()
        toggleBorder();
        props.onComponentClick(props.keyValueId);
      }}
    >
      <div
        style={{ width: '100%', height: '1px', borderBottom: '1px solid black' }}
        onClick={toggleBorder}
      ></div>
      <button
        className="component-delete-web-template"
        id={props.keyValueId + "delete"}
        onClick={() => props.handleRemoveItem(props.keyValueId)}
      >
        <i className="fa-solid fa-trash"></i>
      </button>
    </div>
  );
};

export default HorizontalLine;
