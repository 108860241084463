import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef } from "react";
import 'react-quill/dist/quill.snow.css';
import PreviousOrder from "../../Dashboard/PreviousOrder";
import MainButton from "../../Props/MainButton";

function ClinicMaster() {
    /***********{title}***************/
    useState(() => {
        document.title = 'Clinic Master';
    }, []);


    ////************{Image add}******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    ///*****************Onboarding code Open************************///

    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };


    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    /***********Table Body code*****************/
    const [isTableVisible, setTableVisibility] = useState(false);
    const handleSearchClick = () => {
        setTableVisibility(true);
    };
    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Clinic Master</h2>
                            </div >
                            {/* *******************Top Buttons****************** */}

                            <MainButton />
                        </div>
                        {/* *******************Modal Box End****************** */}
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">  
                                                <div className="company-info-top-left Purchase-invoice-table content-clinic-height">
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", marginTop: "8px" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input1Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required >
                                                                            <option>Diagnosis </option>
                                                                            <option>Examination</option>
                                                                            <option>Complaints</option>
                                                                            <option>Advice</option>
                                                                            <option>Allergy</option>
                                                                            <option>Medicine</option>

                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Master Type</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input2Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Name</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1 pur-body-width clinic-text-area">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", margin: "1%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" id="PartyInput" style={{ margin: "0px 5px", width: "100%" }}>
                                                                        <textarea className="form-control inputEffect modify_im_input mode_limit mode_category  stock_padding" ref={input3Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input4Ref)} id="PartySelectBox" required style={{ fontSize: "14px", width: "99%" }}>
                                                                        </textarea>

                                                                        <label id="input1" className="labelEffect1">Description</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <PageTabs />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-info-top-right-clinic control_flex_m1 ">
                                                <div className="Recipe_change_Item ">
                                                    <div className="stock-recipe-MGM">
                                                        <div className="input-box-Im input-height2">
                                                           
                                                            <div className="input-containerEffect mt-1 input-clinic-search" style={{ margin: "1%" }}>
                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                <label id="input1" className="labelEffect1">Search Text</label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-Imo1 input-flex-clinic">
                                                            <div className="button-m5">
                                                                <button className="NewRecipe-button-main-search" onClick={handleSearchClick}>Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                                        <thead textAlign="center" className="thead_scroll_orderlist table-tbody" >
                                                            <tr className="">
                                                                <th className="td_col6 td_width_sms">
                                                                    <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                                </th>
                                                                <th className="td_col6">
                                                                    <div className="table_head_mmb1" style={{ textAlign: "start" }}>Name</div>
                                                                </th>

                                                                {/* <th className="td_col6 td_width_sms">Dlt</th> */}
                                                                <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                                                <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                                            </tr>
                                                        </thead>
                                                        {isTableVisible && (
                                                            <tbody className="tbody_body_m1">
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">1</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Anniversary Sms </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>


                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">2</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>LensExpiry Sms </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">3</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>CRM Loyalty </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">4</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Delivered </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">5</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Patient Registration </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">6</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Rx Order </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">7</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Sale Challan</td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table_tbody_Order_List">
                                                                    <td className="td_col6">8</td>
                                                                    <td className="td_col6" style={{ textAlign: "start" }}>Sale Order </td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1"><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                            </div>

                                            {/****************************************************** Product Table **************************************************************************/}
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default ClinicMaster;

