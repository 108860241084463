
import DateTime from "../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../Components/PageTabs";

function ModalOrderList() {
    /***********title***************/
    useState(() => {
        document.title = 'Order List';
    }, []);

    //************Check box************/ /
    const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,

    });

    const handleCheckboxChange = (checkboxName) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxName]: !prevCheckboxes[checkboxName],
        }));
    };

    const handleSelectAll = () => {
        const allChecked = Object.values(checkboxes).every((isChecked) => isChecked);

        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {};
            for (const checkboxName in prevCheckboxes) {
                updatedCheckboxes[checkboxName] = !allChecked;
            }
            return updatedCheckboxes;
        });
    };


    //************End Check box************/ /

    return (
        <div>
            < PageTabs />
          
            <div>
                < DateTime />
            </div>
            <div className="content_box">

                <div className="flex-center">
                    <label style={{ color: "darkblue", cursor: "pointer", fontWeight: "600" }}>Party Name: Ramesh Kumar Sahu</label>
                </div>
                <hr />
                <div className="col-sm-12">
                    <div className="table-resposive" >
                        <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                            <thead textAlign="center" className="thead_scroll_orderlist table-tbody" >
                                <tr className="">
                                    <th className="td_col6"> <div className="table_head_mmb ">
                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Bill No</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Date</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Bill Type</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Blnc Qty</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Total Amt</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Status</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="Check_Box_head">
                                        <div><input type="checkbox" className="check_box_order" checked={Object.values(checkboxes).every((isChecked) => isChecked)}
                                            onChange={handleSelectAll} name="" id="" /></div>
                                    </div> </th>

                                </tr>
                            </thead>
                            <tbody className="tbody_body_m1">
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}><input type="checkbox" className="check_box_order" checked={checkboxes.checkbox1}
                                        onChange={() => handleCheckboxChange('checkbox1')} name="" id="" /></td>

                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">2</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 2</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox2}
                                        onChange={() => handleCheckboxChange('checkbox2')} name="" id="" /></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">3</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 3</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox3}
                                        onChange={() => handleCheckboxChange('checkbox3')} name="" id="" /></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">4</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 4</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox4}
                                        onChange={() => handleCheckboxChange('checkbox4')} name="" id="" /></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">5</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 5</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox5}
                                        onChange={() => handleCheckboxChange('checkbox5')} name="" id="" /></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">6</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 6</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox6}
                                        onChange={() => handleCheckboxChange('checkbox6')} name="" id="" /></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">7</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>ORD : 7</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>INCLUSIVE GST(L)</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>500</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Pending</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox7}
                                        onChange={() => handleCheckboxChange('checkbox7')} name="" id="" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="input-order-buttons">
                    <div className="input-remark-left01 input_RMK">
                        <div className="input-box-vo1 Ord_Box_OD">
                            <input className="modify_im_input mode_category input_odr mid-size-orderlist" type="text" required />
                        </div>
                        <div className="input-box-vo1 Ord_Box_OD">
                            <input className="modify_im_input mode_category input_odr mid-size-orderlist" type="date" required />
                            <label className="modify_lbl_text3">Date From</label>
                        </div>
                        <div className="input-box-vo1 Ord_Box_OD">
                            <input className="modify_im_input mode_category input_odr mid-size-orderlist" type="date" required />
                            <label className="modify_lbl_text3">Date To</label>
                        </div>
                        <div className="button-m5 btn_ord_pad">
                            <button className="search-button-main-search">Search
                                <i id="step-2" className="fa-solid fa-thin fa-magnifying-glass im-icon-white"></i>
                            </button>
                        </div>
                        <div className="button-m5 btn_ord_pad">
                            <button className="search-button-main">Add
                                <i id="step-2" className="fa-solid fa-square-plus im-icon-2"></i>
                            </button>
                        </div>
                        <div className="button-m5 btn_ord_pad">
                            <button className="search-button-main">Cancel
                                <i id="step-2" className="fa-solid fa-regular fa-xmark im-icon-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModalOrderList;
