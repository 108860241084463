import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import DeliveryIncentiveApi from "../../../API/APIServices/DeliveryIncentive";
import DataTable from "../TableMaster/DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import DeliveryPointNameAPI from "../../../API/APIServices/DeliveryPointName";
import ToolTipMaster from "../../Props/ToolTipMaster";

function DeliveryPointName() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
    } = useContext(MyContext);

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Delivery Point Name Master';
    }, []);

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });
    const [inputValues, setInputValues] = useState({
        id: 0,
        name: "",
        description: "",
        isDefault: false,
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await DeliveryPointNameAPI.updateDeliveryPointName(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("Error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await DeliveryPointNameAPI.insertDeliveryPointName(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({
                name: "",
                description: "",
                isDefault: false,
                isActive: true
            });
        }
    }
    // ********************************************List Part ******************************
    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "DELIVERY POINT NAME", accessor: "name" },
        { Header: "DESCRIPTION", accessor: "description" },
        { Header: "DEFAULT", accessor: "isDefault" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Delivery Point Name" ref={input1} />, accessor: "DeliveryPointName" },
        { Header: <input type="text" placeholder="Description" ref={input2} />, accessor: "Description" },
        { Header: <input type="text" placeholder="Default" ref={input3} />, accessor: "Default" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await DeliveryPointNameAPI.getDeliveryPointNames();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    name: item.name,
                    description: item.description,
                    isDefault: item.isDefault ? "Yes" : "No",
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editDeliveryPoint(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteDeliveryPoint(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editDeliveryPoint = async (id) => {
        setEdit(true)
        const dataById = await DeliveryPointNameAPI.getDeliveryPointNameById(id);
        if (dataById) {
            try {
                const editData = await dataById; // Assuming props.editData is a Promise
                console.log("dataaaa" + editData);
                setInputValues({
                    id: editData.id,
                    name: editData.name,
                    description: editData.description,
                    isDefault: editData.isDefault,
                    isActive: editData.isActive,
                });
                // setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteDeliveryPoint = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }

    const DeleteDeliveryPointConfirm = async () => {
        setLoading(true);
        try {
            const val = await DeliveryPointNameAPI.deleteDeliveryPointName(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    useEffect(() => {
        input1Ref.current.focus();
    }, []);

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));
        // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        // console.log(inputValues);
    };

    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Delivery Point Name Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1 ">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Delivery Point Name Master
                                                <div className="input-box-checkbox2 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <ToolTipMaster isActiveInactive={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div style={{ display: "flex" }} className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} value={inputValues.name}
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} name="name" required />
                                                            <label className="modify_p_text2">Delivery Point Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div style={{ display: "flex" }} className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} value={inputValues.description}
                                                                onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                                ref={input2Ref} name="description" required />
                                                            <label className="modify_lbl_text3">Description</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div style={{ display: "flex" }} className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select
                                                                className="modify_im_input mode_category1"
                                                                onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input3Ref}
                                                                onChange={handleInputChange}
                                                                value={inputValues.isDefault}
                                                                name="isDefault"
                                                                required
                                                            >
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Is Default</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Incentive Details</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteDeliveryPointConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Delivery Incentive is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Delivery Incentive is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default DeliveryPointName;
