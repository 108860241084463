import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
//import UserApi from "../../../API/APIServices/User";
import AccountGroupApi from "../../../API/APIServices/AccountGroup";
import MainButton from "../../Props/MainButton";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import MiscellaneousMaster from "../AccountMaster/MiscellaneousMaster";
import { useNavigate } from 'react-router-dom';
//import ToolTipMaster from "../../Props/ToolTipMaster";

function AccountGroupMaster(props) {
  //*************************title Start***********************//
  const [loading, setLoading] = useState(false);
  const [isModalOpen4, setModalOpen4] = useState(false);
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

  const navigate = useNavigate();

  useState(() => {
    document.title = "Account Group Master";
  }, []);

  const [controlType] = useState(168)
  const [mainData, setMainData] = useState([]);
  const [BalanceSheet, setBalanceSheet] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const val = await AccountGroupApi.GetAccountGroups();
        setMainData(val);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    const fetchdata3 = async () => {
      try {
        const controlType = 168;
        const balanceSheet = await MiscellaneousMasterApi.GetMiscellaneousMaster(controlType);
        setBalanceSheet(balanceSheet);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata3();
  }, [isModalOpen4]);

  //**************************title End***********************//
  /************Enter Button Click******************/

  // const [inputs, setInputs] = useState({
  //     input1: '',
  //     input2: '',
  //     input3: '',
  //     input4: '',
  //     input5: '',
  // });

  const inputRefs = {
    input1: useRef(null),
    input2: useRef(null),
    input3: useRef(null),
    input4: useRef(null),
    input5: useRef(null),
  };

  const inputOrder = ["input1", "input2", "input3", "input4", "input5"];
  let currentInputIndex = 0;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //   const currentInputName = inputOrder[currentInputIndex];
      const nextInputIndex = (currentInputIndex + 1) % inputOrder.length;
      const nextInputName = inputOrder[nextInputIndex];
      inputRefs[nextInputName].current.focus();
      currentInputIndex = nextInputIndex;
    }
  };

  //************************Show Hide Start***********************//

  const [isInputVisiblam1, setInputVisiblam1] = useState(false);
  const handleToggleChangam1 = () => {
    setInputVisiblam1(!isInputVisiblam1);
  };

  //*************************Show Hide End***********************//

  //**************************modal box Start**********************//

  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };


  const ShowModal4 = () => {
    setModalOpen4(true);
  };
  const HideModal4 = () => {
    setModalOpen4(false);
  };
  //****************************modal box End******************************//

  const [isCheckUnderGroup, setIsCheckUnderGroup] = useState(true);
  const [isTypeMater, setIsTypeMaster] = useState(false);

  const handleselectchange = (e) => {
    const val = e.target.value;
    if (val === "true") {
      setIsTypeMaster(true);
      setIsCheckUnderGroup(false);
    } else {
      setIsTypeMaster(false);
      setIsCheckUnderGroup(true);
    }

    // Call handleInputChange after setting state
    handleInputChange(e);
  };

  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  const [inputValues, setInputValues] = useState({
    id: "",
    name: "",
    printName: "",
    primaryGroup: "",
    underGroup: "",
    groupType: "",
    isActive: true,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    printName: "",
  });

  useEffect(() => {
    //console.log(props.data);
    const fetchData = async () => {
      try {
        if (props.data) {
          try {
            const data = await props.data; // Assuming props.editData is a Promise
            console.log(data.groupTypeId);
            setInputValues({
              id: data.id || "",
              name: data.name || "",
              underGroup: data.parentGroupId || "",
              printName: data.printName || "",
              primaryGroup: data.primaryGroup || "",
              groupType: data.groupTypeId || "",
              isActive: data.isActive,
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.data) {
          const data = await props.data; // Assuming props.data is a Promise
          console.log(data.primaryGroup);

          if (data.primaryGroup === true) {
            setIsTypeMaster(true);
            setIsCheckUnderGroup(false);
          } else {
            setIsCheckUnderGroup(true);
            setIsTypeMaster(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.data]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      printName: name === 'name' ? value : prevInputValues.printName,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name", "printName"];

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    // console.log(inputValues);
  };

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      if (props.modelOpen === true) {
        const isSuccess = await AccountGroupApi.updateAccountGroup(inputValues);
        // console.log(isSuccess);
        if (isSuccess) {
          OpenAlertBoxEdit();
          setLoading(false);
          props.fetchData();
          // props.modelOpen(false);
          props.setSliderCallBack(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = AccountGroupApi.InsertAccountGroup(inputValues);
        if (isSuccess) {
          // alert("Insert done");
          setLoading(false);
          OpenAlertBox();
          setInputValues({
            id: "",
            name: "",
            printName: "",
            primaryGroup: "",
            underGroup: "",
            groupType: "",
            isActive: true,
          });
        } else {
          alert("Error inserting data");
        }
      }
    }
  };

  return (
    <div>
      <PageTabs
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
        locationTab={"/AccountGroupMaster"}
      />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Account Group Master </h2>
        </div>
        {/* *******************Save Button Start****************** */}
        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
          PageName="Masters"
        />
        {/* *******************{Modal Box Open}****************** */}
        <div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Account Master</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam1}
                            checked={isInputVisiblam1}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Address Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im td-1">
                      General Details
                      <div className="input-box-checkbox1 ">
                        <div className=" td-2">
                          <div className="cl-toggle-switch td-3">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                onChange={handleInputChangeActive}
                                name="isActive"
                                checked={inputValues.isActive}
                                value={inputValues.isActive}
                              />
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <input
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            name="name"
                            ref={inputRefs.input1}
                            type="text"
                            required
                            id="GroupName"
                            value={inputValues.name}
                          // value={props.data.name}
                          />

                          <label className="modify_p_text2">
                            Group Name
                            <span className="StarClr">
                              *
                              {errorMessages.name && (
                                <label className="errorMessage">
                                  {errorMessages.name}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <input
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            name="printName"
                            ref={inputRefs.input2}
                            type="text"
                            value={inputValues.printName}
                            // value={props.data.printName}
                            required
                            id="PrintName"
                          />
                          <label className="modify_p_text2">
                            Print Name
                            <span className="StarClr">
                              *
                              {errorMessages.printName && (
                                <label className="errorMessage">
                                  {errorMessages.printName}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <select
                            className="modify_im_input mode_category"
                            onChange={handleselectchange}
                            onKeyPress={handleKeyPress}
                            name="primaryGroup"
                            type="text"
                            required
                            value={inputValues.primaryGroup}
                          // value={props.data.primaryGroup}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Primary Group
                          </label>
                        </div>
                      </div>
                      {isCheckUnderGroup && (
                        <div className="input-box-Im">
                          <div className="input-box-m2">
                            <select
                              className="modify_im_input mode_category"
                              onChange={handleInputChange}
                              onKeyPress={handleKeyPress}
                              id="UnderGroup"
                              name="underGroup"
                              type="text"
                              required
                              value={inputValues.underGroup}
                            >
                              <option>Select Under Group</option>
                              {mainData.map((data) => (
                                <option key={data.id} value={data.id}>
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <label className="modify_lbl_text3">
                              Under Group
                            </label>
                          </div>
                        </div>
                      )}
                      {isTypeMater && (
                        <div className="input-box-Im ">
                          <div className="input-box-m2">
                            <select
                              className="modify_im_input mode_category"
                              onChange={handleInputChange}
                              onKeyPress={handleKeyPress}
                              ref={inputRefs.groupType}
                              type="text"
                              name="groupType"
                              value={inputValues.groupType}
                              required
                            >
                              <option value="">Select B/L Type</option>
                              {BalanceSheet.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <label className="modify_lbl_text3">
                              B/L Types
                            </label>
                          </div>
                          <div onClick={ShowModal4}>
                            <i className="fa-solid fa-circle-plus dm_4"></i>
                          </div>
                        </div>
                      )}
                      <div></div>
                      {isModalOpen4 && (
                        <div onClick={HideModal4} className="modal-back">
                          <div
                            onClick={handleModalClick}
                            className="OpticalField-modal increase_modal"
                          >
                            <div style={{ padding: "15px" }}>
                              <div className="Modal-Display">
                                <label
                                  className="close_modal"
                                  onClick={HideModal4}
                                ></label>
                              </div>
                              {/* <hr /> */}
                              <MiscellaneousMaster controlType={controlType} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Account Group is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Account Group is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default AccountGroupMaster;
