import React, { useState, useEffect } from "react";
import './KotStylesheet.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import OrderApi from "../../API/APIServices/Order";
import AppHeaderDetail from "../AppHeaderDetail";
import { useNavigate } from "react-router-dom";

const PickupList = () => {

    const [tableOrder, setTableOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true)
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const filterData = [
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Done"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Cancel"
                },
                {
                    fieldName: "o2KotStatus",
                    operatorName: "Equal",
                    compareValue: "Pickup"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Returned"
                }
            ]
            const data = await OrderApi.getOrderListFilter(filterData);
            console.log("data");
            console.log(data);
            setTableOrder(data);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    function convertTo12HourFormat(timeString) {
        // Split the time string into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        // Create a new Date object to convert the time
        const date = new Date();
        date.setHours(hours, minutes, seconds);

        // Get the hours and minutes in 12-hour format
        const hours12 = (date.getHours() + 11) % 12 + 1; // Convert to 12-hour format
        const minutesStr = ('0' + date.getMinutes()).slice(-2); // Add leading zero if needed

        // Determine if it's AM or PM
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        // Return the formatted time string
        return `${hours12}:${minutesStr} ${ampm}`;
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <AppHeaderDetail title={`Table Pickup List`} setLoading={setLoading} fetchData={fetchData} />
            <div style={{ backgroundColor: "ghostwhite", marginTop: "55px", height: "92vh" }}>
                <div className="p-1">
                    {tableOrder !== null && tableOrder.length > 0 ? (
                        tableOrder
                            .filter((data, index, self) =>
                                index === self.findIndex((t) => (
                                    t.ord1Id === data.ord1Id || t.ord2Id === data.ord2Id
                                ))
                            )
                            .map((data, index) => (
                                <Link to={`/PickupItemList?ord1Id=${data.ord1Id}&ord2Id=${data.ord2Id}`} style={{ textDecoration: 'none' }} key={index}>
                                    <div className="listDiv" style={{ backgroundColor: "white" }}>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order No : &nbsp;</label>
                                                <label className="ans">{data.orderNo}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Status Type : &nbsp;</label>
                                                <label className="ans">{"Pickup"}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order Date : &nbsp;</label>
                                                <label className="ans">{moment(data.orderDate).format('DD-MM-YYYY')}</label>
                                            </div>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Order Time : &nbsp;</label>
                                            <label className="ans">{convertTo12HourFormat(data.orderTime)}</label>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Confirm Date:&nbsp;</label>
                                                <label className="ans">{moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                    ) : (
                        <p style={{ textAlign: "center" }}>No Order Available</p>
                    )}
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default PickupList;
