// import ListSaveButton from "../../Components/ListSaveButton";
import DataTable from "../TableMaster/DataTable";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import TransportMaster from "./TransportMaster";
import { useContext, useEffect, useState } from "react";
import Transporter from "../../../API/APIServices/Transporter";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function TransportMasterList() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    UserDetails,
    maskMobileNo
  } = useContext(MyContext);

  const navigate = useNavigate();
  const [mainData, setMainData] = useState([]);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSlider = (value) => {
    setSliderOpen(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await Transporter.GetTransporterFilter();
      // console.log(val.length);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          name: item.name,
          transporterId: item.transporterId,
          transporterGSTIN: item.transporterGSTIN,
          transportationMode: item.transportationMode,
          phone: UserDetails.canLst3DgtNo ? maskMobileNo(item.phone) : item.phone,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);
  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await Transporter.deleteTransporter(getdeleteId);
      if (Boolean(val) === true) {
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState("");

  const Edit = (id) => {
    const data = Transporter.GetTransporterById(id);
    // console.log(data);
    setEditData(data);
    toggleSlider(true);
  };

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "NAME", accessor: "name" },
    { Header: "Transporter Id", accessor: "transporterId" },
    { Header: "Transporter GSTIN", accessor: "transporterGSTIN" },
    { Header: "Transportation Mode", accessor: "transportationMode" },
    { Header: "Phone", accessor: "phone" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    {
      Header: <input type="text" placeholder="Name" ref={input1} />,
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Transporter Id" ref={input2} />,
      accessor: "Transporter Id",
    },
    {
      Header: (
        <input type="text" placeholder="Transporter GSTIN" ref={input3} />
      ),
      accessor: "Transporter GSTIN",
    },
    {
      Header: (
        <input type="text" placeholder="Transportation Mode" ref={input4} />
      ),
      accessor: "Transportation Mode",
    },
    {
      Header: <input type="text" placeholder="Phone" ref={input5} />,
      accessor: "phone",
    },
  ];
  return (
    <div className="">
      <PageTabs
        locationTab={"/TransportMaster"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Transporter List</h2>
          <TableButton PageCreate={"/TransportMaster"} PageName="Masters" />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />
      </div>
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <TransportMaster
            data={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
          />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default TransportMasterList;
