import React, { useState } from 'react'
import Dropdown from './Dropdown'
import InputBox from './InputBox'

const Logistics = () => {
    const [inputValues, setInputValues] = useState({
        enableLogisticsParty: '',
        logisticsId: '',
        logisticsPassword: ''
    });
    console.log(inputValues)

    const options = [
        { label: "Shiprocket", value: "Shiprocket" },
        { label: "No", value: 0 }
    ]

    return (
        <div>
            <div className='heading-setting'>Logistics Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <Dropdown label={"Enable Logistics Party"} options={options} selectedValue={inputValues.enableLogisticsParty} onChange={(newvalue) => setInputValues({ ...inputValues, enableLogisticsParty: newvalue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Logistics Id"} value={inputValues.logisticsId} onChange={(newvalue) => setInputValues({ ...inputValues, logisticsId: newvalue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Logistics Password"} value={inputValues.logisticsPassword} onChange={(newvalue) => setInputValues({ ...inputValues, logisticsPassword: newvalue })} />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Logistics
