import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ChildCategory = (props) => {
  const [isLink2Visible, ] = useState(props.showLink2);

  return (
    <Link to={props.Link} onClick={props.onClick} className="yes-bm-child-category-box ">
    <i className="fa-solid fa-caret-right floting-arrow"></i>
    {props.heading}
  
    {isLink2Visible && (
        <Link to={props.Link2} className="fixed-arrow">
          <i className="fa-solid fa-plus"></i>
        </Link>  
      )}
  
  </Link>
  )
}

export default ChildCategory