import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

function ItemAttributeList(props) {
  /************Category box****************/
  const [rows, setRows] = useState(Array(20).fill({}));
  const [headingValue, setHeadingValue] = useState([]);
  const [AttributeData, setAttributeData] = useState([]);

  const navigate = useNavigate();

  const [data, setData] = useState([
    {
      sn: 1,
      itmId: "",
      qty: "",
      purchasePrice: "",
      salePrice: "",
      mrpPrice: "",
      attributes: "",
      attrGrpId: ""
    },
  ]);

  const fetchData = (data) => {
    console.log(props.isAttributeDetails)
    console.log(data)
    const length = (data[0].attributes).length;
    for (var index = 0; index < length; index++) {
      if (data[0]?.attributes[index]?.attrGrpId !== undefined) {
        handleAttributeChange({
          target: { value: data[0].attributes[index].attrGrpId }
        }, index);
        //console.log(length, +"---"+ index, "data", data[0].attributes[index].attrGrpId);
      }
    }
  }

  useEffect(() => {
    // Check if the attribute is not undefined and call handleAttributeChange
  }, [props.isAttributeDetails]); // Include 'index' as a dependency

  const [updateValue, setUpdateValue] = useState(props.updateData);
  const [TempValue, setTempValue] = useState(props.AttributeListTemp);

  useEffect(() => {
    try {
      const AttributeDataList = props.AttributeList;

      if (updateValue && updateValue.length > 0) {
        // Use map to create an array of objects based on the number of updateValue items
        const updatedData = updateValue.map((updateItem, index) => ({
          sn: updateItem.sn,
          itmId: updateItem.itmId,
          qty: updateItem.qty,
          purchasePrice: updateItem.purchasePrice,
          salePrice: updateItem.salePrice,
          mrp: updateItem.mrp,
          attrGrpId: updateItem.attrGrpId,
          attributes:
            updateItem.attributes.map((attribute, childIndex) => ({
              // Map attributes here
              // Example: Adjust attribute values or structure based on your specific logic
              attrGrpId: attribute.attrGrpId,
              attrId: attribute.attrId,
              attrName: attribute.attrName,
              index: childIndex,
            })),
          // You might want to adjust the Attributes value based on your specific logic
        }));
        //console.log(updatedData);
        setData(updatedData);
        fetchData(updatedData)
      } else {
        if (TempValue.length === 0) {
          setData([
            {
              sn: 1,
              itmId: "",
              qty: "",
              purchasePrice: "",
              salePrice: "",
              mrp: "",
              attributes: AttributeData,
              attrGrpId: ""
            },
          ]);
          //console.log(data)

        } else {
          const TempValueData = TempValue.map((updateTemp, index) => ({
            sn: updateTemp.sn,
            itmId: updateTemp.itmId,
            qty: updateTemp.qty,
            purchasePrice: updateTemp.purchasePrice,
            salePrice: updateTemp.salePrice,
            mrp: updateTemp.mrp,
            attrGrpId: updateTemp.attrGrpId,
            attributes:
              updateTemp.attributes.map((attribute, childIndex) => ({
                // Map attributes here
                // Example: Adjust attribute values or structure based on your specific logic
                attrGrpId: attribute.attrGrpId,
                attrId: attribute.attrId,
                attrName: attribute.attrName,
                index: childIndex,
              })),
            // You might want to adjust the Attributes value based on your specific logic
          }));
          setData(TempValueData);
          fetchData(TempValueData)
        }
      }
    }
    catch (error) {
      console.log(error);
    }
    // }
  }, [updateValue, TempValue, props.isAttributeDetails]);

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const [copiedValue, setCopiedValue] = useState("");

  const handleCopyRow = (id, index) => {
    if (copiedValue === null || copiedValue === "") {
      const rowToCopy = data[index];
      const copiedRow = { ...rowToCopy };
      setCopiedValue(copiedRow);
    } else {
      handlePasteRow(id, index);
    }
  };

  const handlePasteRow = (currentId, currentIndex) => {
    const updatedData = [...data];
    const copiedRow = { ...copiedValue };
    copiedRow.id = currentId;

    updatedData[currentIndex] = copiedRow;
    setData(updatedData);
    //console.log(copiedValue);
  };
  const handleInputChange = (index, newValue, namefield) => {
    const updatedData = [...data];
    updatedData[index][namefield] = newValue;
    setData(updatedData);
    setCopiedValue("");
    //console.log(data);
  };

  const handleInputChangeForAttr = (index, parentIndex, e) => {
    // Assuming e.target.value is the innerItem object
    const AttributeDataList = props.AttributeList;
    const newdata = e.target.value;
    // console.log(index)

    //console.log(AttributeDataList[0].attributeChilds[0].attrId);
    // var attributeId=  AttributeDataList.find(item => item.attributeChilds.attributeId === Number(newdata))
    var attributeData = AttributeDataList.find((item) =>
      item.attributeChilds.some((child) => child.attrId === Number(newdata))
    );

    console.log("AttributeDataList", attributeData);

    var attributeParentIndex = AttributeDataList.findIndex((item) =>
      item.attributeChilds.some((child) => child.attrId === Number(newdata))
    );

    // Assuming attributeData is found and it's not undefined
    if (attributeData) {
      var attributeChildIndex = attributeData.attributeChilds.findIndex(
        (child) => child.attrId === Number(newdata)
      );
    } else {
      // Handle the case where no matching parent is found
      console.log("No matching attribute data found.");
    }

    setData((prevData) => {
      const newData = [...prevData]; // Shallow copy of the outer array

      if (
        newData[parentIndex] &&
        Array.isArray(newData[parentIndex].attributes)
      ) {
        // Make sure the object at parentIndex and its Attributes array are copied deeply
        newData[parentIndex] = {
          ...newData[parentIndex],
          attributes: [...newData[parentIndex].attributes], // Shallow copy of the attributes array
        };

        // Update AttrId and AttrName of the object at the specified index
        newData[parentIndex].attributes[index] = {
          ...newData[parentIndex].attributes[index],
          index: index + 1,
          attrGrpId: attributeData.attributeId, //attributeData.attributeChilds[attributeChildIndex].attrGrpId,
          attrId: attributeData.attributeChilds[attributeChildIndex].attrId,
          attrName: attributeData.attributeChilds[attributeChildIndex].attrName,
        };
        console.log("AttributeDataList", newData);
      } else {
        // Handle the case where newData[parentIndex].attributes is not an array
        console.error("Attributes array is not defined or is not an array.");
      }
      return newData; // Returning a new array reference to trigger a re-render
    });

    //console.log(data);
  };

  useEffect(() => {
    //console.log(data);
    if (data && data.length > 0) {
      props.getAttributeList(data);
      props.setAttributeListTemp(data);
    }
  }, [data]);

  // Log AttributeData in a separate useEffect block

  //   console.log(data);

  const handleDeleteRow = (index) => {
    // Copy the data array
    const newData = [...data];

    // Remove the item at the specified index
    newData.splice(index, 1);

    // Update the state with the new data
    setData(newData);
  };

  const addRow = () => {
    // Generate a unique id for the new item (you can use a library like uuid)
    const newId = Math.max(...data.map((item) => item.id), 0) + 1;

    // Create a new item
    const newItem = {
      sn: data.length + 1,
      itmId: "",
      qty: "",
      purchasePrice: "",
      salePrice: "",
      mrp: "",
      attributes: AttributeData,
      attrGrpId: ""
    };

    // Update the state by appending the new item
    setData((prevData) => [...prevData, newItem]);
  };

  const [selectedAttributes, setSelectedAttributes] = useState(
    Array(props.AttributeList.length).fill()
  );

  const activeAttributes = props.AttributeList.filter(
    (attribute) => attribute.isActive === true
  );

  const [selectedOptions, setSelectedOptions] = useState(
    Array(activeAttributes.length).fill([])
  );

  // Function to handle the change of a specific attribute
  const handleAttributeChange = (event, index) => {
    const { value } = event.target;

    setData((prevData) => {
      const newData = prevData.map((item, idx) => {
        //if (idx === index) {
        //console.log(item)
        return {
          ...item,
          //attrGrpId: value,
          attributes: item.attributes.map((attribute, i) => {
            if (i === index) {
              return {
                ...attribute,
                attrGrpId: value,
              };
            }
            else {
              return {
                ...attribute,
              };
            }
          }),
        };
        //}
        // return item;
      });
      console.log("newData", newData)
      return newData;
    });

    const newSelectedAttributes = [...selectedAttributes];
    newSelectedAttributes[index] = event.target.value;
    setSelectedAttributes(newSelectedAttributes);

    //console.log(props.AttributeList);
    var Attributedata = props.AttributeList;

    // Fetch data or use existing data based on the selected id
    const selectedAttribute = Attributedata.find(
      (item) => item.attributeId === Number(event.target.value)
    );

    // Log selectedAttribute for debugging
    // console.log("Selected Attribute:", selectedAttribute);
    const options = selectedAttribute
      ? selectedAttribute.attributeChilds || []
      : [];

    // Update selected options based on the chosen attribute
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = options;
      return newOptions;
    });
  };

  return (
    <div>
      <div className="">
        <table className="table-scrol2" style={{ overflow: "scroll", width: "155%" }}>
          <thead className="table-tbody">
            <tr
              className="table-tr2"
              style={{ backgroundColor: "rgba(21, 50, 125, 0.62)" }}
            >
              <th className="mb_0 tb_1 c-w" style={{ width: "45px" }}>
                <label className="c-w">SNo</label>
              </th>
              <th className=" mb_1 tb_1 c-w">
                <div className="c-w">Qty(?)</div>
              </th>
              <th className="mb_2 tb_1 c-w">
                <div className="c-w">Pur Price</div>
              </th>
              <th className="Attrib_3 c-w">
                {" "}
                <div className="thm2 tb_1 c-w">
                  <label className="c-w">Sal Price</label>
                </div>{" "}
              </th>
              <th className=" mb_4 tb_1 c-w">
                {" "}
                <div className="c-w">MRP</div>
              </th>
              {/* Assuming props.AttributeList is an array of objects with attributeId and attributeName */}
              {props.AttributeList.filter((attribute) => attribute.isActive)
                .sort((a, b) => a.priority - b.priority)
                .map((attribute, index) => (
                  //console.log("Attribute", data),
                  <th key={index} className="mb_5 tb_1 c-w">
                    <div className="c-w">
                      <select
                        className="itemattribute-headre-select"
                        //value={data[index]?.attrGrpId}
                        value={data[0]?.attributes[index]?.attrGrpId}
                        onChange={(event) =>
                          handleAttributeChange(
                            event,
                            index,
                            attribute.attributeName
                          )
                        }
                      >
                        <option value="">Select Variant</option>
                        {props.AttributeList.map((item, i) => (
                          <option key={i} value={item.attributeId}>
                            {item.attributeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="ContainerAttributeTable">
            {data.map((row, index) => (
              <tr className="table-tr2 hover-tr" key={index}>
                <td class="mb_0" style={{ textAlign: "center", width: "50px" }}>
                  <div style={{ width: "47px" }} className="flex">
                    <button
                      className="copyBtn-chaitan"
                      onClick={() => handleCopyRow(row.Sno, index)}
                    >
                      Copy
                    </button>
                    <label className="mt-1">{index + 1}</label>
                  </div>
                </td>
                <td className="mb_1">
                  <input
                    className="table-input-13 pd_1"
                    type="number"
                    name={`code-${index}`}
                    value={row.qty}
                    onChange={(e) =>
                      handleInputChange(index, e.target.value, "qty")
                    }
                  />
                </td>
                <td class="mb_2">
                  <input
                    className="table-input-13 pd_1 "
                    type="number"
                    name={`Group-${index}`}
                    value={row.purchasePrice}
                    onChange={(e) =>
                      handleInputChange(index, e.target.value, "purchasePrice")
                    }
                  />
                </td>
                <td class="Attrib_3">
                  <input
                    className="table-input-13 pd_1"
                    type="number"
                    name={`ItemName-${index}`}
                    value={row.salePrice}
                    onChange={(e) =>
                      handleInputChange(index, e.target.value, "salePrice")
                    }
                  />
                </td>
                <td class="mb_4">
                  <input
                    className="table-input-13 pd_1"
                    type="number"
                    name={`Description-${index}`}
                    value={row.mrp}
                    onChange={(e) =>
                      handleInputChange(index, e.target.value, "mrp")
                    }
                  />
                </td>

                {selectedOptions.map((options, i) => (
                  <td className="mb_5" key={i}>
                    <select
                      onChange={(e) => handleInputChangeForAttr(i, index, e)}
                      className="itemattribute-select"
                      value={data[index]?.attributes[i]?.attrId}
                    >
                      <React.Fragment>
                        <option>Select Options</option>
                        {options.map((innerItem, innerIndex) => (
                          <option
                            key={innerIndex}
                            value={innerItem.attrId} // Set the innerItem as the value
                          >
                            {innerItem.attrName}
                          </option>
                        ))}
                      </React.Fragment>
                    </select>
                  </td>
                ))}

                <td class="" style={{ textAlign: "center", width: "40px" }}>
                  <button
                    className="btn-table1"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <i class="fa-solid fa-trash delete-btn"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-down-main">
        <div className="table-down-m1">
          <button className="btn-table2" onClick={addRow}>
            <i class="fa-solid fa-square-plus" style={{ fontSize: "20px" }}></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ItemAttributeList;











// import React, { useState } from "react";
// import { useEffect } from "react";

// function ItemAttributeList(props) {
//   /************Category box****************/
//   const [rows, setRows] = useState(Array(20).fill({}));
//   const [headingValue, setHeadingValue] = useState([]);
//   const [AttributeData, setAttributeData] = useState([]);
//   const [data, setData] = useState([
//     {
//       sn: 1,
//       itmId: "",
//       qty: "",
//       purchasePrice: "",
//       salePrice: "",
//       mrpPrice: "",
//       attributes: "",
//     },
//   ]);

//   useEffect(() => {
//     // Check if the attribute is not undefined and call handleAttributeChange
//     for (var index = 0; index < data.length; index++) {
//       if (data[index]?.attributes[index]?.attrGrpId !== undefined) {
//         handleAttributeChange({
//           target: { value: data[index].attributes[index].attrGrpId }
//         }, index);
//       }
//     }

//   }, [data]); // Include 'index' as a dependency

//   const [updateValue, setUpdateValue] = useState(props.updateData);
//   const [TempValue, setTempValue] = useState(props.AttributeListTemp);

//   // To log the updated `data`, you can use another useEffect
//   useEffect(() => {
//     console.log(updateValue);
//     console.log(data);
//     console.log(TempValue)
//   }, [updateValue]); // This useEffect will run every time `data` changes

//   useEffect(() => {
//     console.log(data);
//     const AttributeDataList = props.AttributeList;

//     if (updateValue && updateValue.length > 0) {
//       // Use map to create an array of objects based on the number of updateValue items
//       const updatedData = updateValue.map((updateItem, index) => ({
//         sn: updateItem.sn,
//         itmId: updateItem.itmId,
//         qty: updateItem.qty,
//         purchasePrice: updateItem.purchasePrice,
//         salePrice: updateItem.salePrice,
//         mrp: updateItem.mrp,
//         attributes:
//           updateItem.attributes.map((attribute, childIndex) => ({
//             // Map attributes here
//             // Example: Adjust attribute values or structure based on your specific logic
//             attrGrpId: attribute.attrGrpId,
//             attrId: attribute.attrId,
//             attrName: attribute.attrName,
//             index: childIndex,
//           })),
//         // You might want to adjust the Attributes value based on your specific logic
//       }));
//       console.log(updatedData);
//       setData(updatedData);
//     } else {
//       if (TempValue.length === 0) {
//         setData([
//           {
//             sn: 1,
//             itmId: "",
//             qty: "",
//             purchasePrice: "",
//             salePrice: "",
//             mrp: "",
//             attributes: AttributeData,
//           },
//         ]);
//         console.log(data)

//       } else {
//         const TempValueData = TempValue.map((updateTemp, index) => ({
//           sn: updateTemp.sn,
//           itmId: updateTemp.itmId,
//           qty: updateTemp.qty,
//           purchasePrice: updateTemp.purchasePrice,
//           salePrice: updateTemp.salePrice,
//           mrp: updateTemp.mrp,
//           attributes:
//             updateTemp.attributes.map((attribute, childIndex) => ({
//               attrGrpId: attribute.attrGrpId,
//               attrId: attribute.attrId,
//               attrName: attribute.attrName,
//               index: childIndex,
//             })),
//           // You might want to adjust the Attributes value based on your specific logic
//         }));
//         setData(TempValueData);

//       }
//     }
//     // }
//   }, [updateValue, TempValue]);

//   const handleAddRow = () => {
//     setRows([...rows, {}]);
//   };

//   const [copiedValue, setCopiedValue] = useState("");

//   const handleCopyRow = (id, index) => {
//     if (copiedValue === null || copiedValue === "") {
//       const rowToCopy = data[index];
//       const copiedRow = { ...rowToCopy };
//       setCopiedValue(copiedRow);
//       console.log("if");
//     } else {
//       handlePasteRow(id, index);
//       console.log("else");
//     }
//   };

//   const handlePasteRow = (currentId, currentIndex) => {
//     const updatedData = [...data];
//     const copiedRow = { ...copiedValue };
//     copiedRow.id = currentId;

//     updatedData[currentIndex] = copiedRow;
//     setData(updatedData);
//     console.log(copiedValue);
//   };
//   const handleInputChange = (index, newValue, namefield) => {
//     const updatedData = [...data];
//     updatedData[index][namefield] = newValue;
//     setData(updatedData);
//     setCopiedValue("");
//     console.log(data);
//   };

//   const handleInputChangeForAttr = (index, parentIndex, e) => {

//     const AttributeDataList = props.AttributeList;

//     //var parentGrpID =AttributeDataList[parentIndex].attributeId

//     console.log(AttributeDataList)
//     const newdata = e.target.value;
//     console.log(AttributeDataList[0].attributeChilds[0].attrId);

//     var attributeData = AttributeDataList.find((item) =>
//       item.attributeChilds.some((child) => child.attrId === Number(newdata))
//     );

//     if (attributeData) {
//       var attributeChildIndex = attributeData.attributeChilds.findIndex(
//         (child) => child.attrId === Number(newdata)
//       );
//     } else {
//       console.log("No matching attribute data found.");
//     }

//     setData((prevData) => {
//       const newData = [...prevData]; // Shallow copy of the outer array

//       if (
//         newData[parentIndex] &&
//         Array.isArray(newData[parentIndex].attributes)
//       ) {
//         // Make sure the object at parentIndex and its Attributes array are copied deeply
//         newData[parentIndex] = {
//           ...newData[parentIndex],
//           attributes: [...newData[parentIndex].attributes], // Shallow copy of the attributes array
//         };

//         // Update AttrId and AttrName of the object at the specified index
//         newData[parentIndex].attributes[index] = {
//           ...newData[parentIndex].attributes[index],
//           index: index + 1,
//           // attrGrpId:  newData.attrGrpId,             //parentGrpID,
//           attrId: attributeData.attributeChilds[attributeChildIndex].attrId,
//           attrName: attributeData.attributeChilds[attributeChildIndex].attrName,
//         };
//       } else {
//         // Handle the case where newData[parentIndex].attributes is not an array
//         console.error("Attributes array is not defined or is not an array.");
//       }

//       return newData; // Returning a new array reference to trigger a re-render
//     });

//     console.log(data);
//   };

//   useEffect(() => {
//     console.log(data);
//     if (data && data.length > 0) {
//       props.getAttributeList(data);
//       props.setAttributeListTemp(data);
//     }
//   }, [data]);

//   // Log AttributeData in a separate useEffect block

//   //   console.log(data);

//   const handleDeleteRow = (index) => {
//     // Copy the data array
//     const newData = [...data];

//     // Remove the item at the specified index
//     newData.splice(index, 1);

//     // Update the state with the new data
//     setData(newData);
//   };

//   const addRow = () => {
//     // Generate a unique id for the new item (you can use a library like uuid)
//     const newId = Math.max(...data.map((item) => item.id), 0) + 1;

//     // Create a new item
//     const newItem = {
//       sn: data.length + 1,
//       itmId: "",
//       qty: "",
//       purchasePrice: "",
//       salePrice: "",
//       mrp: "",
//       attributes: AttributeData,
//     };

//     // Update the state by appending the new item
//     setData((prevData) => [...prevData, newItem]);
//   };

//   const [selectedAttributes, setSelectedAttributes] = useState(
//     Array(props.AttributeList.length).fill("")
//   );

//   const activeAttributes = props.AttributeList.filter(
//     (attribute) => attribute.isActive === true
//   );

//   const [selectedOptions, setSelectedOptions] = useState(
//     Array(activeAttributes.length).fill([])
//   );


//   // Function to handle the change of a specific attribute
//   const handleAttributeChange = (e, index) => {
//     const value = e.target.value;
//     console.log(data);

//     const newSelectedAttributes = [...selectedAttributes];
//     newSelectedAttributes[index] = value;
//     setSelectedAttributes(newSelectedAttributes);

//     const Attributedata = props.AttributeList;
//     const selectedAttribute = Attributedata.find(
//       (item) => item.attributeId === Number(value)
//     );

//     const options = selectedAttribute ? selectedAttribute.attributeChilds || [] : [];

//     setSelectedOptions((prevOptions) => {
//       const newOptions = [...prevOptions];
//       newOptions[index] = options;
//       return newOptions;
//     });
//   };



//   return (
//     <div>
//       <div className="">
//         <table className="table-scrol2">
//           <thead className="table-tbody">
//             <tr
//               className="table-tr2"
//               style={{ backgroundColor: "rgba(21, 50, 125, 0.62)" }}
//             >
//               <th className="mb_0 tb_1 c-w" style={{ width: "45px" }}>
//                 <label className="c-w">SNo</label>
//               </th>
//               <th className=" mb_1 tb_1 c-w">
//                 <div className="c-w">Qty(?)</div>
//               </th>
//               <th className="mb_2 tb_1 c-w">
//                 <div className="c-w">Pur Price</div>
//               </th>
//               <th className="Attrib_3 c-w">
//                 {" "}
//                 <div className="thm2 tb_1 c-w">
//                   <label className="c-w">Sal Price</label>
//                 </div>{" "}
//               </th>
//               <th className=" mb_4 tb_1 c-w">
//                 {" "}
//                 <div className="c-w">MRP</div>
//               </th>
//               {/* Assuming props.AttributeList is an array of objects with attributeId and attributeName */}
//               {props.AttributeList.filter((attribute) => attribute.isActive)
//                 .sort((a, b) => a.priority - b.priority)
//                 .map((attribute, index) => (
//                   <th key={index} className="mb_5 tb_1 c-w">
//                     <div className="c-w">
//                       <select
//                         className="itemattribute-headre-select"
//                         value={data[index]?.attributes[index]?.attrGrpId}
//                         onChange={(event) =>
//                           handleAttributeChange(
//                             event,
//                             index,
//                             attribute.attributeName
//                           )
//                         }
//                       >
//                         <option value="">Select Variant</option>
//                         {props.AttributeList.map((item, i) => (
//                           <option key={i} value={item.attributeId}>
//                             {item.attributeName}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </th>
//                 ))}
//             </tr>
//           </thead>
//           <tbody className="ContainerAttributeTable">
//             {data.map((row, index) => (
//               <tr className="table-tr2 hover-tr" key={index}>
//                 <td class="mb_0" style={{ textAlign: "center", width: "50px" }}>
//                   <div style={{ width: "47px" }} className="flex">
//                     <button
//                       className="copyBtn-chaitan"
//                       onClick={() => handleCopyRow(row.Sno, index)}
//                     >
//                       Copy
//                     </button>
//                     <label className="mt-1">{index + 1}</label>
//                   </div>
//                 </td>
//                 <td className="mb_1">
//                   <input
//                     className="table-input-13 pd_1"
//                     type="number"
//                     name={`code-${index}`}
//                     value={row.qty}
//                     onChange={(e) =>
//                       handleInputChange(index, e.target.value, "qty")
//                     }
//                   />
//                 </td>
//                 <td class="mb_2">
//                   <input
//                     className="table-input-13 pd_1 "
//                     type="number"
//                     name={`Group-${index}`}
//                     value={row.purchasePrice}
//                     onChange={(e) =>
//                       handleInputChange(index, e.target.value, "purchasePrice")
//                     }
//                   />
//                 </td>
//                 <td class="Attrib_3">
//                   <input
//                     className="table-input-13 pd_1"
//                     type="number"
//                     name={`ItemName-${index}`}
//                     value={row.salePrice}
//                     onChange={(e) =>
//                       handleInputChange(index, e.target.value, "salePrice")
//                     }
//                   />
//                 </td>
//                 <td class="mb_4">
//                   <input
//                     className="table-input-13 pd_1"
//                     type="number"
//                     name={`Description-${index}`}
//                     value={row.mrp}
//                     onChange={(e) =>
//                       handleInputChange(index, e.target.value, "mrp")
//                     }
//                   />
//                 </td>

//                 {selectedOptions.map((options, i) => (
//                   <td className="mb_5" key={i}>
//                     <select
//                       onChange={(e) => handleInputChangeForAttr(i, index, e)}
//                       className="itemattribute-select"
//                       value={data[index]?.attributes[i]?.attrId}
//                     >
//                       <React.Fragment>
//                         <option>Select Options</option>
//                         {options.map((innerItem, innerIndex) => (
//                           <option
//                             key={innerIndex}
//                             value={innerItem.attrId} // Set the innerItem as the value
//                           >
//                             {innerItem.attrName}
//                           </option>
//                         ))}
//                       </React.Fragment>
//                     </select>
//                   </td>
//                 ))}

//                 <td class="" style={{ textAlign: "center", width: "40px" }}>
//                   <button
//                     className="btn-table1"
//                     onClick={() => handleDeleteRow(index)}
//                   >
//                     <i class="fa-solid fa-trash delete-btn"></i>
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="table-down-main">
//         <div className="table-down-m1">
//           <button className="btn-table2" onClick={addRow}>
//             <i class="fa-solid fa-square-plus" style={{ fontSize: "20px" }}></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ItemAttributeList;
