import React, { useState } from 'react';

const ModalForHeight = (props) => {
  const [tableHeight, setTableHeight] = useState(""); // Use tableHeight for state

  const handleHeightChange = (e) => {
    const newHeight = e.target.value;
    console.log(newHeight + "rem");
    setTableHeight(newHeight); // Use setTableHeight to update state
  };

  const handleSave = () => {
    const selectedCell = document.getElementById("maintable"); // Replace "your-cell-id" with the actual cell ID
    selectedCell.style.height = tableHeight + "rem"; // Use tableHeight to set the height
    props.Click();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="modal-back-web-template">
      <div className="image-modal-web-template">
        <i style={{ position: "absolute", fontSize: "20px", right: "10px", top: "5px" }} class="fa-solid fa-xmark" onClick={handleSave}></i>
        <p className='modalheading-text-web-template'>Set Height of table</p>
        <input
          type="number"
          className='input-modalbox-web-template'
          onChange={handleHeightChange}
          value={tableHeight}
          onKeyPress={handleKeyPress}
        />
        <button className='modal-buttonsave-web-template' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ModalForHeight;
