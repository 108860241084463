
import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";

function VehicleMaster() {
    //*****************{title}*********************//
    useState(() => {
        document.title = 'Vehicle Master';
    }, []);


    //******************{modal box}******************/ /
    const [, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };


    //*********************{Button Script}*********************//


    return (
        <div>

            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Vehicle Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton />
                
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Vehicle Master</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Vehicle No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>Bike</option>
                                                                <option>Car</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Vehicle Type</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Fuel Avg Ltr(km)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Bike Description</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Fuel Charge(km)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Vehicle Master Details</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                                        <thead textAlign="center" className="thead_scroll_col1" >
                                                            <tr className="">

                                                                <th className="td_col5"> <div className="table_head_mmb ">
                                                                    <div className="table_head_mmb1">S.No.</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Vehicle No</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Vehicle Description</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Vehicle Type</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Fuel Price(km)</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Fuel Avg Ltr(km)</div>
                                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                                </div> </th>

                                                                <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                                                <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tbody_body_m1">
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>45</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">3</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>45</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">4</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">5</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>45</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">6</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>CAR</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>4</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">7</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2 WHEELER</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>BIKE</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>45</td>
                                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default VehicleMaster;
