import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import img1 from "../../Assets/img/img1.jpg";
import MainButton from "../../Props/MainButton";
import DepartmentApi from '../../../API/APIServices/Department';
import DataTable from "../TableMaster/DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function DepartmentMaster() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Department Master';
    }, []);

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        departmentName: '',
        inchargePerson: '',
    });
    const [inputValues, setInputValues] = useState({
        departmentName: '',
        inchargePerson: '',
        contactNo: '',
        address: '',
        emailId: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['departmentName', 'inchargePerson'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await DepartmentApi.updateDepartment(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await DepartmentApi.insertDepartment(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({
                departmentName: '',
                inchargePerson: '',
                contactNo: '',
                address: '',
                emailId: '',
                isActive: true,
            });
        }
    }
    // ********************************************List Part ******************************

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "departmentName" },
        { Header: "INCHARGE PERSON", accessor: "inchargePerson" },
        { Header: "CONTACT NO.", accessor: "contactNo" },
        { Header: "ADDRESS", accessor: "address" },
        { Header: "EMAIL ID", accessor: "emailId" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Department Name" ref={input1} />, accessor: "DepartmentName" },
        { Header: <input type="text" placeholder="Incharge Person" ref={input2} />, accessor: "InchargePerson" },
        { Header: <input type="text" placeholder="Contact No." ref={input3} />, accessor: "ContactNo" },
        { Header: <input type="text" placeholder="Address" ref={input4} />, accessor: "Address" },
        { Header: <input type="text" placeholder="Email Id" ref={input5} />, accessor: "EmailId" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await DepartmentApi.getDepartment();
            console.log(val.length);

            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    departmentName: item.departmentName,
                    inchargePerson: item.inchargePerson,
                    contactNo: item.contactNo,
                    address: item.address,
                    emailId: item.emailId,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editDepartment(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteDepartment(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editDepartment = async (id) => {
        setEdit(true)
        const dataById = DepartmentApi.getDepartmentById(id);
        if (dataById) {
            try {
                const editData = await dataById; // Assuming props.editData is a Promise
                console.log("dataaaa" + editData);
                setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteDepartment = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteDepartmentConfirm = async () => {
        setLoading(true);
        try {
            const val = await DepartmentApi.deleteDepartment(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return (
        <div>
            <div>
            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Department Master</h2>
                </div >
                {/* ********************************{Buttob}********************************* */}
                <MainButton SaveButton={SaveBtn} PageName="Masters" />
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">

                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Department Details
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" value={inputValues.departmentName}
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} name="departmentName" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Department Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.departmentName && <label className="errorMessage">{errorMessages.departmentName}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" value={inputValues.inchargePerson} name="inchargePerson"
                                                                onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                                ref={input2Ref} onChange={handleInputChange} type="text" required />
                                                            <label className="modify_p_text2">Incharge Person
                                                                <span className="StarClr">*
                                                                    {errorMessages.inchargePerson && <label className="errorMessage">{errorMessages.inchargePerson}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" value={inputValues.contactNo}
                                                                onKeyPress={(e) => handleKeyPress(e, input4Ref)}
                                                                ref={input3Ref} name="contactNo" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Contact No</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" value={inputValues.address}
                                                                onKeyPress={(e) => handleKeyPress(e, input5Ref)}
                                                                ref={input4Ref} name="address" onChange={handleInputChange} placeholder="" required />
                                                            <label className="modify_p_text-fix">Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" value={inputValues.emailId} name="emailId"
                                                                onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input5Ref} onChange={handleInputChange} type="text" required />
                                                            <label className="modify_p_text2">Email Id</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="control_radius">
                                            <div className="col-sm-12 depart_top">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteDepartmentConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Department is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Department is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default DepartmentMaster;