
import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import BillTypeApi from "../../../API/APIServices/BillType";
import { useNavigate } from 'react-router-dom';

function BillType(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    useEffect(() => {
        document.title = 'Bill Type';
    }, []);

    const navigate = useNavigate();

    const [isInput1Visible, setInput1Visible] = useState(false);
    const [isInput1Visible1, setInput1Visible1] = useState(true);

    const [isInputDisabled1, setInputDisabled1] = useState(true);
    const [isInputDisabled2, setInputDisabled2] = useState(true);
    const [isInputDisabled3, setInputDisabled3] = useState(true);
    const [isInputDisabled4, setInputDisabled4] = useState(true);

    const [isModalOpen2, setModalOpen2] = useState(false);

    const showModal2 = () => {
        setModalOpen2(true);
    };
    const hideModal2 = () => {
        setModalOpen2(false);
    };

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        billTypeName: '',
        typeOfTax: '',
    });

    const [inputValues, setInputValues] = useState({
        id: 0,
        billType: 12,
        billTypeName: "",
        specifyAccInfo_Sale: 0,
        accForGoods: 0,
        accForService: 0,
        typeOfTax: 1,
        taxOn: 1,
        modeOfTax: "",
        calculatTaxOn: 0,
        adjTaxInSale: false,
        tCgstAcc: "",
        tSgstAcc: "",
        tIgstAcc: "",
        tCesstAcc: "",
        taxRegion: 1,
        sundryDiscDistri: false,
        transactionType: "Stock Transfer",
        taxRateType: 1,
        cgsTper: 0,
        sgsTper: 0,
        igsTper: 0,
        cesSper: 0,
        isDefault: true
    });

    const [selectedOption, setSelectedOption] = useState('1');
    const [taxRegionOption, setTaxRegionOption] = useState('1');
    const [taxRateOption, setTaxRateOption] = useState('1');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));

        if (name === 'billType') {
            const data = document.getElementById('labelValue');
            if (value === "12") {
                data.textContent = "For Sale";
            }
            else if (value === "14") {
                data.textContent = "For Purchase";
            }
        }
        else if (name === 'specifyAccInfo_Sale') {
            setSelectedOption(value);
            console.log(value);
            if (value === "1") {
                console.log("if");
                setInput1Visible1(true);
                setInput1Visible(false);
            }
            else if (value === "2") {
                console.log("else");
                setInput1Visible(false);
                setInput1Visible1(false);
            }
        }
        else if (name === 'taxRegion') {
            setTaxRegionOption(value);
            if (value === "2") {
                setInputDisabled4(true);
                setInputDisabled3(true);
                setInputDisabled2(false);
                setInputDisabled1(false);
            }
            else if (value === "1") {
                setInputDisabled4(true);
                setInputDisabled3(false);
                setInputDisabled2(true);
                setInputDisabled1(true);

                setInput1Visible(false);
            }
        }
        else if (name === 'taxRateType') {
            setTaxRateOption(value);
            if (value === "1") {
                setInput1Visible(false);

                if (inputValues.taxRegion === "2") {
                    setInputDisabled4(true);
                    setInputDisabled3(true);
                    setInputDisabled2(false);
                    setInputDisabled1(false);
                }
                else if (inputValues.taxRegion === "1") {
                    setInputDisabled4(true);
                    setInputDisabled3(false);
                    setInputDisabled2(true);
                    setInputDisabled1(true);

                    setInput1Visible(false);
                }
            }
            else if (value === "2") {
                setInput1Visible(true);

                setInputDisabled4(false);
                setInputDisabled3(false);
                setInputDisabled2(false);
                setInputDisabled1(false);
            }
        }
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['billTypeName'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = BillTypeApi.updateBillType(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = BillTypeApi.insertBillType(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                id: 0,
                billType: 12,
                billTypeName: "",
                specifyAccInfo_Sale: 0,
                accForGoods: 0,
                accForService: 0,
                typeOfTax: 1,
                taxOn: 1,
                modeOfTax: "",
                calculatTaxOn: 0,
                adjTaxInSale: false,
                tCgstAcc: "",
                tSgstAcc: "",
                tIgstAcc: "",
                tCesstAcc: "",
                taxRegion: 1,
                sundryDiscDistri: false,
                transactionType: "Stock Transfer",
                taxRateType: 1,
                cgsTper: 0,
                sgsTper: 0,
                igsTper: 0,
                cesSper: 0,
                isDefault: true
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData;
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    return (
        <div>
            < PageTabs locationTab={"/BillType"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Bill Type</h2>
                </div>
                {/* *******************Top Buttons****************** */}
                <MainButton PageName="Masters" isBtnSetting={false} SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        {/* <div className="company_info-text-im"></div> */}
                                        <div className="border_bt">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        name="billType"
                                                        ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                        type="text" required
                                                        value={inputValues.billType}>
                                                        <option value="12">Sale</option>
                                                        <option value="14">Purchase</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Select Bill Type</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                {(() => {
                                                    var val = isInput1Visible1 ? input3Ref : input5Ref;
                                                    return (
                                                        <div className="input-box-m2">
                                                            <input className="modify_im_input mode_category"
                                                                onChange={handleInputChange}
                                                                name="billTypeName"
                                                                ref={input2Ref}
                                                                value={inputValues.billTypeName}
                                                                onKeyDown={(event) => handleKeyPress(event, val)}
                                                                type="text" required />
                                                            <label className="modify_p_text2">Name Of Type
                                                                <span className="StarClr">*
                                                                    {errorMessages.billTypeName && <label className="errorMessage">{errorMessages.billTypeName}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    )
                                                })()}
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <select className="modify_im_input mode_category"
                                                    onChange={handleInputChange}
                                                    value={inputValues.isDefault}
                                                    name="isDefault"
                                                    ref={input9Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input10Ref)}
                                                    type="text" required >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                                <label className="modify_p_text2">Is Default</label>
                                            </div>
                                        </div>
                                        <div className="border_bt">
                                            <div className="company_info-text-im">Account Information &nbsp;
                                                <label id="labelValue"></label>
                                            </div>
                                            <div className="input-box-Im radio-size-m1">
                                                <div className="radio-btm-specifyhere">
                                                    <input
                                                        className="radio-btn-n1 billtype_input"
                                                        type="radio"
                                                        checked={selectedOption === "1"}
                                                        name="specifyAccInfo_Sale" value="1"
                                                        onChange={handleInputChange} />
                                                    <label className="radio-btn-billspecify" for="Specify">
                                                        Specify Here
                                                    </label>
                                                </div>
                                                {isInput1Visible1 && (
                                                    <>
                                                        {(() => {
                                                            var val = isInput1Visible ? input4Ref : input5Ref;
                                                            return (
                                                                <div className="input-box-Im">
                                                                    <div className="input-box-m2">
                                                                        <input className="modify_im_input mode_category" ref={input3Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, val)}
                                                                            name="accForGoods" value={inputValues.accForGoods} onChange={handleInputChange}
                                                                            type="text" required />
                                                                        <label className="modify_p_text2">Goods</label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })()}
                                                    </>
                                                )}
                                            </div>
                                            <div className="input-box-Im radio-size-m1">
                                                <div className="radio-btm-specifyhere">
                                                </div>
                                                {isInput1Visible && (
                                                    <div className="radio-btm-specifyhere">
                                                        <div className="input-box-Im">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category" ref={input4Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                                    name="accForService" value={inputValues.accForService} onChange={handleInputChange}
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Service</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="input-box-Im radio-size-m1">
                                                <div className="radio-btm-mn2">
                                                    <input
                                                        className="radio-btn-n1 billtype_input" type="radio" id="Voucher"
                                                        name="specifyAccInfo_Sale" value="2"
                                                        checked={selectedOption === "2"}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className="radio-btn-n2" for="Voucher"> Specify in Voucher </label>
                                                </div>
                                            </div>
                                            {/* ******************************************* */}
                                        </div>
                                        <div className="border_bt type_heigt BillType_Tax">
                                            <div className="company_info-text-im">Type Of Tax</div>
                                            <div className="input-box-Im radio-size-m1">
                                                {(() => {
                                                    var val = isModalOpen2 ? input6Ref : input13Ref;
                                                    return (
                                                        <div className="input-box-m2">
                                                            <select className="modify_im_input mode_category" ref={input5Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, val)}
                                                                name="typeOfTax" value={inputValues.typeOfTax} onChange={handleInputChange} required>
                                                                <option onClick={hideModal2} value="1">Taxable(For Invoice)</option>
                                                                <option onClick={showModal2} value="2">Multi-Tax(For Items)</option>
                                                                <option onClick={hideModal2} value="3">Exempt</option>
                                                                <option onClick={hideModal2} value="4">Non GST</option>
                                                                <option onClick={hideModal2} value="5">Reverse Charge</option>
                                                                <option onClick={hideModal2} value="6">Zero Rated</option>
                                                            </select>
                                                        </div>
                                                    )
                                                })()}
                                            </div>
                                        </div>

                                        <div className="company_info-text-im">Other Information</div>
                                        {isModalOpen2 && (
                                            <div className="border_bt other_hegt">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" ref={input6Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text"
                                                            onChange={handleInputChange} name="taxOn" value={inputValues.taxOn} required>
                                                            <option value="1">Item Price</option>
                                                            <option value="2">Item MRP</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Tax On </label>
                                                    </div>

                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                            name="modeOfTax" value={inputValues.modeOfTax} onChange={handleInputChange} type="text" required>
                                                            <option value="Reverse">Reverse</option>
                                                            <option value="Forward">Forward</option>
                                                        </select>
                                                        <label className="modify_lbl_text4">Mode of Tax</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" ref={input8Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input9Ref)} type="text"
                                                            name="calculatTaxOn" value={inputValues.calculatTaxOn} onChange={handleInputChange} required />
                                                        <label className="modify_p_text2">Calculate Tax on(% of amt)</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" ref={input9Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input10Ref)} type="text"
                                                            name="adjTaxInSale" value={inputValues.adjTaxInSale} onChange={handleInputChange} required>
                                                            <option value="false">No</option>
                                                            <option value="true">Yes</option>
                                                        </select>
                                                        <label className="modify_lbl_text4">Adjust Tax in Sales Account</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input10Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input11Ref)} type="text"
                                                                name="tCgstAcc" value={inputValues.tCgstAcc} onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Tax Acc 1(CGST)</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input11Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text"
                                                                name="tSgstAcc" value={inputValues.tSgstAcc} onChange={handleInputChange} required />
                                                            <label className="modify_lbl_text2">Tax Acc 2(SGST)</label>
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input12Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input13Ref)} type="text"
                                                                name="tCesstAcc" value={inputValues.tCesstAcc} onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Cess Acc</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        <div className="border_bt region_hght Border_Region">
                                            <div className="input-box-Imbt radio-size-m1">
                                                <div className="radio-btm-m1">
                                                    <label className="radio-btn-n2" htmlFor="region">Region</label>
                                                    <div>
                                                        <input className="radio-btn-n1 Radio_Billtype"
                                                            type="radio" checked={taxRegionOption === "1"}
                                                            name="taxRegion" value="1"
                                                            onChange={handleInputChange} />
                                                        <label className="radio-btn-n2" htmlFor="Local">Local</label>
                                                    </div>
                                                    <div>
                                                        <input className="radio-btn-n1 Radio_Billtype"
                                                            type="radio" id="Inter"
                                                            checked={taxRegionOption === "2"}
                                                            name="taxRegion" value="2"
                                                            onChange={handleInputChange} />
                                                        <label className="radio-btn-n2" htmlFor="Inter">Inter-State</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ paddingTop: "20px" }} className="border_bt ">
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input13Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text"
                                                        name="sundryDiscDistri" value={inputValues.sundryDiscDistri}
                                                        onChange={handleInputChange} required>
                                                        <option value="false">No</option>
                                                        <option value="true">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Sundry Discount Distribution in items</label>
                                                </div>

                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input14Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="text"
                                                        name="transactionType" value={inputValues.transactionType}
                                                        onChange={handleInputChange} required>
                                                        <option value="Stock Transfer">Stock Transfer</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">Select Transaction Type</label>
                                                </div>
                                            </div>
                                            {/* <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input13Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)} value={transactionOption} onChange={(e) => setTransactionOption(e.target.value)} type="text" placeholder="" required>
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Transaction Through E-Commerce Portal</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" ref={input14Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="text" placeholder="" required disabled={transactionOption === 'No'} />
                                                    <label className="modify_lbl_text2">Portal Name</label>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="border_bt region_tax">
                                            <div className="company_info-text-im">Tax Calculation</div>
                                            <div>
                                                <div className="input-box-Im radio-size-m1">
                                                    <div className="radio-btm-m2">
                                                        <input className="radio-btn-n1" type="radio" id="SingleTax"
                                                            checked={taxRateOption === "1"}
                                                            name="taxRateType" value="1"
                                                            onChange={handleInputChange} />
                                                        <label className="radio-btn-n2" for="Single"> Single Tax Rate
                                                        </label>
                                                    </div>
                                                    <div className="radio-btm-m2">
                                                        <input className="radio-btn-n1" type="radio"
                                                            checked={taxRateOption === "2"}
                                                            name="taxRateType" value="2"
                                                            onChange={handleInputChange} />
                                                        <label className="radio-btn-n2" for="Single"> Multi Tax Rate </label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Bt input-height2">
                                                    <div className="stock-im-2">
                                                        <div className="company_info-text-im hbn1">CGST %</div>
                                                    </div>
                                                    <div className="input-box-mbt1">
                                                        <input className="modify_im_input mode_tax" type="text"
                                                            name="cgsTper" value={inputValues.cgsTper} onChange={handleInputChange}
                                                            disabled={!isInputDisabled1} required
                                                            ref={input15Ref} onKeyDown={(event) => handleKeyPress(event, input16Ref)} />
                                                    </div>
                                                </div>
                                                <div className="input-box-Bt input-height2">
                                                    <div className="stock-im-2">
                                                        <div className="company_info-text-im hbn1">SGST %</div>
                                                    </div>
                                                    <div className="input-box-mbt1">
                                                        <input className="modify_im_input mode_tax" type="text"
                                                            name="sgsTper" value={inputValues.sgsTper} onChange={handleInputChange}
                                                            disabled={!isInputDisabled2} required
                                                            ref={input16Ref} onKeyDown={(event) => handleKeyPress(event, input17Ref)} />
                                                    </div>
                                                </div>
                                                <div className="input-box-Bt input-height2">
                                                    <div className="stock-im-2">
                                                        <div className="company_info-text-im hbn1">IGST %</div>
                                                    </div>
                                                    <div className="input-box-mbt1">
                                                        <input className="modify_im_input mode_tax" type="text"
                                                            name="igsTper" value={inputValues.igsTper} onChange={handleInputChange}
                                                            disabled={!isInputDisabled3} required
                                                            ref={input17Ref} onKeyDown={(event) => handleKeyPress(event, input18Ref)} />
                                                    </div>
                                                </div>
                                                <div className="input-box-Bt input-height2">
                                                    <div className="stock-im-2">
                                                        <div className="company_info-text-im hbn1">Cess %</div>
                                                    </div>
                                                    <div className="input-box-mbt1">
                                                        <input className="modify_im_input mode_tax" type="text"
                                                            name="cesSper" value={inputValues.cesSper} onChange={handleInputChange}
                                                            disabled={!isInputDisabled4} required
                                                            ref={input18Ref} onKeyDown={(event) => handleKeyPress(event, input1Ref)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Bill Type is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Bill Type is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default BillType;
