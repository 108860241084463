import React, { useState, useEffect, useContext, useRef } from "react";
import ModalForTdWidth from "../ChildComponents/ModalForTdWidth"; // Import your Modal component
import ModalForHeight from "../ChildComponents/ModalForHeight"; // Import your Modal component ";
import MyContext from "../../../Inventory/MyContext";

const Table = (props) => {

  const { handleContentChange, setDroppedItems, droppedItems, setTemplateData, stylesArray, setClickedItemId, isEditing, setIsEditing } = useContext(MyContext);
  const divRef = useRef(null);

  const [tableWidth, setTableWidth] = useState(false);
  const [tableHeight, setTableHeight] = useState(false);
  const [deletebox, setDeleteBox] = useState(false);

  const [clickedCellIndex, setClickedCellIndex] = useState(null);
  const [clickedCellElement, setClickedCellElement] = useState(null);

  const [tableCellData, setTableCellData] = useState(() => {
    // Retrieve the number of columns and rows from local storage or use default values
    const storedColumns = localStorage.getItem("columns");
    const initialColumns = storedColumns && !isNaN(parseInt(storedColumns)) ? parseInt(storedColumns) : 1;

    const storedRows = localStorage.getItem("rows");
    const initialRows = storedRows && !isNaN(parseInt(storedRows)) ? parseInt(storedRows) : 1;

    return {
      headerData: Array(initialColumns).fill(""),
      footerData: Array(initialColumns).fill(""),
      detailData: Array.from({ length: initialRows }, () => Array(initialColumns).fill(""))
    };
  });

  const parseTableHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Extract header data
    const headerInputs = Array.from(doc.querySelectorAll('thead th input')).map(input => input.value);
    let headerDivs = [];
    if (headerInputs.length == 0) {
      headerDivs = Array.from(doc.querySelectorAll('thead th')).map(div => div.textContent);
    }
    const headerData = [...headerInputs, ...headerDivs];

    // Extract footer data
    const footerInputs = Array.from(doc.querySelectorAll('tfoot th input')).map(input => input.value);
    let footerDivs = [];
    if (footerInputs.length == 0) {
      footerDivs = Array.from(doc.querySelectorAll('tfoot th')).map(div => div.textContent);
    }
    const footerData = [...footerInputs, ...footerDivs];

    // Extract detail data
    const bodyRows = doc.querySelectorAll('tbody tr');
    const detailData = Array.from(bodyRows).map(row => {
      const cellInputs = Array.from(row.querySelectorAll('td input')).map(input => input.value);
      let cellDivs = [];
      if (cellInputs.length == 0) {
        cellDivs = Array.from(row.querySelectorAll('td')).map(div => div.textContent);
      }
      return [...cellInputs, ...cellDivs];
    });

    // Check for empty arrays and add default values if needed
    if (headerData.length === 0) {
      headerData.push(''); // Default header if empty
    }

    if (footerData.length === 0) {
      footerData.push(''); // Default footer if empty
    }

    if (detailData.length === 0) {
      detailData.push(['']); // Default detail row if no rows present
    } else {
      detailData.forEach(row => {
        if (row.length === 0) {
          row.push(''); // Default column in row if empty
        }
      });
    }

    // Update the state with the parsed data
    setTableCellData({
      headerData,
      footerData,
      detailData
    });
  };

  useEffect(() => {
    console.log(props.keyValueId)
    console.log(droppedItems);

    const data = droppedItems.find(item => item.id == props.keyValueId);
    const tableData = data.textContents;

    parseTableHtml(tableData);
  }, [])


  const fetchInnerHtmlOfDroppedComponent = (componentId) => {
    console.log(componentId);
    const getRemainingData = (str) => {
      if (str.endsWith("CHILD")) {
        return str.replace("CHILD", "");
      } else if (str.endsWith("CHILD1")) {
        return str.replace("CHILD1", "");
      }
      return str;
    };

    var newID = getRemainingData(componentId)

    if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
      const componentElement = document.getElementById(newID.toString() + "innerHTML");
      if (componentElement) {
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        // Update the corresponding item with the inner HTML
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === newID) {
              return { ...item, textContents: innerHTML };
            }
            return item;
          });

          // Update the template data with the new content
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));

          return updatedItems;
        });
      }
      return
    }
    // Find the component element using its ID
    const componentElement = document.getElementById(componentId.toString());
    console.log(componentElement);
    if (componentElement) {
      // Get the inner HTML
      const innerHTML = componentElement.outerHTML;
      console.log(innerHTML);

      setDroppedItems(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.id === componentId) {
            return { ...item, textContents: innerHTML };
          }
          return item;
        });
        setTemplateData(prevState => ({
          ...prevState,
          jsonStructure: updatedItems,
        }));
        return updatedItems;
      });
    }
  };

  const getParentIdFromChildId = (keyValueId, droppedItems) => {
    for (const item of droppedItems) {
      for (const child of item.JSONChildComponents) {
        if (child.id === keyValueId) {
          return child.parentJSONid;
        }
      }
    }
    return null;
  };

  const handleBlur = () => {
    try {
      setTimeout(() => {
        // Disable editing mode
        setIsEditing(false);
        // Check if the ref is available
        if (divRef.current) {
          const updatedInnerHTML = divRef.current.innerHTML.trim(); // Get the updated content
          console.log('Updated innerHTML:', updatedInnerHTML);

          // Proceed if there is tableCellData
          if (tableCellData) {
            console.log("Text Data:", tableCellData);

            // Call a function to handle content change, passing the updated data
            handleContentChange(updatedInnerHTML, props.keyValueId);

            // Get the parent ID from the child ID (if necessary)
            const parentId = getParentIdFromChildId(props.keyValueId, droppedItems);

            if (parentId !== null) {
              fetchInnerHtmlOfDroppedComponent(parentId); // Fetch updated HTML if needed
            }
            // Update droppedItems with new text content
            setDroppedItems(prevItems => {
              const updatedItems = prevItems.map(item => {
                if (item.id === props.keyValueId) {
                  // Return a new object with updated text contents
                  return { ...item, textContents: updatedInnerHTML };
                }
                return item;
              });

              // Update the template data with the modified items
              setTemplateData(prevState => ({
                ...prevState,
                jsonStructure: updatedItems,
              }));
              return updatedItems; // Return the updated items array
            });
          } else {
            console.log('tableCellData is undefined or null');
          }
        } else {
          console.log('divRef.current is null');
        }
      }, 1000); // Delay to allow for any potential async operations
    } catch (error) {
      console.error('Error during handleBlur:', error);
    }
  };

  useEffect(() => {
    const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
    console.log("parentId", parentId);
  }, [droppedItems]);

  const addColumn = () => {
    setTableCellData((prevData) => {
      const updatedHeader = [...prevData.headerData, ""];
      const updatedFooter = [...prevData.footerData, ""];

      const updatedDetailData = prevData.detailData?.map(row => [...row, ""]);

      return {
        headerData: updatedHeader,
        footerData: updatedFooter,
        detailData: updatedDetailData
      };
    });
  };

  const removeColumn = () => {
    if (tableCellData.headerData.length > 1) {
      setTableCellData((prevData) => {
        const updatedHeader = prevData.headerData.slice(0, prevData.headerData.length - 1);
        const updatedFooter = prevData.footerData.slice(0, prevData.footerData.length - 1);

        const updatedDetailData = prevData.detailData.map(row => row.slice(0, row.length - 1));

        return {
          headerData: updatedHeader,
          footerData: updatedFooter,
          detailData: updatedDetailData
        };
      });
    }
  };

  const addRow = () => {
    setTableCellData((prevData) => {
      const newRow = Array(prevData.detailData[0]?.length).fill("");

      return {
        ...prevData,
        detailData: [...prevData.detailData, newRow]
      };
    });
  };

  const removeRow = () => {
    setTableCellData((prevData) => {
      if (prevData.detailData.length > 1) {
        const updatedDetailData = prevData.detailData.slice(0, prevData.detailData.length - 1);
        return {
          ...prevData,
          detailData: updatedDetailData
        };
      }
      return prevData;
    });
  };

  const [mergedColumns, setMergedColumns] = useState({}); // Store column merges
  const [mergedRows, setMergedRows] = useState({}); // Store row merges

  const mergeColumn = (rowIndex, cellIndex) => {
    setMergedColumns((prev) => {
      const key = `${rowIndex}-${cellIndex}`;
      const newMergedState = { ...prev };
      if (newMergedState[key]) {
        // Unmerge if already merged
        delete newMergedState[key];
      } else {
        // Merge the next column
        newMergedState[key] = true;
      }
      return newMergedState;
    });
  };

  // Function to merge rows on button click
  const mergeRow = (rowIndex, cellIndex) => {
    setMergedRows((prev) => {
      const key = `${rowIndex}-${cellIndex}`;
      const newMergedState = { ...prev };
      if (newMergedState[key]) {
        // Unmerge if already merged
        delete newMergedState[key];
      } else {
        // Merge the next row
        newMergedState[key] = true;
      }
      return newMergedState;
    });
  };

  useEffect(() => {
    localStorage.setItem("columns", tableCellData.length);
    localStorage.setItem("rows", tableCellData.detailData.length);
  }, [tableCellData]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === "+") {
        addColumn();
      } else if (e.altKey && e.key === "-") {
        removeColumn();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleTdClick = (cellIndex, rowIndex, tdElement) => {
    setTableWidth(!tableWidth);
    setClickedCellIndex(cellIndex);
    setClickedCellElement(tdElement);
  };

  const tableheight = () => {
    setTableHeight(!tableHeight);
  };

  const deleteshow = () => {
    setDeleteBox(!deletebox);
  }

  const handleInputHeaderChange = (value, index) => {
    const newHeaderData = [...tableCellData.headerData];
    newHeaderData[index] = value;
    setTableCellData((prevState) => ({
      ...prevState,
      headerData: newHeaderData
    }));
  }

  const handleInputBodyChange = (value, rowIndex, cellIndex) => {
    const newDetailData = [...tableCellData.detailData];
    newDetailData[rowIndex][cellIndex] = value;
    setTableCellData((prevState) => ({
      ...prevState,
      detailData: newDetailData
    }));
  }

  const handleInputFooterChange = (value, index) => {
    const newFooterData = [...tableCellData.footerData];
    newFooterData[index] = value;
    setTableCellData((prevState) => ({
      ...prevState,
      footerData: newFooterData
    }));
  }

  const handlePaste = async (event, index, rowIndex, cellIndex) => {
    event.preventDefault();
    const name = event.target.name;
    const value = localStorage.getItem("VariableName")

    if (value?.includes('@')) {
      if (name == "tableHeader") {
        handleInputHeaderChange(value, index);
        localStorage.setItem("VariableName", "");
      }
      else if (name == "tableData") {
        handleInputBodyChange(value, rowIndex, cellIndex);
        localStorage.setItem("VariableName", "");
      }
      else if (name == "tableFooter") {
        handleInputFooterChange(value, index)
        localStorage.setItem("VariableName", "");
      }
    } else {
      console.log("Not found copied data");
    }
  };

  const outlinebluemain = (e) => {
    setDeleteBox(!deletebox);
    setClickedItemId(props.keyValueId)
    const element = e.target.closest('.resizable-web-template');
    console.log(element.id);

    if (element) {
      // setActiveId(props.keyValueId);

      const deleteButton = document.getElementById(props.keyValueId + "delete");
      if (deleteButton) {
        deleteButton.classList.toggle("column-one-delete-web-template");
      }

      const resizers = document.getElementsByClassName(props.keyValueId); // Get all elements with the keyValueId class
      if (resizers.length > 0) {
        for (let i = 0; i < resizers.length; i++) {
          resizers[i].classList.toggle("displayblock");
        }
      }
    }
  };

  const [hoveredRow, setHoveredRow] = useState(null)

  const handleRowHover = (index) => {
    setHoveredRow(index)
  }

  const handleRowLeave = () => {
    setHoveredRow(null)
  }

  const addColumnAtIndex = (colIndex) => {
    const newHeaderData = [...tableCellData.headerData];
    const newDetailData = tableCellData.detailData.map(row => [...row]); // Clone rows to avoid mutating state directly
    const newFooterData = [...tableCellData.footerData];

    newHeaderData.splice(colIndex, 0, '');

    newDetailData.forEach(row => {
      row.splice(colIndex, 0, ''); // Insert empty cell in each row at the desired index
    });

    newFooterData.splice(colIndex, 0, '');

    setTableCellData({
      ...tableCellData,
      headerData: newHeaderData,
      detailData: newDetailData,
      footerData: newFooterData,
    });
  };

  const deleteColumnAtIndex = (colIndex) => {
    const newHeaderData = [...tableCellData.headerData];
    const newDetailData = tableCellData.detailData.map(row => [...row]); // Clone rows to avoid mutating state directly
    const newFooterData = [...tableCellData.footerData];

    newHeaderData.splice(colIndex, 1);

    newDetailData.forEach(row => {
      row.splice(colIndex, 1); // Remove the corresponding cell in each row
    });

    newFooterData.splice(colIndex, 1);

    setTableCellData({
      ...tableCellData,
      headerData: newHeaderData,
      detailData: newDetailData,
      footerData: newFooterData,
    });
  };

  const [showToolTipByIndex, setShowToolTipByIndex] = useState(null);

  const handleShowToolTip = (index) => {
    setShowToolTipByIndex(index)
  }

  const handleHideToolTip = () => {
    setShowToolTipByIndex(null)
  }

  const handleTextClick = () => {
    setIsEditing(true);
  };

  return (
    <div id={props.keyValueId}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      ref={divRef}
      onBlur={handleBlur}
      className="resizable-web-template"
    >
      <table //onClick={deleteshow}
        className="table-component-web-template"
        style={{
          border: '1px solid lightgrey',
          borderCollapse: 'collapse'
        }}
        onClick={outlinebluemain}
        id={`${props.keyValueId} maintable`}
      >
        <thead >
          <tr >
            {tableCellData && tableCellData.headerData?.map((cell, index) => (
              <th
                key={index}
                style={{
                  height: "auto", border: '1px solid lightgrey', minHeight: "30px",
                  borderCollapse: 'collapse',
                }}
                columnSpan="2"
                onMouseEnter={() => handleRowHover(index)}
                onMouseLeave={handleRowLeave}
              >
                <span style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div>
                    <div className="tooltip-containerform">
                      {showToolTipByIndex == index && (
                        <span className="tooltipForm">Add Table Column</span>
                      )}
                      <span>
                        {index === hoveredRow && (
                          <i
                            className="fa-solid fa-plus tablePlusIcon_webTemplate"
                            onClick={() => addColumnAtIndex(index)}
                            style={{}}
                            onMouseEnter={() => handleShowToolTip(index)}
                            onMouseLeave={handleHideToolTip}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                  {isEditing ? (
                    <input className="textbox-editable-1-web-template withoutBorder textbox-editable" type="text" name="tableHeader" id=""
                      value={`${cell}`} onChange={(e) => handleInputHeaderChange(e.target.value, index)}
                      onClick={(e) => handlePaste(e, index, 0, 0)} onBlur={handleBlur} style={{ width: '100%' }}
                    />
                  ) : (
                    <div
                      className="component-web-template"
                      onClick={handleTextClick}
                      style={{ width: "100%", minHeight: "30px", cursor: "text", padding: "5px" }}
                    >
                      {`${cell}`}
                    </div>
                  )}

                  {/* <div
                    className="component-web-template"
                    onClick={handleTextClick}
                    style={{ width: "100%", minHeight: "30px", cursor: "text" }}
                  >
                    {isEditing ? (
                    ): (cell)}
                  </div> */}
                  {/* ***************** */}
                  <div>
                    <div className="tooltip-containerform">
                      {showToolTipByIndex == index && (
                        <span className="tooltipForm">Delete Table Column</span>
                      )}
                      <span>
                        {index === hoveredRow && (
                          <i
                            className="fa-solid fa-trash tablePlusIcon_webTemplate"
                            onClick={() => deleteColumnAtIndex(index)}
                            style={{}}
                            onMouseEnter={() => handleShowToolTip(index)}
                            onMouseLeave={handleHideToolTip}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody >
          {tableCellData.detailData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                const columnKey = `${rowIndex}-${cellIndex}`;
                const rowKey = `${rowIndex}-${cellIndex}`;
                const isColumnMerged = mergedColumns[columnKey];
                const isRowMerged = mergedRows[rowKey];
                return (
                  <>
                    <td style={{
                      textAlign: "center", border: '1px solid lightgrey',
                      borderCollapse: 'collapse'
                    }}
                      onContextMenu={() =>
                        handleTdClick(
                          cellIndex, rowIndex,
                          document.getElementById(`cell-${rowIndex}-${cellIndex}`)
                        )
                      }
                      key={cellIndex}
                      id={`cell-${rowIndex}-${cellIndex}`}
                      className={`cell ${rowIndex === hoveredRow ? 'hovered-row' : ''}`}
                      colSpan={isColumnMerged ? 2 : 1} // Adjust colSpan based on merge state
                      rowSpan={isRowMerged ? 2 : 1}
                      onMouseEnter={() => handleRowHover(cellIndex)}
                      onMouseLeave={handleRowLeave}
                    >
                      {isEditing ? (
                        <input className="textbox-editable-1-web-template withoutBorder textbox-editable" type="text" name="tableData" id=""
                          value={`${cell}`} onChange={(e) => handleInputBodyChange(e.target.value, rowIndex, cellIndex)}
                          onClick={(e) => handlePaste(e, 0, rowIndex, cellIndex)} onBlur={handleBlur} style={{ width: '100%' }}
                        />
                      ) : (
                        <div
                          className="component-web-template"
                          onClick={handleTextClick}
                          style={{ width: "100%", minHeight: "30px", cursor: "text", padding: "5px" }}
                        >
                          {`${cell}`}
                        </div>
                      )}

                      {/* <input className="textbox-editable-1-web-template withoutBorder" type="text" name="tableData" id=""
                        value={`${cell}`} onChange={(e) => handleInputBodyChange(e.target.value, rowIndex, cellIndex)} onClick={(e) => handlePaste(e, 0, rowIndex, cellIndex)} /> */}
                    </td>
                  </>
                )
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr >
            {tableCellData.footerData?.map((cell, index) => (
              <th
                key={index}
                style={{
                  height: "30px", border: '1px solid lightgrey',
                  borderCollapse: 'collapse'
                }}
                onMouseEnter={() => handleRowHover(index)}
                onMouseLeave={handleRowLeave}
              >
                {isEditing ? (
                  <input className="textbox-editable-1-web-template withoutBorder textbox-editable" type="text" name="tableFooter" id=""
                    value={`${cell}`} onChange={(e) => handleInputFooterChange(e.target.value, index)}
                    onClick={(e) => handlePaste(e, index, 0, 0)} onBlur={handleBlur} style={{ width: '100%' }}
                  />
                ) : (
                  <div
                    className="component-web-template"
                    onClick={handleTextClick}
                    style={{ width: "100%", minHeight: "30px", cursor: "text", padding: "5px" }}
                  >
                    {`${cell}`}
                  </div>
                )}

                {/* <input className="textbox-editable-1-web-template withoutBorder" type="text" name="tableFooter" id=""
                value={`${cell}`} onChange={(e) => handleInputFooterChange(e.target.value, index)} onClick={(e) => handlePaste(e, index, 0, 0)} style={{ fontWeight: "700" }} /> */}
              </th>
            ))}
          </tr>
        </tfoot>
      </table>

      {
        deletebox && (
          <div className="button-box-web-template">
            <button className="table-button-web-template" onClick={addColumn}>
              ADD COLUMN <i className="fa-solid fa-plus"></i>
            </button>
            <button className="table-button-web-template" onClick={removeColumn}>
              REMOVE COLUMN <i className="fa-solid fa-xmark"></i>
            </button>
            <button className="table-button-web-template" onClick={addRow}>
              ADD ROW <i className="fa-solid fa-plus"></i>
            </button>
            <button className="table-button-web-template" onClick={removeRow}>
              REMOVE ROW <i className="fa-solid fa-xmark"></i>
            </button>
            <button className="table-button-web-template" onClick={() => mergeColumn(0, 0)}>
              MERGE COLUMN <i className="fa-solid fa-xmark"></i>
            </button>
            <button className="table-button-web-template" onClick={() => mergeRow(0, 0)}>
              MERGE ROW <i className="fa-solid fa-xmark"></i>
            </button>
            <button className="table-button-web-template" onClick={tableheight}>
              HEIGHT <i className="fa-solid fa-text-height"></i>
            </button>
            {/* <button style={{ width: "30px" }} className="table-button-web-template"
              id={props.id + "column-one-delete-web-template"}
              onClick={props.delete}
            ><i className="fa-solid fa-trash"></i>
            </button> */}
          </div>
        )}
      {
        tableWidth && (
          <ModalForTdWidth
            cellElement={clickedCellElement}
            cellIndex={clickedCellIndex}
            Click={handleTdClick}
          />
        )
      }
      {
        tableHeight && (
          <ModalForHeight
            // cellElement={clickedCellElement}
            // cellIndex={clickedCellIndex}
            Click={tableheight}
          />
        )
      }
    </div >
  );
};

export default Table;
