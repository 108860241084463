import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef } from "react";
import 'react-quill/dist/quill.snow.css';
import PreviousOrder from "../../Dashboard/PreviousOrder";
import DamageList from "../../Props/DamageList";
import MainButton from "../../Props/MainButton";


function DamageAndShrinkage() {
    /***********title***************/
    useState(() => {
        document.title = 'Damage And Shrinkage';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    //************modal box************/ /

    ///*****************Onboarding code Open************************///

    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };


    /***********End Enter code*****************/


    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);

    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        // console.log(isInputVisiblemc1);
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    //***********{End Enter code}*****************//

    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Damage And Shrinkage</h2>
                            </div >

                            {/* *******************Top Buttons****************** */}
                            <MainButton/>
                        
                        </div>
                        {/* *******************Modal Box End****************** */}
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">   {/*height25vh*/}
                                                <div className="company-info-top-left Purchase-invoice-table content-addvoucher-height">
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input1Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input2Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>

                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input8Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input9Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                    </div>
                                                                </div>



                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input9Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input10Ref)} style={{ fontSize: "14px" }} required>
                                                                            <option>Damage</option>
                                                                            <option>Shrinkage</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Type</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input10Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input11Ref)} style={{ fontSize: "14px" }} required>
                                                                            <option>FR 1</option>

                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Godown</label>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}> */}
                                                                {/* <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input8Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input9Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                    </div> */}
                                                                {/* <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input11Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input1Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No(STK OUT)</label>
                                                                    </div> */}
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                        <PageTabs />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="input-box-Imo1 input-heightAddItem StockOut_Copy">
                                                <div className="AddNewItem_Input_Box check_box_b1 ">
                                                    <label className="modify_AddNewItem_checkbox particular_font">Particular</label>
                                                </div>
                                            </div>
                                            {/****************************************************** Product Table **************************************************************************/}
                                            <div /*className="height30vh"*/ >
                                                <DamageList />
                                            </div>
                                            <div id="saleRemarkPart" style={{ display: "block" }}>
                                                <div className="stock-flex-start saleRemarkPart remark-padding">
                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "60%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default DamageAndShrinkage;

