import React, { useState } from "react";
import { useEffect, useRef } from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <div className="flex-end">
            {pages.map((page) => (
                <button className={`paginationButton ${currentPage === page ? 'paginationActiveBtn' : ''}`}
                    key={page}
                    onClick={() => onPageChange(page)}
                    disabled={currentPage === page}
                >
                    {page}
                </button>
            ))}
        </div>
    );
};

function ItemNameList(props) {
    /************Category box****************/
    const [rows, setRows] = useState(Array(20).fill({}));
    const [headingValue, setHeadingValue] = useState([])

    const handleAddRow = () => {
        setRows([...currentData, {}]);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = sampleData.filter((row, i) => i !== index);
        setSampleData(updatedRows);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    //**********show hide*****************//
    const [isInputVisiblsi1, setInputVisiblsi1] = useState(false);
    const handleToggleChangsi1 = () => {
        setInputVisiblsi1(!isInputVisiblsi1);
    };
    const [isInputVisiblsi2, setInputVisiblsi2] = useState(false);
    const handleToggleChangsi2 = () => {
        setInputVisiblsi2(!isInputVisiblsi2);
    };
    const [isCheckboxDisabledsi3, setIsCheckboxDisabledsi3] = useState(true)
    const [isInputVisiblsi3, setInputVisiblsi3] = useState(true);
    const handleToggleChangsi3 = () => {
        setInputVisiblsi3(!isInputVisiblsi3);
        setIsCheckboxDisabledsi3(!isCheckboxDisabledsi3); // Toggle the disabled state
    };
    const [isInputVisiblsi4, setInputVisiblsi4] = useState(false);
    const handleToggleChangsi4 = () => {
        setInputVisiblsi4(!isInputVisiblsi4);
    };

    const [isInputVisiblsi9, setInputVisiblsi9] = useState(false);
    const handleToggleChangsi9 = () => {
        setInputVisiblsi9(!isInputVisiblsi9);
    };
    const [isInputVisiblsi10, setInputVisiblsi10] = useState(false);
    const handleToggleChangsi10 = () => {
        setInputVisiblsi10(!isInputVisiblsi10);
    };
    const [isInputVisiblsi11, setInputVisiblsi11] = useState(false);
    const handleToggleChangsi11 = () => {
        setInputVisiblsi11(!isInputVisiblsi11);
    };
    const [isInputVisiblsi12, setInputVisiblsi12] = useState(false);
    const handleToggleChangsi12 = () => {
        setInputVisiblsi12(!isInputVisiblsi12);
    };
    const [isInputVisiblsi13, setInputVisiblsi13] = useState(false);
    const handleToggleChangsi13 = () => {
        setInputVisiblsi13(!isInputVisiblsi13);
    };
    const [isInputVisiblsi14, setInputVisiblsi14] = useState(false);
    const handleToggleChangsi14 = () => {
        setInputVisiblsi14(!isInputVisiblsi14);
    };
    const [isInputVisiblsi15, setInputVisiblsi15] = useState(false);
    const handleToggleChangsi15 = () => {
        setInputVisiblsi15(!isInputVisiblsi15);
    };
    const [isCheckboxDisabledsi16, setIsCheckboxDisabledsi16] = useState(true); // You can initialize it to true to start as disabled
    const [isInputVisiblsi16, setInputVisiblsi16] = useState(true);
    const handleToggleChangsi16 = () => {
        setInputVisiblsi16(!isInputVisiblsi16);
        setIsCheckboxDisabledsi16(!isCheckboxDisabledsi16); // Toggle the disabled state
    };
    const [isInputVisiblsi17, setInputVisiblsi17] = useState(false);
    const handleToggleChangsi17 = () => {
        setInputVisiblsi17(!isInputVisiblsi17);
    };
    const [isCheckboxDisabledsi18, setIsCheckboxDisabledsi18] = useState(true);
    const [isInputVisiblsi18, setInputVisiblsi18] = useState(true);
    const handleToggleChangsi18 = () => {
        setInputVisiblsi18(!isInputVisiblsi18);
        setIsCheckboxDisabledsi18(!isCheckboxDisabledsi18); // Toggle the disabled state
    };
    const [isInputVisiblsi19, setInputVisiblsi19] = useState(false);
    const handleToggleChangsi19 = () => {
        setInputVisiblsi19(!isInputVisiblsi19);
    };
    const [isInputVisiblsi20, setInputVisiblsi20] = useState(false);
    const handleToggleChangsi20 = () => {
        setInputVisiblsi20(!isInputVisiblsi20);
    };
    const [isCheckboxDisabledsi21, setIsCheckboxDisabledsi21] = useState(true);
    const [isInputVisiblsi21, setInputVisiblsi21] = useState(true);
    const handleToggleChangsi21 = () => {
        setInputVisiblsi21(!isInputVisiblsi21);
        setIsCheckboxDisabledsi21(!isCheckboxDisabledsi21); // Toggle the disabled state
    };

    const [isInputVisiblsi24, setInputVisiblsi24] = useState(false);
    const handleToggleChangsi24 = () => {
        setInputVisiblsi24(!isInputVisiblsi24);
    };
    const [isInputVisiblsi25, setInputVisiblsi25] = useState(false);
    const handleToggleChangsi25 = () => {
        setInputVisiblsi25(!isInputVisiblsi25);
    };
    const [isInputVisiblsi26, setInputVisiblsi26] = useState(false);
    const handleToggleChangsi26 = () => {
        setInputVisiblsi26(!isInputVisiblsi26);
    };
    const [isInputVisiblsi27, setInputVisiblsi27] = useState(false);
    const handleToggleChangsi27 = () => {
        setInputVisiblsi27(!isInputVisiblsi27);
    };
    const [isInputVisiblsi28, setInputVisiblsi28] = useState(false);
    const handleToggleChangsi28 = () => {
        setInputVisiblsi28(!isInputVisiblsi28);
    };

    const [isInputVisible10, setInputVisible10] = useState(false);
    const handleToggleChange10 = () => {
        setInputVisible10(!isInputVisible10);
    };
    const [isInputVisible_0, setInputVisible1] = useState(false);
    const handleToggleChanges = ({ index }) => {
        //const val = "isInputVisible_" + index
        setInputVisible1(!isInputVisible_0);
    };

    //************modal box************//

    const [isModalOpen3, setModalOpen3] = useState(false);

    const showModal3 = () => {
        setModalOpen3(true);
    };

    const hideModal3 = () => {
        setModalOpen3(false);
    };



    const [customfields, setCustomefields] = useState([])

    const customFieldAddBtn = () => {
        const val = document.getElementById('CustomFieldInput').value;
        console.log(val);
        if (val.trim() !== '') {
            const newData = { id: customfields.length + 1, FieldName: val }
            setCustomefields([...customfields, newData]);
            document.getElementById("inputField").style.display = "none";
            document.getElementById('CustomFieldInput').value = "";
        }
        else {
            alert("Please Enter valid Name", "ok");
        }
    }

    const AddCustmField_clicked = () => {
        document.getElementById("inputField").style.display = "block";
    }

    //************modal box************/ /

    const appendValue = (e) => {
        console.log(e.target.value)
        let newItem = [...headingValue, e.target.value]
        setHeadingValue(newItem)
    }
    useEffect(() => {
        let savedItems = JSON.parse(localStorage.getItem("headValue"));
        if (savedItems) {
            setHeadingValue(savedItems);
        }
    }, [])
    const [pageNo, setPageNo] = useState(5);

    useEffect(() => {
        localStorage.setItem("headValue", JSON.stringify(headingValue));
    }, [headingValue])

    const [sampleData, setSampleData] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 },
        { id: 11 }, { id: 12 }, { id: 13 }, { id: 14 }, { id: 15 }, { id: 16 }, { id: 17 }, { id: 18 }, { id: 19 }, { id: 20 },
        { id: 21 }, { id: 22 }, { id: 23 }, { id: 24 }, { id: 25 }, { id: 26 }, { id: 27 }, { id: 28 }, { id: 29 }, { id: 30 },
        { id: 31 }, { id: 32 }, { id: 33 }, { id: 34 }, { id: 35 }, { id: 36 }, { id: 37 }, { id: 38 }, { id: 39 }, { id: 40 },
        { id: 41 }, { id: 42 }, { id: 43 }, { id: 44 }, { id: 45 }, { id: 46 }, { id: 47 }, { id: 48 }, { id: 49 }, { id: 50 },
        // { id: 51 }, { id: 52 }, { id: 53 }, { id: 54 }, { id: 55 }, { id: 56 }, { id: 57 }, { id: 58 }, { id: 59 }, { id: 60 },
        // { id: 61 }, { id: 62 }, { id: 63 }, { id: 64 }, { id: 65 }, { id: 66 }, { id: 67 }, { id: 68 }, { id: 69 }, { id: 70 },
        // { id: 71 }, { id: 72 }, { id: 73 }, { id: 74 }, { id: 75 }, { id: 76 }, { id: 77 }, { id: 78 }, { id: 79 }, { id: 80 },
        // { id: 81 }, { id: 82 }, { id: 83 }, { id: 84 }, { id: 85 }, { id: 86 }, { id: 78 }, { id: 88 }, { id: 89 }, { id: 90 },
        // { id: 91 }, { id: 92 }, { id: 93 }, { id: 94 }, { id: 95 }, { id: 96 }, { id: 97 }, { id: 98 }, { id: 99 }, { id: 100 },
    ]);

    //const sampleColumns = ['id'];

    const itemsPerPage = pageNo; // Adjust the number of items per page as needed

    const [currentPage, setCurrentPage] = useState(1);

    const totalItems = sampleData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = sampleData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: sampleData.length + 1 };

            setSampleData([...sampleData, newRow]);

            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);

            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    function AdjustTableWidth(inputId, targetTh, targetTd) {
        var ItemName = document.getElementById(inputId).value;
        const parsedWidth = parseInt(ItemName, 10);
        const val = parsedWidth + 'px';
        if (!isNaN(parsedWidth)) {
            document.getElementById(targetTh).style.width = val;

            for (var i = 0; i < sampleData.length; i++) {
                var indexId = targetTd + i;
                console.log(indexId);

                const element = document.getElementById(indexId);
                if (element != null) {
                    element.style.width = val;
                }
            }
        } else {
            console.error('Invalid width value:', ItemName);
        }
    }

    const SaveBtnClick = () => {

        const pageNo = document.getElementById('setNoOfFields').value;
        updatePage(pageNo);

        AdjustTableWidth("ItemCodeWidth", "Code-th", "Code-td-");
        AdjustTableWidth("ItemGroupWidth", "ItemGroup-th", "ItemGroup-td-");
        //AdjustTableWidth("ItemNameWidth", "ItemName-th", "ItemName-td-");
        AdjustTableWidth("MasterDescriptionWidth", "MasterDescription-th", "MasterDescriptions-td-");

        hideModal3();

    }


    const updatePage = (val) => {
        const newPage = val;
        setPageNo(newPage);
    };

    const addRowAtBetween = (index) => {
        const newId = index + 1;
        const newRow = {
            id: newId,
        };

        const updatedTableData = sampleData.map((row, i) => ({
            ...row,
            id: i >= index ? row.id + 1 : row.id,
        }));

        updatedTableData.splice(index, 0, newRow);

        setSampleData(updatedTableData);
    };

    const [hoveredRow, setHoveredRow] = useState(null)

    const handleRowHover = (index) => {
        setHoveredRow(index)
    }

    const handleRowLeave = () => {
        setHoveredRow(null)
    }
    const [, setQuestiontoask] = useState(false)
    const deleteValueById = (id) => {
        setQuestiontoask((prevState) =>
            prevState.filter((item) => item.id !== id)
        );
    };

 /***********End Enter code*****************/
     const input12Ref = useRef(null);
    const input13Ref = useRef(null);
      const input14Ref = useRef(null);
    // const input15Ref = useRef(null);
    // const input16Ref = useRef(null);
    // const input17Ref = useRef(null);
    // const input18Ref = useRef(null);
    // const input19Ref = useRef(null);
    // const input20Ref = useRef(null);
    // const input21Ref = useRef(null);
    // const input22Ref = useRef(null);
    // const input23Ref = useRef(null);
    // const input24Ref = useRef(null);
    // const input25Ref = useRef(null);
    // const input26Ref = useRef(null);
    // const input27Ref = useRef(null);
    // const input28Ref = useRef(null);
    // const input29Ref = useRef(null);
    // const input30Ref = useRef(null);
    // const input31Ref = useRef(null);
    // const input32Ref = useRef(null);
    // const input33Ref = useRef(null);
    // const input34Ref = useRef(null);
    // const input35Ref = useRef(null);



    // Function to handle Enter key press
    const handleKeyPress = (event, nextInputRef) => {
        // console.log(isInputVisiblemc1);
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    /***********End Enter code*****************/
    return (
        <div>
            <div>
                <div>

                </div>
                <div className="table-container-s1" style={{ height: "auto" }} >
                    <table className="table-scrol2" style={{ width: "100%" }} >
                        <thead className="table-tbody">
                            <tr className="table-tr2" >
                                <th className=" table-th-1 tb_1 c-w"><label className="c-w">SN</label></th>
                                {isInputVisiblsi1 && (<th className=" mb_1 tb_1 c-w" id="Code-th"><div className="c-w">Code(?)</div></th>)}
                                {isInputVisiblsi2 && (<th className="mb_2 tb_1 c-w " id="ItemGroup-th"><div className="c-w">Group</div></th>)}
                                <th className="mb_3 c-w"> <div className="thm2 tb_1 c-w" id="ItemName-th"><label className="c-w" >Item Name</label></div> </th>
                                {isInputVisiblsi4 && (<th className=" mb_4 tb_1 c-w" id="MasterDescription-th"> <div className="c-w">Description</div></th>)}
                                {/* {isInputVisiblsi5 && (<th className=" mb_5 tb_1 c-w" id="ExpDate-th"> <div className="c-w">Exp Dt</div></th>)} */}
                                {/* {isInputVisiblsi6 && (<th className=" mb_6 tb_1 c-w" id="Unit-th"> <div className="c-w">Unit</div></th>)} */}
                                {isInputVisiblsi24 && (<th className=" mb_6 tb_1 c-w" id="Dia-th"> <div className="c-w">DIA</div></th>)}
                                {isInputVisiblsi25 && (<th className=" mb_6 tb_1 c-w" id="Eye-th"> <div className="c-w">EYE</div></th>)}
                                {isInputVisiblsi26 && (<th className=" mb_6 tb_1 c-w" id="Sph-th"> <div className="c-w">SPH</div></th>)}
                                {isInputVisiblsi27 && (<th className=" mb_6 tb_1 c-w" id="Cyl-th"> <div className="c-w">CYL</div></th>)}
                                {isInputVisiblsi28 && (<th className=" mb_6 tb_1 c-w" id="Axis-th"> <div className="c-w">AXIS</div></th>)}
                                {/* {isInputVisiblsi7 && (<th className=" mb_7 tb_1 c-w" id="Color-th"> <div className="c-w">Color</div></th>)} */}
                                {/* {isInputVisiblsi8 && (<th className=" mb_8 tb_1 c-w" id="Modal-th"> <div className="c-w">Model</div></th>)} */}
                                {isInputVisiblsi9 && (<th className=" mb_9 tb_1 c-w" id="Material-th"> <div className="c-w">Material</div></th>)}
                                {isInputVisiblsi10 && (<th className="mb_10 tb_1 c-w" id="Color2-th"> <div className="c-w">Color2</div></th>)}
                                {isInputVisiblsi11 && (<th className="mb_11 tb_1 c-w" id="Desc-th"> <div className="c-w">Desc</div></th>)}
                                {isInputVisiblsi12 && (<th className="mb_12 tb_1 c-w" id="Note2-th"> <div className="c-w">Note2</div></th>)}
                                {isInputVisiblsi13 && (<th className="mb_13 tb_1 c-w" id="BatchNo-th"> <div className="c-w">Batch No</div></th>)}
                                {isInputVisiblsi14 && (<th className="mb_14 tb_1 c-w" id="MgfDate-th"> <div className="c-w">MGf Dt</div></th>)}
                                {isInputVisiblsi15 && (<th className="mb_15 tb_1 c-w"> <div className="c-w">Unit</div></th>)}
                                <th className="mb_16 tb_1 c-w"><div className="thm15 c-w ">Qty</div></th>
                                {isInputVisiblsi17 && (<th className=" mb_17 tb_1 c-w"> <div className="thm16 c-w">Free Qty</div></th>)}
                                <th className="mb_18 tb_1 c-w"> <div className="thm17 c-w">Price</div></th>
                                {isInputVisiblsi19 && (<th className="mb_19 tb_1 c-w"><div className="thm18 c-w">Dis%</div></th>)}
                                {isInputVisiblsi20 && (<th className="mb_20 tb_1 c-w"><div className="thm19 c-w">Dis Amt</div></th>)}
                                <th className=" mb_21 tb_1 c-w"> <div className="thm20 c-w">Total Amt</div></th>
                                {isInputVisible10 && (<th className="mb_22 tb_1 c-w"><div className="thm21 c-w">Item Description</div></th>)}
                                {/* {isInputVisiblsi22 && (<th className="mb_22 tb_1 c-w"><div className="thm21 c-w">MRP Prc</div></th>)} */}
                                {isInputVisible_0 && (<th className="mb_22 tb_1 c-w"><div className="thm21 c-w">{customfields[0].FieldName}</div></th>)}
                                <th className="table-th table-btn table-btn1 c-w" onClick={showModal3}>
                                    <i className="fa-solid fa-gear c-w settingIcon" style={{ display: "block" }}></i>
                                </th>
                            </tr>
                        </thead>

                        <tbody className="table-container-tbody" style={{ /*height: "200px"*/ }}>
                            {currentData.map((row, rowIndex) => (
                                <tr className={`table-tr2 hover-tr onSerialNoHover ${rowIndex === hoveredRow ? 'hovered-row' : ''}`} key={rowIndex}
                                    onMouseEnter={() => handleRowHover(rowIndex)}
                                    onMouseLeave={handleRowLeave} style={{ textAlign: "center" }} >

                                    <td className="table-th-1" onClick={() => addRowAtBetween(rowIndex)}>
                                        {/* {rowIndex + 1} */} {row.id}
                                        <i className="fa-solid fa-plus tablePlusIcon"
                                            onClick={() => addRowAtBetween(rowIndex)}
                                            style={{ display: rowIndex === hoveredRow ? 'block' : 'none' }}></i>
                                    </td >
                                    {isInputVisiblsi1 && (<td className="mb_1">
                                        <input
                                            className="table-input-13 pd_1"
                                            ref={input12Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                                            type="text"
                                            name={`code-${rowIndex}`}
                                            //value={row[`code-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            id={[`Code-td-${rowIndex}`]}
                                        />
                                    </td>)}
                                    {isInputVisiblsi2 && (<td className="mb_2" >
                                        <input
                                            className="table-input-13 pd_1 "
                                            ref={input13Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                                            type="text"
                                            name={`Group-${rowIndex}`}
                                            //value={row[`Group-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            id={[`ItemGroup-td-${rowIndex}`]}
                                        />
                                    </td>)}
                                    <td className="mb_3" >
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`ItemName-${rowIndex}`}
                                            //value={row[`ItemName-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            id={[`ItemName-td-${rowIndex}`]}
                                        />
                                    </td>
                                    {isInputVisiblsi4 && (<td className="mb_4" >
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Description-${rowIndex}`}
                                            //value={row[`Description-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            id={[`MasterDescription-td-${rowIndex}`]}
                                        />
                                    </td>)}

                                    {isInputVisiblsi24 && (<td className="mb_6">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`DIA-${rowIndex}`}
                                            //value={row[`DIA-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi25 && (<td className="mb_6">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`EYE-${rowIndex}`}
                                            //value={row[`EYE-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi26 && (<td className="mb_6">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`SPH-${rowIndex}`}
                                            //value={row[`SPH-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi27 && (<td className="mb_6">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`CYL-${rowIndex}`}
                                            //value={row[`CYL-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi28 && (<td className="mb_6">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`AXIS-${rowIndex}`}
                                            //value={row[`AXIS-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}

                                    {isInputVisiblsi9 && (<td className="mb_9">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Material-${rowIndex}`}
                                            //value={row[`Material-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi10 && (<td className="mb_10">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Color2nd-${rowIndex}`}
                                            //value={row[`Color2nd-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}

                                    {isInputVisiblsi11 && (<td className="mb_11">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Desc-${rowIndex}`}
                                            //value={row[`Desc-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi12 && (<td className="mb_12">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Note2nd-${rowIndex}`}
                                            //value={row[`Note2nd-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi13 && (<td className="mb_13">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`BatchNo-${rowIndex}`}
                                            //value={row[`BatchNo-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi14 && (<td className="mb_14">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`MgfDt-${rowIndex}`}
                                            //value={row[`MgfDt-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi15 && (<td className="mb_15">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Unit2nd-${rowIndex}`}
                                            //value={row[`Unit2nd-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    <td className="mb_16">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Qty-${rowIndex}`}
                                            //value={row[`Qty-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>
                                    {isInputVisiblsi17 && (<td className="mb_17">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`FreeQty-${rowIndex}`}
                                            //value={row[`FreeQty-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    <td className="mb_18">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Price-${rowIndex}`}
                                            ///value={row[`Price-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>
                                    {isInputVisiblsi19 && (<td className="mb_19">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`Discount-${rowIndex}`}
                                            //value={row[`Discount-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    {isInputVisiblsi20 && (<td className="mb_20">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`DiscountAmount-${rowIndex}`}
                                            //value={row[`DiscountAmount-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    <td className="mb_21">
                                        <input
                                            className="table-input-13 pd_1"
                                            type="text"
                                            name={`TotalAmount-${rowIndex}`}
                                            //value={row[`TotalAmount-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                        />
                                    </td>
                                    {isInputVisible10 && (<td className="mb_2" >
                                        <input
                                            className="table-input-13 pd_1 "
                                            type="text"
                                            name={`ItemDescription-${rowIndex}`}
                                            //value={row[`ItemDescription-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                            id={[`ItemDescription-td-${rowIndex}`]}
                                        />
                                    </td>)}

                                    {isInputVisible_0 && (<td className="mb_22">
                                        <input
                                            className="table-input-13 pd_1 "
                                            type="text"
                                            name={`customFields-${rowIndex}`}
                                            //value={row[`customeFields-${rowIndex}`] || ""}
                                            onChange={(e) => handleInputChange(rowIndex, e)}
                                        />
                                    </td>)}
                                    <td className="" style={{ textAlign: "center" }}>
                                        <button className="btn-table1" onClick={() => handleDeleteRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div className="stock-label">
                    <div>
                        <label className="label-red">&nbsp; There is no stock available for this product. Avail Qty : -2</label>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
                <div className="table-down-main" style={{ marginTop: "15px", marginBottom: "5px" }}>
                    <div className="table-down-m1">
                        <button className="btn-table2" onClick={handleAddRow}><i className="fa-solid fa-square-plus"></i></button>
                    </div>
                    <div className="table-down-s1 stock-out-padding">
                        <div className="table-down-s2 flex-wrap-stock">
                            <label className="table-down-l2">Total&nbsp;Quantity</label>
                            <input type="text" className="table-down-s2" value="0.00" />
                        </div>

                        <div className="table-down-s2">
                            <label className="table-down-l2">Amount</label>
                            <input type="text" className="table-down-s2" value="0.00" />
                        </div>
                        <div className="table-down-s2">
                            <label className="table-down-l2">Gross Amt</label>
                            <input type="text" className="table-down-s2" value="0.00" />
                        </div>
                    </div>
                </div>
            </div>
            {/******************************************************/}
            {isModalOpen3 && (
                <div className="modal" >
                    <div className="ModalBoxProductSetting">
                        <div className="modal-title">
                            <h3 className="modal-h">Add Columns into Table</h3>
                            <span className="close" onClick={hideModal3}>
                                &times;
                            </span>
                        </div>
                        <div className="ModalContentModalSettingP" style={{ paddingTop: "5px" }}>
                            <div className="gridview2">
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input onChange={(e) => {
                                            handleToggleChangsi1(e, "Item Code");
                                            appendValue(e);
                                        }} value="Item Code" checked={isInputVisiblsi1} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Item Code</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="ItemCodeWidth" />
                                    </div>
                                </div>
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input onChange={handleToggleChangsi2} checked={isInputVisiblsi2} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Item group</label>
                                    </div>

                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="ItemGroupWidth" />
                                    </div>
                                </div>
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input onChange={handleToggleChangsi3} disabled={isCheckboxDisabledsi3} checked={isInputVisiblsi3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox" id="ItemName-w"> Item Name</label>
                                    </div>

                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="ItemNameWidth" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 " >
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi4} checked={isInputVisiblsi4} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Master&nbsp;Descriptn</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="MasterDescriptionWidth" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 " >
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange10} checked={isInputVisible10} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Item&nbsp;Descriptn</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="MasterDescriptionWidth" />
                                    </div>
                                </div>


                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi9} checked={isInputVisiblsi9} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Modal</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi10} checked={isInputVisiblsi10} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Color2</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi11} checked={isInputVisiblsi11} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Batch No</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi12} checked={isInputVisiblsi12} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Mgf Date</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi25} checked={isInputVisiblsi25} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">EYE</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input onChange={handleToggleChangsi13} checked={isInputVisiblsi13} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Exp Date</label>
                                    </div>

                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input onChange={handleToggleChangsi14} checked={isInputVisiblsi14} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Unit</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi15} checked={isInputVisiblsi15} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Qty</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi16} disabled={isCheckboxDisabledsi16} checked={isInputVisiblsi16} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Free Qty</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi17} checked={isInputVisiblsi17} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Price</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi18} disabled={isCheckboxDisabledsi18} checked={isInputVisiblsi18} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Discount %</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi19} checked={isInputVisiblsi19} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Discount Amt</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi20} checked={isInputVisiblsi20} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Sale Price</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi24} checked={isInputVisiblsi24} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">DIA</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi21} disabled={isCheckboxDisabledsi21} checked={isInputVisiblsi21} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">MRP Price</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi28} checked={isInputVisiblsi28} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">AXIS</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi26} checked={isInputVisiblsi26} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">SPH</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChangsi27} checked={isInputVisiblsi27} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">CYL</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox" style={{ color: "teal", fontWeight: "600" }}>Sale Prof% (Pur. Page)</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox" style={{ color: "teal", fontWeight: "600" }}>Sale Price (Pur. Page)</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox" style={{ color: "teal", fontWeight: "600" }}>Mrp Price (Pur. Page)</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div>
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        {/* <input onChange={handleToggleChangsi21} disabled={isCheckboxDisabledsi21} checked={isInputVisiblsi21} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" /> */}
                                        <label style={{ backgroundColor: "#ffff54" }} className="modify_p_text_checkbox">Set No of fields</label>
                                    </div>
                                    <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="setNoOfFields" />
                                    </div>
                                </div>
                                {(() => {
                                    if (customfields.length !== 0) {
                                        return customfields.map((fields, index) => (
                                            <div className="ModalBoxInputM5 ">
                                                <div className="input-box3 size-a1" key={fields.id}>
                                                    <input onChange={() => handleToggleChanges(index)} checked={isInputVisible_0} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                    <label className="modify_p_text_checkbox">{fields.FieldName}</label>
                                                </div>
                                                <div className="ModalboxTitleInput flex" style={{ paddingBottom: "0px" }}>
                                                    <input type="text" className="ModalBoxWidthFix" />
                                                    <i className="fa-solid fa-trash mt-1" style={{ zIndex: "1", marginLeft: "50px" }} onClick={() => deleteValueById()}></i>
                                                </div>
                                            </div>
                                        ));
                                    }
                                })()}


                            </div>
                            <hr />
                            <div style={{ display: "none" }} id="inputField">
                                <div className="flex-center" >
                                    <input type="text" id="CustomFieldInput" />
                                    <button className="Add_btn" onClick={customFieldAddBtn}>Add</button>
                                </div>
                            </div>
                            <div className="flex-center">
                                <label style={{ color: "darkblue", cursor: "pointer", fontWeight: "600" }} onClick={AddCustmField_clicked}>+ Add Custom Field into Table</label>
                            </div>

                            <hr />
                            <div className="flex-end">
                                <button id="step-5" className="greenBtn" onClick={SaveBtnClick} style={{ width: "25%" }}>
                                    Save
                                    <i className="fa-regular fa-floppy-disk im-icon-1" style={{ color: "white" }}></i>
                                </button>
                                <button className="redBtn" onClick={hideModal3} style={{ width: "25%" }}>
                                    Back
                                    <i className="fa-solid fa-right-from-bracket im-icon-1" style={{ color: "white" }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default ItemNameList;
