import React, { useState } from 'react'
import Dropdown from './Dropdown'
import InputBox from './InputBox'

const Pincode = () => {


    const [inputValues, setInputValues] = useState({
        pincodeRestriction: '',
        pincodeAvailTitle: '',
        pincodeAvailMsg: ''
    });
    console.log(inputValues)

    const options = [
        { label: "On", value: 1 },
        { label: "Off", value: 0 }
    ]


    return (
        <div>
            <div className='heading-setting'>Pincode Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <Dropdown label={"Pincode Restriction"} options={options} selectedValue={inputValues.pincodeRestriction} onChange={(newvalue) => setInputValues({ ...inputValues, pincodeRestriction: newvalue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Pincode Avail Title"} value={inputValues.pincodeAvailTitle} onChange={(newvalue) => setInputValues({ ...inputValues, pincodeAvailTitle: newvalue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Pincode Avail Message"} value={inputValues.pincodeAvailMsg} onChange={(newvalue) => setInputValues({ ...inputValues, pincodeAvailMsg: newvalue })} />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Pincode
