import { useState, useEffect } from "react";
import "./OrderTakerStylesheet.css";
import { Link, useNavigate } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";

const PaymentGateway = () => {

    const createOrder = async () => {
        const orderId = await OrderApi.createOrder2();
        console.log(orderId);
    }

    const navigate = useNavigate();
    const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

    useEffect(() => {
        // Only load Razorpay script if it's not already loaded
        try {
            if (!window.Razorpay) {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    setIsRazorpayLoaded(true);
                };
                document.body.appendChild(script);
            } else {
                setIsRazorpayLoaded(true);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const [orderStatus] = useState([
        {
            id: 1,
            icon: 'fa-solid fa-basket-shopping',
            statusName: 'Your order is submitted in Restaurant',
            date: "31/10/2023 13:10",
            color: "green",
        },
        {
            id: 2,
            icon: 'fa-solid fa-square-check',
            statusName: 'Your order confirmation is pending',
            date: "31/10/2023 13:10",
            color: "dimgrey",
        },
        {
            id: 3,
            icon: 'fa-solid fa-cake-candles',
            statusName: 'Mothers are cooking your Food',
            date: "",
            color: "dimgrey",
        },
        {
            id: 4,
            icon: 'fa-solid fa-bag-shopping',
            statusName: 'Your food has been delivered',
            date: "",
            color: "dimgrey",
        },
    ])

    return (
        <div>
            <div className="heading">
                <div className="head-display space-between">
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title">Payment of (931703)</h2>
                    </div>
                    <label style={{ color: "orange" }}>FEEDBACK</label>
                    {/*<i className="fa-solid fa-star" style={{ color: "orange" }}></i>*/}
                </div>
            </div>

            <button onClick={createOrder}>Create Order</button>
            {/* <div>
                <h1>My React Component</h1>
                <iframe src="../../../public/Payment.html" title="Embedded Page" width="100%" height="500px"></iframe>
            </div> */}
            <div style={{ backgroundColor: "whitesmoke", overflowY: "scroll", height: "75%", marginBottom: "10px", marginTop: "45px" }}>
                <div className="flex-center">
                    <label className="ques">Track your Order Status</label><br />
                </div>
                <div className="mt-2 flex-center">
                    <img src="https://c8.alamy.com/comp/2GWXAFW/little-girl-tries-to-be-a-chef-serving-food-2GWXAFW.jpg" alt="DeliveryBoyImage" style={{ height: "100px", width: "100px" }}></img><br />
                </div>
                <div className="" style={{ marginLeft: "10px" }}>

                    {/*eslint-disable-next-line array-callback-return*/}
                    {orderStatus.map((status) => (
                        <div className="flex-start mt-3">
                            <div className="iconDiv flex-center" style={{ backgroundColor: status.color, alignItems: "center" }}>
                                <i className={status.icon} style={{}}></i>
                            </div>
                            <div className="statusDiv">
                                <label className="ques">{status.statusName}</label>
                                <label className="ques" style={{ color: "green", fontWeight: "bold", display: "flex" }}> {status.date}</label>
                            </div>
                        </div>
                    ))}

                    <div className="mt-4" style={{ backgroundColor: "white", borderRadius: "5px", marginRight: "10px", padding: "10px 10px" }}>
                        <div style={{ backgroundColor: "whitesmoke", padding: "5px" }}>
                            <div className="flex-start">
                                <label className="ques">Order No : &nbsp;</label>
                                <label className="ans">931703</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Order Date : &nbsp;</label>
                                <label className="ans">31/10/2023</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Service Type : &nbsp;</label>
                                <label className="ans">Table | Table No : HUT-01</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Delivery Time Slot : &nbsp;</label>
                                <label className="ans">14:00 to 14:30</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">KDTT : &nbsp;</label>
                                <label className="ans">14:00</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Confirm Time : &nbsp;</label>
                                <label className="ans">31/10/2023 13:45</label>
                            </div>
                        </div>
                        {(() => {
                            //if (parsedData !== null) {
                            //    return parsedData.map((cart, index) => (
                            //        <div>
                            //            <div className="row" style={{ margin: "5%", display: "flex", justifyContent: "space-evenly" }}>
                            //                <div className="col-sm-2 ">
                            //                    <img src={cart.imageSource} alt={cart.imageName} style={{ width: "70px", height: "70px", borderRadius: "5px" }}></img>
                            //                </div>
                            //                <div className="col-sm-6 ">
                            //                    <div className="row">
                            //                        <label><strong>{cart.imageName}</strong></label>
                            //                        <label>Qty: {cart.quantity} x Rs. {cart.price} /-</label>
                            //                    </div>
                            //                </div>
                            //                <div className="col-sm-3 " style={{ display: "flex", justifyContent: "end" }}>
                            //                    <h6>Rs <label >{cart.quantity * cart.price}</label> /-</h6>
                            //                </div>
                            //            </div>
                            //            <hr />
                            //        </div>
                            //    ))
                            //}
                        })()}
                        {/*<hr style={{ height: "3px", backgroundColor: "grey", opacity: "inherit" }} />*/}
                        <div>
                            <div className="flex-start" style={{ margin: "5%", padding: "5px" }}>
                                <div className="">
                                    <img src="https://i.ytimg.com/vi/H3tW-UFSojU/maxresdefault.jpg" alt="Butter Naan" style={{ width: "70px", height: "70px", borderRadius: "5px" }}></img>
                                </div>
                                <div className="flex-start ">
                                    <div className="p-1">
                                        <label><strong>Butter Naan</strong></label>
                                        <label>Qty: 1 pcs x Rs. 40.00</label>
                                    </div>
                                </div>
                                <label className="ques">Rs.40.00</label>
                            </div>
                            <hr />
                            {/****************/}
                            <div className="flex-start" style={{ margin: "5%", backgroundColor: "white", padding: "5px" }}>
                                <div className="">
                                    <img src="https://i.ytimg.com/vi/H3tW-UFSojU/maxresdefault.jpg" alt="Butter Naan" style={{ width: "70px", height: "70px", borderRadius: "5px" }}></img>
                                </div>
                                <div className="flex-start ">
                                    <div className="p-1">
                                        <label><strong>Butter Naan</strong></label>
                                        <label>Qty: 1 pcs x Rs. 40.00</label>
                                    </div>
                                </div>
                                <label className="ques">Rs.40.00</label>
                            </div>
                            <hr />
                            {/*********************/}
                        </div>
                        <div style={{ borderRadius: "5px", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "column", padding: "10px", margin: "10px" }}>
                            <h6>Sub Amount: 0.00</h6>
                            <h6>Delivery Amount: 0.00</h6>
                            <h6>Total Amount: 0.00</h6><br />
                        </div>
                    </div>
                </div>
            </div>
            <button onclick="createOrder()">Pay with Razorpay</button>

        </div>
    );
};



async function createOrder() {
    // Example function to call your API to create an order
    const response = await fetch('/api/Order/createOrder2', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required
        },
        body: JSON.stringify({
            amount: 50000, // example amount
            // include other order details as required
        }),
    });

    const data = await response.json();

    var options = {
        "key": "rzp_test_6gzv7z3KTwC4Gp", // Enter the Key ID generated from the Dashboard
        "amount": data.amount, // Amount is in currency subunits. Default currency is INR.
        "currency": data.currency,
        "name": "Acme Corp",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response) {
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature);
        },
        "prefill": {
            "name": "Gaurav Kumar",
            "email": "gaurav.kumar@example.com",
            "contact": "9999999999"
        },
        "theme": {
            "color": "#3399cc"
        }
    };
    //var rzp1 = new Razorpay(Option);
    //rzp1.open();
}



export default PaymentGateway;
