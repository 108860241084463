import React, { useContext } from "react";
import MyContext from "../../../Inventory/MyContext";
import WebTemplate from "../../../API/APIServices/WebTemplate";

const DeleteModal = (props) => {

  const { TemplateData, setDroppedItems,
    setTemplateData } = useContext(MyContext)

  const deleteLocalStrg = () => {
    setDroppedItems([])
    setTemplateData({})
    handleCloseModal();
    const saveEmptyData = () => {
      var id = localStorage.getItem("templateId")

      console.log(TemplateData);
      var Emptydata = {
        JSONChildComponents: "",
        id: id,
        name: TemplateData.name,
        textContents: "",
        jsonStructure: "",
        insertQuery: 0,
      }
      var data = WebTemplate.UpdateCustomizedPageTemplate(Emptydata)
      if (data) {
        alert("Project deleted successfully")
      }
    }
    saveEmptyData();
  };

  const handleCloseModal = () => {
    props.handlebox(); // Call the handlebox function to close the modal
  };

  return (
    <div>
      <div className="modal-back">
        <div className="dlt-modal">
          <p className="dlt-modal-text">
            Are you sure you want to delete the project?
          </p>
          <div className="dlt-button-box">
            <div onClick={handleCloseModal} className="dlt-modal-delete-btn">
              <div className="button-wrapper">
                <div className="text" style={{ display: "flex" }}>Cancel
                  <i className="fa-solid fa-xmark dlt-modal-icon"></i>
                </div>
              </div>
            </div>

            <div onClick={deleteLocalStrg} className="dlt-modal-cancel-btn">
              <div className="button-wrapper">
                <div className="text" style={{ display: "flex" }}>
                  Delete
                  <i className="fa-solid fa-trash dlt-modal-icon"></i>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
