import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import DataTable from "../TableMaster/DataTable";
import UnitMasterApi from '../../../API/APIServices/UnitMaster';
import UnitMaster from "./UnitMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function UnitMasterList() {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
        input6,
        input7,
        input8,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "PRINT NAME", accessor: "printName" },
        { Header: "EWAY BILL UNIT NAME", accessor: "eWayBillUnitName" },
        { Header: "MAIN UNIT", accessor: "mainUnit" },
        { Header: "ALT UNIT", accessor: "altUnit" },
        { Header: "CONVERSION UNIT", accessor: "conversionUnit" },
        { Header: "CONVERSION FACTOR", accessor: "conversionFactor" },
        { Header: "CONVERSION VALUE", accessor: "conversionValue" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1}/>, accessor: "Name" },
        { Header: <input type="text" placeholder="Print Name" ref={input2}/>, accessor: "PrintName" },
        { Header: <input type="text" placeholder="Eway Bill Unit Name" ref={input3} />, accessor: "eWayBillUnitName" },
        { Header: <input type="text" placeholder="Main Unit" ref={input4}/>, accessor: "MainUnit" },
        { Header: <input type="text" placeholder="Alt Unit" ref={input5}/>, accessor: "AltUnit" },
        { Header: <input type="text" placeholder="Conversion Unit" ref={input6}/>, accessor: "ConversionUnit" },
        { Header: <input type="text" placeholder="Conversion Factor" ref={input7}/>, accessor: "ConversionFactor" },
        { Header: <input type="text" placeholder="Conversion Value" ref={input8}/>, accessor: "ConversionValue" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await UnitMasterApi.getUnitMasterFilter();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    name: item.unitName,
                    printName: item.printName,
                    eWayBillUnitName: item.eWayBillUnitName,
                    mainUnit: item.mainUnit,
                    altUnit: item.altUnit,
                    conversionUnit: item.conversionUnit,
                    conversionFactor: item.conversionFactor,
                    conversionValue: item.conversionValue,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editUnitMaster(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => deleteUnitMaster(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);

    const editUnitMaster = (id) => {
        const data = UnitMasterApi.getUnitMasterById(id);
        console.log(data);
        setEditData(data);
        toggleSlider();
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        console.log(editData);
        setSliderOpen(!isSliderOpen);
    };

    const [getdeleteId, setDeleteId] = useState(null);

    const deleteUnitMaster = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteUnitConfirm = async () => {
        setLoading(true);
        try {
            const val = await UnitMasterApi.deleteUnitMaster(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    return (
        <div className="" >
            < PageTabs locationTab={"/UnitMaster"} modelOpen={true} setSliderCallBack={setSliderOpen} />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Unit Master List</h2>
                    <TableButton PageName="Masters" PageCreate={"/UnitMaster"} filterData={filterData} columns={columns} data={data1} selectedData={selectedData} />
                </div>
                <DataTable filterData={filterData} columns={columns} data={data1} />
            </div>
            {/******************************* Unit Master Modal*************************/}
            <div className={`slider-container100 ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <UnitMaster editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} />
                </div>
            </div>
            {/* ********************************************** Mobile View ***************************** */}
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            < ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
            {/* ***************************** */}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteUnitConfirm}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}
export default UnitMasterList;
