import PageTabs from "../Components/PageTabs";
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css';
import AccountMaster from './AccountMaster/AccountMaster'

function ProductExchange() {
    /***********title***************/
    useState(() => {
        document.title = 'Product Exchange';
    }, []);

    const options = ['Aadhya', 'Sriti'];

    const [AccountMasterModal, setAccountMasterModal] = useState(false)
    const openAccountMasterModal = (val) => {
        if (val === 'Add') {
            setAccountMasterModal(true)
            document.getElementById('PartySelectBox').value = "";
        }
    }
    const closeAccountMasterModal = () => {
        setAccountMasterModal(false)
    }
    const [isInputVisible1, setInputVisible1] = useState(false);
    const handleToggleChange1 = () => {
        setInputVisible1(!isInputVisible1);
    };
    const [isInputVisible2, setInputVisible2] = useState(false);
    const handleToggleChange2 = () => {
        setInputVisible2(!isInputVisible2);
    };
    const [isInputVisible3, setInputVisible3] = useState(false);
    const handleToggleChange3 = () => {
        setInputVisible3(!isInputVisible3);
    };
    const [isInputVisible4, setInputVisible4] = useState(false);
    const handleToggleChange4 = () => {
        setInputVisible4(!isInputVisible4);
    };
    const [isInputVisible5, setInputVisible5] = useState(false);
    const handleToggleChange5 = () => {
        setInputVisible5(!isInputVisible5);
    };
    const [isInputVisible6, setInputVisible6] = useState(false);
    const handleToggleChange6 = () => {
        setInputVisible6(!isInputVisible6);
    };
    const [isInputVisible7, setInputVisible7] = useState(false);
    const handleToggleChange7 = () => {
        setInputVisible7(!isInputVisible7);
    };
    const [isInputVisible8, setInputVisible8] = useState(false);
    const handleToggleChange8 = () => {
        setInputVisible8(!isInputVisible8);
    };

    const [ListSettingModal, setListSettingModal] = useState(false);

    const openListSettingModal = () => {
        setListSettingModal(true);
    };
    const closeListSettingModal = () => {
        setListSettingModal(false);
    };
    const handleDeleteRow = (index) => {
        const updatedRows = sampleData.filter((row, i) => i !== index);
        setSampleData(updatedRows);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };
    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: sampleData.length + 1 };

            setSampleData([...sampleData, newRow]);

            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);

            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };
    const [rows, setRows] = useState(Array(20).fill({}));
    const [sampleData, setSampleData] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 },
        { id: 11 }, { id: 12 }, { id: 13 }, { id: 14 }, { id: 15 }, { id: 16 }, { id: 17 }, { id: 18 }, { id: 19 }, { id: 20 },
        { id: 21 }, { id: 22 }, { id: 23 }, { id: 24 }, { id: 25 }, { id: 26 }, { id: 27 }, { id: 28 }, { id: 29 }, { id: 30 },
        { id: 31 }, { id: 32 }, { id: 33 }, { id: 34 }, { id: 35 }, { id: 36 }, { id: 37 }, { id: 38 }, { id: 39 }, { id: 40 },
        { id: 41 }, { id: 42 }, { id: 43 }, { id: 44 }, { id: 45 }, { id: 46 }, { id: 47 }, { id: 48 }, { id: 49 }, { id: 50 },
    ]);
    const itemsPerPage = 5; // Adjust the number of items per page as needed

    const [currentPage] = useState(1);

    //const totalItems = sampleData.length;
    //const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = sampleData.slice(startIndex, endIndex);

    const [SettingModal, setSettingModal] = useState(false);

    const openSettingModal = () => {
        setSettingModal(true);
    };
    const closeSettingModal = () => {
        setSettingModal(false);
    };

    return (
        <div>
            <div className="panel-heading">
                <div className="panel-head-title ">
                    <h2 className="panel-title">Product Exchange</h2>
                </div>
                <div className="flex-end mt-2" onClick={openSettingModal}>
                    <i className="fa-solid fa-gear c-w settingIcon" style={{ margin: "0px 5px" }}></i>
                </div>
            </div>
            <div>< PageTabs /></div>
            <div className="content_box">
                <div className="company-info-top-im" style={{ borderBottom: "1px solid lightgrey" }}>
                    {/* **************** Left part ***************** */}
                    <div className="table-left-padding">
                        <div className="input-box-head">
                            {/*************************** Bill Data ***************/}
                            <div className="mt-1" style={{ width: "100%" }}>
                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Bill No</label>
                                    </div>
                                </div>
                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ fontSize: "12px" }} />
                                        <label id="input1" className="labelEffect1">Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                    </div>
                                    <div className="input-containerEffect mt-1" id="Godown" style={{ margin: "1%", width: "50%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Godown</label>
                                    </div>
                                    
                                </div>
                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>

                                    <div className="input-containerEffect mt-1" id="BookedBy" style={{ margin: "1%", width: "50%" }}>
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label id="input1" className="labelEffect1">Booked By</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* **************** Right part ***************** */}
                    <div className="table-right-padding mt-2" style={{ borderLeft: "1px solid lightgrey" }}>
                        <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                            <select className="form-control nameTitle" id="NameTitle" style={{ width: "20%" }}>
                                <option>Mr.</option>
                                <option>Mrs.</option>
                                <option>Ms.</option>
                            </select>

                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "80%" }}>
                                <select className="form-control inputEffect modify_im_input mode_limit mode_-category input-s1" id="PartySelectBox" required onChange={(e) => openAccountMasterModal(e.target.value)} onDoubleClick={() => openAccountMasterModal("Add")} style={{ fontSize: "14px" }}>
                                    {options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                    <option value="Add" className="optionBtn" ><button className="" style={{ textAlign: "center" }}>Add New</button></option>
                                </select>
                                <label id="input1" className="labelEffect1">Party A/c</label>
                            </div>
                        </div>
                        <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "100%" }}>
                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                <label id="input1" className="labelEffect1">Address</label>
                            </div>
                        </div>
                        <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                <label id="input1" className="labelEffect1">Contact No.</label>
                            </div>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                <label id="input1" className="labelEffect1">State Code</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ******************************************* */}
                <div style={{ padding: "20px" }}>
                    <label>Particular </label>
                    <div className="listDiv">
                        <div>
                            <div className="flex-end" style={{ width: "100%" }}>
                                <label style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>Exchange-Out List</label>
                                {/* <i className="fa-solid fa-gear im-icon-3 icon_hover3" style={{ textAlign: "end", width: "50%" }}></i> */}
                            </div>
                            <div className="table-container-s1 mt-1" style={{ height: "200px" }}>
                                <table className="table-scrol2 mt-1" style={{ width: "100%" }} >
                                    <thead className="table-tbody">
                                        <tr className="table-tr2" >
                                            <th className=" table-th-1 tb_1 c-w"><label className="c-w">SN</label></th>
                                            {isInputVisible1 && (<th className=" mb_1 tb_1 c-w" id="Code-th"><div className="c-w">Code(?)</div></th>)}
                                            <th className="mb_3 c-w"> <div className="thm2 tb_1 c-w" id="ItemName-th"><label className="c-w">Item Name</label></div> </th>
                                            {isInputVisible2 && (<th className=" mb_4 tb_1 c-w" id="MasterDescription-th"> <div className="c-w">Description</div></th>)}
                                            {isInputVisible3 && (<th className=" mb_6 tb_1 c-w" id="Dia-th"> <div className="c-w">DIA</div></th>)}
                                            {isInputVisible4 && (<th className=" mb_6 tb_1 c-w" id="Eye-th"> <div className="c-w">EYE</div></th>)}
                                            {isInputVisible5 && (<th className=" mb_6 tb_1 c-w" id="Sph-th"> <div className="c-w">SPH</div></th>)}
                                            {isInputVisible6 && (<th className=" mb_6 tb_1 c-w" id="Cyl-th"> <div className="c-w">CYL</div></th>)}
                                            {isInputVisible7 && (<th className=" mb_6 tb_1 c-w" id="Axis-th"> <div className="c-w">AXIS</div></th>)}
                                            {isInputVisible8 && (<th className="mb_15 tb_1 c-w"> <div className="c-w">Unit</div></th>)}
                                            <th className="mb_16 tb_1 c-w"><div className="thm15 c-w ">Qty</div></th>
                                            <th className="mb_18 tb_1 c-w"> <div className="thm17 c-w">Price</div></th>
                                            <th className=" mb_21 tb_1 c-w"> <div className="thm20 c-w">Total Amt</div></th>
                                            <th className="table-th table-btn table-btn1 c-w" onClick={openListSettingModal}>
                                                <i className="fa-solid fa-gear c-w settingIcon" style={{ display: "block" }}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-container-tbody" style={{ /*height: "200px"*/ }}>
                                        {currentData.map((row, rowIndex) => (
                                            <tr className={`table-tr2 hover-tr onSerialNoHover `} key={rowIndex}
                                                style={{ textAlign: "center" }} >

                                                <td className="table-th-1">{row.id} </td >

                                                {isInputVisible1 && (<td className="mb_2" >
                                                    <input
                                                        className="table-input-13 pd_1 "
                                                        type="text"
                                                        name={`Code-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange1(rowIndex, e)}
                                                        id={[`ItemCode-td-${rowIndex}`]}
                                                    />
                                                </td>)}
                                                <td className="mb_3" >
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`ItemName-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                        id={[`ItemName-td-${rowIndex}`]}
                                                    />
                                                </td>
                                                {isInputVisible2 && (<td className="mb_4" >
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Description-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange2(rowIndex, e)}
                                                        id={[`Description-td-${rowIndex}`]}
                                                    />
                                                </td>)}

                                                {isInputVisible3 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`DIA-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange3(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible4 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`EYE-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange4(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible5 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`SPH-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange5(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible6 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`CYL-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange6(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible7 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`AXIS-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange7(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible8 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Unit-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange8(rowIndex, e)}
                                                    />
                                                </td>)}
                                                <td className="mb_16">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Qty-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                    />
                                                </td>
                                                <td className="mb_18">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Price-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                    />
                                                </td>
                                                <td className="mb_21">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`TotalAmount-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                        onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                    />
                                                </td>
                                                <td className="" style={{ textAlign: "center" }}>
                                                    <button className="btn-table1" onClick={() => handleDeleteRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex-end mt-2" style={{ width: "60%" }}>
                                <input className="pd_1" type="text" style={{ width: "15%" }} />
                                <input className="pd_1" type="text" style={{ width: "15%", margin: "0px 5px" }} />
                                <i className="fa-solid fa-circle-plus mt-1 dm_4"></i>
                            </div>
                        </div>
                        {/* *************** Stock In Table *************** */}
                        <div className="mt-3">
                            <div className="flex-end" style={{ width: "100%" }}>
                                <label style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>Exchange-In List</label>
                                {/* <i className="fa-solid fa-gear im-icon-3 icon_hover3" style={{ textAlign: "end", width: "50%" }}></i> */}
                            </div>
                            <div className="table-container-s1 mt-1" style={{ height: "200px" }}>
                                <table className="table-scrol2 mt-1" style={{ width: "100%" }} >
                                    <thead className="table-tbody">
                                        <tr className="table-tr2" >
                                            <th className=" table-th-1 tb_1 c-w"><label className="c-w">SN</label></th>
                                            {isInputVisible1 && (<th className=" mb_1 tb_1 c-w" id="Code-th"><div className="c-w">Code(?)</div></th>)}
                                            <th className="mb_3 c-w"> <div className="thm2 tb_1 c-w" id="ItemName-th"><label className="c-w">Item Name</label></div> </th>
                                            {isInputVisible2 && (<th className=" mb_4 tb_1 c-w" id="MasterDescription-th"> <div className="c-w">Description</div></th>)}
                                            {isInputVisible3 && (<th className=" mb_6 tb_1 c-w" id="Dia-th"> <div className="c-w">DIA</div></th>)}
                                            {isInputVisible4 && (<th className=" mb_6 tb_1 c-w" id="Eye-th"> <div className="c-w">EYE</div></th>)}
                                            {isInputVisible5 && (<th className=" mb_6 tb_1 c-w" id="Sph-th"> <div className="c-w">SPH</div></th>)}
                                            {isInputVisible6 && (<th className=" mb_6 tb_1 c-w" id="Cyl-th"> <div className="c-w">CYL</div></th>)}
                                            {isInputVisible7 && (<th className=" mb_6 tb_1 c-w" id="Axis-th"> <div className="c-w">AXIS</div></th>)}
                                            {isInputVisible8 && (<th className="mb_15 tb_1 c-w"> <div className="c-w">Unit</div></th>)}
                                            <th className="mb_16 tb_1 c-w"><div className="thm15 c-w ">Qty</div></th>
                                            <th className="mb_18 tb_1 c-w"> <div className="thm17 c-w">Price</div></th>
                                            <th className=" mb_21 tb_1 c-w"> <div className="thm20 c-w">Total Amt</div></th>
                                            <th className="table-th table-btn table-btn1 c-w" onClick={openListSettingModal}>
                                                <i className="fa-solid fa-gear c-w settingIcon" style={{ display: "block" }}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-container-tbody" style={{ /*height: "200px"*/ }}>
                                        {currentData.map((row, rowIndex) => (
                                            <tr className={`table-tr2 hover-tr onSerialNoHover `} key={rowIndex}
                                                style={{ textAlign: "center" }} >

                                                <td className="table-th-1">{row.id} </td >

                                                {isInputVisible1 && (<td className="mb_2" >
                                                    <input
                                                        className="table-input-13 pd_1 "
                                                        type="text"
                                                        name={`Code-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange1(rowIndex, e)}
                                                        id={[`ItemCode-td-${rowIndex}`]}
                                                    />
                                                </td>)}
                                                <td className="mb_3" >
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`ItemName-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                        id={[`ItemName-td-${rowIndex}`]}
                                                    />
                                                </td>
                                                {isInputVisible2 && (<td className="mb_4" >
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Description-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange2(rowIndex, e)}
                                                        id={[`Description-td-${rowIndex}`]}
                                                    />
                                                </td>)}

                                                {isInputVisible3 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`DIA-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange3(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible4 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`EYE-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange4(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible5 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`SPH-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange5(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible6 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`CYL-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange6(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible7 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`AXIS-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange7(rowIndex, e)}
                                                    />
                                                </td>)}
                                                {isInputVisible8 && (<td className="mb_6">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Unit-${rowIndex}`}
                                                        onChange={(e) => handleToggleChange8(rowIndex, e)}
                                                    />
                                                </td>)}
                                                <td className="mb_16">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Qty-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                    />
                                                </td>
                                                <td className="mb_18">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`Price-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                    />
                                                </td>
                                                <td className="mb_21">
                                                    <input
                                                        className="table-input-13 pd_1"
                                                        type="text"
                                                        name={`TotalAmount-${rowIndex}`}
                                                        onChange={(e) => handleInputChange(rowIndex, e)}
                                                        onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                    />
                                                </td>
                                                <td className="" style={{ textAlign: "center" }}>
                                                    <button className="btn-table1" onClick={() => handleDeleteRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex-end mt-2" style={{ width: "60%" }}>
                                <input className="pd_1" type="text" style={{ width: "15%" }} />
                                <input className="pd_1" type="text" style={{ width: "15%", margin: "0px 5px" }} />
                                <i className="fa-solid fa-circle-plus mt-1 dm_4"></i>
                            </div>
                        </div>
                        {/* ********************* */}
                        <div className="space-evenly mt-2">
                            <div className="input-containerEffect mt-1" style={{ margin: "1%" , width:"30%"}}>
                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                <label id="input1" className="labelEffect1">Remarks</label>
                            </div>

                            <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                <select>
                                    <option>Select</option>
                                    <option>Approved</option>
                                    <option>Cancel</option>
                                    <option>Conv. to Pur. Challan</option>
                                    <option>Conv. to Purchase</option>
                                    <option>Conv. to Sale</option>
                                    <option>Conv. to Sale Challan</option>
                                    <option>Delay</option>
                                    <option>Delivered</option>
                                    <option>Done</option>
                                    <option>Done & verified</option>
                                    <option>Exchange-In</option>
                                    <option>Exchange-Out</option>
                                    <option>On Process</option>
                                    <option>Out of Shipment</option>
                                    <option>Pending</option>
                                    <option>Processing</option>
                                    <option>Ready</option>
                                    <option>Ready for Shipment</option>
                                    <option>Ready for Workshop</option>
                                    <option>Ready from Workshop</option>
                                    <option>Shipped</option>
                                </select>
                            </div>
                            <button className="btn greenBtn" style={{ width: "10%" }}>Save</button>
                            <button className="btn redBtn" style={{ width: "10%" }}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
            {/******************************* Account Master Modal*************************/}
            {
                AccountMasterModal && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "90%", width: "90%" }}>
                            <div style={{ padding: "15px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <label></label>
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeAccountMasterModal}>x</label>
                                </div>
                                <AccountMaster />
                            </div>
                        </div>
                    </div>
                )
            }
            {/*************************** List Setting Modal ***************************/}
            {ListSettingModal && (
                <div className="modal" >
                    <div className="modal-content1">
                        <div className="modal-title">
                            <h3 className="modal-h">Add Columns into Table</h3>
                            <span className="close" onClick={closeListSettingModal}>
                                &times;
                            </span>
                        </div>
                        <div className="modal-content-im" style={{ paddingTop: "5px" }}>
                            <div className="gridview2">
                                <div className=" ModalBoxInputM5" >
                                    <div className="input-box3 size-a1 " >
                                        <input value="Item Code" onChange={handleToggleChange1} checked={isInputVisible1} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox"> Item Code</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="ItemCodeWidth" />
                                    </div> */}
                                </div>
                                <div className="ModalBoxInputM5 " >
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange2} checked={isInputVisible2} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Description</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" id="MasterDescriptionWidth" />
                                    </div> */}
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange3} checked={isInputVisible3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">DIA</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange4} checked={isInputVisible4} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">EYE</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange5} checked={isInputVisible5} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">SPH</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange6} checked={isInputVisible6} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">CYL</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>

                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange7} checked={isInputVisible7} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">AXIS</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>
                                <div className="ModalBoxInputM5 ">
                                    <div className="input-box3 size-a1">
                                        <input onChange={handleToggleChange8} checked={isInputVisible8} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                        <label className="modify_p_text_checkbox">Unit</label>
                                    </div>
                                    {/* <div className="ModalboxTitleInput">
                                        <input type="text" className="ModalBoxWidthFix" />
                                    </div> */}
                                </div>


                            </div>
                            <hr />
                            <div className="flex-end">
                                <button id="step-5" className="greenBtn" style={{ width: "25%" }}>
                                    Save
                                    <i className="fa-regular fa-floppy-disk im-icon-1" style={{ color: "white" }}></i>
                                </button>
                                <button className="redBtn" onClick={closeListSettingModal} style={{ width: "25%" }}>
                                    Back
                                    <i className="fa-solid fa-right-from-bracket im-icon-1" style={{ color: "white" }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*************************** Setting Modal ***************************/}
            {SettingModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", width: "40%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label>Exchange Validation</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeSettingModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <label className="fw-600">Exchange-Out:</label>
                                    <div style={{ marginLeft: "25px" }}>
                                        <div className="mt-2">
                                            <input type="checkbox" />
                                            <label>&nbsp; Restrict product which is not purchased</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" />
                                            <label>&nbsp; Restrict product which is not purchased from selected party</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label className="fw-600">Exchange-In:</label>
                                    <div style={{ marginLeft: "25px" }}>
                                        <div className="mt-2" >
                                            <input type="checkbox" />
                                            <label>&nbsp; Restrict product which is not sale</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" />
                                            <label>&nbsp; Restrict product which is not sale from selected party</label>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="flex-end">
                                    <button className="redBtn" style={{width:"25%"}}> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div >
    );
}


export default ProductExchange;
