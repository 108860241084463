import React, { useEffect, useRef, useState } from 'react';

function Ruler({ width, height, step }) {
    const [cursorPosition, setCursorPosition] = useState({ x: null, y: null });
    const rulerRef = useRef(null);

    const xLines = [];
    const yLines = [];

    for (let i = 0; i <= width; i += step) {
        xLines.push(
            <div
                key={`x-${i}`}
                style={{
                    width: '1px',
                    height: '10px',
                    background: 'black',
                    position: 'absolute',
                    left: i,
                    top: '0',
                }}
            />
        );

        if (i % (step * 5) === 0) {
            xLines.push(
                <div
                    key={`x-label-${i}`}
                    style={{
                        position: 'absolute',
                        left: i - 10,
                        top: '12px',
                        fontSize: '12px',
                    }}
                >
                    {i}
                </div>
            );
        }
    }

    for (let i = 0; i <= height; i += step) {
        yLines.push(
            <div
                key={`y-${i}`}
                style={{
                    width: '10px',
                    height: '1px',
                    background: 'black',
                    position: 'absolute',
                    top: i,
                    left: '0',
                }}
            />
        );

        if (i % (step * 5) === 0) {
            yLines.push(
                <div
                    key={`y-label-${i}`}
                    style={{
                        position: 'absolute',
                        top: i - 10,
                        left: '-25px',
                        fontSize: '12px',
                    }}
                >
                    {i}
                </div>
            );
        }
    }


    const handleMouseMove = (e) => {
        const rect = rulerRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;


        if (x >= 0 && x <= width && y >= 0 && y <= height) {
            setCursorPosition({ x, y });
        }
    };


    useEffect(() => {
        const currentRef = rulerRef.current;
        currentRef.addEventListener('mousemove', handleMouseMove);
        return () => {
            currentRef.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div
            ref={rulerRef}
            style={{
                position: 'relative',
                width: `${width}px`,
                height: `${height}px`,
                // maxHeight: '76vh',
                // maxWidth: '85%',
                border: '1px solid black',
                margin: '1.5rem auto',
                overflow: 'hidden',
                padding: '2.5rem',
            }}
        >
            {xLines}
            {yLines}

            {/* Cursor indicator */}
            {cursorPosition.x !== null && (
                <div
                    style={{
                        position: 'absolute',
                        left: cursorPosition.x,
                        top: '0',
                        width: '1px',
                        height: '12px',
                        backgroundColor: 'red',
                        zIndex: 10,
                    }}
                />
            )}
            {cursorPosition.y !== null && (
                <div
                    style={{
                        position: 'absolute',
                        left: '0',
                        top: cursorPosition.y,
                        width: '12px',
                        height: '1px',
                        backgroundColor: 'red',
                        zIndex: 10,
                    }}
                />
            )}

        </div>
    );
}

export default Ruler;
