import React, { useEffect, useState } from 'react'
import Order from '../../API/APIServices/Order';
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import OrderTakerBillPrint from '../OrderTaker/OrderTakerBillPrint';
import CurrentDateAndTime from '../../API/APIServices/CurrentDateAndTime';
import ReportDesign from './ReportDesign';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ItemDetailModalBox = (props) => {

  const [data, setData] = useState([])
  const [orderID, setOrderId] = useState([])
  const { ItemDetail, setItemDetail, closeItemDetailModal } = props;
  const [loading, setLoading] = useState(false);

  const [statusList, setStatusList] = useState([]);
  const [ReceivedStatus, setRecivedStatus] = useState([]);
  const [TransferredStatus, setTransferredStatus] = useState([]);
  const [ProcessingStatus, setProcessingStatus] = useState([]);
  const [ReadyStatus, setReadyStatus] = useState([]);
  const [DeliveredStatus, setDeliveredStatus] = useState([]);
  const [PickupStatus, setPickupStatus] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleStatusClick = async (ord1Id, ord2Id, index) => {
    setSelectedIndex(index);
    const statusDetailsElement = document.getElementById("statusDetails");
    if (statusDetailsElement) {
      statusDetailsElement.style.display = "block";
    }
    setLoading(true)
    try {
      const data = await Order.getItemWiseStatusList(ord1Id, ord2Id);
      console.log(data);
      setStatusList(data);
      setRecivedStatus([]);
      setTransferredStatus([]);
      setProcessingStatus([]);
      setReadyStatus([]);
      setDeliveredStatus([]);
      setPickupStatus([]);

      const setStatus = (status, stausCreatedBy, statusDateTime) => {
        console.log(status, stausCreatedBy, statusDateTime);
        const val = [{
          orderStatus: status,
          stausCreatedBy: stausCreatedBy,
          statusDateTime: statusDateTime
        }];
        switch (status) {
          case "Received":
            setRecivedStatus(val);
            break;
          case "Transferred":
            setTransferredStatus(val);
            break;
          case "Processing":
            setProcessingStatus(val);
            break;
          case "Ready":
            setReadyStatus(val);
            break;
          case "Delivered":
            setDeliveredStatus(val);
            break;
          case "Pickup":
            setPickupStatus(val);
            break;
          default:
            break;
        }
      };
      for (let i = 0; i < data.length; i++) {
        // const { orderStatus, stausCreatedBy, statusDateTime } = data[i];
        setStatus(data[i].orderStatus, data[i].stausCreatedBy, data[i].statusDateTime);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }

  async function getCurrentTime() {
    const isoString = await CurrentDateAndTime.getCurrentTime();
    return isoString;
  }

  const [insertStatus, setInsertStatus] = useState([]);

  const handleSelectChange = (e, ord1Id, ord2Id, index) => {
    const selectedValue = e.target.value;
    console.log(ItemDetail);
    console.log(index);
    const updatedRows = [...ItemDetail];
    updatedRows[index].kotStatus = selectedValue;
    setItemDetail(updatedRows);

    let cookStatusValue;
    if (selectedValue === "Transferred")
      cookStatusValue = "Queue";
    else if (selectedValue === "Processing")
      cookStatusValue = "Process";
    else if (selectedValue === "Ready")
      cookStatusValue = "Complete";
    else
      cookStatusValue = selectedValue;

    const newObj = [{
      operationType: 0,
      path: "KotStatus",
      op: "Add",
      from: "string",
      value: selectedValue
    },
    {
      operationType: 0,
      path: "CookStatus",
      op: "Add",
      from: "string",
      value: cookStatusValue
    }]

    const orderIDcreation = {
      ord2Id: ord2Id
    }
    setData(prevData => [...prevData, newObj]);
    setOrderId(prevData => [...prevData, orderIDcreation]);

    // *************** Insert Status ****************
    setInsertStatus(prevValues => {
      const existingIndex = prevValues.findIndex(item => item.ord2Id === ord2Id);
      if (existingIndex !== -1) {
        // Update existing status
        const newStatuses = [...prevValues];
        newStatuses[existingIndex] = {
          ...newStatuses[existingIndex],
          orderStatus: selectedValue,
          orderDateTime: ""
        };
        return newStatuses;
      } else {
        // Add new status
        return [
          ...prevValues,
          {
            ord1Id: ord1Id,
            ord2Id: ord2Id,
            orderStatus: selectedValue,
            orderDateTime: "",
          }
        ];
      }
    });
  };

  // const KotStatus = async () => {
  //   await Order.updateItemPartiallyMultiple(data, orderID);
  //   closeItemDetailModal();
  // }
  // **************** Update Status ***************

  const KotStatus = async () => {
    setLoading(true)
    const currentDate = await CurrentDateAndTime.getCurrentDateTime();
    // const currentDate = new Date(isoString);

    await Order.updateItemPartiallyMultiple(data, orderID);

    for (var i = 0; i < insertStatus.length; i++) {
      const insertData = [
        {
          ord1Id: Number(insertStatus[i].ord1Id),
          ord2Id: Number(insertStatus[i].ord2Id),
          orderStatus: insertStatus[i].orderStatus,
          orderDateTime: currentDate,
        }
      ]
      console.log(insertData);
      await Order.insertItemStatusForKotStatusList(insertData);

      // *********** If All Items are Delivered ----> update KOT Dispatch Time *********
      if (insertStatus[i].orderStatus === "Delivered") {
        const filteredData = [
          // {
          //   fieldName: "ord1Id",
          //   operatorName: "Equal",
          //   compareValue: ItemDetail[0].ord1Id,
          // },
          {
            fieldName: "o2KotStatus",
            operatorName: "NotEqual",
            compareValue: "Delivered",
          },
        ]
        const data = await Order.getOrderListByOrd1IdWithFilter(filteredData, ItemDetail[0].ord1Id);
        if (data.length == 0) {
          alert(insertStatus[i].orderStatus)
          const time = await getCurrentTime();
          const updatedValues = [
            {
              operationType: 0,
              path: "KOTDispatchTime",
              op: "Add",
              from: "string",
              value: time,
            }
          ]
          await Order.updateOrderPartially(updatedValues, ItemDetail[0].ord1Id);
        }
      }
    }
    setInsertStatus([]);
    setLoading(false);
    closeItemDetailModal();
  }

  const [serviceTypeName, setServiceTypeName] = useState("");
  useEffect(() => {
    try {
      if (props.serviceTypeId == 751)
        setServiceTypeName("Table Order");
      else if (props.serviceTypeId == 752)
        setServiceTypeName("Home Delivery");
      else if (props.serviceTypeId == 753)
        setServiceTypeName("Pickup");
      else
        setServiceTypeName("POS");
    }
    catch (error) {
      console.log(error);
    }
  }, [])

  const imageUrl = `${WebApiUrl}/api/Order/TripImage?imageId=`;

  const [showBillPrint, setShowBillPrint] = useState(false);

  const handleBillPrintButton = () => {
    setShowBillPrint(true);
  }

  const closeBillPrint = () => {
    setShowBillPrint(false)
  }



  function formatDayOfMonth(day) {
    if (day >= 11 && day <= 13) {
      return day + 'th';
    }
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
  }

  function formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }

  const [currentDateTime, setCurrentDateTIme] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const date = await getCurrentDateTime1();
      setCurrentDateTIme(date)
    }
    fetchData();
  }, [])

  async function getCurrentDateTime1() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const isoString = await CurrentDateAndTime.getCurrentDateTime();
    const now = new Date(isoString);

    const dayOfWeek = days[now.getDay()];
    const month = months[now.getMonth()];
    const dayOfMonth = now.getDate();
    const year = now.getFullYear();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const formattedDayOfMonth = formatDayOfMonth(dayOfMonth);

    return `${dayOfWeek} ${formattedDayOfMonth} ${month} ${year} - ${hours}:${formatTime(minutes)} ${period}`;
  }

  function convertTimeToPeriod(time24) {
    const [hours, minutes, seconds] = time24.split(':').map(Number);

    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const time12 = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

    return time12;
  }

  function convertTo12HourFormat(twentyFourHourTime) {
    if (!twentyFourHourTime) {
      return "";
    }

    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  return (
    <div className="modal-back">
      <div className="info-modal" style={{ width: "85%", height: "auto", maxHeight: "600px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1px 10px",
          }}
        >
          <label className="fw-600" style={{ fontSize: "18px" }}>
            KOT Item Detail
          </label>
          <i
            class="fa-solid fa-xmark xmark"
            onClick={closeItemDetailModal}
          ></i>
        </div>
        <hr />
        <>
          <div >
            <h5><u style={{ marginLeft: "10px" }}>{serviceTypeName} :-</u></h5>
          </div>
          <div className="space-between">
            <p style={{ margin: "0px 12px", display: ItemDetail && ItemDetail[0].orderNo != null ? "block" : "none" }}>
              Order No - {ItemDetail[0].orderNo}
            </p>
            <p style={{ margin: "0px 12px", display: ItemDetail && ItemDetail[0].serviceTypeId == 751 ? "block" : "none" }}>
              Table Group :- {ItemDetail[0].tableGroupName}
            </p>
          </div>
          <div className="space-between">
            <p style={{ margin: "0px 12px", display: ItemDetail && ItemDetail[0].orderDate != null ? "block" : "none" }}>
              Order Date - {moment(ItemDetail[0].orderDate).format('DD-MM-YYYY')}
            </p>
            <p style={{ margin: "0px 12px", display: ItemDetail && ItemDetail[0].serviceTypeId == 751 ? "block" : "none" }}>
              Table Name - {ItemDetail[0].tableName}
            </p>
          </div>
        </>
        <div
          className="row mt-3"
          style={{ marginLeft: "20px", width: "100%", margin: "auto", maxHeight: "375px", overflow: "scroll", border: "1px solid #f3f3f3" }}
        >
          <table className="tableStyle tableScroll-kot">
            <thead>
              <tr>
                <th className="thStyle">Sno</th>
                <th className="thStyle">Item</th>
                <th className="thStyle">Qty</th>
                <th className="thStyle">Price</th>
                {props.serviceTypeId == 752 && (
                  <th className="thStyle">Delivered Time</th>
                )}
                <th className="thStyle">Status</th>
                <th className="thStyle">Item Status</th>
                <th className="thStyle">Order Image</th>
              </tr>
            </thead>
            <tbody>
              {ItemDetail.map((item, index) => {
                let backgroundColor = "white";
                console.log(item.itemRunningQty);
                if (item.itemRunningQty > 0) {
                  backgroundColor = 'rgb(249, 214, 214, 0.6)'
                }
                return (
                  <tr
                    className="hoverRow"
                    style={{ backgroundColor: backgroundColor, cursor: "pointer" }}
                    key={index}
                  >
                    <td className="tdStyle">{index + 1}</td>
                    <td className="tdStyle">{item.d2ItemName}
                      {(item.preferenceNo != "" && item.preferenceNo != null && item.preferenceNo != undefined) ? (
                        <>
                          <br />
                          <label>Delivery Preference - <span style={{ color: "blue", fontWeight: "600" }}>{item.preferenceNo}</span></label>
                          <label></label>
                        </>
                      ) : (
                        <>
                          <label></label>
                        </>)}
                      {(item.itemSuggestion != "" && item.itemSuggestion != null && item.itemSuggestion != undefined) ? (
                        <>
                          <br />
                          <label>Item Suggestion - <span style={{ color: "darkgreen", fontWeight: "600" }}>{item.itemSuggestion}</span></label>
                          <label></label>
                        </>
                      ) : (
                        <>
                          <label></label>
                        </>)}
                      {(item.itemConfirmedByName === "" || item.itemConfirmedByName === null || item.itemConfirmedByName === undefined) ? (
                        <label></label>
                      ) : (
                        <>
                          <br />
                          <label>Item Confirm By - <span style={{ color: "darkred", fontWeight: "600" }}>{item.itemConfirmedByName} <span style={{ color: "black", fontWeight: "500" }}>on</span> {moment.utc(item.itemConfirmedOn1).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</span></label>
                        </>
                      )}
                    </td>
                    <td className="tdStyle">{item.qty}</td>
                    {/* Assuming 'name' is a property of each item */}
                    <td className="tdStyle">₹&nbsp;{item.rate * item.qty}</td>
                    {props.serviceTypeId == 752 && (
                      <td className="tdStyle">{convertTo12HourFormat(String(item.deliveredTime))}</td>
                    )}
                    {/* Assuming 'quantity' is a property of each item */}
                    <td className="tdStyle">
                      <div>
                        <select className="form-control" disabled={props.serviceTypeId != 751 && props.serviceTypeId != 753 ? false : true}
                          onChange={(e) => handleSelectChange(e, item.ord1Id, item.ord2Id, index)}
                          value={item.kotStatus} >
                          <option>Select</option>
                          <option value="Received">Kot Received</option>
                          <option value="Transferred">Kot Transferred</option>
                          <option value="Processing">Kot Processing</option>
                          <option value="Ready">Kot Ready</option>
                          {/* <option value="Pickup">Kot Pickup</option> */}
                          <option value="Delivered">Kot Delivered</option>
                        </select>
                      </div>
                    </td>
                    <td className="tdStyle">
                      <div>
                        <button onClick={() => handleStatusClick(item.ord1Id, item.ord2Id, index)}
                          style={{ padding: "0px" }}>Show Status
                        </button>
                        {selectedIndex === index && (
                          <div>
                            <div className="flex-start" >
                              <div className="flex-start">
                                {ReceivedStatus.length > 0 && ReceivedStatus[0].orderStatus === "Received" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    <label>&nbsp;Received&nbsp;</label>
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ReceivedStatus[0].stausCreatedBy}</label>,</label>
                                    {/* <label>Time&nbsp;:&nbsp;{moment.utc(ReceivedStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label> */}
                                    <label>Time&nbsp;:&nbsp;{moment(ReceivedStatus[0].statusDateTime).format('DD-MM-YYYY hh:mm A')}</label> {/*.utcOffset("+05:30") */}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex-start">
                              <div className="flex-start">
                                {TransferredStatus.length > 0 && TransferredStatus[0].orderStatus === "Transferred" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    <label>&nbsp;Transferred &nbsp;</label>
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{TransferredStatus[0].stausCreatedBy}</label>,</label>
                                    {/* <label>Time&nbsp;:&nbsp;{moment.utc(TransferredStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label> */}
                                    <label>Time&nbsp;:&nbsp;{moment(TransferredStatus[0].statusDateTime).format('DD-MM-YYYY hh:mm A')}</label>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex-start">
                              <div className="flex-start">
                                {ProcessingStatus.length > 0 && ProcessingStatus[0].orderStatus === "Processing" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    <label>&nbsp;Processing&nbsp;</label>
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ProcessingStatus[0].stausCreatedBy}</label>,</label>
                                    {/* <label>Time&nbsp;:&nbsp;{moment.utc(ProcessingStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label> */}
                                    <label>Time&nbsp;:&nbsp;{moment(ProcessingStatus[0].statusDateTime).format('DD-MM-YYYY hh:mm A')}</label>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex-start">
                              <div className="flex-start">
                                {ReadyStatus.length > 0 && ReadyStatus[0].orderStatus === "Ready" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    <label>&nbsp;Ready&nbsp;</label>
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ReadyStatus[0].stausCreatedBy}</label>,</label>
                                    {/* <label>Time&nbsp;:&nbsp;{moment.utc(ReadyStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label> */}
                                    <label>Time&nbsp;:&nbsp;{moment(ReadyStatus[0].statusDateTime).format('DD-MM-YYYY hh:mm A')}</label>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className="flex-start">
                              <div className="flex-start">
                                {PickupStatus.length > 0 && PickupStatus[0].orderStatus === "Pickup" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    <label>&nbsp;Pickup</label> &nbsp;
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{PickupStatus[0].stausCreatedBy}</label>,</label>
                                    <label>Time&nbsp;:&nbsp;{moment.utc(PickupStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                  </div>
                                )}
                              </div>
                            </div> */}
                            <div className="flex-start">
                              <div className="flex-start">
                                {DeliveredStatus.length > 0 && DeliveredStatus[0].orderStatus === "Delivered" && (
                                  <div>
                                    <label style={{ color: "black", fontSize: "20px" }}>•</label>
                                    {/* <label>&nbsp;Delivered &nbsp;</label> */}
                                    <label>&nbsp;DDT &nbsp;</label>
                                    <label>&nbsp;by <label style={{ fontWeight: "600" }}>{DeliveredStatus[0].stausCreatedBy}</label>,</label>
                                    {/* <label>Time&nbsp;:&nbsp;{moment.utc(DeliveredStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label> */}
                                    <label>Time&nbsp;:&nbsp;{moment(DeliveredStatus[0].statusDateTime).format('DD-MM-YYYY hh:mm A')}</label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="tdStyle">
                      <div style={{ display: "flex", justifyContent: "center", height: "75px" }}>
                        {/* <img src={item.imageUrl} alt="NotFound" /> */}
                        {item.ord2ImageId != null && item.ord2ImageId != undefined && item.ord2ImageId != 0 ? (
                          <img className="hover-enlarge-image" src={`${imageUrl}${item.ord2ImageId}`} alt=""
                            style={{ height: "75px" }} />
                        ) : (
                          <div></div>
                        )}
                        {/* Assuming 'imageUrl' is a property of each item */}
                      </div>
                    </td>
                  </tr>
                );
              })
              }
            </tbody>
          </table>
        </div>
        <div className="mt-3 ">
          <div className='space-between'>
            <label><span style={{ fontWeight: "600" }}>Print Time -</span> {currentDateTime}</label>
            {/* <label><span style={{ fontWeight: "600" }}>Delivery Time Slot - </span>{(itemList && timeFormat(itemList[0].deliveryTime))}</label><br /> */}
            <label><span style={{ fontWeight: "600" }}>Delivery Time Slot - </span>{(ItemDetail && (ItemDetail[0].timeSlotName))}</label>
          </div>
          <div className='space-between'>
            <label><span style={{ fontWeight: "600" }}>KDTT - </span>{(ItemDetail && convertTimeToPeriod(ItemDetail[0].kotDispatchTargetTime))}</label>
            <label><span style={{ fontWeight: "600" }}>Remarks : </span></label>
          </div>

        </div>
        <div style={{}}>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button className="greenBtn" style={{ display: props.serviceTypeId != 751 ? "block" : "none", width: "10%" }} onClick={() => handleBillPrintButton()}>Receipt</button>
            <button className="greenBtn" style={{ display: props.serviceTypeId != 751 && props.serviceTypeId != 753 ? "block" : "none", width: "12%" }} onClick={KotStatus}>Update</button>
            <button className="redBtn" style={{ width: "12%" }} onClick={closeItemDetailModal}> Close </button>
          </div>
        </div>
      </div>
      {/* {
        showBillPrint && (
          <div>
            <div className="modal-back" onClick={closeBillPrint}>
              <div className="billPrintModal" style={{}}>
                <div style={{ padding: "5px", backgroundColor: "white" }}>
                  <OrderTakerBillPrint id={ItemDetail[0].ord1Id} closePrintModal={closeBillPrint} backBtn={"modal"} />
                </div>
              </div>
            </div>
          </div>
        )
      } */}
      {
        showBillPrint && (
          <div style={{ display: "none" }}> {/**/}
            <div className="modal-back" onClick={closeBillPrint}>
              <div className="billPrintModal" style={{}}>
                <div style={{ padding: "5px", backgroundColor: "white" }}>
                  <ReportDesign templateName="FinalBillPrint" ord1Id={ItemDetail[0].ord1Id} setShowBillPrint={setShowBillPrint} />
                </div>
              </div>
            </div>
          </div>
        )
      }
      {loading && <Loader />}
    </div >
  )
}

export default ItemDetailModalBox
