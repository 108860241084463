import React, { useState } from 'react'
import FloatingLabelTextArea from './TextArea'
import InputBox from './InputBox'
import './WebsiteSetting.css'
import Dropdown from './Dropdown'

const WebsiteSetting = () => {


    const [inputValues, setInputValues] = useState({
        companyTitle: '',
        title: '',
        description: '',
        keywords: '',
        companyWebsite: '',
        headerScript: '',
        headerDescription: '',
        footerDescription: '',
        mobileTitleIcon: '',
        allowStaticFooter: '',
        allowPOSStore: '',
    });
    console.log("current State", inputValues)

    const Booloptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];

    const options = [
        { label: "Title", value: "Title" },
        { label: "Icon", value: "Icon" }
    ]

    console.log(inputValues)

    return (
        <div>
            <div className='heading-setting'>Website Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <InputBox label={"Company Title"}
                        name="companyTitle"
                        value={inputValues.companyTitle}
                        onChange={(newValue) => setInputValues({ ...inputValues, companyTitle: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Title"}
                        name="title"
                        value={inputValues.title}
                        onChange={(newValue) => setInputValues({ ...inputValues, title: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea label={"Description"}
                        name="description"
                        value={inputValues.description}
                        onChange={(newValue) => setInputValues({ ...inputValues, description: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Keywords"}
                        name="keywords"
                        value={inputValues.keywords}
                        onChange={(newValue) => setInputValues({ ...inputValues, keywords: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox label={"Company Website"}
                        name="companyWebsite"
                        value={inputValues.companyWebsite}
                        onChange={(newValue) => setInputValues({ ...inputValues, companyWebsite: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea label={"Header Script"}
                        name="headerScript"
                        value={inputValues.headerScript}
                        onChange={(newValue) => setInputValues({ ...inputValues, headerScript: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea label={"Header Description"}
                        name="headerDescription"
                        value={inputValues.headerDescription}
                        onChange={(newValue) => setInputValues({ ...inputValues, headerDescription: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea label={"Footer Description"}
                        name="footerDescription"
                        value={inputValues.footerDescription}
                        onChange={(newValue) => setInputValues({ ...inputValues, footerDescription: newValue })} />
                </div>
                <div className='grid-item-container'>

                    <Dropdown label={"Mobile Title/Icon"}
                        options={options}
                        selectedValue={inputValues.mobileTitleIcon}
                        onChange={(newValue) => setInputValues({ ...inputValues, mobileTitleIcon: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow Static Footer"}
                        options={Booloptions}
                        selectedValue={inputValues.allowStaticFooter}
                        onChange={(newValue) => setInputValues({ ...inputValues, allowStaticFooter: newValue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow POS Store"}
                        options={Booloptions}
                        selectedValue={inputValues.allowPOSStore}
                        onChange={(newValue) => setInputValues({ ...inputValues, allowPOSStore: newValue })}
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default WebsiteSetting
