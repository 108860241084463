import axios from 'axios';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

console.log(express_server_url);

const axiosInstance = axios.create({
    baseURL: express_server_url,
    withCredentials: true,
});

export default axiosInstance;
