import DateTime from "../Components/DateTime";
import React, { useEffect, useState } from "react";
import PageTabs from "../Components/PageTabs";
import Query from "../../API/APIServices/Query";
import { useNavigate } from 'react-router-dom';
//import { color } from "framer-motion";

function ModalQueryName(props) {
  /***********title***************/
  useState(() => {
    // document.title = "";
  }, []);

  /* *******************Tab****************** */

  const [activeTab, setActiveTab] = useState(11);

  const handleTabClick = (index) => {
    setActiveTab(index);

    if (index === 11) {
      showtabs11();
    }
    else if (index === 22) {
      showtabs22();
    }
    else if (index === 33) {
      showtabs33();
    }
  };

  const [tabcontent11, setTabContent11] = useState(true);
  const [tabcontent22, setTabContent22] = useState(false);
  const [tabcontent33, setTabContent33] = useState(false);

  const showtabs11 = () => {
    setTabContent11(true)
    setTabContent22(false)
    setTabContent33(false)
  }
  const showtabs22 = () => {
    setTabContent11(false)
    setTabContent22(true)
    setTabContent33(false)
  }
  const showtabs33 = () => {
    setTabContent11(false)
    setTabContent22(false)
    setTabContent33(true)
  }

  {/* *******************Tab****************** */ }

  const [isQueryMasterUpdate, setIsQueryMasterUpdate] = useState(false)

  const handleQueryFetch = async (event) => {
    props.handleInputChangeForQuery(event)
    var id = event.target.value;
    var data = await Query.getQueryById(id);
    console.log(data);

    if (data) {
      props.setInputValuesForQuery({
        id: data.id || "",
        title: data.title || "",
        query: data.query || "",
        query2: data.query2 || "",
        query3: data.query3 || "",
        isActive: true,
      });
      setIsQueryMasterUpdate(true);
    } else {
      props.setInputValuesForQuery({
        id: "",
        title: "",
        query: "",
        query2: "",
        query3: "",
        isActive: true,
      });
      //alert("data is not available");
    }
  };

  const saveQueryButton = async () => {
    debugger
    try {
      if (props.inputValuesForQuery.id != 0 && props.inputValuesForQuery.id != "" && props.inputValuesForQuery.id != null && props.inputValuesForQuery.id != undefined) {
        // Attempt to save the query
        await props.updateQuery();
      }
      else {
        // Attempt to save the query
        await props.SaveQuery();
      }
      // If successful, fetch the updated data
      fetchdata();
    } catch (error) {
      // Log the error or display an error message to the user
      console.error("Failed to save query and fetch data:", error);
      // Optionally, implement a more user-friendly error handling mechanism here,
      // such as displaying a notification or an alert to the user.
    }
  };

  /*const updateQueryButton = async () => {
    try {
      // Attempt to save the query
      await props.updateQuery();
      // If successful, fetch the updated data
      fetchdata();
    } catch (error) {
      // Log the error or display an error message to the user
      console.error("Failed to save query and fetch data:", error);
      // Optionally, implement a more user-friendly error handling mechanism here,
      // such as displaying a notification or an alert to the user.
    }
  };*/

  const deleteQueryButton = async () => {
    try {
      // Attempt to save the query
      await props.deleteQuery();
      // If successful, fetch the updated data
      fetchdata();
    } catch (error) {
      // Log the error or display an error message to the user
      console.error("Failed to save query and fetch data:", error);
      // Optionally, implement a more user-friendly error handling mechanism here,
      // such as displaying a notification or an alert to the user.
    }
  }

  const [QueryData, setQueryData] = useState({});

  const fetchdata = async () => {
    const data = await Query.getQuery();

    if (data) {
      setQueryData(data);
    } else {
      console.log(data);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <div>
      <PageTabs />
      <div>
        <DateTime />
      </div>
      <div className="HeadContentItemgroup">
        <div className="input-box-m11" style={{ paddingLeft: "0px" }}>
          <input
            className="modify_im_input mode_limit mode_category"
            type="text"
            required
            name="title"
            value={props.inputValuesForQuery.title}
            onChange={props.handleInputChangeForQuery}
          />
          <label className="modifyTextLabel">Title</label>
        </div>
        <div className="input-box-m11">
          <select
            className="modify_im_input mode_category"
            type="text"
            required
            name="id"
            value={props.inputValuesForQuery.id}
            onChange={(e) => handleQueryFetch(e)}
          >
            <option value='0'>--Select--</option>
            {Array.isArray(QueryData) ? (
              QueryData.map((Query) => (
                <option key={Query.id} value={Query.id}>
                  {Query.title || "Blank"}
                </option>
              ))
            ) : (
              <option>Blank</option>
            )}
          </select>
          <label className="ItemMasterLabelFix">Query Name</label>
        </div>
      </div>
      <div className="">
        <span style={{ color: "#a50000", fontWeight: "600" }}>Please Use This Parameter - @transId, @accId</span>

        <div className="tabs-main1 ">
          <div id="tabs" style={{ width: "100%" }}>
            <div className="tab-btn-box1 tab-b3" style={{ backgroundColor: "grey", borderRadius: "5px 5px 0px 0px", width: "98%" }}>
              <div
                className={`tab-b1 tab-2 ${activeTab === 11 ? 'active' : ''}`}
                onClick={() => { handleTabClick(11); }} >
                <label className="c-w col-black" style={{ textAlign: "center" }}>SQL Lite</label>
              </div>
              <div
                className={`tab-b1 tab-2 ${activeTab === 22 ? 'active' : ''}`}
                onClick={() => { handleTabClick(22); }} >
                <label className="c-w col-black" style={{ textAlign: "center" }}>SQL Server</label>
              </div>
              <div
                className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}
                onClick={() => { handleTabClick(33); }} >
                <label className="c-w col-black" style={{ textAlign: "center" }}>MySQL</label>
              </div>
            </div>

            <div className="tab-content-box1 ">
              {/************************* SQL Lite *****************************/}
              {tabcontent11 && (
                <div className="tab-content-1">
                  {/* <div className="input-box-top-head" >
                    <div className="input-box-tab-head Sms-Text-Box">
                      <div className="input-box-m2"> */}
                  <textarea
                    className="modify_im_textarea mode_category2 Sms-Template-box"
                    type="text"
                    placeholder="Query1"
                    required
                    name="query"
                    value={props.inputValuesForQuery.query}
                    onChange={props.handleInputChangeForQuery}
                  />
                </div>
                //     </div>
                //   </div>
                // </div>
              )}

              {/************************* SQL Server *****************************/}
              {tabcontent22 && (
                <div className="tab-content-2">
                  {/* <div className="input-box-top-head" >
                    <div className="input-box-tab-head Sms-Text-Box">
                      <div className="input-box-m2"> */}
                  <textarea
                    className="modify_im_textarea mode_category2 Sms-Template-box"
                    type="text"
                    placeholder="Query2"
                    required
                    name="query2"
                    value={props.inputValuesForQuery.query2}
                    onChange={props.handleInputChangeForQuery}
                  />
                </div>
                //     </div>
                //   </div>
                // </div>
              )}

              {/************************* MySQL ***********************/}
              {tabcontent33 && (
                <div className="tab-content-3">
                  {/* <div className="input-box-top-head" >
                    <div className="input-box-tab-head Sms-Text-Box">
                      <div className="input-box-m2"> */}
                  <textarea
                    className="modify_im_textarea mode_category2 Sms-Template-box"
                    type="text"
                    placeholder="Query3"
                    required
                    name="query3"
                    value={props.inputValuesForQuery.query3}
                    onChange={props.handleInputChangeForQuery}
                  />
                </div>
                //     </div>
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="greenBtn" style={{ width: "20%" }} onClick={saveQueryButton}>Save</button>
          {/*<button onClick={updateQueryButton}>Update</button>*/}
          {props.inputValuesForQuery.id != 0 && props.inputValuesForQuery.id != "" && props.inputValuesForQuery.id != null && props.inputValuesForQuery.id != undefined && (
            <button className="redBtn" onClick={deleteQueryButton} style={{ width: "20%" }}>Delete</button>
          )}
          <button className="redBtn" onClick={props.closeModal} style={{ width: "20%", backgroundColor: "#a42b40" }}>Cancel</button>
        </div>

      </div>
    </div>
  );
}

export default ModalQueryName;
