import React from 'react'
import { useState, useEffect } from "react";
import ColumnOne from "../Components/DraggableComponent/ColumnOne";
import TwoColumn from "../Components/DraggableComponent/TwoColumn";
import ThreeColumn from "../Components/DraggableComponent/ThreeColumn";
import ThreeAndSevenColumn from "../Components/DraggableComponent/ThreeAndSevenColumn";
import Text from "../Components/DraggableComponent/Text";
import Link from "../Components/DraggableComponent/Link";
import Image from "../Components/DraggableComponent/Image";
import Video from "../Components/DraggableComponent/Video";
import Map from "../Components/DraggableComponent/Map";
import LinkBlock from "../Components/DraggableComponent/LinkBlock";
import Quote from "../Components/DraggableComponent/Quote";
import TextSection from "../Components/DraggableComponent/TextSection";
import Form from "../Components/DraggableComponent/Form";
import Input from "../Components/DraggableComponent/Input";
import TextArea from "../Components/DraggableComponent/TextArea";
import Select from "../Components/DraggableComponent/Select";
import Button from "../Components/DraggableComponent/Button";
import Label from "../Components/DraggableComponent/Label";
import CheckBox from "../Components/DraggableComponent/CheckBox";
import Radio from "../Components/DraggableComponent/Radio";
import CountDown from "../Components/DraggableComponent/CountDown";
import Tabs from "../Components/DraggableComponent/Tabs";
import CustonCode from "../Components/DraggableComponent/CustonCode";
import ToolTip from "../Components/DraggableComponent/ToolTip";
import Typed from "../Components/DraggableComponent/Typed";
import ImageSlider from "../Components/DraggableComponent/ImageSlider";
import ToggleBox from "../Components/DraggableComponent/ToggleBox";
import Calendar from "../Components/DraggableComponent/Calender";
import IconBox from "../Components/DraggableComponent/IconBox";
import AlertBox from "../Components/DraggableComponent/AlertBox";
import SearchBox from "../Components/DraggableComponent/SearchBox";
import IconLibrary from "../Components/DraggableComponent/IconLibrary";
// import ColumnOneMain from "../Components/DraggableComponent/ColumnOneMain";
import NavBarTypes from "../Components/DraggableComponent/NavBarTypes";
import LogoBox from "../Components/DraggableComponent/LogoBox";
import Footer from '../Components/DraggableComponent/Footer';
import TextBox from '../Components/DraggableComponent/TextBox';


const Home = () => {

  const DropTarget1 = () => {
    const [droppedItems1, setDroppedItems1] = useState([]);
    // useEffect(() => {
    //   // Load saved items from localStorage on initial mount
    //   const savedItems = localStorage.getItem("droppedItems1");
    //   if (savedItems) {
    //     setDroppedItems1(JSON.parse(savedItems));
    //   }
    // }, []);

    // useEffect(() => {
    //   localStorage.setItem("droppedItems1", JSON.stringify(droppedItems1));
    // }, [droppedItems1]);


    //  const handleRightClick = (event) => {
    //     event.preventDefault(); // Prevent the default context menu from appearing
    //     myFunction();
    //   };

    //  const myFunction = () => {
    //     alert('You right-clicked!');
    //     // Add your desired functionality here
    //   };

    const handleDrop = (e) => {
      // alert("function one")
      e.preventDefault();
      // e.stopPropation()  

      console.log(e.id);
      const itemIds = e.dataTransfer.getData("text").split(","); // Split the comma-separated itemIds into an array
      console.log(itemIds);
      console.log("JIT 1 :" + e.target.id);
      setDroppedItems1((prevItems) => [
        ...prevItems,
        { key: itemIds, id: Date.now(), items: itemIds, dropAreaId: e.timeStamp },
      ]);
    };

    const handleRemoveItem = (itemId) => {
      setDroppedItems1((prevItems) =>
        prevItems.filter((item) => item.id !== itemId)
      );
    };

    const dragstart = (e, id) => {
      e.dataTransfer.setData("text/plain", id.toString());
    };

    const dragover = (e) => {
      e.preventDefault();
    };


    const onDrop = (e, dropid) => {
      // alert("function second")

      e.preventDefault();
      console.log(e + "e part");

      console.log(dropid + "dropid part");
      let dragId = parseInt(e.dataTransfer.getData("text/plain"), 10);
      console.log(dragId + "dragid");
      let dragIndex = droppedItems1.findIndex(
        (droppedItems1) => droppedItems1.id === dragId
      );
      let dropIndex = droppedItems1.findIndex(
        (droppedItems1) => droppedItems1.id === dropid
      );
      if (dragIndex === -1 || dropIndex === -1) {
        return;
      }

      const newArray = [...droppedItems1];
      const NewDragindex = droppedItems1[dragIndex];
      const NewDropindex = droppedItems1[dropIndex];
      newArray[dragIndex] = NewDropindex;
      newArray[dropIndex] = NewDragindex;

      setDroppedItems1(newArray);
    };

    return (
      <div
        // onContextMenu={handleRightClick}
        className="drop-target-web-template"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {droppedItems1.map((droppedItem, index) => (
          <div key={droppedItem.id} className="dropped-item">
            {droppedItem.items.map((itemId) => (
              <>
                <div style={{ position: "relative", width: "100%" }} key={droppedItem.id}>
                  {(() => {
                    switch (itemId) {
                      case "1":
                        return (
                          <div>
                            {<ColumnOne
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id,)}
                              keyValueId={droppedItem.id}

                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "2":
                        return (
                          <div>
                            {<TwoColumn
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                              keyValueId={droppedItem.id}
                            />}
                            <button
                              id="three-column-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "3":
                        return (
                          <div>
                            {<ThreeColumn
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                              keyValueId={droppedItem.id}
                            />}
                            <button
                              id="three-column-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "4":
                        return (
                          <div>
                            {<ThreeAndSevenColumn
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "5":
                        return (
                          <div>
                            {<Text
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete-web-template"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "6":
                        return (
                          <div>
                            {<Link
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "7":
                        return (
                          <div>
                            {<Image
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="image-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "8":
                        return (
                          <div>
                            {<Video
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "9":
                        return (
                          <div>
                            {<Map
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "10":
                        return (
                          <div>
                            {<LinkBlock
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "11":
                        return (
                          <div>
                            {<Quote
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "12":
                        return (
                          <div>
                            {<TextSection
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "13":
                        return (
                          <div>
                            {<Form
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="form-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "14":
                        return (
                          <div>
                            {<Input
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "15":
                        return (
                          <div>
                            {<TextArea
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="textarea-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "16":
                        return (
                          <div>
                            {<Select
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "17":
                        return (
                          <div>
                            {<Button
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "18":
                        return (
                          <div>
                            {<Label
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "19":
                        return (
                          <div>
                            {<CheckBox
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "20":
                        return (
                          <div>
                            {<Radio
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "21":
                        return (
                          <div>
                            {<CountDown
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="countdown-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "22":
                        return (
                          <div>
                            {<Tabs
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="tabs-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "23":
                        return (
                          <div>
                            {<CustonCode
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "24":
                        return (
                          <div>
                            {<ToolTip
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "25":
                        return (
                          <div>
                            {<Typed
                              key={itemId}
                              draggable

                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "26":
                        return (
                          <div>
                            {<ImageSlider
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "27":
                        return (
                          <div>
                            {<ToggleBox
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "28":
                        return (
                          <div>
                            {<Calendar
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "29":
                        return (
                          <div>
                            {<AlertBox
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "30":
                        return (
                          <div>
                            {<IconBox
                              key={itemId}
                              draggable
                              // handleClick={() => {
                              //     removequestion(element.id);
                              // }}
                              handleDrag={(e) => {
                                dragstart(e, droppedItem.id);
                              }}
                              dragover={(e) => {
                                dragover(e);
                              }}
                              handleDrop={(e) => onDrop(e, droppedItem.id)}
                            />}
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "31":
                        return (
                          <div>
                            {
                              <SearchBox
                                draggable
                                // handleClick={() => {
                                //     removequestion(element.id);
                                // }}
                                handleDrag={(e) => {
                                  dragstart();
                                }}
                                dragover={(e) => {
                                  dragover(e);
                                }}
                                handleDrop={(e) => onDrop()}
                              />
                            }
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "32":
                        return (
                          <div>
                            {
                              <IconLibrary
                                draggable
                                // handleClick={() => {
                                //     removequestion(element.id);
                                // }}
                                handleDrag={(e) => {
                                  dragstart();
                                }}
                                dragover={(e) => {
                                  dragover(e);
                                }}
                                handleDrop={(e) => onDrop()}
                              />
                            }
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "33":
                        return (
                          <div>
                            {
                              <NavBarTypes
                                draggable
                                // handleClick={() => {
                                //     removequestion(element.id);
                                // }}
                                handleDrag={(e) => {
                                  dragstart();
                                }}
                                dragover={(e) => {
                                  dragover(e);
                                }}
                                handleDrop={(e) => onDrop()}
                              />
                            }
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "34":
                        return (
                          <div>
                            {
                              <LogoBox
                                draggable
                                // handleClick={() => {
                                //     removequestion(element.id);
                                // }}
                                handleDrag={(e) => {
                                  dragstart();
                                }}
                                dragover={(e) => {
                                  dragover(e);
                                }}
                                handleDrop={(e) => onDrop()}
                              />
                            }
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      case "35":
                        return (
                          <div>
                            {
                              <Footer
                                draggable
                                // handleClick={() => {
                                //     removequestion(element.id);
                                // }}
                                handleDrag={(e) => {
                                  dragstart();
                                }}
                                dragover={(e) => {
                                  dragover(e);
                                }}
                                handleDrop={(e) => onDrop()}
                              />
                            }
                            <button
                              id="column-one-delete"
                              className="component-delete"
                              onClick={() => handleRemoveItem(droppedItem.id)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        );
                      // case "39":
                      //   return (
                      //     <div>
                      //       {<TextBox
                      //         key={itemId}
                      //         draggable

                      //         handleDrag={(e) => {
                      //           dragstart(e, droppedItem.id);
                      //         }}
                      //         dragover={(e) => {
                      //           dragover(e);
                      //         }}
                      //         handleDrop={(e) => onDrop(e, droppedItem.id)}
                      //       />}
                      //       <button
                      //         id="column-one-delete"
                      //         className="component-delete-web-template"
                      //         onClick={() => handleRemoveItem(droppedItem.id)}
                      //       >
                      //         <i className="fa-solid fa-trash"></i>
                      //       </button>
                      //     </div>
                      //   );

                      default: return null
                    }
                  })()}
                </div>
              </>
            ))}
          </div>
        ))}
      </div>
    );
  };


  return (
    <DropTarget1 />
  )
}

export default Home