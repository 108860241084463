import React, { useState } from 'react';
import './Page4.css';


function FloatingLabelInput({ label, name, value, onChange }) {
    const [isFocused, setIsFocused] = useState(false);
    
    return (
        <div className={`floating-label-input ${isFocused ? 'focused' : ''}`}>
            <input
                type="text"
                name={name}
                value={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={onChange}
            />
            <label>{label}</label>
        </div>
    );
}

export default FloatingLabelInput;
