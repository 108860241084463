
import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";

function XLS() {
    /***********title***************/
    useState(() => {
        document.title = 'XLS User';
    }, []);

    ////************File Upload CSV and XLS******************///

    ////************File Upload CSV and XLS******************///
    //************modal box************/ /
    const [, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    //************modal box************/ /


    /*****************Onboarding code************************/
    const [isModalOpenxls1, setIsModalOpenxls1] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
  
    const showModalxls1 = () => {
      if (selectedFile) {
        setIsModalOpenxls1(true);
      } else {
        alert('Please choose a file before uploading.');
      }
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };


    /***********{Save Button}*****************/

    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    return (
        <div>
            < PageTabs />
            {/* <TopNamePage heading="ModifyProduct"
            /> */}
            <div>

            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">File Import</h2>
                </div >
                 {/* *******************Top Buttons****************** */}
                 <div className="panel-head-button">
                    <div className="col_1">
                    
                        <div className="button-m5">
                            <i id="step-2" onMouseEnter={() => setIsHovered3(true)}
                                onMouseLeave={() => setIsHovered3(false)}
                                className="fa-solid fa-rotate im-icon-3" style={{ color: "white" }}></i>
                            {isHovered3 && <div className="hover-text1">Reset</div>}<br />
                        </div>

                        <div className="button-m5">
                            <i id="step-2" onMouseEnter={() => setIsHovered4(true)}
                                onMouseLeave={() => setIsHovered4(false)}
                                className="fa-solid fa-file-export im-icon-3" style={{ color: "white" }}></i>
                            {isHovered4 && <div className="hover-text1" style={{ marginLeft: "-3%" }}>Save</div>}<br />
                        </div>
                    </div>
                </div>

                
            </div>
            
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1 item_scroll">
                                        <div className="control_radius xls_radius">
                                            {/* <div className="company_info-text-im xls_top">Import File</div> */}
                                            <div className="company_control_xls">
                                                <div className="stock-im-xls">
                                                    <div className="company_info-text-im hbn1 excel_xls">Select Excel/CSV File</div>
                                                    <div className="search-box-btn-1">
                                                        <input
                                                            className="modify_im_input mode_limit mode_category col-1"
                                                            type="file"
                                                            accept=".csv, .xls, .xlsx"
                                                            onChange={handleFileChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2 upload_flex_xls">
                                                    <div className="button-mp1 mode_reset">
                                                            <button className="upload_btn" onClick={showModalxls1}>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                    {isModalOpenxls1 &&(
                                        <div className="control_radius xls_radius">
                                            {/* <div className="company_info-text-im xls_top">Import List</div> */}
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                                        <thead textAlign="center" className="thead_scroll_col1" >
                                                            <tr className="">
                                                                <th className="td_col5"> <div className="table_head_mmb ">
                                                                    <div className="table_head_mmb1">S.No.</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Receipt No</div>
                                                                </div> </th><th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Admission No</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Name</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Session</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Course</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">PreFix</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Year/Sem</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Amount</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Date</div>
                                                                </div> </th>
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Transaction Id</div>
                                                                </div> </th>    
                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                    <div className="table_head_mmb1">Remarks</div>
                                                                </div> </th>
                                                                <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tbody_body_m1">
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">3</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">4</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">5</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">6</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                            <tr className="table_tbody_tr1">
                                                                <td className="td_col5">7</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1220091</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Jayant</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>2022-23</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Primary HM</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>6000</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>22-02-2023</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>0410-1</td>
                                                                <td className="td_col5" style={{ textalign: "start" }}>Correct</td>
                                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default XLS;
