import React, { useContext, useEffect, useState } from "react";
import Loader from "../../Inventory/Props/Loader";
import AlertBox from "../../Inventory/Props/AlertBox";
// import TransportMaster from "../../Inventory/Master/AccountMaster/TransportMaster";
import DeliveryTimeSlotSetting from "./../../Restaurant/Orders/DeliveryTimeSlotSetting"
import DataTable from "../../Inventory/Master/TableMaster/DataTable";
import MyContext from "../../Inventory/MyContext";
import PageTabs from "../../Inventory/Components/PageTabs";
import TableButton from "../../Inventory/Props/TableButton";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";

const DeliveryTimeSlotSettingList = () => {
  useState(() => {
    document.title = "Delivery TimeSlot Setting List";
  }, []);

  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
  } = useContext(MyContext);
  const [mainData, setMainData] = useState([]);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSlider = (value) => {
    setSliderOpen(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlotFilter();
      console.log(val);
      const newData = val && val.length > 0 && val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          website: item.website,
          mobile: item.mobile,
          id: item.id,
          delvryDistRangeFromKM: item.delvryDistRangeFromKM,
          delvryDistRangeToKM: item.delvryDistRangeToKM,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);
  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await DeliveryAndTimeSlot.deleteDeliveryAndTimeSlot(getdeleteId);
      if (Boolean(val) === true) {
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState();

  const Edit = async (id) => {
    setEditData(id);
    toggleSlider(true);
  };

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "Website", accessor: "website" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "id", accessor: "id" },
    { Header: "delvryDistRangeFromKM", accessor: "delvryDistRangeFromKM" },
    { Header: "delvryDistRangeToKM", accessor: "delvryDistRangeToKM" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },

    {
      Header: <input type="text" placeholder="Website" ref={input2} />,
      accessor: "Website",
    },
    {
      Header: <input type="text" placeholder="Mobile" ref={input3} />,
      accessor: "Mobile",
    },
    {
      Header: <input type="text" placeholder="id" ref={input4} />,
      accessor: "id",
    },
    {
      Header: <input type="text" placeholder="delvryDistRangeFromKM" ref={input5} />,
      accessor: "delvryDistRangeFromKM",
    },
    {
      Header: <input type="text" placeholder="delvryDistRangeToKM" ref={input1} />,
      accessor: "delvryDistRangeToKM",
    },
  ];
  return (
    <div className="">
      <PageTabs
        locationTab={"/DeliveryTimeSlotSetting"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Delivery TimeSlot Setting List</h2>
          <TableButton PageCreate={"/DeliveryTimeSlotSetting"} PageName="Masters" />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />
      </div>
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <DeliveryTimeSlotSetting
            // data={mainData}
            editData={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
          />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default DeliveryTimeSlotSettingList;