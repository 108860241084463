import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Order from '../API/APIServices/Order';
import ApiConfiguration from '../API/APIServices/APIConfiguration';
import Loader from '../Inventory/Props/Loader';

const Request = () => {
    // handleSubmit = (event) => {
    //     event.preventDefault();
    // }

    const location = useLocation();
    const { orderNo, ord1Id } = location.state || {}

    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const filteredData = [{
                    "fieldName": "orderNo",
                    "operatorName": "equal",
                    "compareValue": orderNo
                }];

                const data = await Order.getOrderListFilter(filteredData);

                // const data = await Order.getOrderListByOrderNoFilter(filteredData);
                setOrderData(data);
                const grandTotal = parseFloat(data[0].grandTotal);
                const paymentAmount = parseFloat(data[0].paymentAmount);
                const remainingAmount = Math.round((grandTotal - paymentAmount) * 100);

                document.getElementById('amount').value = remainingAmount;
                document.getElementById('contact').value = data[0].accMobileNo;
                // document.getElementById('name').value = data.;
                // document.getElementById('description').value = data.;
                // document.getElementById('email').value = data.;
                // document.getElementById('notes').value = data.;
                // document.getElementById('callback').value = data.;
                console.log("data123", data)
            } catch (error) {
                console.error("Error fetching order data:", error);
                alert(error);
            }
        };
        fetchData();
    }, [])

    const [keyValue, setKeyValue] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const filteredData = [{
                    "fieldName": "isDefault",
                    "operatorName": "equal",
                    "compareValue": true
                }];
                const data = await ApiConfiguration.getAPIConfigurationFilter(60, filteredData);  //ConfigType(Payment Gateway-60)
                setKeyValue(data);
                document.getElementById('key').value = data.workingKey;
                console.log("keydata", data)
            } catch (error) {
                console.error("Error fetching API configuration:", error);
                alert(error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSubmit();
        }, 5000); // 5 seconds delay

        return () => clearTimeout(timer); // Cleanup function to clear the timer
    }, [orderData, keyValue]);

    const handleSubmit = () => {
        const buttonId = document.getElementById('razorpay-payment-button')
        buttonId.click()
    }

    return (
        <div>
            {loading && <Loader />}
            <form id="myForm" method="post" action="https://api.razorpay.com/v1/checkout/embedded" style={{ display: "none" }}>
                <input type="hidden" id="key" name="key_id" value={keyValue ? keyValue.workingKey : 'rzp_test_6gzv7z3KTwC4Gp'} />
                {/* <input type="hidden" name="order_id" value={orderData ? orderData.orderNo : 'order_12660'} /> */}
                <input type="hidden" id="amount" name="amount" value={orderData ? Math.round(orderData[0].grandTotal - orderData[0].paymentAmount) * 100 : '0'} />
                <input type="hidden" id="name" name="name" value="Mothershut" />
                <input type="hidden" id="description" name="description" value="Description" />
                <input type="hidden" id="email" name="prefill[email]" value="gaurav.kumar@example.com" />
                <input type="hidden" id="contact" name="prefill[contact]" value={orderData ? orderData[0].accMobileNo : '9876543210'} />
                <input type="hidden" id="notes" name="notes[transaction_id]" value="transaction_1234" />
                <input type="hidden" id="callback" name="callback_url" value="http://110.227.221.191:24754/Response" />
                <button id="razorpay-payment-button" type="submit">Submit</button>
            </form>
        </div>
    );
}

export default Request;
