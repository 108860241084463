import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";
import Select from 'react-select';

function AddRecipe() {
    /***********title***************/
    useState(() => {
        document.title = 'Add Recipe';
    }, []);

    ////************Image add******************///

    //******************{modal box}******************/ /
    const [setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };


    /**************{attribute code}********************/

    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };


    const [selectedOption, setSelectedOption] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHideChanges, setIsHideChanges] = useState(true);


    const options = [
        { value: '0', label: '--Select Item--' },
        { value: '1', label: 'Pav Bhaji' },
        { value: '2', label: 'Cheese Pav Bhaji' },
        { value: '3', label: 'Pav' },
        { value: '4', label: 'Wadapav' },
    ];

    const handleSelectChange = (selected) => {
        setSelectedOption(selected);
        setIsModalOpen(true);
        setIsHideChanges(false);
    };

    //******************* {Butter Modal box} ******************//
    const [isModalOpenButter, setIsModalOpenButter] = useState(false);
    const [isModalHideSelectButter, setisModalHideSelectButter] = useState(true);

    const handleSelectChangeButter = () => {
        setIsModalOpenButter(false);
        setisModalHideSelectButter(false);
    };

    //******************* {Add and Delelte Row Jumo} ******************//
    const [tableData, setTableData] = useState([
        { id: 1 }
    ]);

    const handleAddRow = () => {
        const newRow = { id: tableData.length + 1 };
        console.log(newRow);
        setTableData([...tableData, newRow]);
    };
    const handleDeleteRow = (id) => {
        const updatedTableData = tableData.filter(row => row.id !== id);

        const updatedTableDataWithSn = updatedTableData.map((row, index) => ({
            ...row,
            id: index + 1
        }));

        setTableData(updatedTableDataWithSn);
    };

    //******************* {Add and Delelte Row Jumbo Butter} ******************//
    const [tableDataButter, setTableDataButter] = useState([
        { id: 1 }
    ]);

    const handleAddRowButter = () => {
        const newRow = { id: tableDataButter.length + 1 };
        console.log(newRow);
        setTableDataButter([...tableDataButter, newRow]);
    };
    const handleDeleteRowButter = (id) => {
        const updatedTableData = tableDataButter.filter(row => row.id !== id);

        const updatedTableDataWithSn = updatedTableData.map((row, index) => ({
            ...row,
            id: index + 1
        }));

        setTableDataButter(updatedTableDataWithSn);
    };

    //******************* {Jumbo Name Select and search } ******************//
    const [selectedOptionsNameRecipe, setSelectedOptionsNameRecipe] = useState([]);
    const optionsnamerecipe = [
        { value: '', label: 'Select Raw-Material' },
        { value: '1', label: 'Potato' },
        { value: '2', label: 'Green Pea' },
        { value: '3', label: 'Oil' },
        { value: '4', label: 'Salt' },
        { value: '5', label: 'Pav' },
        { value: '6', label: 'Pav Bhaji Masala Paste' },
        { value: '7', label: 'Pav Bhaji Spice Mix' },
        { value: '8', label: 'Packaging Box' },

    ];

    const handleOptionChangeNameRecipe = (optionValue) => {
        setSelectedOptionsNameRecipe(optionValue)
    };

    //******************* {Jumbo Butter select and search } ******************//

    const [selectedOptionsNameButter, setSelectedOptionsNameButter] = useState([]);
    const optionsnamebutter = [
        { value: '', label: 'Select Raw-Material' },
        { value: '1', label: 'Potato' },
        { value: '2', label: 'Green Pea' },
        { value: '3', label: 'Oil' },
        { value: '4', label: 'Salt' },
        { value: '5', label: 'Pav' },
        { value: '6', label: 'Pav Bhaji Masala Paste' },
        { value: '7', label: 'Pav Bhaji Spice Mix' },
        { value: '8', label: 'Packaging Box' },

    ];

    const handleOptionChangeNameButter = (optionValue) => {
        setSelectedOptionsNameButter(optionValue);
    };

    //*******************Jumbo Area Select and search ******************//
    const [selectedOptionsJumboArea, setSelectedOptionsJumboArea] = useState([]);

    const optionsjumboarea = [
        { value: 'ac', label: 'AC' },
        { value: 'garden', label: 'Garden' },
        { value: 'family', label: 'Family' },
        { value: 'nonac', label: 'Non Ac' },
        { value: 'banquet', label: 'Banquet' },
        { value: 'acsmoking', label: 'Ac Smoking' },
        { value: 'onlineorder', label: 'Online Orders' },
        { value: 'takeaway', label: 'Take Away' },
        { value: 'dining', label: 'Dining' },
        { value: 'dinein', label: 'Dine in' },


        // Add more options as needed
    ];

    const handleChangeJumboArea = (selected) => {
        setSelectedOptionsJumboArea(selected);
    };


    //*******************Area Butter Select and search******************//

    const [selectedOptionsJumboAreaButter, setSelectedOptionsJumboAreaButter] = useState([]);

    const optionsjumboareabutter = [
        { value: 'ac', label: 'AC' },
        { value: 'garden', label: 'Garden' },
        { value: 'family', label: 'Family' },
        { value: 'nonac', label: 'Non Ac' },
        { value: 'banquet', label: 'Banquet' },
        { value: 'acsmoking', label: 'Ac Smoking' },
        { value: 'onlineorder', label: 'Online Orders' },
        { value: 'takeaway', label: 'Take Away' },
        { value: 'dining', label: 'Dining' },
        { value: 'dinein', label: 'Dine in' },
    ];

    const handleChangeJumboAreaButter = (selected) => {
        setSelectedOptionsJumboAreaButter(selected);
    };

    //*******************Add Additional Cost******************//
    const [isModalAddAddtionalCost, setModalAddAddtionalCost] = useState(false);

    const handleAddAdditionalCostClick = () => {
        setModalAddAddtionalCost(!isModalAddAddtionalCost);
    };

    //******************* {Add and Delelte Row Additional} ******************//
    const [tableDataCost, setTableDataCost] = useState([
        { id: 1 }
    ]);

    const handleAddRowCost = () => {
        const newRow = { id: tableDataCost.length + 1 };
        console.log(newRow);
        setTableDataCost([...tableDataCost, newRow]);
    };
    const handleDeleteRowCost = (id) => {
        const updatedTableData = tableDataCost.filter(row => row.id !== id);

        const updatedTableDataWithSn = updatedTableData.map((row, index) => ({
            ...row,
            id: index + 1
        }));

        setTableDataCost(updatedTableDataWithSn);
    };


    return (
        <div>
            < PageTabs />
            <div>

            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Add RCP/MFG</h2>
                </div >

                {/* ********************************{Buttob}********************************* */}
                <div className="panel-head-button">
                    <div className="col_1">
                        <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Save
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="button-m5">
                            <button className="save-button-main">Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>

                    </div>
                </div>


            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">

                                        <div className="control_radius">
                                            {/* <div className="container_Recipe-Add"> */}
                                            <div className="Add_Recipe_top">
                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="input-AddSelect-BoxRecipe">
                                                        <Select
                                                            className="modify_im_input border_hide"
                                                            options={options}
                                                            value={selectedOption}
                                                            placeholder="--Select Item--"
                                                            isSearchable={true}
                                                            onChange={handleSelectChange}
                                                            required />

                                                        {/* <label className="modify_lbl_text3">Department Name</label> */}
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-NewRcp">
                                                        <select className="modify_im_input mode_category" type="text" required>
                                                            <option>Small</option>
                                                            <option>Medium</option>
                                                            <option>Large</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Variation</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {isHideChanges && (
                                                <div className="Recipe_change_Item ">
                                                    <div className="stock-recipe-MGM">

                                                        <div className="input-box-Imo1 input-flex-Addrecipe">
                                                            <div className="button-m5">
                                                                <button className="save-button-main">Cancel
                                                                </button>
                                                            </div>
                                                            <div className="button-m5">
                                                                <button className="NewRecipe-button-main-search">Save Changes
                                                                    {/* <i id="step-2" className="fa-solid fa-plus im-icon-2"></i> */}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {isModalOpen && (
                                                <div className="company-info-top-left1" >
                                                    <div className="company-info-top-left1" >
                                                        <div className="company_info-text-im">Recipe For Pav Bhaji[Jumbo]</div>
                                                        <div>
                                                            <div className="recipe_add_height overflowY Recipe_PAV_head" >
                                                                <table style={{ width: "100%" }}>
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>S.No.</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Name</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Quantity</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Unit</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Area</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Godown</th>
                                                                            <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {tableData.map((row) => (
                                                                            <tr key={row.id}>
                                                                                <td className="td Add_rcp_td">{row.id}</td>
                                                                                <td className="td Add_rcp_td">
                                                                                    <div>

                                                                                        <Select className="modify_im_input border_hide" name="select " 
                                                                                            options={optionsnamerecipe} multi
                                                                                            id="SelectBoxValue"
                                                                                            optionValue="label"
                                                                                            value={selectedOptionsNameRecipe}
                                                                                            onChange={handleOptionChangeNameRecipe}
                                                                                            placeholder="Select Raw-Material"
                                                                                        >
                                                                                        </Select>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="td Add_rcp_td">
                                                                                    <input type="text" className="tableSelectBox" placeholder="" />
                                                                                </td>
                                                                                <td className="td Add_rcp_td">
                                                                                    <select className="tableSelectBox">
                                                                                        <option>Kg</option>
                                                                                        <option>Ltr.</option>
                                                                                        <option>GM</option>
                                                                                        <option>ML</option>
                                                                                        <option>Piece</option>
                                                                                        <option>Gram</option>
                                                                                        <option>Bag</option>
                                                                                    </select>
                                                                                </td>


                                                                                <td>
                                                                                    <Select className="modify_im_input border_hide"
                                                                                        isMulti
                                                                                        options={optionsjumboarea}
                                                                                        value={selectedOptionsJumboArea}
                                                                                        onChange={handleChangeJumboArea}
                                                                                        placeholder=""
                                                                                        isSearchable
                                                                                    />
                                                                                </td>
                                                                                <td className="td Add_rcp_td">
                                                                                    <select type="text" className="tableSelectBox" placeholder="">
                                                                                        <option>Godown 1</option>
                                                                                        <option>Godown 2</option>
                                                                                    </select>
                                                                                </td>
                                                                                <td className="td Add_rcp_td"><i className="fa-solid fa-trash trashIcon" onClick={() => handleDeleteRow(row.id)}></i></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="Recipe_ADD_flex-end">
                                                                {/* <button className="newRowBtn mt-2" onClick={handleAddRow} style={{ backgroundColor: "darkgreen" }}>+ Add Row</button> */}
                                                                <i className="fa-solid fa-circle-plus Add_rows1 dm_4 " onClick={handleAddRow}></i>
                                                            </div>
                                                            {isModalHideSelectButter && (
                                                                <div className="input-box-Imo1 input-height_Recipe">
                                                                    <div className="input-box-mok1">

                                                                        <select className="modify_im_input mode_Rcp_BTR" type="text" onClick={() => setIsModalOpenButter(!isModalOpenButter)} onChange={handleSelectChangeButter} required>
                                                                            <option>Recipe For Butter</option>
                                                                        </select>
                                                                        {/* <label className="modify_lbl_text3">Department Name</label> */}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {isModalOpenButter && (
                                                            <div className="company-info-top-left1" >
                                                                <div className="company_info-text-im">Recipe For Butter</div>
                                                                <div>
                                                                    <div className="recipe_add_height overflowY Recipe_PAV_head" >
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>S.No.</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Name</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Quantity</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Unit</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Area</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Godown</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {tableDataButter.map((row) => (
                                                                                    <tr key={row.id}>
                                                                                        <td className="td Add_rcp_td">{row.id}</td>
                                                                                        <td className="td Add_rcp_td RCP-width-BTR">
                                                                                            <div>

                                                                                                <Select className="modify_im_input border_hide" name="select" options={optionsnamebutter} multi
                                                                                                    
                                                                                                    optionValue="label"
                                                                                                    value={selectedOptionsNameButter}
                                                                                                    onChange={handleOptionChangeNameButter}
                                                                                                >
                                                                                                </Select>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="td Add_rcp_td">
                                                                                            <input type="text" className="tableSelectBox" placeholder="" />
                                                                                        </td>
                                                                                        <td className="td Add_rcp_td">
                                                                                            <select className="tableSelectBox">
                                                                                                <option>Kg</option>
                                                                                                <option>Ltr.</option>
                                                                                                <option>GM</option>
                                                                                                <option>ML</option>
                                                                                                <option>Piece</option>
                                                                                                <option>gram</option>
                                                                                            </select>
                                                                                        </td>

                                                                                        <td>
                                                                                            <Select className="modify_im_input border_hide"
                                                                                                isMulti
                                                                                                options={optionsjumboareabutter}
                                                                                                value={selectedOptionsJumboAreaButter}
                                                                                                onChange={handleChangeJumboAreaButter}
                                                                                                placeholder=""
                                                                                                isSearchable
                                                                                            />
                                                                                        </td>

                                                                                        <td className="td Add_rcp_td">
                                                                                            <select type="text" className="tableSelectBox" placeholder="">
                                                                                                <option>Godown 1</option>
                                                                                                <option>Godown 2</option>
                                                                                            </select>
                                                                                        </td>

                                                                                        <td className="td Add_rcp_td"><i className="fa-solid fa-trash trashIcon" onClick={() => handleDeleteRowButter(row.id)}></i></td>
                                                                                        {/* <td className="td Add_rcp_td"><i className="fa-solid fa-circle-plus Add_rows" onClick={handleAddRowButter}></i></td> */}
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="Recipe_ADD_flex-end">
                                                                        {/* <button className="newRowBtn mt-2" onClick={handleAddRowButter} style={{ backgroundColor: "darkgreen" }}>+ Add Row</button> */}
                                                                        <i className="fa-solid fa-circle-plus Add_rows2 dm_4" onClick={handleAddRowButter}></i>
                                                                    </div>

                                                                </div>

                                                                {/* </div> */}
                                                            </div>
                                                        )}

                                                        <div className="Recipe_ADD_flex-end Additional_Cost">
                                                            <button className="newRowBtn mt-2" style={{ backgroundColor: "#212e4f" }} onClick={handleAddAdditionalCostClick}>+ Add Additional Cost</button>
                                                        </div>


                                                        {isModalAddAddtionalCost && (
                                                            <div className="company-info-top-left1" >
                                                                {/* <div className="Recipe_radius_add"> */}
                                                                <div className="company_info-text-im">Additional Cost</div>
                                                                <div>
                                                                    <div className="recipe_add_height overflowY Recipe_PAV_head" >
                                                                        {/*<label className="ques" style={{ textAlign: "center" }}>Allowance</label>*/}
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>S.No.</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Charges</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Estimated Cost (₹)</th>
                                                                                    <th className="th head_RCP_brd" style={{ backgroundColor: "#5e709e" }}>Action</th>
                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>
                                                                                {tableDataCost.map((row) => (
                                                                                    <tr key={row.id}>
                                                                                        <td className="td Add_rcp_td">{row.id}</td>
                                                                                        <td className="input-box-m11 Additional_width">
                                                                                            <select className="modify_im_input mode_category" type="text" required>
                                                                                                <option>Electricity Cost</option>
                                                                                                <option>Labour Cost</option>
                                                                                            </select>
                                                                                        </td>

                                                                                        <td className="input-box-m11 Additional_width">
                                                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                                                        </td>
                                                                                        <td className="td Add_rcp_td"><i className="fa-solid fa-trash trashIcon" onClick={() => handleDeleteRowCost(row.id)}></i></td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="Recipe_ADD_flex-end">
                                                                        {/* <button className="newRowBtn mt-2" onClick={handleAddRowCost} style={{ backgroundColor: "darkgreen" }}>+ Add Row</button> */}
                                                                        <i className="fa-solid fa-circle-plus Add_rows dm_4" onClick={handleAddRowCost}></i>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}
                                                            </div>
                                                        )}

                                                        <div className="input-box-Imo1 input-flex-Addrecipe">
                                                            <div className="button-m5 BtnSave-Margin-Right">
                                                                <button className="NewRecipe-button-main-search">Save
                                                                    {/* <i id="step-2" className="fa-solid fa-plus im-icon-2"></i> */}
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            )}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default AddRecipe;
