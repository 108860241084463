import PageTabs from "../Components/PageTabs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainButton from "../Props/MainButton";


function TraceLensStatus() {

    //**********xls modal box*****************//
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    return (
        <div className="" >
            < PageTabs />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Universal Lens Status Trace</h2>
                    <MainButton />
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left">
                                <div className="col-sm-n1">
                                    {/*<div className="group_head">*/}
                                        
                                    {/*</div>*/}
                                    <div className="group_head">
                                        <input placeholder="Trans Type" type="text" className="bill_panel_input svl_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date From" type="date" className="date_panel_input svl_date_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date To" type="date" className="date_panel_input svl_date_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Status" type="text" className="date_panel_input svl_date_input" />
                                    </div>
                                    <div className="group_head">
                                        <svg className="search_panel_icon svl_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                        <input placeholder="Search" type="search" className="searchbar_panel_input " />
                                    </div>
                                </div>
                            </div>

                            <div className="search_panel_right">
                                <div className="search_panel_f1" >
                                    <div className="button-mp1">
                                        <button className="table_search_btn">
                                            <i className="fa-solid fa-magnifying-glass im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp2" >
                                        <button className="table_reset_btn">
                                            <i className="fa-solid fa-rotate im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp3">
                                        <Link to="/SaleInvoice" id="step-5" className="add_btn">
                                            <i className="fa-solid fa-square-plus im-icon-6"></i>
                                        </Link>
                                    </div>
                                    <div className="button-mp4" onClick={showModal}>
                                        <button className="xls_btn">
                                            <i className="fa-solid fa-file-excel im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp5">
                                        <button className="Print_btn">
                                            <i className="fa-solid fa-print im-icon-6"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {isModalOpen && (
                                <div className="modal" >
                                    <div className="modal-content_xls">
                                        <div className="modal-title">
                                            <span className="close" onClick={hideModal}>
                                                &times;
                                            </span>
                                        </div>
                                        <div className="tab-content-1">
                                            <div className="company-info-top-right control_flex_m1 item_scroll">
                                                {/* <XLS />                         */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Company Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Voucher No</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Patient/Party Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Item Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Lens Type & PD</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Vendor Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">DV/NV</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Right Lens</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">SPH</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">CYL</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Axis</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">V/N</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">ADD</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Left Lens</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">SPH</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">CYL</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Axis</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">V/N</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">ADD</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Remarks</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Status</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Detail For Save</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Prv Saved details</div>
                                                <div className="table_head_mmb1">Prv Saved details</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>

                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Print</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Wtsapp</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>My Optical</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <div>
                                                    <label> SALE: 516</label>
                                                    <label>Date : 28-11-2023</label>
                                                </div> </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Tejas Bhagat</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>TITAN FRAME HHH - 125 48 RED,TITAN FRAME HHH - 125 48 RED</td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <div>
                                                    <div className="flex-start">
                                                        <input className="" type="text" required style={{ width: "130px" }} />&nbsp;
                                                        <i className="fa-solid fa-paper-plane mt-1" onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}></i>
                                                        {isHovered && <div className="hover-text1" style={{ marginTop: "4%" }}>Send Mail & share</div>}<br />
                                                    </div>
                                                    <div className="input-containerEffect mt-3" id="PartyInput" style={{ margin: "0%", width: "100%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{ width: "150px" }} type="text" required />
                                                        <label id="input1" className="labelEffect1">Party name for convert</label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <div>
                                                    <div className="flex-start">
                                                        <input type="checkbox" />
                                                        <label>&nbsp; DV</label>
                                                    </div>
                                                    <div className="flex-start">
                                                        <input type="checkbox" />
                                                        <label>&nbsp; NV</label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="td_col5" style={{ textalign: "start" }}><input type="checkbox" /></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>+1.75</td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>6/N</td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><input type="checkbox" /></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>+2.50</td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>6/N</td>
                                            <td className="td_col5" style={{ textalign: "start" }}></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><input type="text" placeholder="Remarks" style={{ width: "90%" }} /></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <select>
                                                    <option>Ready</option>
                                                    <option>Delivered</option>
                                                    <option>Pending</option>
                                                    <option>Approved</option>
                                                    <option>Cancel</option>
                                                </select>
                                            </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "0%", width: "100%" }}>
                                                    <select className="inputEffect">
                                                        <option>Akshay</option>
                                                        <option>Kaveri</option>
                                                        <option>Kavya</option>
                                                        <option>Neha</option>
                                                    </select>
                                                    <label id="input1" className="labelEffect1">Change By</label>
                                                </div>
                                                <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "0%", width: "100%" }}>
                                                    <input className="inputEffect modify_im_input mode_limit mode_category input-s1" style={{ width: "100px" }} type="date" required />
                                                    <label id="input1" className="labelEffect1">Date</label>
                                                </div>
                                            </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>
                                                <div>
                                                    <i className="fa-solid fa-microscope mt-1" onMouseEnter={() => setIsHovered1(true)}
                                                        onMouseLeave={() => setIsHovered1(false)}></i>
                                                    {isHovered1 && <div className="hover-text1" style={{ marginTop: "4%" }}>Lens Details</div>}<br />

                                                    <i className="fa-solid fa-floppy-disk mt-1" onMouseEnter={() => setIsHovered2(true)}
                                                        onMouseLeave={() => setIsHovered2(false)}></i>
                                                    {isHovered2 && <div className="hover-text1" style={{ marginTop: "5%" }}>Save</div>}<br />

                                                    <i className="fa-solid fa-clipboard-list mt-1" onMouseEnter={() => setIsHovered3(true)}
                                                        onMouseLeave={() => setIsHovered3(false)}></i>
                                                    {isHovered3 && <div className="hover-text1" style={{ marginTop: "6%" }}>Create Purchase Order</div>}<br />
                                                </div>
                                            </td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td><button className="print_btn"><i className="fa-solid fa fa-print"></i></button></td>
                                            <td><button className="whatsapp_btn"><i className="fa-solid fa fa-whatsapp"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default TraceLensStatus;
