import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
//import { defaultProps } from 'react-quill';

const LensCreationTable = (props) => {

    const navigate = useNavigate();

    const { combinedV, sphValue, cylValue, axisValue, addValue, fetchLensPowerData } = props;

    const PositonStatus = {
        position: "sticky",
        top: '0px',
        zIndex: "2"
    }

    const rows = document.querySelectorAll('.TrLensCreation');
    rows.forEach(row => {
        row.addEventListener('click', () => {
            rows.forEach(r => r.classList.remove('focused-row'));
            row.classList.add('focused-row');
        });
    });


    useEffect(() => {
        const handleArrowNavigation = (event) => {
            try {
                const arrowKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
                if (arrowKeys.includes(event.key)) {
                    event.preventDefault();
                    const inputs = document.querySelectorAll('.tableBox');
                    const currentIndex = Array.from(inputs).findIndex(input => input === document.activeElement);
                    let newIndex;
                    switch (event.key) {
                        case 'ArrowLeft':
                            newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
                            break;
                        case 'ArrowRight':
                            newIndex = currentIndex < inputs.length - 1 ? currentIndex + 1 : currentIndex;
                            break;
                        case 'ArrowUp':
                            newIndex = currentIndex - 7 >= 0 ? currentIndex - 7 : currentIndex;
                            break;
                        case 'ArrowDown':
                            newIndex = currentIndex + 7 < inputs.length ? currentIndex + 7 : currentIndex;
                            break;
                        default:
                            newIndex = currentIndex;
                    }
                    inputs[newIndex].focus();
                }
            } catch (error) {
                console.log(error);
            }
        };
        //document.addEventListener('keydown', handleArrowNavigation);
        return () => {
            //document.removeEventListener('keydown', handleArrowNavigation);
        };
    }, []);


    return (
        <div>
            {(() => {
                if (sphValue != null) {
                    return (
                        <div className="" id="table" style={{ display: "none", }}>{/* minWidth: "1127px"*/}
                            <div className="tableDiv " style={{ maxHeight: "90vh", maxWidth:"95vw" }}>
                                <table className="lensPowerTable" >
                                    <thead style={PositonStatus}>
                                        <tr className="">
                                            <th colSpan="3" className="headerCellStyle" style={{ backgroundColor: "#5e709e", fontWeight: "600", color: "white", borderBottom: "1px solid black", textAlign: "center" }}>Add -</th>
                                            {addValue && addValue.map((value4, index4) => (
                                                <th colSpan="7" className="headerCellStyle" style={{ backgroundColor: "#5e709e", fontWeight: "600", color: "white", borderBottom: "1px solid black", textAlign: "center" }}>Add {value4}</th>
                                            ))}
                                        </tr>
                                        <tr className="" style={{ justifyContent: "space-evenly", backgroundColor: "#dee1e6", height: "25px" }}>
                                            <React.Fragment className="ButtonStick4">
                                                <th className="headerCellStyle ButtonStick1" style={{ width: "0%", padding: "3px", paddingLeft: "10px", margin: "2px" }}>SPH</th>
                                                <th className="headerCellStyle  ButtonStick2" style={{ width: "0%", padding: "3px", paddingLeft: "10px", margin: "2px" }}>CYL</th>
                                                <th className="headerCellStyle  ButtonStick" style={{ width: "0%", padding: "3px", paddingLeft: "10px", margin: "2px" }}>Axis</th>
                                            </React.Fragment>
                                            {addValue && addValue.map((value4, index4) => (
                                                <React.Fragment key={index4}>
                                                    <th className="headerCellStyle TabWidthMin" >Barcode</th>
                                                    <th className="headerCellStyle TabWidthMin" >Box no</th >
                                                    <th className="headerCellStyle TabWidthMin" >&nbsp;Alert qty</th>
                                                    <th className="headerCellStyle TabWidthMin" >&nbsp;P Price</th>
                                                    <th className="headerCellStyle TabWidthMin" >&nbsp;S Price</th>
                                                    <th className="headerCellStyle TabWidthMin" >Init.Stk.</th>

                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {
                                            combinedV && combinedV.map((data, index1) => (
                                                <tr className="TrLensCreation" key={index1}>
                                                    <React.Fragment className="TrPosiStatus" >
                                                        <td className="firstColumnCellStyle tableData rows_indicator ButtonStick1 " style={{ width: "0%" }} >
                                                            {data.sph}
                                                            {/* <input className="tableBox text" type="text" value={data.sph} readOnly /> */}
                                                        </td>
                                                        <td className="firstColumnCellStyle tableData rows_indicator ButtonStick2 " style={{ width: "0%" }}>
                                                            {data.cyl}
                                                            {/* <input className="tableBox text" type="text" value={data.cyl} readOnly /> */}
                                                        </td>
                                                        <td className="firstColumnCellStyle tableData rows_indicator ButtonStick " style={{ width: "0%" }}>
                                                            {data.axis}
                                                            {/* <input className="tableBox text" type="text" value={data.axis} readOnly /> */}
                                                        </td>
                                                    </React.Fragment>

                                                    <React.Fragment>
                                                        {addValue && addValue.map((value4, index4) => (
                                                            <React.Fragment key={index4}>

                                                                {fetchLensPowerData && fetchLensPowerData.length > 0 ? (
                                                                    fetchLensPowerData.map((item, index5) => {
                                                                        if (parseFloat(item.sph) === parseFloat(data.sph) &&
                                                                            parseFloat(item.cyl) === parseFloat(data.cyl) &&
                                                                            parseFloat(item.axis) === parseFloat(data.axis) &&
                                                                            parseFloat(item.add) === parseFloat(value4)
                                                                        ) {
                                                                            return (
                                                                                <React.Fragment key={index5}>
                                                                                    <td className={`tableData rows_indicator`}>
                                                                                        <input className="tableBox" type="hidden" value={item.id} required />
                                                                                        <input className="tableBox" type="text" value={item.defaultBarcode} style={{ borderLeftColor: "red" }} required />
                                                                                    </td>
                                                                                    <td className="tableData rows_indicator">
                                                                                        <input className="tableBox" type="text" value={item.location} required />
                                                                                    </td>
                                                                                    <td className="tableData rows_indicator">
                                                                                        <input className="tableBox" type="text" value={item.openingStock} required />
                                                                                    </td>
                                                                                    <td className="tableData rows_indicator">
                                                                                        <input className="tableBox" type="text" value={item.purchasePrice} required />
                                                                                    </td>
                                                                                    <td className="tableData rows_indicator">
                                                                                        <input className="tableBox" type="text" value={item.salePrice} required />
                                                                                    </td>
                                                                                    <td className="tableData rows_indicator">
                                                                                        <input className="tableBox" type="text" value={item.openingStock} required />
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })
                                                                ) : (
                                                                    <React.Fragment key={index4}>
                                                                        <td className={`tableData rows_indicator `}>
                                                                            <input className="tableBox" type="text" style={{ borderLeftColor: "red" }} required />
                                                                        </td>
                                                                        <td className="tableData rows_indicator">
                                                                            <input className="tableBox" type="text" required />
                                                                        </td>
                                                                        <td className="tableData rows_indicator">
                                                                            <input className="tableBox" type="text" required />
                                                                        </td>
                                                                        <td className="tableData rows_indicator">
                                                                            <input className="tableBox" type="text" required />
                                                                        </td>
                                                                        <td className="tableData rows_indicator">
                                                                            <input className="tableBox" type="text" required />
                                                                        </td>
                                                                        <td className="tableData rows_indicator">
                                                                            <input className="tableBox" type="text" required />
                                                                        </td>
                                                                    </React.Fragment>
                                                                )}

                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                } else {
                    console.log("not found");
                    return null;
                }
            })()}

        </div>
    );
};

export default LensCreationTable;
