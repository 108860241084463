import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import MyContext from '../../../Inventory/MyContext';

const Video = (props, { initialWidthPercent = 100, initialHeight = 100 }) => {
  let getId = props.keyValueId;
  const [videoUrl, setVideoUrl] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const [contentValue, setContentValue] = useState();
  const [timeoutId, setTimeoutId] = useState(null);

  const { setDroppedItems, setTemplateData, handleContentChange, stylesArray, droppedItems, handleStyleChange } = useContext(MyContext);
  const divRef = useRef(null);
  const [hoveredComponentId, setHoveredComponentId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredComponentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredComponentId(null);
  };

  const [dimensions, setDimensions] = useState({
    widthPercent: initialWidthPercent,
    minHeight: initialHeight,
    top: 0,
    left: 0,
  });

  console.log(droppedItems);
  const getParentIdFromChildId = (keyValueId, droppedItems) => {
    for (const item of droppedItems) {
      for (const child of item.JSONChildComponents) {
        if (child.id === keyValueId) {
          return child.parentJSONid;
        }
      }
    }
    return null; 
  };
  
  useEffect(() => {
    const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
    console.log("parentId", parentId);
  }, [droppedItems]);

  const deletebuttonshow = () => {
    // setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(getId + 'delete');
    console.log(deleteButton);
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    const resizers = document.getElementsByClassName(props.keyValueId);
    if (resizers.length > 0) {
      for (let i = 0; i < resizers.length; i++) {
        resizers[i].classList.toggle("displayblock");
      }
    }
  };

  useEffect(() => {
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

    if (matchingStyle) {
      setContentValue(matchingStyle.content || '');
    } else {
      setContentValue('');
    }
  }, [props.keyValueId, stylesArray, setContentValue]);

  const handleUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const getEmbedUrl = (url) => {
    const videoIdMatch = url?.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
  };

  const fetchInnerHtmlOfDroppedComponent = (componentId) => {
    console.log(componentId);
    const getRemainingData = (str) => {
      if (str.endsWith("CHILD")) {
        return str.replace("CHILD", "");
      } else if (str.endsWith("CHILD1")) {
        return str.replace("CHILD1", "");
      }
      return str;
    };

    var newID = getRemainingData(componentId)

    if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
      const componentElement = document.getElementById(newID.toString() + "innerHTML");
      if (componentElement) {
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        // Update the corresponding item with the inner HTML
        setDroppedItems(prevItems => {
          debugger
          const updatedItems = prevItems.map(item => {
            if (item.id === newID) {
              return { ...item, textContents: innerHTML };
            }
            return item;
          });

          // Update the template data with the new content
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));

          return updatedItems;
        });
      }
      return
    }
    // Find the component element using its ID
    const componentElement = document.getElementById(componentId.toString());
    console.log(componentElement);
    if (componentElement) {
      // Get the inner HTML
      const innerHTML = componentElement.outerHTML;
      console.log(innerHTML);

      setDroppedItems(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.id === componentId) {
            return { ...item, textContents: innerHTML };
          }
          return item;
        });
        setTemplateData(prevState => ({
          ...prevState,
          jsonStructure: updatedItems,
        }));

        return updatedItems;
      });
    }
  };

  useEffect(() => {
    if (contentValue != undefined && contentValue?.trim() !== '') {
      setIsEditing(false);
    }
  }, [contentValue]);

  const handleBlur = () => {
    if (videoUrl?.trim() !== '' || contentValue?.trim() !== '') {
      setIsEditing(false);
    }

    setTimeout(() => {
      if (divRef.current) {
        console.log(divRef.current);
        const updatedInnerHTML = divRef.current.innerHTML;
        console.log('Updated innerHTML:', updatedInnerHTML);
        if (videoUrl) {
          const textData = videoUrl;
          console.log("Text Data:", textData);
          handleContentChange(textData, props.keyValueId);
          const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
          if (parentId != null) {
            fetchInnerHtmlOfDroppedComponent(parentId);
          }
        }
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === props.keyValueId) {
              return { ...item, textContents: updatedInnerHTML };
            }
            return item;
          });
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));
          return updatedItems;
        });
      } else {
        console.error('divRef.current is null');
      }
    }, 1000);
  };

  const handleMouseDown = useCallback((e, direction) => {
    e.preventDefault();
    
    const originalWidthPercent = dimensions.widthPercent;
    const originalHeight = dimensions.minHeight;
    const originalMouseX = e.pageX;
    const originalMouseY = e.pageY;
    const container = document.getElementById(props.keyValueId);

    if (!container) return;

    const containerWidth = container.offsetWidth; // Get container width in pixels

    const maxWidth = containerWidth; // Maximum width in pixels (100% of container width)
    const minWidthPercent = 5; // Minimum width percentage
    const minHeight = 100; // Minimum height in pixels

    const onMouseMove = (e) => {
      let newWidthPercent = originalWidthPercent;
      let newHeight = originalHeight;
      const containerWidth = container.offsetWidth; // Get container width in pixels

      if (direction === 'right') {
        const deltaX = e.pageX - originalMouseX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) + deltaX;

        // Ensure newWidth doesn't exceed containerWidth
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'left') {
        const deltaX = originalMouseX - e.pageX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) - deltaX;

        // Ensure newWidth doesn't go below minimum width
        const minWidthInPixels = minWidthPercent / 100 * containerWidth;
        if (newWidth < minWidthInPixels) {
          newWidth = minWidthInPixels;
        }
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }


        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'top') {
        newHeight = originalHeight - (e.pageY - originalMouseY);
        if (newHeight < minHeight) {
          newHeight = minHeight;
        }
      } else if (direction === 'bottom') {
        newHeight = originalHeight + (e.pageY - originalMouseY);
      }

      // Update state with new dimensions
      if (newWidthPercent >= minWidthPercent && newWidthPercent <= 100) {
        setDimensions(prev => ({ ...prev, widthPercent: newWidthPercent }));
      }
      if (newHeight >= minHeight) {
        setDimensions(prev => ({ ...prev, minHeight: newHeight }));
      }

      // Update droppedItems with new dimensions
      // updateDroppedItemsWithDimensions(newWidthPercent, newHeight);

      if (props.keyValueId) {
        const widthValue = `${newWidthPercent}`;
        const heightValue = `${newHeight}`;

        handleStyleChange("width", widthValue, "%", props.keyValueId);
        // handleStyleChange("height", heightValue, "px", keyValueId);
        handleStyleChange("min-height", heightValue, "px", props.keyValueId);
      }
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
    const id = setTimeout(() => {
      if (isEditing === false) {
        setIsEditing(true);
      }
    }, 2000);
    setTimeoutId(id);

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, [dimensions, props.keyValueId, isEditing]);

  const mouseup = () => {
    clearTimeout(timeoutId)
  }

  const handleColumnClick = (id) => {
    deletebuttonshow()
    props.onComponentClick(id);
  };
  const isHovered = hoveredComponentId === props.keyValueId;
  return (
    <div
      style={{ position: 'relative' }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      id={getId}
      draggable="true"
      onClick={() => handleColumnClick(getId)}
      ref={divRef}
      className="resizers-web-template column-one-content-web-template"
      onMouseDown={(e) => handleMouseDown(e, 'top')}
      onMouseUp={mouseup}
    >
      <div className={`resizer-web-template ${props.keyValueId} top-web-template `} onMouseDown={(e) => handleMouseDown(e, 'top')}></div>
      <div className={`resizer-web-template ${props.keyValueId} right-web-template `} onMouseDown={(e) => handleMouseDown(e, 'right')}></div>
      <div className={`resizer-web-template ${props.keyValueId} bottom-web-template `} onMouseDown={(e) => handleMouseDown(e, 'bottom')}></div>
      <div className={`resizer-web-template ${props.keyValueId} left-web-template `} onMouseDown={(e) => handleMouseDown(e, 'left')}></div>
      {isEditing ? (
        <input
          type="text"
          placeholder="Enter video URL"
          value={contentValue || videoUrl}
          onChange={handleUrlChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            width: '100%',
            padding: '8px',
            boxSizing: 'border-box',
          }}
        />
      ) : (
        <div className={`component-web-template ${isHovered ? 'hover-border-web-template' : ''} `}
          onMouseEnter={() => handleMouseEnter(props.keyValueId)}
          onMouseLeave={handleMouseLeave}>
          <iframe
            className="video-iframe-web-template"
            style={{ width: '100%' }}
            src={getEmbedUrl(contentValue) || getEmbedUrl(videoUrl)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Video;
