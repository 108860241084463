import { useContext, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import MyContext from "../../../Inventory/MyContext";

const Imagemodal = (props) => {
  const [imageUrl, setImageUrl] = useState([]);
  const [urlInput, setUrlInput] = useState("");
  const [newImagesAdded, setNewImagesAdded] = useState(false);
  const inputRef = useRef(null);
  const { TemplateData, imageBaseUrl, handleStyleChange } = useContext(MyContext)
  console.log(TemplateData);
  const GetAllimageOfTemplate = async () => {
    var templateId = localStorage.getItem("templateId")
    var imageList = await WebTemplate.CustomizedPageTemplateImageDetailWithTemplateId(templateId)
    if (imageList) {
      setImageUrl(imageList)
    }
  }
  useEffect(() => {
    GetAllimageOfTemplate()
  }, [])

  const addImage = () => {
    inputRef.current.click();
  };

  const handleImageUpload = async (e) => {
    debugger;
    const formData = new FormData();
    const file = e.target.files[0];
    console.log(TemplateData);
    if (file) {
      var templateId = localStorage.getItem("templateId")
      formData.append(`files`, file);
      var imageUpload = await WebTemplate.UpdateCustomizedPageTemplateImage(formData, templateId)
      if (imageUpload) {
        await GetAllimageOfTemplate()
      }
    }
  };

  const handleAddImageFromUrl = () => {
    setImageUrl((prevUrls) => [...prevUrls, urlInput]);
    setNewImagesAdded(true);
  };

  useEffect(() => {
    if (newImagesAdded) {
      localStorage.setItem("imageURL", JSON.stringify(imageUrl));
      setNewImagesAdded(false);
    }
  }, [imageUrl, newImagesAdded]);

  console.log(imageUrl);
  console.log(newImagesAdded);


  useEffect(() => {
    const savedImageUrl = JSON.parse(localStorage.getItem("imageURL"));
    if (savedImageUrl) {
      setImageUrl(savedImageUrl);
    }
  }, []);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  const handleValuePassing = (localValue) => {
    const fullUrl = `${imageBaseUrl}${localValue}`;
    handleStyleChange("background-image", `url(${fullUrl})`, '', props.getId)
    handleStyleChange("height", "400", "px", props.getId)
    handleStyleChange("width", "100", "%", props.getId)
    handleStyleChange("border", "none", "", props.getId)
  };

  const handleValuePassing1 = () => {
    // Only close the modal when clicking the close icon (X)

  };

  return (
    <div>
      <div className="modal-back">
        <div className="image-modal">
          <div className="viewcode-modal-heading">Image-selector-box</div>
          <i
            id="modal_box_close"
            onClick={props.handleclick}
            className="fa-sharp fa-solid fa-xmark"
          ></i>
          <div className="image-modal-main">
            <div className="image-modal-left" onClick={addImage}>
              <p className="upload-image-txt">
                Drop file here or click to upload
              </p>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleImageUpload}
              />
            </div>
            <div className="image-modal-right">
              <div className="image-modal-rignt-top">
                <div>
                  <input
                    className="add-image-input"
                    type="text"
                    placeholder="Enter URL"
                    value={urlInput}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <button
                    className="add-image-btn"
                    onClick={handleAddImageFromUrl}
                  >
                    Add Image
                  </button>
                </div>
              </div>
              <div className="image-modal-right-bottom">
                {imageUrl.map((localValue, index) => (
                  <div className="right-bottom-box" key={index} style={{ cursor: 'pointer' }}>
                    <div
                      onClick={handleValuePassing1}
                      onKeyDown={() => handleValuePassing(localValue)}
                    >
                      <img
                        // src={localValue}
                        src={`${imageBaseUrl}${localValue.id}`}
                        alt=""
                        style={{
                          width: "150px",
                          height: "90px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        onClick={() => handleValuePassing(localValue.id)}
                      />
                    </div>
                    <div>
                      <p></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Imagemodal