import React, { useEffect, useState, useContext } from "react";
import { } from "react-router-dom";
import LeaveMaster from "./LeaveMaster";
import MyContext from "../../Inventory/MyContext";
import AlertBox from "../../Inventory/Props/AlertBox";
import Loader from "../../Inventory/Props/Loader";
import LeaveMasterApi from "../../API/APIServices/LeaveMaster";
import DataTable from "../../Inventory/Master/TableMaster/DataTable";
import PageTabs from "../../Inventory/Components/PageTabs";
import TableButton from "../../Inventory/Props/TableButton";

function LeaveMasterList() {

    const [Modal, setModal] = useState(false);

    const ModalToggle = () => {
        setModal(!Modal);
    }

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2
    } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "LEAVE NAME", accessor: "leaveName" },
        { Header: "DESCRIPTION", accessor: "description" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Leave Name" ref={input1} />, accessor: "LeaveName" },
        { Header: <input type="text" placeholder="Description" ref={input2} />, accessor: "Description" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await LeaveMasterApi.getLeaveMaster();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    id: item.id,
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    leaveName: item.leaveName,
                    description: item.description,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => EditLeaveMaster(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteLeaveMaster(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);
    const [edit, setEdit] = useState(false);

    const EditLeaveMaster = (id) => {
        setEdit(true);
        setModal(true);
        const data = LeaveMasterApi.getLeaveMasterById(id);
        console.log(data);
        setEditData(data);
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteLeaveMaster = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteLeaveMasterConfirm = async () => {
        setLoading(true);
        try {
            const val = await LeaveMasterApi.deleteLeaveMaster(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
        console.log(data1);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'F2') {
                ModalToggle();
            }
            if (event.key === 'Escape') {
                if (Modal === true) {
                    ModalToggle();
                }
                else {
                    window.history.back();
                }
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div>
            <div className="" >
                {/* <PageTabs /> */}
                <div className="nav-1">
                    <div className="panel-heading">
                        <h2 className="panel-title">Leave Master List</h2>
                        <TableButton showModal={ModalToggle} filterData={filterData} columns={columns} data={data1} selectedData={selectedData} />
                    </div>
                    <div id="printData">
                        <DataTable filterData={filterData} columns={columns} data={data1} />
                    </div>
                </div>
            </div>
            {/************************** Leave Modal ***************/}
            {Modal && (
                <div className="modal-back2" onClick={ModalToggle}>
                    <div className="modalContentTable" onClick={handleModalClick} style={{ padding: "30px" }}>
                        <div className="modal-title">
                            <h3 className="modalTableTitleColor"> </h3>
                            <span className="closeTableModal" onClick={ModalToggle}>
                                &times;
                            </span>
                        </div>
                        <LeaveMaster close={ModalToggle} editData={editData} fetchData={fetchData} isEditedData={edit} />
                    </div>
                </div>
            )}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are you sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteLeaveMasterConfirm}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default LeaveMasterList;
