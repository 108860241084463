import React from 'react';
import './Dropdown.css';

const Dropdown = ({ options, selectedValue, onChange, label }) => {
    return (
        <div className="dropdown-container">
            <select
                value={selectedValue}
                onChange={(e) => onChange(e.target.value)}
                className={`dropdown ${selectedValue !== '' ? 'filled1' : ''}`}
            >
                <option value="" disabled></option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label className={`dropdown-label ${selectedValue !== '' ? 'filled1' : ''}`}>
                {label}
            </label>
        </div>
    );
};

export default Dropdown;
