
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import Order from "../../API/APIServices/Order";
import Loader from "../../Inventory/Props/Loader";
import moment from "moment";
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import UserCredential from "../../API/APIServices/UserCredential";
import ItemDepartment from "../../API/APIServices/ItemDepartment";
import TableGroup from "../../API/APIServices/TableGroup";

function KotOrderSummary() {
    useState(() => {
        document.title = 'KOT Order Summary';
    }, []);

    const { isShowTotalAmountInReport } = useContext(MyContext);
    const [loading, setLoading] = useState(true);

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    }

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [fromTime, setFromTime] = useState();
    const [toTime, setToTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDate();
            setFromDate(date)
            setToDate(date)
        }
        fetchData();
    }, [])

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    // ***************** Get User Credential ***********************
    const [userData, setUserData] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {

                // const filteredData = [{
                //     fieldName: "userRole",
                //     operatorName: "Equal",
                //     compareValue: 565
                // }]
                const filteredData = [{
                    fieldName: "string",
                    operatorName: "string",
                    compareValue: "string"
                }]

                const data = await UserCredential.getFilteredUserCredential(filteredData);
                setUserData(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    // ***************** Get Item Department ***********************
    const [itemDepartment, setItemDepartment] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemDepartment.getItemDepartment();
                setItemDepartment(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    // ***************** Get Table Group ***********************
    const [tableGroup, setTableGroup] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TableGroup.getTableGroup()
                setTableGroup(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const date = await getCurrentDate();
            const filterData = [
                {
                    fieldName: "orderDate",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: date
                },
                {
                    fieldName: "orderDate",
                    operatorName: "LessOrEqualThan",
                    compareValue: date
                },
            ]
            setFilter(filterData);
            try {
                const orderData1 = await Order.getKotOrderSummaryFilter(filterData, 1);
                var val = orderData1
                console.log(val);
                setDataArray(val);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addFilter, setFilter] = useState();

    const handleFilterChange = async (event) => {
        setLoading(true);
        setPageIndex(1);
        setStartIndex(0);

        const { name, value } = event.target;
        let updatedFilter = [...addFilter];

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            let newFilter;

            if (["fromDate", "toDate"].includes(name)) {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue,
                };
                name === "fromDate" ? setFromDate(formattedValue) : setToDate(formattedValue);
            } else if (["fromTime", "toTime"].includes(name)) {
                newFilter = {
                    fieldName: "orderTime",
                    operatorName: name === "fromTime" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: `${value}:00`,
                };
                name === "fromTime" ? setFromTime(value) : setToTime(value);
            } else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value,
                };
            }

            const existingFilter = updatedFilter.find(filter => filter.fieldName === newFilter.fieldName);

            if (existingFilter) {
                const operatorExists = updatedFilter.some(
                    filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                );

                if (operatorExists) {
                    updatedFilter = updatedFilter.map(filter =>
                        filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                            ? { ...filter, compareValue: newFilter.compareValue }
                            : filter
                    );
                } else {
                    updatedFilter.push(newFilter);
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
        try {
            const orderData = await Order.getKotOrderSummaryFilter(updatedFilter, pageIndex);
            setDataArray(orderData);
        } catch (error) {
            console.error("Error fetching order data:", error);
        } finally {
            setLoading(false);
        }
    };

    const [pageIndex, setPageIndex] = useState(1);
    const [startIndex, setStartIndex] = useState(0);

    const previousPage = async () => {
        setLoading(true);
        const index = pageIndex - 1;
        if (index != 0) {
            setPageIndex(index)
            const orderData1 = await Order.getKotOrderSummaryFilter(addFilter, index);
            setStartIndex(Number(50 * index) - 50) //Number(val.length * index) - 50
            var val = orderData1
            if (val.length > 0) {
                setDataArray(val);
                setLoading(false);
            }
            else {
                alert("No More Data")
                setLoading(false);
            }
        }
        else {
            //alert("Not Found")
            setLoading(false);
        }
    }

    const nextPage = async () => {
        setLoading(true);
        const index = pageIndex + 1;
        const orderData1 = await Order.getKotOrderSummaryFilter(addFilter, index);
        var val = orderData1
        if (val.length > 0) {
            setStartIndex(Number(50 * index) - 50)  //Number(val.length * index) - 50
            setDataArray(val);
            setPageIndex(index)
            setLoading(false);
        }
        else {
            alert("No More Data")
            setLoading(false);
        }
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }

        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">KOT Order Summary Report</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={false} saveButtonHide={true} /> {/*handleSearchClick={handleSearchClick} */}
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "96vw" }}>

                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">KOT Order Summary Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="date" required
                                                onChange={handleFilterChange} value={fromDate}
                                                name="fromDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required
                                                onChange={handleFilterChange} value={toDate}
                                                name="toDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                onChange={handleFilterChange}
                                                name="fromTime" type="time" required />
                                            <label id="input1" className="labelEffect1">From Time</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                onChange={handleFilterChange}
                                                name="toTime" type="time" required />
                                            <label id="input1" className="labelEffect1">To Time</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Type</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input6Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="itemDepartmentId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemDepartment !== null && Array.isArray(itemDepartment)) {
                                                        return itemDepartment.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Department Item Category</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input7Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="iConfirmById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (userData !== null && Array.isArray(userData)) {
                                                        return userData.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Kot Receiver Manager</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input8Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input9Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="tableGroupId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                        return tableGroup.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Table Group Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input9Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input1Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="iDeliverById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (userData !== null && Array.isArray(userData)) {
                                                        return userData.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Kot Delivered Manager</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">KOT ITEM STATUS DETAILS</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        {/* ************************** Page Index *********************** */}
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-end",
                                                width: "100%",
                                                margin: "5px 0px"
                                            }}
                                        >
                                            <button
                                                onClick={() => previousPage()}
                                                //disabled={!canPreviousPage}
                                                className="table-list-button"
                                            >
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                            <span className="table-list-page-txt">
                                                Page -
                                                <strong style={{ margin: "0px 3px" }}>
                                                    {pageIndex}
                                                </strong>
                                            </span>
                                            <button
                                                onClick={() => nextPage()}
                                                //disabled={!canNextPage}
                                                className="table-list-button"
                                            >
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div className="table-resposive" style={{ height: "500px", overflow: "scroll" }}> {/*tb-scroll-width */}
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Date</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Dept Category</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Type</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Table</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Food Rating</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Preference</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Qty</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Prep Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Received/Confirm Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Kot Transferred Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Kot Processing Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Kot Ready Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Kot Delivery Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Diff Confirm to Receive</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Diff Receive to Deliver</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>KOT - Diff Procesing to Confirm</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Department - Diff Deliver to Processing</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Guest - Diff Deliver to Confirm</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Confirm By</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Status Change from Kot Manager App</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray != null && dataArray != undefined && dataArray.length > 0 && dataArray
                                                        .filter((item, index, self) =>
                                                            index === self.findIndex((t) => (
                                                                t.ord2Id === item.ord2Id
                                                            ))
                                                        )
                                                        //.slice().reverse()
                                                        .map((item, index) => (
                                                            <tr key={index} className="table_tbody_Order_List">
                                                                <td className="td_col6 td_sno-btn feed-flex-edit">{startIndex + index + 1}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.ordNo}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderDate}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.orderTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.itmDptName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.serTypeName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.tName}</td>

                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.iRating}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.itmName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.iPrefNo}</td> {/*Item Delivery pref */}
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.qty}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.iPrepTime} min</td> {/*Item Prep Time */}
                                                                <td className="tdStyle" style={{ textAlign: "start" }}>
                                                                    {item.serTypeId == 752 ?
                                                                        <div>
                                                                            <label><span style={{ fontWeight: "600" }}>KDTT&nbsp;:&nbsp;</span>{item.kotDispatchTargetTime != "" && item.kotDispatchTargetTime != "00:00:00" ? convertTo12HourFormat(String(item.kotDispatchTargetTime)) : "00:00:00"}</label>
                                                                            <br />
                                                                            <label><span style={{ fontWeight: "600" }}>KDT&nbsp;:&nbsp;</span>{item.kotDispatchTime != null && item.kotDispatchTime != 0 && item.kotDispatchTime != "" && item.kotDispatchTime != "00:00:00" ? convertTo12HourFormat(String(item.kotDispatchTime)) : "00:00:00"}</label>
                                                                            <br />
                                                                            {/* DDT :  {convertTo12HourFormat(String(item.deliveryBoyDispatchTime))} */}
                                                                            <label><span style={{ fontWeight: "600" }}>DDT&nbsp;:&nbsp;</span>{item.deliveryBoyDispatchTime != "" && item.deliveryBoyDispatchTime != "00:00:00" && item.deliveryBoyDispatchTime != null && item.deliveryBoyDispatchTime != "0" ? convertTo12HourFormat(String(item.deliveryBoyDispatchTime)) : "00:00:00"}</label>
                                                                            <br />
                                                                            <label> <span style={{ fontWeight: "600" }}>DT&nbsp;:&nbsp;</span>{item.overallOrderStatus == "Cancel" ? "00:00:00" : (item.deliveredTime == "" || item.deliveredTime == "00:00:00" || item.deliveredTime == 0 || item.deliveredTime == null ? "00:00:00" : convertTo12HourFormat(String(item.deliveredTime)))}</label>
                                                                            <br />
                                                                            <label><span style={{ fontWeight: "600" }}>PDT&nbsp;:&nbsp;</span>{item.paymentStatus == "Pending" ? "00:00:00" : moment.utc(item.paymentDoneOn).utcOffset("+05:30").format('hh:mm A')}</label> {/*item.paymentDoneTime */}
                                                                        </div>
                                                                        : ""}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.iConfirmByName ? `${item.iConfirmByName} on` : ""} {item.iConfirmOn ? moment.utc(item.iConfirmOn).utcOffset("+05:30").format('hh:mm A') : ""} */}
                                                                    {item.iConfirmByName ? `${item.iConfirmByName} on` : ""} {item.iConfirmOn ? moment(item.iConfirmOn).format('hh:mm A') : ""}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.iTransferByName ? `${item.iTransferByName} on` : ""} {item.iTransferOn ? moment.utc(item.iTransferOn).utcOffset("+05:30").format('hh:mm A') : ""} */}
                                                                    {item.iTransferByName ? `${item.iTransferByName} on` : ""} {item.iTransferOn ? moment(item.iTransferOn).format('hh:mm A') : ""}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.iProcessByName ? `${item.iProcessByName} on` : ""} {item.iProcessOn ? moment.utc(item.iProcessOn).utcOffset("+05:30").format('hh:mm A') : ""} */}
                                                                    {item.iProcessByName ? `${item.iProcessByName} on` : ""} {item.iProcessOn ? moment(item.iProcessOn).format('hh:mm A') : ""}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.iReadyName ? `${item.iReadyName} on` : ""} {item.iReadyOn ? moment.utc(item.iReadyOn).utcOffset("+05:30").format('hh:mm A') : ""} */}
                                                                    {item.iReadyName ? `${item.iReadyName} on` : ""} {item.iReadyOn ? moment(item.iReadyOn).format('hh:mm A') : ""}
                                                                </td> {/* Ready Time*/}
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.iDeliverByName ? `${item.iDeliverByName} on` : ""} {item.iDeliverOn ? moment.utc(item.iDeliverOn).utcOffset("+05:30").format('hh:mm A') : ""} */}
                                                                    {item.iDeliverByName ? `${item.iDeliverByName} on` : ""} {item.iDeliverOn ? moment(item.iDeliverOn).format('hh:mm A') : ""}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {(item.confirmToReceiveTime != undefined && item.confirmToReceiveTime != null)
                                                                        ? Number(item.confirmToReceiveTime).toFixed(2) + " min" : item.confirmToReceiveTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}> {(item.receiveToDeliverTime != undefined && item.receiveToDeliverTime != null)
                                                                    ? Number(item.receiveToDeliverTime).toFixed(2) + " min" : item.receiveToDeliverTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}> {(item.processToConfirmTime != undefined && item.processToConfirmTime != null)
                                                                    ? Number(item.processToConfirmTime).toFixed(2) + " min" : item.processToConfirmTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}> {(item.deliverToProcessTime != undefined && item.deliverToProcessTime != null)
                                                                    ? Number(item.deliverToProcessTime).toFixed(2) + " min" : item.deliverToProcessTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}> {(item.deliverToConfirmTime != undefined && item.deliverToConfirmTime != null)
                                                                    ? Number(item.deliverToConfirmTime).toFixed(2) + " min" : item.deliverToConfirmTime}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.ordConfirmByName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.iDeliverByName}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default KotOrderSummary