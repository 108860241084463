import React, { useContext, useEffect, useState } from "react";
import ItemMaster from "../../API/APIServices/ItemMaster";
import MyContext from "../MyContext";

function TabContent8(props) {
    const { handleInputContactLensChange, rightEyeInputs, leftEyeInputs, SaveContactLens, setLeftEyeInputs, setRightEyeInputs } = props;
    const { UserDetails } = useContext(MyContext)
    useEffect(() => {
        console.log("rightEyeInputs", rightEyeInputs);
        console.log("leftEyeInputs", leftEyeInputs);
    }, [leftEyeInputs, rightEyeInputs])

    const [dumpData, setDumpData] = useState();
    const [showToast, setShowToast] = useState(false);

    const handleCopyPaste = (action) => {
        try {
            if (action === "Copy") {
                setDumpData(leftEyeInputs)
                handleCopyClick();
            }
            else if (action === "Paste") {
                setRightEyeInputs(dumpData);
                setDumpData([])
            }
        } catch (error) {
            console.error("Error parsing clipboard text:", error);
        }
    };

    const handleCopyClick = () => {
        setShowToast(true);

        setTimeout(() => {
            setShowToast(false);
        }, 500);
    };

    return (
        <div>
            <div className="company_info-text"></div>
            <div className="tab-content-7 col-d-11">
                <div className="CPLHead" >
                    <div className="CPLHeaderBar" >
                        <span className="CPLTextContent" >Contact Lens Product</span>
                    </div>
                    <div className="CPLContentHeader" >
                        <div className="CPLContentLeft" >
                            {showToast && (
                                <div className="toastDiv_ItemMaster" >
                                    Copied
                                </div>
                            )}
                            <div className="CPLContentLeftTop"  >
                                <label>Power</label>
                                <label>Left</label>
                                {/* <label className="CopyButton" onClick={() => handleCopyPaste("Copy")} >Copy</label> */}
                                <button className="CopyButton" onClick={() => handleCopyPaste("Copy")}>Copy</button>
                            </div>
                            <div className="CPLContentLeftLabel"  >
                                <div className="CPLContentLeftLabel2"> <label></label> </div>
                                <div> <label className="CPLInputBox">From</label></div>
                                <div> <label className="CPLInputBox">To</label></div>
                                <div> <label className="CPLInputBox">Interval</label></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>SPH</label> </div>
                                <div><input className="CPLInputBox" name="l_sph1" value={leftEyeInputs.sph1}
                                    onChange={(e) => handleInputContactLensChange("left", "sph1", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_sph2" value={leftEyeInputs.sph2}
                                    onChange={(e) => handleInputContactLensChange("left", "sph2", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_sph3" value={leftEyeInputs.sph3}
                                    onChange={(e) => handleInputContactLensChange("left", "sph3", e.target.value)} type="text" /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>CYL</label> </div>
                                <div><input className="CPLInputBox" name="l_cyl1" value={leftEyeInputs.cyl1}
                                    onChange={(e) => handleInputContactLensChange("left", "cyl1", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_cyl2" value={leftEyeInputs.cyl2}
                                    onChange={(e) => handleInputContactLensChange("left", "cyl2", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_cyl3" value={leftEyeInputs.cyl3}
                                    onChange={(e) => handleInputContactLensChange("left", "cyl3", e.target.value)} type="text" /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>AXIS</label> </div>
                                <div><input className="CPLInputBox" name="l_axis1" value={leftEyeInputs.axis1}
                                    onChange={(e) => handleInputContactLensChange("left", "axis1", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_axis2" value={leftEyeInputs.axis2}
                                    onChange={(e) => handleInputContactLensChange("left", "axis2", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="l_axis3" value={leftEyeInputs.axis3}
                                    onChange={(e) => handleInputContactLensChange("left", "axis3", e.target.value)} type="text" /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>BC</label> </div>
                                <div><input className="CPLInputBox" type="text" name="l_bc1" value={leftEyeInputs.bc1}
                                    onChange={(e) => handleInputContactLensChange("left", "bc1", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                                <div className="CPLInputBox"></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>Dia</label> </div>
                                <div><input className="CPLInputBox" name="l_dia1" type="text" value={leftEyeInputs.dia1}
                                    onChange={(e) => handleInputContactLensChange("left", "dia1", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                                <div className="CPLInputBox"></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>Add</label> </div>
                                <div><input className="CPLInputBox" type="text" name="l_add1" value={leftEyeInputs.add1}
                                    onChange={(e) => handleInputContactLensChange("left", "add1", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="l_add2" value={leftEyeInputs.add2}
                                    onChange={(e) => handleInputContactLensChange("left", "add2", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                            </div>

                        </div>
                        <div className="CPLContentRight" style={{ width: "50%" }}>
                            <div className="CPLContentRightTop" style={{ border: "1px solid gray", display: "flex" }}>
                                <p>Power</p>
                                <p>Right</p>
                                <button className="CopyButton" onClick={() => handleCopyPaste("Paste")}>Paste</button>
                                {/* <p className="CopyButton" onClick={() => handleCopyPaste("Paste")} >Paste</p> */}
                            </div>
                            <div className="CPLContentLeftLabel"  >
                                <div className="CPLContentLeftLabel2"> <label></label> </div>
                                <div> <label className="CPLInputBox">From</label></div>
                                <div> <label className="CPLInputBox">To</label></div>
                                <div> <label className="CPLInputBox">Interval</label></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>SPH</label> </div>
                                <div><input className="CPLInputBox" name="r_sph1" value={rightEyeInputs.sph1}
                                    onChange={(e) => handleInputContactLensChange("right", "sph1", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="r_sph2" value={rightEyeInputs.sph2}
                                    onChange={(e) => handleInputContactLensChange("right", "sph2", e.target.value)} type="text" /></div>
                                <div><input className="CPLInputBox" name="r_sph3" value={rightEyeInputs.sph3}
                                    onChange={(e) => handleInputContactLensChange("right", "sph3", e.target.value)} type="text" /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>CYL</label> </div>
                                <div><input className="CPLInputBox" name="r_cyl1" type="text" value={rightEyeInputs.cyl1}
                                    onChange={(e) => handleInputContactLensChange("right", "cyl1", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="r_cyl2" value={rightEyeInputs.cyl2}
                                    onChange={(e) => handleInputContactLensChange("right", "cyl2", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="r_cyl3" value={rightEyeInputs.cyl3}
                                    onChange={(e) => handleInputContactLensChange("right", "cyl3", e.target.value)} /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>AXIS</label> </div>
                                <div><input className="CPLInputBox" type="text" name="r_axis1" value={rightEyeInputs.axis1}
                                    onChange={(e) => handleInputContactLensChange("right", "axis1", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="r_axis2" value={rightEyeInputs.axis2}
                                    onChange={(e) => handleInputContactLensChange("right", "axis2", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="r_axis3" value={rightEyeInputs.axis3}
                                    onChange={(e) => handleInputContactLensChange("right", "axis3", e.target.value)} /></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>BC</label> </div>
                                <div><input className="CPLInputBox" type="text" name="r_bc1" value={rightEyeInputs.bc1}
                                    onChange={(e) => handleInputContactLensChange("right", "bc1", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                                <div className="CPLInputBox"></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>Dia</label> </div>
                                <div><input className="CPLInputBox" name="r_dia1" type="text" value={rightEyeInputs.dia1}
                                    onChange={(e) => handleInputContactLensChange("right", "dia1", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                                <div className="CPLInputBox"></div>
                            </div>
                            <div className="CPLContentLeftInput">
                                <div className="CPLContentLeftLabel2"> <label>Add</label> </div>
                                <div><input className="CPLInputBox" type="text" name="r_add1" value={rightEyeInputs.add1}
                                    onChange={(e) => handleInputContactLensChange("right", "add1", e.target.value)} /></div>
                                <div><input className="CPLInputBox" type="text" name="r_add2" value={rightEyeInputs.add2}
                                    onChange={(e) => handleInputContactLensChange("right", "add2", e.target.value)} /></div>
                                <div className="CPLInputBox"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="ButtonTabSave">
                {UserDetails?.canModifyMaster && (
                    <button id="SaveBtnMain"
                        className={`save-button-main-save hovered`} onClick={SaveContactLens} >
                        Save/F10
                        <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i>
                    </button>
                )}
            </div> */}
        </div>
    )
};

export default TabContent8;