import React, { useEffect, useRef } from "react";

function Piechart() {
    const data = [30, 40, 20, 10];
    //const data1 = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = 400;
        canvas.height = 400;

        // Draw the pie chart
        const total = data.reduce((sum, value) => sum + value, 0);
        let startAngle = 0;

        data.forEach((value, index) => {
            const sliceAngle = (value / total) * (2 * Math.PI);
            const endAngle = startAngle + sliceAngle;

            // Draw the pie segment
            ctx.fillStyle = getRandomColor();
            ctx.beginPath();
            ctx.moveTo(canvas.width / 2, canvas.height / 2);
            ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 3, startAngle, endAngle);
            ctx.closePath();
            ctx.fill();

            const textX = canvas.width / 2 + Math.cos(startAngle + sliceAngle / 2) * (canvas.width / 5);
            const textY = canvas.height / 2 + Math.sin(startAngle + sliceAngle / 2) * (canvas.height / 5);
            ctx.fillStyle = '#fff';
            ctx.font = '12px Arial';
            ctx.fillText(`${value}`, textX, textY);

            startAngle = endAngle;
        });
    });

    // Helper function to generate random colors
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return <canvas className="pie-chart" ref={canvasRef} />;
};
export default Piechart;
