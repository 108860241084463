import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import img1 from "../../Assets/img/img1.jpg";
import MainButton from "../../Props/MainButton";
import DesignationApi from "../../../API/APIServices/Designation";
import DataTable from "../TableMaster/DataTable";
import DepartmentApi from "../../../API/APIServices/Department";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { Await } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function DesignationDetails() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Designation Master';
    }, []);

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        designationName: '',
        departmentName: '',
    });
    const [inputValues, setInputValues] = useState({
        designationName: '',
        departmentName: '',
        description: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }
        return true;
    };

    const fieldNames = ['designationName', 'departmentName'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await DesignationApi.updateDesignation(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await DesignationApi.insertDesignation(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({
                designationName: '',
                departmentName: '',
                description: '',
                isActive: true,
            });
        }
    }

    // ********************************************List Part ******************************

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "DESIGNATION NAME", accessor: "designationName" },
        { Header: "DEPARTMENT NAME", accessor: "departmentName" },
        { Header: "DESCRIPTION", accessor: "description" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Designation Name" ref={input1} />, accessor: "DesignationName" },
        { Header: <input type="text" placeholder="Department Name" ref={input2} />, accessor: "DepartmentName" },
        { Header: <input type="text" placeholder="Description" ref={input3} />, accessor: "Description" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await DesignationApi.getDesignation();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    designationName: item.designationName,
                    departmentName: item.departmentName,
                    description: item.description,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editDesignation(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteDesignation(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editDesignation = async (id) => {
        setEdit(true)
        const dataById = DesignationApi.getDesignationById(id);
        if (dataById) {
            try {
                const editData = await dataById; // Assuming props.editData is a Promise
                console.log("dataaaa" + editData);
                setInputValues(
                    {
                        id: editData.id,
                        designationName: editData.designationName,
                        departmentName: editData.departmentNameId,
                        description: editData.description,
                        isActive: editData.isActive,
                    }
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteDesignation = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }

    const DeleteDesignationConfirm = async () => {
        setLoading(true);
        try {
            const val = await DesignationApi.deleteDesignation(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };


    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };
    // **********************************************
    const [departmentValues, setDepartmentValues] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await DepartmentApi.getDepartment();
                if (data && Array.isArray(data)) {
                    setDepartmentValues(data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Designation Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                < MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Designation Details
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" value={inputValues.departmentName} name="departmentName" 
                                                            onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                            ref={input1Ref} onChange={handleInputChange} type="text" required>
                                                                <option value="">--Select--</option>
                                                                {(() => {
                                                                    if (departmentValues !== null && Array.isArray(departmentValues)) {
                                                                        return departmentValues.map((data) => (
                                                                            //console.log(data.departmentName),
                                                                            <option key={data.id} value={data.id} label={data.departmentName}>
                                                                                {data.departmentName}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label className="modify_lbl_text3">Department Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.departmentName && <label className="errorMessage">{errorMessages.departmentName}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} value={inputValues.designationName} 
                                                            onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                            ref={input2Ref} name="designationName" required />
                                                            <label className="modify_p_text2">Designation Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.designationName && <label className="errorMessage">{errorMessages.designationName}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" value={inputValues.description} name="description" 
                                                            onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                            ref={input3Ref} onChange={handleInputChange} required />
                                                            <label className="modify_p_text-fix">Description</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="control_radius">
                                            <div className="col-sm-12 depart_top">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteDesignationConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Designation is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Designation is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}

export default DesignationDetails;
