import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../../../Inventory/MyContext';

const RupeeSymbol = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [fontSize, setFontSize] = useState('');
  let id = props.keyValueId;
  const [position, setPosition] = useState({ x: 10, y: 20 });
  const [size, setSize] = useState({ width: 50, height: 50 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });


  const {
    clickedItemId,
    handleStyleChange,
    stylesArray
  } = useContext(MyContext)

  useEffect(() => {
    debugger
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

    if (matchingStyle) {
      // Extract and parse the position and size values
      const newPositionX = parseFloat(matchingStyle.style.left) || 0;
      const newPositionY = parseFloat(matchingStyle.style.top) || 0;
      const newWidth = parseFloat(matchingStyle.style.width) || 50;
      const newHeight = parseFloat(matchingStyle.style.height) || 20;

      // Update the state with the values
      setPosition({ x: newPositionX, y: newPositionY });
      setSize({ width: newWidth, height: newHeight });
    }
  }, [props.keyValueId, stylesArray]);  // Re-run the effect when keyValueId or stylesArray changes


  const deletebuttonshow = () => {

    // setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }

  };


  const handleMouseDown = (e) => {
    const { clientX, clientY } = e;

    // Check if the left mouse button (button === 0) is clicked for dragging
    if (e.button === 0) { // Left mouse button
      e.preventDefault();
      setIsDragging(true);
      setDragOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });

    }


    else if (e.button === 2) {
      e.preventDefault();

      const resizeHandleSize = 20;
      if (
        clientX >= position.x + size.width - resizeHandleSize &&
        clientY >= position.y + size.height - resizeHandleSize
      ) {
        setIsResizing(true);
        setResizeOffset({
          x: clientX - position.x - size.width,
          y: clientY - position.y - size.height,
        });
      }
    }
  };

  const [getValeStrg, setTheStrgValue] = useState('')


  useEffect(() => {
    debugger

    if (clickedItemId != '') {
      setTheStrgValue(clickedItemId)
    } else {
      setTheStrgValue('')
    }

  }, [clickedItemId]);


  // const handleScroll = (e) => {

  //   if (getValeStrg === id && e.ctrlKey) {
  //     e.preventDefault();
  //     const newHeight = e.deltaY < 0 ? size.height + 5 : size.height - 5;
  //     setSize((prevSize) => ({
  //       ...prevSize,
  //       height: Math.max(newHeight, 20),
  //     }));
  //   }
  // };

  const handleScroll = (e) => {
    if (clickedItemId === props.keyValueId && e.ctrlKey) {
      e.preventDefault();

      const newHeight = e.deltaY < 0 ? size.height + 5 : size.height - 5;


      setSize((prevSize) => ({
        ...prevSize,
        height: Math.max(newHeight, 20),
      }));

      handleStyleChange('height', newHeight, 'px', props.keyValueId);
    }
  };


  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [size]);

  useEffect(() => {
    if (size.height) {
      setFontSize(size.height)
    }
  }, [size.height]);

  const handleMouseMove = (e) => {
    if (isDragging) {
      const { clientX, clientY } = e;
      setPosition({
        x: clientX - dragOffset.x,
        y: clientY - dragOffset.y,
      });
    } else if (isResizing) {
      const { clientX, clientY } = e;
      setSize({
        width: clientX - position.x - resizeOffset.x,
        height: clientY - position.y - resizeOffset.y,
      });
    }
  };

  const handleMouseUp = (e) => {
    debugger
    if (e.button === 0) {
      setIsDragging(false);
      handleStyleChange('left', position.x, 'px', props.keyValueId);
      handleStyleChange('top', position.y, 'px', props.keyValueId);
    } else if (e.button === 2) {
      setIsResizing(false);
      handleStyleChange('width', size.width, 'px', props.keyValueId);
      handleStyleChange('height', size.height, 'px', props.keyValueId);
    }
  };



  const handlecompclick = (e, id) => {
    debugger
    e.preventDefault()
    props.onComponentClick(id);
    deletebuttonshow();
  };

  return (
    <div
      className="draggable-resizable-component-web-template  fieldbox-toolpanel-web-template"
      id={props.keyValueId}
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        cursor: isDragging
          ? "grabbing"
          : isResizing
            ? "nwse-resize"
            : "grab",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={(e) => handlecompclick(e, props.keyValueId)}
    >
      <div className="rupeesymbol-tool-web-template" style={{ fontSize: fontSize }} >₹</div>

      <button
        className="component-delete-web-template"
        id={props.keyValueId + "delete"}
        onClick={() => props.handleRemoveItem(props.keyValueId)}
      >
        <i className="fa-solid fa-trash"></i>
      </button>
    </div>
  )
}

export default RupeeSymbol