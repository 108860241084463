import { useEffect, useState } from "react";
import './POSInvoice.css';
import { Link, useNavigate } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import moment from 'moment';
import Company from "../../API/APIServices/Company";
import Loader from "../../Inventory/Props/Loader";
import ReportDesign from "./ReportDesign";

const KotHomeDelivery = ({ orderList, openKotItemModal, handleViewBtn }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [progress] = useState(70);

    const [orderListHome, setOrderListHome] = useState();

    const openItemDetailModal = () => {
        //setItemDetailModal(true);
        document.getElementById('scrollBar').classList.remove('scroll-container');
        document.getElementById('scrollBar').style.overflow = "hidden";
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }
        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }

        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }

        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes ? minutes : "00".toString().padStart(2, '0')} ${period}`;
    }

    const [orderHeaderFilterData, setOrderHeaderFilterData] = useState([])

    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                // const filterData = [
                //          {
                //             fieldName: "serviceTypeId",
                //             operatorName: "Equal",
                //             compareValue: 752 //Home Delivery
                //         }
                // ]
                // const data = await OrderApi.getOrderReportFilter(filterData);
                // console.log(data);
                setOrderListHome(orderList);
                setOrderHeaderFilterData(orderList)
                console.log("orderList", orderList);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchOrderData();
    }, [orderList])

    const [showBillPrint, setShowBillPrint] = useState(false);
    const [ord1Id, setOrd1Id] = useState(0);

    const closeBillPrint = () => {
        setShowBillPrint(false)
    }

    const handleBillPrintButton = (id) => {
        setOrd1Id(id)
        setShowBillPrint(true)
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id
        //     }
        // });
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);
        console.log(hours + ":" + minutes);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const [showRunningOrderColor, setRunningOrderColor] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId)
                setRunningOrderColor(data.isAllowDiffColorRunningOrder)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])


    const uniqueIds = new Set();

    const [timeSlot, setTimeSlot] = useState('default')

    const handleAddFilter = async (e, name) => {
        setLoading(true)

        let selectedData;

        if (name == "deliveryTimeId") {
            setTimeSlot(e.target.value)
            if (e.target.value == 'default') {
                selectedData = orderHeaderFilterData;
            }
            else {
                selectedData = orderHeaderFilterData.filter(item => {
                    const matchTimeSlot = item.deliveryTimeId == e.target.value;
                    return matchTimeSlot
                });
            }
        }
        setOrderListHome(selectedData);
        setLoading(false)
    };

    return (
        <div>
            <table className="tableStyle mt-4">
                <thead>
                    <tr>
                        <th className="thStyle">Select</th>
                        <th className="thStyle">Order No.</th>
                        <th className="thStyle">Name</th>
                        <th className="thStyle">Mobile No.</th>
                        <th className="thStyle">Address</th>
                        <th className="thStyle">Order Date</th>
                        <th className="thStyle">
                            <div>
                                <label>Delivery Time Slot</label><br />
                                <select style={{ backgroundColor: "white", width: "70px" }} value={timeSlot}
                                    onChange={(e) => handleAddFilter(e, "deliveryTimeId")}
                                >
                                    <option value="default">Select Time</option>

                                    {orderHeaderFilterData != null && orderHeaderFilterData != undefined &&
                                        orderHeaderFilterData
                                            .filter(data => {
                                                if (uniqueIds.has(data.deliveryTimeId)) {
                                                    return false;
                                                }
                                                uniqueIds.add(data.deliveryTimeId);
                                                return true;
                                            })
                                            .sort((a, b) => a.deliveryTimeId - b.deliveryTimeId)
                                            .map((data, index) => (
                                                console.log("dataaaa", data),
                                                <option key={index} value={data.deliveryTimeId}>
                                                    {/* {timeFormat(data.deliveryTime)} */}
                                                    {data.timeSlotName}
                                                </option>
                                            ))}
                                </select>
                                {/* <select style={{ width: "75px" }} value={orderListHome.deliveryTimeId}>
                                    <option selected disabled></option>
                                    <option>DD Snack</option>
                                    <option>DD Lunch</option>
                                    <option>DD Dinner</option> 
                                </select> */}
                            </div>
                        </th>
                        <th className="thStyle">Kot Des.<br />Target Time</th>
                        <th className="thStyle">Del date</th>
                        <th className="thStyle">Progress Status</th>
                        <th className="thStyle">KOT</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (orderListHome !== null && Array.isArray(orderListHome)) {
                            return orderListHome.filter(item => item.deliveredStatus == false) //.filter(data => (data.hasRunningOrder && data.hasRunningOrder == true) || data.overallOrderStatus === "Confirm")
                                // .slice().reverse()
                                .map((data, index) => {
                                    let backgroundStyle = { backgroundColor: "white" };
                                    if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                                        if (showRunningOrderColor) {
                                            backgroundStyle.backgroundColor = "rgba(255, 192, 203, 0.64)"; // Or "rgb(234, 163, 163)";
                                        }
                                    }
                                    return (
                                        <tr className="tableHover" style={{ ...backgroundStyle, cursor: "pointer" }}>
                                            <td className="tdStyle" style={{ textAlign: "center" }}><button onClick={() => openKotItemModal(data.ord1Id, data.tableName)} style={{ padding: "5px 12px" }}>{index + 1}</button></td>
                                            <td className="tdStyle">{data.orderNo}</td>
                                            <td className="tdStyle">{data.accName}</td>
                                            <td className="tdStyle">{data.accMobileNo}</td>
                                            <td className="tdStyle">{data.accAddress}</td>
                                            <td className="tdStyle">{moment(data.orderDate).format('DD-MM-YYYY')}</td>
                                            {/* <td className="tdStyle">{data.deliveryTime}</td> */}
                                            {/* <td className="tdStyle">{timeFormat(data.deliveryTime)}</td> */}
                                            <td className="tdStyle">{(data.timeSlotName)}</td>
                                            <td className="tdStyle">
                                                {/* {convertTo12HourFormat(data.kotDispatchTargetTime)} */}
                                                <div>
                                                    <label><span style={{ fontWeight: "600" }}>KDTT&nbsp;:&nbsp;</span>{data.kotDispatchTargetTime != "" && data.kotDispatchTargetTime != "00:00:00" ? convertTo12HourFormat(String(data.kotDispatchTargetTime)) : "00:00:00"}</label>
                                                    <br />
                                                    {/* <label><span style={{ fontWeight: "600" }}>DDT&nbsp;:&nbsp;</span>{data.deliveryBoyDispatchTime != "" && data.deliveryBoyDispatchTime != "00:00:00" ? convertTo12HourFormat(String(data.deliveryBoyDispatchTime)) : "00:00:00"}</label>
                                                    <br />
                                                    <label> <span style={{ fontWeight: "600" }}>DT&nbsp;:&nbsp;</span>{data.overallOrderStatus == "Cancel" ? "00:00:00" : (data.deliveredTime == "" ? "00:00:00" : data.deliveredTime)}</label> */}
                                                </div>
                                            </td>
                                            <td className="tdStyle">{moment(data.deliveryDate).format('DD-MM-YYYY')}</td>
                                            <td className="tdStyle">
                                                <div style={{ width: '100%', backgroundColor: 'rgb(178 178 178)', height: '20px' }}>
                                                    <div
                                                        style={{
                                                            width: `${((orderListHome[index].countQueue * 100) / (orderListHome[index].countItems * 4)) +
                                                                ((orderListHome[index].countProcess * 100) / (orderListHome[index].countItems * 2)) +
                                                                ((orderListHome[index].countComplete * 100) / (orderListHome[index].countItems * 1.25)) +
                                                                ((orderListHome[index].countDelivered * 100) / (orderListHome[index].countItems * 1))}%`,
                                                            backgroundColor: '#00b900',
                                                            height: '100%',
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    ><h6 style={{ fontWeight: "bold", color: "white", marginLeft: "30px" }}>
                                                            {Math.floor(
                                                                ((orderListHome[index].countQueue * 100) / (orderListHome[index].countItems * 4)) +
                                                                ((orderListHome[index].countProcess * 100) / (orderListHome[index].countItems * 2)) +
                                                                ((orderListHome[index].countComplete * 100) / (orderListHome[index].countItems * 1.25)) +
                                                                ((orderListHome[index].countDelivered * 100) / (orderListHome[index].countItems * 1))
                                                            )}%
                                                        </h6></div>
                                                </div>
                                            </td>
                                            <td className="tdStyle">
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <button className="viewBtn" onClick={() => handleBillPrintButton(data.ord1Id)}
                                                        style={{ padding: "10px" }}>Receipt</button>
                                                </div>
                                                <div className="mt-1" style={{ display: "flex", justifyContent: "center", padding: "5px" }}>
                                                    <button className="viewBtn" onClick={() => handleViewBtn(data.ord1Id, data.tableName)}
                                                        style={{ padding: "10px" }}>View</button>
                                                </div>
                                                {/* <div className="smallCircle" onClick={() => handlePrintButton(data.ord1Id, 
                                            data.paymentAmount, data.balanceAmt, data.grandTotal, data.paymentStatus, data.orderStatus)} 
                                            style={{ backgroundColor: "#cb77ff", marginTop: "0px" }}>
                                                        <i className="fa-solid fa-print leftSideIcon"></i>
                                        </div> */}
                                            </td>
                                        </tr>
                                    );
                                });
                        }
                        else {
                            //alert("else")
                        }
                    })()}
                </tbody>
            </table>
            {/* ****************************** BILL PRINT *************************** */}
            {showBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </div>
    );
};

export default KotHomeDelivery;
