import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserCredentialApi from "../../API/APIServices/UserCredential";
import Order from "../../API/APIServices/Order";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from "../../API/APIServices/BillPaymentMode";
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import MyContext from "../../Inventory/MyContext";
import PaymantModeModel from "./PaymantModeModel";
import { useNavigate } from "react-router-dom";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function PaymentReceivedDetail() {
    /***********title***************/
    useState(() => {
        document.title = 'Payment Received Detail';
    }, []);

    const navigate = useNavigate();

    const { isShowTotalAmountInReport } = useContext(MyContext);

    const [loading, setLoading] = useState(false);

    const getCurrentDate = async () => {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        return isoString;
    };

    const [addFilter, setFilter] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDate();
            setFromDate(date)
            setToDate(date)
            setFilter(
                [{
                    fieldName: "orderDate",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: date
                },
                {
                    fieldName: "orderDate",
                    operatorName: "LessOrEqualThan",
                    compareValue: date
                }]
            )
        }
        fetchData();
    }, [])


    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleFilterChange = async (event) => {
        setLoading(true)
        const { name, value } = event.target;

        let updatedFilter = [...addFilter];

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            let newFilter;
            if (name === "fromDate" || name === "toDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                if (name === "fromDate") {
                    setFromDate(formattedValue);
                }
                else {
                    setToDate(formattedValue);
                }
            } else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
            }

            const indexToUpdate = updatedFilter.findIndex(
                filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
            );

            if (indexToUpdate !== -1) {
                updatedFilter[indexToUpdate] = {
                    ...updatedFilter[indexToUpdate],
                    compareValue: newFilter.compareValue
                };
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
        console.log("updatedFilter", updatedFilter);

        try {
            const reportData = await Order.getPaymentReceivedReportWithFilter(updatedFilter);
            console.log("filteredData", reportData);
            fetchTotalAmt(reportData);
            setDataArray(reportData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchClick = () => {
        // setShowTable(true);
    };

    const handleBackBtn = () => {
        window.history.back();
    }

    // **************Get Order List************************

    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const date = await getCurrentDate();
            try {
                const filteredData = [{
                    fieldName: "orderDate",
                    operatorName: "Equal",
                    compareValue: date
                }]
                const val = await Order.getPaymentReceivedReportWithFilter(filteredData);
                console.log(val);
                fetchTotalAmt(val);
                setDataArray(val);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false)
        };

        fetchData();
    }, []);

    const [ItemDetail, setItemDetail] = useState({});
    const [isModalOpenPaymentDetail, setModalOpenPaymentDetail] = useState(false);
    const [PaymentModalData, setPaymentModalData] = useState([]);
    const [orderId, setOrderId] = useState();

    const showModalPaymentDetail = async (ord1Id, orderId) => {
        setLoading(true)
        setModalOpenPaymentDetail(true);
        handleGetPayment(ord1Id)
        setOrderId({ ord1Id: ord1Id, orderNo: orderId });

        await handleGetItem(ord1Id);
        setLoading(false)
    };

    const handleGetPayment = async (orderId) => {
        setLoading(true)
        try {
            const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
            console.log(PaymentData);
            setPaymentModalData(PaymentData);
            setLoading(false)
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const handleGetItem = async (id) => {
        // var filterData = {
        //     fieldName: "ord1Id",
        //     operatorName: "equal",
        //     compareValue: id,
        // };
        const data = await Order.getOrderListByOrd1Id(id);
        const ItemDetails = data.item1;
        setItemDetail(ItemDetails);
    };

    const hideModalPaymentDetail = () => {
        setModalOpenPaymentDetail(false);
    };

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // ***************** Assign user from user master ***********************
    const [assignUser, setAssignUser] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await UserCredentialApi.getUserCredential();
                setAssignUser(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                // const data = await UserCredentialApi.getUserCredential();
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    const [paymentModeDetails, setPaymentModeDetails] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const filteredData = [
                {
                    fieldName: "isDefault",
                    operatorName: "Equal",
                    compareValue: true
                },
                {
                    fieldName: "masterType",
                    operatorName: "Equal",
                    compareValue: 29
                }
            ]
            const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
            console.log("data", data);
            if (data) {
                const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
                console.log(paymentMode)
                setPaymentModeDetails(paymentMode);
            }
            setLoading(false)
        }
        fetchData();
    }, [])

    const [paymentTotalAmt, setPaymentTotalAmt] = useState(0);

    const fetchTotalAmt = (orderReport) => {
        const total = orderReport
            // .filter((item, index, self) =>
            //     index === self.findIndex((t) => (
            //         t.ord1Id === item.ord1Id || t.ord2Id === item.ord2Id
            //     )))
            .reduce((sum, data) => sum + (data.paymentAmount), 0);
        setPaymentTotalAmt(total.toFixed(2));
    }

    const handlePayment = async () => {
        const orderNo = orderId.orderNo;
        const ord1Id = orderId.ord1Id;

        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });
    }

    return (
        <div>
            <PageTabs />
            <div className="panel-heading" >
                <i className="fa-solid fa-arrow-left icon-white display-none-500" onClick={handleBackBtn} style={{ color: "white", cursor: "pointer", paddingLeft: "15px" }} ></i>
                <div className="panel-head-title">
                    <h2 className="panel-title">Payment Received Detail</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} handleSearchClick={handleSearchClick} saveButtonHide={true} />
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width company-width-pymt" >
                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1" style={{ marginBottom: "5px" }}>Payment Received Detail</div>
                                {/* <div className="input-order-buttons input-copy-Trans"> */}
                                <div className="input-remark-left01 input_copy_stock payment-height-Prd" style={{ marginTop: "10px" }}>

                                    <div className="input-containerEffect mt-1 input-pur-invoice date-time-payment1" style={{ width: "48%" }} >
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="date" required
                                            onChange={handleFilterChange} name="fromDate" style={{ fontSize: "12px" }} value={fromDate} />
                                        <label id="input1" className="labelEffect1">Order From Date</label>
                                    </div>
                                    <div className="input-containerEffect mt-1 input-pur-invoice date-time-payment1" style={{ width: "48%" }} >
                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required
                                            onChange={handleFilterChange} name="toDate" style={{ fontSize: "12px" }} value={toDate} />
                                        <label id="input1" className="labelEffect1">Order To Date</label>
                                    </div>

                                    <div className="input-containerEffect mt-1 date-time-payment1" style={{ width: "48%" }}>
                                        <div className="input-containerEffect mt-1 ">
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required>
                                                <option value="default">All</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Service Type</label>
                                        </div>
                                    </div>
                                    {/* <div className="input-containerEffect mt-1 date-time-payment1" >
                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }} required>
                                            <option>All</option>
                                            <option>Online</option>
                                            <option>Pos</option>
                                        </select>
                                        <label id="input1" className="labelEffect1">Invoice Type</label>
                                    </div> */}
                                </div>
                                {/* </div> */}
                                {/* <div className="input-order-buttons input-copy-Trans"> */}
                                <div className="input-remark-left01 input_copy_stock">
                                    <div className="input-containerEffect mt-1 date-time-payment1" >
                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input6Ref)} style={{ fontSize: "14px" }}
                                            onChange={handleFilterChange}
                                            name="paymentDoneById"
                                            required>
                                            <option value="default">--Select--</option>
                                            {(() => {
                                                if (assignUser !== null && Array.isArray(assignUser)) {
                                                    return assignUser.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.userName}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect1">Pay Receive By</label>
                                    </div>
                                    <div className="input-containerEffect mt-1 date-time-payment1" >
                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input6Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} style={{ fontSize: "14px" }}
                                            name="orderConfirmedById"
                                            onChange={handleFilterChange}
                                            required>
                                            <option value="default">--Select--</option>
                                            {(() => {
                                                if (assignUser !== null && Array.isArray(assignUser)) {
                                                    return assignUser.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.userName}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect1">Confirm By</label>
                                    </div>
                                    <div className="input-containerEffect mt-1 date-time-payment1" >
                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input7Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }}
                                            onChange={handleFilterChange}
                                            name="paymentModeId" required>

                                            <option value="default">All</option>
                                            {(() => {
                                                if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                                    return paymentModeDetails //.filter(item => item.display == true)
                                                        .map((data, index) => (
                                                            <option key={index} value={data.id}>{/*disabled={data.display == true ? false : true} */}
                                                                {data.accountTitle}
                                                            </option>
                                                        ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect1">Mode</label>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">Payment Detail</div>
                                <div className="col-sm-12" style={{ padding: "0" }}>
                                    <div className="table-resposive of-scroll" style={{ height: "510px" }}>
                                        <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                            <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                <tr className="">
                                                    <th className="td_col6 SNO-Width">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                    </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Date</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Amount</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Mode</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Pay U Id</div>
                                                    </div> </th>
                                                    {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Transaction Id</div>
                                                    </div> </th> */}
                                                    <th className="td_col6">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Customer</div>
                                                    </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Pay-Receive by</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Pay Time</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Done By</div>
                                                    </div> </th>
                                                    {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Img</div>
                                                    </div> </th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="tbody_body_m1">
                                                {/* {dataArray.map((item, index) => ( */}
                                                {dataArray !== null && dataArray.length > 0 ? (
                                                    dataArray
                                                        // .filter((item, index, self) =>
                                                        //     index === self.findIndex((t) => (
                                                        //         t.ord1Id === item.ord1Id || t.ord2Id === item.ord2Id
                                                        //     )))
                                                        .slice().reverse()
                                                        .map((item, index) => (
                                                            <tr className="table_tbody_Order_List" key={index}>
                                                                <td className="td_col6 td_sno-btn">
                                                                    <button className="copyBtn-chaitan sno_btn" onClick={() => showModalPaymentDetail(item.ord1Id, item.orderNo)}>
                                                                        {index + 1}
                                                                    </button>
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderNo}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{moment(item.orderDate).format('DD-MM-YYYY')}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "end" }}>₹&nbsp;{item.paymentAmount}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.paymentMode}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.payUId}</td>
                                                                {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.TransactionId}</td> */}
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {item.accName}<br />{item.accMobileNo}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.paymentDoneByName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>
                                                                    {moment.utc(item.paymentDoneOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}
                                                                    {/* {moment(item.paymentDoneOn).format('DD-MM-YYYY hh:mm A')} */}
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.orderConfirmedByName}</td>
                                                                {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}></td> */}
                                                            </tr>
                                                        ))
                                                ) : null
                                                }
                                                {isShowTotalAmountInReport && (
                                                    <tr className="table_tbody_Order_List"
                                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }}>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={3} style={{ textAlign: "end" }}><span style={{ fontWeight: "600" }}>Total Amt&nbsp;:&nbsp;</span></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                            <span style={{ fontWeight: "600" }}>&#x20B9;</span>
                                                            {paymentTotalAmt === isNaN ? 0 : paymentTotalAmt}
                                                            <br />
                                                        </td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/*********************** status Modal ********************/}
                                {isModalOpenPaymentDetail && (
                                    // <div className="modal" >
                                    //     <div className="AddNewItem-modal modal-payment">
                                    //         <div className="modal-title">
                                    //             <h3 className="modal-OrderLIst modal-color-CAD"></h3>
                                    //             <span className="close_modal" onClick={hideModalPaymentDetail}>
                                    //                 &times;
                                    //             </span>
                                    //         </div>
                                    //         <div className="ModalContentModalSettingP modal-overflow-pymt">
                                    //             <ModalPaymentDetail
                                    //                 ItemDetail={ItemDetail} />
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    <PaymantModeModel handlePayment={handlePayment} PaymentModalData={PaymentModalData}
                                        closePaymentModal={hideModalPaymentDetail} orderList={ItemDetail} serviceTypeId={751} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default PaymentReceivedDetail;




