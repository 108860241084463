import React, { useState } from 'react'
import Dropdown from './Dropdown'
import InputBox from './InputBox'
import FloatingLabelTextArea from './TextArea'

const PaymentGateway = () => {

    const [formData, setFormData] = useState({
        paymentGateway: '',
        transactionLoginId: '',
        gatewayUrl: '',
        transactionPassword: '',
        transactionProductId: '',
        transactionInCurrency: '',
        transactionSignatureReq: '',
        signatureResponse: '',
        redirectPage: '',
        paymentPageInstruction: ''
    });


    console.log(formData)

    const options = [
        { label: "Atom", value: "Atom" },
        { label: "payumoney", value: "payumoney" },
        { label: "ccavenue", value: "ccavenue" },
        { label: "paygurush", value: "paygurush" }
    ]

    const itemList = [
        'Card',
        'Cash',
        'Cess Account',
        'CGST',
        'Sale',
        'UPI',
        'Freight Charges',
        'Paytm',
    ];
    const [inputValue, setInputValue] = useState('');
    const [filteredItems, setFilteredItems] = useState(itemList);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);



    // Handle input change
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value) {
            const filtered = itemList.filter(item =>
                item.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(itemList);
        }
    };

    // Show all items on focus
    const handleInputFocus = () => {
        setFilteredItems(itemList);
        setIsDropdownVisible(true);
    };

    // Hide dropdown when the input loses focus
    const handleInputBlur = () => {
        setTimeout(() => {
            setIsDropdownVisible(false);
        }, 100); // Timeout to allow click event to register
    };

    // Handle item click
    const handleItemClick = (item) => {
        setInputValue(item);
        setIsDropdownVisible(false);
    };

    return (
        <div>
            <div className='heading-setting'>Payment Gateway Settings</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Payment Gateway"}
                        options={options}
                        selectedValue={formData.paymentGateway}
                        name="paymentGateway"
                        onChange={(selectedOption) => setFormData({ ...formData, paymentGateway: selectedOption })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Transaction Login Id"}
                        value={formData.transactionLoginId}
                        name="transactionLoginId"
                        onChange={(value) => setFormData({ ...formData, transactionLoginId: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea
                        label={"Gateway Url"}
                        value={formData.gatewayUrl}
                        name="gatewayUrl"
                        onChange={(value) => setFormData({ ...formData, gatewayUrl: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Transaction Password"}
                        value={formData.transactionPassword}
                        name="transactionPassword"
                        onChange={(value) => setFormData({ ...formData, transactionPassword: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Transaction Product ID"}
                        value={formData.transactionProductId}
                        name="transactionProductId"
                        onChange={(value) => setFormData({ ...formData, transactionProductId: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Transaction In Currency"}
                        value={formData.transactionInCurrency}
                        name="transactionInCurrency"
                        onChange={(value) => setFormData({ ...formData, transactionInCurrency: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Transaction Signature(Req)"}
                        value={formData.transactionSignatureReq}
                        name="transactionSignatureReq"
                        onChange={(value) => setFormData({ ...formData, transactionSignatureReq: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Signature (Response)"}
                        value={formData.signatureResponse}
                        name="signatureResponse"
                        onChange={(value) => setFormData({ ...formData, signatureResponse: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Redirect Page"}
                        value={formData.redirectPage}
                        name="redirectPage"
                        onChange={(value) => setFormData({ ...formData, redirectPage: value })}
                    />
                </div>
                <div className='grid-item-container'>
                    <div style={{ width: '100%', margin: '20px 0', position: 'relative' }}>
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            style={{ width: '100%', padding: '10px', boxSizing: 'border-box', borderRadius: '5px' }}
                            placeholder="Set Account For Payment Gateway"
                        />
                        {isDropdownVisible && (
                            <ul
                                style={{
                                    listStyle: 'none',
                                    padding: '0',
                                    margin: '0',
                                    position: 'absolute',
                                    width: '100%',
                                    maxHeight: '100px',
                                    overflowY: 'auto',
                                    border: '1px solid #ccc',
                                    borderTop: 'none',
                                    backgroundColor: '#fff',
                                    zIndex: '1000',
                                    borderBottomLeftRadius: '5px',
                                    borderBottomRightRadius: '5px',
                                    borderTopLeftRadius: '3px',
                                    borderTopRightRadius: '3px',

                                }}
                            >
                                {filteredItems.map((item, index) => (
                                    <li
                                        key={index}
                                        onMouseDown={() => handleItemClick(item)}
                                        style={{
                                            padding: '8px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className='grid-item-container'>
                    <FloatingLabelTextArea
                        label={"Payment Page Instruction"}
                        value={formData.paymentPageInstruction}
                        name="paymentPageInstruction"
                        onChange={(value) => setFormData({ ...formData, paymentPageInstruction: value })}
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default PaymentGateway
