import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useContext } from "react";
import TableButton from "../../Props/TableButton";
import MainButton from "../../Props/MainButton";
import TableGroupApi from '../../../API/APIServices/TableGroup';
import DataTable from "./DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function TableMasterList(props) {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Table Group List';
    }, []);

    //******************* {Modal Box} ************************//
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (props.openModal == true) {
            showModal();
        }
    }, [])

    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    // *************************************************************************************

    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });
    const [inputValues, setInputValues] = useState({
        name: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const validateInput = (fieldName) => {
        console.log(fieldName);
        setErrorMessages("Table Group Name field is required")
        return true;
    };
    const fieldNames = ['name '];

    const SaveBtn = () => {
        console.log("savebtn")
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = TableGroupApi.updateTableGroup(inputValues);
                if (isSuccess) {
                    setTimeout(() => {
                        fetchData();
                    }, 1000);
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                    hideModal();
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = TableGroupApi.insertTableGroup(inputValues);
                if (isSuccess) {
                    setTimeout(() => {
                        fetchData(); // Call fetchData after the delay
                    }, 1000);
                    //fetchData();
                    setLoading(false);
                    OpenAlertBox();
                    hideModal();
                } else {
                    alert("Error inserting data");
                }
            }
            //fetchData();
            setInputValues({ tableGroupName: "", });
        }
    }

    // ********************************************List Part ******************************

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "Name" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await TableGroupApi.getTableGroupFilter();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    name: item.name,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editTableGroup(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteTableGroup(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editTableGroup = async (id) => {
        showModal();
        setEdit(true)
        const dataById = TableGroupApi.getTableGroupById(id);
        if (dataById) {
            try {
                const editData = await dataById;
                console.log("dataaaa" + editData);
                setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }
    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteTableGroup = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }

    const DeleteTableGroupConfirm = async () => {
        setLoading(true);
        try {
            const val = await TableGroupApi.deleteTableGroup(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                if (isModalOpen === true) {
                    hideModal();
                }
                else {
                    window.history.back();
                }
            }
            if (event.key === 'F10') {
                SaveBtn();
            }
            if (event.key === 'F2') {
                showModal();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div>
            <div className="nav-1">
                <div className="panel-heading">
                    <h2 className="panel-title">Table Group List</h2>
                    < TableButton showModal={showModal} isSearchButton={true} filterData={filterData} columns={columns} data={data1}  PageName="Masters" />
                </div>
                <div className="panel panel-default">
                    <div className="col-sm-12">
                        <DataTable filterData={filterData} columns={columns} data={data1} />
                    </div>
                </div>
            </div>
            {/* ***************** Table Modal****************** */}
            {isModalOpen && (
                <div className="modal" onClick={hideModal}>
                    <div className="modalContentTable" onClick={handleModalClick}>
                        <div className="modal-title">
                            <h3 className="modalTableTitleColor"> </h3>
                            <span className="closeTableModal" onClick={hideModal}>
                                &times;
                            </span>
                        </div>
                        <div>
                            {/* <PageTabs /> */}
                            <div className="panel-heading">
                                <div className="panel-head-title">
                                    <h2 className="panel-title">Table Group Master </h2>
                                </div >
                                {/* *******************Top Buttons****************** */}
                                <MainButton SaveButton={SaveBtn} hideModal={hideModal}  PageName="Masters"  />
                            </div>

                            <div className="content_box">
                                <div className="company-info-top-im">
                                    <div className="tab-head">
                                        <div id="tabs">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="modal-content-im">
                            <div className="table_main_01">
                                <div className="input-box-Im ">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_limit mode_category1" type="text" value={inputValues.name} name="name" onChange={handleInputChange} required />
                                        <label className="modify_p_text2">Table Group Name
                                            <span className="StarClr">*
                                                {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="cl-toggle-switch td-3">
                                        <label className="cl-switch">
                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* ********************************************* Mobile View ******************************************* */}
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">

                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>
            {/* ********************************* */}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteTableGroupConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Table group is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Table Group is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}

export default TableMasterList;
