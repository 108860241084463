import React, { } from "react";
import { Link } from "react-router-dom";

function AllowanceMasterList() {

    return (
        <div>
            <div className="" >
                {/*< PageTabs />*/}
                <div className="nav-1" >
                    <div className="panel-heading">
                        <h2 className="panel-title">Salary Master List</h2>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="search_panel_hd1">
                                <div className="search_panel_left">
                                    <div className="col-sm-n1">
                                        <div className="group">
                                            <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                            <input placeholder="Search" type="search" className="search_panel_input" />
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="search_panel_right">
                                <div className="search_panel_f1" >
                                    <div className="button-mp1">
                                        <button className="table_search_btn">
                                            <i className="fa-solid fa-magnifying-glass im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp2" >
                                        <button className="table_reset_btn">
                                            <i className="fa-solid fa-rotate im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp3">
                                        <Link to="/SalaryMaster" id="step-5" className="add_btn">
                                            <i className="fa-solid fa-square-plus im-icon-6"></i>
                                        </Link>
                                    </div>
                                    <div  className="button-mp4">
                                        <button  className="xls_btn">
                                            <i className="fa-solid fa-file-excel im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp5">
                                        <button className="Print_btn">
                                            <i className="fa-solid fa-print im-icon-6"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-12 mt-3">
                                <div className="table-resposive" >
                                    <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                        <thead textAlign="center" className="thead_scroll_col1" >
                                            <tr className="">
                                                {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                                <th className="td_col5 th"> <div className="table_head_mmb ">
                                                    <div className="table_head_mmb1">S.No.</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Leave Name</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">No. of Leave Eligible</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Monthly Restriction</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Monthly Allow</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>

                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Proportionate to</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Leave For</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Max Carry Forward To</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Input</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Round-Off</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Earned Leave</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>
                                                <th className="td_col5 th"> <div className="table_head_mmb">
                                                    <div className="table_head_mmb1">Show Leave</div>
                                                    <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                                </div> </th>

                                                <th className="table_edit table_head_mmb1 th" style={{ textAlign: "center" }} >Edit</th>
                                                <th className="table_delete table_head_mmb1 th" style={{ textAlign: "center" }} >Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tbody_body_m1">
                                            <tr className="table_tbody_tr1">
                                                <td className="td_col5">1</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Casual Leave (CL)</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>15</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>All</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Yearly</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Upto Two Digit</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Always</td>

                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            </tr>
                                            <tr className="table_tbody_tr1">
                                                <td className="td_col5">2</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Casual Leave (CL)</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>15</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>All</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Yearly</td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Upto Two Digit</td>
                                                <td className="td_col5" style={{ textalign: "start" }}></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>Always</td>

                                                <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AllowanceMasterList;
