import React, { useContext, useEffect, useState } from "react";
import ItemMaster from "../../API/APIServices/ItemMaster";
import MyContext from "../MyContext";

const MetaContentItemMaster = (props) => {
  const {
    handleKeyPress,
    input129Ref,
    input130Ref,
    input131Ref,
    input132Ref,
    input133Ref,
    inputValues,
    ItemMasterActive,
    mataData,
    setMetadata,
    handleInputChange
  } = props;

  useEffect(() => {
    console.log(mataData)
  }, [mataData])
  const { UserDetails } = useContext(MyContext)
  const saveMeta = async () => {
    // Update metadata state with D1_Id
    debugger
    setMetadata({
      ...mataData,
      D1_Id: props.data.d1_Id
    });

    try {
      // Call asynchronous function with metadata
      const data = await ItemMaster.EcomSEO(mataData);

      if (data) {
        alert("Saved successfully");
      } else {
        alert("Error saving data");
      }
    } catch (error) {
      console.error("Error saving metadata:", error);
      alert("An error occurred while saving");
    }
  };





  return (
    <div className="tab-content-2">
      {ItemMasterActive && (
        <div className="">
          <div className="company-info-top-im">
            <div className="company-info-top-left-f1">
              <div className="company_info-text pb-15">Meta Details</div>
              <div className="">
                <div className="input-box-tab-head">
                  <div className="input-box-m2">
                    <textarea
                      className="modify_im_textarea mode_category2"
                      ref={input129Ref}
                      onKeyDown={(event) => handleKeyPress(event, input130Ref)}
                      type="text"
                      placeholder="Meta Tag Keyword of Product Page(Less Then 1000 Characters)"
                      required
                      onChange={handleInputChange}
                      value={inputValues.MetaKeywords}
                      name="MetaKeywords"
                    />
                    <label className="modify_p_text-fix">Meta Keywords</label>
                  </div>
                </div>
                <div className="input-box-tab-head">
                  <div className="input-box-m2">
                    <textarea
                      className="modify_im_textarea mode_category2"
                      ref={input130Ref}
                      onKeyDown={(event) => handleKeyPress(event, input131Ref)}
                      type="text"
                      placeholder="Meta Desc. Tag Keyword of Product Page(Less Then 200 Characters)"
                      required
                      onChange={handleInputChange}
                      value={inputValues.MetaDescription}
                      name="MetaDescription"
                    />
                    <label className="modify_p_text-fix">
                      Meta Description
                    </label>
                  </div>
                </div>
                <div className="input-box-tab-head">
                  <div className="input-box-m2">
                    <textarea
                      className="modify_im_textarea mode_category2"
                      ref={input131Ref}
                      onKeyDown={(event) => handleKeyPress(event, input132Ref)}
                      type="text"
                      placeholder="Meta Title Tag Keyword of Product Page(Less Then 100 Characters)"
                      required
                      onChange={handleInputChange}
                      value={inputValues.MetaTitle}
                      name="MetaTitle"
                    />
                    <label className="modify_p_text-fix">Meta Title</label>
                  </div>
                </div>
                <div className="input-box-tab-head">
                  <div className="input-box-m2">
                    <textarea
                      className="modify_im_textarea mode_category2"
                      ref={input132Ref}
                      onKeyDown={(event) => handleKeyPress(event, input133Ref)}
                      type="text"
                      placeholder="Search Keyword of Product Page"
                      required
                      onChange={handleInputChange}
                      value={inputValues.SearchEnginFriendlyPageName}
                      name="SearchEnginFriendlyPageName"
                    />
                    <label className="modify_p_text-fix">
                      Search engine friendly Page Name
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {UserDetails?.canModifyMaster && (

            <div className="ButtonTabSave">
              <button
                id="SaveBtnMain"
                onClick={saveMeta}
                className={`save-button-main-save hovered`}
              >
                Save/F10
                <i
                  id="step-2"
                  className="fa-regular fa-floppy-disk im-icon-white"
                ></i>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MetaContentItemMaster;
