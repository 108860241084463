import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";


const ValuesArray = ({ nestIndex }) => {

};

const OptionalfieldList = (propd, {onClick}) => {
    // const [showInput, setShowInput] = useState(false);
    const [submittedData, setSubmittedData] = useState([]);
    const [optionName, setOptionName] = useState(""); // Add this line for the option name
    // const [showInput, setShowInput] = useState(false);

    // const [submittedDataAll, setSubmittedDataAll] = useState([]);

    // let combinedValuesArray = [];
    // const handleCheckboxChange = (event) => {
    //     setShowInput(event.target.checked);
    // };
    console.log(submittedData)
    // console.log(submittedDataAll)

    const formMethods = useForm();
    const {  handleSubmit, control } = formMethods;

    const onSubmit = (data) => {
        console.log(data);
        setSubmittedData([]);
        setSubmittedData((prevData) => [...prevData, data]);
        console.log("hello")
        // callEmptyFunc()

    };
    // const callEmptyFunc = () => {
    //     // var limit = combinedValuesArray.length;
    //     // for (var i = 0; limit >= i; i++) {
    //     //     combinedValuesArray.pop();
    //     // }
    // }

    const { fields, append, remove } = useFieldArray({
        control,
        name: "options",
    });


    //*****************edit********************//
    return (
        <div>
           
            <div>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ul>
                            {fields.map((item, index) => (
                                <div key={item.id} style={{width:"100%"}}>
                                    <label style={{width:"80%"}} className="cakcbox-label-1">
                                        {/* Option Name */}
                                   
                                             <span>{formMethods.getValues(`options[${index}].name`)}</span>
                                    </label>
                                    <span style={{width:"20%"}} className="checkbox-btn-delete" type="button" onClick={() => remove(index)}>
                                        <i className="fa-solid fa-trash"></i>

                                    </span>
                                    <ValuesArray nestIndex={index} />
                                </div>

                            ))}
                        </ul>
                        <section style={{marginLeft:"8%"}}>
                            <label>
                                {/* Option Name: */}
                                <input
                                    type="text"
                                    placeholder="Enter Optional Field "
                                    value={optionName}
                                    onChange={(e) => setOptionName(e.target.value)}
                                />
                            </label>
                            <button
                                style={{ marginLeft: "10px" }}
                                className="checkbox-btn-addField"
                                type="button"
                                onClick={(customFieldAddBtn) => {
                                    append({ name: optionName, values: [] });
                                    setOptionName(""); // Clear the input after adding the option
                                }}
                            >
                                Add 
                                
                            </button>
                        </section>
                    </form>
                </FormProvider>
            </div>
            {/* )} */}

        </div>
    );
};

export default OptionalfieldList;
