import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const FeedbackSetting = {

    insertFeedbackSetting: async (data) => {
        const updatedData = data.map(data1 => ({
            feedbackCaption: data1.feedbackCaption,
            maxFeedbackValue: Number(data1.maxFeedbackValue),
            serviceTypeId: Number(data1.serviceTypeId),
        }));
        console.log(updatedData)
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/CreateFeedbackSetting`;
            const response = await axios.post(apiUrl, updatedData, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getFeedbackSetting: async (filters) => {
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/GetFeedbackSettings`;
            const response = await axios.post(apiUrl, filters, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteFeedbackSetting: async (feedbackSettingId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/DeleteFeedbackSetting?feedbackSettingId=${feedbackSettingId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getFeedBackById: async (feedbackSettingId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/FeedbackSetting?feedbackSettingId=${feedbackSettingId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateFeedbackSetting: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting?feedbackSettingId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                departmentName: data.departmentName,
                inchargePerson: data.inchargePerson,
                contactNo: data.contactNo,
                address: data.address,
                emailId: data.emailId,
                isActive: data.isActive,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ***************************Insert Order Wise Feedback Setting ***************************
    orderWiseFeedbackSetting: async (data) => {
        const updatedData = data.map(data1 => ({
            ord1Id: data1.ord1Id,
            feedbackId: data1.feedbackId,
            userId: data1.userId,
            d1AccountId: data1.d1AccountId,
            d2AccountId: data1.d2AccountId,
            feedbackValue: data1.feedbackValue,
            feedbackCaption: data1.feedbackCaption,
        }));
        console.log(updatedData)
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/CreateOrderWiseFeedbackSetting`;
            const response = await axios.post(apiUrl, updatedData, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get order wise feedback setting ***************************
    getOrderWiseFeedbackSetting: async (filterData) => {
        const filteredData = filterData.map((data) => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue,
        }));
        try {
            const apiUrl = `${WebApiUrl}/api/FeedbackSetting/GetOrderWiseFeedbackSettings`;
            const response = await axios.post(apiUrl, {
                filter: filteredData,
                order: [
                    {
                        propertyName: "string",
                        ascending: true,
                    },
                ],
                pageNumber: 0,
                pageSize: 0,
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default FeedbackSetting;
