import React, { useState } from 'react'
import Dropdown from './Dropdown'

const B2BSetting = () => {

    const fields = [
        "Show Login Popup in Index",
        "Item Price by A/C Category",
        "Allow Single Vendor",
        "Allow Main Vendor As Seller",
        "Allow To Update Vendor Setting",
        "Product Published By Vendor",
        "Stock Check",
        "Allow view price Tag Option",
        "Allow Multi Vendor Check Cart Amount",
    ]

    const [inputValues, setInputValues] = useState({
        showLoginPopupinIndex: '',
        itemPricebyACCategory: '',
        allowSingleVendor: '',
        allowMainVendorAsSeller: '',
        allowToUpdateVendorSetting: '',
        productPublishedByVendor: '',
        stockCheck: '',
        allowViewPriceTagOption: '',
        allowMultiVendorCheckCartAmount: '',
    })

    console.log(inputValues)

    const handleChange = (field, value) => {
        setInputValues(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];
    const Stockoptions = [
        { label: 'Check Stock', value: true },
        { label: 'No', value: false }
    ];

    return (
        <div>
            <div className='heading-setting'>B2B Setting</div>
            <div className='grid-container' >
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Show Login Popup in Index"}
                        options={options}
                        selectedValue={inputValues.showLoginPopupinIndex}
                        onChange={(newvalue) => handleChange("showLoginPopupinIndex", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Item Price by A/C Category"}
                        options={options}
                        selectedValue={inputValues.itemPricebyACCategory}
                        onChange={(newvalue) => handleChange("itemPricebyACCategory", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow Single Vendor"}
                        options={options}
                        selectedValue={inputValues.allowSingleVendor}
                        onChange={(newvalue) => handleChange("allowSingleVendor", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow Main Vendor As Seller"}
                        options={options}
                        selectedValue={inputValues.allowMainVendorAsSeller}
                        onChange={(newvalue) => handleChange("allowMainVendorAsSeller", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow To Update Vendor Setting"}
                        options={options}
                        selectedValue={inputValues.allowToUpdateVendorSetting}
                        onChange={(newvalue) => handleChange("allowToUpdateVendorSetting", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Product Published By Vendor"}
                        options={options}
                        selectedValue={inputValues.productPublishedByVendor}
                        onChange={(newvalue) => handleChange("productPublishedByVendor", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Stock Check"}
                        options={Stockoptions}
                        selectedValue={inputValues.stockCheck}
                        onChange={(newvalue) => handleChange("stockCheck", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow view price Tag Option"}
                        options={options}
                        selectedValue={inputValues.allowViewPriceTagOption}
                        onChange={(newvalue) => handleChange("allowViewPriceTagOption", newvalue)}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Allow Multi Vendor Check Cart Amount"}
                        options={options}
                        selectedValue={inputValues.allowMultiVendorCheckCartAmount}
                        onChange={(newvalue) => handleChange("allowMultiVendorCheckCartAmount", newvalue)}
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default B2BSetting
