import React, { useState } from 'react';
import './Page4.css';

function FloatingDropdown({ label, options, value, Onchange }) {
  const [isFocused, setIsFocused] = useState(false);

  // console.log(selectedValue)

  return (
    <div className={`floating-dropdown ${isFocused ? 'focused' : ''}`}>
      <select
        value={value}
        onChange={Onchange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={isFocused ? 'focused' : ''}
      >
        <option value="" disabled >Select</option>
        {options.map((item, index) => (
          <option key={index} value={item.id}>
            {item.imageBannerName}
          </option>
        ))}
      </select>
      <label>{label}</label>
    </div>
  );
}



export default FloatingDropdown;
