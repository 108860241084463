import React, {  useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";


const ValuesArray = ({ nestIndex }) => {
  const { register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `options[${nestIndex}].values`, // REMOVE value field
  });

  const handleValueKeyDown = (event) => {
    if (event.key === 'Enter') {
      append({ value: "" }); // Add a new value field
    }
  };


  return (
    <div>
      <label className="cakcbox-label-1">Option Values</label>
      {fields.map((item, k) => (
        <div key={item.id}>
          <input
            className="checkbox-input-1"
            {...register(`options[${nestIndex}].values[${k}].value`)}
            onKeyDown={handleValueKeyDown}
          />
          <span className="checkbox-btn-delete" type="button"  onClick={() => remove(k)}>
            <i className="fa-solid fa-trash"></i>
          </span>
        </div>
      ))}
      <button className="checkbox-add-value" type="button" onClick={() => append({ value: "" })}>
        Add
      </button>
      <hr />
    </div>
  );
};


const CheckboxWithInput = () => {
  const [showInput, setShowInput] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);
  // const [submittedDataAll, setSubmittedDataAll] = useState([]);

  let combinedValuesArray = [];
  const handleCheckboxChange = (event) => {
    setShowInput(event.target.checked);
  };
  console.log(submittedData)
  // console.log(submittedDataAll)

  const formMethods = useForm();
  const { register, handleSubmit, control } = formMethods;

  const onSubmit = (data) => {
    console.log(data);
    setSubmittedData([]);
    setSubmittedData((prevData) => [...prevData, data]);
    console.log("hello")
    callEmptyFunc()

  };
  const callEmptyFunc = () => {
    var limit = combinedValuesArray.length;
    for (var i = 0; limit >= i; i++) {
      combinedValuesArray.pop();
    }
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });



  // const combinedValues = (value, value1) => {
  //   const combinedValue = `${value}/${value1}`;
  //   combinedValuesArray = []
  //   combinedValuesArray.push(combinedValue);
  // };
  // console.log(combinedValuesArray)

  //*****************edit********************//

  //*****************edit********************//
  ///****************delete*******************///
  const deleteCombinedValue = (dataIndex, valueIndex, values) => {
    // Implement the delete logic here
    const updatedData = [...submittedData];
  
    // Assuming the structure of submittedData is like:
    // { options: [ { name: "", values: [ { value: "" }, ... ] }, ... ] }
  
    // Remove the value at the specified index within the nested array
    updatedData[dataIndex].options[valueIndex].values.splice(valueIndex, 1);
  
    // Update the state
    setSubmittedData(updatedData);
  };

  ////************************************/////


  return (
    <div>
      <label>
        <input
          className="checkbox-variant"
          type="checkbox"
          checked={showInput}
          onChange={handleCheckboxChange}
        />
        Show Input Box
      </label>

      {showInput && (
        <div>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ul>
                {fields.map((item, index) => (
                  <div key={item.id}>
                    <label className="cakcbox-label-1">
                      Option Name
                      <input className="modify_im_input"
                        {...register(`options[${index}].name`)} />
                    </label>
                    <span className="checkbox-btn-delete" type="button" onClick={() => remove(index)}>
                      <i className="fa-solid fa-trash"></i>

                    </span>
                    <ValuesArray nestIndex={index} />

                  </div>

                ))}
              </ul>
              <section>
                <button
                  className="checkbox-btn-add"
                  type="button"
                  onClick={() => append({ name: "", values: [] })}
                >
                  Add Option
                </button>
              </section>
              <input type="submit" value="Submit" className="checkbox-btn-submit" />
            </form>
          </FormProvider>
        </div>
      )}

      {submittedData.length > 0 && (
        <div>
          <h2>Variant</h2>
          <table className="checkbox-table-1">
            <thead>
              <tr>
                <th>Variant</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {submittedData.map((data, dataIndex) => (
                <tr key={dataIndex}>
                  {data.options.map((option, dataIndex) => (
                    <div className="checkbox-table-b2" key={option.id}>
                      {option.values.map((value) => (
                        <div className="checkbox-table-b1" key={value.id}>


                          {data.options[dataIndex + 1] && data.options[dataIndex + 1].values && (

                            data.options[dataIndex + 1].values.map((value1) => (
                              <tr className="checkbox-table-b2" key={value1.id}>

                                <td className="checkbox-table-t1" key={value1.id}>



                                  {value.value}/{value1.value}
                                  {/* {combinedValues(value.value, value1.value)} */}
                                  



                                  

                                  {/* {console.log(value1.id)}
                                  {combinedValues.push(`${value.value}/${value1.value}`)} */}
                                  {/* {SubmDataAll(value.value, value1.value)}
                                  {setSubmittedDataAll((prevUrls) => [...prevUrls,value.value/value1.value])}  */}

                                </td>

                              </tr>
                            ))
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                  {combinedValuesArray.map((item, index) => (
                    <p> {index + 1} {item}</p>
                  )

                  )}

                  <td>

                    {/* {submittedData.map((data, dataIndex) => (
                      <tr key={dataIndex}>
                        {data.options.map((option, optionIndex) => (
                          <td key={optionIndex}>
                            {option.values.map((value) => (
                              <div className="checkbox-table-b1" key={value.id}>
                                {data.options[optionIndex + 1] && data.options[optionIndex + 1].values && (
                                  data.options[optionIndex + 1].values.map((value1) => (
                                    <React.Fragment key={value1.id}>
                                      <div className="checkbox-table-b2">
                                        <td className="checkbox-table-t1">
                                          {value.value}/{value1.value}
                                          {console.log(value1.id)}
                                          {SubmDataAll(`${value.value}/${value1.value}`)}
                                        </td>
                                      </div>
                                    </React.Fragment>
                                  ))
                                )}
                              </div>
                            ))}
                          </td>
                        ))}
                      </tr>
                    ))} */}


                    {data.options.map((option, dataIndex) => (
                      <div key={option.id}>
                        {option.values.map((value) => (
                          <div className="checkbox-table-b1" key={value.id}>
                            {/* <h2>1st value: {value.value}</h2> */}

                            {data.options[dataIndex + 1] && data.options[dataIndex + 1].values && (
                              data.options[dataIndex + 1].values.map((value1) => (
                                <tr className="checkbox-table-b2" key={value1.id}>
                                  {/* {value.value} / {value1.value} */}
                                  <input className="checkbox-input-2" type="number" placeholder="Enter price" />
                                </tr>
                              ))
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </td>
                  <td>
                    {data.options.map((option, dataIndex) => (
                      <div key={option.id}>
                        {option.values.map((value) => (
                          <div className="checkbox-table-b1" key={value.id}>
                            {data.options[dataIndex + 1] && data.options[dataIndex + 1].values && (
                              data.options[dataIndex + 1].values.map((value1) => (
                                <tr className="checkbox-table-b2" key={value1.id}>
                                  <input className="checkbox-input-2" type="number" placeholder="Enter Quantity" />
                                </tr>
                              ))
                            )}

                          </div>
                        ))}
                      </div>
                    ))}
                  </td>
                  <td>
                    {data.options.map((option, dataIndex) => (
                      <div key={option.id}>
                        {option.values.map((value) => (
                          <div className="checkbox-table-b1" key={value.id}>
                            {data.options[dataIndex + 1] && data.options[dataIndex + 1].values && (
                              data.options[dataIndex + 1].values.map((value1) => (
                                <tr className="checkbox-table-b2" key={value1.id}>
                                              <button onClick={() => deleteCombinedValue(dataIndex, value1)}>
                                                    Delete
                                                </button>

                                </tr>
                              ))
                            )}

                          </div>
                        ))}
                      </div>
                    ))}

                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      )}
    </div>
  );
};

export default CheckboxWithInput;
