import React, { useState } from "react";

const PriceList = (props) => {
  //**************************{Table}*****************************

  const [taxRows, setTaxRows] = useState([
    { id: 1, TaxName: "", Percent: "", Amount: "" },
  ]);

  const handleCellKeyDown = (event, rowIndex) => {
    if (event.key === "Enter") {
      const newRow = { id: taxRows.length + 1, content: "" };
      setTaxRows([...taxRows, newRow]);
      const nextRowIndex = rowIndex + 1;
      const nextRowCellId = `cell-${nextRowIndex}-0`;
      const nextRowCell = document.getElementDyId(nextRowCellId);
      if (nextRowCell) {
        nextRowCell.focus();
      }
    }
  };

  const [, setClearText] = useState("");
  const handleClearData = () => {
    // Create a new array with empty values to clear all input fields
    const clearedRows = taxRows.map((row) => ({
      RateA: "",
      RateB: "",
      RateC: "",
      RateD: "",
      RateE: "",
      RateF: "",
      RateG: "",
      RateH: "",
      RateI: "",
      RateJ: "",
    }));

    // Update the state with the cleared rows
    setTaxRows(clearedRows);
    setClearText("");
  };

  return (
    <div>
      <div>
        <div className="">
          <div className=" table-container-tbody TableTbodyScroll">
            {taxRows.map((row, rowIndex) => (
              <div
                style={{ height: "38px" }}
                className="table-tr22"
                key={rowIndex}
              >
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                    //   name={row.RateA}
                    //   value={row.RateA}
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateA}
                      name="ICateRateA"
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate A
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateB}
                      name="ICateRateB"
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate B
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                    
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateC}
                      name="ICateRateC"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate C
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                     
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateD}
                      name="ICateRateD"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate D
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateE}
                      name="ICateRateE"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate E
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                                          className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateF}
                      name="ICateRateF"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate F
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateG}
                      name="ICateRateG"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate G
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                onChange={props.handleInputChange}
                value={props.inputValues.ICateRateH}
                name="ICateRateH"
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate H
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                  
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateI}
                      name="ICateRateI"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate I
                    </label>
                  </div>
                </div>
                <div className="table-td-2 HTR01">
                  <div style={{ position: "relative" }}>
                    <input
                      className="modify_im_input mode_limit mode_category"
                      type="text"
                      style={{ height: "28px" }}
                      required
                      onChange={props.handleInputChange}
                      value={props.inputValues.ICateRateJ}
                      name="ICateRateJ"
                    />
                    <label
                      className="modifyTextLabel"
                      style={{ left: "9px", top: "-8px", lineHeight: "12px" }}
                    >
                      Rate J
                    </label>
                  </div>
                </div>
                <div className="" style={{ textAlign: "center" }}>
                  {/* <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button> */}
                  <i
                    onClick={handleClearData}
                    className="fa-solid fa-xmark delete-btn"
                  ></i>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div onClick={handleCellKeyDown}></div>
      </div>
    </div>
  );
};

export default PriceList;
