import { useContext, useEffect, useState } from "react";
import "./POSInvoice.css";
import Order from "../../API/APIServices/Order";
import ItemDetailModalBox from "./ItemDetailModalBox";
import { useNavigate } from "react-router-dom";
import OrderApi from "../../API/APIServices/Order";
import GstModalOrderView from "./GstModalOrderView";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";
import OrderDetailStatusModal from "./OrderDetailStatusModal";
import PaymantModeModel from "./PaymantModeModel";
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import Loader from "../../Inventory/Props/Loader";
import TrackOrderModel from "./TrackOrderModel";
import moment from "moment";
import DeliveryPointName from "../../API/APIServices/DeliveryPointName";
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ReportDesign from "./ReportDesign";

const HomeDelivery = (props) => {
  const { deliveryBoy, orderListHomeDelivery, formatDate1, setOrderListHomeDelivery,
    fetchOrderData, OrderWiseButtonClick, fetchOrderDataHome, handleRefreshBtn, selectedDateFilter,
    deliveryTimeSlotData, orderHeaderFilterData, startIndexHome, summary, handlePaymentPay, addFilterForHome,
    setSummary, setPageIndexHome } = props;

  const [loading, setLoading] = useState(true);

  const { messageFunction, maskMobileNo, UserDetails } = useContext(MyContext); //isAutoTransferToKitchen

  const orderList = orderListHomeDelivery;

  // const deliveryTimeSlotsData = deliveryTimeSlotData;

  // console.log(deliveryTimeSlotData);

  // useEffect(() => {
  //   if (orderList && orderList.length > 0) {
  //     fetchTotalAmt(orderList);
  //   }
  // }, [orderList])

  const [deliveryBoyId, setDeliveryBoyId] = useState([]);
  const [RemarkId, setRemarkId] = useState([]);

  const [GstModal, setGstModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  var [filterDataForOrder, setfilterDataForOrder] = useState([
    {
      fieldName: "serviceTypeId",
      operatorName: "Equal",
      compareValue: 752, //Home Delivery
    },
    {
      fieldName: "orderDate",
      operatorName: "Equal",
      compareValue: selectedDateFilter,
    }
  ])

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    // fetchLocation();
  }, [])

  const getCurrentTime = async () => {
    const isoString = await CurrentDateAndTime.getCurrentTime();
    return isoString;
  };

  const UpdatePaymentPartially = async (e, data) => {
    if (window.confirm("Are you sure you want to change the payment status")) {
      // payment status and paid amount and balance amount 
      const value = Number(e.target.value);
      console.log(value)
      console.log(data)
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      var loginUser;
      if (storedArrayString) {
        loginUser = JSON.parse(storedArrayString);
      }

      const datetime = await CurrentDateAndTime.getCurrentDateTime();
      // const datetime = new Date(isoString);

      const updatedValues = [
        {
          operationType: 0,
          path: "PaymentModeId",
          op: "Add",
          from: "string",
          value: value
        },
        {
          operationType: 0,
          path: "balanceAmt",
          op: "Add",
          from: "string",
          value: "0"
        },
        {
          operationType: 0,
          path: "paymentAmount",
          op: "Add",
          from: "string",
          value: Number(data.grandTotal) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0))
        },
        {
          operationType: 0,
          path: "paymentStatus",
          op: "Add",
          from: "string",
          value: "Done"
        },
        // {
        //   operationType: 0,
        //   path: "PaymentDoneTime",
        //   op: "Add",
        //   from: "string",
        //   value: new Date().toISOString()
        // },
        {
          operationType: 0,
          path: "paymentDoneOn",
          op: "Add",
          from: "string",
          value: datetime
        },
        {
          operationType: 0,
          path: "paymentDoneBy",
          op: "Add",
          from: "string",
          value: loginUser.userId
        }
      ]

      await Order.updateOrderPartially(updatedValues, data.ord1Id);

      const filterData1 = [{
        ord1Id: data.ord1Id,
        paymentModeId: value,
        paymentAmount: Number(data.grandTotal) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0)),
        transId: "",
        payUId: 0,
        bankRefNo: "",
        status: "",
        paymentTypeMethod: "",
      }]
      await Order.createPaymentModeWithOrder(filterData1)
      fetchOrderData();
      alert("Updated");
    }

    // const Update = [
    //   {
    //     operationType: 0,
    //     path: "Remarks",
    //     op: "Add",
    //     from: "string",
    //     value: id,
    //   },
    // ];
    // console.log("Data to update order:", Update); // Logging for debugging
    // try {
    //   const update = Order.updateOrderPartially(Update);
    //   if (update) {
    //     alert("Updated Remark")
    //   }
    //   // Handle success or further processing
    // } catch (error) {
    //   console.error("Error updating order:", error); // Error handling
    //   // Handle error condition
    // }
  }

  const [ord1Id, setOrd1Id] = useState();

  const openGstModal = async (id) => {
    handleRefreshBtn();
    setOrd1Id(id);
    await handleGetItem(id);
    setGstModal(true);
  };

  const closeGstModal = () => {
    handleRefreshBtn();
    setGstModal(false);
  };

  const [info, setinfo] = useState(false)

  const showInfo = () => {
    setinfo(true)
  }

  const hideInfo = () => {
    setinfo(false)
  }

  const [selectedDate, setSelectedDate] = useState('default');

  const [previousDates, setPreviousDates] = useState([]);


  const [DeliverySlotData, setDeliveryData] = useState([])

  useEffect(() => {
    const getdeliveryTimeAlot = async () => {
      try {
        const filteredData = [{
          fieldName: "isDefault",
          operatorName: "Equal",
          compareValue: true
        }]
        const deliveryPointData = await DeliveryPointName.getDeliveryPointNamesWithFilter(filteredData);

        const dataForAllotedTime = {
          deliveryPointName: deliveryPointData.length > 0 ? deliveryPointData[0].id : 1,
          orderType: 752,
          distance: 0
        }
        const Data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(dataForAllotedTime)
        setDeliveryData(Data)
      } catch (error) {
        console.log(error);
      }
    }
    getdeliveryTimeAlot()
  }, [])

  useEffect(() => {
    const today = new Date();
    const dates = [];

    for (let i = 0; i < 2; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date);
    }
    console.log("Formated Date", dates);
    setPreviousDates(dates);

    setSelectedDate(formatDate(dates[0]));

  }, []);

  const convertDateFormat = (inputDate) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(inputDate)) {
      throw new Error("Invalid date format. Expected yyyy-mm-dd.");
    }
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [orderDate, setOrderDate] = useState('default')
  const [deliveryDate, setDeliveryDate] = useState('default')
  const [timeSlot, setTimeSlot] = useState('default')
  const [overAllStatus, setOverAllStatus] = useState('default')

  const [tableHeaderFilterData, setTableHeaderFilterData] = useState([]);

  const handleAddFilter = async (e, name) => {
    setLoading(true)
    const value = e.target.value;

    let selectedData;

    if (name == "orderDate") {
      setOrderDate(e.target.value)
      if (e.target.value == 'default') {
        selectedData = orderHeaderFilterData.filter(item => {
          //const matchOrderDate = item.orderDate === e.target.value;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId == timeSlot;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchDeliveryDate && matchTimeSlot && matchOverAllStatus;
        });
      }
      else {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = item.orderDate === e.target.value;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId == timeSlot;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchOrderDate && matchDeliveryDate && matchTimeSlot && matchOverAllStatus;
        });
      }
    }
    else if (name == "deliveryDate") {
      setDeliveryDate(e.target.value)
      if (e.target.value == 'default') {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          // const matchDeliveryDate = item.deliveryDate === e.target.value;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId == timeSlot;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchOrderDate && matchTimeSlot && matchOverAllStatus;
        });
      }
      else {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          const matchDeliveryDate = item.deliveryDate === e.target.value;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId == timeSlot;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchOrderDate && matchDeliveryDate && matchTimeSlot && matchOverAllStatus;
        });
      }
    }
    else if (name == "deliveryTimeId") {
      setTimeSlot(e.target.value)
      if (e.target.value == 'default') {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          // const matchTimeSlot = item.deliveryTimeId === e.target.value;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchOrderDate && matchDeliveryDate && matchOverAllStatus;
        });
      }
      else {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          const matchTimeSlot = item.deliveryTimeId == e.target.value;
          const matchOverAllStatus = overAllStatus === 'default' || item.overallOrderStatus === overAllStatus;

          return matchOrderDate && matchDeliveryDate && matchTimeSlot && matchOverAllStatus;
        });
      }
    }
    else if (name == "overallOrderStatus") {
      setOverAllStatus(e.target.value)
      if (e.target.value == 'default') {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId == timeSlot;
          // const matchOverAllStatus = item.overallOrderStatus === e.target.value;

          return matchOrderDate && matchDeliveryDate && matchTimeSlot;
        });
      }
      else {
        selectedData = orderHeaderFilterData.filter(item => {
          const matchOrderDate = orderDate === 'default' || item.orderDate === orderDate;
          const matchDeliveryDate = deliveryDate === 'default' || item.deliveryDate === deliveryDate;
          const matchTimeSlot = timeSlot === 'default' || item.deliveryTimeId === timeSlot;
          const matchOverAllStatus = item.overallOrderStatus === e.target.value;

          return matchOrderDate && matchDeliveryDate && matchTimeSlot && matchOverAllStatus;
        });
      }
    }
    console.log("orderHeaderFilterData", selectedData)
    setOrderListHomeDelivery(selectedData);

    console.log("orderHeaderFilterData", orderHeaderFilterData)
    // alert(name)
    let updatedData = [...addFilterForHome, ...tableHeaderFilterData];

    if (value === "default") {
      updatedData = updatedData.filter(item => item.fieldName !== name);
    } else {
      const index = updatedData.findIndex(item => item.fieldName == name);
      if (index !== -1) {
        const filterData = {
          fieldName: name,
          operatorName: "Equal",
          compareValue: value
        }
        updatedData[index] = filterData;
      } else {
        const filterData = {
          fieldName: name,
          operatorName: "Equal",
          compareValue: value
        }
        updatedData.push(filterData);
      }
    }
    console.log(updatedData);
    setTableHeaderFilterData(updatedData);
    setPageIndexHome(1);
    const orderData = await OrderApi.getRawOrderReportFilter(updatedData, 1);
    setSummary(orderData?.summary);

    // let FilterToSend;

    // if (name == "orderDate")
    //   setSelectedDate(e.target.value)
    // else if (name == "deliveryDate")
    //   setSelectedDateFD(e.target.value)

    // if (e.target.value === "default") {
    //   // Remove the filter object with the specified name
    //   setfilterDataForOrder(prevFilters => prevFilters.filter(filter => filter.fieldName !== name));
    //   const updatedData = filterDataForOrder.filter(filter => filter.fieldName !== name);
    //   handletestFetch(updatedData);
    //   return;
    // }

    // const newFilter = {
    //   fieldName: name,
    //   operatorName: "Equal",
    //   compareValue: e.target.value,
    // };

    // // Check if a filter with the same name already exists
    // const existingFilterIndex = filterDataForOrder.findIndex(filter => filter.fieldName === name);

    // if (existingFilterIndex !== -1) {
    //   // If a filter with the same name exists, update its value
    //   const updatedFilters = [...filterDataForOrder]; // Copying filterDataForOrder array
    //   updatedFilters[existingFilterIndex] = newFilter; // Updating the desired index with newFilter
    //   FilterToSend = updatedFilters;


    //   setfilterDataForOrder(prevFilters => {
    //     const updatedFilters = [...prevFilters];
    //     updatedFilters[existingFilterIndex] = newFilter;
    //     return updatedFilters;
    //   });

    // } else {
    //   // If no filter with the same name exists, add the new filter
    //   setfilterDataForOrder(prevFilters => [...prevFilters, newFilter]);
    //   FilterToSend = [...filterDataForOrder, newFilter];
    // }
    // handletestFetch(FilterToSend)
    setLoading(false)
  };

  const handletestFetch = async (FilterToSend) => {
    setLoading(true)
    console.log("filterData", FilterToSend);
    const data = await OrderApi.getOrderListReport(FilterToSend);
    setOrderListHomeDelivery(data);
    setLoading(false)
  }

  useEffect(() => {
    console.log(filterDataForOrder)
  }, [filterDataForOrder])

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  // Helper function to format time
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const isDeliveryBoyAlreadyStartedOtherTrip = async (userId) => {
    const filteredData = [
      {
        fieldName: "delBoyId",
        operatorName: "Equal",
        compareValue: userId
      },
      {
        fieldName: "tripStatus",
        operatorName: "Equal",
        compareValue: "Open"
      }
    ]
    const data = await Order.getDeliveryBoyTrip(filteredData);
    return data;
  }

  const deliveryBoySet = async (e, id, rowIndex) => {
    const newDeliveryBoyId = e.target.value;

    const data = await isDeliveryBoyAlreadyStartedOtherTrip(newDeliveryBoyId);
    // alert(data.length);
    if (data.length > 0) {
      const delBoyName = deliveryBoy.filter(item => item.id == newDeliveryBoyId);
      alert(`${delBoyName[0].userName || "Delivery Boy"} is on Another Trip`);
      return 0;
    }
    else {
      const newDataArray = [...deliveryBoyId];
      newDataArray[rowIndex] = {
        ...newDataArray[rowIndex],
        deliveryBoyId: newDeliveryBoyId,
      };
      setDeliveryBoyId(newDataArray);
      const dataToUpdate = [
        {
          operationType: 0,
          path: "DeliveryBoyId",
          op: "add",
          from: "string",
          value: newDeliveryBoyId,
        },
        {
          operationType: 0,
          path: "DeliveryBoyDispatchTime",
          op: "add",
          from: "string",
          value: formatTime(currentTime),
        },
      ];
      console.log("Data to update order:", dataToUpdate); // Logging for debugging
      try {
        const updateDeliveryBoy = await Order.updateOrderPartially(dataToUpdate, id);
        setOrderListHomeDelivery(prevValues => {
          const updatedList = [...prevValues];
          updatedList[rowIndex] = { ...updatedList[rowIndex], deliveryBoyId: newDeliveryBoyId };
          return updatedList;
        });
      } catch (error) {
        console.error("Error updating order:", error); // Error handling
        // Handle error condition
      }
      // OrderWiseButtonClick("HomeDelivery");
      fetchOrderDataHome();
    }
  };

  const RemarkSet = (e, id, rowIndex) => {
    const newRemarkId = e.target.value;
    const newDataArray = [...RemarkId]; // Copy the original array
    newDataArray[rowIndex] = {
      ...newDataArray[rowIndex],
      RemarkId: newRemarkId,
    };
    setRemarkId(newDataArray);
  };

  const remarkUpdate = (id, index) => {
    setLoading(true);
    const dataToUpdate = [
      {
        operationType: 0,
        path: "Remarks",
        op: "Add",
        from: "string",
        value: RemarkId[index].RemarkId,
      },
    ];
    console.log("Data to update order:", dataToUpdate); // Logging for debugging
    try {
      const updateDeliveryBoy = Order.updateOrderPartially(dataToUpdate, id);
      if (updateDeliveryBoy) {
        alert("Updated Remark")
      }
      // Handle success or further processing
    } catch (error) {
      console.error("Error updating order:", error); // Error handling
      // Handle error condition
    }
    setLoading(false);
  }
  const [hoverStates, setHoverStates] = useState({});

  const navigate = useNavigate();

  const handleMouseEnter = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: true },
    }));
  };

  const handleMouseLeave = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: false },
    }));
  };

  const isButtonHovered = (rowIndex, btnId) => {
    return hoverStates[rowIndex]?.[btnId];
  };

  const [ItemDetailModal, setItemDetailModal] = useState(false);
  const [ItemDetail, setItemDetail] = useState([]);


  const openItemDetailModal = async (id) => {
    await handleGetItem(id);
    setItemDetailModal(true);
  };

  const handleGetItem = async (id) => {
    var filterData = {
      fieldName: "ord1Id",
      operatorName: "equal",
      compareValue: id,
    };
    // const billdata = await Order.getOrderListWithFilter(filterData);
    const orderlistdata = await Order.getOrderItemWiseList(id);
    setItemDetail(orderlistdata);
    console.log(orderlistdata);
  };

  const closeItemDetailModal = () => {
    setItemDetailModal(false);
  };

  const [showBillPrint, setShowBillPrint] = useState(false);

  const closeBillPrint = () => {
    setShowBillPrint(false)
  }

  const handlePrintButton = (id) => {
    setOrd1Id(id)
    setShowBillPrint(true);

    // navigate("/OrderTakerBillPrint", {
    //   state: {
    //     id,
    //   },
    // });
  };

  function convertTo12HourFormat(twentyFourHourTime) {
    if (!twentyFourHourTime) {
      return "";
    }

    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  useEffect(() => {
    const newDataArray = [...RemarkId]; // Copy the original array
    if (orderListHomeDelivery) {
      if (orderListHomeDelivery.length > 0) {
        // Loop through the orderList to update the remarks in RemarkId
        orderListHomeDelivery.forEach((order, index) => {
          const newRemarkId = order.remarks; // Get the remark from orderList
          newDataArray[index] = { ...newDataArray[index], RemarkId: newRemarkId };
        });
        setRemarkId(newDataArray); // Update the RemarkId state
      }
    }
  }, [orderList]);

  const [Modal, setModal] = useState(false);
  const [OpenTrackOrder, setOpenTrackOrder] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const openTrackMdl = async (id) => {
    await handleGetItem(id);
    const selectedDataa = orderList.filter(item => item.ord1Id == id);
    setSelectedData(selectedDataa)
    setOpenTrackOrder(true);
  };

  const openTrackclose = async () => {
    handleRefreshBtn();
    setOpenTrackOrder(false);
  };

  const openModal = async (id) => {
    await handleGetItem(id);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  // const handlePaymentPay = async (orderNo, ord1Id) => {
  //   navigate("/Request", {
  //     state: {
  //       orderNo,
  //       ord1Id
  //     }
  //   });
  // }

  const [PaymentModal, setPaymentModal] = useState(false);
  const [PaymentModalData, setPaymentModalData] = useState([]);
  const [orderId, setOrderId] = useState();

  const openPaymentModal = (ord1Id, orderId) => {
    handleGetPayment(ord1Id)
    setOrderId({ ord1Id: ord1Id, orderNo: orderId });
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleGetPayment = async (orderId) => {
    const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
    console.log(PaymentData);
    setPaymentModalData(PaymentData);
    if (PaymentData.length > 0) {
      setPaymentModal(true);
    }
    else {
      alert("No Payment Found")
    }
  };

  const handlePayment = async () => {
    const orderNo = orderId.orderNo;
    const ord1Id = orderId.ord1Id;

    navigate("/Request", {
      state: {
        orderNo,
        ord1Id
      }
    });
  }

  const [paymentModeDetails, setPaymentModeDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const filteredData = [
        {
          fieldName: "isDefault",
          operatorName: "Equal",
          compareValue: true
        },
        {
          fieldName: "masterType",
          operatorName: "Equal",
          compareValue: 29
        }
      ]
      const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
      console.log("data", data);
      if (data.length > 0) {
        const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
        console.log(paymentMode)
        setPaymentModeDetails(paymentMode);
        setLoading(false);
      }
      else {
        //alert("Please select default Bill series");
      }
    }
    fetchData();
  }, [])

  const orderStatusFunction = async (event, ord1Id, index, deliveryBoyId, accId, mobileNo) => {
    const val = event.target.value;
    if (val == "Done") {
      if (deliveryBoyId == "0") {
        alert("Please Assign Delivery Boy First");
        return 0;
      }
    }
    if (val == "Confirm") {
      messageFunction("on_order_confirm", ord1Id, accId, mobileNo)
    }
    setOrderListHomeDelivery(prevValues => {
      const updatedList = [...prevValues];
      updatedList[index] = { ...updatedList[index], overallOrderStatus: event.target.value };
      return updatedList;
    });

    setLoading(true);

    const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
    // const formattedDate = new Date(isoString);

    const storedArrayString = localStorage.getItem('loginAccountUserData');
    const loginUser = JSON.parse(storedArrayString);

    if (val == "Returned") {
      var updatedValues = [
        {
          operationType: 0,
          path: "overallOrderStatus",
          op: "Add",
          from: "string",
          value: event.target.value
        },
        {
          operationType: 0,
          path: "returnedBy",
          op: "Add",
          from: "string",
          value: loginUser.userId
        },
        {
          operationType: 0,
          path: "returnedOn",
          op: "Add",
          from: "string",
          value: formattedDate
        },
      ]
      await OrderApi.updateOrderPartially(updatedValues, ord1Id);
      fetchOrderData();
      setLoading(false)
      return false;
    }
    // ************************************
    var updatedValues = [
      {
        operationType: 0,
        path: "overallOrderStatus",
        op: "Add",
        from: "string",
        value: event.target.value
      },
      {
        operationType: 0,
        path: "orderConfirmedBy",
        op: "Add",
        from: "string",
        value: loginUser.userId
      },
      {
        operationType: 0,
        path: "orderConfirmedOn",
        op: "Add",
        from: "string",
        value: formattedDate
      },
    ]
    if (val == "Delivered") {
      const time = await getCurrentTime();
      updatedValues.push(
        {
          operationType: 0,
          path: "DeliveryTripStatus",
          op: "Add",
          from: "string",
          value: "Close"
        },
        {
          operationType: 0,
          path: "deliveredTime",
          op: "Add",
          from: "string",
          value: time,
        },
      )
    }
    await OrderApi.updateOrderPartially(updatedValues, ord1Id);
    // ***************************
    const itemListData = await OrderApi.getOrderItemWiseList(ord1Id);
    console.log(itemListData);
    setLoading(true)
    for (var i = 0; i < itemListData.length; i++) {
      //alert(itemListData[i].kotStatus)
      if (itemListData[i].kotStatus === "Pending") {
        // **************************** Order 3 *********************
        const statusData = {
          ord1Id: ord1Id,
          ord2Id: itemListData[i].ord2Id,
          orderStatus: "Received",
          orderDateTime: formattedDate,
        }
        await OrderApi.insertItemStatus(statusData);

        // ************************** Order2 **********************
        const data = [
          {
            operationType: 0,
            path: "KotStatus",
            op: "Add",
            from: "string",
            value: "Received"
          }
        ]
        await OrderApi.updateItemPartially(data, itemListData[i].ord2Id);
      }
    }
    fetchOrderData();
    alert(`Updated status to ${val}`);
    setLoading(false);
  }

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00`;
    }
  }
  const timeFormat = (twentyFourHourTime) => {
    const [startTime, endTime] = twentyFourHourTime.split('-');
    const convertedStartTime = convertTo12HourFormat(startTime.trim());
    const convertedEndTime = convertTo12HourFormat(endTime.trim());
    const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
    return convertedTimeRange;
  }

  const formatTimestamp = (timestampString) => {
    const timestamp = new Date(timestampString);

    // Formatting the date
    const formattedDate = `${("0" + timestamp.getDate()).slice(-2)}-${("0" + (timestamp.getMonth() + 1)).slice(-2)}-${timestamp.getFullYear()}`;

    // Formatting the time
    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    // Combining date and time
    const formattedDateTime = `${formattedDate} , ${formattedTime}`;

    return formattedDateTime;
  }

  // const [orderTotalAmt, setOrderTotalAmt] = useState(0);
  // const [orderCancelAmt, setOrderCancelAmt] = useState(0);
  // const [orderBalAmt, setOrderBalAmt] = useState(0);
  // const [orderPaidAmt, setOrderPaidAmt] = useState(0);
  // const [totalReturn, setTotalReturn] = useState(0);
  // const [totalReturnRequest, setTotalReturnRequest] = useState(0);

  // const fetchTotalAmt = (orderReport) => {
  //   const total = orderReport.reduce((sum, data) => sum + (data.grandTotal), 0);
  //   setOrderTotalAmt(total.toFixed(2));

  //   const cancelTotal = orderReport.reduce((sum, data) => sum + (data.o1CancelledAmt), 0);
  //   setOrderCancelAmt(cancelTotal.toFixed(2));

  //   const balTotal = orderReport.reduce((sum, data) => sum + (data.grandTotal - (data.o1CancelledAmt + data.paymentAmount)), 0);
  //   setOrderBalAmt(balTotal.toFixed(2));

  //   const paidTotal = orderReport.reduce((sum, data) => sum + (data.paymentAmount), 0);
  //   setOrderPaidAmt(paidTotal.toFixed(2));

  //   const returnTotal = orderReport.filter(item => item.overallOrderStatus == "Returned");
  //   setTotalReturn(returnTotal.length)

  //   const returnRequestTotal = orderReport.filter(item => item.overallOrderStatus == "Return Request");
  //   setTotalReturnRequest(returnRequestTotal.length)
  // }

  const uniqueDates = new Set();
  const uniqueDatesForOrderDate = new Set();
  const uniqueIds = new Set();

  // ***************** Auto Transfer To Kitchen *********************
  // useEffect(() => {
  // const autoTransferToKitchen = async () => {
  //   try {
  //     await OrderApi.autoTranferToKitchen();
  //     console.log("Data transferred to kitchen");
  //   } catch (error) {
  //     console.log("Error transferring data to kitchen:", error);
  //   }
  // };

  // let intervalId;

  // if (isAutoTransferToKitchen) {
  //   intervalId = setInterval(autoTransferToKitchen, 10000);
  // }

  // return () => {
  //   if (intervalId) {
  //     clearInterval(intervalId);
  //   }
  // };
  // }, [isAutoTransferToKitchen]);

  const redirectToMaps = (latitude, longitude) => {
    const value = latitude + "," + longitude;
    const currentLocationQuery = currentLocation ? `${currentLocation.lat},${currentLocation.lng}` : '';
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(value)}&destination_place_id&origin=${currentLocationQuery}&origin_place_id&travelmode=driving`;
    window.open(mapsUrl, '_blank');
  };

  return (
    <div>
      <table className="tableStyle mt-4">
        <thead className="table_headerSticky" style={{ top: "-10px" }}>
          <tr>
            <th className="thStyle">Select</th>
            <th className="thStyle">Order No.</th>
            <th className="thStyle">Name</th>
            <th className="thStyle">Address</th>
            <th className="thStyle" style={{ width: "45%" }}>
              Total&nbsp;Amt.
            </th>
            <th className="thStyle">
              <div>
                <label>Order</label>
                <br />
                {/* <select name="" id="" onChange={(e)=>OnChangeDate(e)}> */}
                <select value={orderDate}
                  onChange={(e) => handleAddFilter(e, "orderDate")}
                // onChange={handleDateChange}
                >
                  <option value="default">Select date</option>
                  {/* {previousDates.map((date, index) => (
                    <option key={index} value={formatDate2(date)}>
                      {formatDate(date)}
                    </option>
                  ))} */}
                  {orderHeaderFilterData && orderHeaderFilterData
                    .filter(data => {
                      if (uniqueDatesForOrderDate.has(data.orderDate)) {
                        return false;
                      }
                      uniqueDatesForOrderDate.add(data.orderDate);
                      return true;
                    })
                    .map((data, index) => (
                      <option key={index} value={data.orderDate}>
                        {convertDateFormat(data.orderDate)}
                      </option>
                    ))}
                </select>
              </div>
            </th>
            <th className="thStyle">
              <div>
                <label>Delivery</label>
                <br />
                <select value={deliveryDate}
                  onChange={(e) => handleAddFilter(e, "deliveryDate")}
                // onChange={handleDateChangefordelivery}
                >
                  <option value="default">Select date</option>
                  {orderHeaderFilterData && orderHeaderFilterData
                    .filter(data => {
                      if (uniqueDates.has(data.deliveryDate)) {
                        return false;
                      }
                      uniqueDates.add(data.deliveryDate);
                      return true;
                    })
                    .map((data, index) => (
                      <option key={index} value={data.deliveryDate}>
                        {convertDateFormat(data.deliveryDate)}
                      </option>
                    ))}
                </select>
              </div>
            </th>
            <th className="thStyle">
              <div>
                <label style={{ position: "relative" }}>Time &nbsp;
                  {info && (
                    <div className="description">
                      <ul className="ul-padding-left">
                        <li className="li-margin-bot">KDTT - KOT Dispatch Target Time</li>
                        <li className="li-margin-bot">KDT - KOT Dispatch Time</li>
                        <li className="li-margin-bot">DDT - Delivery Done Time</li>
                        <li className="li-margin-bot">DT - Delivered Time</li>
                        <li className="li-margin-bot">PDT - Payment Done Time</li>
                        <li className="li-margin-bot">NPDT - Next Possible Delivery Time</li>
                      </ul>
                    </div>
                  )}
                </label>
                <i onMouseEnter={showInfo} onMouseLeave={hideInfo} class="fa-solid fa-circle-info"></i>
                <br />
                <select style={{ backgroundColor: "white", width: "70px" }} value={timeSlot}
                  onChange={(e) => handleAddFilter(e, "deliveryTimeId")}
                // onChange={FilterTimeSlot}
                >
                  <option value="default">Select Time</option>

                  {orderHeaderFilterData != null && orderHeaderFilterData != undefined &&
                    orderHeaderFilterData
                      .filter(data => {
                        if (uniqueIds.has(data.deliveryTimeId)) {
                          return false;
                        }
                        uniqueIds.add(data.deliveryTimeId);
                        return true;
                      })
                      .sort((a, b) => a.deliveryTimeId - b.deliveryTimeId)
                      .map((data, index) => (
                        console.log("dataaaa", data),
                        <option key={index} value={data.deliveryTimeId}>
                          {/* {timeFormat(data.deliveryTime)} */}
                          {data.timeSlotName}
                        </option>
                      ))}
                </select>
              </div>

            </th>
            <th className="thStyle">Delivery Boy</th>
            <th className="thStyle" style={{ width: "10%" }}>
              <div>
                <label>Order Status</label>
                <select style={{ backgroundColor: "white" }}
                  value={overAllStatus}
                  onChange={(e) => handleAddFilter(e, "overallOrderStatus")}
                // onChange={orderStatusFilter}
                >
                  <option value="default">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Confirm">Confirm</option>
                  <option value="Done">Done</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Return Request">Return Request</option>
                  <option value="Returned">Returned</option>
                </select>
              </div>
            </th>
            <th className="thStyle" style={{ width: "12%" }}>
              Payment
            </th>
            <th className="thStyle" style={{ width: "7%" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody className="tbody_body_m1">
          {(() => {
            if (orderList !== null && Array.isArray(orderList)) {
              return orderList
                // .slice().reverse()
                .map((data, rowIndex) => (
                  <tr className="hoverRow" style={{ cursor: "pointer" }}>
                    <td
                      className="tdStyle"
                      onClick={() => openItemDetailModal(data.ord1Id)}
                    >
                      <button style={{ padding: "5px 10px" }}>
                        {startIndexHome + rowIndex + 1}
                      </button>
                    </td>
                    <td className="tdStyle">{data.orderNo}</td>
                    <td className="tdStyle">
                      {data.name}
                      <br />
                      {/* {maskMobileNo(data.mobileNo)} */}
                      {UserDetails.canLst3DgtNo ? (maskMobileNo(data.mobileNo)) : (data.mobileNo)}
                    </td>
                    <td className="tdStyle">
                      <div>
                        <label><span style={{ fontWeight: "600" }}>Address&nbsp;:</span>{data.accAddress} &nbsp;
                          <i onClick={() => redirectToMaps(data.latitude, data.longitude)} className="fa-solid fa-location-dot" style={{ color: "green", fontSize: "18px" }}></i></label>
                        <label><span style={{ fontWeight: "600" }}>Latitude&nbsp;:</span>{data.latitude}</label>
                        <label><span style={{ fontWeight: "600" }}>Longitude&nbsp;:</span>{data.longitude}</label>
                      </div>
                    </td>
                    <td className="tdStyle" style={{ width: "20%", textAlign: "center" }}>
                      <span style={{ fontWeight: "600" }}>Total&nbsp;Amt: &#x20B9;</span>
                      {data.grandTotal !== null &&
                        data.grandTotal !== undefined
                        ? data.grandTotal
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Cancel&nbsp;Amt: &#x20B9;</span>
                      {data.o1CancelledAmt !== null &&
                        data.o1CancelledAmt !== undefined
                        ? data.o1CancelledAmt
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Bal.&nbsp;Amt: &#x20B9;</span>
                      {data.grandTotal !== null &&
                        data.grandTotal !== undefined
                        ? data.grandTotal - (data.o1CancelledAmt + data.paymentAmount)
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Paid&nbsp;Amt: &#x20B9;</span>
                      {data.paymentAmount !== null &&
                        data.paymentAmount !== undefined
                        ? data.paymentAmount
                        : 0}
                      <br />
                      <span style={{ fontWeight: "600" }}>Del&nbsp;Charges: &#x20B9;</span>
                      {data.deliveryCharges !== null &&
                        data.deliveryCharges !== undefined
                        ? data.deliveryCharges
                        : 0}
                    </td>
                    <td className="tdStyle" style={{ textAlign: "center" }}>
                      {formatDate1(data.orderDate)} <br /> {convertTo12HourFormat(String(data.orderTime))}
                    </td>
                    <td className="tdStyle" style={{ textAlign: "center" }}>
                      {formatDate1(data.deliveryDate)} <br /> {data.timeSlotName}
                      {/* {timeFormat((data.deliveryTime))} */}
                    </td>
                    <td className="tdStyle" style={{ width: "13%", textAlign: "center" }}>
                      <div>
                        <label><span style={{ fontWeight: "600" }}>KDTT&nbsp;:&nbsp;</span>{data.kotDispatchTargetTime != "" && data.kotDispatchTargetTime != "00:00:00" ? convertTo12HourFormat(String(data.kotDispatchTargetTime)) : "00:00:00"}</label>
                        <br />
                        <label><span style={{ fontWeight: "600" }}>KDT&nbsp;:&nbsp;</span>{data.kotDispatchTime != null && data.kotDispatchTime != 0 && data.kotDispatchTime != "" && data.kotDispatchTime != "00:00:00" ? convertTo12HourFormat(String(data.kotDispatchTime)) : "00:00:00"}</label>
                        <br />
                        {/* DDT :  {convertTo12HourFormat(String(data.deliveryBoyDispatchTime))} */}
                        <label><span style={{ fontWeight: "600" }}>DDT&nbsp;:&nbsp;</span>{data.deliveryBoyDispatchTime != "" && data.deliveryBoyDispatchTime != "00:00:00" && data.deliveryBoyDispatchTime != null && data.deliveryBoyDispatchTime != "0" ? convertTo12HourFormat(String(data.deliveryBoyDispatchTime)) : "00:00:00"}</label>
                        <br />
                        <label> <span style={{ fontWeight: "600" }}>DT&nbsp;:&nbsp;</span>{data.overallOrderStatus == "Cancel" ? "00:00:00" : (data.deliveredTime == "" || data.deliveredTime == "00:00:00" || data.deliveredTime == 0 || data.deliveredTime == null ? "00:00:00" : convertTo12HourFormat(String(data.deliveredTime)))}</label>
                        <br />
                        <label><span style={{ fontWeight: "600" }}>PDT&nbsp;:&nbsp;</span>{data.paymentStatus == "Pending" ? "00:00:00" : moment.utc(data.paymentDoneOn).utcOffset("+05:30").format('hh:mm A')}</label> {/*data.paymentDoneTime */}
                      </div>
                    </td>
                    <td className="tdStyle">
                      <div>
                        <label>
                          Delivery Point: Mother's Hut- 987654321 - Kitchen
                        </label>
                        <br />
                        <br />
                        <select
                          style={{ width: "120px", textAlign: "center" }}
                          onChange={(e) =>
                            deliveryBoySet(e, data.ord1Id, rowIndex)
                          }
                          disabled={data.overallOrderStatus == "Confirm" ? false : true}
                          value={data.deliveryBoyId
                            // || deliveryBoyId[rowIndex]?.deliveryBoyId
                            // || data.deliveryBoyId
                          }
                          name="deliveryBoyId"
                        >
                          <option value="0">Select</option>
                          {Array.isArray(deliveryBoy) &&
                            deliveryBoy.length > 0 ? (
                            deliveryBoy.map((person, index) => (
                              <option key={index} value={person.id}>
                                {person.userName}
                              </option>
                            ))
                          ) : (
                            <option disabled>No options available</option>
                          )}
                        </select>
                      </div>
                    </td>
                    <td className="tdStyle">
                      <div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                          <select
                            className="form-control modify_im_input"
                            style={{ width: "80%" }}
                            value={data.overallOrderStatus}
                            onChange={(event) => orderStatusFunction(event, data.ord1Id, rowIndex, (deliveryBoyId[rowIndex]?.deliveryBoyId || data.deliveryBoyId), data.d1AccId, data.accMobileNo)}
                          >
                            <option value="Pending"
                              disabled={data.overallOrderStatus == "Done" || data.overallOrderStatus == "Confirm" || data.overallOrderStatus == "Delivered"
                                || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Pending</option>
                            <option value="Confirm"
                              disabled={data.overallOrderStatus == "Done" || data.overallOrderStatus == "Delivered"
                                || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Confirm</option>
                            <option value="Done"
                              disabled={data.overallOrderStatus == "Delivered" || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Done</option>
                            {/* <option value="Cancel">Cancel</option> */}
                            <option value="Delivered"
                              disabled={data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" || (data.deliveryBoyId && data.deliveryBoyId != 0 && data.deliveryBoyId != "") ? true : false} >Delivered</option>
                            <option value="Return Request" disabled>Return Request</option>
                            <option value="Returned">Returned</option>
                            <option value="Cancel">Cancel</option>
                          </select>
                          <i onClick={() => openModal(data.ord1Id)}
                            class="fa-solid fa-circle-info mt-1 icon_ov1"
                            style={{
                              color: "#646464",
                              cursor: "pointer",
                              margin: "0 !important",
                            }}
                          ></i>
                        </div>
                        <div className="mt-2">
                          <label style={{
                            display: data.orderConfirmedByName != "" && data.orderConfirmedByName != null && data.orderConfirmedByName != undefined ? "block" : "none",
                            fontSize: "14px"
                          }} >
                            Confirm By :- <span style={{ fontWeight: "600" }}>{data.orderConfirmedByName}</span><br />
                            {moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}
                          </label>

                          <label style={{
                            display: data.returnedBy != "" && data.returnedBy != null && data.returnedBy != undefined ? "block" : "none",
                            fontSize: "14px"
                          }}>
                            Return By :- <span style={{ fontWeight: "600" }}>{data.returnedByName}</span><br />
                            {/* {moment(data.returnedOn).format('DD-MM-YYYY hh:mm A')} */}
                            {moment.utc(data.returnedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}
                          </label>
                        </div>
                      </div>
                    </td>
                    {/* <td className="tdStyle">
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <i onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                          onMouseEnter={() =>
                            handleMouseEnter(rowIndex, "btn1")
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(rowIndex, "btn1")
                          }
                          class="fa-solid fa-note-sticky icon_ov1"
                          style={{ color: "black", position: "relative" }}
                        >
                          {isButtonHovered(rowIndex, "btn1") && (
                            <div className="hover-text">Payment Details</div>
                          )}
                        </i>

                        <i onClick={() => handlePaymentPay(data.orderNo, data.ord1Id)}
                          onMouseEnter={() =>
                            handleMouseEnter(rowIndex, "btn2")
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(rowIndex, "btn2")
                          }
                          class="fa-solid fa-sack-dollar icon_ov1"
                          style={{ color: "#7c9504", position: "relative" }}
                        >
                          {isButtonHovered(rowIndex, "btn2") && (
                            <div className="hover-text">Pay Online</div>
                          )}
                        </i>
                      </div>
                      <div
                        className="mt-2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          onChange={(e) =>
                            RemarkSet(e, data.ord1Id, rowIndex)
                          }
                          value={
                            RemarkId[rowIndex]?.RemarkId
                          }
                          style={{ width: "130px", marginRight: "5px" }}
                        />
                        <i
                          onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                          class="fa-solid fa-floppy-disk icon_ov1"
                          style={{
                            color: "brown",
                            cursor: "pointer",
                            position: "relative",
                            fontSize: "20px"
                          }}
                        ></i>
                      </div>
                      <div className="mt-2" style={{ display: "flex" }}>
                        <select
                          style={{
                            width: "70px",
                            padding: "2px 5px",
                            textAlign: "center",
                          }}
                          className="form-control mt-1"
                        >
                          <option>Cash</option>
                          <option>CDC</option>
                          <option>HDFC</option>
                        </select>{" "}
                        &nbsp;
                        <button
                          className="btn mt-1"
                          style={{
                            fontWeight: "600",
                            color: "#574e4e",
                            backgroundColor: "ghostwhite",
                            border: "1px solid darkgrey",
                          }}
                        >
                          {data.paymentStatus ? data.paymentStatus : "Pending"}
                        </button>
                      </div>
                    </div>
                  </td> */}
                    <td className="tdStyle">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "grid", alignItems: "baseline", alignContent: "baseline" }}>
                          <div
                            className="mt-2"
                            style={{ display: "flex", alignItems: "center" }}
                          >

                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Remark"
                                style={{ width: "140px", marginRight: "12px" }}
                                name={`remark-${rowIndex}`}
                                value={inputValues[`remark-${rowIndex}`] || ''}
                                onChange={(event) => handleInputChange(event, rowIndex)}
                              />
                              <i
                                className="fa-solid fa-floppy-disk icon_ov1"
                                style={{ color: "brown", cursor: "pointer" }}
                                onClick={() => updateRemarkPartially(data.ord1Id, rowIndex)}
                              ></i> */}
                            <input
                              type="text"
                              className="form-control remark-ov"
                              placeholder="Remark"
                              onChange={(e) =>
                                RemarkSet(e, data.ord1Id, rowIndex)
                              }
                              value={
                                RemarkId[rowIndex]?.RemarkId
                              }
                              style={{ width: "138px", marginRight: "5px" }}
                            />
                            <i
                              onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                              class="fa-solid fa-floppy-disk icon_ov1"
                              style={{
                                color: "brown",
                                cursor: "pointer",
                                position: "relative",
                                fontSize: "20px"
                              }}
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "btn10")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "btn10")
                              }
                            >
                              {isButtonHovered(rowIndex, "btn10") && (
                                <div className="hover-text">Save Remark</div>
                              )}
                            </i>
                          </div>

                          <div className="mt-2" style={{ display: "flex", marginTop: "10px" }}>
                            <select
                              disabled={data.paymentStatus === "Done" ? true : false}
                              value={data.payModeId || 0}
                              onChange={(e) => UpdatePaymentPartially(e, data)}
                              style={{
                                width: "70px",
                                padding: "2px 5px",
                                textAlign: "center",
                              }}
                              className="OrderPayMode form-control mt-1"
                            >
                              <option value="0" default selected> --- </option>
                              {(() => {
                                if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                  return paymentModeDetails //.filter(item => item.display == true)
                                    // .filter(data =>
                                    //   data.accountTitle && typeof data.accountTitle === 'string' &&
                                    //   !data.accountTitle.toLowerCase().includes("online" || "cdc"))
                                    .map((data, index) => (
                                      <option key={index} disabled={data.display == true ? false : true} value={data.accountId}>
                                        {data.accountTitle}
                                      </option>
                                    ))
                                }
                              })()}
                            </select>{" "}
                            &nbsp;
                            <button
                              className="btn mt-1 btnPaymentStatus-ov"
                            >
                              {data.paymentStatus ? data.paymentStatus : "Pending"}
                            </button>

                            <div
                              className="iconBox-ov"
                            >
                              <i
                                onMouseEnter={() =>
                                  handleMouseEnter(rowIndex, "btn1")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(rowIndex, "btn1")
                                }
                                class="fa-solid fa-note-sticky icon_ov1"
                                style={{
                                  color: "indigo",
                                  position: "relative",
                                }}
                                onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                              >
                                {isButtonHovered(rowIndex, "btn1") && (
                                  <div className="hover-text">Payment Details</div>
                                )}
                              </i>

                              <i
                                onMouseEnter={() =>
                                  handleMouseEnter(rowIndex, "btn2")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(rowIndex, "btn2")
                                }
                                onClick={() => handlePaymentPay(data.orderNo, data.ord1Id, orderList)}
                                class="fa-solid fa-sack-dollar icon_ov1"
                                style={{
                                  color: "#7c9504",
                                  position: "relative",
                                  // marginLeft: "20px",
                                }}
                              >
                                {isButtonHovered(rowIndex, "btn2") && (
                                  <div className="hover-text">Pay Online</div>
                                )}
                              </i>
                            </div>
                          </div>
                        </div>

                      </div>
                    </td>
                    <td className="tdStyle">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          height: "100px",
                        }}
                      >
                        <i onClick={() => openTrackMdl(data.ord1Id)}
                          onMouseEnter={() => handleMouseEnter(rowIndex, "btn3")}
                          onMouseLeave={() => handleMouseLeave(rowIndex, "btn3")}
                          class="fa-solid fa-truck-fast icon_ov1"
                          style={{ color: "black", position: "relative" }}
                        >
                          {isButtonHovered(rowIndex, "btn3") && (
                            <div className="hover-text">Track</div>
                          )}
                        </i>

                        <i
                          onMouseEnter={() => handleMouseEnter(rowIndex, "btn4")}
                          onMouseLeave={() => handleMouseLeave(rowIndex, "btn4")}
                          class="fa-brands fa-google icon_ov1"
                          style={{ color: "darkgoldenrod", position: "relative" }}
                          onClick={() => openGstModal(data.ord1Id)}
                        >
                          {isButtonHovered(rowIndex, "btn4") && (
                            <div
                              className="hover-text"
                              style={{ marginTop: "4%" }}
                            >
                              GST
                            </div>
                          )}
                        </i>
                        <div style={{ display: data.overallOrderStatus != "Pending" ? "block" : "none" }}>
                          <i
                            onClick={() => handlePrintButton(data.ord1Id)}
                            onMouseEnter={() =>
                              handleMouseEnter(rowIndex, "btn5")
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(rowIndex, "btn5")
                            }
                            class="fa-solid fa-receipt icon_ov1"
                            style={{ color: "darkred", position: "relative" }}
                          // onClick={}
                          >
                            {isButtonHovered(rowIndex, "btn5") && (
                              <div
                                className="hover-text"
                                style={{ marginTop: "6%" }}
                              >
                                Receipt
                              </div>
                            )}
                          </i>
                        </div>
                      </div>
                    </td>
                  </tr>
                ));
            } else {
              // alert("else")
            }
          })()}
          {/* ******************************** Total ******************************** */}
          {props.isShowTotalAmountInReport && (
            <tr className="table_tbody_Order_List"
              style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
              <td className="td_col6 td-padding-sn td_footer" colSpan={2} style={{ textAlign: "end" }}>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].grandTotalAmt
                  )}
                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].cancelAmt
                  )}
                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].grandTotalAmt - (summary[0].paidAmt + summary[0].cancelAmt)
                  )}
                </span>
                <br />
                <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                <span style={{ fontWeight: "500" }}>
                  {summary && summary.length > 0 && (
                    summary[0].paidAmt
                  )}
                </span>
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                <span style={{ fontWeight: "600" }}>Total Returned: </span>

                {summary && summary.length > 0 && (
                  summary[0].returnAmt
                )}
                <br />
                <span style={{ fontWeight: "600" }}>Total Return Request: </span>
                {summary && summary.length > 0 && (
                  summary[0].returnRequestQty
                )}
                <br />
              </td>
              <td className="td_col6 td-padding-sn td_footer" colSpan={4}></td>
            </tr>
          )}
        </tbody>
      </table>

      {GstModal && (
        <GstModalOrderView closeGstModal={closeGstModal} ItemDetail={ItemDetail} ord1Id={ord1Id} />
      )}
      {ItemDetailModal && (
        <ItemDetailModalBox
          closeItemDetailModal={closeItemDetailModal}
          ItemDetail={ItemDetail}
          setItemDetail={setItemDetail}
          serviceTypeId={props.serviceTypeId}
        />
      )}
      {/************************** Info Modal ***************/}
      {Modal && (
        <OrderDetailStatusModal
          closeModal={closeModal}
          ItemDetail={ItemDetail}
        />
      )}

      {/************************** Track order ***************/}
      {OpenTrackOrder && (
        <TrackOrderModel
          ItemDetail={ItemDetail}
          setItemDetail={setItemDetail}
          closeModal={openTrackclose}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
      {/************************** Payment Modal ***************/}
      {PaymentModal && (
        <PaymantModeModel handlePayment={handlePayment} PaymentModalData={PaymentModalData} closePaymentModal={closePaymentModal} />
      )}
      {/* ********************* Bill Print *************************** */}
      {
        showBillPrint && (
          <div style={{ display: "none" }}> {/**/}
            <div className="modal-back" onClick={closeBillPrint}>
              <div className="billPrintModal" style={{}}>
                <div style={{ padding: "5px", backgroundColor: "white" }}>
                  <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} />
                </div>
              </div>
            </div>
          </div>
        )
      }
      {loading && (<Loader />)}
    </div>
  );
};

export default HomeDelivery;
