import React, { useState } from "react";


function ProfessionalMaster(props) {

    const [tableData, setTableData] = useState([
        { id: 1 }
    ]);

    const handleAddRow = () => {
        const newRow = { id: tableData.length + 1 };
        console.log(newRow);
        setTableData([...tableData, newRow]);
    };
    const handleDeleteRow = (id) => {
        const updatedTableData = tableData.filter(row => row.id !== id);

        const updatedTableDataWithSn = updatedTableData.map((row, index) => ({
            ...row,
            id: index + 1
        }));

        setTableData(updatedTableDataWithSn);
    };

    //************************* Deduction *******************
    const [tableData1, setTableData1] = useState([
        { id: 1 }
    ]);

    const handleAddRow1 = () => {
        const newRow = { id: tableData1.length + 1 };
        setTableData1([...tableData1, newRow]);
    };

    const handleDeleteRow1 = (id) => {
        const updatedTableData = tableData1.filter(row => row.id !== id);
        const updatedTableDataWithSn = updatedTableData.map((row, index) => ({
            ...row,
            id: index + 1
        }));

        setTableData1(updatedTableDataWithSn);
    };

    return (
        <div>
            <div>
                <div className="panel-heading">
                    <div className="panel-head-title">
                        <h2 className="panel-title">Professional Master</h2>
                    </div>
                </div>
                <div className="content_box" style={{ display: "flex", boxShadow:"none" }}>
                    <div className="company-info-top-im">
                        <div className="tab-head">
                            <div id="tabs">
                                <div className="tab-content-box">
                                    <div className="tab-content-1">
                                        {/************************** Left Part ****************************/}
                                        <div className="company-info-top-left" >
                                            <div className="company_info-text-im" style={{ backgroundColor:"#b5bcce" }}>General Details</div>
                                            <div className="">
                                                <div className="space-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            {/*<input className="modify_im_input mode_category"*/}
                                                            {/*    type="text" required />*/}
                                                            <select className="modify_im_input mode_category" required>
                                                                <option>Select</option>
                                                            </select>
                                                            <label className="modify_p_text2">Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            {/*<input className="modify_im_input mode_category"*/}
                                                            {/*    type="text" required />*/}
                                                            <select className="modify_im_input mode_category" required>
                                                                <option>Select</option>
                                                            </select>
                                                            <label className="modify_p_text2">Grade Name</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="space-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            <input className="modify_im_input mode_category"
                                                                type="text" required />
                                                            <label className="modify_p_text2">Grade Pay</label>
                                                        </div>
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Increment</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="space-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            {/*<input className="modify_im_input mode_category"*/}
                                                            {/*    type="text" required />*/}
                                                            <select className="modify_im_input mode_category" required>
                                                                <option>True</option>
                                                                <option>False</option>
                                                            </select>
                                                            <label className="modify_p_text2">EPF Deduction</label>
                                                        </div>
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                {/*<input className="modify_im_input mode_category"*/}
                                                                {/*    type="text" required />*/}
                                                                <select className="modify_im_input mode_category" required>
                                                                    <option>Select</option>
                                                                    <option>Master</option>
                                                                </select>
                                                                <label className="modify_p_text2">DA From</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="space-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            <input className="modify_im_input mode_category"
                                                                type="text" required />
                                                            <label className="modify_p_text2">Pay Scale</label>
                                                        </div>
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Employee Id</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="space-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            <input className="modify_im_input mode_category"
                                                                type="text" required />
                                                            <label className="modify_p_text2">Total Salary</label>
                                                        </div>
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Basic Salary</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="spac-between">
                                                    <div className="input-box-Im ">
                                                        <div className="input-box-m2">
                                                            {/*<input className="modify_im_input mode_category"*/}
                                                            {/*    type="text" required />*/}
                                                            <select className="modify_im_input mode_category" required>
                                                                <option>True</option>
                                                                <option>False</option>
                                                            </select>
                                                            <label className="modify_p_text2">ESIC Deduction</label>
                                                        </div>

                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Fixed DA Amount</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        {/*************************** Right part*************************/}
                                        <div className="company-info-top-right">
                                            <div>
                                                <div className="company_info-text-im" style={{ backgroundColor:"#b5bcce" }}>Deduction</div>
                                                <div>
                                                    <div className="height25vh overflowY" style={{height:"29vh"}}>
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="th" style={{ backgroundColor:"#ebeef3" }}>S.No.</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Allowance Type</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Amount</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData1.map((row) => (
                                                                    <tr key={row.id}>
                                                                        <td className="td">{row.id}</td>
                                                                        <td className="td">
                                                                            <div>
                                                                                <select className="tableSelectBox">
                                                                                    <option>TDS</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="td">
                                                                            <input type="text" className="tableSelectBox" placeholder="Amount" />
                                                                        </td>
                                                                        <td className="td"><i className="fa-solid fa-trash trashIcon" onClick={() => handleDeleteRow1(row.id)}></i></td>
                                                                    </tr>
                                                                    //<tr >
                                                                    //    <td className="td">{row.id}</td>
                                                                    //    <td className="td">{row.AllowanceType}</td>
                                                                    //    <td className="td">{row.Amount}</td>
                                                                    //    <td className="td"><i className="fa-solid fa-trash"></i></td>
                                                                    //</tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex-center">
                                                        <button className="newRowBtn mt-2" onClick={handleAddRow1} style={{ backgroundColor: "darkgreen", }}>Add New Row</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*************************** Bottom ************************/}
                                <div className="company-info-top-right">
                                    <div className="company_info-text-im" style={{ backgroundColor:"#b5bcce" }}>Allowance</div>
                                    <div>
                                        <div className="height28vh overflowY" >
                                            {/*<label className="ques" style={{ textAlign: "center" }}>Allowance</label>*/}
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr >
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>S.No.</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Allowance Type</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Amount</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Amount Type</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((row) => (
                                                        <tr key={row.id}>
                                                            <td className="td">{row.id}</td>
                                                            <td className="td">
                                                                <div>
                                                                    <select className="tableSelectBox">
                                                                        <option>Select</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td className="td">
                                                                <input type="text" className="tableSelectBox" placeholder="Amount" />
                                                            </td>
                                                            <td className="td">
                                                                <select className="tableSelectBox">
                                                                    <option>Amount Type</option>
                                                                </select>
                                                            </td>
                                                            <td className="td"><i className="fa-solid fa-trash trashIcon" onClick={() => handleDeleteRow(row.id)}></i></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex-center">
                                            <button className="newRowBtn mt-2" onClick={handleAddRow} style={{ backgroundColor: "darkgreen" }}>Add New Row</button>
                                        </div>
                                    </div>

                                    <div className="flex-end">
                                        <div className="input-containerEffect mt-1">
                                            <input className="form-control inputEffect" type="text" id="" required />
                                            <label id="input1" className="labelEffect">Total Allowance</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "0px 10px" }}>
                                            <input className="form-control inputEffect" type="text" id="" required />
                                            <label id="input1" className="labelEffect">Total Deduction</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="buttom-box">
                                <div className="button-btn-1">
                                    <button className="btn-1">Save</button>
                                </div>
                                <div className="button-btn-2">
                                    <button className="btn-2">Reset</button>
                                </div>
                                <div className="button-btn-3">
                                    <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}


export default ProfessionalMaster;
