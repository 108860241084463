
import "../../css/resposive.css";
function NavBar() {
    return (
        <div className="panel-time">
            <div className="panel-type-top">
                <p className="panel-type-p">Receipt No.</p>
                <p className="panel-type-h">12<i className="fa-solid fa-angle-down ic-1"></i></p>
            </div>
            <div className="panel-type-top">
                <p className="panel-type-p">Date</p>
                <p className="panel-type-h">18/07/2023<i className="fa-solid fa-angle-down ic-1"></i></p>
            </div>
            <div className="panel-type-top">
                <p className="panel-type-p">Time</p>
                <p className="panel-type-h">12:40<i className="fa-solid fa-angle-down ic-1"></i></p>
            </div>
        </div>
    )
}

export default NavBar;