import React from "react";

const SideBarCategory = (props) => {
  return (
    <div onClick={props.function} className="sidebar-yesbm-bottom-option">
      <div>
        <img src={props.image} alt="" />
        <p>{props.heading}</p>
      </div>

      {props.ArrowChange ? (
      <i style={{marginTop:"-5px"}} className="fa-solid fa-sort-down"></i>
        ) : (
          <i className="fa-solid fa-caret-right"></i>
      )}
    </div>
  );
};

export default SideBarCategory;
