
import DateTime from "../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../Components/PageTabs";

function ModalEyeCheckUp() {
    /***********title***************/
    useState(() => {
        document.title = 'Eye Check Up';
    }, []);

    return (
        <div>
            <PageTabs />
            <div>
                < DateTime />
            </div>
            <div className="content_box">

                <div className="flex-center">
                    <label style={{ color: "darkblue", cursor: "pointer", fontWeight: "600" }}>Customer Name: Ramesh Kumar Sahu</label>
                </div>
                <hr />
                <div className="col-sm-12">
                    <div className="table-resposive" >
                        <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                            <thead textAlign="center" className="thead_scroll_orderlist table-tbody" >
                                <tr className="">
                                    <th className="td_col6"> <div className="table_head_mmb ">
                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Type</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Date</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Series</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Vouch No</div>
                                    </div> </th>
                                    <th className="td_col6"> <div className="table_head_OrderList">
                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Show</div>
                                    </div> </th>
                                </tr>
                            </thead>
                            <tbody className="tbody_body_m1">
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                    <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">2</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">3</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">4</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">5</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">6</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                                <tr className="table_tbody_Order_List">
                                    <td className="td_col6">7</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>14-06-2023</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>Sale</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>1</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>
                                        <button className="SaveBtn_Sms Show_Btn">Show</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="input-order-buttons">
                    <div className="input-remark-left01 input_RMK">
                        <div className="button-m5 btn_ord_pad">
                            <button className="search-button-main">Add
                                <i id="step-2" className="fa-solid fa-square-plus im-icon-2"></i>
                            </button>
                        </div>
                        <div className="button-m5 btn_ord_pad">
                            <button className="search-button-main">Cancel
                                <i id="step-2" className="fa-solid fa-regular fa-xmark im-icon-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModalEyeCheckUp;
