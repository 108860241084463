import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import img1 from "../../Assets/img/img1.jpg";
import { Link } from "react-router-dom";
import MainButton from "../../Props/MainButton";
import ItemDepartmentApi from "../../../API/APIServices/ItemDepartment";
import DataTable from '../TableMaster/DataTable';
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';
import Order from "../../../API/APIServices/Order";

function ServiceTypeMaster(props) {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Service Type Item Transfer Time';
    }, []);

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });
    const [inputValues, setInputValues] = useState({
        id: 751,
        name: '',
        printName: '',
        itemTransferTime: 0,
        advanceOrderDays: 0,
        noOfTimeSlot: 0,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        if (!isNaN(value) && value !== '') {
            if (name == "name") {
                const data = serviceTypes.filter(item => item.id == value)
                setInputValues((prevValues) => ({
                    ...prevValues,
                    id: value,
                    name: data[0].name,
                    printName: data[0].printName,
                }));
            }
            else {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                }));
            }
            setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        } else {
            alert('Please enter a valid number.');
        }
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            const isSuccess = await Order.updateServiceType(inputValues);
            fetchData();
            if (isSuccess) {
                fetchData();
                OpenAlertBoxEdit();
                setLoading(false);
            } else {
                alert("error while updating");
            }
            fetchData();
            setInputValues({
                id: 0,
                name: "",
                printName: "",
                itemTransferTime: 0,
                advanceOrderDays: 0,
                noOfTimeSlot: 0,
                isActive: true,
            });
            setLoading(false);
        }
    }

    // ******************************************* List Part ******************************
    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "ITEM TRANSFER TIME", accessor: "itemTransferTime" },
        { Header: "ADAVNCE ORDER DAYS", accessor: "advanceOrderDays" },
        { Header: "NO.OF TIME SLOT", accessor: "noOfTimeSlot" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "Name" },
        { Header: <input type="text" placeholder="Item Transfer Time" ref={input2} />, accessor: "ItemTransferTime" },
        { Header: <input type="text" placeholder="Advance Order Days" ref={input2} />, accessor: "advanceOrderDays" },
        { Header: <input type="text" placeholder="No of Time Slot" ref={input2} />, accessor: "noOfSlot" },
    ];

    const [serviceTypes, setServiceTypes] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await Order.getServiceType();
            console.log("value", val);

            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    name: item.name,
                    itemTransferTime: item?.itemTransferTime?.toString() + ` minutes`,
                    advanceOrderDays: item?.advanceOrderDays?.toString() + ` days`,
                    noOfTimeSlot: item?.noOfTimeSlot?.toString(),
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editServiceType(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            console.log("newData", newData)
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchServiceType = async () => {
        setLoading(true);
        try {
            const data = await Order.getServiceType();
            console.log("serviceData", data);
            setServiceTypes(data);
            setLoading(false);
            return data
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchServiceType();
        fetchData();
    }, []);

    const editServiceType = async (id) => {
        const data = await fetchServiceType();
        const dataById = data.filter(item => item.id == id);
        if (dataById) {
            try {
                const editData = await dataById[0];
                console.log("dataaaa" + editData);
                // setInputValues(editData);
                setInputValues({
                    id: editData.id,
                    name: editData.name,
                    printName: editData.printName,
                    itemTransferTime: editData.itemTransferTime,
                    advanceOrderDays: editData.advanceOrderDays,
                    noOfTimeSlot: editData.noOfTimeSlot,
                })
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        // if (!isNaN(event.target.value) && event.target.value !== '') {
        //     event.preventDefault();
        //     alert('Please enter a valid number.');
        // }
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    useEffect(() => {
        input1Ref.current.focus();
    }, []);

    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Service Type Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div style={{ height: "max-content" }} className="company-info-top-right control_flex_m1 item_scroll">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Service Type</div>
                                            <div className="company_control">
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select
                                                                className="modify_im_input mode_category"
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} value={inputValues.id} name="name" onChange={handleInputChange}
                                                                required>
                                                                <option value={0} disabled>--Select--</option>
                                                                {serviceTypes && serviceTypes.map((item, index) => (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                            <label className="modify_p_text2">Service Type
                                                                <span className="StarClr">*
                                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    {/* <div className="company_info-text-im">Controls Details</div> */}
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_limit mode_category1" type="number" onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input2Ref} value={inputValues.itemTransferTime} name="itemTransferTime" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Item Transfer Time (in minutes)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_limit mode_category1" type="number" onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input3Ref} value={inputValues.advanceOrderDays} name="advanceOrderDays" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Advance Order Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_limit mode_category1" type="number" onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input4Ref} value={inputValues.noOfTimeSlot} name="noOfTimeSlot" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">No of Time Slot</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Service Type Details</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Item Department is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Item Department is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default ServiceTypeMaster;
