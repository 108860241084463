import React, { useState, useRef, useEffect } from "react";
import Loader from '../../Inventory/Props/Loader';
import PageTabs from "../../Inventory/Components/PageTabs";
import DateTime from "../../Inventory/Components/DateTime";
import MainButton from "../../Inventory/Props/MainButton";
import Order from "../../API/APIServices/Order";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ItemMaster from "../../API/APIServices/ItemMaster";
import ItemGroup from "../../API/APIServices/ItemGroup";

const ItemIncentiveMaster = () => {
    useState(() => {
        document.title = 'Item Incentive Master';
    }, []);

    const [loading, setLoading] = useState(true);

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    }

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDate();
            setFromDate(date)
            setToDate(date)

            const filterData = [
                {
                    fieldName: "A46",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: date
                },
                {
                    fieldName: "A47",
                    operatorName: "LessOrEqualThan",
                    compareValue: date
                },
            ]

            const data = await Order.getAllItemIncentiveMaster(filterData);
            setCheckedData(data && data.map(item => item.itemId));
        }
        fetchData();
    }, [])

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // ***************** Get Item Master***********************
    const [itemName, setItemName] = useState();
    const [dataArray, setDataArray] = useState([]);
    const [itemGroup, setItemGroup] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemMaster.GetItemMaster();
                setItemName(data);
                setDataArray(data);
                console.log(data);
                const grpData = await ItemGroup.GetItemGroup();
                setItemGroup(grpData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const [addFilter, setAddFilter] = useState([]);

    const handleFilterChange = async (e) => {
        const { name, value } = e.target

        if (name == "fromDate") {
            setFromDate(value);
            const filterData = [
                {
                    fieldName: "A46",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: value
                },
                {
                    fieldName: "A47",
                    operatorName: "LessOrEqualThan",
                    compareValue: toDate
                },
            ]

            const data = await Order.getAllItemIncentiveMaster(filterData);
            setCheckedData(data && data.map(item => item.itemId));
            return true;
        }
        else if (name == "toDate") {
            setToDate(value);
            const filterData = [
                {
                    fieldName: "A46",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: fromDate
                },
                {
                    fieldName: "A47",
                    operatorName: "LessOrEqualThan",
                    compareValue: value
                },
            ]

            const data = await Order.getAllItemIncentiveMaster(filterData);
            setCheckedData(data && data.map(item => item.itemId));
            return true;
        }

        let updatedFilter = [...addFilter];
        let newFilter;

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            newFilter = {
                fieldName: name,
                operatorName: "Equal",
                compareValue: value,
            };

            const existingFilter = updatedFilter.find(filter => filter.fieldName === newFilter.fieldName);

            if (existingFilter) {
                const operatorExists = updatedFilter.some(
                    filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                );

                if (operatorExists) {
                    updatedFilter = updatedFilter.map(filter =>
                        filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                            ? { ...filter, compareValue: newFilter.compareValue }
                            : filter
                    );
                } else {
                    updatedFilter.push(newFilter);
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }

        console.log(updatedFilter);
        setAddFilter(updatedFilter);
        const data = await ItemMaster.GetItemMasterWithFilter(updatedFilter);
        setDataArray(data);
    };

    const [checkedData, setCheckedData] = useState([]);

    const handleCheckoxChange = (e, itemId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedData(prevData => [...prevData, itemId]);
        } else {
            setCheckedData(prevData => prevData.filter(id => id !== itemId));
        }
        console.log(checkedData);
    }

    const handleSelectAllCheckBox = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedData(dataArray.map(item => item.d2Id));
        } else {
            setCheckedData([]);
        }
    }

    const SaveButton = async () => {
        const filterData = checkedData.map(item => ({
            incentiveDateFrom: `${fromDate}T12:00:00`,
            incentiveDateTo: `${toDate}T12:00:00`,
            itemId: item,
        }));
        const data = await Order.createOrUpdateItemIncentiveMaster(filterData);
        if (data.isSuccess) {
            alert("Updated Successfully");
        }
        else {
            alert("Error Inserting data");
        }
    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Item Incentive Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={false} saveButtonHide={false} SaveButton={SaveButton} />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "96vw" }}>

                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Item Incentive Master</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">

                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="parentGrpId2"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemGroup !== null && Array.isArray(itemGroup)) {
                                                        return itemGroup.map((data) => (
                                                            <option key={data.d2_Id} value={data.d2_Id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Category Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange} name="d2Id" required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemName !== null && Array.isArray(itemName)) {
                                                        return itemName.map((data) => (
                                                            <option key={data.d2Id} value={data.d2Id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Item Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange} name="published" required>
                                                <option value="default"></option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Publish Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="date" required
                                                onChange={handleFilterChange} value={fromDate}
                                                name="fromDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Incentive From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required
                                                onChange={handleFilterChange} value={toDate}
                                                name="toDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Incentive To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ******************************************************** */}
                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">ITEM INCENTIVE MASTER</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        <div className="table-resposive" style={{ height: "500px", overflow: "scroll" }}> {/*tb-scroll-width */}
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <input type="checkbox" onChange={handleSelectAllCheckBox} />
                                                            <div className="table_head_mmb1" style={{ textAlign: "center", marginLeft: "10px" }}>Item Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Incentive</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray !== null && dataArray !== undefined && dataArray.length > 0 && dataArray
                                                        .map((data, index) => (
                                                            <tr key={index} className="table_tbody_Order_List">
                                                                <td className="td_col6 td_sno-btn feed-flex-edit">{index + 1}</td>
                                                                <td className="td_col6 td_sno-btn">
                                                                    <div className="flex-start">
                                                                        <input type="checkbox"
                                                                            checked={checkedData.includes(data.d2Id)}
                                                                            onChange={(e) => handleCheckoxChange(e, data.d2Id)} />
                                                                        <div className="table_head_mmb1" style={{ textAlign: "center", marginLeft: "10px" }}>{data.name}</div>
                                                                    </div>
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}></td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default ItemIncentiveMaster