import React, { useEffect, useState, useRef, useContext } from 'react';
import MyContext from '../../../Inventory/MyContext';
import throttle from 'lodash.throttle';
// import { useMyContext } from '../Components/MyContext';

const Square = (props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [clickVtLn, setClickVtLn] = useState(false);
  let id = props.keyValueId;
  const [position, setPosition] = useState({ x: 10, y: 20 });
  const [size, setSize] = useState({ width: 100, height: 100 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 });

  const { stylesArray, handleStyleChange, droppedItems, setDroppedItems, setTemplateData } = useContext(MyContext);

  const aspectRatio = useRef(size.width / size.height);

  const handleMouseDown = (e) => {
    if (e.button === 0) {

      e.preventDefault()
      const { clientX, clientY } = e;
      setIsDragging(true);
      setDragOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });
    } else if (e.button === 2) {
      e.preventDefault()

      const { clientX, clientY } = e;
      setIsResizing(true);
      setResizeOffset({
        x: clientX - position.x - size.width,
        y: clientY - position.y - size.height,
      });
    }
  };

  // const handleMouseMove = (e) => {
  //   if (isDragging) {
  //     const { clientX, clientY } = e;


  //     const newPositionX = clientX - dragOffset.x;
  //     const newPositionY = clientY - dragOffset.y;


  //     const maxX = window.innerWidth - size.width; // Adjust this as needed
  //     const maxY = window.innerHeight - size.height; // Adjust this as needed

  //     const constrainedPositionX = Math.min(Math.max(newPositionX, 0), maxX);
  //     const constrainedPositionY = Math.min(Math.max(newPositionY, 0), maxY);

  //     setPosition({
  //       x: constrainedPositionX,
  //       y: constrainedPositionY,
  //     });

  //     // Use handleStyleChange to update the position
  //     handleStyleChange("left", constrainedPositionX, "px", id);
  //     handleStyleChange("top", constrainedPositionY, "px", id);

  //   } else if (isResizing) {
  //     const { clientX, clientY } = e;
  //     // setSize({
  //     //   width: clientX - position.x - resizeOffset.x,
  //     //   height: clientY - position.y - resizeOffset.y,
  //     // });

  //     const newWidth = clientX - position.x - resizeOffset.x;
  //     const newHeight = newWidth / aspectRatio.current;

  //     // Constrain the resizing based on minimum width and height (if needed)
  //     const constrainedWidth = Math.max(newWidth, 50); // Minimum width: 50px
  //     const constrainedHeight = constrainedWidth / aspectRatio.current;

  //     setSize({
  //       width: constrainedWidth,
  //       height: constrainedHeight,
  //     });

  //     // Update width and height through context
  //     handleStyleChange("width", constrainedWidth, "px", id);
  //     handleStyleChange("height", constrainedHeight, "px", id);

  //   }
  // };


  // useEffect(() => {
  //   debugger
  //   const matchingItem = stylesArray.find(item => item.id === props.keyValueId);

  //   if (matchingItem) {
  //     const newPositionX = matchingItem.positionX || 0;  // Fetch initial X position
  //     const newPositionY = matchingItem.positionY || 0;  // Fetch initial Y position
  //     const newWidth = parseFloat(matchingItem.style?.width) || 100;  // Fetch initial width
  //     const newHeight = parseFloat(matchingItem.style?.height) || 100;  // Fetch initial height

  //     // Set the initial position and size for the element
  //     setPosition({ x: newPositionX, y: newPositionY });
  //     setSize({ width: newWidth, height: newHeight });
  //   }
  // }, [props.keyValueId, stylesArray]);  // Dependencies: keyValueId and droppedItems

  useEffect(() => {
    debugger
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);
    if (matchingStyle) {
      const newPositionX = parseFloat(matchingStyle.style.left) || 0;
      const newPositionY = parseFloat(matchingStyle.style.top) || 0;
      const newWidth = parseFloat(matchingStyle.style.width) || 50;
      const newHeight = parseFloat(matchingStyle.style.height) || 100;

      setPosition({ x: newPositionX, y: newPositionY });
      setSize({ width: newWidth, height: newHeight });
    }
  }, [props.keyValueId, stylesArray]);


  const handleMouseMove = throttle((e) => {
    if (isDragging) {
      const { clientX, clientY } = e;


      const newPositionX = clientX - dragOffset.x;
      const newPositionY = clientY - dragOffset.y;


      const maxX = window.innerWidth - size.width;
      const maxY = window.innerHeight - size.height;

      const constrainedPositionX = Math.min(Math.max(newPositionX, 0), maxX);
      const constrainedPositionY = Math.min(Math.max(newPositionY, 0), maxY);


      setPosition({
        x: constrainedPositionX,
        y: constrainedPositionY,
      });

    } else if (isResizing) {
      const { clientX } = e;


      const newWidth = clientX - position.x - resizeOffset.x;
      const newHeight = newWidth / aspectRatio.current;


      const constrainedWidth = Math.max(newWidth, 50);
      const constrainedHeight = constrainedWidth / aspectRatio.current;


      setSize({
        width: constrainedWidth,
        height: constrainedHeight,
      });
    }
  }, 16);


  // const handleMouseUp = () => {
  //   setIsDragging(false);
  //   setIsResizing(false);
  // };


  // const handleMouseUp = () => {
  //   // Stop dragging and resizing
  //   setIsDragging(false);
  //   setIsResizing(false);

  //   // Perform the style updates only after dragging or resizing ends
  //   requestAnimationFrame(() => {
  //     // Update the position styles after dragging
  //     handleStyleChange("left", position.x, "px", id);
  //     handleStyleChange("top", position.y, "px", id);

  //     // Update the size styles after resizing
  //     handleStyleChange("width", size.width, "px", id);
  //     handleStyleChange("height", size.height, "px", id);

  //     // Optionally, capture and log the outerHTML
  //     if (clickRef.current) {
  //       const updatedOuterHTML = clickRef.current.outerHTML;
  //       console.log('Updated outerHTML:', updatedOuterHTML);




  //       // Optional: Save the final position and size in the context or other state if necessary
  //       const updatedX = position.x;
  //       const updatedY = position.y;

  //       const prevItem = droppedItems.find(item => item.id === id);
  //       const prevX = prevItem ? prevItem.positionX : null;
  //       const prevY = prevItem ? prevItem.positionY : null;

  //       // Check if the position has changed to avoid unnecessary updates
  //       if (updatedX !== prevX || updatedY !== prevY) {
  //         console.log('Position changed, updating...');

  //         // Update droppedItems or any other state based on the new position
  //         setDroppedItems((prevItems) => {
  //           const updatedItems = prevItems.map((item) => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 textContents: updatedOuterHTML,
  //                 positionX: updatedX,
  //                 positionY: updatedY,
  //               };
  //             }
  //             return item;
  //           });


  //           setTemplateData((prevState) => ({
  //             ...prevState,
  //             jsonStructure: updatedItems,
  //           }));

  //           return updatedItems;
  //         });
  //       } else {
  //         console.log('Position not changed, no update needed.');
  //       }
  //     }
  //   });
  // };


  const handleMouseUp = () => {
    // Stop dragging and resizing
    setIsDragging(false);
    setIsResizing(false);

    // Extract updated position and size
    const updatedX = position.x;
    const updatedY = position.y;
    const updatedWidth = size.width;
    const updatedHeight = size.height;

    // Find the previous item to compare the current position and size
    const prevItem = droppedItems.find(item => item.id === id);
    const prevX = prevItem ? prevItem.positionX : null;
    const prevY = prevItem ? prevItem.positionY : null;
    const prevWidth = prevItem ? parseFloat(prevItem.style?.width) : null;
    const prevHeight = prevItem ? parseFloat(prevItem.style?.height) : null;

    // Determine if there are any changes in position or size
    const positionChanged = updatedX !== prevX || updatedY !== prevY;
    const sizeChanged = updatedWidth !== prevWidth || updatedHeight !== prevHeight;

    // Perform the style updates and state changes only if necessary
    if (positionChanged || sizeChanged) {
      requestAnimationFrame(() => {
        // Update styles only if position or size changed
        if (positionChanged) {
          handleStyleChange("left", updatedX, "px", id);
          handleStyleChange("top", updatedY, "px", id);
        }

        if (sizeChanged) {
          handleStyleChange("width", updatedWidth, "px", id);
          handleStyleChange("height", updatedHeight, "px", id);
        }

        // Optionally update outerHTML if position or size changed
        if (clickRef.current) {
          const updatedOuterHTML = clickRef.current.outerHTML;
          console.log('Updated outerHTML:', updatedOuterHTML);

          // Update droppedItems or any other state based on new position/size
          if (positionChanged || sizeChanged) {
            setDroppedItems((prevItems) => {
              const updatedItems = prevItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    textContents: updatedOuterHTML,
                    positionX: updatedX,
                    positionY: updatedY,
                    style: {
                      ...item.style,
                      width: updatedWidth + 'px',
                      height: updatedHeight + 'px',
                    },
                  };
                }
                return item;
              });

              // Update template data with new items
              setTemplateData((prevState) => ({
                ...prevState,
                jsonStructure: updatedItems,
              }));

              return updatedItems;
            });
          }
        }
      });
    } else {
      console.log('No position or size change detected, no updates needed.');
    }
  };

  const toggleBorder = () => {
    setClickVtLn(!clickVtLn);
  };



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (clickVtLn) {
        if (event.key === 'ArrowUp') {

          event.preventDefault();


          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - moveAmount,
          }));
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();


          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + moveAmount,
          }));
        } else if (event.key === 'ArrowLeft') {
          event.preventDefault();


          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x - moveAmount,
          }));
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();


          const moveAmount = event.ctrlKey ? 10 : 1;
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + moveAmount,
          }));
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickVtLn]);


  const clickRef = useRef(null);

  // Effect to add the global click event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        // Click occurred outside the component
        setClickVtLn(false);
      }
    };

    const handleEnterKey = (event) => {
      if (clickVtLn && event.key === 'Enter') {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // Set clickVtLn to false when Enter key is pressed
        setClickVtLn(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [clickRef, clickVtLn]);


  const deletebuttonshow = () => {

    // setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }

  };

  const handlecompclick = (id) => {
    debugger
    props.onComponentClick(id);
    deletebuttonshow();
    toggleBorder();
  };

  return (
    <div
      className={`draggable-resizable-component fieldbox-toolpanel ${clickVtLn ? 'bordered-web-template' : ''
        } `}
      ref={clickRef}
      id={props.keyValueId}
      style={{
        display: "flex", alignItems: "center",
        position: 'absolute',
        justifyContent: "center",
        // top: `${position.y / 5}%`,
        // left: `${position.x / 10}%`,
        left: position.x,
        top: position.y,
        width: size.width,
        // width: `${size.width / 8}%`,
        height: size.height,
        cursor: isDragging
          ? 'grabbing'
          : isResizing
            ? 'nwse-resize'
            : 'grab',
        // borderStyle:"solid",
        // borderWidth:"0px 0px 0px 1px",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      // onClick={(e) => {
      //   // getThItemValue(e, id);
      //   deletebuttonshow();
      //   toggleBorder();
      //   props.onComponentClick(props.keyValueId);
      // }}  
      onClick={() => handlecompclick(props.keyValueId)}
    >
      <div
        style={{ width: '99%', height: '95%', border: '1px solid black' }}
        onClick={toggleBorder}
      >

      </div>
      {/* <div className="verticalline-tool" onClick={(e)=>{getThItemValue(e,id)}}></div> */}
      <button
        className="component-delete-web-template"
        id={props.keyValueId + "delete"}
        onClick={() => props.handleRemoveItem(props.keyValueId)}
      >
        <i className="fa-solid fa-trash"></i>
      </button>

    </div>
  );
};

export default Square;
