import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Department = {
	
    insertDepartment: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Department/Department`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                departmentName: data.departmentName,
                inchargePerson: data.inchargePerson,
                contactNo: data.contactNo,
                address: data.address,
                emailId: data.emailId,
                isActive: data.isActive,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getDepartment: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Department/GetDepartments`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteDepartment: async (departmentId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Department?departmentId=${departmentId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getDepartmentById: async (departmentId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Department/Department?departmentId=${departmentId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            //setEditCallBack(response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateDepartment: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/Department?departmentId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                departmentName: data.departmentName,
                inchargePerson: data.inchargePerson,
                contactNo: data.contactNo,
                address: data.address,
                emailId: data.emailId,
                isActive: data.isActive,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default Department;
