import React, { useState } from 'react'
import GlobalSelector from './GlobalSelector';

const ExtraSection = (props) => {

  const [divList, setDivList] = useState([]);
  const [propertyValue, setpropertyValue] = useState("width");
  const [timingValue, setTimingValue] = useState("ease");
  const [durationValue, setDurationValue] = useState(0);
  const [durationUnit, setDurationUnit] = useState("s");
  const [rotateTypeValue, setRotateType] = useState("scaleY");
  const [transValue, setTransValue] = useState(0);
  const [transUnit, setTransUnit] = useState("deg");
  
  const styleName ={
    style1: "perspective",
  }
  const appendDiv = () => {
    setDivList(prevDivList => [...prevDivList, { id: prevDivList.length }]);
  };

  const deleteDiv = (id) => {
    setDivList(prevDivList => prevDivList.filter(item => item.id !== id));
  };
  const [divListsec, setDivListsec] = useState([]);

  const appendDivSec = () => {
    setDivListsec(prevDivListSec => [...prevDivListSec, { id: prevDivListSec.length }]);
  };

  const deleteDivSec = (id) => {
    setDivListsec(prevDivListSec => prevDivListSec.filter(item => item.id !== id));
  }
 
  const propertyState = (e) =>{
    setpropertyValue(e.target.value)
    transitionAllFunc()
    console.log(e.target.value)
  }

  const timingState = (e) =>{
    setTimingValue(e.target.value)
    transitionAllFunc()
    console.log(e.target.value)
  }
  const durationValueFunc = (e) =>{
    setDurationValue(e.target.value)
    transitionAllFunc()
    console.log(e.target.value)
  }
  const durationUnitFunc = (e) =>{
    setDurationUnit(e.target.value)
    transitionAllFunc()
    console.log(e.target.value)
  }
  const transitionAllFunc = () =>{
    let allValue = propertyValue + " " + durationValue + durationUnit + " " + timingValue  ;
    console.log(allValue)
    document.getElementById(props.id).style.transition = allValue;
  }

  const rotateType = (e) =>{
    setRotateType(e.target.value)
    transformAll()
  }

  const transformValue = (e) => {
    setTransValue(e.target.value)
    transformAll()
  }

  const transformUnit = (e) => {
    setTransUnit(e.target.value)
    transformAll()
  }

  const transformAll = () =>{
    let transAll = rotateTypeValue + "("+ transValue + transUnit + ")";
    console.log(transAll)
    document.getElementById(props.id).style.transform = transAll;
  }  

  return (
    <>

      <div className="Extracontainer">
        <div className="text-plus">
          <p className='general-paragraph vertical-justify'>Transition</p>
          <button className='typo-plus' onClick={appendDiv}><i className="icon-edit fa-solid fa-plus small"></i></button>
        </div>
        <div className="shadow-contain">
          {
            divList.map((div) => (

              <div className='firstcontent-container' key={div.id} >
                <div className="firstcontent">
                  <div className='firstcontent-paragicon'>
                    <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                    <p className='general-paragraph vertical-justify'>width 2s ease</p>
                  </div>
                  <div className='firstcontent-deleteicon'>
                    <i className="icon-edit fa-solid fa-t typoicon"></i>
                    <button onClick={() => deleteDiv(div.id)}><i className="icon-edit fa-solid fa-xmark typoicon" ></i></button>
                  </div>
                </div>

                <div className='secondcontent'>
                  <div className="decoration-sectionoption">
                    <p className="general-paragraph">Property</p>
                    <select name="display" className='selectorstyle' onChange={propertyState}>
                      <option value="all" >all</option>
                      <option value="width" selected>width</option>
                      <option value="height">height</option>
                      <option value="background-color">background-color</option>
                      <option value="transform">transform</option>
                      <option value="box-shadow">box-shadow</option>
                      <option value="opacity">opacity</option>


                    </select>
                  </div>
                  <div className="decoration-sectionoption">
                    <p className="general-paragraph">Timing</p>
                    <select name="display" className='selectorstyle' onChange={timingState}>
                      <option value="linear" >linear</option>
                      <option value="ease" selected>ease</option>
                      <option value="ease-in">ease-in</option>
                      <option value="ease-out">ease-out</option>
                      <option value="ease-in-out">ease-in-out</option>

                    </select>
                  </div>

                  <div className="globalselector-container">
                    <p className="general-paragraph" >Duration</p>
                    <input type="number" className='globalselector-input' placeholder="0" onChange={durationValueFunc}/>
                    <select name="" className='globalselector-select' onChange={durationUnitFunc}>

                      <option value="s">s</option>
                      <option value="ms">ms</option>

                    </select>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <GlobalSelector placeholder={"0"} name={"Perspective"}  id={props.id} style={styleName.style1}/>
        <div className="text-plus extra-margin">
          <p className='general-paragraph vertical-justify'>Transform</p>
          <button className='typo-plus' onClick={appendDivSec}><i className="icon-edit fa-solid fa-plus small"></i></button>
        </div>
        <div className="shadow-contain">
          {
            divListsec.map((div) => (

              <div className='firstcontent-container' key={div.id} >
                <div className="firstcontent">
                  <div className='firstcontent-paragicon'>
                    <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                    <p className='general-paragraph vertical-justify'>rotateZ 0deg</p>
                  </div>
                  <div className='firstcontent-deleteicon'>
                    <i className="icon-edit fa-solid fa-t typoicon"></i>
                    <button onClick={() => deleteDivSec(div.id)}><i className="icon-edit fa-solid fa-xmark typoicon" ></i></button>
                  </div>
                </div>

                <div className='secondcontent'>
                  <div className="decoration-sectionoption">
                    <p className="general-paragraph">Type</p>
                    <select name="display" className='selectorstyle' onChange={rotateType}>
                      <option value="ScaleX" >ScaleX</option>
                      <option value="ScaleY" selected>ScaleY</option>
                      <option value="ScaleZ">ScaleZ</option>
                      <option value="rotateX">rotateX</option>
                      <option value="rotateY">rotateY</option>
                      <option value="rotateZ">rotateZ</option>
                      <option value="translateX">translateX</option>
                      <option value="translateY">translateY</option>


                    </select>
                  </div><div className="globalselector-container">
                    <p className="general-paragraph" >Transform value</p>
                    <input type="number" className='globalselector-input' onChange={transformValue}/>
                    <select name="" className='globalselector-select' onChange={transformUnit}>

                      <option value="deg">deg</option>
                      <option value="rad">rad</option>
                      <option value="grad">grad</option>

                    </select>
                  </div>


                </div>
              </div>
            ))
          }
        </div>
      </div>



    </>
  );
}

export default ExtraSection