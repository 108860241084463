import React, { useEffect } from 'react';
//import UserApi from '../../API/APIServices/User';

function PageTabs(props) {

  const showModal = () => {
    console.log('Modal is shown!');
  };

  const openPage = (url) => {
    window.location.href = url;
  };
  const openPage1 = (url) => {
    window.location.href = url;
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey && event.key === 'i') {
        openPage('/ItemMaster');
      }
      if (event.altKey && event.key === 'a') {
        openPage('/AccountMaster');
      }
      if (event.altKey && event.key === 's') {
        openPage1('/SaleInvoice');
        const shouldNavigate = window.confirm('Do you want to navigate to the new page?');
        if (shouldNavigate) {
        }
      }
      if (event.altKey && event.key === 'c') {
        openPage('/ModifyCompany1');
      }
      if (event.key === 'F2') {
        openPage(props.locationTab);
      }
      if (event.key === 'Escape') {
        console.log(props.joyride);
        if (props.joyride === true) {
          props.stopJoyride();
          console.log("if")
        }
        else if (props.joyride === undefined) {
          console.log("else if")
          
        }
        // else if(props.showModal){
        //   handleModalClick();
        //   console.log("else if")
        // }
        else {
          window.history.back();
          console.log("else")
        }
        console.log(props.modelOpen)
        if (Boolean(props.modelOpen) === true) {
          //alert("props.isModalOpenAttribute   " + props.isModalOpenAttribute)

          if (props.isModalOpenAttribute === true) {
            props.showAttribute(false);
          }
          else {
            props.setSliderCallBack(false);
          }
        }
        else if (props.modal === true) {
          props.modalClose();
        }
        else {
          window.history.back();
        }
      }
      if (event.key === 'F8') {
        window.location.reload();
      }
      if (event.key === 'F9') {
        props.showModal();
      }
      // if (event.key === 'F10') {
      //   event.preventDefault();
      // }
      // console.log(props.locationTab);
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if(event.altKey && event.key === 'm'){
  //      window.confirm('Do you want to navigate to the new page?');
  //       if (event.altKey && event.key === 's') {
  //         openPage1('/SaleInvoice'); // Replace with the URL of the page you want to open
  //       }
  //     }
  //   };
  //   window.addEventListener('keydown', handleKeyPress);
  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  return (
    <div></div>
  );
}

export default PageTabs;

