import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
  WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Status = {

  GetStatus: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Status/GetStatus`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  InsertStatus: async (data) => {
    debugger
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/Status`;
      const response = await axios.post(
        apiUrl,
        {
          name: data.name,
          sendSMS: parseBoolean(data.sendSMS),
          email: parseBoolean(data.email),
          whatsappMessage: parseBoolean(data.whatsappMessage),
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetStatusById: async (StatusId) => {
    try {
      console.log("get by id");
      const apiUrl = `${WebApiUrl}/api/Status?StatusId=${StatusId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${WebApiUrl}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  deleteStatus: async (StatusId) => {
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/Status?StatusId=${StatusId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateStatus: async (data) => {

    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }

  const sendSMS1 = parseBoolean(data.sendSMS)
  const email1 = parseBoolean(data.email)
  const whatsappMessage1 = parseBoolean(data.whatsappMessage)

console.log(sendSMS1)
console.log(email1)
console.log(whatsappMessage1)


  debugger
    console.log(data);
    try {
      const apiUrl = `${WebApiUrl}/api/Status?StatusId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          name: data.name,
          sendSMS: sendSMS1,
          email: email1,
          whatsappMessage: whatsappMessage1,
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
};

export default Status;
