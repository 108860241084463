
import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";

function MemberCard() {
    /***********title***************/
    useState(() => {
        document.title = 'Vehicle Master';
    }, []);

    ////****************{Image add}**********************//


    //************modal box************/ /
    const [, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };


    /***********attribute code*****************/

    //*********************{Button Script}*********************//
    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

    const [isHovered1, setHovered1] = useState(false);
    const handleMouseEnter1 = () => {
        setHovered1(true);
    };
    const handleMouseLeave1 = () => {
        setHovered1(false);
    };
    const [isHovered3, setHovered3] = useState(false);
    const handleMouseEnter3 = () => {
        setHovered3(true);
    };
    const handleMouseLeave3 = () => {
        setHovered3(false);
    };
    const handleBack = () => {
        // Add your logic to go back or navigate to the previous page
        // You might want to use react-router's history or window.history.back()
        window.history.back();
    };

    return (
        <div>
            < PageTabs />
            <div>
            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Member Card Details</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                
                <div className="panel-head-button">
                    <div className="col_1">
                      
                       
                    <div className="button-m5">
                            <button id="SaveBtnMain"
                                className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                Save
                                <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                {isHovered && (
                                    <div className="hover-textSave">
                                        <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                        Save/F10
                                    </div>
                                )}
                            </button>
                        </div>

                        <div className="button-m5">
                            <button className={`save-button-main ${isHovered1 ? 'hovered' : ''}`}
                                onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                                {isHovered1 && (
                                    <div className="hover-textSave">
                                        <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                        Reset/F8
                                    </div>
                                )}
                            </button>
                        </div>
                        <div onClick={handleBack} className="button-m5">
                            <button className={`save-button-main ${isHovered3 ? 'hovered' : ''}`}
                                onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                                    <i id="step-2" className="fa-solid fa-arrow-left-long im-IconRight" onClick={handleBack}></i>
                                    Back
                                {isHovered3 && (
                                    <div className="hover-textSave">
                                     
                                        <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                        Back/ESC
                                    </div>
                                )}
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Member Card Details</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Membership Card Id</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>Individual User</option>
                                                                <option>Group User</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Card User By</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>1 Star</option>
                                                                <option>2 Star</option>
                                                                <option>3 Star</option>

                                                            </select>
                                                            <label className="modify_lbl_text3">Card Type</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Card Owner</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="date" required />
                                                            <label className="modify_lbl_text3">Registration Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="date" required />
                                                            <label className="modify_lbl_text3">Card Expire Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="number" required />
                                                            <label className="modify_p_text2">Initial Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="number" required />
                                                            <label className="modify_p_text2">Total Amount</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="company_control">
                                            <div className="stock-im-dep1">
                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" type="text" required>
                                                            <option>Only Card Owner</option>
                                                            <option>Associate Member</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Redemption Amount By</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stock-im-dep1">
                                                <div className="input-box-Imo1 input-height2">
                                                    <div className="input-box-mok1">
                                                        <label style={{ color: "#5e5e5e", fontWeight: "600" }} className="modify_p_text2">Associate Card member</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default MemberCard;
