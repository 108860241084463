import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";

function Salt() {
    /***********title***************/
    useState(() => {
        document.title = 'Salt';
    }, []);

    ////************Image add******************///

    //******************{modal box}******************/ /
    const [ setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };
   

    /**************{attribute code}********************/

    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

 /**************{More Information Modal}********************/
    const [isModalOpenMoreInfo, setIsModalOpenMoreInfo] = useState(false);

  const handleCheckboxChangeMore = () => {
    setIsModalOpenMoreInfo(!isModalOpenMoreInfo);
  };


    return (
        <div>
            < PageTabs />
            <div>

            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Salt</h2>
                </div >

                {/* ********************************{Buttob}********************************* */}
                <div className="panel-head-button">
                    <div className="col_1">
                        <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Save
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="button-m5">
                            <button className="save-button-main">Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>

                    </div>
                </div>

                
            </div> 
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">

                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Salt Details</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            {/* <label className="modify_lbl_text3">Shop</label> */}
                                                            <label className="modify_p_text2">Salt Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1 Flex-Start-Moreinfo">
                                                        <input type="checkbox" className="check_box_order"  onChange={handleCheckboxChangeMore} checked={isModalOpenMoreInfo} name="" id="" />
                                                            <div className="More_info_check">More Info</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                              
                                                {/* <div className="stock-im-dep1">
                                                
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label className="modify_p_text2">More Info</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        {isModalOpenMoreInfo && (
                                        <div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">More information</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Indication</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Dosage</label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            {/* <label className="modify_p_text2">Dosage</label> */}
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Note</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>Normal</option>
                                                                <option>No</option>
                                                            </select>
                                                            <label className="modify_p_text2">TB Item</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="stock-im-dep1">
                                                    {/* <div className="company_info-text-im">Controls Details</div> */}
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Special Precautions</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                            <label className="modify_p_text-fix">Address</label>
                                                        </div>
                                                    </div> */}
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Side Effects</label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            {/* <label className="modify_p_text2">Dosage</label> */}
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" required />
                                                            <label className="modify_p_text2">Drug Interactions</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Narcotic Details</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label className="modify_p_text2">Narcotic</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label className="modify_p_text2">Schedule H1</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label className="modify_p_text2">Prohibited</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    {/* <div className="company_info-text-im">Controls Details</div> */}
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label className="modify_p_text2">Schedule H</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required >
                                                                <option>Active</option>
                                                                <option>Inactive</option>
                                                            </select>
                                                            <label className="modify_p_text2">Status</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default Salt;