import React, { useState, useEffect, useContext, useRef } from 'react'
import MyContext from '../../../Inventory/MyContext'

const TextArea = (props) => {

  //   const deletebuttonshow = () => {
  //   document.getElementById("textarea-delete").classList.toggle("column-one-delete-web-template");
  //   document.getElementById("textarea").classList.toggle("element-border");
  // };

  const { setDroppedItems, setTemplateData, handleContentChange, stylesArray, droppedItems } = useContext(MyContext);

  const [getValueAndStrg, setGetValueAndStrg] = useState([])
  const [textAreaValue, setTextAreaValue] = useState("");
  const divRef = useRef(null);

  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  const SaveTextAreaValue = (e) => {
    //localStorage.setItem("TextAreaData", e.target.value);
    setTextAreaValue(e.target.value)
  }
  useEffect(() => {
    //setTextAreaValue(localStorage.getItem("TextAreaData"))
  }, [textAreaValue])


  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;

  useEffect(() => {
    debugger;
    const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

    if (matchingStyle) {
      setTextAreaValue(matchingStyle.content || '');
    } else {
      // setTextValue('');
    }
  }, [props.keyValueId, stylesArray]);

  const getParentIdFromChildId = (keyValueId, droppedItems) => {
    for (const item of droppedItems) {
        for (const child of item.JSONChildComponents) {
            if (child.id === keyValueId) {
                return child.parentJSONid;
            }
        }
    }
    return null; // Return null if the keyValueId is not found
};

  const fetchInnerHtmlOfDroppedComponent = (componentId) => {
    console.log(componentId);
    const getRemainingData = (str) => {
        if (str.endsWith("CHILD")) {
            return str.replace("CHILD", "");
        } else if (str.endsWith("CHILD1")) {
            return str.replace("CHILD1", "");
        }
        return str;
    };

    var newID = getRemainingData(componentId)

    if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
        const componentElement = document.getElementById(newID.toString() + "innerHTML");
        if (componentElement) {
            const innerHTML = componentElement.outerHTML;
            console.log(innerHTML);

            // Update the corresponding item with the inner HTML
            setDroppedItems(prevItems => {
                const updatedItems = prevItems.map(item => {
                    if (item.id === newID) {
                        return { ...item, textContents: innerHTML };
                    }
                    return item;
                });

                // Update the template data with the new content
                setTemplateData(prevState => ({
                    ...prevState,
                    jsonStructure: updatedItems,
                }));

                return updatedItems;
            });
        }
        return
    }
    // Find the component element using its ID
    const componentElement = document.getElementById(componentId.toString());

    console.log(componentElement);
    if (componentElement) {
        // Get the inner HTML
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        setDroppedItems(prevItems => {
            const updatedItems = prevItems.map(item => {
                if (item.id === componentId) {
                    return { ...item, textContents: innerHTML };
                }
                return item;
            });

            setTemplateData(prevState => ({
                ...prevState,
                jsonStructure: updatedItems,
            }));

            return updatedItems;
        });
    }
};

  const handleBlur = () => {
    setTimeout(() => {
      if (divRef.current) {
        console.log(divRef.current);
        const updatedInnerHTML = divRef.current.innerHTML;
        console.log('Updated innerHTML:', updatedInnerHTML);
          const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
          if (parentId != null) {
            fetchInnerHtmlOfDroppedComponent(parentId)
          }

        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === props.keyValueId) {
              return { ...item, textContents: updatedInnerHTML };
            }
            return item;
          });
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));
          return updatedItems;
        });
      } else {
        console.error('divRef.current is null');
      }
    }, 1000);
  };

  return (
    <div style={{ position: "relative" }}
      onClick={deletebuttonshow}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" id={getId}
      onBlur={handleBlur}
      ref={divRef}>
      <textarea className="textarea-main-web-template" name="" cols="40" rows="4" id={getId} onChange={(e) => SaveTextAreaValue(e)} value={textAreaValue}></textarea>
    </div>
  )
}

export default TextArea