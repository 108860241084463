import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";

function UserActivity() {
    return (
        <div className="" >
            < PageTabs />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">User Activity</h2>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left_useract">
                                <div className="col-sm-n1">
                                    <div className="group_head">
                                        <select className="bill_panel_input mode_category" type="text" required>
                                            <option>Transaction</option>
                                            <option>Master</option>
                                        </select>
                                        <label className="modify_lbl_text3">Firm Name</label>
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date From" type="date" className="date_panel_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="Date To" type="date" className="date_panel_input" />
                                    </div>
                                    <div className="group_head">
                                        <input placeholder="User" type="search" className="searchbar_panel_input" />
                                    </div>
                                    <div className="group_head">
                                        <svg className="search_panel_icon svl_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                        <input placeholder="Search" type="search" className="searchbar_panel_input" />
                                    </div>
                                </div>
                            </div>
                            <div className="search_panel_right_useract">
                                <div className="search_panel_f1" >
                                    <div className="button-mp1">
                                        <button className="table_search_btn">
                                            <i className="fa-solid fa-magnifying-glass im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp2" >
                                        <button className="table_reset_btn">
                                            <i className="fa-solid fa-rotate im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp4">
                                        <button className="xls_btn">
                                            <i className="fa-solid fa-file-excel im-icon-6"></i>
                                        </button>
                                    </div>
                                    <div className="button-mp5">
                                        <button className="Print_btn">
                                            <i className="fa-solid fa-print im-icon-6"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Type</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Series</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Voucher No</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Date</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Party Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Bill Amt</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Creation Date</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5 user_activity_panel"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">User</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">2</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">3</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">4</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">5</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">6</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">7</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>20-10-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>SALE</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>407</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raaj</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>2500</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>25-11-2023 </td>
                                            <td className="td_col5" style={{ textalign: "start" }}>admin</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>

        </div >


    );
}

export default UserActivity;
