import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css'
import ImageUploadWithPreview from "./../../Components/ImagePreview"
import MainButton from "../../Props/MainButton";

function LensRangePriceMaster() {
    //******************* {title} ******************//
    useState(() => {
        document.title = 'Lens Range Price Master';
    }, []);

    // {/* ******************* {show hide} ****************** */}

    // {/* ********************* {modal box} *********************/}
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    };
    // {/* *******************modal box****************** */}
    const [isModalOpen3, setModalOpen3] = useState(true);
    const hideModal3 = () => {
        setModalOpen3(false);
    };
    // {/* *******************Category****************** */}


    // ************************* {Lens} **************************//
    const [rows2, setRows2] = useState(Array(10).fill({}));
    const handleInputChange13 = (rowIndex, e) => {
        // Handle input changes and update the corresponding row data
        const { name, value } = e.target;
        const updatedRows2 = [...rows2];
        updatedRows2[rowIndex][name] = value;
        setRows2(updatedRows2);
    };

    const handleDeleteRow2 = (rowIndex) => {
        // Create a copy of the rows1 array without the row to be deleted
        const updatedRows = rows2.filter((_, index) => index !== rowIndex);

        // Update the state with the modified rows array
        setRows2(updatedRows);
    }
    const handleImageUpload = (rowIndex, event) => {
        const file = event.target.files[0]; // Get the selected file
        const updatedRows = [...rows2]; // Create a copy of the rows array

        // Update the specific row's image property with the selected file
        updatedRows[rowIndex].image = file;

        // Update the state with the modified rows array
        setRows2(updatedRows);
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Lens Range Price Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton isBtnSetting={true} />

                {/* *******************Modal Box Open****************** */}
                <div>

                    {isModalOpen && (
                        <div className="modal" >
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Item Setting</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im ">
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
            {isModalOpen3 && (

                <div className="panel-bulk-upload">
                    <div className="bulk-upload-m1">
                        <div className="button-bulk-1">
                            <p className="bulk-upload-p">Bulk Upload Items from a Purchase Bill Photo or from Excel</p>
                        </div>
                        <div className="button-bulk-2">
                            <button className="item_upload">
                                Item Upload
                                <i className="fa-solid fa-file-export im-icon-6"></i>
                            </button>
                        </div>
                        <div>
                            <span className="close-btn" onClick={hideModal3}>
                                &times;
                            </span>
                        </div>
                    </div>

                </div>
            )}

            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                        <div className="input-box-checkbox1 ">
                                                        <div className=" td-2">
                                                            <div className="cl-toggle-switch td-3">
                                                                <label className="cl-switch">
                                                                    <input type="checkbox"  />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im">
                                                <div className="input-box-m1 input-height">
                                                    <input name="" className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Title Name</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" type="text" placeholder="" required>
                                                        <option>--Select--</option>
                                                        <option>Unit 1</option>
                                                        <option>Unit 2</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Lens Group</label>
                                                </div>

                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" type="text" placeholder="" required>
                                                        <option>--Select--</option>
                                                        <option>Bag</option>
                                                        <option>BAG BAGS</option>
                                                        <option>BALE BALE</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">Lens Name</label>
                                                </div>

                                            </div>

                                            <div className="input-box-Im radio-size-m1">
                                                <label className="radio-button-label-m1">Vision Type</label>
                                                <div className="radio-btm-m1">
                                                    <input
                                                        className="radio-btn-n1" type="radio"
                                                        id="html" name="visionType"
                                                        value="Single Vision"
                                                    />
                                                    <label className="radio-btn-n2" for="html">
                                                        Single Vision
                                                    </label>
                                                    <input
                                                        className="radio-btn-n1" type="radio"
                                                        id="css" name="visionType"
                                                        value="Bifocal/Progressive"
                                                    />
                                                    <label className="radio-btn-n2" for="css">
                                                        Bifocal/Progressive
                                                    </label>
                                                    <input
                                                        className="radio-btn-n1" type="radio"
                                                        id="javascript" name="visionType"
                                                        value="Zero Power"
                                                    />
                                                    <label className="radio-btn-n2" for="javascript">
                                                        Zero Power
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im radio-size-m1">
                                                <label className="radio-button-label-m1">Lens Type</label>
                                                <div className="radio-btm-m2">
                                                    <input
                                                        className="radio-btn-n1" type="radio"
                                                        id="Single" name="lensType"
                                                        value="Single Vision"
                                                    />
                                                    <label className="radio-btn-n2" for="Single">
                                                        Ready Lens
                                                    </label>
                                                    <input
                                                        className="radio-btn-n1" type="radio"
                                                        id="Progresive" name="lensType"
                                                        value="Bifocal/Progressive"
                                                    />
                                                    <label className="radio-btn-n2" for="Progresive">
                                                        Rx Lens
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="table_head_mmbb1">Left EYE</div>
                                            <div className="input-box-SI-2 ">
                                                <div className="input-box-m6">
                                                    <div className="row mt-2 PriceMarket">
                                                        <label className="colLensRange ">SPH</label>
                                                        <label className="colLensRange ">CYL</label>
                                                        <label className="colLensRange ">AXIS</label>
                                                        <label className="colLensRange ">ADD</label>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m6">
                                                    <div className="row mt-2b mj-m1">
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table_head_mmbb1">Right EYE</div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m6">
                                                    <div className="row mt-2 mj-m1">
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input style={{ marginRight: "5px" }} className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />
                                                        <input className="col-sm-3-w1 table-input-w1 box-size1" type="text" required />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im">
                                                <div className="input-box-m1 input-height">
                                                    <input name="" className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Price</label>
                                                </div>

                                                <div className="input-box-m1 input-height ">
                                                    <input name="" className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text10">Group Name</label>
                                                </div>

                                            </div>




                                            <div className="input-box-Im">
                                                <div className=" upi-payment-main input-height">
                                                    <input name="" className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Video Link</label>
                                                </div>

                                            </div>
                                            <div className="">
                                                <div>
                                                    <table className="table-scrol1">
                                                        <thead className="table-tbody">
                                                            <tr className="table-tr1">
                                                                <th className="table-th-1 TabCOlorWhite">SN</th>
                                                                <th className=" td-col3 TabCOlorWhite">Future</th>
                                                                <th className="table-th-2 TabCOlorWhite">Price</th>
                                                                <th className="table-th-2 TabCOlorWhite">Group</th>
                                                                <th className="table-th-2 TabCOlorWhite">Remark</th>
                                                                <th className="table-th-2 TabCOlorWhite">Image</th>
                                                                <th className="table-th-2 TabCOlorWhite">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className=" table-container-tbody" >
                                                            {rows2.map((row, rowIndex) => (
                                                                <tr className="table-tr1" key={rowIndex} >
                                                                    <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                        {rowIndex + 1}
                                                                    </td>
                                                                    <td className="table-td-3">
                                                                        <input
                                                                            className="table-input-13 input-box-td1"
                                                                            type="text"
                                                                            name={`Future-${rowIndex}`}
                                                                            value={row[`Future-${rowIndex}`] || ""}
                                                                            onChange={(e) => handleInputChange13(rowIndex, e)}
                                                                        />
                                                                    </td>
                                                                    <td className="table-td-2">
                                                                        <input
                                                                            className="table-input-13 input-box-td2"
                                                                            type="text" name={`Price-${rowIndex}`}
                                                                            value={row[`Price-${rowIndex}`] || ""}
                                                                            onChange={(e) => handleInputChange13(rowIndex, e)} />
                                                                    </td>
                                                                    <td className="table-td-2">
                                                                        <input
                                                                            className="table-input-13 input-box-td2"
                                                                            type="text" name={`Group-${rowIndex}`}
                                                                            value={row[`Group-${rowIndex}`] || ""}
                                                                            onChange={(e) => handleInputChange13(rowIndex, e)} />
                                                                    </td>
                                                                    <td className="table-td-2">
                                                                        <input
                                                                            className="table-input-13 input-box-td2"
                                                                            type="text" name={`Remark-${rowIndex}`}
                                                                            value={row[`Remark-${rowIndex}`] || ""}
                                                                            onChange={(e) => handleInputChange13(rowIndex, e)} />
                                                                    </td>
                                                                    <td className="table-td-2 img-bm" style={{ textAlign: "center" }}>
                                                                        <input
                                                                            className="img-bm" type="file" accept="image/*"
                                                                            onChange={(e) => handleImageUpload(rowIndex, e)} />
                                                                    </td>
                                                                    <td className="table-td-2" style={{ textAlign: "center" }}>
                                                                        <button className="btn-table1" onClick={() => handleDeleteRow2(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        <div className="stock-im-2">
                                            <div className="company_info-text-im hbn1">Search Filter</div>
                                            <div className="input-box-iml hbn2">
                                                <input className="modify_im_input mode_category form-control" type="search" id="txtsearch" required />
                                                <label className="modify_label_text2">Search By Name</label>
                                            </div>
                                            <div className="search-box-btn-1">
                                                <button className="search-btn-m1">Search</button>

                                            </div>
                                        </div>
                                            <div className="company_info-text-im">Image Details</div>
                                            <div className="stock-im-1">
                                                <div className="input-box-Im1">
                                                    <p className="modify_p_2"></p>
                                                    <div className="radio-buttons-container1 m2 overstack">
                                                        < ImageUploadWithPreview id={"item11"} />
                                                    </div>
                                                </div>
                                            </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}


export default LensRangePriceMaster;
