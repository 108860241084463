import React, { useState} from "react";

function EwayBillSetting(props) {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div>
            <div className="EWayDiv">
                <div className="space-between mt-2">
                    <div className="flex-start">
                        <input type="checkbox" />
                        <label className="ques">&nbsp; Enable E-Way Bill</label>
                    </div>
                    <div className="flex-start">
                        <input type="checkbox" />
                        <label className="ques">&nbsp; E-Way Bill SendBox</label>
                    </div>
                    <div className="flex-start">
                        <input type="checkbox" />
                        <label className="ques">&nbsp; Enable E-Invoicing</label>
                    </div>
                    <div className="flex-start">
                        <input type="checkbox" />
                        <label className="ques">&nbsp; Enable SandBox</label>
                    </div>
                </div>
                {/********************************* Left Part ***********************************/}
                <div className="space-evenly mt-3">
                    <div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Material Dispatch From &nbsp;</label>
                            <div className="col-sm-6 flex">
                                <input type="text" />
                                <i className="fa-solid fa-info icon" onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}></i>
                                {isHovered && <div className="hover-text" style={{}}>Home Address where Firm/Godown is located.</div>}
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Pin Code &nbsp;</label>
                            <div className="col-sm-6">
                                <input type="text" />
                                <i className="fa-solid fa-info icon" onMouseEnter={() => setIsHovered1(true)}
                                    onMouseLeave={() => setIsHovered1(false)}></i>
                                {isHovered1 && <div className="hover-text" style={{}}></div>}
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Inter State Bill Amount &nbsp;</label>
                            <div className="col-sm-6">
                                <input type="text" />
                                <i className="fa-solid fa-info icon" onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}></i>
                                {isHovered2 && <div className="hover-text" style={{}}></div>}
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Allow Intra(Same) State E-Way Bill &nbsp;</label>
                            <div className="col-sm-6">
                                <input type="text" />
                                <i className="fa-solid fa-info icon"></i>
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Intra State Bill Amount &nbsp;</label>
                            <div className="col-sm-6">
                                <input type="text" />
                                <i className="fa-solid fa-info icon"></i>
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Intra State Distance to Applicable E-Way Bill &nbsp;</label>
                            <div className="col-sm-6">
                                <input type="text" />
                                <i className="fa-solid fa-info icon"></i>
                            </div>
                        </div>
                        <div className="flex-end">
                            <button className="save_btn">Save</button>
                        </div>
                    </div>
                    {/************************* Right Part **********************************/}
                    <div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Firm GSTIN No. &nbsp;</label>
                            <div className="col-sm-6 flex">
                                <input type="text" />
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">GSP User Name &nbsp;</label>
                            <div className="col-sm-6 flex">
                                <input type="text" className="marginTop-1px" />
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">GSP Password &nbsp;</label>
                            <div className="col-sm-6 flex">
                                <input type="text" className="marginTop-1px" />
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">GST User Name &nbsp;</label>
                            <div className="col-sm-6 flex marginTop-1px">
                                <input type="text" />
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">GST Password &nbsp;</label>
                            <div className="col-sm-6 flex ">
                                <input type="text" className="marginTop-1px" />
                                {/*<i className="fa-solid fa-eye icon"></i>*/}
                            </div>
                        </div>
                        <div className="flex-start row">
                            <label className="ans col-sm-6">Enable E-Invoicing in series &nbsp;</label>
                            <div className="col-sm-6 flex">
                                <input type="text" className="marginTop-1px" />
                            </div>
                        </div>
                        <div className="flex-end" style={{ width: "100%" }} >
                            <button className="save_btn mt-3" >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EwayBillSetting;
