
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";
import MyContext from "../../Inventory/MyContext";
import ItemDepartment from "../../API/APIServices/ItemDepartment";
import FeedbackItemWiseModal from '../../Inventory/ModalBox/FeedbackItemWiseModal'

function FeedbackItemDepartmentWiseReport() {

    useState(() => {
        document.title = 'Feedback Item Department Wise Report';
    }, []);

    const { isShowTotalAmountInReport } = useContext(MyContext);
    const [loading, setLoading] = useState(true);

    // ***************** Get Assign user from user master ***********************
    const [assignUser, setAssignUser] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserCredential.getUserCredential();
                setAssignUser(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const [orderTaker, setOrderTaker] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const filteredData = [{
                    fieldName: "userRole",
                    operatorName: "Equal",
                    compareValue: 563  //OrderTaker
                }];
                const data = await UserCredential.getFilteredUserCredential(filteredData);
                setOrderTaker(data);
                console.log(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    // ***************** Item Department ******************
    const [itemDepartment, setItemDepartment] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemDepartment.getItemDepartment();
                setItemDepartment(data);
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        fetchData();
    }, [])

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // **************Get Order List************************
    const [dataArray, setDataArray] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const val = await Order.getFeedbackReport();
                console.log(val);
                setDataArray(val);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addFilter, setFilter] = useState([]);

    const handleFilterChange = async (event) => {
        setLoading(true);
        const { name, value } = event.target;

        let updatedFilter = [...addFilter];

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            let newFilter;
            if (name === "fromDate" || name === "toDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                // if (name === "fromDate") {
                //     setFromDate(formattedValue)
                // }
                // else if (name === "toDate") {
                //     setToDate(formattedValue)
                // }
            }
            else if (name == "accMobileNo" || name == "accName") {
                newFilter = {
                    fieldName: name,
                    operatorName: "Contains",
                    compareValue: value
                };
            }
            else if (name === "feedBackList") {
                if (value === "feedbackDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "Equal",
                        compareValue: true
                    };
                }
                else if (value === "feedbackNotDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "NotEqual",
                        compareValue: true
                    };
                }
            }
            else if (name === "feedbackFromDate" || name === "feedbackToDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "feedbackUpdateOn",
                    operatorName: name === "feedbackFromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                // if (name === "fromDate") {
                //     setFromDate(formattedValue)
                // }
                // else if (name === "toDate") {
                //     setToDate(formattedValue)
                // }
            }
            else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
            }
            const orderDateFilters = updatedFilter.filter(filter => filter.fieldName === name);
            const existingFilterWithSameOperator = updatedFilter.find(filter => filter.operatorName === newFilter.operatorName);

            if (existingFilterWithSameOperator) {
                const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilterWithSameOperator);
                if (indexToUpdate !== -1) {
                    updatedFilter[indexToUpdate] = {
                        ...updatedFilter[indexToUpdate],
                        compareValue: newFilter.compareValue
                    };
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
	//const val = await Order.getOrderListFilter(updatedFilter);
        const val = await Order.getFeedbackReportFilter(updatedFilter);
        setDataArray(val);
        setLoading(false);
    };

    const [reportByItemWise, setReportByItemWise] = useState(false);
    const [d2ItemId, setD2ItemId] = useState(0);

    const handleModalOpen = (itemId) => {
        setD2ItemId(itemId);
        setReportByItemWise(true);
    }

    const closeModal = () => {
        setReportByItemWise(false);
    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Feedback Item Department Wise Report</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={false} saveButtonHide={true} /> {/*handleSearchClick={handleSearchClick} */}
            </div>
            <div><DateTime /></div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "91vw" }}>
                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Feedback Item Department Wise Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemDepartment !== null && Array.isArray(itemDepartment)) {
                                                        return itemDepartment.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Item Department</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input7Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }} required>
                                                <option value="default">N/A</option>
                                                <option>1</option>
                                                {/* <option>1.5</option> */}
                                                <option>2</option>
                                                {/* <option>2.5</option> */}
                                                <option>3</option>
                                                {/* <option>3.5</option> */}
                                                <option>4</option>
                                                {/* <option>4.5</option> */}
                                                <option>5</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Rating Star</label>
                                        </div>
                                        {/* <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                onChange={handleFilterChange} name="feedBackList" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }} required>
                                                <option value="default">All</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Confirm</label>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="date" required
                                                onChange={handleFilterChange}
                                                name="fromDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input6Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="date" required
                                                onChange={handleFilterChange}
                                                name="toDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>

                                        {/* <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input8Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input9Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.confirmUser}
                                                onChange={handleFilterChange}
                                                name="orderConfirmedById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (assignUser !== null && Array.isArray(assignUser)) {
                                                        return assignUser.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Confirmed By</label>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-feedback-left01 input_copy_stock feedback-date">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "23%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input9Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input10Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.feedbackUser}
                                                onChange={handleFilterChange}
                                                name=""
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (orderTaker !== null && Array.isArray(orderTaker)) {
                                                        return orderTaker.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Taker</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">Feedback Details</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        <div className="table-resposive tb-scroll-width" style={{ height: "500px", overflow: "scroll" }}>
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Department</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Feedback</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Product Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Product Amt</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>No of Person Rated</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>No of Orders Qty</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray && dataArray != null && dataArray != undefined && dataArray.length > 0 && dataArray
                                                        // .slice().reverse()
                                                        .map((item, index) => (
                                                            <tr key={index} className="table_tbody_Order_List">
                                                                <td className="td_col6 td_sno-btn ">
                                                                    <button className="" onClick={() => handleModalOpen(item.itemId)} style={{ padding: "5px 15px", backgroundColor: "rgba(185, 185, 234, 0.5)", borderRadius: "5px" }}>{index + 1}</button>
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemDepartmentName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemRating}<span style={{ color: "darkorange" }}>&#9733;</span></td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.itemPrice}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.noOfPersonRating}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.noOfOrdersQty}</td>
                                                            </tr>
                                                        ))}

                                                    {isShowTotalAmountInReport && (
                                                        <tr className="table_tbody_Order_List"
                                                            style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Total :- </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            .filter((item, index, self) =>
                                                                                index === self.findIndex((t) => t.ord1Id === item.ord1Id)
                                                                            )
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = data.grandTotal || 0;
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;

                                                                    return (
                                                                        <span>
                                                                            ₹ {sum.toFixed(2)}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/*********************** Feedback By Item wise Modal ********************/}
                                {reportByItemWise && (
                                    <div className="modal" >
                                        <div className="AddNewItem-modal modal-payment" style={{ minHeight: "95%", width: "90%" }}>
                                            <div className="">
                                                <h3 className="modal-OrderLIst modal-color-CAD">Item Order List</h3>
                                                <span className="close_modal" onClick={closeModal}>
                                                    &times;
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="ModalContentModalSettingP modal-overflow-feedback" style={{ padding: "0px" }}>
                                                <FeedbackItemWiseModal itemId={d2ItemId} />
                                            </div>
                                            <div className="mt-2" style={{ display: "flex", justifyContent: "end", marginRight: "10px" }} >
                                                <button className="redBtn" style={{ width: "17%" }} onClick={closeModal} > Close</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default FeedbackItemDepartmentWiseReport;