import React, { useEffect, useState } from "react";
import Account from "../../API/APIServices/Account";
import Order from "../../API/APIServices/Order";
import Loader from "../../Inventory/Props/Loader";

const GstModalOrderView = (props) => {
  const { closeGstModal, ItemDetail, ord1Id } = props;

  const [loading, setLoading] = useState(false)

  const [accountDetails, setAccountDetails] = useState({
    name: "",
    mobileNo: "",
    companyGSTNo: "",
    companyDetails: "",
    address: ""
  });
  console.log(ItemDetail, ord1Id);

  const updateAccount = async () => {
    setLoading(true);
    console.log(accountDetails);

    const updatedData = [
      {
        operationType: 0,
        path: "CompanyGSTNo",
        op: "Add",
        from: "string",
        value: accountDetails.companyGSTNo
      },
      {
        operationType: 0,
        path: "CompanyDetails",
        op: "Add",
        from: "string",
        value: accountDetails.companyDetails
      },
      {
        operationType: 0,
        path: "Name",
        op: "Add",
        from: "string",
        value: accountDetails.name
      }
    ]
    await Order.updateOrderPartially(updatedData, ord1Id);
    // await Account.updateAccountPartially(
    //   updatedData,
    //   accountDetails.d1Id,
    //   accountDetails.d2Id
    // );
    setLoading(false);
    alert("Updated Successfully");
  };

  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      try {
        // const data = await Account.getAccountById(ItemDetail[0].d1AccId);
        const filteredData = {
          fieldName: "ord1Id",
          operatorName: "equal",
          compareValue: ord1Id
        }

        const data = await Order.getOrderListByOrd1Id(ord1Id)
        console.log(data);

        setAccountDetails({
          name: data[0].name,
          mobileNo: data[0].mobileNo,
          companyGSTNo: data[0].companyGSTNo,
          companyDetails: data[0].companyDetails,
          address: data[0].accAddress
        });
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
      setLoading(false);
    };

    if (ItemDetail && ItemDetail.length > 0) {
      fetchdata();
    }
  }, [ItemDetail]);

  const handleGstInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);
    setAccountDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content5">
        <div className="modal-title">
          <h3 className="modal-h" style={{ color: "black" }}>Gst Details</h3>
          <span className="close" onClick={closeGstModal}>
            &times;
          </span>
        </div>
        <hr style={{ padding: "0px", margin: "1px" }} />
        {accountDetails === undefined ? (
          <div className="modal-content-im">
            <div className="table_main_01">
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Order No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <h6 className=" mode_category">{ItemDetail[0].orderNo}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Mobile No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        className="modify_im_input mode_category"
                        type="text"
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Address :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <textarea
                        className="modify_im_input mode_category"
                        type="text"
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Name :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="name"
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Company GST No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="gStINNo"
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Company Detail :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="accountDetails"
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-head-button1">
              <div className="col_4">
                <div className="button-m1">
                  <button id="step-5" className="greenBtn">
                    Save
                    <i className="fa-regular fa-floppy-disk im-icon-5"></i>
                  </button>
                </div>
                <div className="button-m2">
                  <button className="redBtn" onClick={closeGstModal}>
                    Back
                    <i class="fa-solid fa-right-from-bracket im-icon-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-content-im">
            <div className="table_main_01">
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Order No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <h6 className=" mode_category">{ItemDetail[0].orderNo}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Mobile No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        className="modify_im_input mode_category"
                        value={
                          accountDetails.mobileNo !== undefined
                            ? accountDetails.mobileNo
                            : ""
                        }
                        type="text"
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Address :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <textarea
                        className="modify_im_input mode_category"
                        type="text"
                        value={
                          accountDetails.address !== undefined
                            ? accountDetails.address
                            : ""
                        }
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Name :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="name"
                        value={
                          accountDetails.name !== undefined
                            ? accountDetails.name
                            : ""
                        }
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Company GST No :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="companyGSTNo"
                        // value={accountDetails.gStINNo}
                        value={
                          accountDetails.companyGSTNo !== undefined
                            ? accountDetails.companyGSTNo
                            : ""
                        }
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-box-Im_ov ">
                <div className="ov_modal_content">
                  <div className="ov_left_side">
                    <h6 class="">Company Detail :</h6>
                  </div>
                  <div className="ov_right_side">
                    <div className="input-box-m2">
                      <input
                        type="text"
                        className="modify_im_input mode_category"
                        name="companyDetails"
                        value={
                          accountDetails.companyDetails !== undefined
                            ? accountDetails.companyDetails
                            : ""
                        }
                        onChange={handleGstInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-head-button1">
              <div className="col_4">
                <div className="button-m1">
                  <button
                    id="step-5"
                    className="greenBtn"
                    onClick={updateAccount}
                  >
                    Save
                    <i className="fa-regular fa-floppy-disk im-icon-5" style={{ color: "white" }}></i>
                  </button>
                </div>
                <div className="button-m2">
                  <button className="redBtn" onClick={closeGstModal}>
                    Back
                    <i class="fa-solid fa-right-from-bracket im-icon-1" style={{ color: "white" }}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default GstModalOrderView;
