import React, { useState } from "react";

const AlertBoxError = (props) => {
  const [Button1] = useState(props.singleButton);

  return (
    <div className="modal-back" onClick={props.OpenAlertBox}>
      <div className="alert-card-error" >
        <div className="content">
          <span className="alert-title-error"><i className="fa-solid fa-triangle-exclamation" style={{ marginBottom: "10px", marginLeft: "3px", fontSize: "15px" }}></i> {props.HeadingTop} </span>
          <div className="desc-error">{props.Heading}</div>

          {Button1 ? (
            <div style={{ textAlign: "center" }} className="action">
              <div onClick={props.onYesClick} className="download-error" >
               Close
              </div>
            </div>
          ) : (
            <div className="actions">
              <div>
                <div onClick={props.onYesClick} className="download-error">
                  Yes
                </div>
              </div>
              <div>
                <div onClick={props.OpenAlertBox} className="notnow">
                  Not now
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          className="alert-close-error"
          type="button"
          onClick={props.OpenAlertBox}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AlertBoxError;
