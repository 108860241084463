//import { useState } from "react";

const Payment = () => {
   
    return (
        <div>
            
            <div className="scroll-container" style={{ height: "627px", backgroundColor: "white", padding:"10px 10px" }}>
                <div style={{backgroundColor: "white" }}>
                    <label className="mt-2" style={{ fontWeight: "700", fontSize: "18px", color: "#3d470a", padding: "10px"}}>Payment</label>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label style={{fontSize:"18px"} }>Bill No:123</label>
                    <label style={{fontSize:"18px"} }>Payment Amount: &#x20B9;255</label>
                </div>
                <div className="mt-2" style={{ display: "flex", backgroundColor: "#f2f2f2", border: "1px solid darkgrey" }}>
                    <div className="paymentDiv" style={{}}>
                        <i className="fa-solid fa-money-check-dollar payemntIcon"></i>
                        <label>Card</label>
                    </div>
                    <div className="paymentDiv" style={{}}>
                        <i className="fa-solid fa-wallet payemntIcon"></i>
                        <label>Wallet</label>
                    </div>
                    <div className="paymentDiv" style={{}}>
                        <i className="fa-solid fa-coins payemntIcon" ></i>
                        <label>Other</label>
                    </div>
                    <div className="paymentDiv" style={{}}>
                        <i className="fa-solid fa-stopwatch payemntIcon" ></i>
                        <label>Due Payment</label>
                    </div>
                </div>
                <div style={{ backgroundColor: "#f2f2f2", padding: "10px", border: "1px solid black" }}>
                    <label style={{ fontWeight: "700" }}>Captured Amount</label>
                    <div className="mt-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <label className="mt-2" style={{ fontWeight:"600" }}>Enter Amount</label>
                        <input type="text" className="form-control modify_im_input" style={{ width: "15%", marginLeft: "10px",height:"40px",padding:"7px" }} />
                        <button className="OrderNow-btn" style={{ backgroundColor:"#054205", width: "5%", marginLeft: "10px" }}>Save</button>
                    </div>
                </div>
                <div className="mt-4">
                    <div style={{ backgroundColor: "#5fdd2511", border: "1px solid black", padding: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label className="fw-600">Payment Summary</label>
                            <label className="fw-600">Rs 255</label>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#5fdd2511", border: "1px solid black", borderTop: "none", padding: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label className="fw-600">Pay via cash</label>
                            <label className="fw-600">Rs 255</label>
                        </div>
                    </div>
                </div>
                <div className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="white-btn" style={{marginRight:"10px", width:"10%"} }>Back to Order</button>
                    <button className="OrderNow-btn" style={{marginRight:"10px", width:"10%"} }>New Order</button>
                    <button className="OrderNow-btn" style={{marginRight:"10px", width:"10%"} }>Print</button>
                    <button className="OrderNow-btn" style={{marginRight:"10px", width:"10%"} }>EBill</button>
                </div>
            </div>
        </div>
    );
};

export default Payment;
