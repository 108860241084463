import React, { useState } from 'react'
import InputBox from './InputBox'

const VirtualTrySetting = () => {

    const [inputValues, setInputValues] = useState({
        virtualTryURL: '',
        companyIdForVirtualTry: '',
    })
    console.log(inputValues)

    return (
        <div>
            <div className='heading-setting'>Virtual Try Setting</div>
            <div className='grid-container'>
                {/* {fields.map((field, index) => (
                    <div key={index} className='grid-item-container'>
                        <InputBox
                            label={field}
                            onChange={(e) => handleChange(field.toLowerCase().replace(/ /g, ""), e.target.value)}
                            value={inputValues[field.toLowerCase().replace(/ /g, "")] || ''}
                        />
                    </div>
                ))} */}
                <div className='grid-item-container'>
                    <InputBox
                        label={"Virtual Try URL"}
                        value={inputValues.virtualTryURL}
                        onChange={(newvalue) => setInputValues({ ...inputValues, virtualTryURL: newvalue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Company Id For Virtual Try"}
                        value={inputValues.companyIdForVirtualTry}
                        onChange={(newvalue) => setInputValues({ ...inputValues, companyIdForVirtualTry: newvalue })}
                    />
                </div>

                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default VirtualTrySetting
