import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css'
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";


function PatientMaster() {
    /**********************{title}*************************/
    useState(() => {
        document.title = 'Patient Master';
    }, []);

    //*****************{Image add}***********************//

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    //********************{editer}***************************//

    const [isInputVisiblemc1, setInputVisiblemc1] = useState(false);
    const handleToggleChangemc1 = () => {
        setInputVisiblemc1(!isInputVisiblemc1);
    };
    const [isInputVisiblemc2, setInputVisiblemc2] = useState(false);
    const handleToggleChangemc2 = () => {
        setInputVisiblemc2(!isInputVisiblemc2);
    };
    const [isInputVisiblemc3, setInputVisiblemc3] = useState(false);
    const handleToggleChangemc3 = () => {
        setInputVisiblemc3(!isInputVisiblemc3);
    };

    //*********************{modal box}*********************/ /
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    };

    //*********************{modal box End}*********************//
    //*********************{Button Script}*********************//

    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Patient Master</h2>
                </div >
                
                 {/* *******************Top Buttons****************** */}
                 <MainButton />
                 <div className="panel-head-button">
                    <div className="col_1">
                        <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Save
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="button-m5">
                            <button className="save-button-main">Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>
                        
                        
                        <div onClick={showModal} className="button-m5">
                            <button className="save-button-main">Setting
                                <i id="step-2" className="fa-solid fa-gear im-icon-2" onClick={showModal}></i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* *******************Modal Box Open****************** */}
                <div>
                    {isModalOpen && (
                        <div className="modal" >
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Setting</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangemc1} checked={isInputVisiblemc1} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Additional Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangemc2} checked={isInputVisiblemc2} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">VAT/GST Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangemc3} checked={isInputVisiblemc3} />
                                                    <span></span>
                                                </label>
                                                <div className="ModalLabeltext2">UPI Payment Details<i className="fa-solid fa-circle-info ml-10"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
           
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im">Patient Info</div>
                                        <div className="">
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">PID</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="date" required />
                                                    <label className="modify_lbl_text4">Reg. Date</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Patient Name</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text2">Guard Name</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                            <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="date" required />
                                                    <label className="modify_lbl_text4">DOB</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                        <option>Male</option>
                                                        <option>FeMale</option>
                                                        <option>Other</option>
                                                    </select>
                                                    <label className="modify_lbl_text2">Gender</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="date" required />
                                                    <label className="modify_p_text2">Adhar No.</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="date" required />
                                                    <label className="modify_p_text2">Blood Group</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" type="text" required>
                                                        <option className=" s-1 form-control" value="">Select Country</option>
                                                        <option className=" s-1 form-control" value="India">India</option>
                                                        <option className=" s-1 form-control" value="Afghanistan">Afghanistan</option>
                                                        <option className=" s-1 form-control" value="Åland Islands">Åland Islands</option>
                                                        <option className=" s-1 form-control" value="Albania">Albania</option>
                                                        <option className=" s-1 form-control" value="Algeria">Algeria</option>
                                                    </select>
                                                </div>
                                                <div className="input-box-m1">
                                                    <label className="modify_lbl_text3">Country</label>
                                                    <select className="modify_im_input mode_limit mode_category" type="text" required>
                                                        <option Classname="form-control s-1" value="">Select state</option>
                                                        <option Classname="form-control s-1" value="AN">Andaman and Nicobar Islands</option>
                                                        <option Classname="form-control s-1" value="AP">Andhra Pradesh</option>
                                                        <option Classname="form-control s-1" value="AR">Arunachal Pradesh</option>
                                                        <option Classname="form-control s-1" value="AS">Assam</option>
                                                        <option Classname="form-control s-1" value="BR">Bihar</option>
                                                        <option Classname="form-control s-1" value="CH">Chandigarh</option>
                                                        <option Classname="form-control s-1" value="CT">Chhattisgarh</option>
                                                        <option Classname="form-control s-1" value="DN">Dadra and Nagar Haveli</option>
                                                        <option Classname="form-control s-1" value="DD">Daman and Diu</option>
                                                        <option Classname="form-control s-1" value="DL">Delhi</option>
                                                        <option Classname="form-control s-1" value="GA">Goa</option>
                                                        <option Classname="form-control s-1" value="GJ">Gujarat</option>
                                                        <option Classname="form-control s-1" value="HR">Haryana</option>
                                                        <option Classname="form-control s-1" value="HP">Himachal Pradesh</option>
                                                        <option Classname="form-control s-1" value="JK">Jammu and Kashmir</option>
                                                        <option Classname="form-control s-1" value="JH">Jharkhand</option>
                                                        <option Classname="form-control s-1" value="KA">Karnataka</option>
                                                        <option Classname="form-control s-1" value="KL">Kerala</option>
                                                        <option Classname="form-control s-1" value="LA">Ladakh</option>
                                                        <option Classname="form-control s-1" value="LD">Lakshadweep</option>
                                                        <option Classname="form-control s-1" value="MP">Madhya Pradesh</option>
                                                        <option Classname="form-control s-1" value="MH">Maharashtra</option>
                                                        <option Classname="form-control s-1" value="MN">Manipur</option>
                                                        <option Classname="form-control s-1" value="ML">Meghalaya</option>
                                                        <option Classname="form-control s-1" value="MZ">Mizoram</option>
                                                        <option Classname="form-control s-1" value="NL">Nagaland</option>
                                                        <option Classname="form-control s-1" value="OR">Odisha</option>
                                                        <option Classname="form-control s-1" value="PY">Puducherry</option>
                                                        <option Classname="form-control s-1" value="PB">Punjab</option>
                                                        <option Classname="form-control s-1" value="RJ">Rajasthan</option>
                                                        <option Classname="form-control s-1" value="SK">Sikkim</option>
                                                        <option Classname="form-control s-1" value="TN">Tamil Nadu</option>
                                                        <option Classname="form-control s-1" value="TG">Telangana</option>
                                                        <option Classname="form-control s-1" value="TR">Tripura</option>
                                                        <option Classname="form-control s-1" value="UP">Uttar Pradesh</option>
                                                        <option Classname="form-control s-1" value="UT">Uttarakhand</option>
                                                        <option Classname="form-control s-1" value="WB">West Bengal</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">State</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" type="text" required>
                                                        <option>Optical Store</option>
                                                        <option>General Store</option>
                                                        <option>Resto</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Business Types</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="date" required />
                                                    <label className="modify_lbl_text4">Books Commencing From</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" type="text" required>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Enable Online Store</label>
                                                </div>
                                                
                                            </div>
                                            
                                            {isInputVisiblemc1 && (
                                                <div>
                                                    <div className="company_info-text-im">Additional Details
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Currency Symbol</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Currency String</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Currency Unit</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Currency Decimal Places</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Day Interval For Backup</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Ecom Min. Shop Limit</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {isInputVisiblemc3 && (
                                                <div>
                                                    <div className="company_info-text-im">UPI Payment Details</div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Payee Name</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">UPI Id</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Transfer Msg</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Currency Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">Trans Vouch No</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Pay Amt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        <div className="company_info-text-im">Contact Details</div>
                                        <div className="stock-im-1">
                                            
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Tel. No.</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text2">E-Mail</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <label className="modify_p_text2">Fax No.</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                    <label className="modify_lbl_text2">IT PAN</label>
                                                </div>
                                            </div>
                                            <div className="input-box-tab-head">
                                                <div className="input-box-m2">
                                                    <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                    <label className="modify_p_text-fix">Address</label>
                                                </div>
                                            </div>
                                        </div>
                                        {isInputVisiblemc2 && (
                                            <div>
                                                <div className="company_info-text-im">VAT/GST Details</div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" type="text" required>
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Enable VAT/GST</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" type="text" required>
                                                            <option>VAT</option>
                                                            <option>GST</option>
                                                        </select>
                                                        <label className="modify_lbl_text4">Enable VAT/GST</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" type="text" required>
                                                            <option>Yes</option>
                                                            <option>No</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Enable Add. Tax/Schg.</label>
                                                    </div>
                                                    <div className="input-box-m1 m-logo ">
                                                        <div className="modify-input-box-part-3">
                                                            <label className="modify_lbl_text2">Set Logo</label>
                                                        </div>
                                                        <div className="modify-input-box-part-1">
                                                            <input
                                                                className="modify_im_input mode_limit mode_category col-1"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="modify-input-box-part-2">
                                                            {selectedImage && <img src={selectedImage} alt="Selected" className="modify-img-logo" />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" type="text" required />
                                                        <label className="modify_p_text2">Enable Add. Tax/Schg.</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                        <label className="modify_lbl_text2">Fax</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" type="text" required />
                                                        <label className="modify_p_text2">GST TIN</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                        <label className="modify_lbl_text2">CST No.</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-tab-head">
                                                    <div className="input-box-m2">
                                                        <textarea className="modify_im_textarea mode_category2" type="text" placeholder="" required />
                                                        <label className="modify_p_text-fix">Terms & Condition</label>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    );
}


export default PatientMaster;
