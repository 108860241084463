import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "../OrderTaker/OrderTakerStylesheet.css";
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import GlobalFunction from "../../API/APIServices/GlobalFunction";
import html2pdf from 'html2pdf.js';
import Order from "../../API/APIServices/Order";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
//import { WebView } from 'react-native-webview';

function MultipleKotPrintPage(props) {

    const navigate = useNavigate();

    const backButton = () => {
        navigate(-1);
    };

    const location = useLocation();

    let ord2Id, isOnloadPrint, orderListData, setShowPrintModal;

    if (location.state && location.state.orderNo) {
        ({ ord2Id, isOnloadPrint, orderListData, setShowPrintModal } = location.state);
    } else {
        ({ ord2Id, isOnloadPrint, orderListData, setShowPrintModal } = props);
    }

    console.log("ord2Id", ord2Id)

    const [orderData, setOrderData] = useState([]);
    const [serviceTypeId, setServiceTypeId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                // const filteredData = {
                //     fieldName: "ord2Id",
                //     operatorName: "Equal",
                //     compareValue: ord2Id
                // }
                // const data = await Order.getRawOrderList(filteredData, 0);
                // setOrderData(data);
                // if (data.length > 0) {
                //     setServiceTypeId(data[0].serviceTypeId)
                // }
                setOrderData(orderListData)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching banner images:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [])

    const [loading, setLoading] = useState(true);

    const [companyData, setCompanyData] = useState({});

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId);
                setCompanyData(data || {}); // If data is undefined, set an empty object
                console.log("dataaaa", data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const isoString = await CurrentDateAndTime.getCurrentDateTime();
            const currentDate = new Date(isoString);

            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const date = currentDate.getDate();
            let hours = currentDate.getHours();
            const minutes = currentDate.getMinutes();
            const seconds = currentDate.getSeconds();

            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            hours = hours % 12 || 12;

            const formattedHours = hours < 10 ? '0' + hours : hours;
            const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
            const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

            const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amOrPm}`;

            const formattedDate = `${date}-${month}-${year}`;

            setCurrentDate(formattedDate);
            setCurrentTime(formattedTime);
        }
        fetchData();
    }, [])

    const webViewRef = useRef(null);

    const printDocument = () => {
        //GlobalFunction.generatePDF("displayBlock", "pdf-content");
        // Execute JavaScript code in the WebView to trigger printing
        //webViewRef.current.injectJavaScript('window.print();');
    };

    const generatePDF = async () => {
        setLoading(true);
        const element = document.getElementById("pdf_content");

        if (!element) {
            console.log('Element with id "pdf_content" not found.');
            return;
        }
        const opt = {
            margin: 0,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
        };

        try {
            const pdfDataUri = await html2pdf()
                .from(element)
                .set(opt)
                .toPdf()
                .output('datauristring');

            const pdfBase64 = pdfDataUri.split(',')[1];

            let file;
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(pdfBase64);
            } else {
                // Create a blob from the base64 string
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                file = new File([blob], 'myfile.pdf', {
                    type: 'application/pdf',
                    lastModified: Date.now()
                });

                console.log('File metadata:', {
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    webkitRelativePath: file.webkitRelativePath
                });

                setShowPrintModal(false);

                const pdfUrl = URL.createObjectURL(blob);
                const newWindow = window.open(pdfUrl);
                newWindow.onload = function () {
                    newWindow.print();
                    newWindow.onafterprint = function () {
                        newWindow.close();
                    };
                };
                setHasExecuted(false);
            }
            setLoading(false)
        } catch (error) {
            console.log('Error generating PDF:', error);
            setLoading(false)
        }
    };

    function formatDate(inputDate) {
        // Split the inputDate string into year, month, and day components
        const parts = inputDate.split('-');

        // Create a Date object with the components in yyyy-mm-dd format
        const dateObject = new Date(parts[0], parts[1] - 1, parts[2]);

        // Extract day, month, and year from the Date object
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = dateObject.getFullYear();

        // Return the formatted date string in dd-mm-yyyy format
        return `${day}-${month}-${year}`;
    }

    const handleBackBtn = () => {
        if (props.backBtn == "modal") {
            props.closePrintModal();
        }
        else {
            window.history.back();
        }
    }

    const [lastExecuted, setLastExecuted] = useState(0);
    const [hasExecuted, setHasExecuted] = useState(false); // Track if the action has been executed

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isOnloadPrint && !hasExecuted) {
                setLoading(true);

                await generatePDF();
                await handleBackBtn();

                setLastExecuted(Date.now());
                setHasExecuted(true); // Set flag to true after execution
                setLoading(false);
            }
        }, 3000); // Wait for 3 seconds before executing

        return () => clearTimeout(timer); // Clear timeout on component unmount
    }, [isOnloadPrint, hasExecuted]);

    return (
        <div>
            <div style={{ padding: "0px", backgroundColor: "rgb(33, 46, 79)" }}>  {/*backgroundColor: "rgb(33, 46, 79)" */}
                <div className="flex-center-MHut" style={{ justifyContent: "space-between" }}>
                    <div>
                        <i class="fa-solid fa-arrow-left" style={{ display: props.backBtn == "none" ? "none" : "block", marginLeft: "5px", color: "white", }} onClick={backButton}></i>
                    </div>
                    <label className="MHut-text"><u className="MHut-underline" style={{ fontSize: "18px" }}>{companyData.printName ? companyData.printName : ''}</u></label>
                    <div>
                        <i class="fa-solid fa-print" onClick={generatePDF} style={{ display: props.backBtn == "none" ? "none" : "block", color: "white", marginRight: "5px" }}></i>
                    </div>
                </div>
                <div id="pdf_content">
                    <div style={{ display: "none" }} id="displayBlock">
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px", borderBottom: "1px solid black", backgroundColor: "White" }}>
                            <label className="MHut-text" style={{ color: "black", textAlign: "center", fontSize: "20px" }}>
                                <label className="MHut-underline" style={{ fontFamily: "calibri" }}>{companyData.printName}</label>
                            </label>
                        </div>
                    </div>
                    {orderData && orderData.map((data, index) => (
                        <div className="MHut-color-KOT">
                            <div className="space-between">
                                <div className="flex-start MHut-width-KOT">
                                    <label className="ques-text-col">Order&nbsp;No&nbsp;:&nbsp;</label>
                                    <label className="ans printLabel">{data.orderNo}</label>
                                </div>
                            </div>
                            <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                <label className="ques-text-col">Item&nbsp;Name:&nbsp;</label>
                                <label className="ans printLabel">{data.d2ItemName}</label>
                            </div>
                            {/***************/}
                            <div className="space-between">
                                <div className="flex-start MHut-width-KOT">
                                    <label className="ques-text-col">Item&nbsp;Category:&nbsp;</label>
                                    <label className="ans printLabel">Testing</label>
                                </div>
                                {/* <div className="flex-start MHut-width-KOT">
                            <label className="ques-text-col">Atr :&nbsp;</label>
                            <label className="ans"></label>
                        </div> */}
                            </div>
                            <div className="space-between">
                                <div className="flex-start MHut-width-KOT">
                                    <label className="ques-text-col printLabel-dark">SKU&nbsp;:&nbsp;</label>
                                    <label className="ans printLabel">{data.sku}</label>
                                </div>
                            </div>
                            <div className="space-between">
                                <div className="flex-start MHut-width-KOT">
                                    <label className="ques-text-col">Serve&nbsp;Pref.&nbsp;:&nbsp;</label>
                                    <label className="ans printLabel">{data.preferenceNo}</label>
                                </div>
                            </div>
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">Qty&nbsp;:&nbsp;</label>
                                <label className="ans printLabel">{data.qty}</label>
                            </div>
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">Item Suggestion :&nbsp;</label>
                                <label className="ans printLabel">{data.itemSuggestion}</label>
                            </div>
                            <div className="flex-start MHut-width-KOT">
                                <label className="ques-text-col">KOT Manager Name :&nbsp;</label>
                                <label className="ans printLabel">{data.kitchenManagerName}</label>
                            </div>
                            <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                <label className="ques-text-col">Print&nbsp;Date&nbsp;:&nbsp;</label>
                                <label className="ans printLabel">{currentDate}</label>
                            </div>
                            <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                <label className="ques-text-col">&&nbsp;Time&nbsp;: &nbsp;</label>
                                <label className="ans printLabel">{currentTime}</label>
                            </div>
                            {/* {renderTimeInformation()} */}
                            <div>
                                <div className="space-between">
                                    <div className="flex-start MHut-width-KOT">
                                        <label className="ques-text-col">Time Slot :&nbsp;</label>
                                        <label className="ans printLabel">{formatDate(data.deliveryDate)} , {data.timeSlotName}</label>
                                    </div>
                                </div>
                                <div className="space-between">
                                    <div className="flex-start MHut-width-KOT">
                                        <label className="ques-text-col">KDTT Time :</label>
                                        <label className="ans printLabel">{data.kotDispatchTargetTime}</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex-start MHut-width-KOT">
                        <label className="ques-text-col">Pickup Time : &nbsp;</label>
                        <label className="ans">{currentDate}</label>
                    </div> */}
                            {/*************************/}
                        </div>
                    ))}
                </div>
                {/**************************************************************************/}
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default MultipleKotPrintPage;
