import React, { useState } from 'react'
import InputBox from './InputBox'

const SendNotificationSetting = () => {

    const [inputValues, setInputValues] = useState({
        autenticationKey: '0',
        appId: '0',
        deviceKey: '0',
    })

    return (
        <div>
            <div className='heading-setting'>Send Notification Setting</div>
            <div className="grid-container">
                <div className="grid-item-container">
                    <InputBox
                        label={"Authentication Key"}
                        value={inputValues.autenticationKey}
                        onChange={(newvalue) => setInputValues({ ...inputValues, autenticationKey: newvalue })}
                    />
                </div>
                <div className="grid-item-container">
                    <InputBox
                        label={"App Id"}
                        value={inputValues.appId}
                        onChange={(newvalue) => setInputValues({ ...inputValues, appId: newvalue })}
                    />
                </div>
                <div className="grid-item-container">
                    <InputBox
                        label={"Device Key"}
                        value={inputValues.deviceKey}
                        onChange={(newvalue) => setInputValues({ ...inputValues, deviceKey: newvalue })}
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SendNotificationSetting
