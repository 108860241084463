import React, { useState } from 'react';
import './InputBox.css'; // Assuming you have a CSS file for styling

const InputBox = ({ value, onChange, label }) => {
    const [focused, setFocused] = useState(false);

    const handleInputChange = (e) => {
        onChange(e.target.value);
    };

    const handleInputFocus = () => {
        setFocused(true);
    };

    const handleInputBlur = () => {
        if (!value) {
            setFocused(false);
        }
    };

    return (
        <div className={`input-box-container ${focused ? 'focused' : ''}`}>
            <input
                type="text"
                value={value}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                className="input-box"
            />
            <label className={`input-box-label ${focused || value ? 'filled' : ''}`}>
                {label}
            </label>
        </div>
    );
};

export default InputBox;
