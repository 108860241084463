import React, { useState } from 'react';
import './Attendance.css';
import { Link } from 'react-router-dom';
//import DepartmentDetails from '../Inventory/Master/AccountMaster/DepartmentDetails';
//import DesignationDetails from '../Inventory/Master/Userdetails/DesignationDetails';

const AttendancePage = () => {
    //const departments = ['Department A', 'Department B', 'Department C'];
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log(selectedDate)
    };

    const [isModalOpenDepartmentDetails, setModalOpenDepartmentDetails] = useState(false);
    const showModalDepartmentDetails = () => {
        setModalOpenDepartmentDetails(true);
    };
    const hideModalDepartmentDetails = () => {
        setModalOpenDepartmentDetails(false);
    };

    const [isModalOpenDesignationDetails, setModalOpenDesignationDetails] = useState(false);
    const showModalDesignationDetails = () => {
        setModalOpenDesignationDetails(true);
    };
    const hideModalDesignationDetails = () => {
        setModalOpenDesignationDetails(false);
    };

    //const handleDepartmentChange = (event) => {
    //    setSelectedDepartment(event.target.value);
    //};
    const initialData = [
        {
            empId: 1, name: 'John Doe', mobile: '9876543210', date: '07/11/2023', designation: 'Developer', cl: '2', pl: '1', hpl: 0,
            dutySchedule: 'Yes', startTime: '09:00 AM', endTime: '05:00 PM', status: 'Present', totalWorkingHrs: '8'
        },
        {
            empId: 2, name: 'John Doe', mobile: '9876543210', date: '07/11/2023', designation: 'Developer', cl: '2', pl: '1', hpl: 0,
            dutySchedule: 'Yes', startTime: '09:00 AM', endTime: '05:00 PM', status: 'Present', totalWorkingHrs: '8'
        },
        {
            empId: 3, name: 'John Doe', mobile: '9876543210', date: '07/11/2023', designation: 'Developer', cl: '2', pl: '1', hpl: 0,
            dutySchedule: 'Yes', startTime: '09:00 AM', endTime: '05:00 PM', status: 'Present', totalWorkingHrs: '8'
        },
        {
            empId: 4, name: 'John Doe', mobile: '9876543210', date: '07/11/2023', designation: 'Developer', cl: '2', pl: '1', hpl: 0,
            dutySchedule: 'Yes', startTime: '09:00 AM', endTime: '05:00 PM', status: 'Present', totalWorkingHrs: '8'
        },
    ];

    //const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
    const [attendanceData, setAttendanceData] = useState(initialData);

    const handleStatusChange = (index, status) => {
        const newData = [...attendanceData];
        newData[index].status = status;
        setAttendanceData(newData);
    };
    const [Modal, setModal] = useState(false);

    const openModal = () => {
        setModal(true);
        document.getElementById('scrollBar').classList.remove('scroll-container');
        document.getElementById('scrollBar').style.overflow = "hidden";
    }

    const closeModal = () => {
        setModal(false);
        document.getElementById("body").classList.toggle("back-blur");
        document.getElementById('scrollBar').classList.add('scroll-container');
        document.getElementById('scrollBar').style.overflow = "scroll";
    };

    //const [activeButton, setActiveButton] = useState(null);

    //const handleClick = (buttonId) => {
    //    setActiveButton(buttonId);
    //};
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        console.log(selectedOption);
    };

    const getSelectStyle = () => {
        const colorMappings = {
            Present: 'green',
            Absent: 'indianred',
            LWP: 'skyblue',
            HLWP: '#ff94a6',
            CL: '#f29c2d',
            HCL: '#3ec277',
            EL: '#02b2ed',
            HEL: '#6a63d3',
            SPL: '#ff944e',
            HSPL: '#fd637f',
            MAT: '#4faafd',
            ML: '#d489ff',
            OD: '#3ec277'
        };

        const selectedColor = colorMappings[selectedOption] || 'grey';

        return {
            backgroundColor: selectedColor,
            color: 'white',
            padding: "8px",
            borderRadius: "5px",
            fontWeight: "500",
            boxShadow: "none",
        };
    };
    //*************************************
    const getSelectStyle1 = () => {
        const colorMappings = {
            Present: 'green',
            Absent: 'indianred',
            LWP: 'skyblue',
            HLWP: '#ff94a6',
            CL: '#f29c2d',
            HCL: '#3ec277',
            EL: '#02b2ed',
            HEL: '#6a63d3',
            SPL: '#ff944e',
            HSPL: '#fd637f',
            MAT: '#4faafd',
            ML: '#d489ff',
            OD: '#3ec277'

        };

        const selectedColor = colorMappings[selectedOption] || 'grey';

        return {
            backgroundColor: selectedColor,
            color: 'white',
            padding: "3px",
            borderRadius: "5px",
            fontWeight: "500",
            boxShadow: "none",
            border: "1px solid lightgrey"
        };
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    return (
        <div style={{ padding: "0px" }}>
            <div className="attendanceBody display-full">
                <div>
                    <div className="panel-heading1" style={{ backgroundColor: "#212e4f" }}>
                        <div className="panel-head-title1">
                            <h2 className="panel-title1" style={{ color: "white", fontWeight: "600" }}>Attendance</h2>
                        </div>
                        {/* *******************Top Buttons****************** */}
                        <div className="panel-head-button">
                            <div className="col_1">
                                <div className="button-m5">
                                    <i id="step-2" onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        className="fa-solid fa-gear im-icon-1" style={{ color: "white" }} ></i>
                                    {isHovered && <div className="hover-text1">Setting</div>}<br />
                                </div>

                                <div className="button-m5">
                                    <i id="step-2" onMouseEnter={() => setIsHovered3(true)}
                                        onMouseLeave={() => setIsHovered3(false)}
                                        className="fa-solid fa-rotate im-icon-1" style={{ color: "white" }}></i>
                                    {isHovered3 && <div className="hover-text1">Reset</div>}<br />
                                </div>

                                <div className="button-m5">
                                    <i id="step-2" onMouseEnter={() => setIsHovered4(true)}
                                        onMouseLeave={() => setIsHovered4(false)}
                                        className="fa-solid fa-file-export im-icon-1" style={{ color: "white" }}></i>
                                    {isHovered4 && <div className="hover-text1" style={{ marginLeft: "-3%" }}>Save</div>}<br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-section" style={{ marginLeft: "10px" }}>
                        <div className="">
                            <div className="space-evenly mt-3" style={{ width: "100%" }}>
                                <div className="flex" style={{}}>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <select className="inputEffect" style={{ width: "200px" }}>
                                            <option>Department A</option>
                                            <option>Department B</option>
                                            <option>Department C</option>
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Department</label>
                                    </div>
                                    <Link onClick={showModalDepartmentDetails}><i className="fa-solid fa-circle-plus mt-2"></i></Link>
                                </div>
                                <div className="input-containerEffect mt-1" style={{ margin: "1%", }}>
                                    <input className="inputEffect modify_im_input" type="date" id="" required style={{ width: "200px" }} />
                                    <label id="input1" className="labelEffect" style={{}}>Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                </div>
                                <div className="flex" style={{}}>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", }}>
                                        <select className="inputEffect" style={{ width: "200px" }}>
                                            <option>Morning Shift</option>
                                            <option>Day Shift</option>
                                            <option>Night Shift</option>
                                        </select>
                                        <label id="input1" className="labelEffect" style={{}}>Day Schedule</label>
                                    </div>
                                    <Link onClick={showModalDesignationDetails}><i className="fa-solid fa-circle-plus mt-2"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {isModalOpenDepartmentDetails && (

                        <div className="modal" >
                            <div className="OpticalField-modal increase_modal">
                                <div className="modal-title">
                                    <span className="close_modal" onClick={hideModalDepartmentDetails}>
                                        &times;
                                    </span>
                                </div>
                                <div className="">
                                    <div className="tab-content-modal">
                                        <div className="company-info-top-modal control_flex_modal item_scroll">
                                            {/* <DepartmentDetails /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    {isModalOpenDesignationDetails && (
                        <div className="modal" >
                            <div className="OpticalField-modal increase_modal">
                                <div className="modal-title">
                                    <span className="close_modal" onClick={hideModalDesignationDetails}>
                                        &times;
                                    </span>
                                </div>
                                <div className="">
                                    <div className="tab-content-modal">
                                        <div className="company-info-top-modal control_flex_modal item_scroll">
                                            {/* <DesignationDetails /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <table className="attendance-table" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th className="th">S.No</th>
                            <th className="th">Emp. Details</th>
                            <th className="th mobNo">Mobile</th>
                            <th className="th designation">Designation</th>
                            <th className="th display-1150">CL</th>
                            <th className="th display-1150">PL</th>
                            <th className="th display-1150">HPL</th>
                            {/*<th className="th dutySchedule">Duty Schedule</th>*/}
                            <th className="th s-e-time">Start Time</th>
                            <th className="th">End Time</th>
                            <th className="th display-1150">Take Off From</th>
                            <th className="th display-1150">Take Off To</th>
                            {/*<th className="th">Date</th>*/}
                            <th className="th">Status</th>
                            <th className="th">Total Work. Hrs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceData.map((employee, index) => (
                            <tr key={index}>
                                <td className="td" onClick={openModal}>
                                    <div className="flex-start" >
                                        <label>{employee.empId}</label>
                                        {/*<i className="fa-solid fa-circle-info display-550"  style={{ color: "#1842bb" }}></i>*/}
                                    </div>
                                </td>
                                <td className="td">
                                    <div>
                                        <div className="flex-start1">
                                            {/*<i className="fa-solid fa-user-tie display-550" onClick={openModal}></i>*/}
                                            {/*<label className="ques">&nbsp; EMP01 &nbsp;</label>*/}
                                            <label className="">&nbsp;{employee.name}</label>
                                        </div>
                                    </div>
                                </td>
                                <td className="td mobNo">{employee.mobile}</td>
                                <td className="td designation">{employee.designation}</td>
                                <td className="td  display-1150">{employee.cl}</td>
                                <td className="td  display-1150">{employee.pl}</td>
                                <td contentEditable onBlur={(e) => handleStatusChange(index, e.target.innerText)} className="td  display-1150">{employee.hpl}</td>
                                {/*<td className="td">*/}
                                {/*    <select value={employee.dutySchedule} style={{*/}
                                {/*        height: "fit-content", border: "1px solid lightgray",*/}
                                {/*        padding: "10px", boxShadow: "none", borderRadius: "5px"*/}
                                {/*    }}>*/}
                                {/*        <option>Morning Shift</option>*/}
                                {/*        <option>Afternoon Shift</option>*/}
                                {/*        <option>Evening Shift</option>*/}
                                {/*        <option>Night Shift</option>*/}
                                {/*    </select>*/}
                                {/*</td>*/}
                                <td className="td s-e-time">
                                    <div className="flex-start1">
                                        <input type="time" placeholder="Start" className="inputTime" style={{ width: "100%" }} /><br />
                                        {/*<div className="">*/}
                                        {/*    <label className="ans" style={{ color: "black" }}>&nbsp; to &nbsp;</label>*/}
                                        {/*</div>*/}
                                        {/*<input type="time" placeholder="End" className="inputTime" style={{ width: "100%" }} />*/}
                                        {/*<label className="ans" style={{ color: "black" }}>&nbsp; = &nbsp;</label>*/}
                                    </div>
                                </td>
                                <td className="td">
                                    <div className="">
                                        <input type="time" placeholder="Start" className="inputTime" style={{ width: "100%" }} />
                                        {/*<label className="ans" style={{ color: "black" }}>&nbsp; = &nbsp;8 hrs</label>*/}
                                    </div>
                                </td>
                                <td contentEditable onBlur={(e) => handleStatusChange(index, e.target.innerText)} className="td display-1150"></td>
                                <td contentEditable onBlur={(e) => handleStatusChange(index, e.target.innerText)} className="td display-1150"></td>
                                {/*<td className="td">{employee.date}</td>*/}
                                <td className="td">
                                    <select value={selectedOption}
                                        onChange={handleSelectChange}
                                        style={getSelectStyle()} >
                                        <option value="Select">Select</option>
                                        <option value="Present">Present</option>
                                        <option value="Absent">Absent</option>
                                        <option value="LWP">LWP</option>
                                        <option value="HLWP">Half LWP</option>
                                        <option value="CL">CL</option>
                                        <option value="HCL">Half CL</option>
                                        <option value="EL">EL</option>
                                        <option value="HEL">Half EL</option>
                                        <option value="SPL">SPL</option>
                                        <option value="HSPL">HSPL</option>
                                        <option value="MAT">MAT</option>
                                        <option value="ML">ML</option>
                                        <option value="OD">Out Duty</option>
                                    </select>
                                </td>
                                <td className="td">{employee.totalWorkingHrs}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/******************* Media 550px ********************/}
            <div className="display-550" style={{ backgroundColor: "ghostwhite" }}>
                <div className="heading">
                    <div className="head-display">
                        <div className="space-evenly">
                            <Link to="">
                                <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                            </Link>
                            <h2 className="head-title">Attendance</h2>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "50px", padding: "10px" }}>
                    <div className="filter-section" style={{ marginLeft: "10px" }}>
                        <div className="space-evenly1">
                            <div className="flex">
                                <div className="col-sm-6 col-xs-6">
                                    <label className="mt-1">Department : &nbsp;</label><br />
                                    <label className="mt-2">Date : &nbsp;</label><br />
                                    <label className="mt-2"> Duty Schedule : &nbsp;</label>
                                </div>

                                <div className="col-sm-6 col-xs-6">
                                    <div className="flex-start">
                                        <select className="filterBox">
                                            <option selected disabled>Select</option>
                                            <option>Department A</option>
                                            <option>Department B</option>
                                            <option>Department C</option>
                                        </select> &nbsp;
                                        <Link to="/DepartmentDetails"> <i className="fa-solid fa-circle-plus mt-2" style={{ color: "dimgrey" }}></i></Link>
                                    </div>
                                    <input type="date" className="filterBox mt-1" style={{ height: "fit-content", backgroundColor: "white", width: "86%" }} onChange={handleDateChange} /><br />
                                    <div className="flex-start">
                                        <select className="filterBox mt-1" style={{ height: "fit-content" }}>
                                            <option selected disabled>Select</option>
                                            <option>Morning Shift</option>
                                            <option>Day Shift</option>
                                            <option>Night Shift</option>
                                        </select> &nbsp;
                                        <Link to="/DesignationDetails"> <i className="fa-solid fa-circle-plus mt-2" style={{ color: "dimgrey" }}></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*******************/}
                    <div className="attendanceDiv mt-2" >
                        <div className="space-between " style={{ padding: "0px" }}>
                            <div className="flex-start">
                                <i className="fa-solid fa-user-tie mt-1" onClick={openModal} style={{ color: "black", fontSize: "16px" }}></i> &nbsp;
                                <label className="ques">Saravi Sinha</label><br />
                                <div className="flex-start" style={{ alignContent: "center" }}>
                                    {/*<label className="ans">Software Developer</label>*/}
                                </div>
                            </div>
                            <div>
                                <select value={selectedOption}
                                    onChange={handleSelectChange}
                                    style={getSelectStyle1()} >
                                    <option value="Select">Select</option>
                                    <option value="Present">Present</option>
                                    <option value="Absent">Absent</option>
                                    <option value="LWP">LWP</option>
                                    <option value="HLWP">Half LWP</option>
                                    <option value="CL">CL</option>
                                    <option value="HCL">Half CL</option>
                                    <option value="EL">EL</option>
                                    <option value="HEL">Half EL</option>
                                    <option value="SPL">SPL</option>
                                    <option value="HSPL">HSPL</option>
                                    <option value="MAT">MAT</option>
                                    <option value="ML">ML</option>
                                    <option value="OD">Out Duty</option>
                                </select>
                            </div>
                        </div>
                        <div className="space-between mt-1">
                            <div className="flex-start">
                                <input type="time" placeholder="Start" className="inputTime" />
                                <label className="ans mt-2" style={{ fontSize: "16px" }}>&nbsp; to &nbsp;</label>
                                <input type="time" placeholder="end" className="inputTime" /><br />
                                <label className="ans mt-1">&nbsp; = 8 hrs</label>
                            </div>
                            <div className="flex-start" style={{ alignItems: "center" }}>
                                {/*<label className="ans"> =&nbsp;8&nbsp;hrs</label>*/}
                            </div>
                        </div>
                    </div>
                    {/*****************/}
                    <div className="attendanceDiv" >
                        <div className="space-between " style={{ padding: "0px" }}>
                            <div className="flex-start">
                                <i className="fa-solid fa-user-tie mt-1" onClick={openModal} style={{ color: "black", fontSize: "16px" }}></i> &nbsp;
                                <label className="ques">Saravi Sinha</label><br />
                                <div className="flex-start" style={{ alignContent: "center" }}>
                                    {/*<label className="ans">Software Developer</label>*/}
                                </div>
                            </div>
                            <div>
                                <select value={selectedOption}
                                    onChange={handleSelectChange}
                                    style={getSelectStyle1()} >
                                    <option value="Select">Select</option>
                                    <option value="Present">Present</option>
                                    <option value="Absent">Absent</option>
                                    <option value="LWP">LWP</option>
                                    <option value="HLWP">Half LWP</option>
                                    <option value="CL">CL</option>
                                    <option value="HCL">Half CL</option>
                                    <option value="EL">EL</option>
                                    <option value="HEL">Half EL</option>
                                    <option value="SPL">SPL</option>
                                    <option value="HSPL">HSPL</option>
                                    <option value="MAT">MAT</option>
                                    <option value="ML">ML</option>
                                    <option value="OD">Out Duty</option>
                                </select>
                            </div>
                        </div>
                        <div className="space-between mt-1">
                            <div className="flex-start">
                                <input type="time" placeholder="Start" className="inputTime" />
                                <label className="ans mt-2" style={{ fontSize: "16px" }}>&nbsp; to &nbsp;</label>
                                <input type="time" placeholder="end" className="inputTime" /><br />
                                <label className="ans mt-1">&nbsp; = 8 hrs</label>
                            </div>
                            <div className="flex-start" style={{ alignItems: "center" }}>
                                {/*<label className="ans"> =&nbsp;8&nbsp;hrs</label>*/}
                            </div>
                        </div>
                    </div>

                    <div className="attendanceDiv" >
                        <div className="space-between " style={{ padding: "0px" }}>
                            <div className="flex-start">
                                <i className="fa-solid fa-user-tie mt-1" onClick={openModal} style={{ color: "black", fontSize: "16px" }}></i> &nbsp;
                                <label className="ques">Saravi Sinha</label><br />
                                <div className="flex-start" style={{ alignContent: "center" }}>
                                    {/*<label className="ans">Software Developer</label>*/}
                                </div>
                            </div>
                            <div>
                                <select value={selectedOption}
                                    onChange={handleSelectChange}
                                    style={getSelectStyle1()} >
                                    <option value="Select">Select</option>
                                    <option value="Present">Present</option>
                                    <option value="Absent">Absent</option>
                                    <option value="LWP">LWP</option>
                                    <option value="HLWP">Half LWP</option>
                                    <option value="CL">CL</option>
                                    <option value="HCL">Half CL</option>
                                    <option value="EL">EL</option>
                                    <option value="HEL">Half EL</option>
                                    <option value="SPL">SPL</option>
                                    <option value="HSPL">HSPL</option>
                                    <option value="MAT">MAT</option>
                                    <option value="ML">ML</option>
                                    <option value="OD">Out Duty</option>
                                </select>
                            </div>
                        </div>
                        <div className="space-between mt-1">
                            <div className="flex-start">
                                <input type="time" placeholder="Start" className="inputTime" />
                                <label className="ans mt-2" style={{ fontSize: "16px" }}>&nbsp; to &nbsp;</label>
                                <input type="time" placeholder="end" className="inputTime" /><br />
                                <label className="ans mt-1">&nbsp; = 8 hrs</label>
                            </div>
                            <div className="flex-start" style={{ alignItems: "center" }}>
                                {/*<label className="ans"> =&nbsp;8&nbsp;hrs</label>*/}
                            </div>
                        </div>
                    </div>
                    {/******************/}
                    <div>
                        {/*<div className="attendance-page">*/}
                        {/*    <div className="attendance-list">*/}
                        {/*        {attendanceData.map((data, index) => (*/}
                        {/*            <div key={index} className="attendance-item">*/}
                        {/*                <p>{`Emp ID: ${data.empId}`}</p>*/}
                        {/*                <p>{`Name: ${data.name}`}</p>*/}
                        {/*                <p>{`Mobile: ${data.mobile}`}</p>*/}
                        {/*                <p>{`Designation: ${data.designation}`}</p>*/}
                        {/*                <p>{`Start Time: ${data.startTime}`}</p>*/}
                        {/*                <p>{`End Time: ${data.endTime}`}</p>*/}
                        {/*                <p>{`Date: ${data.date}`}</p>*/}
                        {/*                <p>{`Status: ${data.status}`}</p>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<table className="w-100">*/}
                        {/*    <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th className="th">S.No</th>*/}
                        {/*            <th className="th">Employee Name</th>*/}
                        {/*            <th className="th">Attendance</th>*/}
                        {/*        </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*        <tr>*/}
                        {/*            <td className="td">1</td>*/}
                        {/*            <td className="td">*/}
                        {/*                <div>*/}
                        {/*                    <label className="ques">Krishna Dev</label>*/}
                        {/*                    <label className="ans"></label>*/}
                        {/*                </div>*/}
                        {/*            </td>*/}
                        {/*            <td className="td">*/}
                        {/*                <div className="flex-center">*/}
                        {/*                    <button className="attendanceBtn"*/}
                        {/*                        onClick={() => handleClick(1)}*/}
                        {/*                        style={{*/}
                        {/*                            backgroundColor: activeButton === 1 ? "green" : "ghostwhite",*/}
                        {/*                            border: activeButton === 1 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*                            color: activeButton === 1 ? "white" : "black",*/}
                        {/*                            fontWeight: activeButton === 1 ? "600" : "400",*/}
                        {/*                            borderRadius: "5px",*/}
                        {/*                        }} > P*/}
                        {/*                    </button>*/}
                        {/*                    <button className="attendanceBtn"*/}
                        {/*                        onClick={() => handleClick(2)}*/}
                        {/*                        style={{*/}
                        {/*                            backgroundColor: activeButton === 2 ? "darkred" : "ghostwhite",*/}
                        {/*                            border: activeButton === 2 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*                            color: activeButton === 2 ? "white" : "black",*/}
                        {/*                            fontWeight: activeButton === 2 ? "600" : "400",*/}
                        {/*                            borderRadius: "5px",*/}
                        {/*                        }} > A*/}
                        {/*                    </button>*/}
                        {/*                    <button className="attendanceBtn"*/}
                        {/*                        onClick={() => handleClick(3)}*/}
                        {/*                        style={{*/}
                        {/*                            backgroundColor: activeButton === 3 ? "orange" : "ghostwhite",*/}
                        {/*                            border: activeButton === 3 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*                            color: activeButton === 3 ? "white" : "black",*/}
                        {/*                            fontWeight: activeButton === 3 ? "600" : "400",*/}
                        {/*                            borderRadius: "5px",*/}
                        {/*                        }} > L*/}
                        {/*                    </button>*/}
                        {/*                    */}{/*<button className="attendanceBtn"> P </button>*/}
                        {/*                    */}{/*<button className="attendanceBtn"> A </button>*/}
                        {/*                    */}{/*<button className="attendanceBtn"> L </button>*/}
                        {/*                </div>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*        */}{/*****************/}
                        {/*        <tr>*/}
                        {/*            */}{/*<td className="td">1</td>*/}
                        {/*            */}{/*<td className="td">John</td>*/}
                        {/*            */}{/*<td className="td">*/}
                        {/*            */}{/*    <div className="flex-center">*/}
                        {/*            */}{/*        <button className="attendanceBtn"*/}
                        {/*            */}{/*            onClick={() => handleClick(4)}*/}
                        {/*            */}{/*            style={{*/}
                        {/*            */}{/*                backgroundColor: activeButton === 4 ? "green" : "ghostwhite",*/}
                        {/*            */}{/*                border: activeButton === 4 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*            */}{/*                color: activeButton === 4 ? "white" : "black",*/}
                        {/*            */}{/*                fontWeight: activeButton === 4 ? "600" : "400",*/}
                        {/*            */}{/*                borderRadius: "5px",*/}
                        {/*            */}{/*            }} > P*/}
                        {/*            */}{/*        </button>*/}
                        {/*            */}{/*        <button className="attendanceBtn"*/}
                        {/*            */}{/*            onClick={() => handleClick(5)}*/}
                        {/*            */}{/*            style={{*/}
                        {/*            */}{/*                backgroundColor: activeButton === 5 ? "darkred" : "ghostwhite",*/}
                        {/*            */}{/*                border: activeButton === 5 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*            */}{/*                color: activeButton === 5 ? "white" : "black",*/}
                        {/*            */}{/*                fontWeight: activeButton === 5 ? "600" : "400",*/}
                        {/*            */}{/*                borderRadius: "5px",*/}
                        {/*            */}{/*            }} > A*/}
                        {/*            */}{/*        </button>*/}
                        {/*            */}{/*        <button className="attendanceBtn"*/}
                        {/*            */}{/*            onClick={() => handleClick(6)}*/}
                        {/*            */}{/*            style={{*/}
                        {/*            */}{/*                backgroundColor: activeButton === 6 ? "orange" : "ghostwhite",*/}
                        {/*            */}{/*                border: activeButton === 6 ? "2px solid white" : "2px solid lightgrey",*/}
                        {/*            */}{/*                color: activeButton === 6 ? "white" : "black",*/}
                        {/*            */}{/*                fontWeight: activeButton === 6 ? "600" : "400",*/}
                        {/*            */}{/*                borderRadius: "5px",*/}
                        {/*            */}{/*            }} > L*/}
                        {/*            */}{/*        </button>*/}
                        {/*            */}{/*        */}{/*<button className="attendanceBtn"> P </button>*/}
                        {/*            */}{/*        */}{/*<button className="attendanceBtn"> A </button>*/}
                        {/*            */}{/*        */}{/*<button className="attendanceBtn"> L </button>*/}
                        {/*            */}{/*    </div>*/}
                        {/*            */}{/*</td>*/}
                        {/*        </tr>*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                    </div>
                </div>
            </div>
            {/************************** User Details Modal ***************/}
            {
                Modal && (
                    <div className="modal-back2">
                        <div className="userDetail-modal">
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}>Employee Details</label>
                                <i className="fa-solid fa-xmark xmark" onClick={closeModal}></i>
                            </div>
                            <hr />
                            <div className="">
                                <div className="" style={{ display: "flex" }}>
                                    <div className="col-sm-6 col-xs-6" style={{ paddingRight: "10px" }}>
                                        <div className="flex-start">
                                            <label className="ques">Name : &nbsp;</label>
                                            <label className="ans">John Doe</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Department : &nbsp;</label>
                                            <label className="ans">IT</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Duty Schedule : &nbsp;</label>
                                            <label className="ans">Day Shift</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Take Off from : &nbsp;</label>
                                            <label className="ans">-</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Take Off To : &nbsp;</label>
                                            <label className="ans">-</label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-xs-6">
                                        <div className="flex-start">
                                            <label className="ques">Designation : &nbsp;</label>
                                            <label className="ans">Developer</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Mobile No : &nbsp;</label>
                                            <label className="ans">9876543210</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">CL : &nbsp;</label>
                                            <label className="ans">2/5</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">EL : &nbsp;</label>
                                            <label className="ans">3/10</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">HPL : &nbsp;</label>
                                            <label className="ans">0</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                <button className="btn greenBtn" style={{ width: "25%" }} >Submit</button>
                                <button className="btn redBtn" style={{ width: "25%" }} onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default AttendancePage;
