import axios from "../axiosConfig"; // Adjust the import path accordingly

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const CheckOutAndGeneralSetting = {
  // ****************************** Create Eye CheckUp And Optical Setting ******************************************************** */
  CreateEyeCheckUpAndOptiSett: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/CheckOutAndGeneralSetting/CreateEyeCheckUpAndOptiSett`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          data,
        },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  //**************************************************Get Eye CheckUp And Optical Setting*********************************** */
  GetEyeCheckUpAndOptiSett: async (masterType) => {
    debugger;
    console.log(masterType);
    try {
      const apiUrl = `${WebApiUrl}/api/CheckOutAndGeneralSetting/GetEyeCheckUpAndOptiSett?masterType=${masterType}`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
};

export default CheckOutAndGeneralSetting;
