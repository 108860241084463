import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TableAllocation = {

    insertTableAllocation: async (data) => {

        const updatedData = data.map(data1 => ({
            userId: Number(data1.userId),
            tableId: Number(data1.tableId),
            mastType: 167,
            useType: 167

        }));
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation/CreateTableAllotment`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, updatedData, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getTableAllocation: async (userId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteTableAllocation: async (userId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation?userId=${userId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By user Id ****************************
    getTableAllocationByIdPost: async (userId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
            const response = await axios.post(apiUrl, {
                filter: [
                    {
                        fieldName: "string",
                        operatorName: "string",
                        compareValue: "string"
                    }
                ],
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 50000
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('getTableAllocationById Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By user Id ****************************
    getTableAllocationById: async (userId) => {
        //debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By user Id ****************************
    // getTableAllocationById1: async (userId) => {
    //     debugger;
    //     try {
    //         //http://192.168.1.6/YESBM-API/api/v1/TableAllocation/GetTableAllocations/1
    //         //const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
    //         const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
    //         console.log(apiUrl);
    //         const response = await axios.post(apiUrl, {
    //             withCredentials: true,
    //             credentials: 'include',
    //         });
    //         console.log('getTableAllocationById:', response.data.result);
    //         return response.data.result;
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // },
    // *************************** Update ***************************
    updateTableAllocation: async (tableData) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        let filteredData;
        if (tableData.length !== 0) {
            filteredData = tableData.map(data => ({
                userId: Number(data.userId),
                tableId: Number(data.tableId),
                tableName: data.tableName,
                userName: data.userName,
                mastType: 167,
                useType: 167
            }));
        }
        else {
            filteredData = [];
        }
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation?userId=${loginUser.userId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, filteredData, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default TableAllocation;
