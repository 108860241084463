import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";
import { Link } from "react-router-dom";
import Select from 'react-select';

function RecipeMaster() {
    /***********title***************/
    useState(() => {
        document.title = 'Recipe Master List';
    }, []);

    //************I{mage add}******************//

    //******************{modal box}******************//
    const [,setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };


    /**************{attribute code}********************/

    // const [isHovered, setHovered] = useState(false);
    // const handleMouseEnter = () => {
    //     setHovered(true);
    // };
    // const handleMouseLeave = () => {
    //     setHovered(false);
    // };

    /**************{Select Box Label}********************/
    const options = [
        { value: '0', label: '--Select Item--' },
        { value: '1', label: '7up' },
        { value: '2', label: 'Aloo Fry & Curd Rice Bow' },
        { value: '3', label: 'Aloo Gobhi Masala' },
        { value: '4', label: 'Arrabito Pasta' },
    ];

    //************Check box************/ /
    const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,

    });

    const handleCheckboxChange = (checkboxName) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxName]: !prevCheckboxes[checkboxName],
        }));
    };

    const handleSelectAll = () => {
        const allChecked = Object.values(checkboxes).every((isChecked) => isChecked);

        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {};
            for (const checkboxName in prevCheckboxes) {
                updatedCheckboxes[checkboxName] = !allChecked;
            }
            return updatedCheckboxes;
        });
    };


    //************End Check box************/ /


    return (
        <div>
            < PageTabs />
            <div>

            </div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Recipe Master List</h2>
                </div >

                {/* ********************************{Buttob}********************************* */}
                <div className="panel-head-button">
                    <div className="col_1">
                        {/* <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Save
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div> */}

                        <div className="button-m5">
                            <button className="save-button-main">Back
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>

                    </div>
                </div>


            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">

                                        <div className="control_radius">
                                            <div className="Recipe_right_top">
                                                <div className="Recipe-flex-start">&nbsp; &nbsp; &nbsp;

                                                    <div className="button-m5">
                                                        <Link to="/AddRecipe" className="NewRecipe-button-main-search">Add New Recipe
                                                            <i id="step-2" className="fa-solid fa-plus im-icon-2"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="Recipe-button-main">Quick Recipe
                                                            <i id="step-2" className="fa-solid fa-gauge-high im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="Recipe-button-main">Area Recipe
                                                            <i id="step-2" className="fa-solid fa-chart-area im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="Recipe-button-main">Copy Recipe
                                                            <i id="step-2" className="fa-solid fa-copy im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="Recipe-button-main">Import Recipe
                                                            <i id="step-2" className="fa-solid fa-file-import im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="Recipe-button-main">Export Excel
                                                            <i id="step-2" className="fa-solid fa-file-export im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m5">
                                                        <button className="ItemRecipe-button-main-multiple">Delete Multiple Recipe
                                                            <i id="step-2" className="fa-solid fa-trash-can im-icon-2"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="normal_div">

                                            </div>
                                            <div className="Recipe_Select_Item ">
                                                <div className="stock-recipe-MGM">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            {/* <select className="modify_im_input mode_category1 border_hide" type="text" value={selectedItem} onChange={handleSelectChange} required>
                                            <option>--Select Item--</option>
                                            <option value="7up">7up</option>
                                            <option value="Aloo Fry & Curd Rice Bow">Aloo Fry & Curd Rice Bow</option>
                                            <option value="Aloo Gobhi Masala">Aloo Gobhi Masala</option>
                                            <option value="Arrabito Pasta">Arrabito Pasta</option>
                                        </select> */}
                                                            <Select
                                                                className="modify_im_input border_hide"
                                                                options={options}
                                                                placeholder="--Select Item--"
                                                                isSearchable={true}
                                                                required />

                                                            {/* <label className="modify_lbl_text3">Department Name</label> */}
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-flex-recipe">
                                                        <div className="button-m5">
                                                            <button className="save-button-main-save">Search
                                                                <i id="step-2" className="fa-solid fa-magnifying-glass im-icon-2"></i>
                                                            </button>
                                                        </div>
                                                        <div className="button-m5">
                                                            <button className="save-button-main">Show All
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="Recipes_Text">
                                            <label>Note: Consumption through recipes would work on 'Save & Print' or 'Save & e-bill' options only it does not work on the 'Save' bill.</label>
                                        </div>

                                        <div className="control_radius modified_top">
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <table id="tblTransactionList" className="table_ord_mod table-border_RCP" frame="box">
                                                        <thead textAlign="center" className="thead_scroll_RecipeManage table-tbody_RecipeMgm" >
                                                            <tr className="">
                                                                <th className="td_col_Recipe" style={{ width: "1%" }}> <div className="Check_Box_head">
                                                                    <div><input type="checkbox" className="check_box_order" checked={Object.values(checkboxes).every((isChecked) => isChecked)}
                                                                        onChange={handleSelectAll} name="" id="" /></div>
                                                                </div> </th>
                                                                <th className="td_col_Recipe" style={{ width: "6%" }}> <div className="table_head_OrderList">
                                                                    <div className="table_head_mmb1" >Name</div>
                                                                </div> </th>
                                                                <th className="td_col_Recipe" style={{ width: "13%" }}> <div className="table_head_OrderList">
                                                                    <div className="table_head_nnb1" >Modified</div>
                                                                </div> </th>
                                                                <th className="td_col_Recipe" style={{ width: "10%" }}> <div className="table_head_OrderList">
                                                                    <div className="table_head_nnb1" >Created/Modified By</div>
                                                                </div> </th>
                                                                <th className="td_col_Recipe" style={{ width: "6%" }}> <div className="table_head_OrderList">
                                                                    <div className="table_head_nnb1" >Action</div>
                                                                </div> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tbody_body_m1">
                                                            <tr className="table_tbody_Recipe recipe_bgcolor">
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}><input type="checkbox" className="check_box_order" checked={checkboxes.checkbox1}
                                                                    onChange={() => handleCheckboxChange('checkbox1')} name="" id="" /></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta </td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20 <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                                <tr className="Action_Buttons">
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                                </tr>
                                                            </tr>
                                                            <tr className="table_tbody_Recipe recipe_bgcolor">
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox2}
                                                                    onChange={() => handleCheckboxChange('checkbox2')} name="" id="" /></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta</td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20 <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                                <tr className="Action_Buttons">
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                                </tr>
                                                            </tr>
                                                            <tr className="table_tbody_Recipe recipe_bgcolor">
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox3}
                                                                    onChange={() => handleCheckboxChange('checkbox3')} name="" id="" /></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta</td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20  <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                                <tr className="Action_Buttons">
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                                </tr>
                                                            </tr>
                                                            <tr className="table_tbody_Recipe recipe_bgcolor">
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox4}
                                                                    onChange={() => handleCheckboxChange('checkbox4')} name="" id="" /></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta</td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20 <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                                <tr className="Action_Buttons">
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                                </tr>
                                                            </tr>
                                                            <tr className="table_tbody_Recipe recipe_bgcolor">
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox5}
                                                                    onChange={() => handleCheckboxChange('checkbox5')} name="" id="" /></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta</td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20  <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                                <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                                <tr className="Action_Buttons">
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                                    <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                                    <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                                </tr>
                                                            </tr>
                                                            {/* <tr className="table_tbody_Recipe recipe_bgcolor">
                                                    <td className="td_col_Recipe" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box_order" checked={checkboxes.checkbox2}
                                                        onChange={() => handleCheckboxChange('checkbox2')} name="" id="" /></td>
                                                    <td className="td_col_Recipe" style={{ textAlign: "center" }}>Arrabito Pasta</td>
                                                    <td className="td_col_Recipe" style={{ textAlign: "center" }}>Created: 15-Dec-2023 13:22:20  <td className="modified_flex">Modified 15-Dec-2023 13:22:20</td></td>
                                                    <td className="td_col_Recipe" style={{ textAlign: "center" }}>Raipur-Headoffice_Demo</td>
                                                    <tr className="Action_Buttons">
                                                        <td><button className="edit_btn"><i className="fa-solid fa-regular fa-eye"></i></button></td>
                                                        <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td><button className="delete_btn_RCP"><i className="fa-solid fa-trash"></i></button></td>
                                                    </tr>
                                                </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="Recipe_footer">
                                            <div>
                                                <div className="Recipe-flex-start">&nbsp; &nbsp; &nbsp;
                                                    <div className="Recipes_Footer_Text">
                                                        <label>Showing 1 to 6 of 6 records</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default RecipeMaster;
