import React, { useEffect, useState } from "react";
import moment from "moment";
import Order from "../../API/APIServices/Order";

const TrackOrderModel = (props) => {
    const { closeModal, ItemDetail, setItemDetail, selectedData, setSelectedData } = props;
    console.log(selectedData);
    const [tripList, setTripList] = useState();

    useEffect(() => {
        //alert(selectedData[0].deliveryBoyTripNo)
        const fetchData = async () => {
            const filteredData = [
                {
                    fieldName: "tripNo",
                    operatorName: "Equal",
                    compareValue: selectedData[0].deliveryBoyTripNo
                }
            ]
            const data = await Order.getDeliveryBoyTrip(filteredData);
            setTripList(data)
        }
        fetchData();
    }, []);

    const changeOrderMobileNo = (event) => {
        // Create a new array with the updated item
        const updatedItemDetails = ItemDetail.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, mobileNo: event.target.value };
            }
            return item;
        });

        // Update the state
        setItemDetail(updatedItemDetails);

        const updatedItemDetail = selectedData.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, mobileNo: event.target.value };
            }
            return item;
        });

        setSelectedData(updatedItemDetail)

    };
    const changeOrderAddress = (event) => {
        // Create a new array with the updated item
        const updatedItemDetails = ItemDetail.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, accAddress: event.target.value };
            }
            return item;
        });

        // Update the state
        setItemDetail(updatedItemDetails);

        const updatedItemDetail = selectedData.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, accAddress: event.target.value };
            }
            return item;
        });

        setSelectedData(updatedItemDetail)

    };
    const changeOrderName = (event) => {
        // Create a new array with the updated item
        const updatedItemDetails = ItemDetail.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, name: event.target.value };
            }
            return item;
        });

        // Update the state
        setItemDetail(updatedItemDetails);

        const updatedItemDetail = selectedData.map((item, index) => {
            if (index === 0) {  // Assuming we're only updating the first item
                return { ...item, name: event.target.value };
            }
            return item;
        });
        setSelectedData(updatedItemDetail)
    };

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }

        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    const UpdateOrderPartially = async (ord1Id) => {
        const updatedData = [
            {
                operationType: 0,
                path: "Name",
                op: "Add",
                from: "string",
                value: selectedData[0].name
            },
            {
                operationType: 0,
                path: "mobileNo",
                op: "Add",
                from: "string",
                value: selectedData[0].mobileNo
            },
            {
                operationType: 0,
                path: "DeliveryAddress",
                op: "Add",
                from: "string",
                value: selectedData[0].accAddress
            }
        ]

        await Order.updateOrderPartially(updatedData, ord1Id);
        alert("Updated Successfully");
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const trackTripClick = () => {
        const element = document.getElementById("trackTrip");
        element.style.display = "block";
    }

    return (
        <div className="modal-back" >
            <div>
                <div className="TrackOrderModel">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label
                            className="fw-600"
                            style={{
                                fontSize: "18px",
                                padding: "10px",
                                width: "100%",
                                borderBottom: "1px solid rgb(233, 233, 233)",
                            }}
                        >
                            Track Your Order
                        </label>
                        <label onClick={closeModal} style={{ fontSize: "25px", marginRight: "5px" }}>x</label>
                    </div>

                    <div style={{ overflow: "scroll", height: "550px" }}>
                        <div>
                            <div className="track-data-row">
                                <p className="track-row">Order No : {ItemDetail[0].orderNo}</p>
                                <p className="track-row">Order Time : {ItemDetail[0].orderTime}</p>
                                <p className="track-row">
                                    Order Date -{" "}
                                    {moment(ItemDetail[0].orderDate).format("DD-MM-YYYY")}
                                </p>
                                <p className="track-row"></p>
                            </div>
                        </div>
                        <div className="track-data-row">
                            <div className="track-row">
                                <p>Name :</p> <input type="text" onChange={changeOrderName} value={selectedData[0].name} />
                            </div>
                            <div style={{ marginBottom: "-40px" }} className="track-row">
                                <p>Address :</p>{" "}
                                <textarea onChange={changeOrderAddress} type="text" value={selectedData[0].accAddress} />
                            </div>
                            <div className="track-row">
                                <p>Mobile No :</p>{" "}
                                <input onChange={changeOrderMobileNo} type="text" value={selectedData[0].mobileNo} />
                            </div>
                            <div className="track-row"></div>
                        </div>
                        <div className="track-data-row">
                            <div className="track-row">
                                <p>Delivery Boy Pre. No :</p>{" "}
                                <p>{selectedData[0].deliveryPreferenceNo} </p>
                            </div>
                            <div className="track-row">
                                <p>Delivery Boy Name :</p> <p>{selectedData[0].deliveryBoyName} </p>
                            </div>
                            <div className="track-row">
                                <p>Delivery Boy Mobile No :</p> <p>{selectedData[0].accMobileNo} </p>
                            </div>
                            <div className="track-row">
                                <p>Delivery Boy Bike No :</p> <p>{selectedData[0].accMobileNo} </p>
                            </div>
                        </div>
                        <table className="track-order-table" border={1}>
                            <tr>
                                <th>S no</th>
                                <th>Description</th>
                                <th>Time</th>
                                <th>Status</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Your Order is being Confirmed</td>
                                    <td>{moment.utc(selectedData[0].orderConfirmedOn).utcOffset("+05:30").format('hh:mm A')}</td>{/*moment(selectedData[0].orderConfirmedOn).format('hh:mm A')*/}

                                    <td> {selectedData[0].orderConfirmedOn ? <i class="fa-solid fa-check"></i> : ""
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td> Mothers are cooking your food</td>
                                    <td> {convertTo12HourFormat(String(selectedData[0].kotDispatchTime))}</td>

                                    <td> {selectedData[0].kotDispatchTime ? <i class="fa-solid fa-check"></i> : ""
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td> Delivery boy on the way</td>
                                    <td>{convertTo12HourFormat(String(selectedData[0].deliveryBoyDispatchTime))}</td>

                                    <td> {selectedData[0].deliveryBoyDispatchTime ? <i class="fa-solid fa-check"></i> : ""
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Your Food has Been Delivered</td>
                                    <td>{convertTo12HourFormat(String(selectedData[0].deliveredTime))}</td>
                                    <td> {selectedData[0].deliveredTime ? <i class="fa-solid fa-check"></i> : ""
                                    }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="track-button-box">
                            <button className="track-button" onClick={trackTripClick} style={{ background: "rgb(96 188 255)", color: "white", borderRadius: "8px" }}>Track trip</button>
                            <div>
                                <button onClick={() => UpdateOrderPartially(selectedData[0].ord1Id)} className="track-button" style={{ background: "rgb(96 188 255)", color: "white", borderRadius: "8px" }}>Update</button>
                                <button onClick={closeModal} className="track-button" style={{ background: "rgb(255 96 96)", color: "white", borderRadius: "8px" }}>Close</button>
                            </div>
                        </div>

                        <div style={{ display: "none" }} id="trackTrip">
                            <table className="track-order-table" border={1}>
                                <tr>
                                    <th>S no</th>
                                    <th>Order No</th>
                                    <th>Trip No</th>
                                    <th>Preference No</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Time Slot</th>
                                    <th>Delivery Status</th>
                                </tr>
                                <tbody>
                                    {(() => {
                                        if (tripList !== null && Array.isArray(tripList)) {
                                            return tripList.map((data, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data.orderNo}</td>
                                                    <td>{data.tripNo}</td>
                                                    <td>{data.deliveryPreferenceNo}</td>
                                                    <td>{data.accountName}</td>
                                                    <td>{data.delAddress}</td>
                                                    <td>{timeFormat(data.deliveryTimeSlot)}</td>
                                                    <td>{data.deliveryStatus}</td>
                                                </tr>
                                            ))
                                        }
                                    })()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* ********************************* Track Trip **************** */}
            </div>
        </div>
    );
};

export default TrackOrderModel;
