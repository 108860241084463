import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef } from "react";
import 'react-quill/dist/quill.snow.css';
import PreviousOrder from "../../Dashboard/PreviousOrder";
import ModalEyeCheckUp from "../../ModalBox/ModalEyeCheckUp";
import MainButton from "../../Props/MainButton";


function EyeCheckUp() {
    /***************{title}*******************/
    useState(() => {
        document.title = 'Eye Check Up';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    //************{modal box}************/ /

    const [isModalOpenPrev, setModalOpenPrev] = useState(false);

    const showModalPrev = () => {
        setModalOpenPrev(true);
    };

    const hideModalPrev = () => {
        setModalOpenPrev(false);
    };

    //*****************{save button box}*********************//


    //*****************{Onboarding code Open}************************//

    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };


    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);
    const input24Ref = useRef(null);
    const input25Ref = useRef(null);
    const input26Ref = useRef(null);
    const input27Ref = useRef(null);
    const input28Ref = useRef(null);
    const input29Ref = useRef(null);
    const input30Ref = useRef(null);
    const input31Ref = useRef(null);
    const input32Ref = useRef(null);
    const input33Ref = useRef(null);
    const input34Ref = useRef(null);
    const input35Ref = useRef(null);
    const input36Ref = useRef(null);
    const input37Ref = useRef(null);
    const input38Ref = useRef(null);
    const input39Ref = useRef(null);
    const input40Ref = useRef(null);
    const input41Ref = useRef(null);
    const input42Ref = useRef(null);
    const input43Ref = useRef(null);
    const input44Ref = useRef(null);
    const input45Ref = useRef(null);
    const input46Ref = useRef(null);
    const input47Ref = useRef(null);
    const input48Ref = useRef(null);
    const input49Ref = useRef(null);
    const input50Ref = useRef(null);
    const input51Ref = useRef(null);
    const input52Ref = useRef(null);
    const input53Ref = useRef(null);
    const input54Ref = useRef(null);
    const input55Ref = useRef(null);
    const input56Ref = useRef(null);
    const input57Ref = useRef(null);
    const input58Ref = useRef(null);
    const input59Ref = useRef(null);
    const input60Ref = useRef(null);
    const input61Ref = useRef(null);
    const input62Ref = useRef(null);
    const input63Ref = useRef(null);
    const input64Ref = useRef(null);
    const input65Ref = useRef(null);
    const input66Ref = useRef(null);
    const input67Ref = useRef(null);
    const input68Ref = useRef(null);
    const input69Ref = useRef(null);
    const input71Ref = useRef(null);
    const input72Ref = useRef(null);
    const input73Ref = useRef(null);
    const input74Ref = useRef(null);
    const input75Ref = useRef(null);
    const input76Ref = useRef(null);
    const input77Ref = useRef(null);
    const input78Ref = useRef(null);
    const input79Ref = useRef(null);
    const input80Ref = useRef(null);
    const input81Ref = useRef(null);
    const input82Ref = useRef(null);
    const input83Ref = useRef(null);
    const input84Ref = useRef(null);
    const input86Ref = useRef(null);
    const input87Ref = useRef(null);
    const input88Ref = useRef(null);
    const input89Ref = useRef(null);
    const input90Ref = useRef(null);
    const input91Ref = useRef(null);
    const input92Ref = useRef(null);
    const input93Ref = useRef(null);
    const input94Ref = useRef(null);
    const input95Ref = useRef(null);
    const input96Ref = useRef(null);
    const input97Ref = useRef(null);
    const input98Ref = useRef(null);
    const input100Ref = useRef(null);
    const input101Ref = useRef(null);
    const input102Ref = useRef(null);
    const input103Ref = useRef(null);
    const input104Ref = useRef(null);
    const input105Ref = useRef(null);
    const input106Ref = useRef(null);
    const input107Ref = useRef(null);
    const input108Ref = useRef(null);
    const input109Ref = useRef(null);
    const input110Ref = useRef(null);
    const input111Ref = useRef(null);
    const input112Ref = useRef(null);
    const input113Ref = useRef(null);
    const input114Ref = useRef(null);
    const input115Ref = useRef(null);
    const input116Ref = useRef(null);
    const input117Ref = useRef(null);
    const input118Ref = useRef(null);
    const input119Ref = useRef(null);
    const input120Ref = useRef(null);
    const input121Ref = useRef(null);
    const input122Ref = useRef(null);
    const input123Ref = useRef(null);
    const input124Ref = useRef(null);


    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };


    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (index !== 5) {
            var firstTabElement = document.getElementById('firstTab');
            firstTabElement.classList.remove('active1');
            console.log(index);
        }
    };

    //  /* *******************Tab****************** */

    const [tabcontent1, setTabContent1] = useState(true);
    const [tabcontent2, setTabContent2] = useState(false);
    const [tabcontent3, setTabContent3] = useState(false);
    const [tabcontent4, setTabContent4] = useState(false);
    const [, setTabContent5] = useState(false);
    const [tabcontent6, setTabContent6] = useState(false);


    const showtabs1 = () => {
        setTabContent1(true)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs2 = () => {
        setTabContent1(false)
        setTabContent2(true)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs3 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(true)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }
    const showtabs4 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(true)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs6 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(true)
    }

    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Eye Check Up</h2>
                            </div >
                            {/* *******************Top Buttons****************** */}
                            <MainButton />
                        </div>
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">   {/*height25vh*/}
                                                <div className="company-info-top-left Purchase-invoice-table content-eyecheck-height">
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", margin: "1%" }}>
                                                                    <select className="form-control inputEffect modify_im_input input-s1  mt-1" ref={input1Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)} style={{ width: "20%" }}>
                                                                        <option>Mr.</option>
                                                                        <option>Mrs.</option>
                                                                        <option>Ms.</option>
                                                                    </select>

                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" id="PartyInput" style={{ margin: "0px 5px", width: "100%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit  input-s1 " ref={input2Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} id="PartySelectBox" required style={{ fontSize: "14px", width: "99%" }} />
                                                                        <label id="input1" className="labelEffect1">Customer Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", marginTop: "8px" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input3Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Address</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input4Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="text" required />
                                                                        <label id="input1" className="labelEffect1">Contact No</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input5Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom stock-margin-top" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input6Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date Of Birth &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input7Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }} required />

                                                                        <label id="input1" className="labelEffect1">Age</label>
                                                                    </div>

                                                                </div>

                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input8Ref}
                                                                            onKeyDown={(event) => handleKeyPress(event, input9Ref)} style={{ fontSize: "14px" }} required>
                                                                            <option>--Select--</option>
                                                                            <option>Male</option>
                                                                            <option>Female</option>
                                                                            <option>Other</option>

                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Gender</label>
                                                                    </div>
                                                                    {(() => {
                                                                        var check = tabcontent1 ? input10Ref : input11Ref;
                                                                        return (
                                                                            <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                                <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 " ref={input9Ref}
                                                                                    onKeyDown={(event) => handleKeyPress(event, check)} style={{ fontSize: "14px" }} required>
                                                                                    <option>Register</option>
                                                                                    <option>Check Up </option>
                                                                                    <option>Complete</option>
                                                                                </select>
                                                                                <label id="input1" className="labelEffect1">Status</label>
                                                                            </div>
                                                                        )
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <PageTabs />
                                                    </div>
                                                </div>
                                            </div>


                                            {/**************************Lens Part ***************************/}
                                            <div className="table-width-eye" style={{ backgroundColor: "white", borderLeft: "1px solid lightgrey" }} >
                                                <div className="tabs-main" >
                                                    <div id="tabs " className="si-size-Eye ">
                                                        <div className="tab-btn-box1 tab-Height" style={{ paddingBottom: "25px", paddingTop: "0px", backgroundColor: "#e1effd" }}>
                                                            <div id="firstTab" className={`tab-Lens tab-1 tab-eye active-eye ${activeTab === 5 ? 'active-eye' : ''}`}
                                                                onClick={() => { handleTabClick(5); showtabs1() }} > <h6 className="mt-1 fw-600 fw-12">Lens</h6>
                                                            </div>
                                                            <div className={`tab-Lens tab-1 tab-eye ${activeTab === 1 ? 'active-eye' : ''}`}
                                                                onClick={() => { handleTabClick(1); showtabs2() }} > <h6 className="mt-1 fw-600 fw-12">Cont</h6>
                                                            </div>
                                                            <div className={`tab-Lens tab-1 tab-eye ${activeTab === 2 ? 'active-eye' : ''}`}
                                                                onClick={() => { handleTabClick(2); showtabs3() }} > <h6 className="mt-1 fw-600 fw-12">Trans</h6>
                                                            </div>
                                                            <div className={`tab-Lens tab-1 tab-eye ${activeTab === 3 ? 'active-eye' : ''}`}
                                                                onClick={() => { handleTabClick(3); showtabs4() }} > <h6 className="mt-1 fw-600 fw-12">AR</h6>
                                                            </div>
                                                            <div className={`tab-Lens tab-1 tab-eye ${activeTab === 6 ? 'active-eye' : ''}`}
                                                                onClick={() => { handleTabClick(6); showtabs6() }} > <h6 className="mt-1 fw-600 fw-12">Im</h6>
                                                            </div>
                                                        </div>

                                                        <div className="tab-content-box1 col-s5" >
                                                            {tabcontent1 && (
                                                                <div className="Flex-Lens-Display">
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/******************* Right Format ********************/}
                                                                                    <div id="LensFormat1" style={{ display: "block" }}>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 col-eye-check">RIGHT</label>
                                                                                                    <label className="col-sm-3 col-eye-check ">SPH</label>
                                                                                                    <label className="col-sm-3 col-eye-check ">CYL</label>
                                                                                                    <label className="col-sm-3 col-eye-check ">AXIS</label>
                                                                                                    <label className="col-sm-3 col-eye-check " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3">D.V.</label>
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input10Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input11Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input11Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input12Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input13Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input13Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input14Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input15Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input16Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input16Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input17Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input17Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input18Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input18Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input19Ref)} type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2 DV-input-Eye">P.B</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input19Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input20Ref)} type="text" required />
                                                                                                    {(() => {
                                                                                                        var Val2 = tabcontent2 ? input22Ref : input21Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input20Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Val2)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/******************* Second Format ********************/}
                                                                                    <div id="LensFormat2" style={{ display: "none" }}>
                                                                                        <div className="input-box-SI-2" >
                                                                                            <div className="input-box-m6">
                                                                                                <div className=" space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>&nbsp;SPH</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>CYL</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>AXIS</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>V/N</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>Add</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2" style={{ width: "100%" }}>
                                                                                            <div className="input-box-m6">
                                                                                                <div className="space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    <label className="col-sm-3 "> R</label>
                                                                                                    <input className=" col-sm-3 table-input table-input1 box-size1 m-r-2" ref={input21Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input22Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input table-input1 box-size1 m-r-2" ref={input22Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input23Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input table-input1 box-size1 m-r-2" ref={input23Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input24Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input table-input1 box-size1 m-r-2" ref={input24Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input25Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input table-input1 box-size1 m-r-2" ref={input25Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input26Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2" >
                                                                                            <div className="input-box-m6">
                                                                                                <div className="space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    <label className="col-sm-3 "> L</label>
                                                                                                    <input className=" col-sm-4 table-input table-input1 box-size1 m-r-2" ref={input26Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input27Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input table-input1 box-size1 m-r-2" ref={input27Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input28Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input table-input1 box-size1 m-r-2" ref={input28Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input29Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input table-input1 box-size1 m-r-2" ref={input29Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input30Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input table-input1 box-size1 m-r-2" ref={input30Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input31Ref)} style={{ width: "16.5%" }} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/******************* LEFT Format ********************/}
                                                                                    <div id="LensFormat1" style={{ display: "block" }}>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 col-eye-check">LEFT</label>
                                                                                                    <label className="col-sm-3 col-eye-check">SPH</label>
                                                                                                    <label className="col-sm-3 col-eye-check">CYL</label>
                                                                                                    <label className="col-sm-3 col-eye-check">AXIS</label>
                                                                                                    <label className="col-sm-3 col-eye-check">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input21Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input22Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input22Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input23Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input23Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input24Ref)} type="text" required />
                                                                                                    <input className=" col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input24Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input25Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input25Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input26Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input26Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input27Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input27Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input28Ref)} type="text" required />
                                                                                                    <input className=" col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input28Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input29Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input29Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2 DV-input-Eye">P.B</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input30Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" required />
                                                                                                    {(() => {
                                                                                                        var Animal = tabcontent2 ? input32Ref : input114Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input31Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Animal)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/******************* Second Format ********************/}
                                                                                    <div id="LensFormat2" style={{ display: "none" }}>


                                                                                    </div>
                                                                                    {/********************/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/********************************* {For Cont} *************************/}
                                                            {tabcontent2 && (
                                                                <div className="Flex-Lens-Display">
                                                                    <div className="tab-content-2">
                                                                        <div className="">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        {/******************* RIGHT Format ********************/}
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 col-eye-check">RIGHT</label>
                                                                                                    <label className="col-sm-3 col-eye-check">SPH</label>
                                                                                                    <label className="col-sm-3 col-eye-check">CYL</label>
                                                                                                    <label className="col-sm-3 col-eye-check">AXIS</label>
                                                                                                    <label className="col-sm-3 col-eye-check" style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input10Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input33Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input33Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input34Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input34Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input35Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 DV-input-Eye" ref={input35Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input36Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input37Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input37Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input38Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input38Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input39Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input39Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input40Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input40Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input41Ref)} type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2 DV-input-Eye">B&D</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input41Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input42Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input42Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input43Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3">REM.</label>
                                                                                                    {(() => {
                                                                                                        var Check = tabcontent2 ? input44Ref : input46Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-4 table-input box-size1 m-r-2 DV-input-Eye" ref={input43Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-2">
                                                                        <div className="">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        {/******************* LEFT Format ********************/}
                                                                                        <div className="input-box-SI-2  ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 col-eye-check">LEFT</label>
                                                                                                    <label className="col-sm-3 col-eye-check">SPH</label>
                                                                                                    <label className="col-sm-3 col-eye-check">CYL</label>
                                                                                                    <label className="col-sm-3 col-eye-check">AXIS</label>
                                                                                                    <label className="col-sm-3 col-eye-check">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input44Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input45Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input45Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input47Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input47Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input48Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input48Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input49Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input49Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input50Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input50Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input51Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input51Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input52Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input52Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input53Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input53Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input54Ref)} type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2 col-eye-check">B&D</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input54Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input55Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input55Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input56Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3">REM.</label>
                                                                                                    {(() => {
                                                                                                        var check = tabcontent3 ? input57Ref : input114Ref;
                                                                                                        return (

                                                                                                            <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input56Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/************************************ For Trans *********************************/}
                                                            {tabcontent3 && (
                                                                <div className="Flex-Lens-Display">
                                                                    <div className="tab-content-3">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/******************* RIGHT Format ********************/}
                                                                                    <div className="input-box-SI-2">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 DV-input-Eye">RIGHT</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                <label className="col-sm-3 DV-input-Eye" style={{ marginLeft: "0px" }} >V/N</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">D.V.</label>
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input57Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input58Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input58Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input59Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input59Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input60Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input60Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input61Ref)} type="text" required />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">N.V.</label>
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input61Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input62Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input62Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input63Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input63Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input64Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input64Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input65Ref)} type="text" required />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2 row-m1">
                                                                                                <label className="col-sm-3">ADD &nbsp;</label>
                                                                                                {(() => {
                                                                                                    var check = tabcontent3 ? input66Ref : input68Ref;
                                                                                                    return (
                                                                                                        <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input65Ref}
                                                                                                            onKeyDown={(event) => handleKeyPress(event, check)} type="text" required />
                                                                                                    )
                                                                                                })()}
                                                                                                <div className="col-sm-3"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-3">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/******************* LEFT Format ********************/}
                                                                                    <div className="input-box-SI-2">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 DV-input-Eye">LEFT</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                <label className="col-sm-3 DV-input-Eye">V/N</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">D.V.</label>
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input66Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input67Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input67Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input68Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input68Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input69Ref)} type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input69Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input71Ref)} type="text" required />

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">N.V.</label>
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input71Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input72Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input72Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input73Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input73Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input74Ref)} type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input74Ref}
                                                                                                    onKeyDown={(event) => handleKeyPress(event, input75Ref)} type="text" required />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2 row-m1">
                                                                                                <label className="col-sm-3">ADD &nbsp;</label>
                                                                                                {(() => {
                                                                                                    var Check = tabcontent4 ? input76Ref : input114Ref;
                                                                                                    return (
                                                                                                        <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input75Ref}
                                                                                                            onKeyDown={(event) => handleKeyPress(event, Check)} type="text" required />
                                                                                                    )
                                                                                                })()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/*************************************** AR *******************************************/}
                                                            {tabcontent4 && (
                                                                <div className="Flex-Lens-Display">
                                                                    <div className="tab-content-4">
                                                                        <div className="">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        {/******************* RIGHT Format ********************/}
                                                                                        <div className="input-box-SI-2">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 DV-input-Eye">RIGHT</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye" style={{ marginLeft: "0px" }} >V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input76Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input77Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input77Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input78Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input78Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input79Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input79Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input80Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input80Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input81Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input81Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input82Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input82Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input83Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input83Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input84Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD &nbsp;</label>
                                                                                                    {(() => {
                                                                                                        var Check = tabcontent4 ? input86Ref : input114Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input84Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-4">
                                                                        <div className="">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        {/******************* LEFT Format ********************/}
                                                                                        <div className="input-box-SI-2">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 DV-input-Eye">LEFT</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input86Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input87Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input87Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input88Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input88Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input89Ref)} type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input89Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input90Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input90Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input91Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input91Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input92Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input92Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input93Ref)} type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input93Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input94Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD &nbsp;</label>
                                                                                                    {(() => {
                                                                                                        var Check = tabcontent6 ? input95Ref : input114Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input94Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}


                                                            {/************************************* {Im }**********************************/}
                                                            {tabcontent6 && (
                                                                <div className="Flex-Lens-Display">
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/**************************************/}
                                                                                    <div>
                                                                                        {/******************* RIGHT Format ********************/}
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 DV-input-Eye">RIGHT</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye" style={{ marginLeft: "0px" }} >V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input95Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input96Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input96Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input97Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input97Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input98Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input98Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input100Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input100Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input101Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input101Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input102Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input102Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input103Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input103Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input104Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD &nbsp;</label>
                                                                                                    {(() => {
                                                                                                        var check = tabcontent4 ? input105Ref : input114Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-3 table-input box-size1 col-eye-check" ref={input104Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    {/**************************************/}
                                                                                    <div>
                                                                                        {/******************* LEFT Format ********************/}
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 DV-input-Eye">LEFT</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">SPH</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">CYL</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">AXIS</label>
                                                                                                    <label className="col-sm-3 DV-input-Eye">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input105Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input106Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input106Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input107Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input107Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input108Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2 col-eye-check" ref={input108Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input109Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input109Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input110Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input110Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input111Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input111Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input112Ref)} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2 col-eye-check" ref={input112Ref}
                                                                                                        onKeyDown={(event) => handleKeyPress(event, input113Ref)} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">ADD &nbsp;</label>
                                                                                                    {(() => {
                                                                                                        var Check = tabcontent4 ? input114Ref : input115Ref;
                                                                                                        return (
                                                                                                            <input className="col-sm-3 table-input box-size1 col-eye-check" ref={input113Ref}
                                                                                                                onKeyDown={(event) => handleKeyPress(event, Check)} type="text" required />
                                                                                                        )
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="tab-head-side-right">
                                                    <div className="">
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="company-info-top-left Purchase-invoice-table content-eyecheck-height">
                                                <div className="">
                                                    <div className="input-box-head">
                                                        {/*************************** Bill Data ***************/}
                                                        <div className="mt-1 pur-body-width">

                                                            <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", marginTop: "8px" }}>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input114Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input115Ref)} type="text" required />
                                                                    <label id="input1" className="labelEffect1">PD RL</label>
                                                                </div>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input115Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input116Ref)} type="text" required />
                                                                    <label id="input1" className="labelEffect1">PD R</label>
                                                                </div>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input116Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input117Ref)} type="text" required />
                                                                    <label id="input1" className="labelEffect1">PD L</label>
                                                                </div>
                                                            </div>
                                                            <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input117Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input118Ref)} type="text" required />
                                                                    <label id="input1" className="labelEffect1">Ref By</label>
                                                                </div>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input118Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input119Ref)} type="text" required />
                                                                    <label id="input1" className="labelEffect1">Lens Type</label>
                                                                </div>
                                                            </div>
                                                            <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", margin: "1%" }}>
                                                                <div className="input-containerEffect input-pur-invoice" id="PartyInput" style={{ width: "99%" }}>
                                                                    <textarea className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input119Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input120Ref)} id="PartySelectBox" required style={{ fontSize: "14px", width: "99%" }}>
                                                                    </textarea>
                                                                    <label id="input1" className="labelEffect1">Remarks</label>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/*************************** Party Details ***************/}
                                                        <div className="mt-1 pur-body-width">

                                                            <div className="input-box-SI input-height2 input-stock-bottom stock-margin-top" style={{ width: "100%" }}>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input120Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input121Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                    <label id="input1" className="labelEffect1">Next Visit &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                </div>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input121Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input122Ref)} type="date" required style={{ fontSize: "12px" }} />
                                                                    <label id="input1" className="labelEffect1">Lens Exp Rem &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                </div>
                                                            </div>

                                                            <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input122Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input123Ref)} style={{ fontSize: "14px" }} required />



                                                                    <label id="input1" className="labelEffect1">Delivery In(Days)</label>
                                                                </div>
                                                                <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                    <input id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" ref={input123Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, input124Ref)} style={{ fontSize: "14px" }} type="time" required />

                                                                    <label id="input1" className="labelEffect1">Time</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/************************************************************************/}
                                            <div className="display-flex-eyecheck">
                                                <div className="input-box-Imo1 input-heightAddItem StockOut_Copy Print-width-DV">
                                                    <div className="AddNewItem_Input_Box check_box_b1 ">
                                                        <label className="modify_AddNewItem_checkbox particular_font">Select For Print</label>
                                                    </div>
                                                </div>

                                                <div id="tabs si-size-2" className="si-size-2 Trans-Margin-Top">
                                                    <div className="tab-btn-box2 tab-b2 NV-CL-Width" style={{ paddingBottom: "25px", paddingTop: "0px", backgroundColor: "white" }}>
                                                        <div className="radio-btn-1" style={{ width: "100%" }}>
                                                            <div className="flex REye" >
                                                                <input type="radio" id="All" className="radio-box-1 box-size1" name="options" value="All" />
                                                                <label For="All" style={{ fontWeight: "600" }}>&nbsp; All</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="radio" id="DV" className="radio-box-1 box-size1" name="options" value="DV" />
                                                                <label For="DV" style={{ fontWeight: "600" }}>&nbsp; DV</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="radio" className="radio-box-1 box-size1" id="NV" name="options" value="NV" />
                                                                <label For="NV" style={{ fontWeight: "600" }}>&nbsp; NV</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="radio" className="radio-box-1 box-size1" id="CL" name="options" value="CL" />
                                                                <label For="CL" style={{ fontWeight: "600" }}>&nbsp; CL</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="radio" className="radio-box-1 box-size1" id="Trans" name="options" value="Trans" />
                                                                <label For="Trans" style={{ fontWeight: "600" }}>&nbsp; Trans</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="checkbox" className="radio-box-1 box-size1" id="R" value="R" style={{ height: "25px" }} />
                                                                <label For="R" style={{ fontWeight: "600" }}>&nbsp; R EYE</label>
                                                            </div>
                                                            <div className="flex REye">
                                                                <input type="checkbox" className="radio-box-1 box-size1" id="L" value="L" style={{ height: "25px" }} />
                                                                <label className="padding-02" For="L" style={{ fontWeight: "600" }}>&nbsp; L EYE</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="input-box-EyeCheckup ">
                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                        <button className="btn-lavender copy_trasn_btn" onClick={showModalPrev} style={{ width: "auto", padding: "5px" }}>Prev List</button>
                                                    </div>
                                                </div>
                                                {/*********************** Order List ********************/}
                                                {isModalOpenPrev && (
                                                    <div className="modal" >
                                                        <div className="AddNewItem-modal modal_level_ordlist">
                                                            <div className="modal-title">
                                                                <h3 className="modal-OrderLIst">Customer Eye Check Up Details</h3>
                                                                <span className="close_modal" onClick={hideModalPrev}>
                                                                    &times;
                                                                </span>
                                                            </div>
                                                            <div className="ModalContentModalSettingP ">
                                                                <ModalEyeCheckUp />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {/****************************************************** Product Table **************************************************************************/}
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default EyeCheckUp;

