import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
import ProductList from "../../Props/ProductList";
import BillPaymentInvoice from "../../Props/BillPaymentInvoice";
import AccountMaster from '../../Master/AccountMaster/AccountMaster';
import PreviousOrder from "../../Dashboard/PreviousOrder";
import ModalOrderList from "../../ModalBox/ModalOrderList";
import MainButton from "../../Props/MainButton";

function PurchaseChallan(props) {
    /***********title***************/
    useState(() => {
        document.title = 'Purchase Challan';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (index !== 5) {
            var firstTabElement = document.getElementById('firstTab');
            firstTabElement.classList.remove('active1');
            console.log(index);
        }
    };


    //  /* *******************Tab****************** */

    const [tabcontent11, setTabContent11] = useState(true);
    const [tabcontent22, setTabContent22] = useState(false);
    //const [tabcontent33, setTabContent33] = useState(false);
    const [tabcontent44, setTabContent44] = useState(false);

    const showtabs11 = () => {
        setTabContent11(true)
        setTabContent22(false)
        //setTabContent33(false)
        setTabContent44(false)
    
    }

    const showtabs22 = () => {
        setTabContent11(false)
        setTabContent22(true)
        //setTabContent33(false)
        setTabContent44(false)
   
    }

    const showtabs44 = () => {
        setTabContent11(false)
        setTabContent22(false)
        //setTabContent33(false)
        setTabContent44(true)
        
    }

    // {/* *******************Tab****************** */}

    //************modal box************/ /

    const [isModalOpen1, setModalOpen1] = useState(false);


    const hideModal1 = () => {
        setModalOpen1(false);
    };

    // const [isModalOpen2, setModalOpen2] = useState(false);

    // const showModal2 = () => {
    //     setModalOpen2(true);
    // };

    // const hideModal2 = () => {
    //     setModalOpen2(false);
    // };
    //************modal box************/ /


    ///*****************Onboarding code Open************************///

   

    const [PartyDetailModal, setPartyDetailModal] = useState(false);

    const OpenPartyModal = () => {
        setPartyDetailModal(true);
    };

    const ClosePartyModal = () => {
        setPartyDetailModal(false);
    };

    const [EyeModal, setEyeModal] = useState(false);

    // const OpenEyeModal = () => {
    //     setEyeModal(true);
    // };

    const CloseEyeModal = () => {
        setEyeModal(false);
    };

    const handleSliderButton = (index) => {
        const val = CheckBoxData[index];
        const isSelected = selectedData.some(item => item.id === val.id);

        if (!isSelected) {
            setSelectedData(prevSelectedData => [...prevSelectedData, val]);
        } else {
            setSelectedData(prevSelectedData =>
                prevSelectedData.filter(item => item.id !== val.id)
            );
        }
        console.log(selectedData);
    };

    const handleSliderButton1 = () => {
        const title = document.getElementById('NameTitle').style.display;
        if (title === 'none') {
            document.getElementById('NameTitle').style.display = "block";
            document.getElementById('PartyInput').style.width = "80%";
        }
        else {
            document.getElementById('NameTitle').style.display = "none";
            document.getElementById('PartyInput').style.width = "100%";
        }
    };
    const handleSliderButton2 = (val) => {
        document.getElementById('saleRemarkPart').style.display = "block";
        const title = document.getElementById(val).style.display;
        if (title === 'none') {
            document.getElementById(val).style.display = "block";
        }
        else {
            document.getElementById(val).style.display = "none";
        }
    };

    const [CheckBoxData] = useState([
        {
            id: 3,
            name: 'Date of birth',
            type: "date",
            idName: "DateOfBirth",
        },
        {
            id: 4,
            name: 'Anniversary Date',
            type: "date",
            idName: "AnniversaryDate",
        },
        {
            id: 6,
            name: 'POS',
            type: "text",
            idName: "POS",
        },
        {
            id: 7,
            name: 'A/c Bal.',
            type: "text",
            idName: "AccBalance",
        },
        {
            id: 8,
            name: 'Credit Limit',
            type: "text",
            idName: "CreditLimit",
        },
        {
            id: 9,
            name: 'Referred By',
            type: "text",
            idName: "ReferredBy",
        },
    ])
    const [selectedData, setSelectedData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        console.log(event.target.value)
        if (event.target.value === "Format1") {
            document.getElementById('LensFormat1').style.display = "block";
            document.getElementById('LensFormat2').style.display = "none";
        }
        else if (event.target.value === "Format2") {
            document.getElementById('LensFormat1').style.display = "none";
            document.getElementById('LensFormat2').style.display = "block";
        }
    };
    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [couponCode, setCouponCode] = useState('');

    const handleInputChange = (e) => {
        setCouponCode(e.target.value);
    };


    const [CouponModal, setCouponModal] = useState(false)

    const openCouponModal = () => {
        setCouponModal(true)
    }
    const closeCouponModal = () => {
        setCouponModal(false)
    }


    const ApplyTaxClick = () => {
        const val = document.getElementById('BillType').value;
        console.log(val);
        //  deleteTaxRow(1);
        var newRows;
        if (val === "12% GST(L)") {
            newRows = [
                { id: 1, TaxName: "CGST", Percent: "6%", Amount: "500" },
                { id: 2, TaxName: "SGST", Percent: "6%", Amount: "500" }
            ];
            setTaxRows([...newRows]);
        }
        else if (val === "12% GST(I)") {
            newRows =
                [{ id: 1, TaxName: "IGST", Percent: "12%", Amount: "1000" }];
            setTaxRows([...newRows]);
        }
    }

    const [taxRows, setTaxRows] = useState([{ id: 1, TaxName: "", Percent: "", Amount: "" }]);

    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: taxRows.length + 1, content: '' };

            setTaxRows([...taxRows, newRow]);

            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);

            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    const handleCellChange = (event, rowIndex, colIndex) => {
        const updatedRows = [...taxRows];
        updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            content: event.target.value,
        };
        setTaxRows(updatedRows);
    };
    const deleteTaxRow = (rowIndex) => {
        const updatedRows = taxRows.filter((_, index) => index !== rowIndex);
        setTaxRows(updatedRows);
    }

    const [AccountMasterModal, setAccountMasterModal] = useState(false)

    const openAccountMasterModal = (val) => {
        if (val === 'Add') {
            setAccountMasterModal(true)
            document.getElementById('PartySelectBox').value = "";
        }
    }
    const closeAccountMasterModal = () => {
        setAccountMasterModal(false)
    }

    const options = ['Aadhya', 'Sriti'];

    // }
    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        {/* <TopNamePage heading="ModifyProduct"/> */}
                        <div>
                           
                           
                        </div>

                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Purchase Challan</h2>
                            </div >
                           

                            {/* *******************Top Buttons****************** */}
                            <MainButton
                             showModal={OpenPartyModal}
                             isBtnSetting={true} />
                            {/* <div className="panel-head-button">
                                <div className="col_1">
                                    <div>
                                        <div className="button-m5">
                                            <button id="SaveBtnMain"
                                                className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                                onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                                Save
                                                <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                                {isHovered && (
                                                    <div className="hover-textSave">
                                                        <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                                        Alt + Enter
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                   
                                    <div className="button-m5">
                                        <button onClick={showModal1} className="save-button-main">Order
                                            <i id="step-2" className="fa-solid fa-clipboard-list im-icon-2" ></i>
                                        </button>
                                    </div>
                                   
                                    <div className="button-m5">
                                        <button className="save-button-main">Reset
                                            <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                                        </button>
                                    </div>
                                    <div onClick={OpenPartyModal} className="button-m5">
                                        <button className="save-button-main">Setting
                                            <i id="step-2" className="fa-solid fa-gear im-icon-2" onClick={OpenPartyModal}></i>
                                        </button>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        {/* *******************Modal Box End****************** */}
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">   {/*height25vh*/}
                                                <div className="company-info-top-left Purchase-invoice-table " style={{ padding: "5px" }}>
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required /> */}
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{ fontSize: "14px" }} required>
                                                                            <option>Select</option>
                                                                            <option>12% GST(L)</option>
                                                                            <option>12% GST(I)</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Bill Types</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Pur Bill No</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Pur Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" id="Godown" style={{ margin: "1%", display: "none" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Godown</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" id="BookedBy" style={{ margin: "1%", display: "none" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Booked By</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%", justifyContent: "center" }}>
                                                                    <div className="input-containerEffect mt-1 " style={{ margin: "1%" }}>
                                                                        {/* <Link to="/PreviousOrder"> */}
                                                                        {/* <button className="btn-lavender" onClick={openPreviousOrderModal}>Previous Orders</button> */}
                                                                        {/* </Link> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <select className="form-control nameTitle" id="NameTitle" style={{ display: "none", width: "20%" }}>
                                                                        <option>Mr.</option>
                                                                        <option>Mrs.</option>
                                                                        <option>Ms.</option>
                                                                    </select>

                                                                    <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" onDoubleClick={openAccountMasterModal} style={{}} type="text" required /> */}
                                                                        <select className="form-control inputEffect modify_im_input mode_limit mode_-category input-s1" id="PartySelectBox" required onChange={(e) => openAccountMasterModal(e.target.value)} onDoubleClick={() => openAccountMasterModal("Add")} style={{ fontSize: "14px" }}>
                                                                            {options.map((option, index) => (
                                                                                <option key={index} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                            <option value="Add" className="optionBtn" ><button className="" style={{ textAlign: "center" }}>Add New</button></option>
                                                                        </select>
                                                                        {/* <select className="form-control inputEffect modify_im_input mode_limit mode_-category input-s1" required>
                                                                            <option>Aadhya</option>
                                                                            <option>Sriti</option>
                                                                            <option><button onDoubleClick={openAccountMasterModal}>Add New</button></option>
                                                                        </select> */}
                                                                        <label id="input1" className="labelEffect1">Part<span style={{ color: "red" }}>y</span> A/c</label>
                                                                    </div>
                                                                    <div onClick={toggleSlider} className="btn-lavender mt-1">
                                                                        <i className="fa-solid fa-right-from-bracket" onClick={toggleSlider}></i>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Address</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Mobile</label>
                                                                    </div>
                                                                </div>
                                                                {/***********/}
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="addedGridView Right-Pur-Flex" style={{ marginTop: "-5px" }}>
                                                                        {(() => {
                                                                            if (selectedData.length != null) {
                                                                                //console.log(selectedData);
                                                                                return (
                                                                                    selectedData.map((data) => (
                                                                                        <div className="input-containerEffect mt-2 input-pur-invoice" style={{ margin: "1%" }} key={data.id}>
                                                                                            <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" style={{ width: "100%" }} required />
                                                                                            <label id="input1" className="labelEffect1">{data.name}</label>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            return console.log("not found");;
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        {/*********************** Order List ********************/}
                                                        {isModalOpen1 && (
                                                             <div className="modal" >
                                                             <div className="AddNewItem-modal modal_level_ordlist">
                                                                 <div className="modal-title">
                                                                     <h3 className="modal-OrderLIst">Order List</h3>
                                                                     <span className="close_modal" onClick={hideModal1}>
                                                                         &times;
                                                                     </span>
                                                                 </div>
                                                                 <div className="ModalContentModalSettingP ">
                                                                     <ModalOrderList />
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}

                                                        <PageTabs />

                                                        {/*********************** Challan List ********************/}
                                                        {/* {isModalOpen2 && (
                                                            <div className="modal" >
                                                                <div className="AddNewItem-modal modal_level_ordlist">
                                                                    <div className="modal-title">
                                                                        <h3 className="modal-OrderLIst">Challan List</h3>
                                                                        <span className="close_modal" onClick={hideModal2}>
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                    <div className="ModalContentModalSettingP ">
                                                                        <ModalChallanList />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/****************************************************** Product Table **************************************************************************/}
                                            <div /*className="height30vh"*/ >
                                                <ProductList />
                                            </div>
                                            {/******************************************************* Total Details *************************************************************************/}
                                            <div className="tabs-main1 height30vh">
                                                <div id="tabs" className="tab_a1">
                                                    <div className="tab-btn-box1 tab-b3 bg-color" style={{ backgroundColor: "#5e709e" }}>
                                                        <div id="firstTab"
                                                            className={`tab-b1 tab-2 active11 ${activeTab === 11 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(11); showtabs11() }} >
                                                            <label className="c-w col-black">Sundry</label>
                                                        </div>
                                                        <div
                                                            className={`tab-b1 tab-2  ${activeTab === 22 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(22); showtabs22() }} >
                                                            <label className="c-w col-black">GST</label>
                                                        </div>
                                                        {/*<div*/}
                                                        {/*    className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}*/}
                                                        {/*    onClick={() => { handleTabClick(33); showtabs33() }} >*/}
                                                        {/*    <label className="c-white col-black">Coupon</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className={`tab-b1 tab-2  ${activeTab === 44 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(44); showtabs44() }} >
                                                            <label className="c-w col-black">Loyality</label>
                                                        </div>
                                                    </div>

                                                    <div className="tab-content-box1 ">
                                                        {/*************** Sundry *****************************/}
                                                        {tabcontent11 && (
                                                            <div className="tab-content-1">
                                                                <div className="input-box-top-head" >
                                                                    <div className="">
                                                                        <div>
                                                                            <table className="table-scrol1">
                                                                                <thead className="table-tbody" style={{ backgroundColor: "#e9e5e5" }}>
                                                                                    <tr className="table-tr1">
                                                                                        <th className="table-th-1">SN</th>
                                                                                        <th className=" td-col2">Taxes & Othr Charge(Sundry)</th>
                                                                                        <th className="table-th-6">@%</th>
                                                                                        <th className="table-th-5">Amount</th>
                                                                                        <th className="table-th-4">Action</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody className=" table-container-tbody" style={{ height: "auto", maxHeight: "155px", overflowY: "scroll" }} >
                                                                                    {taxRows.map((row, rowIndex) => (
                                                                                        <tr className="table-tr1" key={rowIndex} >
                                                                                            <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                                                {rowIndex + 1}
                                                                                            </td>
                                                                                            <td className="td-col2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td1"
                                                                                                    type="text"
                                                                                                    name={row.TaxName}
                                                                                                    value={row.TaxName}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td2"
                                                                                                    type="text"
                                                                                                    name={row.Percent}
                                                                                                    value={row.Percent}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td2"
                                                                                                    type="text"
                                                                                                    name={row.Amount}
                                                                                                    value={row.Amount}
                                                                                                    onChange={(e) => handleCellChange(e, rowIndex, 0)}
                                                                                                    onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2" style={{ textAlign: "center" }}>
                                                                                                <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/*************** GST *****************************/}
                                                        {tabcontent22 && (
                                                            <div className="tab-content-1 height-115">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left1">
                                                                        <div className="input-box-head-1">
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m7">
                                                                                    <div className="space-between">
                                                                                        <div className="button-col-s1 bill-item-btn">
                                                                                            <button type="button" className="button-col-s2">Bill Wise</button>
                                                                                        </div>
                                                                                        <div className="button-col-s1 bill-item-btn">
                                                                                            <button type="button" className="button-col-s2">Item Wise</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s1 SaleTaxBtn"></span>
                                                                                        <label>Tax not calculated</label>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s4 SaleTaxBtn"></span>
                                                                                        <label>Tax not apply</label>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s3 SaleTaxBtn"></span>
                                                                                        <label>Tax value not apply</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/************************* Loyality ***********************/}
                                                        {tabcontent44 && (
                                                            <div className="tab-content-3">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left2">
                                                                        <div className="input-box-head-1">
                                                                            <table style={{ width: "100%" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >S.No.</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >Item Details</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >Loyalty Point</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }}>Loyalty Amount</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="td">1</td>
                                                                                        <td className="td">Lens</td>
                                                                                        <td className="td">50</td>
                                                                                        <td className="td">50</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="space-between mt-1" style={{ padding: "5px" }}>
                                                            <button onClick={ApplyTaxClick}>Apply Tax</button>
                                                            <label>Total Sundry Amt. 0.00</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/***************************** Bill Payment Invoice **************************/}
                                                <div className="table-row1">
                                                    {/*<div className="col_58" style={{ backgroundColor:"#98a1b7", color:"black", borderLeft:"1px solid black"} }>Bill Payment Details</div>*/}
                                                    <div>
                                                        <BillPaymentInvoice />
                                                    </div>

                                                </div>
                                                {/***************************** Total **************************/}
                                                <div className="table-row2">
                                                    <span className="table-ax-1"></span>
                                                    <div className="padding-20 totalBg-color" style={{ backgroundColor: "rgba(94, 112, 158, 0.12)", height: "100%" }}>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Loyality</label>
                                                            <div className="flex-end" style={{ width: "50%" }}>
                                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                                    <input type="text" placeholder="" className=" modify_im_input12 mode_limit mode_category" />
                                                                    <button className=" apply-button input-image" >Use</button> {/*onClick={handleApplyCoupon}*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Coupon</label>
                                                            <div className="flex-end" style={{ width: "50%" }}>
                                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                                    <input type="text" value={couponCode} placeholder="Enter Coupon" onChange={handleInputChange} className=" modify_im_input12 mode_limit mode_category" />
                                                                    <button className=" apply-button input-image" onClick={openCouponModal}>View</button> {/*onClick={handleApplyCoupon}*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Taxable Value</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Gross Value</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="saleRemarkPart" style={{ display: "none" }}>
                                                <div className="space-evenly saleRemarkPart">
                                                    <div className="input-containerEffect mt-1" id="Remark" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="DeliveryDate" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ padding: "0px 7px" }} />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Delivery Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Status" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 width-100 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="Pending">Pending</option>
                                                            <option Classname="form-control s-1" value="Approved">Approved</option>
                                                            <option Classname="form-control s-1" value="Cancel">Cancel</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Challan">Conv. to Pur Challan</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Puechase">Conv. to Pur Puechase</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Sale">Conv. to Pur Sale</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Sale_Challan">Conv. to Pur Sale Challan</option>
                                                            <option Classname="form-control s-1" value="Delay">Delay</option>
                                                            <option Classname="form-control s-1" value="Delivered">Delivered</option>
                                                            <option Classname="form-control s-1" value="Done">Done</option>
                                                            <option Classname="form-control s-1" value="Done_with_Verified">Done with Verified</option>
                                                            <option Classname="form-control s-1" value="Exchange_In">Exchange In</option>
                                                            <option Classname="form-control s-1" value="Exchange_Out">Exchange Out</option>
                                                            <option Classname="form-control s-1" value="On_Process">On Process</option>
                                                            <option Classname="form-control s-1" value="Put_of_Shippment">Put of Shippment</option>
                                                            <option Classname="form-control s-1" value="Processing">Processing</option>
                                                            <option Classname="form-control s-1" value="Ready">Ready</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Status</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark1" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="AN">Special Customer</option>
                                                            <option Classname="form-control s-1" value="AP">Test1</option>
                                                            <option Classname="form-control s-1" value="AR">Wrong Order</option>
                                                            <option Classname="form-control s-1" value="AR">Conv. to Pur Puechase</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark1 &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark2" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">TEST2</option>
                                                            <option Classname="form-control s-1" value="AN">CAMP CUSTOMER</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark2 &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark3" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="">CHARITY</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark3 &nbsp; &nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/************************* Party Detail Modal *****************************/}
            {PartyDetailModal && (
             
                <div className="modal" >
                    <div className="modal-content2">
                        <div className="modal-title">
                            <h3 className="modal-h">Invoice Setting</h3>
                            <span className="close" onClick={ClosePartyModal}>
                                &times;
                            </span>
                        </div>
                        <div className="ModalBoxItem_FullHeight">
                            <div className="ModalBoxItem_Left"  >
                                <div className="modal-content-im ">
                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton1("Mx")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Mx<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    {CheckBoxData.map((data, index) => (
                                        <div className="input-box-m1 modal-2" >
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch" key={data.id}>
                                                    <input type="checkbox" onChange={() => handleSliderButton(index)} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">{data.name}</label>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="ModalBoxItem_Right">
                                <div className="modal-content-im ">


                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("BookedBy")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Booked By<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Status")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Delivery Status<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("DeliveryDate")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Delivery Date<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark1")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark1<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark2")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark2<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark3")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark3<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/**************************** Eye/Lens Modal ********************/}
            {EyeModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", width: "22%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label>Select the format</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={CloseEyeModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ display: "grid", justifyContent: "center" }}>
                                <label className="radio-label">
                                    <input type="radio" value="Format1" checked={selectedOption === 'Format1'}
                                        onChange={handleOptionChange} className="large-radio" />
                                    &nbsp; With NV
                                </label><br /><hr style={{ color: "black" }} />

                                <label className="radio-label">
                                    <input type="radio" value="Format2" checked={selectedOption === 'Format2'}
                                        onChange={handleOptionChange} className="large-radio" />
                                    &nbsp; Without NV
                                </label>
                            </div>
                            <hr className="mt-2" />
                            <div className="flex-end">
                                <button className="close-btn1" onClick={CloseEyeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Coupon Modal*************************/}
            {CouponModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", width: "35%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label>View Coupon</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCouponModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ margin: "10px", padding: "10px" }}>
                                <div className="couponBox">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="couponBox mt-3">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr className="mt-2" />
                            <div className="flex-end">
                                <button className="greenBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Apply</button>
                                <button className="redBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Account Master Modal*************************/}
            {AccountMasterModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "90%", width: "90%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label></label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeAccountMasterModal}>x</label>
                            </div>
                            <AccountMaster />
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default PurchaseChallan;

