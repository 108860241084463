import React, { useState, useRef, useEffect } from "react";
import Loader from '../../Inventory/Props/Loader';
import PageTabs from "../../Inventory/Components/PageTabs";
import DateTime from "../../Inventory/Components/DateTime";
import MainButton from "../../Inventory/Props/MainButton";
import Order from "../../API/APIServices/Order";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ItemMaster from "../../API/APIServices/ItemMaster";
import ItemGroup from "../../API/APIServices/ItemGroup";

const ItemIncentiveReport = () => {
    useState(() => {
        document.title = 'Item Incentive Report';
    }, []);

    const [loading, setLoading] = useState(true);

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    }

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // ***************** Get Item Master***********************
    const [itemName, setItemName] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemMaster.GetItemMaster();
                setItemName(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    // ***************** Get Item Group***********************
    const [itemGroup, setItemGroup] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemGroup.GetItemGroup();
                setItemGroup(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    //  **********************************************
    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const date = await getCurrentDate();
            setFromDate(date)
            setToDate(date)
            const filterData = [
                {
                    fieldName: "A46",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: date
                },
                {
                    fieldName: "A47",
                    operatorName: "LessOrEqualThan",
                    compareValue: date
                },
            ]
            try {
                const data = await Order.getAllReportItemIncentiveMaster(filterData);
                setDataArray(data);
                setAddFilter(filterData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const [addFilter, setAddFilter] = useState([]);

    const handleFilterChange = async (e) => {
        const { name, value } = e.target
        let updatedFilter = [...addFilter];
        let newFilter;

        let operatorValue = "Equal"
        if (name == "A46") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
            setFromDate(value);
            operatorValue = "GreaterOrEqualThan";
        }
        else if (name == "A47") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
            setToDate(value);
            operatorValue = "LessOrEqualThan";
        }

        if (value === "default" || value === "") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            newFilter = {
                fieldName: name,
                operatorName: operatorValue,
                compareValue: value,
            };

            const existingFilter = updatedFilter.find(filter => filter.fieldName === newFilter.fieldName);

            if (existingFilter) {
                const operatorExists = updatedFilter.some(
                    filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                );

                if (operatorExists) {
                    updatedFilter = updatedFilter.map(filter =>
                        filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
                            ? { ...filter, compareValue: newFilter.compareValue }
                            : filter
                    );
                } else {
                    updatedFilter.push(newFilter);
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }
        console.log(updatedFilter);
        setAddFilter(updatedFilter);
        const data = await Order.getAllReportItemIncentiveMaster(updatedFilter)
        setDataArray(data);
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Order Item Incentive Report</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={false} saveButtonHide={true} />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "96vw" }}>

                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Order Item Incentive Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="date" required
                                                onChange={handleFilterChange} value={fromDate}
                                                name="A46" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Incentive From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required
                                                onChange={handleFilterChange} value={toDate}
                                                name="A47" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Incentive To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="IsPublished" required>
                                                <option value="default"></option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Publish Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="itmGrpId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemGroup !== null && Array.isArray(itemGroup)) {
                                                        return itemGroup.map((data) => (
                                                            <option key={data.d2_Id} value={data.d2_Id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Category Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="itemId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemName !== null && Array.isArray(itemName)) {
                                                        return itemName.map((data) => (
                                                            <option key={data.d2Id} value={data.d2Id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Item Name</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceType"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Type</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">ITEM INCENTIVE REPORT</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        <div className="table-resposive" style={{ height: "500px", overflow: "scroll" }}> {/*tb-scroll-width */}
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Qty</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Rate</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Amount</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Incentive</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray !== null && dataArray !== undefined && dataArray.length > 0 && dataArray
                                                        // .filter((item, index, self) =>
                                                        //     index === self.findIndex((t) => (
                                                        //         t.ord2Id === item.ord2Id
                                                        //     ))
                                                        // )
                                                        .map((item, index) => (
                                                            <tr key={index} className="table_tbody_Order_List">
                                                                <td className="td_col6 td_sno-btn feed-flex-edit">{index + 1}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>
                                                                    <div className="table_head_mmb1" style={{ textAlign: "center" }}>{item.itemName}</div>
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.quantity}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.rate}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.amount}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default ItemIncentiveReport