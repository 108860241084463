import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RazorpayCheckout = () => {
    const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Only load Razorpay script if it's not already loaded
        try {
            if (!window.Razorpay) {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    setIsRazorpayLoaded(true);
                };
                document.body.appendChild(script);
            } else {
                setIsRazorpayLoaded(true);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handlePayment = () => {
        if (!isRazorpayLoaded) {
            console.error('Razorpay SDK is not loaded yet.');
            return;
        }

        const options = {
            key: "rzp_test_6gzv7z3KTwC4Gp", // Replace with your key
            amount: '50000', // Amount in smallest currency unit (e.g., 50000 paise for ₹500)
            currency: 'INR',
            name: 'Your Company Name',
            description: 'Some Description',
            // Add your callback handler here
            handler: function (response) {
                alert(response.razorpay_payment_id);
            },
            prefill: {
                name: 'Customer Name',
                email: 'customer@example.com',
                contact: '9999999999'
            }
        };

        const rzp1 = new window.Razorpay(options);
        console.log(rzp1);
        rzp1.open();
    };


    // $.ajax(settings).done(function (response) {

    //     orderId="order_DBJOWzybf0sJbb";
    //     console.log(orderId);
    //     $("button").show();
    //   });

    return (
        <button onClick={handlePayment} disabled={!isRazorpayLoaded}>
            Pay with Razorpay
        </button>
    );
};

export default RazorpayCheckout;
