import React, { useState } from 'react'
import InputBox from './InputBox'
import Dropdown from './Dropdown'

const ShippingSetting = () => {

    const [inputValues, setInputValues] = useState({
        fixedFee: '0',
        freeShippingOnGreaterAmt: '0',
        freeShippingOnAmtLimit: '',
        estimatedDeliveryTime: ''
    })
    console.log(inputValues);
    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];
    return (
        <div>
            <div className='heading-setting'>Shipping Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Fixed Fee"}
                        value={inputValues.fixedFee}
                        onChange={(newvalue) => setInputValues({ ...inputValues, fixedFee: newvalue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Free Shipping On Greater Amount"}
                        value={inputValues.freeShippingOnGreaterAmt}
                        onChange={(newvalue) => setInputValues({ ...inputValues, freeShippingOnGreaterAmt: newvalue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        label={"Free Shipping on Amount Limit"}
                        options={options}
                        selectedValue={inputValues.freeShippingOnAmtLimit}
                        onChange={(newvalue) => setInputValues({ ...inputValues, freeShippingOnAmtLimit: newvalue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        label={"Estimated delivery Time"}
                        value={inputValues.estimatedDeliveryTime}
                        onChange={(newvalue) => setInputValues({ ...inputValues, estimatedDeliveryTime: newvalue })}
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default ShippingSetting
