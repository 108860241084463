import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const LeaveMaster = {

    insertLeaveMaster: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LeaveMaster/LeaveMaster`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                leaveName: data.leaveName,
                description: data.description,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getLeaveMaster: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/LeaveMaster/GetLeaveMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteLeaveMaster: async (leaveId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LeaveMaster?leaveId=${leaveId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get By Id ****************************
    getLeaveMasterById: async (leaveId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LeaveMaster/LeaveMaster?leaveId=${leaveId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateLeaveMaster: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/LeaveMaster?leaveId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                leaveName: data.leaveName,
                description: data.description,
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default LeaveMaster;
