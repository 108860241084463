import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import MyContext from "../Inventory/MyContext";

function AppHeader({ setLoading, fetchData, sidebar, title, link, bellIcon, handleBellIcon, counting }) {

    useState(() => {
        document.title = title;
    }, []);

    const navigate = useNavigate();

    const { appToWebLink, webToAppLink } = useContext(MyContext);

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        fetchData();
        setLoading(false)
    };

    const handleLogOutClick = () => {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        localStorage.clear();
        // window.location.href = "http://122.168.117.143:44483/loginpanel";
        window.location.href = `${webToAppLink}/loginpanel`;
    }
    useEffect(() => {
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            if (loginUser.loginType == 563)
                navigate(link)
            else if (loginUser.loginType == 565)
                navigate(link)
            else if (loginUser.loginType == 566)
                navigate(link)
            else {
                navigate("/LoginPanel")
                window.location.reload();
            }

        } catch (error) {
            console.log(error);
        }
    }, [])

    return (
        <div>
            <div>
                <div className="heading"
                    style={{ display: "flex" }}>
                    <i style={{ fontSize: '20px' }} className="fa-solid fa-bars icon-white" onClick={sidebar}></i>
                    <div className=""
                        onTouchStart={e => { handleTouchStart(e) }}
                        onTouchMove={e => { handleTouchMove(e) }}
                        onTouchEnd={e => { handleTouchEnd(e) }}
                        style={{ alignItems: "baseline", height: "25px", width: "100%" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <label className="head-title-ot">{title}</label>
                            <div className="space-evenly">
                                {bellIcon && bellIcon == true && (
                                    <div class="notification-container">
                                        <i class="fa-solid fa-bell head-title-ot" onClick={handleBellIcon} style={{ marginRight: "18px" }}></i>
                                        <div class="notification-badge" id="notification-badge">{counting}</div>
                                    </div>
                                )}
                                <i class="fa-solid fa-power-off head-title-ot" onClick={handleLogOutClick}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AppHeader;
