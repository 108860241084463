
import "./DeliveryStylesheet.css";
// import Loader from "./../../Inventory/Props/Loader";
// import AlertBox from "./../../Inventory/Props/AlertBox";
import MyContext from "../../Inventory/MyContext";
import { useState, useContext, useEffect, useRef } from "react";
import OrderAPI from "../../API/APIServices/Order";
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Order from "../../API/APIServices/Order";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from "../../API/APIServices/BillPaymentMode";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import Payment from "../../API/APIServices/Payment";
import axios from "axios";
import Loader from "../../Inventory/Props/Loader";
import ReportDesign from "../Orders/ReportDesign";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Orders = (props) => {
    const { OrderInfoMain, fetchData, tripNo, showCancelDelBtn, setOrderInfoMain } = props;
    const [PaymentStatusModal, setPaymentStatusModal] = useState(false);

    // console.log(OrderInfoMain)

    const closePaymentStatusModal = () => {
        setAmount((prevValues) => ({
            ...prevValues,
            "paymentAmount": 0,
            "balanceAmt": 0,
            "grandTotal": 0
        }));
        setOrderId('')
        setPaymentStatusModal(false);
        fetchData();
    }

    const [tableOrder, setTableOrder] = useState([]);
    const [paymentModeDetails, setPaymentModeDetails] = useState();
    const [paymentStatusValue, setPaymentStatusValue] = useState();
    const [payingAmount, setPayingAmount] = useState();
    const [orderId, setOrderId] = useState();

    const [amount, setAmount] = useState({
        paymentAmount: 0,
        balanceAmt: 0,
        grandTotal: 0
    })

    const handleAmountChange = (e) => {
        setPayingAmount(e.target.value);
    }
    const paymentStatusSaveBtn = async () => {
        // console.log("paymentStatusValue",paymentStatusValue);
        //alert(payingAmount);
        if (paymentStatusValue == "") {
            alert("Please select Payment Mode");
        }
        else {
            console.log(Number(amount.paymentAmount) + Number(payingAmount))
            const val = amount.grandTotal - (amount.paymentAmount + payingAmount)
            console.log(val)
            const updatedValues = [
                {
                    operationType: 0,
                    path: "PaymentModeId",
                    op: "Add",
                    from: "string",
                    value: paymentStatusValue
                },
                // {
                //     operationType: 0,
                //     path: "overallOrderStatus",
                //     op: "Add",
                //     from: "string",
                //     value: orderStatus
                // },
                {
                    operationType: 0,
                    path: "balanceAmt",
                    op: "Add",
                    from: "string",
                    value: val
                },
                {
                    operationType: 0,
                    path: "paymentAmount",
                    op: "Add",
                    from: "string",
                    value: Number(amount.paymentAmount) + Number(payingAmount)
                }
            ]
            if (val === 0) {
                updatedValues.push({
                    operationType: 0,
                    path: "paymentStatus",
                    op: "Add",
                    from: "string",
                    value: "Done"
                });
            }
            await Order.updateOrderPartially(updatedValues, orderId);

            const filterData = [{
                ord1Id: orderId,
                paymentModeId: paymentStatusValue,
                paymentAmount: payingAmount,
                //paymentAmount: amount.paymentAmount + payingAmount,
                transId: "",
                payUId: 0,
                bankRefNo: "",
                status: "",
                paymentTypeMethod: "",
            }]

            await Order.createPaymentModeWithOrder(filterData)
            closePaymentStatusModal();
            fetchData();
        }
    }

    const handlePaymentStatus = (event) => {
        const val = event.target.value;
        // if (val === "Pending") {
        //     setOrderStatus(val);
        // }
        // else if (val === "Cash") {
        //     setOrderStatus("Done");
        // }
        setPaymentStatusValue(val);
        //alert(val);
        fetchData();
    }

    useEffect(() => {
        const fetchData = async () => {
            const filteredData = [
                {
                    fieldName: "isDefault",
                    operatorName: "Equal",
                    compareValue: true
                },
                {
                    fieldName: "masterType",
                    operatorName: "Equal",
                    compareValue: 29
                }
            ]
            const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
            console.log("BillData", data);
            if (data.length > 0) {
                const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
                if (paymentMode.length > 0) {
                    console.log("paymentMode", paymentMode[0].accountId)
                    setPaymentModeDetails(paymentMode);
                    setPaymentStatusValue(paymentMode[0].accountId)
                }
            }
        }
        fetchData();
    }, [])

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const state1 = {
        id: 123,
        paymentAmt: 50,
        balanceAmt: 100,
        grandTotal: 150,
        paymentStatus: 'Paid',
        orderStatus: 'Delivered'
    };

    const mapStyles = {
        width: '100%',
        height: '100%'
    };

    const markers = [
        { lat: 37.7749, lng: -122.4194, name: 'Location 1' },
        { lat: 37.7622, lng: -122.4325, name: 'Location 2' },
        { lat: 37.7913, lng: -122.4382, name: 'Location 3' }
    ];

    const { OpenAlertBoxEdit, setAlertBoxDelete, setErrorMessages } = useContext(MyContext);

    const [loading, setLoading] = useState(false);

    const CallButtonClick = (accMobileNo) => {
        const phoneNumber = { accMobileNo };
        const message = JSON.stringify({ type: 'call', phoneNumber });
        window.ReactNativeWebView.postMessage(message);
        //alert("Click Call Button");
        // const phoneNumber = { accMobileNo };
        // window.location.href = `tel:${phoneNumber}`
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const data = await OrderAPI.getOrderList();

                const filteredData = [{
                    fieldName: "string",
                    operatorName: "string",
                    compareValue: "string"
                }]

                const orderData = await OrderAPI.getRawOrderList(filteredData, 1);
                const data = orderData?.orderList;
                setCancelled(data)
                setInputValues(data)
                console.log("Hello", OrderInfoMain);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const Cancel = (ord1Id, orderId) => {
        setAlertBoxDelete(true);
        OpenAlertBoxEdit(ord1Id, orderId);
    }
    const [orderStatus, setOrderStatus] = useState('Pending')

    const openPaymentStatusModal = async (id, paymentAmt, balanceAmt, grandTotal, orderStatus) => {
        const ord1Id = id;
        setLoading(true);
        const orderData = OrderInfoMain.find(item => item.ord1Id === ord1Id);

        if (!orderData) {
            console.error('Order not found');
            return;
        }

        let orderNo = orderData.orderNo;

        let totalAmt = orderData.confirmTotalAmt;
        if (orderData.paymentType == 2) {
            totalAmt = orderData.grandTotal;
        }
        const balAmt = Number(totalAmt - (orderData.o1CancelledAmt + orderData.paymentAmount));

        if (balAmt && balAmt != 0) {
            // ******************* Payment Gateway ********************
            const filteredData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: orderData.ord1Id,
            }]

            const dateTime = await CurrentDateAndTime.getCurrentDateTime();
            // const dateTime = date.toISOString();

            const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

            let paymentData;

            if (previousPaymentDetails && previousPaymentDetails.length > 0) {
                const spiltData = Number((previousPaymentDetails[0].orderNo).split("_")[1]) || 0;

                orderNo = `${orderData.orderNo}_${spiltData + 1}`

                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            else {
                orderNo = orderData.orderNo;
                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            const insertedData = await Payment.insertPayment(paymentData);

            // ***********************************
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;
            const port = window.location.port;

            const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

            // const returnUrl = `${fullUrl}/Response`;
            const returnUrl = `https://pay.mothershut.com/Response`;

            const data = { orderNo, balAmt, returnUrl };
            const apiUrl = `${WebApiUrl}/api/JusPayPayment/initiateJuspayPayment`;
            try {
                const response = await axios.post(apiUrl, data, { withCredentials: true });

                if (response.data && response.data.payment_links && response.data.payment_links.web) {
                    const url = response.data.payment_links.web;
                    console.log("orderNo", orderNo);
                    window.location.href = url;
                    localStorage.setItem("JusPayOrderNo", orderNo);
                    localStorage.setItem("Ord1Id", ord1Id);
                } else {
                    console.error('Payment links not found in response');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error initiating payment:', error);
                setLoading(false);
            }
        }
        else if (balAmt == 0) {
            alert("Balance Amount is Zero(0)");
        }
        setLoading(false);
        // const data = await Order.getOrderItemCancelStatus(id);
        // //alert(data);
        // if (data === "Completed") {
        //     console.log(orderStatus);
        //     setOrderStatus(orderStatus);
        //     console.log(balanceAmt, grandTotal)
        //     const balAmt = grandTotal - paymentAmt;
        //     setAmount((prevValues) => ({
        //         ...prevValues,
        //         "paymentAmount": paymentAmt,
        //         "balanceAmt": balAmt,
        //         "grandTotal": grandTotal
        //     }));
        //     setPayingAmount(balAmt);
        //     setOrderId(id);
        //     setPaymentStatusModal(true);
        // }
        // else {
        //     alert("Please Settle Cancel Order Status");
        // }
    }

    const [isCancelled, setCancelled] = useState(false);

    const OrderCancel = async (orderId, deliveryStatus, cancelStatus) => {
        setLoading(true);

        const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        // const formattedDate = new Date(isoString);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        const time = await getCurrentTime();

        const updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: "Return Request"
            },
            {
                operationType: 0,
                path: "deliveredTime",
                op: "Add",
                from: "string",
                value: time,
            },
            {
                operationType: 0,
                path: "returnRequestBy",
                op: "Add",
                from: "string",
                value: loginUser.userId,
            },
            {
                operationType: 0,
                path: "returnRequestOn",
                op: "Add",
                from: "string",
                value: formattedDate,
            },
        ];

        if (deliveryStatus === 'Delivered') {
            setLoading(false);
            alert("Cannot cancel the order as it has already been delivered.");
            return;
        }

        if (deliveryStatus === 'Cancel' || cancelStatus) {
            setLoading(false);
            alert("This product has already been cancelled.");
            return;
        }

        try {
            const isSuccess = await OrderAPI.updateOrderPartially(updatedValues, orderId);
            if (isSuccess) {
                setLoading(false);
                setCancelled(true)
                alert("Order canceled successfully");
                fetchData();
            } else {
                throw new Error("Error while canceling order");
            }
        } catch (error) {
            console.error("Error while canceling order:", error);
            alert("Error while canceling order");
        }
    };

    async function getCurrentTime() {
        const isoString = await CurrentDateAndTime.getCurrentTime();
        return isoString;
    }

    const [isDelivered, setIsDelivered] = useState(false);

    const OrderDelivery = async (orderId, deliveryStatus, paymentStatus) => {
        setLoading(true);
        console.log(deliveryStatus);
        if (deliveryStatus === 'Delivered') {
            setLoading(false);
            alert("This product has already been delivered.");
            return;
        } else if (deliveryStatus === 'Cancel') {
            setLoading(false);
            alert("This product has already Cancelled..");
            return;
        } else if (deliveryStatus) {
            const time = await getCurrentTime();

            const updatedValues = [
                {
                    operationType: 0,
                    path: "overallOrderStatus",
                    op: "Add",
                    from: "string",
                    value: "Delivered"
                },
                {
                    operationType: 0,
                    path: "deliveredTime",
                    op: "Add",
                    from: "string",
                    value: time,
                },
            ];
            try {
                const isSuccess = await OrderAPI.updateOrderPartially(updatedValues, orderId);
                if (isSuccess) {
                    setLoading(false);
                    setIsDelivered(true); // Set the flag to true after successful delivery
                    alert("Order Delivered successfully");
                    fetchData();
                } else {
                    throw new Error("Error while delivering order");
                }
            } catch (error) {
                console.error("Error while delivering order:", error);
                alert("Error while delivering order");
            }
        }
    };

    // *****************************{Image Capture}******************************
    const inputRef = useRef(null);
    const [capturedImages, setCapturedImages] = useState([]);
    // const [showDeleteButton, setShowDeleteButton] = useState(true);

    // const handleCapture = () => {
    //     if (inputRef.current) {
    //         inputRef.current.click();
    //     }
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setCapturedImages((prevImages) => [...prevImages, imageUrl]);
        }
    };

    const handleDelete = (index) => {
        setCapturedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    // ****************{Google Map}*************
    const [inputValues, setInputValues] = useState({
        longitude: '',
        latitude: ''

    });
    const [currentLocation, setCurrentLocation] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState('');

    const fetchLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleDeliveryAddressChange = (e) => {
        setDeliveryAddress(e.target.value);
    };

    const redirectToMaps = (latitude, longitude) => {
        const value = latitude + "," + longitude;
        const currentLocationQuery = currentLocation ? `${currentLocation.lat},${currentLocation.lng}` : '';
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(value)}&destination_place_id&origin=${currentLocationQuery}&origin_place_id&travelmode=driving`;
        window.open(mapsUrl, '_blank');
    };

    const [showBillPrint, setShowBillPrint] = useState(false);
    const [ord1Id, setOrd1Id] = useState(0);

    const closeBillPrint = () => {
        setShowBillPrint(false)
    }

    const handlePrintButton = (id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus) => {
        setOrd1Id(id)
        setShowBillPrint(true)
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id,
        //         paymentAmt,
        //         balanceAmt,
        //         grandTotal,
        //         paymentStatus,
        //         orderStatus
        //     }
        // });
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }

        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }

        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const handlePrefChange = (index, event) => {
        const newValues = [...OrderInfoMain];
        newValues[index].deliveryPreferenceNo = event.target.value;
        setOrderInfoMain(newValues);
        // console.log(OrderInfoMain);
    }

    const savePrefernceClick = async (index) => {
        const filterData = [
            {
                "operationType": 0,
                "path": "DeliveryPreferenceNo",
                "op": "Add",
                "from": "string",
                "value": OrderInfoMain[index].deliveryPreferenceNo
            },
        ]
        await OrderAPI.updateOrderPartially(filterData, OrderInfoMain[index].ord1Id);
        alert("Updated");
        fetchData()
    }


    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <div style={{}}>
            <div>
                {currentLocation && (
                    <div>
                        Current Location: {currentLocation.lat}, {currentLocation.lng}
                    </div>
                )}
                {/* <div>
                    Delivery Address: */}
                {/* <input type="hidden" value={`${OrderInfoMain.longitude},${OrderInfoMain.latitude}`} onChange={handleDeliveryAddressChange} /> */}
                {/* <input type="text" value={deliveryAddress } onChange={handleDeliveryAddressChange} /> */}
                {/* </div> */}
                {/* <button onClick={redirectToMaps}>Open Google Maps</button> */}
            </div>
            <div>
                <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handleFileChange}
                    ref={inputRef} style={{ display: 'none' }}
                />
                {/* <button onClick={handleCapture}>Capture Photo</button> */}
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                    {capturedImages.map((imageUrl, index) => (
                        <div key={index} style={{ margin: '5px', position: 'relative' }}>
                            <div className="delete-button DeleteBtnImg" onClick={() => handleDelete(index)}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                            <img src={imageUrl} alt={`Captured ${index + 1}`} style={{ width: '80px', height: '80px' }} />
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ overflow: "scroll", height: "97vh", padding: "0px 5px" }}>
                {
                    OrderInfoMain && OrderInfoMain.length > 0 ? (
                        OrderInfoMain.filter(item => item.overallOrderStatus != "Confirm"
                            && item.overallOrderStatus != "Pending")
                            //.sort((a, b) => a.deliveryPreferenceNo - b.deliveryPreferenceNo)
                            .map((data, index) => (
                                <div className="bg-white" key={index}>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Ord Pref No : &nbsp; </label>
                                            <input type="number" className="preferenceInput" name="preferenceNo" onChange={(e) => handlePrefChange(index, e)} value={data.deliveryPreferenceNo != null && data.deliveryPreferenceNo != undefined ? data.deliveryPreferenceNo : 0} onFocus={(e) => handleFocus(e)} style={{ width: "30%" }} /> &nbsp;
                                            <i class="fa-solid fa-floppy-disk" style={{ color: "darkgreen", fontSize: "18px" }} onClick={() => savePrefernceClick(index)}></i>
                                        </div>
                                        <div className="space-evenly mt-1" style={{ alignItems: "baseline" }}>
                                            <i onClick={() => redirectToMaps(data.latitude, data.longitude)} className="fa-solid fa-location-dot" style={{ color: "red", fontSize: "18px" }}></i>
                                            <Link to={`/OrderFeedback?ord1Id=${data.ord1Id}`}>
                                                <i className="fa-solid fa-star" style={{ color: "darkorange", fontSize: "18px", margin: "0px 5px" }}></i>
                                            </Link>
                                            <i className="fa-solid fa-envelope" style={{ color: "grey", fontSize: "18px" }}></i>
                                            <i onClick={() => handlePrintButton(data.ord1Id, data.paymentAmount, data.balanceAmt, data.grandTotal, data.paymentStatus, data.orderStatus)} className="fa-solid fa-print" style={{ color: "blueviolet", fontSize: "18px", marginLeft: "5px" }}></i>
                                            {/* <i onClick={() => handleCapture()} className="fa-solid fa-camera" style={{ color: "gray", fontSize: "19px", margin: "0px 5px" }}></i> */}
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Order No:&nbsp;</label>
                                            <label className="ans"> {data.orderNo} </label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Order Date:&nbsp;</label>
                                            {/* <label className="ans"> {formatDate(data.orderDate)} </label> */}
                                            <label className="ans"> {moment(data.orderDate).format('DD-MM-YYYY')} </label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Name : &nbsp;</label>
                                            <label className="ans"> {data.name}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Mob:&nbsp;</label>
                                            <label className="ans"> {data.mobileNo} &nbsp;</label>
                                            <i className="fa-solid fa-phone mt-1" style={{ color: "green", fontSize: "14px" }} onClick={() => CallButtonClick(data.accMobileNo)}></i>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Del. Time&nbsp;  Slot:&nbsp;</label>
                                            {/* <label className="ans">{timeFormat(data.deliveryTime)}</label> */}
                                            <label className="ans">{(data.timeSlotName)}</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Del Date:&nbsp;</label>
                                            {/* <label className="ans">{formatDate(data.deliveryDate)}</label> */}
                                            <label className="ans">{moment(data.deliveryDate).format('DD-MM-YYYY')}</label>
                                        </div>
                                        <div className="flex mt-1">
                                            <label className="ques">Ord Status:&nbsp;</label>
                                            <label className="ans">{data.overallOrderStatus}&nbsp;</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Ord&nbsp;Time:&nbsp;</label>
                                            <label className="ans">{moment(data.orderTime, 'HH:mm:ss').format('hh:mm A')}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">KOT Disp Time:&nbsp;</label>
                                            <label className="ans">{data.kotDispatchTime != "" ? moment(data.kotDispatchTime, 'HH:mm:ss').format('hh:mm A') : "00:00"}</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Disp Time:&nbsp;</label>
                                            <label className="ans">{data.deliveryBoyDispatchTime ? moment(data.deliveryBoyDispatchTime, 'HH:mm:ss').format('hh:mm A') : "00:00"}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Delivered Time : &nbsp;</label>
                                            <label className="ans">{data.deliveredTime ? moment(data.deliveredTime, 'HH:mm:ss').format('hh:mm A') : "00:00"}</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Total Amt:&nbsp;</label>
                                            <label className="ans">₹ {data.grandTotal - data.paymentAmount}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Delivery Charge : &nbsp;</label>
                                            <label className="ans">₹ {data.deliveryCharges || 0} </label>
                                        </div>
                                    </div>
                                    {/* <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Voucher Amt:&nbsp;</label>
                                            <label className="ans">₹ {data.couponCodeAmount || 0}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Service Tax : &nbsp;</label>
                                            <label className="ans">0</label>
                                        </div>
                                    </div> */}
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Grand Total : &nbsp;</label>
                                            <label className="ans">₹ {data.grandTotal || 0} </label>
                                        </div>

                                        <div className="flex-start">
                                            <label className="ques">Balance Amount  :&nbsp;</label>
                                            <label className="ans">₹ {data.balanceAmt || 0} </label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques">Payment:&nbsp;</label>
                                            <label className="ans">{data.paymentStatus ? data.paymentStatus : "Pending"}</label>
                                        </div>
                                        {data.balanceAmt != 0 && (
                                            <label className="ans"
                                                onClick={() => openPaymentStatusModal(data.ord1Id, data.paymentAmount, data.balanceAmt, data.grandTotal, data.paymentStatus, data.overallOrderStatus)}
                                                style={{ backgroundColor: "#aef2ff", borderRadius: "20px", marginLeft: "10px", padding: "4px 10px" }}>Pay Online</label>
                                        )}
                                    </div>
                                    <div className="flex-start">
                                        <label className="ques">Address&nbsp;:</label>
                                        <label className="ans">&nbsp;{data.accAddress}</label>&nbsp;
                                        <input type="hidden" value={data.latitude} onChange={handleDeliveryAddressChange} />
                                        <input type="hidden" value={data.longitude} onChange={handleDeliveryAddressChange} />
                                        {/*<i className="fa-solid fa-map-location-dot"></i>*/}
                                    </div>
                                    {/* <div className="flex-start">
                                        <label className="ques">House/Flat/Block No : &nbsp;</label>
                                        <label className="ans">{data.accAddress}</label>
                                    </div>
                                    <div className="flex-start">
                                        <label className="ques">Landmark : &nbsp;</label>
                                        <label className="ans"></label>
                                    </div> */}
                                    <div className="flex-start">
                                        <label className="ques">Trip No : &nbsp;</label>
                                        <label className="ans">{data.accName}/{tripNo || 0}</label>
                                    </div>
                                    {/* <div className="flex-start">
                                        <label className="ques">Delivery Msg No : &nbsp;</label>
                                        <input type="text" className="preferenceInput" />  
                                    </div> */} {/* value={data.serviceTypeId} */}

                                    {showCancelDelBtn && (
                                        <div className="flex-start mt-2">
                                            <button onClick={() => OrderDelivery(data.ord1Id, data.overallOrderStatus, data.paymentStatus)} className="Btn1"
                                                style={{
                                                    display: data.overallOrderStatus == "Returned" || data.overallOrderStatus == "Delivered" ||
                                                        data.overallOrderStatus == "Return Request" ? "none" : "block",
                                                    backgroundColor: "lavender", color: "black", fontWeight: "400"
                                                }}>Order Delivered</button>
                                            <button onClick={() => OrderCancel(data.ord1Id, data.overallOrderStatus)} className="Btn1"
                                                style={{
                                                    display: data.overallOrderStatus == "Returned" || data.overallOrderStatus == "Return Request" ||
                                                        data.overallOrderStatus == "Delivered" ? "none" : "block",
                                                    backgroundColor: "lightBlue", color: "black", fontWeight: "400"
                                                }}>Order Cancel</button>
                                        </div>
                                    )}
                                </div>
                            ))
                    ) : (
                        <div>No pending deliveries for you !!</div>
                    )}

            </div>
            {/* {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Station Master is Updated"
                    onYesClick={OrderCancel}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                    
                />
            )} */}
            {/* {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={OrderCancel}
                />
            )}
            {loading && <Loader />} */}
            {
                PaymentStatusModal && (
                    <div className="modal-back1">
                        <div className="kotStatus-modal">
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}>Set Payment Status</label>
                                <i className="fa-solid fa-xmark xmark" onClick={closePaymentStatusModal}></i>
                            </div>
                            <hr />
                            <div className="">
                                <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "90%" }}>
                                    <input type="text" className="inputEffect Min_height_mob" onChange={handleAmountChange} value={payingAmount} />
                                    <label id="input1" className="labelEffect " style={{}}>Amount</label>
                                </div>
                                {/* <label>{tableOrder.paymentStatus}</label> */}
                                <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                    <select className="inputEffect Min_height_mob" value={tableOrder.paymentMode} onChange={handlePaymentStatus}>
                                        {/* <option value="Pending">Pending</option>
                                                <option value="Cash">Cash</option>
                                                <option value="CDC">CDC</option> */}
                                        {/* <option value=""></option> */}
                                        {(() => {
                                            if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                                return paymentModeDetails // .filter(item => item.display == true)
                                                    .map((data) => (
                                                        <option key={data.accountId} disabled={data.display == true ? false : true} value={data.accountId}>
                                                            {data.accountTitle}
                                                        </option>
                                                    ))
                                            }
                                        })()}
                                    </select>
                                    <label id="input1" className="labelEffect " style={{}}>Payment Status</label>
                                </div>

                                {/* <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" id="orderStatus" value={orderStatus} onChange={orderStatusChange}>
                                            <option value="Pending">Pending</option>
                                            <option valaue="Done">Done</option>
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Order Status</label>
                                    </div> */}
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                <button className="greenBtn" style={{ width: "25%" }} onClick={paymentStatusSaveBtn} >Submit</button>
                                <button className="redBtn" style={{ width: "25%" }} onClick={closePaymentStatusModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                showBillPrint && (
                    <div style={{ display: "none" }}> {/**/}
                        <div className="modal-back" onClick={closeBillPrint}>
                            <div className="billPrintModal" style={{}}>
                                <div style={{ padding: "5px", backgroundColor: "white" }}>
                                    <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {loading && <Loader />}
        </div>
    );
};

export default Orders;
