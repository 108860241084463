import React, { } from "react";

class MonthWiseAttendance extends React.Component {

    state = {
        attendanceData: [
            true, false, true, true, true, true, true,
            true, false, true, true, true, true, true,
            true, false, true, true, true, true, true,
            true, false, true, true, true, true, true,
            true, false, true,
        ],
    };

    render() {
        const daysInMonth = 30;
        //const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        return (
            <div className="mt-2" style={{ margin: "10px" }}>
                <table>
                    <thead>
                        <tr>
                            <th className="sticky-column tableHeader">Emp. Id</th>
                            <th className="sticky-column tableHeader">Emp. Name</th>
                            {(() => {
                                const thElements = [];
                                for (let i = 1; i <= 30; i++) {
                                    thElements.push(<th key={i} className="tableHeader">{i}</th>);
                                }
                                return thElements;
                            })()}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="sticky-column tableHeader td">1</td>
                            <td className="sticky-column tableHeader td">Sneha Sharma</td>
                            {Array.from({ length: daysInMonth }, (_, index) => index + 1).map(day => (
                                <th>
                                    <div key={day} className={`day ${this.state.attendanceData[day - 1] ? 'present' : 'absent'} ${day % 7 === 0 ? 'sunday' : ''}`}>
                                        <div>
                                            <div>
                                                {(() => {
                                                    if (day % 7 === 0) {
                                                        return (
                                                            <div>Sun</div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div>{`${this.state.attendanceData[day - 1] ? 'P' : 'A'}`}</div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}


export default MonthWiseAttendance;
