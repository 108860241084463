import React, { useState } from 'react';

const ModalForTdWidth = (props) => {
  const [tableWidth, setTableWidth] = useState('');

  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    console.log(newWidth + "%");
    setTableWidth(newWidth);
  };

  const handleSave = () => {
    const selectedCell = props.cellElement;
    selectedCell.style.width = tableWidth + "%";
    props.Click();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="modal-back-web-template">
      <div className="image-modal-web-template">
        <i style={{ position: "absolute", fontSize: "20px", right: "10px", top: "5px" }} class="fa-solid fa-xmark" onClick={handleSave}></i>
        <p className='modalheading-text-web-template'>Set Width for Column {props.cellIndex + 1}</p>
        <input
          type="number"
          className='input-modalbox-web-template'
          onChange={handleWidthChange}
          value={tableWidth}
          onKeyPress={handleKeyPress}
        />
        <button className='modal-buttonsave-web-template' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ModalForTdWidth;
