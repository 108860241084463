import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useContext, useRef } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import ToolTipMaster from "../../Props/ToolTipMaster";
import UnitMasterApi from '../../../API/APIServices/UnitMaster';
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function UnitMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const navigate = useNavigate();

    useState(() => {
        document.title = 'Unit Master';
    }, []);

    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        eWayBillUnitName: '',
        conversionFactor: '',
        conversionValue: '',
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        printName: '',
        eWayBillUnitName: '',
        mainUnit: '',
        altUnit: '',
        conversionUnit: '',
        conversionFactor: '',
        conversionValue: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }
        setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
        return true;
    };

    const fieldNames = ['name', 'eWayBillUnitName', 'conversionFactor', 'conversionValue'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = UnitMasterApi.updateUnitMaster(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = UnitMasterApi.insertUnitMaster(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: '',
                printName: '',
                eWayBillUnitName: '',
                mainUnit: '',
                altUnit: '',
                conversionUnit: '',
                conversionFactor: '',
                conversionValue: '',
                isActive: true,
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData; // Assuming props.editData is a Promise
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
               console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };


    return (
        <div>
            <PageTabs locationTab={"/UnitMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Unit Master</h2>
                </div>
                <MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="ToggleSwitchActive td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <ToolTipMaster isActiveInactive={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.name}
                                                        name="name"
                                                        ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Unit Name
                                                        <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span>
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.printName}
                                                        name="printName"
                                                        ref={input2Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Print Name</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.eWayBillUnitName}
                                                        name="eWayBillUnitName"
                                                        ref={input3Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">E-WAY Bill Unit Name
                                                        <span className="StarClr">*
                                                            {errorMessages.eWayBillUnitName && <label className="errorMessage">{errorMessages.eWayBillUnitName}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.mainUnit}
                                                        name="mainUnit"
                                                        ref={input4Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Main Unit
                                                        <span className="StarClr">*
                                                            {errorMessages.mainUnit && <label className="errorMessage">{errorMessages.mainUnit}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.altUnit}
                                                        name="altUnit"
                                                        ref={input5Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                        type="number" required />
                                                    <label className="modify_p_text2">Alt Unit</label>
                                                </div>
                                            </div>
                                            {/* <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.conversionUnit}
                                                        name="conversionUnit"
                                                        ref={input6Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Conversion Unit
                                                        <span className="StarClr">*
                                                            {errorMessages.conversionUnit && <label className="errorMessage">{errorMessages.conversionUnit}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.conversionFactor}
                                                        name="conversionFactor"
                                                        ref={input7Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                        type="number" required />
                                                    <label className="modify_p_text2">Conversion Factor
                                                        <span className="StarClr">*
                                                            {errorMessages.conversionFactor && <label className="errorMessage">{errorMessages.conversionFactor}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        value={inputValues.conversionValue}
                                                        name="conversionValue"
                                                        ref={input8Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input9Ref)}

                                                        type="number" required />
                                                    <label className="modify_p_text2">Conversion Value
                                                        <span className="StarClr">*
                                                            {errorMessages.conversionValue && <label className="errorMessage">{errorMessages.conversionValue}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        // value={inputValues.conversionValue}
                                                        // name="conversionValue"
                                                        ref={input9Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input10Ref)}
                                                        type="text" required >
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                    <label className="modify_p_text2">Set as default Unit</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    {/* <ImageCapture/> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Unit is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Unit is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default UnitMaster;
