import React, { useEffect, useState } from 'react'
import './Eyecheckup.css'
import Dropdown from './Dropdown'
import InputBox from './InputBox'
import CheckOutAndGeneralSetting from '../API/APIServices/CheckoutAndGeneralSetting'

const Eyecheckupsetting = () => {

    const [inputValues, setInputValues] = useState({
        masterType: 230,
        eyeCheckUpInHome: '',
        eyeCheckUpFeeOnline: '',
        lensEyeCheckUpFee: '',
        checkUpTimeSlot: '',
        checkUpDateSlotLimit: '',
        eyeCheckUpUsePincode: '',
        cartDetailShowLP: '',
        allowCODOnLP: '',
        hasLensProductInShop: '',
        allowToShowFrameDetailPage: '',
    });
    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];
    console.log("Current state", inputValues);

    // ******************************************API's calls*****************************************************

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(inputValues)
        try {
            const response = await CheckOutAndGeneralSetting.CreateEyeCheckUpAndOptiSett(inputValues)
            console.log(response)
            if (response.isSuccess) {
                alert("Saved Successfully")
                setInputValues({
                    ...inputValues,
                    eyeCheckUpInHome: '',
                    eyeCheckUpFeeOnline: '',
                    lensEyeCheckUpFee: '',
                    checkUpTimeSlot: '',
                    checkUpDateSlotLimit: '',
                    eyeCheckUpUsePincode: '',
                    cartDetailShowLP: '',
                    allowCODOnLP: '',
                    hasLensProductInShop: '',
                    allowToShowFrameDetailPage: '',
                });
                fetchData(230)
            } else {
                alert('Not Updated')
                throw new Error("Unable To Update")
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchData = async () => {
        debugger
        try {
            const response = await CheckOutAndGeneralSetting.GetEyeCheckUpAndOptiSett()
            if (response.isSuccess) {
                const {
                    eyeCheckUpInHome,
                    eyeCheckUpFeeOnline,
                    lensEyeCheckUpFee,
                    checkUpTimeSlot,
                    checkUpDateSlotLimit,
                    eyeCheckUpUsePincode,
                    cartDetailShowLP,
                    allowCODOnLP,
                    hasLensProductInShop,
                    allowToShowFrameDetailPage,
                } = response.result;

                setInputValues({
                    ...inputValues,
                    eyeCheckUpInHome,
                    eyeCheckUpFeeOnline,
                    lensEyeCheckUpFee,
                    checkUpTimeSlot,
                    checkUpDateSlotLimit,
                    eyeCheckUpUsePincode,
                    cartDetailShowLP,
                    allowCODOnLP,
                    hasLensProductInShop,
                    allowToShowFrameDetailPage,
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    // ******************************************API's calls*****************************************************
    return (
        <div>
            <div className='heading-setting'>Eye Checkup Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.eyeCheckUpInHome}
                        onChange={(value) => setInputValues({ ...inputValues, eyeCheckUpInHome: value })}
                        label="Eye Checkup In Home"
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.eyeCheckUpFeeOnline}
                        onChange={(value) => setInputValues({ ...inputValues, eyeCheckUpFeeOnline: value })}
                        label="Eye CheckUp Fee Online"
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        value={inputValues.lensEyeCheckUpFee}
                        label={"Lens Eye Check Up Fees"}
                        onChange={(newValue) => setInputValues({ ...inputValues, lensEyeCheckUpFee: newValue })}
                    />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        value={inputValues.checkUpTimeSlot}
                        label={"Check Up Time Slot"}
                        onChange={(newValue) => setInputValues({ ...inputValues, checkUpTimeSlot: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox value={inputValues.checkUpDateSlotLimit}
                        label={"CheckUp Date Slot Limit"}
                        onChange={(newValue) => setInputValues({ ...inputValues, checkUpDateSlotLimit: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <InputBox
                        value={inputValues.eyeCheckUpUsePincode}
                        label={"Eye CheckUp Location"}
                        onChange={(newValue) => setInputValues({ ...inputValues, eyeCheckUpUsePincode: newValue })} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.cartDetailShowLP}
                        onChange={(value) => setInputValues({ ...inputValues, cartDetailShowLP: value })}
                        label="Cart Detail Show LP"
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.allowCODOnLP}
                        onChange={(value) => setInputValues({ ...inputValues, allowCODOnLP: value })}
                        label="Allow COD In LP"
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.HasLensProductInShop}
                        onChange={(value) => setInputValues({ ...inputValues, hasLensProductInShop: value })}
                        label="Has Lens Product In Shop"
                    />
                </div>
                <div className='grid-item-container'>
                    <Dropdown
                        options={options}
                        selectedValue={inputValues.allowToShowFrameDetailPage}
                        onChange={(value) => setInputValues({ ...inputValues, allowToShowFrameDetailPage: value })}
                        label="Allow To Show Frame Detail Page"
                    />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button' onClick={(e) => handleSubmit(e)}>Submit</button>
                </div>
            </div>
            {/* <div>
                <button className='save-button-main'>
                    Save
                </button>
            </div> */}
        </div>
    )
}

export default Eyecheckupsetting
