import React, { useContext, useEffect, useState } from "react";
import ItemMaster from "../../API/APIServices/ItemMaster";
import MyContext from "../MyContext";

const TabContent9 = (props) => {
  const { data, setLoading } = props;
  const [ItemMasterList, setItemMasterList] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const { UserDetails } = useContext(MyContext)
  console.log("ItemMasterList", ItemMasterList);
  console.log("inputValues", inputValues);

  const handleInputChange = (event, Id) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    // Check if attItmId is already present in inputValues
    const existingIndex = inputValues.findIndex((item) => item.attItmId === Id);

    if (existingIndex !== -1) {
      // If attItmId exists, update only the 'cntrlTypeImgTxt' parameter
      const updatedValues = inputValues.map((item, idx) => {
        if (idx === existingIndex) {
          return {
            ...item,
            cntrlTypeImgTxt: Number(value),
          };
        }
        return item;
      });

      // Update inputValues state
      setInputValues(updatedValues);


      const updatedValues1 = ItemMasterList.map((item1, idx1) => {
        if (idx1 === existingIndex) {
          return {
            ...item1,
            cntrlTypeImgTxt: Number(value),
          };
        }
        return item1;
      });
      setItemMasterList(updatedValues1)

      console.log("Updated inputValues: ", updatedValues);
    } else {
      // If attItmId doesn't exist, update inputValues with new key-value pair
      const newInputValue = {
        attItmId: Id,
        cntrlTypeImgTxt: Number(value),
        useForVariation: false,
        attributeParentId: "",
      };
      // Concatenate the new value with the existing values
      const updatedValues = [...inputValues, newInputValue];
      // Update inputValues state
      setInputValues(updatedValues);
      console.log("Updated inputValues: ", updatedValues);
    }
  };

  const handleCheckboxChange = (index, e, id) => {
    const { name, checked } = e.target;

    setInputValues((prevValues) => {
      const existingIndex = prevValues.findIndex(
        (item) => item.attItmId === id
      );

      if (existingIndex !== -1) {
        // If attItmId exists, update the value for the given name
        const updatedValues = prevValues.map((item, idx) => {
          if (idx === existingIndex) {
            return {
              ...item,
              [name]: checked,
            };
          }
          return item;
        });

        console.log("Updated inputValues: ", updatedValues);
        return updatedValues;
      } else {
        // If attItmId doesn't exist, create a new array item
        const newItem = {
          attItmId: id,
          cntrlTypeImgTxt: "",
          [name]: checked,
          attributeParentId: "",
        };

        const updatedValues = [...prevValues, newItem];
        console.log("Updated inputValues: ", updatedValues);
        return updatedValues;
      }
    });
    setItemMasterList((prevValues) => {
      const existingIndex = prevValues.findIndex(
        (item) => item.d1_Id === id
      );

      if (existingIndex !== -1) {
        // If attItmId exists, update the value for the given name
        const updatedValues = prevValues.map((item, idx) => {
          if (idx === existingIndex) {
            return {
              ...item,
              [name]: checked,
            };
          }
          return item;
        });

        console.log("Updated inputValues: ", updatedValues);
        return updatedValues;
      } else {
        // If attItmId doesn't exist, create a new array item
        const newItem = {
          attItmId: id,
          cntrlTypeImgTxt: "",
          [name]: checked,
          attributeParentId: "",
        };

        const updatedValues = [...prevValues, newItem];
        console.log("Updated inputValues: ", updatedValues);
        return updatedValues;
      }
    });
  };

  // const handleCheckboxChangeAttribute = (e, parentId, id) => {
  //   const { name, checked } = e.target;

  //   // Assuming ItemMasterList is available in scope
  //   const itemMasterListLength = ItemMasterList.length;

  //   setInputValues((prevValues) => {
  //     let updatedValues = [...prevValues];

  //     for (let i = 0; i < itemMasterListLength; i++) {
  //       const itemMaster = ItemMasterList[i];
  //       const attItmId = itemMaster.d1Id;  // Set attItmId from d1Id

  //       const existingItem = updatedValues.find((item) => item.attItmId === attItmId);

  //       if (existingItem) {
  //         updatedValues = updatedValues.map((item) => {
  //           if (item.attItmId === attItmId) {
  //             let parentIds = Array.isArray(item.attributeParentId) ? item.attributeParentId : [];
  //             if (!parentIds.includes(parentId)) {  // Feed id in attributeParentId
  //               parentIds.push(parentId);
  //             }

  //             return {
  //               ...item,
  //               attributeParentId: parentIds,
  //             };
  //           }
  //           return item;
  //         });
  //       } else {
  //         const newInputValue = {
  //           attItmId: attItmId,
  //           cntrlTypeImgTxt: "",
  //           useForVariation: "",
  //           attributeParentId: [parentId],  // Feed id in attributeParentId
  //         };

  //         updatedValues = [...updatedValues, newInputValue];
  //       }
  //     }

  //     console.log("Updated inputValues: ", updatedValues);
  //     return updatedValues;
  //   });
  // };



  const handleCheckboxChangeAttribute = (e, parentId, id) => {
    const { name, checked } = e.target;

    // Assuming ItemMasterList is available in scope
    const itemMasterListLength = ItemMasterList.length;

    setInputValues((prevValues) => {
      let updatedValues = [...prevValues];

      for (let i = 0; i < itemMasterListLength; i++) {
        const itemMaster = ItemMasterList[i];
        const attItmId = itemMaster.d1_Id;  // Set attItmId from d1Id

        const existingItem = updatedValues.find((item) => item.attItmId === attItmId);

        if (existingItem) {
          // Update existing item
          updatedValues = updatedValues.map((item) => {
            if (item.attItmId === attItmId) {
              let parentIds = Array.isArray(item.attributeParentId) ? item.attributeParentId : [];

              // Remove parentId if already present
              if (parentIds.includes(parentId)) {
                parentIds = parentIds.filter((id) => id !== parentId);
              } else {
                parentIds.push(parentId);  // Add parentId if not already included
              }

              return {
                ...item,
                attributeParentId: parentIds,
              };
            }
            return item;
          });
        } else {
          // Create new item
          const newInputValue = {
            attItmId: attItmId,
            cntrlTypeImgTxt: "",
            useForVariation: "",
            attributeParentId: [parentId],  // Initialize attributeParentId with parentId
          };

          updatedValues = [...updatedValues, newInputValue];
        }
      }

      console.log("Updated inputValues: ", updatedValues);
      return updatedValues;
    });
  };






  console.log(inputValues);

  // useEffect(() => {
  //   debugger;
  //   const fetchAttributeData = async () => {
  //     setLoading(true);
  //     if (props.data.iAttributeParent === 0) {
  //       const filteredData = [
  //         {
  //           fieldName: "attributeParentId",
  //           operatorName: "equal",
  //           compareValue: props.data.d2_Id,
  //         },
  //       ];
  //       var list = await ItemMaster.GetItemMasterWithFilter(filteredData);
  //       if (list) {
  //         console.log(list);
  //         setItemMasterList(list);
  //         setLoading(false);
  //       }
  //       setLoading(false);
  //     } else {
  //       const filteredData = [
  //         {
  //           fieldName: "attributeParentId",
  //           operatorName: "equal",
  //           compareValue: props.data.iAttributeParent,
  //         },
  //       ];
  //       var list = await ItemMaster.GetItemMasterWithFilter(filteredData);
  //       if (list) {
  //         console.log(list);
  //         setItemMasterList(list);
  //         setLoading(false);
  //       }
  //       setLoading(false);
  //     }
  //   };
  //   setLoading(false);
  //   fetchAttributeData();
  //   Getattribute();
  // }, []);



  useEffect(() => {
    setLoading(true);
    debugger
    const fetchAttributeData = async () => {
      var list = await ItemMaster.EcomItemsVariation(props.data.d1_Id);
      if (list && list.length > 0) {
        setItemMasterList(list);

        let anyVariationActive = list.some(item => item.variation.some(v => v.isActive));

        let inputValues = list.map(item => ({
          "attItmId": item.d1_Id || "",
          "cntrlTypeImgTxt": item.cntrlTypeImgTxt || "",
          "useForVariation": item.useForVariation || "",
          "attributeParentId": anyVariationActive ? item.variation.filter(v => v.isActive).map(v => v.attr_prnt) : []
        }));

        // Assuming setInputValues is a function that accepts an array
        setInputValues(inputValues);

        setLoading(false);
      }
    }
    setLoading(false);
    fetchAttributeData()
  }, [])

  const saveProductAttribute = () => {
    const filtered = inputValues.filter(item => item.useForVariation === true || item.useForVariation === false)
    var save = ItemMaster.SaveEcomItemsVariation(filtered);
    if (save) {
      alert("Saved");
    }
  };

  const [isMasterChecked, setIsMasterChecked] = useState(false);

  useEffect(() => {

    const allChecked = inputValues.every(input => input.useForVariation);
    setIsMasterChecked(allChecked);
  }, [inputValues]);

  const checkAllinputofvariation = (e) => {
    const isChecked = e.target.checked;
    const updatedList = ItemMasterList.map((item) => ({
      ...item,
      useForVariation: isChecked,
    }));

    setItemMasterList(updatedList);

    const updatedInputValues = inputValues.map((inputValue) => ({
      ...inputValue,
      useForVariation: isChecked,
    }));

    setInputValues(updatedInputValues);
  };

  const resetAttributeParentId = () => {
    setInputValues((prevValues) => {
      const updatedValues = prevValues.map((item) => ({
        ...item,
        attributeParentId: [],  // Reset attributeParentId to an empty array
      }));

      console.log("Reset inputValues: ", updatedValues);
      return updatedValues;
    });
  };

  return (
    <div>
      <div className="company_info-text"></div>
      <div className="tab-content-7 col-d-11">
        <div className="tabs-col-sm-1">
          <div className="table-resposive">
            <table
              style={{ margin: "auto" }}
              border={1}
              id="tblTransactionList"
              className="tabs-table table-bordered1"
              frame="box"
            >
              <thead
                className="ProductAttributeTabHead"
                style={{ backgroundColor: "e5f2ff" }}
              >
                <tr className="variationtable-tr">
                  <th>S.No.</th>
                  <th>Product Name</th>
                  <th>Attribue Value</th>
                  <th>Control Type</th>
                  <th>
                    Use For Variation{" "}
                    {UserDetails?.canModifyMaster && (
                      <input
                        onChange={checkAllinputofvariation}
                        checked={isMasterChecked}
                        type="checkbox"
                        name=""
                        id=""
                      />
                    )}
                  </th>
                  <th>Variation Name
                    {UserDetails?.canModifyMaster && (
                      <span className="resetButton-vatiation" onClick={resetAttributeParentId}>Reset</span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ItemMasterList.map((item, index) => (
                  <tr key={index} className="variationtable-tr">
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        border: "none",
                      }}
                    >
                      {item.variation.map((variations, variationIndex) => (
                        <label style={{ width: "100%" }} key={variationIndex}>
                          <span>

                            {variations.attr_prnt_nm}
                          </span>
                          -
                          <span>
                            {variations.attr_nm}
                          </span>
                        </label>
                      ))}
                    </td>

                    <td>
                      <select
                        onChange={(e) => handleInputChange(e, item.d1_Id)}
                        name="cntrlTypeImgTxt"
                        value={item.cntrlTypeImgTxt}
                        className="attribute-ct"
                      >
                        <option value="">--select--</option>
                        <option value="1">image</option>
                        <option value="2">text</option>
                      </select>

                    </td>
                    <td>
                      <input
                        onChange={(e) =>
                          handleCheckboxChange(index, e, item.d1_Id)
                        }
                        type="checkbox"
                        name="useForVariation"
                        id=""
                        checked={item.useForVariation === true}
                        value={item.useForVariation}
                      />
                    </td>
                    <td>
                      {" "}
                      {item.variation.map((variations, variationIndex) => (
                        <label style={{ width: "30%" }} key={variationIndex}>
                          <input
                            onChange={(e) =>
                              handleCheckboxChangeAttribute(
                                e,
                                variations.attr_prnt,
                                item.d1_Id
                              )
                            }
                            type="checkbox"
                            name="attributeParentId"
                            checked={inputValues.some(
                              (item) =>
                                Array.isArray(item.attributeParentId) &&
                                item.attributeParentId.includes(
                                  Number(variations.attr_prnt)
                                ),
                            )}
                            style={{ marginRight: "5px" }}
                          />

                          {variations.attr_prnt_nm}
                        </label>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="productAttri-buttons">
              {UserDetails?.canModifyMaster && (<>
                <button onClick={saveProductAttribute}>save</button>
                <button style={{ backgroundColor: "#72728f" }}>Reset</button>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabContent9;
