import DateTime from "../Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import Joyride from "react-joyride";
import "react-quill/dist/quill.snow.css";
import PageTabs from "../Components/PageTabs";
import { useTranslation } from "react-i18next";
import MainButton from "./../Props/MainButton";
import CompanySetting from "../Props/CompanySetting";
import CompanyApi from "../../API/APIServices/Company";
import StateInfo from "../../API/APIServices/StateInfo";
import Country from "../../API/APIServices/Country";
import Loader from "../Props/Loader";
import AlertBox from "../Props/AlertBox";
import MyContext from "../MyContext";
import User from "../../API/APIServices/User";
import BusinessTypeApi from "../../API/APIServices/BusinessType";
import { Link, useNavigate } from 'react-router-dom';

function ItemMaster(props) {
  const { OpenAlertBoxEdit, alertBoxEdit, BusinessType, setBusinessType, setLoginUser, imageBaseUrl, UserDetails, maskMobileNo } =
    useContext(MyContext);
  // const [companyData , setCompanyData] = useState("")
  const [loading, setLoading] = useState(true);
  // useEffect(async () => {
  //   const Login = await User.login();
  // });
  useEffect(() => {
    setLoginUser(true);
  }, [])
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    stateId: "",
    mobileNo: "",
    email: "",
  });

  const [inputValues, setInputValues] = useState({
    id: "",
    name: "",
    printName: "",
    shortName: "",
    branchCode: "",
    fySessionFrom: "",
    fySessionTo: "",
    booksComForm: "",
    businessTypes: "",
    firmAddress1: "",
    firmAddress2: "",
    firmAddress3: "",
    countryId: "",
    stateId: "",
    pinCode: "",
    currencySymbol: "",
    currencyString: "",
    currencyUnit: "",
    currencyDecimalPlaces: "",
    dayIntervalForBackup: "",
    ecomMinShopLimit: "",
    payeeName: "",
    upiId: "",
    transferMsg: "",
    currencyCode: "",
    transferVoucherNo: "",
    vatGstType: "",
    payAmt: "",
    mobileNo: "",
    mobileNoForOTP: "",
    whatsappNo: "",
    email: "",
    telephoneNo: "",
    pan: "",
    website: "",
    facebook: "",
    instagram: "",
    fax: "",
    enableVatGST: true,
    enableAddTaxSchg: "",
    logo: "",
    gstTinNo: "",
    cstNo: "",
    drugLicenseNo: "",
    drugLicenseExpiryDate: "",
    termsCondition: "",
    urL1: "",
    urL2: "",
    isEnblMobOrdr: "",
    isEnblPayFrmOrdrTakerApp: false,
    isEnableWebsite: false,
    isEnableHomeDelivery: false,
    isEnableTblOrder: false,
    isEnablePickUp: false,
    isEnblItmAvailTimeChk: "",
    isEnblItmAvailDateChk: "",
    isEnblDlvryPnt: "",
    isEnblTmSltPopupInHmPg: "",
    isEnblCnclOrdrFrmDlvryBoyApp: "",
    isAllowTblOptionForGuest: "",
    isActive: false,
    // deletedOn: "",
    // deletedBy: "",
    defaultLanguage: "",
    // createdBy: "",
    isAllowDlvryPreferCheck: false,
    isEnblAutoTransferToKitchen: false,
    isShowBillAmtAfterOrderConfirm: false,
    isAllowDiffColorRunningOrder: false,
    isCheckNoOfPersonBeforeOrdConfirm: false,
    canUsePineLabDevice: false,
    isEnableGoogleMap: false,
    isPOSConfirmNKotBtn: false,
    isVariationItmShowInCatPage: false,
  });

  const handleInputChange = (event) => {
    console.log(inputValues);
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name", "stateId", "mobileNo", "email"];

  const countrychange = (e) => {
    const countryId = parseInt(e.target.value);
    // console.log(countryId);
    handleInputChange(e);
    handleCountryChange(countryId);
  };

  const [stateInfoMain, setStateInfoMain] = useState([]);

  useEffect(() => {
    // User.login()
    const fetchData = async () => {
      try {
        const state = await StateInfo.GetAllState();
        setStateInfoMain(state);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [countryMain, setCountryMain] = useState([]);
  // console.log(countryMain)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const country = await Country.GetAllCountry();
        setCountryMain(country);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [selectedCountry, setSelectedCountry] = useState(1101);
  // console.log(selectedCountry)
  // const [selectedState, setSelectedState] = useState("");

  const handleCountryChange = (countryId) => {
    // console.log(countryId);
    setSelectedCountry(countryId);
    // console.log(stateInfoMain)
    const filteredStates = stateInfoMain.filter(
      (stateInfoMain) => stateInfoMain.countryId === countryId
    );
    setStateInfoMain(filteredStates);
  };
  //console.log(stateInfoMain);

  useEffect(() => {
    // handlechangewithecom()
    const fetchData2 = async (id) => {
      try {
        const data = await CompanyApi.GetCompanyById(id);
        if (data) {
          setInputValues({
            id: data.id || "",
            name: data.name || "",
            printName: data.printName || "",
            shortName: data.shortName || "",
            branchCode: data.branchCode || "",
            fySessionFrom: data.fySessionFrom || "",
            fySessionTo: data.fySessionTo || "",
            booksComForm: data.booksComForm || "",
            businessTypes: data.businessTypes || "",
            firmAddress1: data.firmAddress1 || "",
            firmAddress2: data.firmAddress2 || "",
            firmAddress3: data.firmAddress3 || "",
            countryId: String(data.countryId) || "",
            stateId: String(data.stateId) || "",
            pinCode: data.pinCode || "",
            currencySymbol: data.currencySymbol || "",
            currencyString: data.currencyString || "",
            currencyUnit: data.currencyUnit || "",
            currencyDecimalPlaces: String(data.currencyDecimalPlaces) || "",
            dayIntervalForBackup: String(data.dayIntervalForBackup) || "",
            ecomMinShopLimit: String(data.ecomMinShopLimit) || "",
            payeeName: data.payeeName || "",
            upiId: data.upiId || "",
            transferMsg: data.transferMsg || "",
            currencyCode: data.currencyCode || "",
            transferVoucherNo: data.transferVoucherNo || "",
            payAmt: String(data.payAmt) || "",
            mobileNo: data.mobileNo || "",
            mobileNoForOTP: data.mobileNoForOTP || "",
            whatsappNo: data.whatsappNo || "",
            email: data.email || "",
            telephoneNo: data.telephoneNo || "",
            pan: data.pan || "",
            vatGstType: "",
            website: data.website || "",
            facebook: data.facebook || "",
            instagram: data.instagram || "",
            fax: data.fax || "",
            enableVatGST: data.enableVatGST || "",
            enableAddTaxSchg: data.enableAddTaxSchg || "",
            gstTinNo: data.gstTinNo || "",
            cstNo: data.cstNo || "",
            drugLicenseNo: data.drugLicenseNo || "",
            drugLicenseExpiryDate: data.drugLicenseExpiryDate || "",
            termsCondition: data.termsCondition || "",
            urL1: data.urL1,
            urL2: data.urL2,
            isEnblMobOrdr: data.isEnblMobOrdr || "",
            isEnblPayFrmOrdrTakerApp: data.isEnblPayFrmOrdrTakerApp || false,
            isEnableWebsite: data.isEnableWebsite || false,
            isEnableHomeDelivery: data.isEnableHomeDelivery || false,
            isEnableTblOrder: data.isEnableTblOrder || false,
            isEnablePickUp: data.isEnablePickUp || false,
            isEnblItmAvailTimeChk: data.isEnblItmAvailTimeChk || "",
            isEnblItmAvailDateChk: data.isEnblItmAvailDateChk || "",
            isEnblDlvryPnt: data.isEnblDlvryPnt || "",
            isEnblTmSltPopupInHmPg: data.isEnblTmSltPopupInHmPg || "",
            isEnblCnclOrdrFrmDlvryBoyApp: data.isEnblCnclOrdrFrmDlvryBoyApp || "",
            isAllowTblOptionForGuest: data.isAllowTblOptionForGuest || "",
            defaultLanguage: data.defaultLanguage,
            isAllowDlvryPreferCheck: data.isAllowDlvryPreferCheck || "",
            isEnblAutoTransferToKitchen: data.isEnblAutoTransferToKitchen || "",
            isShowBillAmtAfterOrderConfirm: data.isShowBillAmtAfterOrderConfirm || "",
            isAllowDiffColorRunningOrder: data.isAllowDiffColorRunningOrder || "",
            isCheckNoOfPersonBeforeOrdConfirm: data.isCheckNoOfPersonBeforeOrdConfirm || "",
            canUsePineLabDevice: data.canUsePineLabDevice || "",
            isEnableGoogleMap: data.isEnableGoogleMap || "",
            isPOSConfirmNKotBtn: data.isPOSConfirmNKotBtn || "",
            isVariationItmShowInCatPage: data.isVariationItmShowInCatPage || "",
          });
          // const imageUrl = `http://localhost:5000/api/v1/Company/Image?ImageId=${data.companyImageId}`;
          const imageUrl = `${imageBaseUrl}${data.companyImageId}`;
          setSelectedImage(imageUrl);
          console.log(data.businessTypes)
          setBusinessType(data.businessTypes)
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData1 = async () => {

      const arrayString = localStorage.getItem('loginUser');
      const arrayToStore = JSON.parse(arrayString);

      try {
        const data = await CompanyApi.GetCompany(); // Assuming props.editData is a Promise
        fetchData2(arrayToStore.compId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData1();
  }, []);

  const [settingInputValues, setSettingInputValues] = useState({
    additionalDetails: false,
    vatGstDetails: false,
    upiPaymentDetails: false,
    faxNo: false,
    loginThroughBranchCode: false,
  });

  const handleSettingChangeCompany = async (event) => {
    const { name, checked } = event.target;
    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...settingInputValues,
      [name]: checked,
    };

    setSettingInputValues(updatedValues);

    await CompanyApi.insertCreateCompanyMasterSetting(updatedValues);
    switch (name) {
      case "additionalDetails":
        handleToggleChangemc1();
        break;
      case "vatGstDetails":
        handleToggleChangemc2();
        break;
      case "upiPaymentDetails":
        handleToggleChangemc3();
        break;
      case "faxNo":
        handleFaxNumber();
        break;
      case "loginThroughBranchCode":
        //handleToggleChange1();
        break;
      default:
        break;
    }
  };

  const upload_image = async () => {
    debugger
    const formData = new FormData();
    const file = ItemImage[0];
    console.log(file)
    if (file != undefined) {
      formData.append(`CompanyId`, inputValues.id);
      formData.append('files', file);
      console.log(formData);

      try {
        const imageUpload = await CompanyApi.companyLogoUpload(formData, inputValues.id);
        console.log(imageUpload);

        if (imageUpload) {
          console.log("image uploaded successfully");
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    }
  };
  // *********************** get Master Setting *************************

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await CompanyApi.getCompanyMasterSettings();
        console.log(data);
        setSettingInputValues(data);
        setInputVisiblemc1(data.additionalDetails);
        setFaxNumber(data.faxNo);
        setInputVisiblemc2(data.vatGstDetails);
        setInputVisiblemc3(data.upiPaymentDetails);
        // setAltUnit(data.loginThroughBranchCode)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    console.log(settingInputValues);
    fetchData();
  }, []);

  const [ItemImage, setItemImage] = useState([]);

  const ItemImageUpload = (file) => {
    const newItemImage = [...ItemImage];
    newItemImage[0] = file;
    setItemImage(newItemImage);
  };

  const UpdateCompany = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      try {
        // Assuming updateCompany returns a promise
        const isSuccess = await CompanyApi.updateCompany(inputValues);
        upload_image();
        setLoading(true);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBoxEdit();
        } else {
          setLoading(false);
          alert("error while updating");
        }
      } catch (error) {
        console.error("Error updating company:", error);
      }

      // try {
      //   // Assuming updateCompany returns a promise
      //   await CompanyApi.();

      //   // Show a success message (you can replace this with your preferred alert mechanism)
      //   alert("Company updated successfully");
      // } catch (error) {
      //   // Handle the error (you can log it or show an error message)
      //   console.error("Error updating company:", error);

      //   // Show an error message (you can replace this with your preferred alert mechanism)
      //   alert("Error updating company. Please try again.");
      // }
    }
  };

  /**********************{title}*************************/
  useState(() => {
    document.title = "Modify Company";
  }, []);

  const { t } = useTranslation();

  //*******************{Image add}*************************//

  const [selectedImage, setSelectedImage] = useState(null);
  // const handleImageChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         setSelectedImage(URL.createObjectURL(file));

  //     }
  // };

  //********************{Editer}***************************//p

  const [isInputVisiblemc1, setInputVisiblemc1] = useState(false);
  const handleToggleChangemc1 = () => {
    setInputVisiblemc1(!isInputVisiblemc1);
  };
  const [isInputVisiblemc2, setInputVisiblemc2] = useState(false);
  const handleToggleChangemc2 = () => {
    setInputVisiblemc2(!isInputVisiblemc2);
  };
  const [isInputVisiblemc3, setInputVisiblemc3] = useState(false);
  const handleToggleChangemc3 = () => {
    setInputVisiblemc3(!isInputVisiblemc3);
  };

  const [isFaxNumber, setFaxNumber] = useState(false);
  const handleFaxNumber = () => {
    setFaxNumber(!isFaxNumber);
  };

  //*********************{modal box}*********************/ /
  const [isModalOpen, setModalOpen] = useState("");
  const showModal = () => {
    setModalOpen(!isModalOpen);
  };
  const hideModal = () => {
    setModalOpen(false);
  };

  const [CompmaySetting, setCompmaySetting] = useState(false);
  // const showCompany = () =>{
  //     setCompmaySetting(true);
  // }
  // const hideCompany = () =>{
  //     setCompmaySetting(false);
  // }

  const [, sethandleInputChangeEcom] = useState(false);
  // const [, sethandleInputChangeEcom] = useState(false)
  const handlechangewithecom = (event) => {
    handleInputChangeEcom(event);
    handleInputChange(event);
  };

  useEffect(() => {
    try {
      if (inputValues.businessTypes === 841) {
        setCompmaySetting(false);
      } else if (inputValues.businessTypes === 845) {
        setCompmaySetting(true);
      }
    } catch (error) {
      alert("Unable to find Business Type");
    }
  }, [inputValues.businessTypes]);

  const handleInputChangeEcom = (event) => {
    // handleInputChange(event);
    const selectedValue = event.target.value;
    sethandleInputChangeEcom(selectedValue);
    if (selectedValue == 841) {
      setCompmaySetting(false);
    } else if (selectedValue == 845) {
      setCompmaySetting(true);
    }
  };

  //*********************{modal box End}*********************//

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  //*****************{Onboarding code}************************//

  const [joyrideState, setJoyrideState] = useState({
    run: true,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>Upload Item Image!</h2>,
        placement: "bottom",
        target: "#step-1",
        title: "First step",
      },
      {
        content: <h2>Here is second step!</h2>,
        placement: "bottom",
        target: "#step-2",
        title: "Second step",
      },
      {
        content: <h2>Here is third step!</h2>,
        placement: "bottom",
        target: "#step-3",
        title: "Third step",
      },
      {
        content: <h2>Here is fourth step!</h2>,
        placement: "bottom",
        target: "#step-4",
        title: "Fourth step",
      },
      {
        content: <h2>Here is fifth step!</h2>,
        placement: "bottom",
        target: "#step-5",
        title: "Fifth step",
      },
      {
        content: <h2>Here is six step!</h2>,
        placement: "bottom",
        target: "#step-6",
        title: "Six step",
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    // const { status } = data;
    if (joyrideState.run === true) {
      stopJoyride();
    }
  };

  const stopJoyride = () => {
    setJoyrideState({
      ...joyrideState,
      run: false,
    });
  };

  //***********{Attribute code}*****************//

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const openFile = useRef(null);
  const handleImageChange = (event) => {
    ItemImageUpload(event.target.files[0])
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      //alert(imageUrl)
      setSelectedImage(imageUrl);
      setShowDeleteButton(true);
    }
  };
  const handleDeleteImage = () => {
    setSelectedImage(null);
    setShowDeleteButton(false);

    if (openFile.current) {
      openFile.current.value = null;
    }
  };

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);
  const input15Ref = useRef(null);
  const input16Ref = useRef(null);
  const input17Ref = useRef(null);
  const input18Ref = useRef(null);
  const input19Ref = useRef(null);
  const input20Ref = useRef(null);
  const input21Ref = useRef(null);
  const input22Ref = useRef(null);
  const input23Ref = useRef(null);
  const input24Ref = useRef(null);
  const input25Ref = useRef(null);
  const input26Ref = useRef(null);
  const input27Ref = useRef(null);
  const input28Ref = useRef(null);
  const input29Ref = useRef(null);
  const input30Ref = useRef(null);
  const input31Ref = useRef(null);
  const input32Ref = useRef(null);
  const input33Ref = useRef(null);
  const input34Ref = useRef(null);
  const input35Ref = useRef(null);
  const input36Ref = useRef(null);
  const input37Ref = useRef(null);
  const input38Ref = useRef(null);
  const input39Ref = useRef(null);
  const input41Ref = useRef(null);
  const input42Ref = useRef(null);
  const input43Ref = useRef(null);
  const input44Ref = useRef(null);
  const input45Ref = useRef(null);
  const input46Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  useEffect(() => {
    try {
      input1Ref.current.focus();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [businessTypeValue, setBusinessTypeValue] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await BusinessTypeApi.getBusinessType();
        setBusinessTypeValue(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [])

  const [showInputBox, setShowInputBox] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === 'b') {
        setShowInputBox((prevShowInputBox) => !prevShowInputBox);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <PageTabs showModal={showModal} />
      <div>
        <Joyride
          continuous
          run={joyrideState.run}
          steps={joyrideState.steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress
          callback={handleJoyrideCallback}
        />
        {[].map((item) => {
          return (
            <div
              key={item}
              id={`step-${item}`}
              style={{
                border: "1px solid white",
                width: "100px",
                height: "100px",
                background: "#0c1d2b",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
      <PageTabs />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">{t("ModifyCompany")}</h2>
        </div>
        <MainButton
          showModal={showModal}
          isHindiLanguage={true}
          isEnlishLanguage={true}
          isBtnSetting={true}
          loginbtn={true}
          SaveButton={UpdateCompany}
          UserDetails={UserDetails}
          PageName="Modifycompany"
        />
        {/* *******************{Modal Box Open}****************** */}
        <div>
          {isModalOpen && (
            <div onClick={hideModal} className="modal">
              <div onClick={handleModalClick} className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">{t("Setting")}</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            name="additionalDetails"
                            value={settingInputValues.additionalDetails}
                            onChange={handleSettingChangeCompany}
                            checked={settingInputValues.additionalDetails}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          {t("AdditionalDetails")}
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            name="vatGstDetails"
                            onChange={handleSettingChangeCompany}
                            checked={settingInputValues.vatGstDetails}
                            value={settingInputValues.vatGstDetails}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          {t("VAT/GST Details")}
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            name="upiPaymentDetails"
                            value={settingInputValues.upiPaymentDetails}
                            onChange={handleSettingChangeCompany}
                            checked={settingInputValues.upiPaymentDetails}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          {t("UPIPaymentDetails")}
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            name="faxNo"
                            onChange={handleSettingChangeCompany}
                            checked={settingInputValues.faxNo}
                            value={settingInputValues.faxNo}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Fax No
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input type="checkbox"
                            name="loginThroughBranchCode"
                            onChange={handleSettingChangeCompany}
                            checked={settingInputValues.loginThroughBranchCode}
                            value={settingInputValues.loginThroughBranchCode}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          LogIn Through With Branch Code
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <DateTime />
      </div>
      {/* ******************************************************************************************************************** */}
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  {/* ******************{Left Container}*************** */}
                  <div className="company-info-top-left">
                    <div className="company_info-text-im td-1">
                      {t("Company Details")}{" "}
                      {/* <button onClick={UpdateCompany}>update</button>    */}
                    </div>
                    <div className="">
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <input
                            id="step-1"
                            className="modify_im_input mode_category"
                            value={inputValues.name}
                            type="text"
                            required
                            name="name"
                            onChange={handleInputChange}
                            ref={input1Ref}
                            onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                          />
                          <label className="modify_p_text2">{t("Name")}</label>
                        </div>
                        <div className="input-box-m1 ">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            value={inputValues.printName}
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="printName"
                            ref={input2Ref}
                            onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                          />
                          <label className="modify_lbl_text2">
                            {t("Print Name")}
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_category"
                            type="text"
                            value={inputValues.shortName}
                            required
                            onChange={handleInputChange}
                            name="shortName"
                            ref={input3Ref}
                            onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                          />
                          <label className="modify_p_text2">
                            {t("Short Name")}
                          </label>
                        </div>
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            value={inputValues.branchCode}
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="branchCode"
                            ref={input4Ref}
                            onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                          />
                          <label className="modify_lbl_text2">Branch Code</label>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_category"
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="fySessionFrom"
                            value={inputValues.fySessionFrom}
                            ref={input5Ref}
                            onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                          />
                          <label className="modify_p_text2">
                            {t("F.Y.(Session From)")}
                          </label>
                        </div>
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="fySessionTo"
                            value={inputValues.fySessionTo}
                            ref={input6Ref}
                            onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                          />
                          <label className="modify_lbl_text2">
                            {t("F.Y.(Session To)")}
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          {showInputBox && (
                            <div className="input-box-m1" style={{ width: "100%" }}>
                              <select
                                className="modify_im_input mode_category"
                                type="text"
                                // onChange={handleInputChange}
                                // onChange={handleInputChangeEcom}
                                onChange={handlechangewithecom}
                                value={inputValues.businessTypes}
                                name="businessTypes"
                                ref={input7Ref}
                                onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                required
                              >
                                {(() => {
                                  if (businessTypeValue !== null && Array.isArray(businessTypeValue)) {  // && Array.isArray(stateInfo)
                                    return businessTypeValue.map((data) => (
                                      <option key={data.id} value={data.id}>
                                        {data.name}
                                      </option>
                                    ))
                                  }
                                })()}

                                {/* <option value="0">{t("General Store")}</option>
                            <option value="1">{t("Optical Store")}</option>
                            <option value="2">{t("Resto")}</option>
                            <option value="3">Medical Store</option> */}
                              </select>
                              <label className="modify_lbl_text3">
                                {t("Business Types")}
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="input-box-m1">
                          <select
                            onChange={handleInputChange}
                            className="modify_im_input mode_category"
                            type="text"
                            value={inputValues.defaultLanguage}
                            name="defaultLanguage"
                            required
                            ref={input8Ref}
                            onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                          >
                            <option value="0">English</option>
                            <option value="1">Hindi</option>
                            <option value="2">Bangali</option>
                          </select>
                          <label className="modify_lbl_text4">Langauge </label>
                        </div>
                      </div>
                      {/* ************************* URL ******************* */}
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <div className="input-box-m1" style={{ width: "100%" }}>
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="urL1"
                              value={inputValues.urL1}
                              ref={input5Ref}
                              onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                            />
                            <label className="modify_lbl_text3">App to Web Link</label>
                          </div>
                        </div>
                        <div className="input-box-m1">
                          <input
                            className="modify_im_input mode_category"
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="urL2"
                            value={inputValues.urL2}
                            ref={input5Ref}
                            onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                          />
                          <label className="modify_lbl_text4">Web to App Link </label>
                        </div>
                      </div>
                      {/* ********************** */}
                      {/* <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                  <input className="modify_im_input mode_category" type="text" required />
                                  <label className="modify_p_text2">Company Code</label>
                              </div>
                          </div> */}

                      <div className="AddressAreaInputHeadTop input-height2">
                        <div className="AddressAreaInputHead">
                          <div className="input-box-m2 ">
                            <input
                              className="AddressTextInputBox "
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="firmAddress1"
                              value={inputValues.firmAddress1}
                              ref={input9Ref}
                              onKeyDown={(event) => handleKeyPress(event, input10Ref)}
                            />
                            <input
                              className="AddressTextInputBox "
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="firmAddress2"
                              value={inputValues.firmAddress2}
                              ref={input10Ref}
                              onKeyDown={(event) => handleKeyPress(event, input11Ref)}
                            />
                            <input
                              className="AddressTextInputBox "
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="firmAddress3"
                              value={inputValues.firmAddress3}
                              ref={input11Ref}
                              onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                            />
                            <label className="AddressTextCountryAddress">
                              {t("Address")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="AddressContentBoxM1">
                          {/* <select
                            className="modify_im_input mode_category"
                            type="text"
                            required
                            onChange={handleInputChange}
                            value={inputValues.countryId}
                            name="countryId"
			                      ref={input12Ref}
                            onKeyDown={(event) => handleKeyPress(event, input13Ref)} 
                          >
                            <option className=" s-1 form-control" value="">
                              Select Country
                            </option>
                            <option className=" s-1 form-control" value="0">
                              {t("India")}
                            </option>
                            <option className=" s-1 form-control" value="2">
                              {t("Afghanistan")}
                            </option>
                            <option className=" s-1 form-control" value="3">
                              Åland Islands{t("Afghanistan")}
                            </option>
                            <option className=" s-1 form-control" value="4">
                              Albania
                            </option>
                            <option className=" s-1 form-control" value="5">
                              Algeria
                            </option>
                          </select> */}

                          <select
                            id=""
                            className="modify_im_input mode_category"
                            name="countryId"
                            type="text"
                            ref={input12Ref}
                            onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                            // onChange={handleInputChange}
                            onChange={countrychange}
                            value={inputValues.countryId}
                          >
                            {countryMain !== undefined &&
                              countryMain.length > 0 && (
                                <>
                                  {countryMain.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </>
                              )}
                          </select>

                          <label className="AddressTextCountry">
                            {t("Country")}
                          </label>
                        </div>
                        <div className="AddressContentBoxM2">
                          <select
                            id="stateDropdown"
                            className="modify_im_input mode_category"
                            name="stateId"
                            onChange={handleInputChange}
                            // value={inputValues.stateId}
                            ref={input13Ref}
                            onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                          >
                            {/* {stateInfoMain.map((data) => (
                              <option key={data.id} value={data.id}>
                                {data.stateName}
                              </option>
                            ))} */}

                            {(() => {
                              if (
                                stateInfoMain &&
                                Array.isArray(stateInfoMain) &&
                                stateInfoMain.length > 0
                              ) {
                                return stateInfoMain
                                  .filter(
                                    (state) =>
                                      state.countryId === selectedCountry
                                  )
                                  .map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.stateName}
                                    </option>
                                  ));
                              } else {
                                return (
                                  <option value="">No states available</option>
                                );
                              }
                            })()}

                            {/* {stateInfoMain
                              .filter(
                                (state) => state.countryId === selectedCountry
                              )
                              .map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.stateName}
                                </option>
                              ))} */}
                          </select>

                          <label className="AddressTextState">
                            {t("State")}
                            <span className="StarClr">
                              *
                              {errorMessages.stateId && (
                                <label className="errorMessage">
                                  {errorMessages.stateId}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                        {(() => {
                          var Check = isInputVisiblemc1 ? input15Ref : input27Ref;
                          return (
                            <div className="AddressContentBoxM3">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                value={inputValues.pinCode}
                                required
                                onChange={handleInputChange}
                                name="pinCode"
                                ref={input14Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, Check)
                                }
                              />
                              <label className="AddressTextPin">Pin</label>
                            </div>
                          );
                        })()}
                      </div>
                      {/* ************************ */}
                      {isInputVisiblemc1 && (
                        <div>
                          <div className="company_info-text-im">
                            {t("AdditionalDetails")}
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                required
                                onChange={handleInputChange}
                                name="currencySymbol"
                                value={inputValues.currencySymbol}
                                ref={input15Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input16Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("CurrencySymbol")}
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                required
                                value={inputValues.currencyString}
                                onChange={handleInputChange}
                                name="currencyString"
                                ref={input16Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input17Ref)
                                }
                              />
                              <label className="modify_lbl_text2">
                                {t("CurrencyString")}
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                value={inputValues.currencyUnit}
                                required
                                onChange={handleInputChange}
                                name="currencyUnit"
                                ref={input17Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input18Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("CurrencyUnit")}
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="number"
                                value={inputValues.currencyDecimalPlaces}
                                required
                                onChange={handleInputChange}
                                name="currencyDecimalPlaces"
                                ref={input18Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input19Ref)
                                }
                              />
                              <label className="modify_lbl_text2">
                                {t("CurrencyDecimalPlaces")}
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="number"
                                value={inputValues.dayIntervalForBackup}
                                required
                                onChange={handleInputChange}
                                name="dayIntervalForBackup"
                                ref={input19Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input20Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("DayIntervalForBackup")}
                              </label>
                            </div>
                            {(() => {
                              var Check = isInputVisiblemc3 ? input21Ref : input27Ref;
                              return (
                                <div className="input-box-m1">
                                  <input
                                    className="modify_im_input mode_limit mode_category"
                                    type="number"
                                    value={inputValues.ecomMinShopLimit}
                                    required
                                    onChange={handleInputChange}
                                    name="ecomMinShopLimit"
                                    ref={input20Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, Check)
                                    }
                                  />
                                  <label className="modify_lbl_text2">
                                    {t("EcomMinShopLimit")}
                                  </label>
                                </div>
                              )
                            })()}
                          </div>
                          <div className=" CompWidthMaster">
                            <div className="">
                              <select className="modify_im_input mode_category1" type="text" required
                                name="isVariationItmShowInCatPage"
                                onChange={handleInputChange}
                                value={inputValues.isVariationItmShowInCatPage} >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label className="modify_lbl_text3 userLeft1">
                                Is Variation Item Show in Category Page
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isInputVisiblemc3 && (
                        <div>
                          <div className="company_info-text-im">
                            {t("UPIPaymentDetails")}
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                value={inputValues.payeeName}
                                required
                                onChange={handleInputChange}
                                name="payeeName"
                                ref={input21Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input22Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("PayeeName")}
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                required
                                value={inputValues.upiId}
                                onChange={handleInputChange}
                                name="upiId"
                                ref={input22Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input23Ref)
                                }
                              />
                              <label className="modify_lbl_text2">
                                {t("UPI Id")}
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                required
                                onChange={handleInputChange}
                                value={inputValues.transferMsg}
                                name="transferMsg"
                                ref={input23Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input24Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("TransferMsg")}
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                required
                                onChange={handleInputChange}
                                value={inputValues.currencyCode}
                                name="currencyCode"
                                ref={input24Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input25Ref)
                                }
                              />
                              <label className="modify_lbl_text2">
                                {t("CurrencyCode")}
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                value={inputValues.transferVoucherNo}
                                required
                                onChange={handleInputChange}
                                name="transferVoucherNo"
                                ref={input25Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input26Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                {t("TransVouchNo")}
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                value={inputValues.payAmt}
                                type="number"
                                required
                                onChange={handleInputChange}
                                name="payAmt"
                                ref={input26Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input27Ref)
                                }
                              />
                              <label className="modify_lbl_text2">
                                {t("PayAmt")}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ******************{Right Container}*************** */}
                  <div className="company-info-top-right">
                    <div>
                      <div className="company_info-text-im">
                        {t("ContactDetails")}
                      </div>
                      <div className="HeadContentInputBpx">
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            value={UserDetails.canLst3DgtNo ? (maskMobileNo(inputValues.mobileNo)) : (inputValues.mobileNo)}
                            required
                            onChange={handleInputChange}
                            name="mobileNo"
                            // disabled={UserDetails.canLst3DgtNo0 ? false : true}
                            ref={input27Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input28Ref)
                            }
                          />
                          <label className="modifyTextLabel">
                            Mobile No
                            <span className="StarClr">
                              *
                              {errorMessages.mobileNo && (
                                <label className="errorMessage">
                                  {errorMessages.mobileNo}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            // value={inputValues.mobileNoForOTP}
                            value={UserDetails.canLst3DgtNo ? (maskMobileNo(inputValues.mobileNoForOTP)) : (inputValues.mobileNoForOTP)}
                            required
                            onChange={handleInputChange}
                            name="mobileNoForOTP"
                            ref={input28Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input29Ref)
                            }
                          />
                          <label className="modifyTextLabel">
                            Mobile No for OTP
                          </label>
                        </div>
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            required
                            // value={inputValues.whatsappNo}
                            value={UserDetails.canLst3DgtNo ? (maskMobileNo(inputValues.whatsappNo)) : (inputValues.whatsappNo)}
                            onChange={handleInputChange}
                            name="whatsappNo"
                            ref={input29Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input30Ref)
                            }
                          />
                          <label className="modifyTextLabel">WhatsApp No</label>
                        </div>
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            value={inputValues.email}
                            required
                            onChange={handleInputChange}
                            name="email"
                            ref={input30Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input31Ref)
                            }
                          />
                          <label className="modifyTextLabel">
                            {t("E-Mail")}
                            <span className="StarClr">
                              *
                              {errorMessages.email && (
                                <label className="errorMessage">
                                  {errorMessages.email}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_category"
                            type="text"
                            required
                            onChange={handleInputChange}
                            value={UserDetails.canLst3DgtNo ? (maskMobileNo(inputValues.telephoneNo)) : (inputValues.telephoneNo)}
                            // value={inputValues.telephoneNo}
                            name="telephoneNo"
                            ref={input31Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input32Ref)
                            }
                          />
                          <label className="modifyTextLabel">
                            {t("TelephoneNo")}&nbsp;
                          </label>
                        </div>

                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            value={inputValues.pan}
                            type="text"
                            required
                            onChange={handleInputChange}
                            name="pan"
                            ref={input32Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input33Ref)
                            }
                          />
                          <label className="modifyTextLabel">
                            {t("IT PAN")}
                          </label>
                        </div>

                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            value={inputValues.website}
                            required
                            onChange={handleInputChange}
                            name="website"
                            ref={input33Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input34Ref)
                            }
                          />
                          <label className="modifyTextLabel">website</label>
                        </div>
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            value={inputValues.facebook}
                            required
                            onChange={handleInputChange}
                            name="facebook"
                            ref={input34Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input35Ref)
                            }
                          />
                          <label className="modifyTextLabel">facebook</label>
                        </div>
                        {(() => {
                          var Check = isFaxNumber ? input36Ref : input37Ref;
                          return (
                            <div className="input-box-m11">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                value={inputValues.instagram}
                                required
                                onChange={handleInputChange}
                                name="instagram"
                                ref={input35Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, Check)
                                }
                              />
                              <label className="modifyTextLabel">
                                instagram
                              </label>
                            </div>
                          );
                        })()}
                        {isFaxNumber && (
                          <div className="input-box-m11">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              value={inputValues.fax}
                              required
                              onChange={handleInputChange}
                              name="fax"
                              ref={input36Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input37Ref)
                              }
                            />
                            <label className="modifyTextLabel">
                              {t("Fax no")}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {isInputVisiblemc2 && (
                      <div>
                        <div className="company_info-text-im">
                          {t("VAT/GST Details")}
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="enableVatGST"
                              value={inputValues.enableVatGST}
                              ref={input37Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input38Ref)
                              }
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <label className="modify_lbl_text3">
                              {t("Enable VAT/GST")}
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              // value={inputValues.Type}
                              type="text"
                              required
                              // onChange={handleInputChange}
                              name="Type"
                              ref={input38Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input39Ref)
                              }
                            >
                              <option>VAT</option>
                              <option>GST</option>
                            </select>
                            <label className="modify_lbl_text4">
                              {t("Type")}
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              value={inputValues.enableAddTaxSchg}
                              name="enableAddTaxSchg"
                              onChange={handleInputChange}
                              ref={input39Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input41Ref)
                              }
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                            <label className="modify_lbl_text3">
                              Enable Add. Tax/Schg.
                            </label>
                          </div>
                          <div className="input-box-m1 m-logo ">
                            <div className="modify-input-box-part-3">
                              <label
                                style={{ color: "black" }}
                                className="modify_lbl_text2"
                              >
                                {t("Set Logo")}
                              </label>
                            </div>
                            <div className="modify-input-box-part-1">
                              {showDeleteButton && (
                                <div
                                  className="delete-button LogoDeleteIcon"
                                  onClick={handleDeleteImage}
                                >
                                  <i className="fa-solid fa-xmark"></i>
                                </div>
                              )}
                              <label
                                htmlFor="file"
                                className="custum-file-upload"
                              >
                                <div className="iconLogo">
                                  <svg
                                    viewBox="0 0 24 24"
                                    fill=""
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      stroke-width="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                                        fill=""
                                      ></path>{" "}
                                    </g>
                                  </svg>
                                </div>
                                <input
                                  className="modify_im_input mode_limit mode_category col-1"
                                  type="file"
                                  id="file"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                              </label>
                            </div>
                            <div className="modify-input-box-part-2">
                              {selectedImage && (
                                <img
                                  src={selectedImage}
                                  alt="Selected"
                                  className="modify-img-logo"
                                  style={{ width: "70px", height: "45px" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              value={inputValues.gstTinNo}
                              required
                              onChange={handleInputChange}
                              name="gstTinNo"
                              ref={input41Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input42Ref)
                              }
                            />
                            <label className="modify_p_text2">
                              {t("GST TIN")}
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              value={inputValues.cstNo}
                              onChange={handleInputChange}
                              name="cstNo"
                              ref={input42Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input43Ref)
                              }
                            />
                            <label className="modify_lbl_text2">
                              {t("CST No /FSSAI No")}
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              value={inputValues.drugLicenseNo}
                              required
                              onChange={handleInputChange}
                              name="drugLicenseNo"
                              ref={input43Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input44Ref)
                              }
                            />
                            <label className="modify_p_text2">
                              {t("Drug License No")}
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="date"
                              value={inputValues.drugLicenseExpiryDate}
                              required
                              onChange={handleInputChange}
                              name="drugLicenseExpiryDate"
                              ref={input44Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input45Ref)
                              }
                            />
                            <label className="modify_lbl_text4">Exp Date</label>
                          </div>
                        </div>
                        <div className="input-box-tab-head">
                          <div className="input-box-m2">
                            <textarea
                              className="modify_im_textarea mode_category2"
                              type="text"
                              value={inputValues.termsCondition}
                              placeholder=""
                              required
                              onChange={handleInputChange}
                              name="termsCondition"
                              ref={input45Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input46Ref)
                              }
                            />
                            <label className="modify_p_text-fix">
                              {t("Terms&Condition")}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {CompmaySetting && (
                      <CompanySetting
                        input46Ref handleKeyPress
                        setFunction={handleInputChange}
                        inputValues={inputValues}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Updated successfully"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default ItemMaster;
