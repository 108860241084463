import React, { useState } from "react";

function BatchWiseModalBox(props) {
    const [selectedOption, setSelectedOption] = useState('single');
    const [, setInput1Visible] = useState(true);
    const [isInput2Visible, setInput2Visible] = useState(false);
    const [isInput4Visible, setInput4Visible] = useState(false);
    const [isInput5Visible, setInput5Visible] = useState(false);


    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'single') {
            setInput1Visible(true);
            setInput2Visible(false);
        } else if (event.target.value === 'bifocal') {
            setInput1Visible(false);
            setInput2Visible(true);
        } else {
            setInput1Visible(false);
            setInput2Visible(false);
        }
    };
    const ModalOPen4 = () => {
        setInput4Visible(true)
    }
    const ModalClose4 = () => {
        setInput4Visible(false)

    }

    const ModalOPen5 = () => {
        setInput5Visible(true)
    }
    const ModalClose5 = () => {
        setInput5Visible(false)

    }

    const [isModalOpen7, setModalOpen7] = useState(true);

    const hideModal7 = () => {
        setModalOpen7(false);
    };
    const [rows,] = useState(Array(3).fill({}));

    // const handleAddRow = () => {
    //     setRows([...rows, {}]);
    // };

    return (
        <div>
            {isModalOpen7 && (
                <div className="modal-back">
                    <div className="BatchWise-modal">
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h6 className="BatchWiseModalBox1">Item Serial No Setting</h6>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={hideModal7} >x</label>
                            </div>
                            <hr />
                            <div className="table-container-BatchWise" >
                                <div className="radio-btm-BatchWise">
                                    <div>
                                        <input
                                            className="radio-btn-n1" type="radio" id="Single"
                                            name="lensType" value="single"
                                            checked={selectedOption === 'single'}
                                            onChange={handleRadioChange} />
                                        <label className="radio-btn-BatchWise2" for="Single">
                                        Manual Numbering
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="radio-btn-n1" type="radio" id="Progresive"
                                            name="lensType" value="bifocal"
                                            checked={selectedOption === 'bifocal'}
                                            onChange={handleRadioChange} />
                                        <label className="radio-btn-BatchWise2" for="Progresive">
                                        Automatic Numbering
                                        </label>
                                    </div>
                                </div>

                                {isInput2Visible && (
                                    <div className="ItemSerialCol1">
                                        <div className="input-box-Im5 input-height2">
                                            <div style={{ paddingBottom: "10px", display: "flex", width: "100%" }}>
                                                <div style={{ width: "50%" }}>
                                                    <h6 className="modify_p_text2">Starting Auto Number</h6>
                                                </div>
                                                <div style={{ width: "50%" }}>
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                </div>
                                            </div >
                                            <div style={{ paddingBottom: "4px", display: "flex", width: "100%" }}>
                                                <div style={{ width: "50%" }}>
                                                    <h6 className="modify_p_text2Batch">Structure Name</h6>
                                                </div>
                                                <div style={{ width: "50%", display: "flex" }}>
                                                    <input className="modify_im_input mode_category" type="text" required />
                                                    <button onClick={ModalOPen4} className="BatchConfigBtn">Configuration</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="panel-head-button1">
                                    <div className="col_4">
                                        <div className="button-m1">
                                            <button id="step-5" className="save_btnBatch" >
                                                Save
                                                <i className="fa-regular fa-floppy-disk im-icon-1"></i>
                                            </button>
                                        </div>
                                        <div className="button-m2" >
                                            <button className="reset_btnBatch" >
                                                Back
                                                <i className="fa-solid fa-right-from-bracket im-icon-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {isInput4Visible && (
                    <div>
                        <div className="modal-back">
                            <div className="BatchWise-modal2">
                                <div style={{ padding: "15px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h6 className="BatchWiseModalBox1">Serial Number Structure</h6>
                                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={ModalClose4} >x</label>
                                    </div>
                                    <hr />
                                    <div className="table-container-BatchWise" >
                                        <div>
                                            <div className="ItemSerialCol1">
                                                <div className="input-box-Im5 input-height2">
                                                    <div style={{ paddingBottom: "4px", display: "flex", width: "80%" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <h6 className="modify_p_text2">Select Structure</h6>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                        </div>
                                                    <div className="InputBoxButton">
                                                        <i onClick={ModalOPen5} className="fa-solid fa-circle-plus icon_btn_batch"></i>
                                                        <i className="fa-solid fa-pen-to-square icon_btn_batch"></i>
                                                        <i className="fa-solid fa-trash icon_btn_batch"></i>
                                                    </div>
                                                    </div >
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" >
                                            <table className="table-t" style={{ border: "1px solid #bebebe" }}>
                                                <thead className="thead-groupmaster" style={{background:"#5e709e"}}>
                                                    <tr style={{ color: "#rgba(21, 50, 125, 0.62)" }}>
                                                        <th style={{ minWidth: "45px" }} className="table-th_Wise">Sn No</th>
                                                        <th className="table-th_Wise">Category</th>
                                                        <th className="table-th_Wise">Length</th>
                                                        <th className="table-th_Wise">Alignment</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows.map((row, rowIndex) => (
                                                        <tr key={rowIndex}  className="ttt">
                                                            <td style={{ textAlign: "center" }} >
                                                                {rowIndex + 1}
                                                            </td>
                                                            <td>
                                                                <select style={{ width: "190px" }}
                                                                    className="table-input-15"
                                                                    name={`changeFrom-${rowIndex}`}
                                                                    type="text" required>
                                                                    <option></option>
                                                                    <option>Company_Id</option>
                                                                    <option>Item_Name</option>
                                                                    <option>MC_Name</option>
                                                                    <option>Auto_No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="table-input-15" type="number"
                                                                    name={`taxCatg2-${rowIndex}`} />
                                                            </td>
                                                            <td>
                                                                <select style={{ width: "165px" }}
                                                                    className="table-input-15"
                                                                    name={`range-${rowIndex}`} type="text" required>
                                                                    <option></option>
                                                                    <option>Left</option>
                                                                    <option>Right</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="panel-head-button1">
                                            <div className="col_4">
                                                <div className="button-m1">
                                                    <button id="step-5" className="save_btnBatch" >
                                                        Save
                                                        <i className="fa-regular fa-floppy-disk im-icon-2"></i>
                                                    </button>
                                                </div>
                                                <div className="button-m2" onClick={ModalClose4} >
                                                    <button className="reset_btnBatch" >
                                                        Back
                                                        <i className="fa-solid fa-right-from-bracket im-icon-1"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {isInput5Visible && (
                    <div>
                        <div className="modal-back">
                            <div className="BatchWise-modal3">
                                <div style={{ padding: "15px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h6 className="BatchWiseModalBox1">Add Structure</h6>
                                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={ModalClose5} >x</label>
                                    </div>
                                    <hr />
                                    <div className="table-container-BatchWise" >
                                        <div>
                                            <div className="ItemSerialCol1">
                                                <div className="input-box-Im5 input-height2">
                                                    <div style={{ paddingBottom: "4px", display: "flex", width: "80%" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <h6 className="modify_p_text2">Structure Name</h6>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                        </div>
                                                    </div >
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" >

                                        </div>
                                        <div className="panel-head-button1">
                                            <div className="col_4">
                                                <div className="button-m1">
                                                    <button id="step-5" className="save_btnBatch" >
                                                        Save
                                                        <i className="fa-regular fa-floppy-disk im-icon-1"></i>
                                                    </button>
                                                </div>
                                                <div className="button-m2" onClick={ModalClose5} >
                                                    <button className="reset_btnBatch" >
                                                        Back
                                                        <i className="fa-solid fa-right-from-bracket im-icon-1"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default BatchWiseModalBox;
