import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Station = {

    insertStation: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Station`;

            //const apiUrl = `${express_server_url}/api/Station`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                name: data.name,
                printName: data.printName,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getStation: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Station/GetStationMasters`;
            //const apiUrl = `${express_server_url}/api/Station/GetStationMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get All ***************************
    getStationFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Station/GetStationMasters`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteStation: async (stationId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Station?stationId=${stationId}`;

            //const apiUrl = `${express_server_url}/api/Station?stationId=${stationId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getStationById: async (stationId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Station?stationId=${stationId}`;

            //const apiUrl = `${express_server_url}/api/Station?stationId=${stationId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateStation: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/Station?stationId=${data.id}`;

            //const apiUrl = `${express_server_url}/api/Station?stationId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                printName: data.printName,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default Station;
