import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const UserAuthentication = {

    insertUserAuthentication: async (data) => {
        
        const processedData = data.map(loop => ({
            branchId: loop.branchId,
            userId: loop.userId,
            modulId: loop.modulId,
            formId: loop.formId,
        })); 

        try {
            const apiUrl = `${WebApiUrl}/api/UserAuthentication/CreateUserAuthentication`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return  response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get All ****************************
    getUserAuthentications: async () => {
       
        try {
            const apiUrl = `${WebApiUrl}/api/UserAuthentication/GetUserAuthentication`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getUserAuthenticationById: async (userId) => {
        
        try {
            const apiUrl = `${WebApiUrl}/api/UserAuthentication?userId=${userId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteUserAuthentication: async (userId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UserAuthentication?userId=${userId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // *************************** Update ***************************

    updateUserAuthentication: async (data) => {
        
        const processedData = data.map(loop => ({
            branchId: loop.branchId,
            userId: loop.userId,
            modulId: loop.modulId,
            formId: loop.formId,
        }));

        var userId = processedData[0].userId;
        try {
            const apiUrl = `${WebApiUrl}/api/UserAuthentication?userId=${userId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default UserAuthentication;
