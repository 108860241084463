import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect } from "react";
import Joyride from 'react-joyride';
import 'react-quill/dist/quill.snow.css';
import ProductList from "../../Props/ProductList"
import BillPaymentInvpice from "../../Props/BillPaymentInvoice"


function SaleInvoice() {
    /***********title***************/
    useState(() => {
        document.title = 'Sale Invoice';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    //  /* *******************Tab****************** */

    const [tabcontent1, setTabContent1] = useState(true);
    const [tabcontent2, setTabContent2] = useState(false);
    const [tabcontent3, setTabContent3] = useState(false);
    const [tabcontent4, setTabContent4] = useState(false);
    const [tabcontent5, setTabContent5] = useState(false);
    const [tabcontent6, setTabContent6] = useState(false);


    const showtabs1 = () => {
        setTabContent1(true)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs2 = () => {
        setTabContent1(false)
        setTabContent2(true)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs3 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(true)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }
    const showtabs4 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(true)
        setTabContent5(false)
        setTabContent6(false)
    }
    const showtabs5 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(true)
        setTabContent6(false)
    }
    // const showtabs6 = () => {
    //     setTabContent1(false)
    //     setTabContent2(false)
    //     setTabContent3(false)
    //     setTabContent4(false)
    //     setTabContent5(false)
    //     setTabContent6(true)
    // }

    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // {/* *******************Tab****************** */}
    //  /* *******************Tab****************** */

    const [tabcontent11, setTabContent11] = useState(true);
    const [tabcontent22, setTabContent22] = useState(false);
    const [tabcontent33, setTabContent33] = useState(false);
    const [tabcontent44, setTabContent44] = useState(false);

    const showtabs11 = () => {
        setTabContent11(true)
        setTabContent22(false)
        setTabContent33(false)
        setTabContent44(false)
    }

    const showtabs22 = () => {
        setTabContent11(false)
        setTabContent22(true)
        setTabContent33(false)
        setTabContent44(false)
    }

    const showtabs33 = () => {
        setTabContent11(false)
        setTabContent22(false)
        setTabContent33(true)
        setTabContent44(false)
    }
    const showtabs44 = () => {
        setTabContent11(false)
        setTabContent22(false)
        setTabContent33(false)
        setTabContent44(true)
    }

    // {/* *******************Tab****************** */}

    //**********editer*****************//
    // const [convertedText, setConvertedText] = useState("Some default content");
    //  const [convertedText2, setConvertedText2] = useState("Some default content");

    //**********show hide*****************//
    const [isInputVisiblam1, setInputVisiblam1] = useState(false);
    const handleToggleChangam1 = () => {
        setInputVisiblam1(!isInputVisiblam1);
    };
    const [isInputVisiblam2, setInputVisiblam2] = useState(false);
    const handleToggleChangam2 = () => {
        setInputVisiblam2(!isInputVisiblam2);
    };
    const [isInputVisiblam3, setInputVisiblam3] = useState(true);
    const handleToggleChangam3 = () => {
        setInputVisiblam3(!isInputVisiblam3);
    };
    const [isInputVisiblam4, setInputVisiblam4] = useState(false);
    const handleToggleChangam4 = () => {
        setInputVisiblam4(!isInputVisiblam4);
    };
    const [isInputVisiblam5, setInputVisiblam5] = useState(false);
    const handleToggleChangam5 = () => {
        setInputVisiblam5(!isInputVisiblam5);
    };
    const [isInputVisiblam6, setInputVisiblam6] = useState(false);
    const handleToggleChangam6 = () => {
        setInputVisiblam6(!isInputVisiblam6);
    };
    const [isInputVisiblam7, setInputVisiblam7] = useState(false);
    const handleToggleChangam7 = () => {
        setInputVisiblam7(!isInputVisiblam7);
    };
    //**********show hide End*****************//

    const [isCheckboxDisabledsi3, setIsCheckboxDisabledsi3] = useState(true)
    const [isInputVisiblsi3, setInputVisiblsi3] = useState(true);
    const handleToggleChangsi3 = () => {
        setInputVisiblsi3(!isInputVisiblsi3);
        setIsCheckboxDisabledsi3(!isCheckboxDisabledsi3); // Toggle the disabled state
    };


    //**********show hide End*****************//

    //************modal box************/ /

    const [isModalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };
    const [isModalOpen1, setModalOpen1] = useState(false);

    const showModal1 = () => {
        setModalOpen1(true);
    };

    const hideModal1 = () => {
        setModalOpen1(false);
    };

    const [isModalOpen2, setModalOpen2] = useState(false);

    const showModal2 = () => {
        setModalOpen2(true);
    };

    const hideModal2 = () => {
        setModalOpen2(false);
    };



    //************modal box************/ /

    /************Category box****************/
    // const [rows, setRows] = useState(Array(20).fill({}));

    // const handleAddRow = () => {
    //     setRows([...rows, {}]);
    // };


    ///*****************Onboarding code Open************************///

    const [{ run, steps },] = useState({
        run: true,
        steps: [
            {
                content: <h2>Let's begin our journey!</h2>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body"
            },
            {
                content: <h2>Upload Item Image!</h2>,
                placement: "bottom",
                target: "#step-1",
                title: "First step"
            },
            {
                content: <h2>Here is second step!</h2>,
                placement: "bottom",
                target: "#step-2",
                title: "Second step"
            },
            {
                content: <h2>Here is third step!</h2>,
                placement: "bottom",
                target: "#step-3",
                title: "Third step"
            },
            {
                content: <h2>Here is fourth step!</h2>,
                placement: "bottom",
                target: "#step-4",
                title: "Fourth step"
            },
            {
                content: <h2>Here is fifth step!</h2>,
                placement: "bottom",
                target: "#step-5",
                title: "Fifth step"
            },
            {
                content: <h2>Here is six step!</h2>,
                placement: "bottom",
                target: "#step-6",
                title: "Six step"
            },
        ]
    });

    ///*****************Onboarding code Close************************//

    // *************************** Lens ***************************************//
    const [rows1, setRows1] = useState(Array(10).fill({}));

    // const handleAddRow1 = () => {
    //     setRows([...rows1, {}]);
    // };


    const handleInputChange12 = (rowIndex, e) => {
        // Handle input changes and update the corresponding row data
        const { name, value } = e.target;
        const updatedRows1 = [...rows1];
        updatedRows1[rowIndex][name] = value;
        setRows1(updatedRows1);
    };

    const handleDeleteRow1 = (rowIndex) => {
        // Create a copy of the rows1 array without the row to be deleted
        const updatedRows = rows1.filter((_, index) => index !== rowIndex);

        // Update the state with the modified rows array
        setRows1(updatedRows);
    }



    return (
        <div>
            {/* <TopNamePage heading="ModifyProduct"
            /> */}
            <div>
                {/* <Joyride
                    continuous
                    callback={() => { }}
                    run={run}
                    steps={steps}
                    hideCloseButton
                    scrollToFirstStep
                    showSkipButton
                    showProgress
                /> */}
                {
                    [].map((item) => {
                        return (
                            <div
                                key={item}
                                id={`step-${item}`}
                                style={{
                                    border: "1px solid white",
                                    width: "100px",
                                    height: "100px",
                                    background: "#0c1d2b",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>


            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Sale Invoice</h2>
                </div >
                {/* *******************Save Button****************** */}
                <div className="panel-head-button">
                    <div className="col_1">
                        <div className="button-m5">
                            <button id="step-2" className="setting_btn" onClick={showModal}>
                                Setting
                                <i className="fa-solid fa-gear im-icon-1"></i>
                            </button>
                        </div>
                        <div className="button-m3">
                            <button className="member_btn">
                                Member Card
                                <i className="fa-solid fa-user-group im-icon-1"></i>
                            </button>
                        </div>
                        <div className="button-m4">
                            <button className="optional_btn">
                                Optional Field
                                <i className="fa-solid fa-list im-icon-1"></i>
                            </button>
                        </div>
                        <div className="button-m2" >
                            <button className="reset_btn">
                                Reset
                                <i className="fa-solid fa-rotate im-icon-1"></i>
                            </button>
                        </div>
                        <div className="button-m1">
                            <button id="step-5" className="save_btn">
                                Save
                                <i className="fa-solid fa-file-export im-icon-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* *******************Save Button End****************** */}
                {/* <button className="modal-button" onClick={showModal}>Setting<i style={{ marginLeft: "3px" }} className="fa-solid fa-gear"></i></button> */}
                {/* *******************Modal Box Open****************** */}
                <div>
                    {isModalOpen && (
                        <div className="modal" >
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Account Master</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam1} checked={isInputVisiblam1} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Transport Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam2} checked={isInputVisiblam2} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Document Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam3} checked={isInputVisiblam3} />
                                                    <span></span>
                                                </label>
                                                <div className="modify_p_text2">Account Details<i className="fa-solid fa-circle-info ml-10"></i></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam4} checked={isInputVisiblam4} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Tax details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam5} checked={isInputVisiblam5} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Price Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam6} checked={isInputVisiblam6} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Item Setting<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam7} checked={isInputVisiblam7} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Min Sale Price<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}
                </div>

            </div>
            {/* *******************Modal Box End****************** */}
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left-i1 table-left-padding" >
                                        <div className="">
                                            <div className="input-box-head-fm1">
                                                <div className="input-box-content-l1">
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Bill Series</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                            <label className="modify_lbl_text6 m-23 ffm_h1">Bill No</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Date</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                            <label className="modify_lbl_text6 m-23 ffm_h1">Bill Types</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Godown</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                            <label className="modify_lbl_text6 m-23 ffm_h1">Booked By</label>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="input-box-content-r1">
                                                    <div className="input-box-bill ">
                                                        <div className="input-box-m6 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Party A/C</label>
                                                        </div>

                                                    </div>
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Address</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_limit mode_category input-s1" type="date" required />
                                                            <label className="modify_lbl_text-si1 ">DOB</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Mobile</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <select className="modify_im_input mode_limit mode_category input-s1" type="text" required>
                                                                <option Classname="form-control s-1" value="">Select state</option>
                                                                <option Classname="form-control s-1" value="AN">Andaman and Nicobar Islands</option>
                                                                <option Classname="form-control s-1" value="AP">Andhra Pradesh</option>
                                                                <option Classname="form-control s-1" value="AR">Arunachal Pradesh</option>
                                                                <option Classname="form-control s-1" value="AS">Assam</option>
                                                                <option Classname="form-control s-1" value="BR">Bihar</option>
                                                                <option Classname="form-control s-1" value="CH">Chandigarh</option>
                                                                <option Classname="form-control s-1" value="CG">Chhattisgarh</option>
                                                                <option Classname="form-control s-1" value="DN">Dadra and Nagar Haveli</option>
                                                                <option Classname="form-control s-1" value="DD">Daman and Diu</option>
                                                                <option Classname="form-control s-1" value="DL">Delhi</option>
                                                                <option Classname="form-control s-1" value="GA">Goa</option>
                                                                <option Classname="form-control s-1" value="GJ">Gujarat</option>
                                                                <option Classname="form-control s-1" value="HR">Haryana</option>
                                                                <option Classname="form-control s-1" value="HP">Himachal Pradesh</option>
                                                                <option Classname="form-control s-1" value="JK">Jammu and Kashmir</option>
                                                                <option Classname="form-control s-1" value="JH">Jharkhand</option>
                                                                <option Classname="form-control s-1" value="KA">Karnataka</option>
                                                                <option Classname="form-control s-1" value="KL">Kerala</option>
                                                                <option Classname="form-control s-1" value="LA">Ladakh</option>
                                                                <option Classname="form-control s-1" value="LD">Lakshadweep</option>
                                                                <option Classname="form-control s-1" value="MP">Madhya Pradesh</option>
                                                                <option Classname="form-control s-1" value="MH">Maharashtra</option>
                                                                <option Classname="form-control s-1" value="MN">Manipur</option>
                                                                <option Classname="form-control s-1" value="ML">Meghalaya</option>
                                                                <option Classname="form-control s-1" value="MZ">Mizoram</option>
                                                                <option Classname="form-control s-1" value="NL">Nagaland</option>
                                                                <option Classname="form-control s-1" value="OR">Odisha</option>
                                                                <option Classname="form-control s-1" value="PY">Puducherry</option>
                                                                <option Classname="form-control s-1" value="PB">Punjab</option>
                                                                <option Classname="form-control s-1" value="RJ">Rajasthan</option>
                                                                <option Classname="form-control s-1" value="SK">Sikkim</option>
                                                                <option Classname="form-control s-1" value="TN">Tamil Nadu</option>
                                                                <option Classname="form-control s-1" value="TG">Telangana</option>
                                                                <option Classname="form-control s-1" value="TR">Tripura</option>
                                                                <option Classname="form-control s-1" value="UP">Uttar Pradesh</option>
                                                                <option Classname="form-control s-1" value="UT">Uttarakhand</option>
                                                                <option Classname="form-control s-1" value="WB">West Bengal</option>
                                                            </select>
                                                            <label className="modify_lbl_text-si1 ">State</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-bill ffd">
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_category input-s1" type="text" required />
                                                            <label className="modify_p_text2 m-22">Acc bal</label>
                                                        </div>
                                                        <div className="input-box-m1 ddm1">
                                                            <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                            <label className="modify_lbl_text6  ffm_h1">Cr. Limit</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel-head-button1 ">
                                                <div className="col_587452 ssm">
                                                    <div className="button-m4">
                                                        <button className="order_list_btn" onClick={showModal1}>
                                                            Order List
                                                            <i className="fa-solid fa-list im-icon-1"></i>
                                                        </button>
                                                    </div>
                                                    <div className="button-m2" >
                                                        <button className="challan_list_btn" onClick={showModal2}>
                                                            Challan List
                                                            <i className="fa-solid fa-rotate im-icon-1"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {isModalOpen1 && (
                                                <div className="modal" >
                                                    <div className="modal-content1">
                                                        <div className="modal-title">
                                                            <h3 className="modal-h">Order List</h3>
                                                            <span className="close" onClick={hideModal1}>
                                                                &times;
                                                            </span>
                                                        </div>
                                                        <div className="modal-content-im">
                                                            <div className="table-container" >
                                                                <table className="table-t" >
                                                                    <thead className="thead-groupmaster">
                                                                        <tr>
                                                                            <th className="table-th table-sn">SN</th>
                                                                            <th className="table-th">Bill No</th>
                                                                            <th className="table-th">Date</th>
                                                                            <th className="table-th ">Bill Type</th>
                                                                            <th className="table-th">Blnc Qty</th>
                                                                            <th className="table-th">Total Amt</th>
                                                                            <th className="table-th">Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-overflow" style={{ overflowY: 'scroll', maxHeight: '50px' }}>
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>1343534</td>
                                                                            <td>10/01/2023</td>
                                                                            <td>GST</td>
                                                                            <td>Amount</td>
                                                                            <td>1000</td>
                                                                            <td>Pending</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            < PageTabs />
                                            {isModalOpen2 && (
                                                <div className="modal" >
                                                    <div className="modal-content1">
                                                        <div className="modal-title">
                                                            <h3 className="modal-h">Challan List</h3>
                                                            <span className="close" onClick={hideModal2}>
                                                                &times;
                                                            </span>
                                                        </div>
                                                        <div className="modal-content-im">
                                                            <div className="table-container" >
                                                                <table className="table-t" >
                                                                    <thead className="thead-groupmaster">
                                                                        <tr>
                                                                            <th className="table-th table-sn">SN</th>
                                                                            <th className="table-th">Bill No</th>
                                                                            <th className="table-th">Date</th>
                                                                            <th className="table-th ">Bill Type</th>
                                                                            <th className="table-th">Blnc Qty</th>
                                                                            <th className="table-th">Total Amt</th>
                                                                            <th className="table-th">Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-overflow" style={{ overflowY: 'scroll', maxHeight: '50px' }}>
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>1343534</td>
                                                                            <td>10/01/2023</td>
                                                                            <td>GST</td>
                                                                            <td>Amount</td>
                                                                            <td>1000</td>
                                                                            <td>Pending</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="table-right-padding"  >
                                        <div className="tabs-main">
                                            <div id="tabs " className="si-size-1">
                                                <div>
                                                    <div className="tab-btn-box1 tab-b2">

                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 5 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(5); showtabs1() }} >
                                                            Lens
                                                        </div>
                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 1 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(1); showtabs2() }} >
                                                            Cont
                                                        </div>
                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 2 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(2); showtabs3() }} >
                                                            Trans
                                                        </div>
                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 3 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(3); showtabs4() }} >
                                                            AR
                                                        </div>
                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 4 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(4); showtabs5() }} >
                                                            Prevs
                                                        </div>
                                                        <div
                                                            className={`ffm tab-b1 tab-1 ${activeTab === 6 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(6); showtabs5() }} >
                                                            <i className="fa-solid fa-arrows-rotate"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-content-box1 col-s5 ">
                                                    {tabcontent1 && (
                                                        <div className="tab-content-1">
                                                            <div className="input-box-top-head" >
                                                                <div className="input-box-head-left1">
                                                                    <div className="input-box-head-1 pp1">
                                                                        <div className="input-box-SI-1 bottm-01 h8">
                                                                            <div className="input-box-m1">
                                                                                <input className="modify_im_input mode_category input-s1 " type="text" required />
                                                                                <label className="modify_label_text6">Right</label>
                                                                            </div>
                                                                            <div className="input-box-m1 ">
                                                                                <input className="modify_im_input mode_limit mode_category input-s1 " type="text" required />
                                                                                <label className="modify_lbl_text15 m-24">Left</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >SPH</label>
                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2 row-m1">
                                                                                    <label className="col-sm-3 ">Add</label>
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input input-j1 box-size1" type="text" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                    }
                                                    {tabcontent2 && (
                                                        <div className="tab-content-2">
                                                            <div className="">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left1">
                                                                        <div className="input-box-head-1 pp1">
                                                                            <div className="input-box-SI-1  h7">
                                                                                <div className="input-box-m1 ">
                                                                                    <span className="ssp m-22">Right</span>
                                                                                </div>
                                                                                <div className="input-box-m1 ">
                                                                                    <span className="ssp2 m-22">Left</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 padding-1 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">V/N</label>
                                                                                        <label className="col-sm-3 ">AXIS</label>
                                                                                        <label className="col-sm-3 ">CYL</label>
                                                                                        <label className="col-sm-3 " style={{ marginLeft: "5px" }} >SPH</label>
                                                                                        <label className="col-sm-3 ">V/N</label>
                                                                                        <label className="col-sm-3 ">AXIS</label>
                                                                                        <label className="col-sm-3 ">CYL</label>
                                                                                        <label className="col-sm-3 ">SPH</label>
                                                                                        <label className="col-sm-3 ">PWR</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">D.V.</label>
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">N.V.</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2 row-m1">
                                                                                        <label className="col-sm-3 ">Add</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <label className="col-sm-3 p2 ">B&D</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <label className="col-sm-3  p2">B&D</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2 row-m1">
                                                                                        <label className="col-sm-3  p2">Rem.</label>
                                                                                        <input className="col-sm-5 input-sal3 p5 " type="text" required />
                                                                                        <input className="col-sm-5 input-sal3 p5 " type="text" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                    {tabcontent3 && (
                                                        <div className="tab-content-3">
                                                            <div className="input-box-top-head" >
                                                                <div className="input-box-head-left1">
                                                                    <div className="input-box-head-1 pp1">
                                                                        <div className="input-box-SI-1 input-height4 h9">
                                                                            <div className="input-box-m1 ">

                                                                                <span className="ssp m-22">Right</span>
                                                                            </div>
                                                                            <div className="input-box-m1 ">

                                                                                <span className="ssp2 m-22">Left</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 padding-1 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >SPH</label>
                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2">
                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                    <input className="col-sm-4 table-input box-size1" type="text" required />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-box-SI-2 ">
                                                                            <div className="input-box-m6">
                                                                                <div className="row mt-2 row-m1">
                                                                                    <label className="col-sm-3 ">Add</label>
                                                                                    <input className="col-sm-4 table-input" type="text" required />
                                                                                    <input className="col-sm-4 table-input input-j1" type="text" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                    {tabcontent4 && (
                                                        <div className="tab-content-4">
                                                            <div className="">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left1">
                                                                        <div className="input-box-head-1 pp1">
                                                                            <div className="input-box-SI-1 input-height4">
                                                                                <div className="input-box-m1 ">

                                                                                    <span className="ssp m-22">Right</span>
                                                                                </div>
                                                                                <div className="input-box-m1 ">

                                                                                    <span className="ssp2 m-22">Left</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 padding-1 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">V/N</label>
                                                                                        <label className="col-sm-3 ">AXIS</label>
                                                                                        <label className="col-sm-3 ">CYL</label>
                                                                                        <label className="col-sm-3 " style={{ marginLeft: "5px" }} >SPH</label>
                                                                                        <label className="col-sm-3 ">V/N</label>
                                                                                        <label className="col-sm-3 ">AXIS</label>
                                                                                        <label className="col-sm-3 ">CYL</label>
                                                                                        <label className="col-sm-3 ">SPH</label>
                                                                                        <label className="col-sm-3 ">PWR</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">D.V.</label>
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-3 table-input box-size1" type="text" required />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2">
                                                                                        <label className="col-sm-3 ">N.V.</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m6">
                                                                                    <div className="row mt-2 row-m1">
                                                                                        <label className="col-sm-3 ">Add</label>
                                                                                        <input className="col-sm-4 table-input box-size1" type="text" required />
                                                                                        <input className="col-sm-4 table-input input-j1 box-size1" type="text" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                    {tabcontent5 && (
                                                        <div className="tab-content-5">
                                                            <div className="">
                                                                <div className="input-box-m8">
                                                                    <table className="table-scrol1">
                                                                        <thead className="table-tbody">
                                                                            <tr className="table-tr5">
                                                                                <th className="table-th-1">SN</th>
                                                                                <th className=" td-col4">Vch No Date</th>
                                                                                <th className="table-th-2">
                                                                                    <div>
                                                                                        Right Lens
                                                                                    </div>
                                                                                    <div className="direct_coll-1">
                                                                                        <div>SPH</div>
                                                                                        <div>CYL</div>
                                                                                        <div>AXIS</div>
                                                                                        <div>ADD</div>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="table-th-2">
                                                                                    <div>
                                                                                        Left Lens
                                                                                    </div>
                                                                                    <div className="direct_coll-2">
                                                                                        <div>SPH</div>
                                                                                        <div>CYL</div>
                                                                                        <div>AXIS</div>
                                                                                        <div>ADD</div>
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className=" table-container-tbody" >

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                    {tabcontent6 && (
                                                        <div className="tab-content-5">
                                                            <div className="">
                                                                <div className="company-info-top-im">

                                                                    <div className="company-info-top-right-tab5" >
                                                                        <div className="company_info-text"></div>

                                                                        <div className="input-box-Im">
                                                                            <div className="input-box-m1">
                                                                                <div className="company_info-text">List Of All Related Product</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                            <div id="tabs si-size-2" className="si-size-2">
                                                <div className="tab-btn-box2 tab-b2">
                                                    <div className="radio-btn-1">
                                                        <input type="radio" id="All" className="radio-box-1 box-size1" name="options" value="All" />
                                                        <label className="llm-1" For="All">All</label>
                                                        <input type="radio" id="DV" className="radio-box-1 box-size1" name="options" value="DV" />
                                                        <label className="llm-1" For="DV">DV</label>
                                                        <input type="radio" className="radio-box-1 box-size1" id="NV" name="options" value="NV" />
                                                        <label className="llm-1" For="NV">NV</label>
                                                        <input type="radio" className="radio-box-1 box-size1" id="CL" name="options" value="CL" />
                                                        <label className="llm-1" For="CL">CL</label>
                                                        <input type="radio" className="radio-box-1 box-size1" id="Trans" name="options" value="Trans" />
                                                        <label className="llm-1" For="Trans">Trans</label>
                                                    </div>
                                                    <div className="radio-btn-2">
                                                        <input type="checkbox" className="radio-box-1 box-size1" id="R" value="R" />
                                                        <label className="llm-1" For="R">R</label>
                                                        <input type="checkbox" className="radio-box-1 box-size1" id="L" value="L" />
                                                        <label className="padding-02 llm-1" For="L">L</label>
                                                    </div>
                                                </div>

                                                {tabcontent1 && (
                                                    <div>
                                                        <div className="tab-content-box1 col-s6">
                                                            <div className="lens-table-col1">
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex iim">
                                                                        <span className="label-box-si-12">P.D.</span>
                                                                        <div className="input-box-v1 ppm-1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" placeholder="RL" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                        <div className="input-box-v1 ppm-1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" placeholder="L" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                        <div className="input-box-v1 ppm-1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" placeholder="R" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex iim">
                                                                        <span className="label-box-si-12 sol1">Next Visit</span>
                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14 mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14 mode_category3 input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex iim">
                                                                        <span className="label-box-si-12">RefBY</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" required />

                                                                        </div>

                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex iim">
                                                                        <span className="label-box-si-12 sol1">Lens Exp</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14 mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14 mode_category3 input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>

                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Lens For</span>
                                                                        <div className="input-box-v1">
                                                                            <div className="radio-btn-02">
                                                                                <input type="radio" id="All" className="radio-box-1 box-size1" name="options1" value="All" />
                                                                                <label For="All">All</label>
                                                                                <input type="radio" id="DV" className="radio-box-1 box-size1" name="options1" value="DV" />
                                                                                <label For="DV">DV</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol2">Lens Type</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Delivery</span>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol4">In Days</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14 mode_category input-s1 mid-size-01" type="number" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol3">Time</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="time" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="lens-table-col2"></div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabcontent2 && (
                                                    <div>
                                                        <div className="tab-content-box1 col-s6">
                                                            <div className="lens-table-col1">
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">P.D.</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" placeholder="RL" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" placeholder="L" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14 mode_category input-s1" type="text" placeholder="R" required />
                                                                            <label className="label-input-sa1"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Next Visit</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">RefBY</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />

                                                                        </div>

                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Lens Exp</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>

                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Lens For</span>
                                                                        <div className="input-box-v1">
                                                                            <div className="radio-btn-02">
                                                                                <input type="radio" id="All" className="radio-box-1 box-size1" name="options1" value="All" />
                                                                                <label For="All">All</label>
                                                                                <input type="radio" id="DV" className="radio-box-1 box-size1" name="options1" value="DV" />
                                                                                <label For="DV">DV</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol2">Lens Type</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Delivery</span>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol4">In Days</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1 mid-size-01" type="number" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol3">Time</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="time" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="lens-table-col2"></div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabcontent3 && (
                                                    <div>
                                                        <div className="tab-content-box1 col-s6">
                                                            <div className="lens-table-col1">
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">P.D.</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">RL</label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">L</label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">R</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Next Visit</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">RefBY</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />

                                                                        </div>

                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Lens Exp</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>

                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Lens For</span>
                                                                        <div className="input-box-v1">
                                                                            <div className="radio-btn-02">
                                                                                <input type="radio" id="All" className="radio-box-1 box-size1" name="options1" value="All" />
                                                                                <label For="All">All</label>
                                                                                <input type="radio" id="DV" className="radio-box-1 box-size1" name="options1" value="DV" />
                                                                                <label For="DV">DV</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol2">Lens Type</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Delivery</span>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol4">In Days</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1 mid-size-01" type="number" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol3">Time</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="time" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="lens-table-col2"></div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabcontent4 && (
                                                    <div>
                                                        <div className="tab-content-box1 col-s6">
                                                            <div className="lens-table-col1">
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">P.D.</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">RL</label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">L</label>
                                                                        </div>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                            <label className="label-input-sa1">R</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Next Visit</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">RefBY</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />

                                                                        </div>

                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol1">Lens Exp</span>

                                                                        <div className="input-box-m1 ">
                                                                            <select className="modify_im_input14  mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>

                                                                        </div>
                                                                        <input className="modify_im_input14  mode_category input-s1" type="DATE" required />
                                                                    </div>
                                                                </div>

                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Lens For</span>
                                                                        <div className="input-box-v1">
                                                                            <div className="radio-btn-02">
                                                                                <input type="radio" id="All" className="radio-box-1 box-size1" name="options1" value="All" />
                                                                                <label For="All">All</label>
                                                                                <input type="radio" id="DV" className="radio-box-1 box-size1" name="options1" value="DV" />
                                                                                <label For="DV">DV</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol2">Lens Type</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="text" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-s11 input-height5">
                                                                    <div className="input-box-m1 dis-flex">
                                                                        <span className="label-box-si-12">Delivery</span>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol4">In Days</span>
                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1 mid-size-01" type="number" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex">
                                                                        <span className="label-box-si-12 sol3">Time</span>

                                                                        <div className="input-box-v1">
                                                                            <input className="modify_im_input14  mode_category input-s1" type="time" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="lens-table-col2"></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="tab-head-side-right">
                                            <div className="">
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        < ProductList />

                                    </div>

                                </div>
                                <div className="tabs-main1">
                                    <div id="tabs" className="tab_a1">
                                        <div className="tab-btn-box1 tab-b3">
                                            <div
                                                className={`tab-b1 tab-2 ${activeTab === 11 ? 'active' : ''}`}
                                                onClick={() => { handleTabClick(11); showtabs11() }} >
                                                Sundry
                                            </div>
                                            <div
                                                className={`tab-b1 tab-2 ${activeTab === 22 ? 'active' : ''}`}
                                                onClick={() => { handleTabClick(22); showtabs22() }} >
                                                GST
                                            </div>

                                            <div
                                                className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}
                                                onClick={() => { handleTabClick(33); showtabs33() }} >
                                                Coupon
                                            </div>

                                            <div
                                                className={`tab-b1 tab-2 ${activeTab === 44 ? 'active' : ''}`}
                                                onClick={() => { handleTabClick(44); showtabs44() }} >
                                                Loyalty
                                            </div>
                                        </div>

                                        <div className="tab-content-box1 ">
                                            {tabcontent11 && (
                                                <div className="tab-content-1">
                                                    <div className="input-box-top-head" >
                                                        <div className="">
                                                            <div>
                                                                <table className="table-scrol1">
                                                                    <thead className="table-tbody">
                                                                        <tr className="table-tr1">
                                                                            <th className="table-th-1">SN</th>
                                                                            <th className=" td-col2">Taxes & Othr Charge(Sundry)</th>
                                                                            <th className="table-th-6">@%</th>
                                                                            <th className="table-th-5">Amount</th>
                                                                            <th className="table-th-4">Action</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody className=" table-container-tbody" >
                                                                        {rows1.map((row, rowIndex) => (
                                                                            <tr className="table-tr1" key={rowIndex} >
                                                                                <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                                    {rowIndex + 1}
                                                                                </td>
                                                                                <td className="td-col2">
                                                                                    <input
                                                                                        className="table-input-13 input-box-td1"
                                                                                        type="text"
                                                                                        name={`Sundry-${rowIndex}`}
                                                                                        value={row[`Sundry-${rowIndex}`] || ""}
                                                                                        onChange={(e) => handleInputChange12(rowIndex, e)}
                                                                                    />
                                                                                </td>
                                                                                <td className="table-td-2">
                                                                                    <input
                                                                                        className="table-input-13 input-box-td2"
                                                                                        type="text"
                                                                                        name={`percent-${rowIndex}`}
                                                                                        value={row[`percent-${rowIndex}`] || ""}
                                                                                        onChange={(e) => handleInputChange12(rowIndex, e)}
                                                                                    />
                                                                                </td>
                                                                                <td className="table-td-2">
                                                                                    <input
                                                                                        className="table-input-13 input-box-td2"
                                                                                        type="text"
                                                                                        name={`Amount-${rowIndex}`}
                                                                                        value={row[`Amount-${rowIndex}`] || ""}
                                                                                        onChange={(e) => handleInputChange12(rowIndex, e)}
                                                                                    />
                                                                                </td>
                                                                                <td className="table-td-2" style={{ textAlign: "center" }}>
                                                                                    <button className="btn-table1" onClick={() => handleDeleteRow1(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }


                                            {tabcontent22 && (
                                                <div className="tab-content-1">
                                                    <div className="input-box-top-head" >
                                                        <div className="input-box-head-left1">
                                                            <div className="input-box-head-1">
                                                                <div className="input-box-SI-2 ">
                                                                    <div className="input-box-m7">
                                                                        <div className="button-col-s1">
                                                                            <button type="button" className="button-col-s2">Bill Wise</button>
                                                                        </div>
                                                                        <div className="button-col-s1">
                                                                            <button type="button" className="button-col-s2">Item Wise</button>
                                                                        </div>
                                                                        <div className="button-col-s1">
                                                                            <span className="color-col-s1"></span>
                                                                            Tax not calculated
                                                                        </div>
                                                                        <div className="button-col-s1">
                                                                            <span className="color-col-s4"></span>
                                                                            Tax not apply
                                                                        </div>
                                                                        <div className="button-col-s1">
                                                                            <span className="color-col-s3"></span>
                                                                            Tax value not apply
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                            {tabcontent33 && (
                                                <div className="tab-content-2">
                                                    <div className="">
                                                        <div className="input-box-top-head" >
                                                            <div className="input-box-head-left1">
                                                                <div className="input-box-head-1">
                                                                    <div className="search-panel-col-1">
                                                                        <div>
                                                                            <div className="input-box-SI-1 input-height5">
                                                                                <div className="input-box-m3">
                                                                                    <input className="modify_im_input mode_category input-s1" type="text" required />
                                                                                    <label className="modify_p_text2 m-22">Enter Coupon Name</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="panel-all-btn">

                                                                            <div className="panel-col-1">
                                                                                <button className="panel-search-btn">Search</button>
                                                                            </div>
                                                                            <div className="panel-col-2">
                                                                                <button className="panel-reset-btn ">Reset</button>
                                                                            </div>
                                                                            <div className="panel-col-3">
                                                                                <button className="panel-buy-btn">
                                                                                    Apply All Buy X Get Y Free
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <td>S.No.</td>
                                                                                <td>Coupon Details</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td>
                                                                                    Code: FRAMEFREE, Expire Date: 26-06-2024, Min. Amt: 200
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                            {tabcontent44 && (
                                                <div className="tab-content-3">
                                                    <div className="input-box-top-head" >
                                                        <div className="input-box-head-left2">
                                                            <div className="input-box-head-1">
                                                                <table >
                                                                    <thead>
                                                                        <tr>
                                                                            <td>S.No.</td>
                                                                            <td>Item Details</td>
                                                                            <td>Loyalty Point</td>
                                                                            <td>Loyalty Amount</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>Lens</td>
                                                                            <td>50</td>
                                                                            <td>
                                                                                50
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="table-row1">
                                        <div className="col_58">Bill Payment Details</div>
                                        <div>
                                            < BillPaymentInvpice />

                                        </div>
                                    </div>

                                    <div className="table-row2">
                                        <span className="table-ax-1"></span>
                                        <div className="tbody-col-2">
                                            <div className="input-box-i1">
                                                <div className="input-box-Im col-xl-1">
                                                    <div className="input-box-m1">
                                                        <label className="modify_p_text7">Taxable Value</label>
                                                    </div>
                                                    <div className="input-box-m1 col-xl-2">
                                                        <input className="modify_im_input12 mode_limit mode_category" type="text" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-i1">
                                                <div className="input-box-Im col-xl-1">
                                                    <div className="input-box-m1">
                                                        <label style={{ fontWeight: "600" }} className="modify_p_text7 ">Gross Value</label>
                                                    </div>
                                                    <div className="input-box-m1 col-xl-2">
                                                        <input style={{ fontWeight: "600" }} className="modify_im_input12 mode_limit mode_category" type="text" required />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="input-box-i1">
                                                <div className="input-box-Im col-xl-1">
                                                    <div className="input-box-m1">
                                                        <label className="modify_p_text7">Allow Loyalty</label>
                                                    </div>
                                                    <div className="input-box-m1 col-xl-2">
                                                        <div className="col-d-1">
                                                            <input onChange={handleToggleChangsi3} disabled={isCheckboxDisabledsi3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                            <label className="modify_p_text_checkbox col-in-1"> Loyalty Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-i1">
                                                <div className="input-box-Im col-xl-1">
                                                    <div className="input-box-m1">
                                                        <label className="modify_p_text7">Add Discount</label>
                                                    </div>
                                                    <div className="input-box-m1 col-xl-2">
                                                        <div className="col-d-1">
                                                            <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                            <label className="modify_p_text_checkbox col-in-1 col_ffn1"> (Party Wise Catgry Disc)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="input-remark-01">
                                    <div className="input-remark-left">
                                        <div className="input-remark-m1">
                                            <div className="input-remark-001 padding-03">
                                                <div className="input-size-04">
                                                    <span className=" sol4">Remarks:-</span>
                                                </div>
                                                <div className="input-box-v1 input-size-01">
                                                    <input className="modify_im_input15 mode_cat3 input-s1 mode_h1 mode_cat6" type="text" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-remark-right bmm_5">
                                        <div className="input-remark-m1">
                                            <div className="input-box-s11 input-height5 ffm4">
                                                <div className="input-box-m9 hhm1 dis-flex padding-03">
                                                    <span className=" sol4 sol5">Delivery Date</span>
                                                    <div className="input-box-v1 col-hm3">
                                                        <input className="modify_im_input15 mode_cat3 input-s1 mid-size-01" type="date" required />
                                                    </div>

                                                </div>
                                                <div className="input-box-m9 hhm1 dis-flex padding-05">
                                                    <div className="input-box-v1 col-hm4">
                                                        <div className=" ">
                                                            <select className="modify_im_input15 mode_limit mode_cat3 input-s1" type="text" required>
                                                                <option Classname="form-control s-1" value="Pending">Pending</option>
                                                                <option Classname="form-control s-1" value="Approved">Approved</option>
                                                                <option Classname="form-control s-1" value="Cancel">Cancel</option>
                                                                <option Classname="form-control s-1" value="Conv_Pur_Challan">Conv. to Pur Challan</option>
                                                                <option Classname="form-control s-1" value="Conv_Pur_Puechase">Conv. to Pur Puechase</option>
                                                                <option Classname="form-control s-1" value="Conv_Pur_Sale">Conv. to Pur Sale</option>
                                                                <option Classname="form-control s-1" value="Conv_Pur_Sale_Challan">Conv. to Pur Sale Challan</option>
                                                                <option Classname="form-control s-1" value="Delay">Delay</option>
                                                                <option Classname="form-control s-1" value="Delivered">Delivered</option>
                                                                <option Classname="form-control s-1" value="Done">Done</option>
                                                                <option Classname="form-control s-1" value="Done_with_Verified">Done with Verified</option>
                                                                <option Classname="form-control s-1" value="Exchange_In">Exchange In</option>
                                                                <option Classname="form-control s-1" value="Exchange_Out">Exchange Out</option>
                                                                <option Classname="form-control s-1" value="On_Process">On Process</option>
                                                                <option Classname="form-control s-1" value="Put_of_Shippment">Put of Shippment</option>
                                                                <option Classname="form-control s-1" value="Processing">Processing</option>
                                                                <option Classname="form-control s-1" value="Ready">Ready</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="input-remark-box-m1 ">
                                                        <select className="modify_im_input15 mode_limit mode_cat3 input-s1" type="text" required>
                                                            <option Classname="form-control s-1" value="">PREMARK1</option>
                                                            <option Classname="form-control s-1" value="AN">Special Customer</option>
                                                            <option Classname="form-control s-1" value="AP">Test1</option>
                                                            <option Classname="form-control s-1" value="AR">Wrong Order</option>
                                                            <option Classname="form-control s-1" value="AR">Conv. to Pur Puechase</option>
                                                        </select>
                                                    </div>


                                                </div>
                                                <div className="input-box-m9 hhm1 dis-flex padding-05">

                                                    <div className="input-remark-box-m1 ">
                                                        <select className="modify_im_input15 mode_limit mode_cat3 input-s1" type="text" required>
                                                            <option Classname="form-control s-1" value="">TEST2</option>
                                                            <option Classname="form-control s-1" value="AN">CAMP CUSTOMER</option>
                                                        </select>
                                                    </div>
                                                    <div className="input-remark-box-m1 ">
                                                        <select className="modify_im_input15 mode_limit mode_cat3 input-s1" type="text" required>
                                                            <option Classname="form-control s-1" value="">CHARITY</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}


export default SaleInvoice;
