import { useState, useEffect, useRef } from "react";
import "./DeliveryStylesheet.css";
import { } from 'react-router-dom'
import TransporterAPI from "../../API/APIServices/Transporter";
import Order from "../../API/APIServices/Order";
import Loader from "../../Inventory/Props/Loader";

const Timer = ({ time, openBox, isRunning, handlePause, isModalOpen, hideModal, handleModalClick, openingKmValue, handleOpeningKmChange,
    closingKmValue, handleCapture, inputRef, capturedImages, handleDelete, handleImageChange, images, isStartOrClose,
    handleStart, handleStop
}) => {

    console.log("isModalOpen", isModalOpen);
    const [loading, setLoading] = useState(false);

    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <div>
            <div>
                {/* <div className="flex-start" style={{ paddingLeft: "15px" }}>
                    <label className="ques" style={{ fontSize: "20px" }}>Time : &nbsp;</label>
                    <span className="c-b" style={{ fontSize: "20px" }}>{String(time.hours).padStart(2, '0')}:</span>
                    <span className="c-b" style={{ fontSize: "20px" }}>{String(time.minutes).padStart(2, '0')}:</span>
                    <span className="c-b" style={{ fontSize: "20px" }}>{String(time.seconds).padStart(2, '0')}</span>
                    <span>&nbsp; &nbsp;<i class="fa-solid fa-circle-info" style={{ fontSize: "16px", color: "darkcyan" }} onClick={openBox}></i></span>
                </div> */}
            </div>

            <div className="button-container mt-2">
                <button className="timer-button" onClick={() => openBox("Start")} disabled={isRunning}>
                    <i class="fa-solid fa-circle-play" style={{ fontSize: "22px", color: "green" }}></i><br /> <b>Start</b>
                </button>
                {/* <button className="timer-button" onClick={handlePause} disabled={!isRunning}>
                    <i class="fa-solid fa-circle-pause" style={{ fontSize: "22px", color: "dimgray" }}></i><br /> Pause
                </button> */}
                <button className="timer-button" onClick={() => openBox("Stop")}>
                    <i class="fa-solid fa-circle-stop" style={{ fontSize: "22px", color: "red" }}></i><br /> <b>Stop</b>
                </button>
            </div>
            {/* ************************* Open Modal ************************* */}
            {loading && <Loader />}
        </div>
    );
};

export default Timer;
