import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
import ItemNameList from "../../Props/ItemNameList";
import PreviousOrder from "../../Dashboard/PreviousOrder";
import MainButton from "../../Props/MainButton";


function StockIn() {
    /***********title***************/
    useState(() => {
        document.title = 'Stock In';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);


    //*****************{Onboarding code Open}************************//



    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [isSliderOpen, setSliderOpen] = useState(false);
    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        {/* <TopNamePage heading="ModifyProduct"/> */}


                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Stock In</h2>
                            </div >

                            {/* *******************Top Buttons****************** */}
                            <MainButton
                            />

                        </div>
                        {/* *******************Modal Box End****************** */}
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">   {/*height25vh*/}
                                                <div className="company-info-top-left Purchase-invoice-table content-stock-height">
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" style={{ fontSize: "14px" }} required>
                                                                            <option>Optic World</option>
                                                                            <option>Techcherry</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Firm Name</label>
                                                                    </div>

                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" style={{ fontSize: "14px" }} required>
                                                                            <option>FR</option>
                                                                            <option>MC1</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Firm Godown</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom " style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" id="Godown" style={{ margin: "1%", display: "none" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Godown</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Booked By</label>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1 pur-body-width">
                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%", margin: "1%" }}>
                                                                    <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" style={{ width: "20%" }}>
                                                                        <option>Mr.</option>
                                                                        <option>Mrs.</option>
                                                                        <option>Ms.</option>
                                                                    </select>

                                                                    <div className="input-containerEffect input-pur-invoice" id="PartyInput" style={{ margin: "0px 5px", width: "100%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" id="PartySelectBox" required style={{ fontSize: "14px" }}>
                                                                        </input>

                                                                        <label id="input1" className="labelEffect1">Party Name</label>
                                                                    </div>
                                                                    <div onClick={toggleSlider} className="btn-lavender ">
                                                                        <i className="fa-solid fa-right-from-bracket" onClick={toggleSlider}></i>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2 input-stock-bottom stock-margin-top" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffect1">Date &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" style={{ fontSize: "14px" }} required>
                                                                            <option>FR</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Godown</label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" style={{ fontSize: "14px" }} required>
                                                                            <option>Stock In</option>

                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Bill Series(STK OUT)</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No(STK OUT)</label>
                                                                    </div>
                                                                </div>
                                                                {/***********/}
                                                                {/* <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="addedGridView Right-Pur-Flex" style={{ marginTop: "-5px" }}>
                                                                        {(() => {
                                                                            if (selectedData.length != null) {
                                                                                //console.log(selectedData);
                                                                                return (
                                                                                    selectedData.map((data) => (
                                                                                        <div className="input-containerEffect mt-2 input-pur-invoice" style={{ margin: "1%" }} key={data.id}>
                                                                                            <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" style={{ width: "100%" }} required />
                                                                                            <label id="input1" className="labelEffect1">{data.name}</label>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            return console.log("not found");;
                                                                        })()}
                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                        {/*********************** Order List ********************/}
                                                        {/* {isModalOpenCopy && (
                                                            <div className="modal" >
                                                                <div className="AddNewItem-modal modal_level_ordlist">
                                                                    <div className="modal-title">
                                                                        <h3 className="modal-OrderLIst">Transaction List</h3>
                                                                        <span className="close_modal" onClick={hideModalCopy}>
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                    <div className="ModalContentModalSettingP ">
                                                                        <ModalCopyTransaction />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )} */}

                                                        <PageTabs />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="input-box-Imo1 input-heightAddItem StockOut_Copy">
                                                <div className="AddNewItem_Input_Box check_box_b1 ">
                                                    <label className="modify_AddNewItem_checkbox particular_font">Particular</label>
                                                </div>
                                            </div>
                                            {/****************************************************** Product Table **************************************************************************/}
                                            <div /*className="height30vh"*/ >
                                                <ItemNameList />
                                            </div>
                                            {/******************************************************* Total Details *************************************************************************/}
                                            {/* <div className="tabs-main_stock height30vh"> */}
                                            {/* <div id="tabs" className="tab_stock_remark"> 
                                                    <div className="tab-content-box1 ">
                                                        <div className="space-between mt-1" style={{ padding: "5px" }}>
                                                            <div className="input-box-SI input-height2 input-stock-bottom" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Remark</label>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            {/***************************** Total **************************/}
                                            {/* <div className="table-row2">
                                                    <span className="table-ax-1"></span>
                                                    <div className="padding-20 totalBg-color" style={{ backgroundColor: "rgba(94, 112, 158, 0.12)", height: "100%" }}>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Amount</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Gross Amt</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* </div> */}
                                            <div id="saleRemarkPart" style={{ display: "block" }}>
                                                <div className="stock-flex-start saleRemarkPart">
                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "55%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding" type="text" required />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default StockIn;

