import React, { useState, useEffect } from 'react'
const Text = (props) => {

  const [getValueAndStrg, setGetValueAndStrg] = useState([])

  useEffect(() => {
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };
  let getId = props.keyValueId;
  return (
    <div style={{ position: "relative" }} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover} id={props.keyValueId}
      draggable="true" className="draggable-text-web-template" onClick={deletebuttonshow}>
      <input className="draggable-txt-input-web-template" type="text" placeholder={"You Can write your text here"} />
    </div>
  )
}

export default Text