import React from "react";
import { Link } from "react-router-dom";

const SideBarSubCategory = (props) => {
  return (
    <Link
      to={props.Link}
      onClick={props.onClick}
      className="yes-bm-sub-category-box"
      style={{ height: `${props.height}px`, opacity: `${props.Opacity}` }}
    >
      <i className="fa-solid fa-caret-right floting-arrow"></i>
      {props.heading}
      <i className="fa-solid fa-caret-right fixed-arrow"></i>
    </Link>
  );
};

export default SideBarSubCategory;
