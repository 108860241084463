import React, { useState } from 'react'
import Dropdown from './Dropdown'

const GeneralSetting = () => {
    const options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ];

    const orderstatusoptions = [
        { label: 'Eccom Order Status', value: 'Eccom Order Status' },
        { label: 'Lens Order Status', value: 'Lens Order Status' }
    ];

    const Displayorderoptions = [
        { value: 'lowToHigh', label: 'Price: Low To High' },
        { value: 'highToLow', label: 'Price: High To Low' },
        { value: 'new', label: 'New' },
        { value: 'popular', label: 'Popular' },
    ];

    const [dropdownStates, setDropdownStates] = useState({
        variationItemShowInCategory: '',
        allowOTPCheck: '',
        allowLoyaltyPointSetting: '',
        allowToDisplayCouponListInBuyPage: '',
        allowTrackDeliveryInIndex: '',
        allowTrackOrderStatus: '',
        allowTestimonialInIndex: '',
        allowToAddOldCartProduct: '',
        productDisplayOrder: '',
        orderDisplayInIndexPage: '',
        allowToShowViewCounter: '',
        allowVariationImg: '',
        showMenuInCategoryTree: '',
        forceDownloadApp: '',
        forceLoginRating: ''
    });

    const handleDropdownChange = (key, value) => {
        setDropdownStates(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    console.log("Current States", dropdownStates)
    return (
        <div>
            <div className='heading-setting'>General Setting</div>
            <div className='grid-container'>
                <div className='grid-item-container'>
                    <Dropdown label={"Variation Item Show in Category"} options={options} selectedValue={dropdownStates.variationItemShowInCategory} onChange={(value) => handleDropdownChange('variationItemShowInCategory', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow OTP check"} options={options} selectedValue={dropdownStates.allowOTPCheck} onChange={(value) => handleDropdownChange('allowOTPCheck', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow Loyalty Point Setting"} options={options} selectedValue={dropdownStates.allowLoyaltyPointSetting} onChange={(value) => handleDropdownChange('allowLoyaltyPointSetting', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow to display Coupon list in Buy Page"} options={options} selectedValue={dropdownStates.allowToDisplayCouponListInBuyPage} onChange={(value) => handleDropdownChange('allowToDisplayCouponListInBuyPage', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow Track Delivery In Index"} options={options} selectedValue={dropdownStates.allowTrackDeliveryInIndex} onChange={(value) => handleDropdownChange('allowTrackDeliveryInIndex', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow Track Order Status"} options={orderstatusoptions} selectedValue={dropdownStates.allowTrackOrderStatus} onChange={(value) => handleDropdownChange('allowTrackOrderStatus', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow Testimonial in Index"} options={options} selectedValue={dropdownStates.allowTestimonialInIndex} onChange={(value) => handleDropdownChange('allowTestimonialInIndex', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow to add Old cart Product"} options={options} selectedValue={dropdownStates.allowToAddOldCartProduct} onChange={(value) => handleDropdownChange('allowToAddOldCartProduct', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Product Display Order"} options={Displayorderoptions} selectedValue={dropdownStates.productDisplayOrder} onChange={(value) => handleDropdownChange('productDisplayOrder', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Order Display in indexPage"} options={options} selectedValue={dropdownStates.orderDisplayInIndexPage} onChange={(value) => handleDropdownChange('orderDisplayInIndexPage', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow to Show View Counter"} options={options} selectedValue={dropdownStates.allowToShowViewCounter} onChange={(value) => handleDropdownChange('allowToShowViewCounter', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Allow Variation Img"} options={options} selectedValue={dropdownStates.allowVariationImg} onChange={(value) => handleDropdownChange('allowVariationImg', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Show Menu(F) in Category Tree"} options={options} selectedValue={dropdownStates.showMenuInCategoryTree} onChange={(value) => handleDropdownChange('showMenuInCategoryTree', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Force Download App"} options={options} selectedValue={dropdownStates.forceDownloadApp} onChange={(value) => handleDropdownChange('forceDownloadApp', value)} />
                </div>
                <div className='grid-item-container'>
                    <Dropdown label={"Force Login Rating"} options={options} selectedValue={dropdownStates.forceLoginRating} onChange={(value) => handleDropdownChange('forceLoginRating', value)} />
                </div>
                <div className='grid-item-container button-container'>
                    <button className='submit-button'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default GeneralSetting
