import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryPointName = {

    insertDeliveryPointName: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName/DeliveryPointName`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                name: data.name,
                description: data.description,
                isDefault: Boolean(data.isDefault),
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getDeliveryPointNames: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName/GetDeliveryPointNames`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Delete ****************************
    deleteDeliveryPointName: async (deliveryPointNameId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName?deliveryPointNameId=${deliveryPointNameId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get By Id ****************************
    getDeliveryPointNameById: async (deliveryPointNameId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName/DeliveryPointName?deliveryPointNameId=${deliveryPointNameId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateDeliveryPointName: async (data) => {
        console.log(data)
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName?deliveryPointNameId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                description: data.description,
                isDefault: Boolean(data.isDefault),
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get Delivery Point With Filter ***************************
    getDeliveryPointNamesWithFilter: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryPointName/GetDeliveryPointNames`;
            const response = await axios.post(apiUrl,
                {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 50000
                },
                {
                    withCredentials: true,
                });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    }

};

export default DeliveryPointName;
