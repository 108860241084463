
import "../../css/resposive.css";
function Savebutton() {
    return (
        <div className="buttom-box">
            <div className="button-btn-1">
                <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
                <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
                <button className="btn-3" ><i className="fa-solid fa-share"></i></button>
            </div>
        </div>
    )

}

export default Savebutton;