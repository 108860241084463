import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

function CompanySetting(props) {
  // const handleInputChangeInChild = (event) => {
  //   const { name, value } = event.target;
  //   props.setFunction(name, value);
  // };
  const navigate = useNavigate();

  const MobileOrder = (event) => {
    handleInputChangeEcom(event);
    props.setFunction(event);
  };

  const EnableWebsite = (event) => {
    handleInputChangeEcom6(event);
    props.setFunction(event);
  };

  useEffect(() => {
    try {
      if (props.inputValues.isEnableWebsite === true) {
        setWebsitePage(true);
      } else {
        setWebsitePage(false);
      }
    }
    catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (props.inputValues.isEnblMobOrdr === true) {
        setIPAddres(true);
      } else {
        setIPAddres(false);
      }
    }
    catch (error) {
      console.log(error);
    }
  }, []);



  const [isIPAddres, setIPAddres] = useState(false);
  const [selectedOption5, sethandleInputChangeEcom] = useState(false);
  // const [, sethandleInputChangeEcom] = useState(false)
  const handleInputChangeEcom = (event) => {
    const selectedValue = event.target.value;
    sethandleInputChangeEcom(selectedValue);
    if (selectedValue === "true") {
      setIPAddres(true);
    } else if (selectedValue === "false") {
      setIPAddres(false);
    }
  };

  const [isWebsitePage, setWebsitePage] = useState(false);
  const [selectedOption6, sethandleInputChangeEcom6] = useState(false);
  // const [, sethandleInputChangeEcom] = useState(false)
  const handleInputChangeEcom6 = (event) => {
    const selectedValue1 = event.target.value;
    sethandleInputChangeEcom6(selectedValue1);
    if (selectedValue1 === "true") {
      setWebsitePage(true);
    } else if (selectedValue1 === "false") {
      setWebsitePage(false);
    }
  };

  return (
    <div>
      <div className="company-info-top-right control_flex">
        <div className="control_radius">
          <div className="company_info-text-im">Company Setting</div>

          <div className="HeadContentInputBpx">
            <div className=" CompWidthMaster">
              <div className="">
                <select
                  className="modify_im_input mode_category1"
                  type="text"
                  // value={selectedOption5}
                  value={props.inputValues.isEnblMobOrdr}
                  onChange={MobileOrder}
                  name="isEnblMobOrdr"
                  required
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label class="modify_lbl_text3 userLeft1">
                  Enable Mobile ordering
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <select
                className="modify_im_input mode_category1"
                type="text"
                value={props.inputValues.isEnableWebsite}
                onChange={EnableWebsite}
                name="isEnableWebsite"
                required
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
              <label class="modify_lbl_text3 userLeft1">Enable Website</label>
            </div>
            {isIPAddres && (
              <div className=" CompWidthMaster">
                <div className="input-box-mok1">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    name="isEnblPayFrmOrdrTakerApp"
                    onChange={props.setFunction}
                    value={props.inputValues.isEnblPayFrmOrdrTakerApp}
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 ">
                    Enable to take payment from Order Taker App{" "}
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    name="isEnableHomeDelivery"
                    required
                    onChange={props.setFunction}
                    value={props.inputValues.isEnableHomeDelivery}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable Home Delivery
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    name="isEnableTblOrder"
                    required
                    onChange={props.setFunction}
                    value={props.inputValues.isEnableTblOrder}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable Table Order
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    required
                    name="isEnablePickUp"
                    onChange={props.setFunction}
                    value={props.inputValues.isEnablePickUp}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable PickUp
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    required
                    name="isEnblItmAvailTimeChk"
                    onChange={props.setFunction}
                    value={props.inputValues.isEnblItmAvailTimeChk}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable Item available time check{" "}
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    onChange={props.setFunction}
                    required
                    name="isEnblItmAvailDateChk"
                    value={props.inputValues.isEnblItmAvailDateChk}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable Item available date check
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    onChange={props.setFunction}
                    required
                    name="isEnblDlvryPnt"
                    value={props.inputValues.isEnblDlvryPnt}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Enable Delivery Point
                  </label>
                </div>
              </div>
            )}
            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="input-box-mok1">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    required
                    name="isEnblTmSltPopupInHmPg"
                    onChange={props.setFunction}
                    value={props.inputValues.isEnblTmSltPopupInHmPg}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 ">
                    Enable time slot Popup in Home Page
                  </label>
                </div>
              </div>
            )}

            {isWebsitePage && (
              <div className=" CompWidthMaster">
                <div className="input-box-mok1">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    required
                    name="isEnblCnclOrdrFrmDlvryBoyApp"
                    onChange={props.setFunction}
                    value={props.inputValues.isEnblCnclOrdrFrmDlvryBoyApp}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label className="modify_lbl_text3 ">
                    Enable to cancel order from delivery boy app
                  </label>
                </div>
              </div>
            )}

            {isWebsitePage && (
              <div className="CompWidthMaster">
                <div className="input-box-mok1">
                  <select
                    className="modify_im_input mode_category1"
                    type="text"
                    required
                    name="isAllowTblOptionForGuest"
                    onChange={props.setFunction}
                    value={props.inputValues.isAllowTblOptionForGuest}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label class="modify_lbl_text3 userLeft1">
                    Allow Table Option for Guest
                  </label>
                </div>
              </div>
            )}

            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isAllowDlvryPreferCheck"
                  onChange={props.setFunction}
                  value={props.inputValues.isAllowDlvryPreferCheck} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Allow delivery Preference to Check
                </label>
              </div>
            </div>

            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isEnblAutoTransferToKitchen"
                  onChange={props.setFunction}
                  value={props.inputValues.isEnblAutoTransferToKitchen} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Allow Item Auto transfer to kitchen
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isShowBillAmtAfterOrderConfirm"
                  onChange={props.setFunction}
                  value={props.inputValues.isShowBillAmtAfterOrderConfirm} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Show Bill amount after order confirmation
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isAllowDiffColorRunningOrder"
                  onChange={props.setFunction}
                  value={props.inputValues.isAllowDiffColorRunningOrder} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Allow different Color for Running Order
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isCheckNoOfPersonBeforeOrdConfirm"
                  onChange={props.setFunction}
                  value={props.inputValues.isCheckNoOfPersonBeforeOrdConfirm} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Check No Of Person before order Confirm
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="canUsePineLabDevice"
                  onChange={props.setFunction}
                  value={props.inputValues.canUsePineLabDevice} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Can use Pine Lab Device
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isEnableGoogleMap"
                  onChange={props.setFunction}
                  value={props.inputValues.isEnableGoogleMap} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Enable Google Map
                </label>
              </div>
            </div>
            <div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isPOSConfirmNKotBtn"
                  onChange={props.setFunction}
                  value={props.inputValues.isPOSConfirmNKotBtn} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Is POS Confirm and KOT Btn
                </label>
              </div>
            </div>
            {/*<div className=" CompWidthMaster">
              <div className="">
                <select className="modify_im_input mode_category1" type="text" required
                  name="isVariationItmShowInCatPage"
                  onChange={props.setFunction}
                  value={props.inputValues.isVariationItmShowInCatPage} >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <label className="modify_lbl_text3 userLeft1">
                  Is Variation Item Show in Category Page
                </label>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanySetting;
