import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import img1 from "../../Assets/img/img1.jpg";
import { Link } from "react-router-dom";
import MainButton from "../../Props/MainButton";
import ItemDepartmentApi from "../../../API/APIServices/ItemDepartment";
import DataTable from '../TableMaster/DataTable';
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function ItemDepartmentMaster(props) {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Item Department master';
    }, []);

    const [isInputVisiblemc3, setInputVisiblemc3] = useState(false);
    const handleToggleChangemc3 = () => {
        setInputVisiblemc3(!isInputVisiblemc3);
    };

    //***************{Check box}***************/ /
    const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,

    });

    const handleCheckboxChange = (checkboxName) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxName]: !prevCheckboxes[checkboxName],
        }));
    };

    const handleSelectAll = () => {
        const allChecked = Object.values(checkboxes).every((isChecked) => isChecked);

        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {};
            for (const checkboxName in prevCheckboxes) {
                updatedCheckboxes[checkboxName] = !allChecked;
            }
            return updatedCheckboxes;
        });
    };

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });
    const [inputValues, setInputValues] = useState({
        name: '',
        description: '',
        deptAvgPrepareTime: 0,
        remarks: "",
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await ItemDepartmentApi.updateItemDepartment(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await ItemDepartmentApi.insertItemDepartment(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({
                name: "",
                description: "",
                deptAvgPrepareTime: 0,
                remarks: "",
                isActive: true,
            });
        }
    }

    // ******************************************** List Part ******************************

    const [data1, setData1] = useState([]);

    console.log(data1);
    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "DESCRIPTION", accessor: "description" },
        { Header: "DEPT AVG PREP TIME", accessor: "deptAvgPrepareTime" },
        { Header: "REMARKS", accessor: "remarks" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "Name" },
        { Header: <input type="text" placeholder="Description" ref={input2} />, accessor: "Description" },
        { Header: <input type="text" placeholder="Dept Avg Prepare Time" ref={input2} />, accessor: "deptAvgPrepareTime" },
        { Header: <input type="text" placeholder="Remark" ref={input2} />, accessor: "remarks" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await ItemDepartmentApi.getItemDepartment();
            console.log("value", val);

            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    name: item.name,
                    description: item.description,
                    deptAvgPrepareTime: item.deptAvgPrepareTime,
                    remarks: item.remarks,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editItemDepartment(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteItemDepartment(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editItemDepartment = async (id) => {
        setEdit(true)
        const dataById = ItemDepartmentApi.getItemDepartmentById(id);
        if (dataById) {
            try {
                const editData = await dataById;
                console.log("dataaaa" + editData);
                setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteItemDepartment = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteItemDepartmentConfirm = async () => {
        setLoading(true);
        try {
            const val = await ItemDepartmentApi.deleteItemDepartment(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    useEffect(() => {
        input1Ref.current.focus();
    }, []);


    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Item Department Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div style={{ height: "max-content" }} className="company-info-top-right control_flex_m1 item_scroll">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Item Department Details
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_limit mode_category1" type="text" value={inputValues.name}
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} name="name" onChange={handleInputChange} required />
                                                            <label className="modify_p_text2">Item Department Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    {/* <div className="company_info-text-im">Controls Details</div> */}
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <textarea style={{ width: "100%" }} className="modify_im_input mode_limit mode_category1"
                                                                onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                                ref={input2Ref} value={inputValues.description} name="description" onChange={handleInputChange} type="text" required />
                                                            <label className="modify_p_text2">Description</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category Marg18" type="text" value={inputValues.deptAvgPrepareTime}
                                                                onKeyPress={(e) => handleKeyPress(e, input4Ref)} disabled={inputValues.id ? true : false}
                                                                ref={input3Ref} name="deptAvgPrepareTime" onChange={handleInputChange} required />
                                                            <label className="modify_lbl_textage" style={{left:"10%"}}>Dept Avg Prepare Time</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-b1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category Marg18" type="text" value={inputValues.remarks}
                                                                onKeyPress={(e) => handleKeyPress(e, input1Ref)} disabled={inputValues.id ? true : false}
                                                                ref={input4Ref} name="remarks" onChange={handleInputChange} required />
                                                            <label className="modify_lbl_textage" style={{left:"10%"}}>Remarks</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Item Department Details</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ************************************************************************************* */}
                                        <div className="control_radius" style={{ display: "none" }}>
                                            <div className="company_info-text-im depart_top">Associate Department Category Details</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>YES BM</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Shop</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>--Select--</option>
                                                                <option>Dal</option>
                                                                <option>Rice and Roti</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Item Category</label>
                                                        </div>
                                                    </div>
                                                    <div className="search_panel_ff2" >

                                                        <div className="button-mp3">
                                                            <Link to="/UserMaster1" style={{ paddingTop: "8px" }} id="step-5" className="add_btn">
                                                                <i className="fa-solid fa-square-plus im-icon-6"></i>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="stock-im-dep1">

                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>Yes Bm</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Vendor</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" required>
                                                                <option>--Select--</option>
                                                                <option>North Indian</option>
                                                                <option>South Indian</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Item Department</label>
                                                        </div>
                                                    </div>
                                                    <div className="search_panel_fo1" >
                                                        {/* <div className="button-mp1">
                                                            <button className="table_search_btn" onClick={handleToggleChangemc3}>

                                                                <svg xmlns="http://www.w3.org/2000/svg" className="im-icon-1" height="1em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                                                            </button>

                                                        </div> */}
                                                        <div className="button-mp1">
                                                            <button style={{ paddingTop: "8px" }} className="table_search_btn" onClick={handleToggleChangemc3}>
                                                                <i className="fa-solid fa-magnifying-glass im-icon-6"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {isInputVisiblemc3 && (
                                            <div>
                                                <div className="control_radius">
                                                    <div className="company_info-text-im depart_top">Item List</div>
                                                    <div className="col-sm-12">
                                                        <div className="table-resposive" >
                                                            <table id="tblTransactionList" className="table_hdo1 table-bordered1" frame="box">
                                                                <thead textAlign="center" className="thead_scroll_col1" >
                                                                    <tr className="">

                                                                        <th className="td_col6" style={{ width: "6%" }}> <div className="table_head_mhead ">
                                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                                        </div> </th>
                                                                        <th className="td_col6" style={{ width: "10%" }}> <div className="table_head_mab">
                                                                            <div><input type="checkbox" className="check_box" checked={Object.values(checkboxes).every((isChecked) => isChecked)}
                                                                                onChange={handleSelectAll} name="" id="" /></div>
                                                                            <div className="table_head_mmbb1" >All</div>
                                                                        </div> </th>
                                                                        <th className="td_col6" style={{ width: "12%" }}> <div className="table_head_mhead">

                                                                            <div className="table_head_mmbb1" >Blk Prep.</div>
                                                                        </div> </th>
                                                                        <th className="td_col6" style={{ width: "12%" }}> <div className="table_head_mhead">

                                                                            <div className="table_head_mmbb1" >AI-A-CART</div>
                                                                        </div> </th>
                                                                        <th className="td_col6" style={{ width: "8%" }}> <div className="table_head_mhead">
                                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Picture</div>
                                                                        </div> </th>
                                                                        <th className="td_col6"> <div className="table_head_mhead">
                                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Item</div>
                                                                        </div> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="tbody_body_m1">
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">1</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><input type="checkbox" className="check_box" checked={checkboxes.checkbox1}
                                                                            onChange={() => handleCheckboxChange('checkbox1')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">2</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox2}
                                                                            onChange={() => handleCheckboxChange('checkbox2')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">3</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox3}
                                                                            onChange={() => handleCheckboxChange('checkbox3')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">4</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox4}
                                                                            onChange={() => handleCheckboxChange('checkbox4')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">5</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox5}
                                                                            onChange={() => handleCheckboxChange('checkbox5')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">6</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox6}
                                                                            onChange={() => handleCheckboxChange('checkbox6')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                    <tr className="table_tbody_it1">
                                                                        <td className="td_col6">7</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox7}
                                                                            onChange={() => handleCheckboxChange('checkbox7')} name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" name="" id="" /></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}><img src={img1} alt="img1" width="90" height="90" /></td>
                                                                        <td className="td_col6 item_text" style={{ textAlign: "left" }}>KAJU KISMIS DIYE BHAJA MUGHER DAL</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteItemDepartmentConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Item Department is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Item Department is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default ItemDepartmentMaster;
