import logo from "./../../images/logo.png";
import React, { useState } from 'react';
import Company from "../../API/APIServices/Company";

function LogInMaster(props) {
    const { emailId, setEmailId, setLoading } = props
    const [buttonClicked, setButtonClicked] = useState(false);
    const handleButtonClick = () => {
        setButtonClicked(true);
    };
    const handleButtonDelete = () => {
        setButtonClicked(false);  
    }

    const handleEmailChange = (event) => {
        setEmailId(event.target.value);
        props.handleInputChange(event);
    }
    const generateOTP = async () => {
        setLoading(true)
        var OtpData = await Company.RequestOtpForUserRegistration(emailId)
        debugger
        if (OtpData.isSuccess) {
            setLoading(false)
            props.gototop()
        } else {
            setLoading(false)
            alert("Check the email")
        }
        setLoading(false)
    }

    return (
        <div className="Formheader git check">
            <div>
                <div className="form-container">
                    <div className="form-sidebar-yesbm-logo ">
                        <img src={logo} alt="" />
                    </div>
                    <p className="FormTitle">Let's Register Now !</p>
                    <div className="formMainDiv">

                        <div className="InputTextD">
                            <input type="text" className="FormInputBox" name="email" placeholder="" required onChange={handleEmailChange} />
                            <label className="formTextLabelUser ">Enter Email</label>
                        </div>

                        {buttonClicked && (
                            <div className="InputTextD">
                                <input type="text" className="FormInputBox  " placeholder="" required />
                                <label className="formTextLabelUser ">Referral Code</label>
                                <span onClick={handleButtonDelete} className="IconDelRef">x</span>

                            </div>)}
                        {!buttonClicked && (
                            <p className="page-link">
                                <span className="page-link-label" onClick={handleButtonClick}>Have A Referral Code?</span>
                            </p>)}
                        {/* <Link style={{ width: "100%" }} to="/OtpVerify"> */}
                        <button onClick={generateOTP} className="form-btn">Login</button>
                        {/* </Link> */}

                    </div>
                    <p className="sign-up-label">
                        Have A Backup?<span className="sign-up-link">Restore it</span>
                    </p>
                    <div className="buttons-container">
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default LogInMaster;
