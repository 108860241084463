//import { useState } from "react";
import "./DeliveryStylesheet.css";
import {  } from 'react-router-dom'

const Trips = () => {
    return (
        <div>
           
        </div>
    );
};

export default Trips;
