import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './OrderTakerStylesheet.css';
import OrderApi from '../../API/APIServices/Order';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../Inventory/Props/Loader";
import AppHeaderDetail from "../AppHeaderDetail";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

const CancelOrderedItem = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ord1Id = searchParams.get('ord1Id');
    const orderNo = searchParams.get('orderNo');

    const [itemWiseList, setItemWiseList] = useState([]);

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [cancelItemList, setCancelItemList] = useState();

    const fetchData = async () => {
        setLoading(true)
        try {
            const data = await OrderApi.getOrderItemWiseList(ord1Id);
            setItemWiseList(data);
            setLoading(false)
            const cancelItem = await OrderApi.getCancelItemByOrd1Id(ord1Id);
            setCancelItemList(cancelItem);
            //console.log("cancelItem", cancelItem);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    // ******************** Get Order Details **********************
    const [orderDetails, setOrderDetails] = useState([]);
    const [balanceAmount, setBalanceAmount] = useState()
    const [paymentStatus, setPaymentStatus] = useState("Pending");

    const fetchDataList = async () => {
        try {
            const filteredData = {
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: ord1Id
            }
            setLoading(true)
            const orderData = await OrderApi.getOrderListByOrd1Id(ord1Id);
            var data = orderData.item1
            // const data = await OrderApi.getOrderListByOrderNoFilter(filteredData);
            console.log("data");
            console.log(data);
            setOrderDetails(data);
            setLoading(false)
            if (data.length > 0) {
                const balAmt = data[0].confirmTotalAmt - (data[0].o1CancelledAmt + data[0].paymentAmount);
                setBalanceAmount(balAmt)
                setPaymentStatus(data[0].paymentStatus)
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchDataList();
    }, [])

    const getIndexById = (id) => {
        return itemWiseList.findIndex(item => item.ord2Id === id);
    }

    const [showBtn, setShowBtn] = useState(true);

    const [balanceValue, setBalanceValue] = useState(0);
    const [quantities, setQuantities] = useState({});
    const [currentIndex, setCurrentIndex] = useState(null);
    //const [qtyChange, setQtyChange] = useState();

    const onHandleChange = async (event, id, qty, rate, index, cancelQty, apprQty, requestQty, kotStatus) => {
        const balAmt = orderDetails[0].confirmTotalAmt - orderDetails[0].o1CancelledAmt - orderDetails[0].paymentAmount;
        const { name, value } = event.target;
        const newQty = parseInt(value, 10);
        const previousQty = quantities[id] || 0;
        const deltaQty = newQty - previousQty;
        const deltaValue = deltaQty * rate;
        // alert(Number(value) + Number(apprQty) + Number(requestQty))

        console.log(kotStatus)
        if (kotStatus != "Pending" && kotStatus != "Received") {
            alert("Cannot Cancel Item as it Transferred to Chef");
            return false;
        }
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [id]: newQty
        }));
        const totalAmt = (qty * rate);
        // Calculate the total balance excluding the current index
        const cancelItem = cancelItemList.filter(item => item.cancelApprovalStatus === "Request");
        console.log(cancelItem);

        // ******************************* Calculate Cancel Amount ********************
        let newBalanceValue = 0;
        if (cancelItem.length > 0) {
            newBalanceValue = cancelItem.reduce((total, item) => {
                // Calculate total balance value based on quantities and item price
                return Number(total) + Number((quantities[item.ord2Id] || item.cancelRequestQty) * item.itemPrice);
            }, 0);
        }
        let newBlnceAmt = 0;
        const cancelData = itemWiseList.filter(item => item.cancelQty != 0 && item.cancelQty != null)
        const enteredItem = cancelData.filter(item => item.ord2Id != id);
        if (enteredItem.length > 0) {
            newBlnceAmt = enteredItem.reduce((total, item) => {
                return Number(total) + Number((quantities[item.ord2Id] || item.cancelQty) * item.rate);
            }, 0) + (newQty * rate);
        }
        else {
            newBlnceAmt = ((newQty * rate));
        }
        console.log(`New Balance Value:${balAmt}...${newBalanceValue}...${newBlnceAmt}`, (newBalanceValue + newBlnceAmt));
        console.log(Number(value), Number(apprQty), Number(requestQty));
        //alert(`Current Balance Amount: ${newBalanceValue}`);
        if (orderDetails[0].paymentStatus === "Done") {
            alert("Cannot Cancel Item as Payment has been done");
            return false;
        }
        else if (balAmt < (newBalanceValue + newBlnceAmt)) {  //+ (newQty * rate)
            if (qty) {
                if ((Number(value) + Number(apprQty) + Number(requestQty)) > qty) {
                    alert("You cannot select more than the actual Qty");
                    // setItemWiseList(prevValues => {
                    //     const updatedList = [...prevValues];
                    //     updatedList[index] = { ...updatedList[index], [name]: qty };
                    //     console.log(updatedList);
                    //     return updatedList;
                    // });
                    return 0;
                }
            }
            //if (value != null && value != "" && value != 0) {
            alert(`Cannot Cancel Item as your Balance Amount is Rs ${balAmt}`);
            return 0;
            // }
        }
        else {
            //alert(cancelQty)
            if (name == "cancelQty")
                setCheckIsSelectItem(true)
            console.log(itemWiseList);
            setShowBtn(true);

            const numericValue = parseFloat(value);

            const index = itemWiseList.findIndex(item => item.ord2Id === id); //getIndexById(id);
            if (numericValue < 0) {
                alert("Quantity cannot be negative");
                return;
            }
            else if (numericValue != "" && !isNaN(numericValue) && !Number.isInteger(numericValue)) {
                alert("Please enter valid number");
                return;
            }
            if (qty) {
                if ((Number(value) + Number(apprQty) + Number(requestQty)) > qty) {
                    alert("You cannot select more than the actual Qty");
                    // setItemWiseList(prevValues => {
                    //     const updatedList = [...prevValues];
                    //     updatedList[index] = { ...updatedList[index], [name]: qty };
                    //     console.log(updatedList);
                    //     return updatedList;
                    // });
                    return 0;
                }
                // return 0;
            }
            setItemWiseList(prevValues => {
                const updatedList = [...prevValues];
                updatedList[index] = { ...updatedList[index], [name]: value };
                console.log(updatedList);
                return updatedList;
            });
        };
        setBalanceValue(newBalanceValue);
        setCurrentIndex(index);
    }
    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setLoginUserDetails(loginUser);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // const RequestToCancel = async () => {
    //     const currentDate = new Date();
    //     const formattedDate = currentDate.toISOString();

    //     let cancelAmt = 0;

    //     for (const itemWiseValue of itemWiseList) {
    //         cancelAmt = (itemWiseValue.cancelQty * itemWiseValue.rate);
    //         const filteredData = [
    //             {
    //                 operationType: 0,
    //                 path: "CancelQty",
    //                 op: "Add",
    //                 from: "string",
    //                 value: itemWiseValue.cancelQty
    //             },
    //             {
    //                 operationType: 0,
    //                 path: "ItemCancelStatus",
    //                 op: "Add",
    //                 from: "string",
    //                 value: itemWiseValue.cancelStatus
    //             },
    //             {
    //                 operationType: 0,
    //                 path: "ItemCancelRemarks",
    //                 op: "Add",
    //                 from: "string",
    //                 value: itemWiseValue.cancelRemarks
    //             },
    //             {
    //                 operationType: 0,
    //                 path: "ItemCancelRequestedBy",
    //                 op: "Add",
    //                 from: "string",
    //                 value: loginUserDetails.userId          // LoginUserId 
    //             },
    //             {
    //                 operationType: 0,
    //                 path: "ItemCancelRequestedOn",
    //                 op: "Add",
    //                 from: "string",
    //                 value: formattedDate
    //             },
    //             {
    //                 operationType: 0,
    //                 path: "cancelAmt",
    //                 op: "Add",
    //                 from: "string",
    //                 value: cancelAmt
    //             }
    //         ];
    //         await OrderApi.updateItemPartially(filteredData, itemWiseValue.ord2Id);
    //     }
    //     const cancelData = {
    //         operationType: 0,
    //         path: "CancelOrderTotalAmt",
    //         op: "Add",
    //         from: "string",
    //         value: cancelAmt
    //     }
    //     await OrderApi.updateOrderPartially(cancelData, ord1Id);
    //     console.log(itemWiseList);
    // }

    const [checkIsSelectItem, setCheckIsSelectItem] = useState(false);

    const RequestToCancel = async () => {
        setLoading(true);
        if (checkIsSelectItem == false) {
            alert("Select atleast one Item to Cancel")
            setLoading(false);
            return false;
        }
        else {
            setLoading(true);
            const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
            // const formattedDate = new Date(isoString);

            let cancelAmt = 0;

            for (const itemWiseValue of itemWiseList) {
                cancelAmt = (itemWiseValue.cancelQty * itemWiseValue.rate) + cancelAmt;
            }
            for (const itemWiseValue of itemWiseList) {
                if (!itemWiseValue.cancelQty || itemWiseValue.cancelQty === 0 || itemWiseValue.cancelQty === "") {
                    continue;
                }
                console.log(itemWiseValue);
                //const itemCancelAmt = itemWiseValue.cancelQty * itemWiseValue.rate;
                // const filteredData = [
                //     {
                //         operationType: 0,
                //         path: "CancelQty",
                //         op: "Add",
                //         from: "string",
                //         value: itemWiseValue.cancelQty
                //     },
                //     {
                //         operationType: 0,
                //         path: "CancelStatus",
                //         op: "Add",
                //         from: "string",
                //         value: itemWiseValue.cancelStatus
                //     },
                //     {
                //         operationType: 0,
                //         path: "CancelRemarks",
                //         op: "Add",
                //         from: "string",
                //         value: itemWiseValue.cancelRemarks
                //     },
                //     {
                //         operationType: 0,
                //         path: "ItemCancelRequestedBy",
                //         op: "Add",
                //         from: "string",
                //         value: loginUserDetails.userId // LoginUserId
                //     },
                //     {
                //         operationType: 0,
                //         path: "ItemCancelRequestedOn",
                //         op: "Add",
                //         from: "string",
                //         value: formattedDate
                //     },
                //     {
                //         operationType: 0,
                //         path: "ItemCancelApprovalStatus",
                //         op: "Add",
                //         from: "string",
                //         value: "Request"
                //     },
                //     {
                //         operationType: 0,
                //         path: "cancelAmt",
                //         op: "Add",
                //         from: "string",
                //         value: cancelAmt
                //     }
                // ];

                // if (itemWiseValue.cancelApprovalStatus != "Approve" && itemWiseValue.cancelApprovalStatus != "Reject")
                //     await OrderApi.updateItemPartially(filteredData, itemWiseValue.ord2Id);

                const filteredData = {
                    ord1Id: ord1Id,
                    ord2Id: itemWiseValue.ord2Id,
                    d2ItemId: itemWiseValue.d2ItemId,
                    cancelRequestQty: itemWiseValue.cancelQty,
                    cancelAmt: itemWiseValue.cancelQty * itemWiseValue.rate,//cancelAmt,
                    cancelRemarks: itemWiseValue.cancelRemarks,
                    cancelStatus: itemWiseValue.cancelStatus,
                    cancelApprovalStatus: "Request",
                    cancelRequestedBy: loginUserDetails.userId,
                    cancelRequestedOn: formattedDate,
                    cancelApprovedBy: 0,
                    cancelApprovedOn: formattedDate,
                    cancelApprovedQty: 0
                }
                if (Number(itemWiseValue.cancelQty) != 0 || Number(itemWiseValue.cancelQty) != "") {
                    await OrderApi.createCancelItem(filteredData);
                }
            }
            // const cancelData = [
            //     {
            //         operationType: 0,
            //         path: "CancelOrderTotalAmt",
            //         op: "Add",
            //         from: "string",
            //         value: cancelAmt
            //     }
            // ];
            // await OrderApi.updateOrderPartially(cancelData, ord1Id);
            setLoading(false);
            alert("Request has been sent");
            navigate("/OrderTakerView")
            fetchDataList();
            console.log(itemWiseList);
        }
    };

    return (
        <div>
            <AppHeaderDetail title={`Cancel Order`} setLoading={setLoading} fetchData={fetchData} />
            <div className="containerTop-ot">
                {/**************************** Order Detail *****************************/}
                <div>
                    <div className="detail-header">
                        <label className="c-w">Order Detail</label>
                    </div>
                    <div className="detailDiv">
                        <div className="flex-start">
                            <label className="ques">Mob No : &nbsp;</label>
                            <label className="ans">{orderDetails.length > 0 ? orderDetails[0].accMobileNo : 'Loading...'}</label>
                        </div>
                        <div className="flex-start">
                            <label className="ques">Name : &nbsp;</label>
                            <label className="ans">{orderDetails.length > 0 ? orderDetails[0].accName : 'Loading...'}</label>
                        </div>
                        <div className="flex-start">
                            <label className="ques">Address&nbsp;: &nbsp;</label>
                            <label className="ans">{orderDetails.length > 0 ? orderDetails[0].accAddress : 'Loading...'}</label>
                        </div>

                        <div className="space-between">
                            <div className="flex-start">
                                <label className="ques">Order No : &nbsp;</label>
                                <label className="ans">{orderDetails.length > 0 ? orderDetails[0].orderNo : 'Loading...'}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques" style={{ color: "darkgreen" }}>Grand Total : &nbsp;</label>
                                <label className="ans">&#x20B9; {orderDetails.length > 0 ? orderDetails[0].confirmTotalAmt : 'Loading...'}</label>
                            </div>
                        </div>
                        <div className="space-between">
                            <div className="flex-start">
                                <label className="ques">Order Date : &nbsp;</label>
                                <label className="ans">{moment(orderDetails.length > 0 ? orderDetails[0].orderDate : 'Loading...').format('DD-MM-YYYY')}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques" style={{ color: "#ac7e29" }}>Balance Total : &nbsp;</label>
                                {/* <label className="ans">&#x20B9; {orderDetails.length > 0 ? orderDetails[0].balanceAmt : 'Loading...'}</label> */}
                                <label className="ans">&#x20B9; {orderDetails.length > 0 ? orderDetails[0].confirmTotalAmt - (orderDetails[0].o1CancelledAmt + orderDetails[0].paymentAmount) : 'Loading...'}</label>
                            </div>
                        </div>
                        <div className="space-between">
                            <div className="flex-start">
                                <label className="ques">Order Time : &nbsp;</label>
                                <label className="ans">{orderDetails.length > 0 ? orderDetails[0].orderTime : 'Loading...'}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques" style={{ color: "red" }}>Cancel Total : &nbsp;</label>
                                <label className="ans">&#x20B9; {orderDetails.length > 0 ? orderDetails[0].orderCancelOrderTotalAmt : 'Loading...'}</label>
                            </div>
                        </div>

                        <div className="flex-start">
                            <label className="ques">Table : &nbsp;</label>
                            <label className="ans">{orderDetails.length > 0 ? orderDetails[0].tableName : 'Loading...'}</label>
                        </div>
                    </div>
                </div>
                {/**************************** Order Item *****************************/}
                <div className="mt-2">
                    <div className="detail-header">
                        <label className="c-w">Order Items Details</label>
                    </div>
                    <div style={{ marginBottom: "50px" }}>
                        {(() => {
                            if (itemWiseList !== null && itemWiseList.length > 0) {
                                console.log(itemWiseList);
                                return (
                                    itemWiseList.filter(item => item.kotStatus !== "Pending").map((data, index) => {
                                        // Ensure cancelItemList is defined before filtering
                                        const cancelItems = cancelItemList ? cancelItemList.filter(cancelItem => cancelItem.itemOrd2Id === data.ord2Id) : [];
                                        const requestedItem = cancelItems.filter(item => item.cancelApprovalStatus == "Request")
                                        const requestedQty = requestedItem.reduce((total, item) => total + item.cancelRequestQty, 0);

                                        const totalCancelQty = cancelItems.reduce((total, item) => total + item.cancelRequestQty, 0);
                                        const totalApprovedQty = cancelItems.reduce((total, item) => total + item.cancelApprovedQty, 0);
                                        const totalCancelAmt = totalCancelQty * data.rate;

                                        console.log("totalCancelAmt", totalCancelAmt)
                                        console.log("totalCancelQty", totalCancelQty)
                                        console.log("qty", data.cancelRequestQty)
                                        console.log("cancelItem", cancelItems)
                                        console.log("itemWiseList", itemWiseList)
                                        console.log("test", data.cancelQty == totalCancelQty)
                                        console.log("totalAppr.", totalApprovedQty)
                                        console.log("requestedItem", requestedItem)
                                        return (
                                            <div className="detailDiv">
                                                <div className="mt-2">
                                                    <div className="flex-start">
                                                        <label className="ques">#{index + 1} &nbsp;</label>
                                                        <label className="ques" style={{ color: "#3892b7" }}>{data.d2ItemName}</label>
                                                    </div>
                                                    {cancelItems.length != 0 && cancelItems != undefined && cancelItems != null && cancelItems[0].cancelApprovalStatus === "Reject" ?
                                                        // <div className="flex-center" style={{ backgroundColor: "#8b000029", maxWidth: "fitContent", borderRadius: "5px", width: "35%" }}>
                                                        //     <label style={{ color: "darkred", fontSize: "14px", fontWeight: "600", display: "flex", textAlign: "center" }}>&nbsp;{cancelItems[0].cancelApprovalStatus} &nbsp;</label>
                                                        // </div>
                                                        <div></div>
                                                        :
                                                        cancelItems.length != 0 && cancelItems != undefined && cancelItems != null && cancelItems[0].cancelApprovalStatus != null && cancelItems[0].cancelApprovalStatus != "" ?
                                                            // <div className="flex-center" style={{ backgroundColor: "rgba(104, 189, 104, 0.24)", maxWidth: "fitContent", borderRadius: "5px", width: "35%" }}>
                                                            //     <label style={{ color: "darkgreen", fontSize: "14px", fontWeight: "600", display: "flex", textAlign: "center" }}>&nbsp; {cancelItems[0].cancelApprovalStatus} &nbsp;</label>
                                                            // </div>
                                                            <div></div>
                                                            :
                                                            <div></div>
                                                    }
                                                    <div className="flex-start">
                                                        <label className="ques" style={{ color: "black" }}>KOT Status : &nbsp;</label>
                                                        <label className="ques" style={{ color: "rgb(24 70 139)" }}>{data.kotStatus}</label>
                                                    </div>
                                                    <div className="space-evenly">
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "green" }}>Qty : &nbsp;</label>
                                                            <label className="ques">{data.qty}</label>
                                                        </div>
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "green" }}>Amt : &nbsp;</label>
                                                            <label className="ques">&#x20B9; {data.qty * data.rate}</label>
                                                        </div>
                                                    </div>
                                                    <div className="space-evenly">
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "darkred" }}>C.Req Qty : &nbsp;</label>
                                                            <label className="ques">{data.cancelQty ? data.cancelQty : totalCancelQty}</label>
                                                        </div>
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "darkred" }}>C.Req Amt : &nbsp;</label>
                                                            <label className="ques">&#x20B9;  {(data.cancelQty * data.rate) ? (data.cancelQty * data.rate) : totalCancelAmt}</label>
                                                        </div>
                                                    </div>
                                                    <div className="space-evenly">
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "orange" }}>C.App. Qty : &nbsp;</label>
                                                            <label className="ques">
                                                                {/* {(cancelItems.length != 0 && cancelItems != undefined && cancelItems != null && cancelItems[0] && cancelItems[0].cancelApprovalStatus != "Reject") ? (cancelItems[0].cancelApprovedQty ? cancelItems[0].cancelApprovedQty : 0) : 0} */}
                                                                {totalApprovedQty ? totalApprovedQty : 0}
                                                            </label>
                                                        </div>
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "orange" }}>C.App. Amt : &nbsp;</label>
                                                            <label className="ques">&#x20B9;
                                                                {/* {(cancelItems.length != 0 && cancelItems != undefined && cancelItems != null && cancelItems[0] && cancelItems[0].cancelApprovalStatus != "Reject") ? ((cancelItems[0].cancelApprovedQty * cancelItems[0].itemPrice) ? (cancelItems[0].cancelApprovedQty * cancelItems[0].itemPrice) : 0) : 0} */}
                                                                {totalApprovedQty && cancelItems.length > 0 ? (totalApprovedQty * cancelItems[0].itemPrice) : 0}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="space-evenly">
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "steelblue" }}>Rej. Qty : &nbsp;</label>
                                                            <label className="ques">{}</label>
                                                        </div>
                                                        <div className="flex-start">
                                                            <label className="ques" style={{ color: "steelblue" }}>Rej. Amt : &nbsp;</label>
                                                            <label className="ques">&#x20B9; {}</label>
                                                        </div>
                                                    </div> */}
                                                    <div className="space-evenly">
                                                        <select className="form-control" style={{ height: "35px", fontSize: "13px" }} name="cancelStatus" onChange={(e) => onHandleChange(e, data.ord2Id, false, 0, index, 0, 0, 0, data.kotStatus)}
                                                            disabled={balanceAmount == 0 || paymentStatus == "Done" || data.qty == totalApprovedQty ? true : false}>
                                                            {/* value={data.cancelStatus ? data.cancelStatus : (cancelItems.length != 0 && cancelItems != undefined && cancelItems != null && cancelItems[0] && cancelItems[0].cancelStatus ? cancelItems[0].cancelStatus : (cancelItems == undefined || cancelItems == null || cancelItems.length == 0 ? "" : cancelItems[0].cancelStatus))} */}
                                                            <option value="">Select</option>
                                                            <option value="Cancel Item">Cancel Item</option>
                                                            <option value="NCKOT">NCKOT</option>
                                                        </select>
                                                        <input className="form-control" type="number" placeholder="Qty" name="cancelQty" value={data.cancelQty ? data.cancelQty : ""}
                                                            onChange={(e) => onHandleChange(e, data.ord2Id, data.qty, data.rate, index, data.cancelQty, totalApprovedQty, requestedQty, data.kotStatus)}
                                                            style={{ fontSize: "13px", height: "35px", marginLeft: "5px" }}
                                                            disabled={balanceAmount == 0 || paymentStatus == "Done" || data.qty == totalApprovedQty ? true : false} />
                                                        {/* value={data.cancelQty ? data.cancelQty : (totalCancelQty == 0 ? "" : totalCancelQty)} */}
                                                        <input className="form-control w-100"
                                                            type="text" disabled={balanceAmount == 0 || paymentStatus == "Done" || data.qty == totalApprovedQty ? true : false}
                                                            // value={data.cancelRemarks ? data.cancelRemarks : (cancelItems == undefined || cancelItems == null || cancelItems.length == 0 ? "" : cancelItems[0].cancelRemarks)}
                                                            placeholder="Remarks" name="cancelRemarks" onChange={(e) => onHandleChange(e, data.ord2Id, false, 0, index, 0, 0, 0, data.kotStatus)} style={{ fontSize: "13px", marginLeft: "5px", height: "35px" }}
                                                        />
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        })()}
                    </div>
                    {orderDetails.length > 0 && (
                        <div style={{ display: showBtn ? "block" : "none" }}>
                            <div style={{ display: orderDetails[0].paymentStatus === "Done" ? "none" : "block" }}>
                                <div className="floating-button-container" style={{ width: "90%", bottom: "0px", right: "10px" }}>
                                    <button className="orangeBtn" onClick={RequestToCancel}>Request to Cancel Order</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default CancelOrderedItem;
