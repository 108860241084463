
import "../../css/resposive.css";

function ListSaveButton() {
    return (
        <div className="class_control_3">
            <div className="col_btn_1">
                <div className="">
                    <input type="button" className="payment-btn1" value="Take payment" />
                </div>
            </div>
            <div className="col_btn_1">
                <div className="">
                    <button className="drop-btn3"><i className="fa-solid fa-plus"></i></button>
                </div>
            </div>
            <div className="col_btn_1">
                <div className="">
                    <input type="button" className="sale-btn2" value="Add Sake" />
                </div>
            </div>
        </div>
    )
}

export default ListSaveButton;