import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
  WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
  
const BillSundry = {
	
  GetBillSundry: async () => {
    try {
      //const apiUrl = `${WebApiUrl}/api/BillSundry/GetBillSundries`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry/GetBillSundries`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  GetBillSundryFilter: async () => {
    try {
      //const apiUrl = `${WebApiUrl}/api/BillSundry/GetBillSundries`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry/GetBillSundries`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
              {
                  propertyName: "id",
                  ascending: false
              }
          ],
          pageNumber: 0,
          pageSize: 500000
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  InsertBillSundry: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      //const apiUrl = `${WebApiUrl}/api/BillSundry`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry`;
      const response = await axios.post(
        apiUrl,
        {
          // id: data.id,
          name: data.name,
          printName: data.printName,
          sundryType: Number(data.sundryType),
          defaultValue: Number(data.defaultValue),
          methodApplied: data.methodApplied,
          absolutePercentAmount: data.absolutePercentAmount,
          percentOfAmount: Number(data.percentOfAmount),
          applyOnBill: Number(data.applyOnBill),
          roundOffSundryAmount: parseBoolean(data.roundOffSundryAmount),
          amtRoundOffConfig: Number(data.amtRoundOffConfig),
          amtAdjustSaleAccount: parseBoolean(data.amtAdjustSaleAccount),
          selectedAccountSale: Number(data.selectedAccountSale),
          amtAdjustPurchaseAccount: parseBoolean(data.amtAdjustPurchaseAccount),
          selectedAccountPurchase: Number(data.selectedAccountPurchase),
          roundOffLimit: data.roundOffLimit,
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetBillSundryById: async (BillSundryId) => {

    try {
      console.log("get by id");
      //const apiUrl = `${express_server_url}/api/BillSundry?BillSundryId=${BillSundryId}`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry?BillSundryId=${BillSundryId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${WebApiUrl}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  deleteBillSundry: async (BillSundryId) => {
    try {
      console.log("delete");
      //const apiUrl = `${express_server_url}/api/BillSundry?BillSundryId=${BillSundryId}`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry?BillSundryId=${BillSundryId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateBillSundry: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    console.log(data);
    try {
      //const apiUrl = `${express_server_url}/api/BillSundry?BillSundryId=${data.id}`;
	  const apiUrl = `${WebApiUrl}/api/BillSundry?BillSundryId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          name: data.name,
          printName: data.printName,
          sundryType: Number(data.sundryType),
          defaultValue: Number(data.defaultValue),
          methodApplied: data.methodApplied,
          absolutePercentAmount: data.absolutePercentAmount,
          percentOfAmount: Number(data.percentOfAmount),
          applyOnBill: Number(data.applyOnBill),
          roundOffSundryAmount: parseBoolean(data.roundOffSundryAmount),
          amtRoundOffConfig: Number(data.amtRoundOffConfig),
          amtAdjustSaleAccount: parseBoolean(data.amtAdjustSaleAccount),
          selectedAccountSale: Number(data.selectedAccountSale),
          amtAdjustPurchaseAccount: parseBoolean(data.amtAdjustPurchaseAccount),
          selectedAccountPurchase: Number(data.selectedAccountPurchase),
          roundOffLimit: data.roundOffLimit,
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
};

export default BillSundry;
