import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from '../../Components/ListSaveButton';
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton"
import UserCredentialApi from '../../../API/APIServices/UserCredential';
import DataTable from "../TableMaster/DataTable";
import UserMaster from "./UserMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function UserMasterList() {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "USERNAME", accessor: "userName" },
        { Header: "ITEM DEPARTMENT", accessor: "itemDepartment" },
        { Header: "USER ROLE", accessor: "userRoleName" },
        { Header: "ACTION", accessor: "action" },
        { Header: "ROLES & PERMISSION", accessor: "rolesAndPermission" },
        { Header: "USER ACTIVITY", accessor: "userActivity" },
        { Header: "GRAND", accessor: "grand" },
        { Header: "REVOKE", accessor: "revoke" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Username" ref={input1} />, accessor: "Username" },
        { Header: <input type="text" placeholder="Item Department" ref={input2} />, accessor: "ItemDepartment" },
        { Header: <input type="text" placeholder="User Role Name" ref={input2} />, accessor: "UserRoleName" },
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await UserCredentialApi.getUserCredential();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    id: item.id,
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    userName: item.userName,
                    itemDepartment: item.itemDepartment,
                    userRoleName: item.userRoleName,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editUserCredential(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteUserCredential(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                    rolesAndPermission: (<button>Roles & Permission</button>),
                    userActivity: (<button>User Activity</button>),
                    grand: (<button className="allow_btn">Allow</button>),
                    revoke: (<button className="not_allow_btn">Not Allow</button>),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);

    const editUserCredential = async (id) => {
        const data = await UserCredentialApi.getUserCredentialById(id);
        console.log("dataaaa", data);
        setEditData(data);
        toggleSlider();
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        console.log(editData);
        setSliderOpen(!isSliderOpen);
    };

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteUserCredential = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }

    const DeleteUserCredentialConfirm = async () => {
        setLoading(true);
        try {
            const val = await UserCredentialApi.deleteUserCredential(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    return (
        <div className="" >
            <PageTabs locationTab={"/UserMaster"} modelOpen={true} setSliderCallBack={setSliderOpen} />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">User Master List</h2>
                    <TableButton PageCreate={"/UserMaster"}  filterData={filterData} columns={columns} data={data1} selectedData={selectedData} />
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <DataTable filterData={filterData} columns={columns} data={data1} />
                                {/* <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">User Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Department</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                            <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>

                                            <th className="table_edit2 table_head_mmb1 " style={{ textAlign: "center" }} >Roles & Permissions</th>
                                            <th className="table_edit3 table_head_mmb1 " style={{ textAlign: "center" }} >User Activity</th>
                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Grand</th>
                                            <th className="table_revoke table_head_mmb1" style={{ textAlign: "center" }} >Revoke</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">1</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Jeet</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}> <button className="allow_btn">Allow</button> </td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>


                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">2</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Akash</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Akash</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">3</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Royal</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Royal</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">4</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raju Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Raju Kumar</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>


                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">5</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Ashish Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Ashish Kumar</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>


                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">6</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Kamal Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Kamal Kumar</td>

                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>


                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col5">7</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Sonu Kumar</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Sonu Kumar</td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}>Roles & Permissions</td>
                                            <td className="td_col5" style={{ textalign: "start" }}>User Activity</td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="allow_btn">Allow</button></td>
                                            <td className="td_col5" style={{ textalign: "start" }}><button className="not_allow_btn">Not Allow</button></td>

                                        </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/******************************* User Master Modal*************************/}
            <div className={`slider-container100 ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <UserMaster editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} />
                </div>
            </div>
            {/* ******************************************** Mobile View **************************************** */}
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>

            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteUserCredentialConfirm}
                />
            )}
            {loading && <Loader />}
        </div>


    );
}

export default UserMasterList;
