import React, { useState } from "react";
// import { useEffect } from "react";

function BillPaymentInvoice(props) {


    const [SettingModal, setSettingModal] = useState(false);

    const OpenSettingModal = () => {
        setSettingModal(true);
    };

    const CloseSettingModal = () => {
        setSettingModal(false);
    };

    //const [selectedData, setSelectedData] = useState();
    const [selectedData] = useState();

    //const handleSliderButton = (index) => {
    //    const val = CheckBoxData[index];
    //    const isSelected = selectedData.some(item => item.id === val.id);

    //    if (!isSelected) {
    //        setSelectedData(prevSelectedData => [...prevSelectedData, val]);
    //    } else {
    //        setSelectedData(prevSelectedData =>
    //            prevSelectedData.filter(item => item.id !== val.id)
    //        );
    //    }
    //    console.log(selectedData);
    //};
    //const [CheckBoxData] = useState([
    //    {
    //        id: 1,
    //        name: 'Cash',
    //        type: "text",
    //        idName: "Cash",
    //    },
    //    {
    //        id: 2,
    //        name: 'UPI',
    //        type: "text",
    //        idName: "Upi",
    //    },
    //    {
    //        id: 3,
    //        name: 'Card',
    //        type: "text",
    //        idName: "card",
    //    },
    //    {
    //        id: 4,
    //        name: 'Paytm',
    //        type: "text",
    //        idName: "Paytm",
    //    },
    //    {
    //        id: 5,
    //        name: 'Advance Adjustment Amt',
    //        type: "text",
    //        idName: "Ad",
    //    },
    //    {
    //        id: 6,
    //        name: 'Advance Adjustment Voucher No',
    //        type: "text",
    //        idName: "Ad",
    //    },
    //])

    const handleSliderButton1 = (val) => {
        const title = document.getElementById(val).style.display;
        if (title === 'none') {
            document.getElementById(val).style.display = "block";
        }
        else {
            document.getElementById(val).style.display = "none";
        }
    };

    return (
        <div>
            {/*<div style={{ display: "flex", justifyContent: "space-between", padding: "2px", backgroundColor: "#98a1b7", borderLeft: "1px solid black" }}>*/}
            {/*    <div className="col_58" style={{ backgroundColor: "#98a1b7", color: "black" }}>Bill Payment Details</div>*/}
            {/*    <i className="fa-solid fa-gear mt-1" onClick={OpenSettingModal} style={{ color: "black", fontSize: "14px" }}></i>*/}
            {/*</div>*/}
            <div className="tab-btn-box1 tab-b3 bg-color space-between" style={{ backgroundColor: "#5e709e", borderLeft: "1px solid white" }}>
                <label className="c-w">&nbsp; Bill Payment Details</label>
                <i className="fa-solid fa-gear mt-1 settingIcon" onClick={OpenSettingModal} style={{ color: "white", fontSize: "14px" }}></i>
            </div>
            <div className="">
                <div className="input-box-content-l1 container_over col_fm_xl1" style={{height:"auto", maxHeight:"210px", scrollbarWidth:"none"}}>
                    {(() => {
                        if (selectedData != null) {
                            selectedData.map((data) => (
                                <div className="input-box-bill ffd ">
                                    <div className="input-box-m1 ddm1">
                                        <input className="modify_im_input mode_category input-s1" type="text" required />
                                        <label className="modify_p_text2 m-22">{data.name}</label>
                                    </div>
                                    <div className="input-box-m1 ddm1">
                                        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />
                                        <label className="modify_lbl_text12 m-23 ffm_h1">Remark</label>
                                    </div>
                                </div>
                            ))
                        }
                    })()}
                    <div>
                        {/*<div className="input-box-bill ffd">*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_p_text2 m-22">UPI</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_lbl_text12 m-23 ffm_h1">Remark</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="input-box-bill ffd">*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_p_text2 m-22">Card</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_lbl_text12 m-23 ffm_h1">Remark</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="input-box-bill ffd">*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_p_text2 m-22">Paytm</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_lbl_text12 m-23 ffm_h1">Remark</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="input-box-bill ffd">*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_p_text2 m-22">Paytm 4</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_lbl_text12 m-23 ffm_h1">Remark</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="input-box-bill ffd">*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_p_text2 m-22">Adv Adjst Series</label>*/}
                        {/*    </div>*/}
                        {/*    <div className="input-box-m1 ddm1">*/}
                        {/*        <input className="modify_im_input mode_limit mode_category input-s1" type="text" required />*/}
                        {/*        <label className="modify_lbl_text12 m-23 ffm_h1">Adv Adjst Vch No</label>*/}
                        {/*    </div>*/}
                        {/*    </div>*/}
                    </div>

                    <div style={{padding:"10px"} }>
                        <div className="mt-2" id="Cash" style={{ display: "none" }}>
                            <div className="space-between">
                                <label>Cash</label>
                                <input type="text" />
                            </div>
                        </div>
                        <div className="mt-2" id="Payment1" style={{ display: "none" }}>
                            <div className="space-between">
                                <label>Payment 1</label>
                                <input type="text" />
                            </div>
                        </div>
                        <div className="mt-2" id="Payment2" style={{ display: "none" }}>
                            <div className="space-between">
                                <label>Payment 2</label>
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="space-between input-box-bill alignCenter">
                            <div className="input-box-m1 ddm1 mb-2" style={{}}>
                                <input className="modify_im_input mode_category input-s1" type="text" required style={{ width: "180px" }} />
                                <label className="modify_p_text2 m-22">Cr note no/Sale return Bill no</label>
                            </div>
                            <div className="input-box-m1 ddm1" style={{ display: "flex", width: "180px" }}>
                                <input type="checkbox" />
                                <label>&nbsp;Credit Note Adjustment</label>
                            </div>
                        </div>
                    </div>

                    {/* <div className=" space-between input-box-bill ffd alignCenter">
                        <div className="input-box-m1 ddm1 mb-2" style={{}}>
                            <input className="modify_im_input mode_category input-s1" type="text" required style={{ width: "180px" }} />
                            <label className="modify_p_text2 m-22">Cr note no/Sale return Bill no</label>
                        </div>
                        <div className="input-box-m1 ddm1" style={{ display: "flex", width: "180px" }}>
                            <input type="checkbox" />
                            <label>&nbsp;Credit Note Adjustment</label>
                        </div>
                    </div> */}
                    <div className="">
                        <span className="pay_amt1">&nbsp; Pay Amount ='0.00' , Due Amt = '0.00'.</span>
                    </div>
                </div>
            </div>
            {/************************* Bill Payment Setting Modal *****************************/}
            {SettingModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "52%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label>Party Settlement and Payment Mode</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={CloseSettingModal}>x</label>
                            </div>
                            <hr />
                            <div className="">
                                <div className="space-between" style={{ alignItems: "center" }}>
                                    <label className="mt-2">Cash</label>
                                    <input type="checkbox" onClick={() => handleSliderButton1("Cash")} />
                                    <div className="flex">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                            <input type="text" className="inputEffect"></input>
                                            <label id="input1" className="labelEffect " style={{}}>Account Name</label>
                                        </div>&nbsp;
                                        <i className="fa-solid fa-circle-plus mt-2"></i>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <input type="text" className="inputEffect"></input>
                                        <label id="input1" className="labelEffect " style={{}}>Title</label>
                                    </div>
                                </div>

                                <div className="space-between">
                                    <label className="mt-2">Pymt</label>
                                    <input type="checkbox" onClick={() => handleSliderButton1("Payment1")} />
                                    <div className="flex">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                            <input type="text" className="inputEffect"></input>
                                            <label id="input1" className="labelEffect " style={{}}>Account Name</label>
                                        </div>&nbsp;
                                        <i className="fa-solid fa-circle-plus mt-2"></i>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <input type="text" className="inputEffect"></input>
                                        <label id="input1" className="labelEffect " style={{}}>Title</label>
                                    </div>
                                </div>

                                <div className="space-between">
                                    <label className="mt-2">Pymt</label>
                                    <input type="checkbox" onClick={() => handleSliderButton1("Payment2")} />
                                    <div className="flex">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                            <input type="text" className="inputEffect"></input>
                                            <label id="input1" className="labelEffect " style={{}}>Account Name</label>
                                        </div>&nbsp;
                                        <i className="fa-solid fa-circle-plus mt-2"></i>
                                    </div>
                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                        <input type="text" className="inputEffect"></input>
                                        <label id="input1" className="labelEffect " style={{}}>Title</label>
                                    </div>
                                </div>

                            </div>
                            {/*<div className="flex-start">*/}
                            {/*    <label>Assign</label>*/}
                            {/*</div>*/}

                            {/*{CheckBoxData.map((data, index) => (*/}
                            {/*    <div>*/}
                            {/*        <div style={{ display: "flex", justifyContent: "space-between" }}>*/}
                            {/*            <label>{data.name}</label>*/}
                            {/*            <label className="switch" key={data.id} style={{}} >*/}
                            {/*                <input type="checkbox" onChange={() => handleSliderButton(index)} />*/}
                            {/*                <span className="slider round"></span>*/}
                            {/*            </label>*/}
                            {/*        </div>*/}
                            {/*        <hr />*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        </div>
                        <hr className="mt-2" />
                        <div className="flex-end" style={{ padding: "5px" }}>
                            <button className="greenBtn" style={{ width: "15%" }}>Save</button>
                            <button className="redBtn" style={{ width: "15%" }} onClick={CloseSettingModal}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BillPaymentInvoice;
