import React, { useState, useEffect, useContext, useRef } from "react";
import ModalForTdWidth from "../ChildComponents/ModalForTdWidth"; // Import your Modal component
import ModalForHeight from "../ChildComponents/ModalForHeight"; // Import your Modal component ";
import MyContext from "../../../Inventory/MyContext";

const TableHeader = (props) => {
    const [tableWidth, setTableWidth] = useState(false);
    const [tableHeight, setTableHeight] = useState(false);
    const [deletebox, setDeleteBox] = useState(false);
    const divRef = useRef(null);

    const { tableCellData, setTableCellData, setDroppedItems, droppedItems, setTemplateData, handleContentChange, setClickedItemId } = useContext(MyContext);

    const [clickedCellIndex, setClickedCellIndex] = useState(null);
    const [clickedCellElement, setClickedCellElement] = useState(null);

    useEffect(() => {
        localStorage.setItem("columns_rd", (tableCellData.headerData.length > 0 ? tableCellData.headerData.length : 1));
        localStorage.setItem("rows_rd", (tableCellData.headerData.length > 0 ? tableCellData.headerData.length : 1));
    }, [tableCellData]);

    const addColumn = () => {
        setTableCellData((prevData) => {
            const updatedData = prevData.map((row) => [...row, ""]);
            return updatedData;
        });
    };

    const removeColumn = () => {
        if (tableCellData[0].length > 1) {
            setTableCellData((prevData) => {
                const updatedData = prevData.map((row) => {
                    const newRow = [...row];
                    newRow.pop();
                    return newRow;
                });
                return updatedData;
            });
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.altKey && e.key === "+") {
                addColumn();
            } else if (e.altKey && e.key === "-") {
                removeColumn();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleTdClick = (cellIndex, tdElement) => {
        setTableWidth(!tableWidth);
        setClickedCellIndex(cellIndex);
        setClickedCellElement(tdElement);
    };

    const tableheight = () => {
        setTableHeight(!tableHeight);
    };

    const deleteshow = () => {
        setDeleteBox(!deletebox);
    }

    const handleInputBoxChange = (value, index) => {
        const newHeaderData = [...tableCellData.headerData];
        newHeaderData[index] = value;
        setTableCellData((prevState) => ({
            ...prevState,
            headerData: newHeaderData
        }));
    }

    const handlePaste = async (event, index) => {
        event.preventDefault();
        const name = event.target.name;
        const value = localStorage.getItem("VariableName")

        if (value?.includes('@')) {
            handleInputBoxChange(value, index)
            localStorage.setItem("VariableName", "");
        } else {
            console.log("Not found copied data");
        }
    };

    // ***********************************************
    const parseTableHtml = (html) => {
        console.log("TableCellData", tableCellData);
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Extract header data
        const headerInputs = doc.querySelectorAll('thead th input');
        const headerData = Array.from(headerInputs).map(input => input.value);

        // Extract footer data
        const footerInputs = doc.querySelectorAll('tfoot th input');
        const footerData = Array.from(footerInputs).map(input => input.value);

        // Extract detail data
        const bodyRows = doc.querySelectorAll('tbody tr');
        const detailData = Array.from(bodyRows).map(row => {
            const cellInputs = row.querySelectorAll('td input');
            return Array.from(cellInputs).map(input => input.value);
        });

        // Set state with the extracted data
        setTableCellData(prevValues => ({
            ...prevValues,
            headerData,
            footerData,
            detailData
        }));
    };

    useEffect(() => {
        console.log(props.keyValueId)
        console.log(droppedItems);

        const data = droppedItems.find(item => item.id == props.keyValueId);
        const tableData = data.textContents;
        if (tableData && tableData != "") {
            parseTableHtml(tableData);
        }
    }, []);

    const fetchInnerHtmlOfDroppedComponent = (componentId) => {
        console.log(componentId);
        const getRemainingData = (str) => {
            if (str.endsWith("CHILD")) {
                return str.replace("CHILD", "");
            } else if (str.endsWith("CHILD1")) {
                return str.replace("CHILD1", "");
            }
            return str;
        };

        var newID = getRemainingData(componentId)

        if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
            const componentElement = document.getElementById(newID.toString() + "innerHTML");
            if (componentElement) {
                const innerHTML = componentElement.outerHTML;
                console.log(innerHTML);

                // Update the corresponding item with the inner HTML
                setDroppedItems(prevItems => {
                    debugger
                    const updatedItems = prevItems.map(item => {
                        if (item.id === newID) {
                            return { ...item, textContents: innerHTML };
                        }
                        return item;
                    });

                    // Update the template data with the new content
                    setTemplateData(prevState => ({
                        ...prevState,
                        jsonStructure: updatedItems,
                    }));

                    return updatedItems;
                });
            }
            return
        }
        // Find the component element using its ID
        const componentElement = document.getElementById(componentId.toString());
        console.log(componentElement);
        if (componentElement) {
            // Get the inner HTML
            const innerHTML = componentElement.outerHTML;
            console.log(innerHTML);

            setDroppedItems(prevItems => {
                const updatedItems = prevItems.map(item => {
                    if (item.id === componentId) {
                        return { ...item, textContents: innerHTML };
                    }
                    return item;
                });
                setTemplateData(prevState => ({
                    ...prevState,
                    jsonStructure: updatedItems,
                }));

                return updatedItems;
            });
        }
    };

    const getParentIdFromChildId = (keyValueId, droppedItems) => {
        for (const item of droppedItems) {
            for (const child of item.JSONChildComponents) {
                if (child.id === keyValueId) {
                    return child.parentJSONid;
                }
            }
        }
        return null;
    };

    const handleBlur = () => {
        try {
            setTimeout(() => {
                if (divRef.current) {
                    console.log(divRef.current);
                    const updatedInnerHTML = divRef.current.innerHTML;
                    console.log('Updated innerHTML:', updatedInnerHTML);
                    if (tableCellData) {
                        const textData = tableCellData;
                        console.log("Text Data:", textData);
                        handleContentChange(textData, props.keyValueId);
                        const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
                        if (parentId != null) {
                            fetchInnerHtmlOfDroppedComponent(parentId);
                        }
                    }
                    setDroppedItems(prevItems => {
                        const updatedItems = prevItems.map(item => {
                            if (item.id === props.keyValueId) {
                                return { ...item, textContents: updatedInnerHTML };
                            }
                            return item;
                        });
                        setTemplateData(prevState => ({
                            ...prevState,
                            jsonStructure: updatedItems,
                        }));
                        return updatedItems;
                    });
                } else {
                    console.error('divRef.current is null');
                }
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
        console.log("parentId", parentId);
    }, [droppedItems]);

    const outlinebluemain = (e) => {
        setDeleteBox(!deletebox);
        setClickedItemId(props.keyValueId)
        const element = e.target.closest('.resizable-web-template');
        console.log(element.id);

        if (element) {
            // setActiveId(props.keyValueId);
            const deleteButton = document.getElementById(props.keyValueId + "delete");
            if (deleteButton) {
                deleteButton.classList.toggle("column-one-delete-web-template");
            }
            const resizers = document.getElementsByClassName(props.keyValueId); // Get all elements with the keyValueId class
            if (resizers.length > 0) {
                for (let i = 0; i < resizers.length; i++) {
                    resizers[i].classList.toggle("displayblock");
                }
            }
        }
    };

    return (
        <div id={props.keyValueId}
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
            onDragOver={props.dragover}
            draggable="true"
            onBlur={handleBlur}
            ref={divRef}
            className="resizable-web-template"
        >
            <table //onClick={deleteshow}
                className="table-component-web-template_forReportDesign"
                id="maintable"
                style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse'
                }}
                onClick={outlinebluemain}
            >
                <thead >
                    <tr >
                        {tableCellData && tableCellData.headerData?.map((cell, index) => (
                            <th
                                key={index}
                                style={{
                                    height: "30px", border: '1px solid black',
                                    borderCollapse: 'collapse'
                                }}

                            > <input className="textbox-editable-1-web-template withoutBorder" type="text" name="" id=""
                                value={`${cell}`} onChange={(e) => handleInputBoxChange(e.target.value, index)} onClick={(e) => handlePaste(e, index)} style={{ fontWeight: "700" }} /></th>
                        ))}
                    </tr>
                </thead>
            </table>
            {tableWidth && (
                <ModalForTdWidth
                    cellElement={clickedCellElement}
                    cellIndex={clickedCellIndex}
                    Click={handleTdClick}
                />
            )}
            {tableHeight && (
                <ModalForHeight
                    // cellElement={clickedCellElement}
                    // cellIndex={clickedCellIndex}
                    Click={tableheight}
                />
            )}
        </div >
    );
};

export default TableHeader;
