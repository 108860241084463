import React, { useState } from 'react';
import './MainSetting.css';
import Eyecheckupsetting from './Eyecheckupsetting';
import WebsiteSetting from './WebsiteSetting';
import GeneralSetting from './GeneralSetting';
import PaymentGateway from './PaymentGateway';
import Pincode from './Pincode';
import Logistics from './Logistic';
import CODsetting from './CODsetting';
import ShippingSetting from './ShippingSetting';
import B2BSetting from './B2BSetting';
import VirtualTrySetting from './VirtualTrySetting';
import SendNotificationSetting from './SendNotificationSetting';



const Tab = ({ label, onClick, isActive }) => (
    <div className={`tab ${isActive ? "active" : ""}`} onClick={onClick}>
        {label}
    </div>
);

const Content = ({ children, isActive }) => (
    <div className={`content ${isActive ? "active" : ""}`}>{children}</div>
);


const MainSetting = () => {


    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <div style={{ backgroundColor: "white", padding: "10px" }}>
            <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                <div className="panel-head-title">
                    <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Checkout And General Settings</h2>
                </div >
            </div>
            {/* <div className='heading'>Eye-checkup & Opticals setting</div> */}
            <div className="tab-container">
                <div className="navigation-on-top">
                    <div className="tab-nav">
                        <Tab style={{ color: "white" }}
                            icon=""
                            label="Website Setting"
                            onClick={() => handleTabClick(1)}
                            isActive={activeTab === 1}
                        />
                        <Tab
                            label="General Setting"
                            onClick={() => handleTabClick(2)}
                            isActive={activeTab === 2}
                        />
                        <Tab
                            label="Payment Gateway"
                            onClick={() => handleTabClick(3)}
                            isActive={activeTab === 3}
                        />
                        <Tab
                            label="Pincode"
                            onClick={() => handleTabClick(4)}
                            isActive={activeTab === 4}
                        />
                        <Tab
                            label="Logistics Setting"
                            onClick={() => handleTabClick(5)}
                            isActive={activeTab === 5}
                        />
                        <Tab
                            label="COD Setting"
                            onClick={() => handleTabClick(6)}
                            isActive={activeTab === 6}
                        />
                        <Tab
                            label="Shipping Setting"
                            onClick={() => handleTabClick(7)}
                            isActive={activeTab === 7}
                        />
                        <Tab
                            label="Eye-checkup & Opticals sett"
                            onClick={() => handleTabClick(8)}
                            isActive={activeTab === 8}
                        />
                        <Tab
                            label="B2B"
                            onClick={() => handleTabClick(9)}
                            isActive={activeTab === 9}
                        />
                        <Tab
                            label="Virtual Try"
                            onClick={() => handleTabClick(10)}
                            isActive={activeTab === 10}
                        />
                        <Tab
                            label="Send Notification"
                            onClick={() => handleTabClick(11)}
                            isActive={activeTab === 11}
                        />
                    </div>
                </div>
                <div className="">
                    {activeTab === 1 && (
                        <Content isActive={activeTab === 1}>
                            <div className="" style={{ backgroundColor: "white" }}>
                                {/* content goes here */}
                                <WebsiteSetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 2 && (
                        <Content isActive={activeTab === 2}>
                            <div style={{ backgroundColor: "white" }}>
                                <GeneralSetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 3 && (
                        <Content isActive={activeTab === 3}>
                            <div style={{ backgroundColor: "white" }}>
                                <PaymentGateway />
                            </div>
                        </Content>
                    )}
                    {activeTab === 4 && (
                        <Content isActive={activeTab === 4}>
                            <div style={{ backgroundColor: "white" }}>
                                <Pincode />
                            </div>
                        </Content>
                    )}
                    {activeTab === 5 && (
                        <Content isActive={activeTab === 5}>
                            <div style={{ backgroundColor: "white" }}>
                                <Logistics />
                            </div>
                        </Content>
                    )}
                    {activeTab === 6 && (
                        <Content isActive={activeTab === 6}>
                            <div style={{ backgroundColor: "white" }}>
                                <CODsetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 7 && (
                        <Content isActive={activeTab === 7}>
                            <div style={{ backgroundColor: "white" }}>
                                <ShippingSetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 8 && (
                        <Content isActive={activeTab === 8}>
                            <div style={{ backgroundColor: "white" }}>
                                <Eyecheckupsetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 9 && (
                        <Content isActive={activeTab === 9}>
                            <div style={{ backgroundColor: "white" }}>
                                <B2BSetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 10 && (
                        <Content isActive={activeTab === 10}>
                            <div style={{ backgroundColor: "white" }}>
                                <VirtualTrySetting />
                            </div>
                        </Content>
                    )}
                    {activeTab === 11 && (
                        <Content isActive={activeTab === 11}>
                            <div style={{ backgroundColor: "white" }}>
                                <SendNotificationSetting />
                            </div>
                        </Content>
                    )}
                </div>

            </div>
        </div>
    );
}

export default MainSetting;
