
import DateTime from "../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../Components/PageTabs";
import ItemGroupMaster from "../Master/InvertoryMaster/ItemGroupMasterUpdate";

function AddNewItem() {
    /***********title***************/
    useState(() => {
        document.title = 'Add New Item';
    }, []);

    const handleInputChange = (index, newValue, namefield) => {
        const updatedData = [...data];
        updatedData[index][namefield] = newValue;
        setData(updatedData);
    };

    const [data, setData] = useState([
        { id: 1, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 2, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 3, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 4, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 5, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 6, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 7, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 8, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 9, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
        { id: 10, name: '', groupName: '', unitName: '', purchasePrice: '', salePrice: '', mrpPrice: '', barcode: '', qty: '' },
    ]);

    const handleClearFields = (id) => {
        const index = data.findIndex(item => item.id === id);
        const updatedData = [...data];
        updatedData[index].name = '';
        updatedData[index].groupName = '';
        updatedData[index].unitName = '';
        updatedData[index].purchasePrice = '';
        updatedData[index].salePrice = '';
        updatedData[index].mrpPrice = '';
        updatedData[index].barcode = '';
        updatedData[index].qty = '';
        setData(updatedData);
    };

    const resetBtnClick = () => {
        const clearedData = data.map(item => ({
            id: item.id,
            name: '',
            groupName: '',
            unitName: '',
            purchasePrice: '',
            salePrice: '',
            mrpPrice: '',
            barcode: '',
            qty: '',
        }));

        setData(clearedData);
    };

    const [copiedValue, setCopiedValue] = useState('');
    const handleCopyRow = (index) => {
        const rowToCopy = data[index];
        const copiedRow = { ...rowToCopy };
        setCopiedValue(copiedRow);
    };

    const handlePasteRow = (currentId, currentIndex) => {
        const updatedData = [...data];
        const copiedRow = { ...copiedValue };
        copiedRow.id = currentId;

        updatedData[currentIndex] = copiedRow;
        setData(updatedData);
    };

    const [isModalOpenAddNewItem, setModalOpenAddNewItem] = useState(false);
    const showModalAddNewItem = () => {
        setModalOpenAddNewItem(true);
    };
    const hideModalAddNewItem = () => {
        setModalOpenAddNewItem(false);
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);

    const [hoverStates, setHoverStates] = useState({});

    const handleMouseEnter = (id, action) => {
        setHoverStates((prevHoverStates) => ({
            ...prevHoverStates,
            [`${id}_${action}`]: true,
        }));
    };

    const handleMouseLeave = (id, action) => {
        setHoverStates((prevHoverStates) => ({
            ...prevHoverStates,
            [`${id}_${action}`]: false,
        }));
    };


    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: data.length + 1 };

            setData([...data, newRow]);

            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);

            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    return (
        <div>
            < PageTabs />
            < PageTabs />
            <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                <div className="panel-head-title">
                    <h2 className="panel-title">Add New Item</h2>
                </div>
                {/* *******************Top Buttons****************** */}
                <div className="panel-head-button mt-1">
                    <div className="col_1">
                        <div className="flex">
                            <div className="button-m5">
                                <i id="step-2" onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    className="fa-solid fa-floppy-disk " style={{ color: "white" }} ></i>
                                {isHovered && <div className="hover-text1" style={{ marginTop: "4%" }}>Save</div>}<br />
                            </div>
                            <div className="button-m5" onClick={resetBtnClick}>
                                <i id="step-2" onMouseEnter={() => setIsHovered1(true)}
                                    onMouseLeave={() => setIsHovered1(false)}
                                    className="fa-solid fa-arrows-rotate " style={{ color: "white" }} ></i>
                                {isHovered1 && <div className="hover-text1" style={{ marginTop: "4%" }}>Reset</div>}<br />
                            </div>
                        </div>
                    </div>
                </div >
                {/* *******************Top Buttons****************** */}
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="input-box-Imo1 input-heightAddItem Newitem_add">
                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                        <button className="btn-lavender" style={{ width: "auto", padding: "5px" }} onClick={showModalAddNewItem}>Add Item Group</button>
                    </div>
                    {/* <div onClick={showModalAddNewItem}>
                        <i className="fa-solid fa-circle-plus dm_4 plus_user"></i>
                    </div> */}
                    <div className="input-box-NewItem ">
                        <div className="AddNewItem_Input_Box check_box_b1">
                            <input style={{ boxShadow: "none" }} className="modify_Item_checkbox" type="checkbox" />
                            <label className="modify_AddNewItem_checkbox"> Apply Action To All</label>
                        </div>
                    </div>
                </div>
                <div>
                    {/* ******** Item Group Master Open ********** */}
                    {isModalOpenAddNewItem && (
                        <div className="modal" >
                            <div className="ItemGroup-modal">
                                <div className="modal-title">
                                    <span className="close_modal" onClick={hideModalAddNewItem}>
                                        &times;
                                    </span>
                                </div>
                                <div className="">
                                    <div className="tab-content-modal">
                                        <div className="company-info-top-modal control_flex_modal AddNewItem_scroll">
                                            <ItemGroupMaster />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* ****************** */}
                </div>
                <div className="table-container-s1" style={{ height: "auto" }} >
                    <table className="table-scrol2" style={{ width: "100%" }} >
                        <thead className="table-tbody">
                            <tr className="table-tr2" >
                                <th className=" table-th-serialno tb_1 c-w"><label className="c-w">SN</label></th>
                                <th className="mb_3_itemname c-w"> <div className="thm2 tb_1 c-w"><label id="itemName" className="c-w">
                                    Item Name</label></div> </th>
                                <th className="mb_4_groupname tb_1 c-w"><div className="c-w">Group Name</div></th>
                                <th className="mb_5_unitname tb_1 c-w"> <div className="c-w">Unit Name</div></th>
                                <th className="mb_6 tb_1 c-w"> <div className="thm17 c-w">Pur Prc</div></th>
                                <th className="mb_7 tb_1 c-w"> <div className="thm17 c-w">Sale Prc</div></th>
                                <th className="mb_8 tb_1 c-w"><div className="thm21 c-w">MRP Prc</div></th>
                                <th className=" mb_9 tb_1 c-w"> <div className="thm20 c-w">Barcode</div></th>
                                <th className="mb_10 tb_1 c-w"><div className="thm15 c-w ">Qty</div></th>
                            </tr>
                        </thead>
                        <tbody className="table-container-tbody AddNewItem_scroll addItemTable" style={{}}>
                            {data.map((row, index) => (
                                <tr className="tableDoubleClick hover-tr" key={index} >
                                    <td className="table-th-serialno" style={{ textAlign: "right" }} >
                                        <div className="serialno_icon">
                                            <div className="Remove_Icon" onClick={() => handleClearFields(row.id)}>
                                                <i className="fa-sharp fa-solid fa-xmark" onMouseEnter={() => handleMouseEnter(row.id, "cancel")}
                                                    onMouseLeave={() => handleMouseLeave(row.id, "cancel")}
                                                    style={{ color: hoverStates[`${row.id}_cancel`] ? "red" : "darkred" }}></i>
                                            </div>
                                            {hoverStates[`${row.id}_cancel`] && (<div className="hover-text-black"> Cancel </div>)}

                                            <div className="Copy_Icon" onClick={() => handleCopyRow(index)}>
                                                <i className="fa-solid fa-copy" onMouseEnter={() => handleMouseEnter(row.id, "copy")}
                                                    onMouseLeave={() => handleMouseLeave(row.id, "copy")}
                                                    style={{ color: hoverStates[`${row.id}_copy`] ? "orange" : "darkorange" }}></i>
                                            </div>
                                            {hoverStates[`${row.id}_copy`] && (<div className="hover-text-black" style={{ left: "4%" }}> Copy </div>)}<br />

                                            <div className="Paste_Icon" onClick={() => handlePasteRow(row.id, index)}>
                                                <i className="fa-solid fa-paste" onMouseEnter={() => handleMouseEnter(row.id, "paste")}
                                                    onMouseLeave={() => handleMouseLeave(row.id, "paste")}
                                                    style={{ color: hoverStates[`${row.id}_paste`] ? "green" : "darkgreen" }}></i>
                                            </div>
                                            {hoverStates[`${row.id}_paste`] && (<div className="hover-text-black" style={{ left: "7%" }}> Paste</div>)}<br />
                                            {index + 1}
                                        </div>
                                    </td >
                                    <td className="mb_3_itemname">
                                        <input
                                            className="table-input-Barcode  pd_1"
                                            type="text"
                                            name="input1"
                                            value={row.name}
                                            // value={formData.input1}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'name')}
                                        />


                                    </td>
                                    <td className="mb_4_groupname">
                                        <input
                                            className="table-input-Barcode  pd_1_txtcenter "
                                            type="text"
                                            name="input2"
                                            value={row.groupName}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'groupName')}
                                            placeholder="--Select Group--"
                                        />
                                    </td>
                                    <td className="mb_5_unitname">
                                        <input
                                            className="table-input-Barcode  pd_1_txtcenter "
                                            type="text"
                                            name="input3"
                                            value={row.unitName}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'unitName')}
                                            placeholder="--Select Unit--"
                                        />
                                    </td>
                                    <td className="mb_6">
                                        <input
                                            className="table-input-Barcode  pd_1"
                                            type="text"
                                            name="input4"
                                            value={row.purchasePrice}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'purchasePrice')}
                                        />
                                    </td>
                                    <td className="mb_7">
                                        <input
                                            className="table-input-Barcode  pd_1"
                                            type="text"
                                            name="input5"
                                            value={row.salePrice}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'salePrice')}
                                        />
                                    </td>
                                    <td className="mb_8">
                                        <input
                                            className="table-input-Barcode  pd_1"
                                            type="text"
                                            name="input6"
                                            value={row.mrpPrice}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'mrpPrice')}
                                        />
                                    </td>
                                    <td className="mb_9">
                                        <select
                                            className="table-input-Barcode  pd_1"
                                            type="text"
                                            name="input7"
                                            value={row.barcode}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'barcode')}
                                        >
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </td>
                                    <td className="mb_10">
                                        <input
                                            className="table-input-Barcode  pd_1 border_barcode"
                                            type="text"
                                            name="input8"
                                            value={row.qty}
                                            onChange={(e) => handleInputChange(index, e.target.value, 'qty')}
                                            onKeyDown={(e) => handleCellKeyDown(e, index)}
                                        />
                                    </td>
                                    {/* <td className="" style={{ textAlign: "center" }}>
                                        <button className="btn-table1" onClick={() => handleDeleteRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="panel-head-button1">
                        <div className="col_4 clear_row">
                            <div className="button-m1">
                                <label className="take_time">
                                    Please wait for 2-3 minute after adding product, Barcode take time to update
                                </label>
                            </div>
                            {/* <div className="button-m1">
                                <button id="step-5" className="greenBtn" style={{ width: "100%" }}>
                                    Save
                                    <i className="fa-regular fa-floppy-disk im-icon-1" style={{ color: "white" }}></i>
                                </button>
                            </div>
                            <div className="button-m2" onClick={hideModal6}>
                                <button className="redBtn" style={{ width: "100%" }}>
                                    Clear
                                    <i className="fa-solid fa-brush im-icon-1" style={{ color: "white" }}></i>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div >
    );
}

export default AddNewItem;
