import React, { useState } from "react";
import "./SideBar.css";
import company from "../../images/monitor.png";
import Master from "../../images/script.png";
import Transaction from "../../images/process.png";
import RxTransaction from "../../images/payment-method.png";
import LensTransaction from "../../images/camera-lens.png";
import Orders from "../../images/delivery-man.png";
import PayRemaining from "../../images/salary.png";
import report from "../../images/report.png";
import Crm from "../../images/monitor.png";
import Utilities from "../../images/wrench.png";
import Ecommerce from "../../images/shopping-cart (1).png";
import PayRoll from "../../images/book.png";
import SideBarCategory from "./SideBarCategory";
import SideBarSubCategory from "./SideBarSubCategory";
import ChildCategory from "./ChildCategory";
import logo from "../../images/logo.png";
import UserAuthentication from "../../API/APIServices/Authentication"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import User from "../../API/APIServices/User";

const SideBarNew = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(false);

  const opensidebar = () => {
    setExpanded(!isExpanded);
    props.handleToggle();
    if (isExpanded === true) {
      setAdditionalClassName(false);
      setAdditionalClassName1(false);
      setAdditionalClassName2(false);
      setAdditionalClassName3(false);
      setAdditionalClassName4(false);
      setAdditionalClassName5(false);
      setAdditionalClassName6(false);
      setAdditionalClassName7(false);
      setAdditionalClassName8(false);
      setAdditionalClassName9(false);
      setAdditionalClassName10(false);
      setAdditionalClassName11(false);
    }
  };

  // const closecate =()=>{
  //   setAdditionalClassName(false);
  //   setAdditionalClassName1(false);
  //   setAdditionalClassName2(false);
  //   setAdditionalClassName3(false);
  //   setAdditionalClassName4(false);
  //   setAdditionalClassName5(false);
  //   setAdditionalClassName6(false);
  //   setAdditionalClassName7(false);
  //   setAdditionalClassName8(false);
  //   setAdditionalClassName9(false);
  //   setAdditionalClassName10(false);
  //   setAdditionalClassName11(false);
  // }

  const [additionalClassName, setAdditionalClassName] = useState("");
  const [additionalClassName1, setAdditionalClassName1] = useState("");
  const [additionalClassName2, setAdditionalClassName2] = useState("");
  const [additionalClassName3, setAdditionalClassName3] = useState("");
  const [additionalClassName4, setAdditionalClassName4] = useState("");
  const [additionalClassName5, setAdditionalClassName5] = useState("");
  const [additionalClassName6, setAdditionalClassName6] = useState("");
  const [additionalClassName7, setAdditionalClassName7] = useState("");
  const [additionalClassName8, setAdditionalClassName8] = useState("");
  const [additionalClassName9, setAdditionalClassName9] = useState("");
  const [additionalClassName10, setAdditionalClassName10] = useState("");
  const [additionalClassName11, setAdditionalClassName11] = useState("");

  const [childclassadd1, setChildClassAdd1] = useState(false);
  const [childclassadd2, setChildClassAdd2] = useState(false);
  const [childclassadd3, setChildClassAdd3] = useState(false);
  const [childclassadd4, setChildClassAdd4] = useState(false);
  const [childclassadd5, setChildClassAdd5] = useState(false);
  const [childclassadd6, setChildClassAdd6] = useState(false);
  const [childclassadd7, setChildClassAdd7] = useState(false);
  const [childclassadd8, setChildClassAdd8] = useState(false);
  const [childclassadd9, setChildClassAdd9] = useState(false);
  const [childclassadd10, setChildClassAdd10] = useState(false);
  const [childclassadd11, setChildClassAdd11] = useState(false);
  const [childclassadd12, setChildClassAdd12] = useState(false);
  const [childclassadd13, setChildClassAdd13] = useState(false);
  const [childclassadd14, setChildClassAdd14] = useState(false);
  const [childclassadd15, setChildClassAdd15] = useState(false);
  const [childclassadd16, setChildClassAdd16] = useState(false);
  const [childclassadd17, setChildClassAdd17] = useState(false);
  const [childclassadd18, setChildClassAdd18] = useState(false);
  const [childclassadd19, setChildClassAdd19] = useState(false);
  const [childclassadd20, setChildClassAdd20] = useState(false);
  const [childclassadd21, setChildClassAdd21] = useState(false);
  const [childclassadd22, setChildClassAdd22] = useState(false);
  const [childclassadd23, setChildClassAdd23] = useState(false);
  const [childclassadd24, setChildClassAdd24] = useState(false);
  const [childclassadd25, setChildClassAdd25] = useState(false);
  const [childclassadd26, setChildClassAdd26] = useState(false);


  const subcateopen1 = () => {
    setChildClassAdd1(!childclassadd1);
  };

  const subcateopen2 = () => {
    setChildClassAdd2(!childclassadd2);
  };

  const subcateopen3 = () => {
    setChildClassAdd3(!childclassadd3);
  };

  const subcateopen4 = () => {
    setChildClassAdd4(!childclassadd4);
  };

  const subcateopen5 = () => {
    setChildClassAdd5(!childclassadd5);
  };

  const subcateopen6 = () => {
    setChildClassAdd6(!childclassadd6);
  };

  const subcateopen7 = () => {
    setChildClassAdd7(!childclassadd7);
  };

  const subcateopen8 = () => {
    setChildClassAdd8(!childclassadd8);
  };

  const subcateopen9 = () => {
    setChildClassAdd9(!childclassadd9);
  };

  const subcateopen10 = () => {
    setChildClassAdd10(!childclassadd10);
  };

  const subcateopen11 = () => {
    setChildClassAdd11(!childclassadd11);
  };

  const subcateopen12 = () => {
    setChildClassAdd12(!childclassadd12);
  };

  const subcateopen13 = () => {
    setChildClassAdd13(!childclassadd13);
  };

  const subcateopen14 = () => {
    setChildClassAdd14(!childclassadd14);
  };

  const subcateopen15 = () => {
    setChildClassAdd15(!childclassadd15);
  };

  const subcateopen16 = () => {
    setChildClassAdd16(!childclassadd16);
  };

  const subcateopen17 = () => {
    setChildClassAdd17(!childclassadd17);
  };

  const subcateopen18 = () => {
    setChildClassAdd18(!childclassadd18);
  };

  const subcateopen19 = () => {
    setChildClassAdd19(!childclassadd19);
  };

  const subcateopen20 = () => {
    setChildClassAdd20(!childclassadd20);
  };
  const subcateopen21 = () => {
    setChildClassAdd21(!childclassadd21);
  };

  const subcateopen22 = () => {
    setChildClassAdd22(!childclassadd22);
  };

  const subcateopen23 = () => {
    setChildClassAdd23(!childclassadd23);
  };

  const subcateopen24 = () => {
    setChildClassAdd24(!childclassadd24);
  };

  const subcateopen25 = () => {
    setChildClassAdd25(!childclassadd25);
  };

  const subcateopen26 = () => {
    setChildClassAdd26(!childclassadd26);
  };

  const closesubcat = () => {
    setChildClassAdd1(false)
    setChildClassAdd2(false)
    setChildClassAdd3(false)
    setChildClassAdd4(false)
    setChildClassAdd5(false)
    setChildClassAdd6(false)
    setChildClassAdd7(false)
    setChildClassAdd8(false)
    setChildClassAdd9(false)
    setChildClassAdd10(false)
    setChildClassAdd11(false)
    setChildClassAdd12(false)
    setChildClassAdd13(false)
    setChildClassAdd14(false)
    setChildClassAdd15(false)
    setChildClassAdd16(false)
    setChildClassAdd17(false)
    setChildClassAdd18(false)
    setChildClassAdd19(false)
    setChildClassAdd20(false)
    setChildClassAdd21(false)
    setChildClassAdd22(false)
    setChildClassAdd23(false)
    setChildClassAdd24(false)
    setChildClassAdd25(false)
    setChildClassAdd26(false)
  };

  const addClassOnClick = () => {
    closesubcat();
    setAdditionalClassName(!additionalClassName);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick1 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName1(!additionalClassName1);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick2 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName2(!additionalClassName2);
    setAdditionalClassName1(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick3 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName3(!additionalClassName3);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick4 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName4(!additionalClassName4);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick5 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName5(!additionalClassName5);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick6 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName6(!additionalClassName6);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick7 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName7(!additionalClassName7);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick8 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName8(!additionalClassName8);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick9 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName9(!additionalClassName9);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName10(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick10 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName10(!additionalClassName10);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName11(false);
  };

  const addClassOnClick11 = () => {
    closesubcat();
    setAdditionalClassName(false);
    setAdditionalClassName11(!additionalClassName11);
    setAdditionalClassName1(false);
    setAdditionalClassName2(false);
    setAdditionalClassName3(false);
    setAdditionalClassName4(false);
    setAdditionalClassName5(false);
    setAdditionalClassName6(false);
    setAdditionalClassName7(false);
    setAdditionalClassName8(false);
    setAdditionalClassName9(false);
    setAdditionalClassName10(false);
  };

  const logoutpage = async () => {
    await User.Logout();
    localStorage.clear()
    navigate("/loginpanel")
    window.location.reload();
  }

  return (
    <div
      //onMouseLeave={closesubcat}
      className={props.class_name}
      style={{ width: `${props.width}px` }}
    >
      <div style={{ width: `${props.width}px` }}>
        <div className={`sidebar-yesbm ${isExpanded ? "expanded" : ""}`}>
          {/* <Link to="/previousOrder" className="sidebar-yesbm-logo space-between"> */}
          <div className="sidebar-yesbm-logo space-between">
            <img src={logo} alt="" />
            <i class="fa-solid fa-power-off sideBar_LogOut" onClick={logoutpage}></i>
          </div>
          {/* </Link> */}
          <div className="sidebar-yesbm-open-btn">
            <div>
              <input
                onChange={opensidebar}
                hidden=""
                className="check-icon"
                id="check-icon"
                name="check-icon"
                type="checkbox"
              />
              <label className="icon-menu" htmlFor="check-icon">
                <div className="bar bar--1"></div>
                <div className="bar bar--2"></div>
                <div className="bar bar--3"></div>
              </label>
              <input
                className="sidebar-yesbm-bottom-input"
                type="text"
                placeholder="Type To Search"
              />
            </div>
          </div>
          <div className="sidebar-yesbm-bottom">
            <div className="sidebar-yesbm-bottom-scroll-box">
              <SideBarCategory
                heading="Company"
                image={company}
                function={addClassOnClick}
                ArrowChange={additionalClassName}
              />

              <div
                className={`yes-bm-sub-category-main ${additionalClassName ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory
                  heading="Modify Company"
                  Link="/ModifyCompany1"
                />
                <SideBarSubCategory
                  heading="Create Company"
                  Link="/CreateBusiness"
                />
                <SideBarSubCategory heading="Close Company" Link="/" />
              </div>

              <SideBarCategory
                ArrowChange={additionalClassName1}
                heading="Master"
                image={Master}
                function={addClassOnClick1}
              />

              <div
                className={`yes-bm-sub-category-main ${additionalClassName1 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory
                  heading="Account Master"
                  onClick={subcateopen1}
                />
                <div
                  className={`yes-bm-sub-category ${childclassadd1 ? "show-child-cate hf6" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Account group Master"
                    Link="/AccountGroupMasterList"
                    Link2="/AccountGroupMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Account Master"
                    Link="/AccountMasterList"
                    Link2="/AccountMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Agent Master"
                    Link="/AgentMasterList"
                    Link2="/AgentMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Station Master"
                    Link="/StationMasterList"
                    Link2="/StationMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Account Caregory Master"
                    Link="/AccountCategoryMasterList"
                    Link2="/AccountCategoryMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Transporter"
                    Link="/TransportMasterList"
                    Link2="/TransportMaster"
                    showLink2={true}
                  />
                </div>

                <SideBarSubCategory
                  heading="Inventory Master"
                  onClick={subcateopen2}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd2 ? "show-child-cate hf9" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Item group Master"
                    Link="/ItemGroupMasterList"
                    Link2="/ItemGroupMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Item Master"
                    Link="/ItemMasterList"
                    Link2="/ItemMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Product Stock Master"
                    Link="/ProductStockMaster"
                    // Link2="/ItemMaster"
                    showLink2={false}
                  />
                  {/* <ChildCategory
                    heading="Godown Group"
                    Link="/MaterialCentreGroupMasterList"
                    Link2="/MaterialCentreGroupMaster"
                    showLink2={true}
                  /> */}
                  <ChildCategory
                    heading="Godown Master"
                    Link="/GodownMasterList"
                    Link2="/GodownMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Unit Master"
                    Link="/UnitMasterList"
                    Link2="/UnitMaster"
                    showLink2={true}
                  />
                  {/* <ChildCategory
                    heading="Unit Conversion"
                    Link="/UnitConversionMasterList"
                    Link2="/UnitConversionMaster"
                    showLink2={true}
                  /> */}
                  {/* <ChildCategory
                    heading="Lens Price"
                    Link="/LensRangePriceMasterList"
                    Link2="/LensRangePriceMaster"
                    showLink2={true}
                  /> */}
                  <ChildCategory
                    heading="Product Price Account Category"
                    Link="/ProductPriceAccCatWise"
                    Link2="/"
                    showLink2={false}
                  />
                  <ChildCategory
                    heading="Attribute Field"
                    Link="/AttributeField"
                    Link2="/"
                    showLink2={false}
                  />
                  {/* <ChildCategory
                    heading="Lens Remark Master"
                    Link="/lensRemarkMasterList"
                    Link2="/lensRemarkMaster"
                    showLink2={true}
                  /> */}
                </div>

                <SideBarSubCategory
                  heading="Bill and Other Master"
                  onClick={subcateopen3}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd3 ? "show-child-cate hf5" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Sundry Master"
                    Link="/SundryMasterList"
                    Link2="/SundryMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Tax Category"
                    Link="/TaxCategoryList"
                    Link2="/TaxCategory"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Bill Types Master"
                    Link="/BillTypesList"
                    Link2="/BillType"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Status Master"
                    Link="/StatusMasterList"
                    Link2="/StatusMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Loyalty Master Setting"
                    Link="/loyaltyMasterSetting"
                    // Link2="/"
                    showLink2={false}
                  />
                </div>

                <SideBarSubCategory
                  heading="Master Setting"
                  onClick={subcateopen23}
                />


                <div
                  className={`yes-bm-sub-category ${childclassadd23 ? "show-child-cate hf5" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Setting"
                    Link="/MasterSetting"
                    showLink2={false}
                  />
                  {/* <ChildCategory
                    heading="MiscellaneousMaster Master"
                    Link="/MiscellaneousMaster"
                  /> */}
                  <ChildCategory
                    heading="Miscellaneous Master"
                    Link="/MiscellaneousMasterList"
                    Link2="/MiscellaneousMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Feedback Master"
                    Link="/feedbackmaster"
                  />
                  <ChildCategory
                    heading="API Configuration Master"
                    Link="/APIConfigurationMasterList"
                    Link2="/APIConfigurationMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="SMS Template"
                    Link="/SmsTemplate1"
                  />

                </div>

                <SideBarSubCategory
                  heading="User Details"
                  onClick={subcateopen4}
                />
                <div
                  className={`yes-bm-sub-category ${childclassadd4 ? "show-child-cate hf5" : ""
                    }`}
                >
                  <ChildCategory
                    heading="User Master"
                    Link="/UserMasterList"
                    Link2="/UserMaster"
                    showLink2={true}
                  />

                  <ChildCategory
                    heading="Authentication"
                    Link="/UserAuthentication"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="Form Name"
                    Link="/AddUpdateFormName"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="User Activity"
                    Link="/UserActivity"
                    Link2="/SalaryMaster"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="Attendence Report"
                    Link="/MonthWiseAttendance"
                    Link2="/Attendance"
                    showLink2={true}
                  />

                  <ChildCategory
                    heading="LogIn Type Master"
                    Link="/LoginTypeMaster"
                    Link2="/"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="Department Master"
                    Link="/DepartmentMaster"
                    Link2="/DepartmentMaster"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="Designation Master"
                    Link="/DesignationMaster"
                    Link2="/DesignationMaster"
                    showLink2={false}
                  />


                </div>
                {/* ****************** Shop Banner ***************** */}
                <SideBarSubCategory
                  heading="Shop Banner"
                  onClick={subcateopen24}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd24 ? "show-child-cate hf5" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Banner Master"
                    Link="/ShopBanner"
                    Link2="/"
                    showLink2={false}
                  />

                  <ChildCategory
                    heading="BannerShop"
                    Link="/MainShopBanner"
                    showLink2={false}
                  />
                </div>
                <SideBarSubCategory
                  heading="LogIn Type Master"
                  Link="/LoginTypeMaster"
                />
                {/* <SideBarSubCategory
                  heading="Vehicle Master"
                  Link="/VehicleMaster"
                /> */}
                <SideBarSubCategory
                  heading="Incentive Master"
                  Link="/DeliveryIncentive"
                />
                <SideBarSubCategory
                  heading="Table Master"
                  onClick={subcateopen9}
                />
                <div
                  className={`yes-bm-sub-category ${childclassadd9 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Table Group"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Table Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Table Allocation"
                    Link="/TableAllocation"
                    Link2="/"
                  />
                </div>
                <SideBarSubCategory
                  heading="Feedback Master"
                  Link="/FeedBackMaster"
                />
                <SideBarSubCategory
                  heading="Meal Course Master"
                  Link="/MealCourseMaster"
                />
                <SideBarSubCategory
                  heading="Recipe Master"
                  Link="/RecipeMaster"
                  Link2="/AddRecipe"
                  showLink2={false}
                />
                <SideBarSubCategory
                  heading="Item Department Master"
                  Link="/ItemDepartmentMaster"
                  showLink2={false}
                />
                <SideBarSubCategory
                  heading="Service Type Master"
                  Link="/ServiceTypeMaster"
                  showLink2={false}
                />
                {/* <div
                  className={`yes-bm-sub-category ${childclassadd25 ? "show-child-cate hf2  " : ""
                    }`}> */}
                <SideBarSubCategory
                  heading="Delivery Time Slot Setting"
                  Link="/DeliveryTimeSlotSettingList"
                  showLink2={true}
                  Link2="/DeliveryTimeSlotSetting"
                />
                <SideBarSubCategory
                  heading="Delivery Point Name"
                  Link="/DeliveryPointName"
                  showLink2={false}
                />
                {/* </div> */}
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName2}
                heading="Transaction"
                image={Transaction}
                function={addClassOnClick2}
              /> */}
              <div className={`yes-bm-sub-category-main ${additionalClassName2 ? "show-sub-cate" : ""}`} >
                <SideBarSubCategory heading="Sale" onClick={subcateopen5} />

                <div
                  className={`yes-bm-sub-category ${childclassadd5 ? "show-child-cate hf3" : ""}`} >
                  <ChildCategory heading="Sale Invoice" Link="/SaleVoucherList" Link2="/SaleInvoice" showLink2={true} />
                  <ChildCategory heading="Sale Order" Link="/SaleOrderList" Link2="/SaleOrder" showLink2={true} />
                  <ChildCategory heading="Sale Challan" Link="/SaleChallanList" Link2="/SaleChallan" showLink2={true} />
                </div>

                <SideBarSubCategory heading="Sale Return" Link="/SaleReturnVoucherList" Link2="/SaleReturnVoucher" showLink2={true} />

                <SideBarSubCategory heading="Purchase" onClick={subcateopen6} />
                <div
                  className={`yes-bm-sub-category ${childclassadd6 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Purchase Invoice"
                    Link="/PurchaseVoucherList"
                    Link2="/PurchaseInvoice"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Purchase Order"
                    Link="/PurchaseOrder"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Purchase Challan"
                    Link="/PurchaseChallan"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Purchase Return " Link="/PurchaseReturnList" />
                <SideBarSubCategory heading="Damage & Shrinking" Link="/DamageAndShrinkageList" />
                <SideBarSubCategory heading="Stock In/Out" onClick={subcateopen7} />
                <div
                  className={`yes-bm-sub-category ${childclassadd7 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Stock In"
                    Link="/StockInList"
                    Link2="/StockIn"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Stock Out"
                    Link="/StockOutList"
                    Link2="/StockOut"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Stock Transfer Report"
                    Link="/GodownTransfer"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Stock Request"
                    Link="/StockRequest"
                    Link2="/"
                  />
                </div>
                <SideBarSubCategory heading="Eye Test" onClick={subcateopen8} />
                <div
                  className={`yes-bm-sub-category ${childclassadd8 ? "show-child-cate hf8" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Eye Check Up"
                    Link="/EyeCheckUpList"
                    Link2="/EyeCheckUp"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Clinic Master"
                    Link="/ClinicMaster"
                    Link2="/ClinicMaster"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Patient Regisration"
                    Link="/PatientMaster"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Patient Treatment"
                    Link="/StockRequest"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Patient Treatment List"
                    Link="/StockRequest"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Patient Discharge"
                    Link="/StockRequest"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="OT Appointment "
                    Link="/StockRequest"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="OT Treatment"
                    Link="/StockRequest"
                    Link2="/"
                    showLink2={true}
                  />

                </div>
                <SideBarSubCategory heading="WorkShop Order" onClick={subcateopen9} />
                <div
                  className={`yes-bm-sub-category ${childclassadd9 ? "show-child-cate hf5" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Trace Lens Status"
                    Link="/"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Work Show Order"
                    Link="/"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Work Show Order List"
                    Link="/"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Fitting Master"
                    Link="/"
                    Link2="/"
                    showLink2={true}
                  />
                  <ChildCategory
                    heading="Fitting Master List"
                    Link="/"
                    Link2="/"
                    showLink2={true}
                  />

                </div>
                <SideBarSubCategory heading="Pay" />
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName3}
                heading="Rx Transaction"
                image={RxTransaction}
                function={addClassOnClick3}
              /> */}

              <div
                className={`yes-bm-sub-category-main ${additionalClassName3 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory heading="Rx Order" />
                <SideBarSubCategory heading="Rx Report" />
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName4}
                heading="Lens Transaction"
                image={LensTransaction}
                function={addClassOnClick4}
              /> */}

              <div
                className={`yes-bm-sub-category-main ${additionalClassName4 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory
                  heading="Lens Group Creation"
                  Link="/LensCreation"
                />
                <SideBarSubCategory heading="Sale" onClick={subcateopen7} />

                <div
                  className={`yes-bm-sub-category ${childclassadd7 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Sale Invoice"
                    Link="/ItemGroupmasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Sale Order"
                    Link="/ItemMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Sale Challan"
                    Link="/MaterialCentreGroupMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Sale Return" />
                <SideBarSubCategory heading="Purchase" onClick={subcateopen8} />

                <div
                  className={`yes-bm-sub-category ${childclassadd8 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Purchase Invoice"
                    Link="/ItemGroupmasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Purchase Order"
                    Link="/ItemMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Purchase Challan"
                    Link="/MaterialCentreGroupMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Purchase Return " />
                <SideBarSubCategory heading="Bulk Purchase" />
                <SideBarSubCategory heading="Lens Rate Master" />
                <SideBarSubCategory heading="Lens Stock Report" />
                <SideBarSubCategory heading="Lens SPH/CYL Wise Stock" />
                <SideBarSubCategory heading="Damage and Shrinkage" />
                <SideBarSubCategory heading="Product exchange" />
              </div>

              <SideBarCategory
                ArrowChange={additionalClassName5}
                heading="Orders"
                image={Orders}
                function={addClassOnClick5}
              />

              <div
                className={`yes-bm-sub-category-main ${additionalClassName5 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory heading="POS Invoice" Link="/PosInvoice" />
                <SideBarSubCategory
                  heading="KOT Order List"
                  Link="/KotOrderList"
                />
                <SideBarSubCategory heading="Order View" Link="/OrderView" />
                <SideBarSubCategory heading="KOT Order Summary" Link="/KotOrderSummary" />
                <SideBarSubCategory heading="Item Incentive Master" Link="/ItemIncentiveMaster" />
                <SideBarSubCategory heading="Item Incentive Report" Link="/ItemIncentiveReport" />
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName6}
                heading="Pay Remaining"
                image={PayRemaining}
                function={addClassOnClick6}
              /> */}
              <div
                className={`yes-bm-sub-category-main ${additionalClassName6 ? "show-sub-cate" : ""}`}>
                <SideBarSubCategory heading="Received From Account" />
                <SideBarSubCategory heading="Pay to Account" />
              </div>

              <SideBarCategory
                ArrowChange={additionalClassName7}
                heading="Report"
                image={report}
                function={addClassOnClick7}
              />

              <div
                className={`yes-bm-sub-category-main ${additionalClassName7 ? "show-sub-cate" : ""}`}>

                <SideBarSubCategory
                  heading="Cancellation Report"
                  Link="/CancellationReport"
                />
                <SideBarSubCategory
                  heading="Payment Received Report"
                  Link="/PaymentReceivedDetail"
                />
                <SideBarSubCategory
                  heading="Feedback Report"
                  Link="/FeedbackReport"
                />
                <SideBarSubCategory
                  heading="Feedback Item Department Wise Report"
                  Link="/FeedbackItemDepartmentWiseReport"
                />
                <SideBarSubCategory
                  heading="Order Report"
                  Link="/OrderReport"
                />
                <SideBarSubCategory
                  heading="Home Delivery Pickup List"
                  Link="/HomeDeliveryPickupList"
                />
                <SideBarSubCategory
                  heading="Delivery Boy Tracking Report"
                  onClick={subcateopen1}
                />
                <div className={`yes-bm-sub-category ${childclassadd1 ? "show-child-cate hf2" : ""}`} >
                  <ChildCategory
                    heading="Order Wise Tracking Report"
                    Link="/OrderWiseTrackingReport"
                    showLink2={false}
                  />
                  <ChildCategory
                    heading="Trip Wise Tracking Report"
                    Link="/TripWiseDelBoyTrackingReport"
                    showLink2={false}
                  />
                </div>
                {/* ************************************************ */}
                <SideBarSubCategory
                  heading="Transaction Details"
                  onClick={subcateopen10}
                />
                <div
                  className={`yes-bm-sub-category ${childclassadd10 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Transaction Summery"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Transaction Detail"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Sale Summery Form"
                    Link="/TableAllocation"
                    Link2="/"
                  />
                </div>
                <SideBarSubCategory heading="Book" onClick={subcateopen11} />
                <div
                  className={`yes-bm-sub-category ${childclassadd11 ? "show-child-cate hf8" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Day Book"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Day Book Details"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Daily Transcations Book"
                    Link="/TableAllocation"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Cash/Bank Book"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Profit & Loss (Item)"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Profit & Loss (Account)"
                    Link="/TableAllocation"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Balance Sheet"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Collection Report"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Ledger" />
                <SideBarSubCategory heading="Stock & Inventory" />
                <SideBarSubCategory heading="Batch & Serial No" />
                <SideBarSubCategory heading="VAT Reports" />
                <SideBarSubCategory heading="GST Reports" />
                <SideBarSubCategory heading="Other Sale/Order Reports" />
                <SideBarSubCategory heading="Other Reports" />
                <SideBarSubCategory heading="Universal Search" />
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName8}
                heading="CRM"
                image={Crm}
                function={addClassOnClick8}
              /> */}

              <div
                className={`yes-bm-sub-category-main ${additionalClassName8 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory heading="Irregular Customer List" />
                <SideBarSubCategory heading="Date Reminder Report" />
                <SideBarSubCategory heading="Customer Loyalty Report" />
                <SideBarSubCategory heading="Coupon Details" />
                <SideBarSubCategory
                  heading="Feedback"
                  onClick={subcateopen12}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd12 ? "show-child-cate hf2" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Day Book"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Day Book Details"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="DSR Form" />
                <SideBarSubCategory heading="Lead" />
                <SideBarSubCategory heading="Task" />
                <SideBarSubCategory heading="Event" />
                <SideBarSubCategory heading="Contact List" />
                <SideBarSubCategory heading="Support" />
                <SideBarSubCategory heading="Tickit" />
              </div>

              <SideBarCategory
                ArrowChange={additionalClassName9}
                heading="Utilities"
                image={Utilities}
                function={addClassOnClick9}
              />

              <div
                className={`yes-bm-sub-category-main ${additionalClassName9 ? "show-sub-cate" : ""
                  }`}
              >
                <SideBarSubCategory
                  heading="Report Design"
                  onClick={subcateopen13}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd13 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Create Templete"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Report LayOut"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Assign Report to Bill barcode"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Email Details"
                  onClick={subcateopen14}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd14 ? "show-child-cate hf4" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Item Group Master"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Item Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Material Center Group"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Material Center Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Sms Detail"
                  onClick={subcateopen15}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd15 ? "show-child-cate hf2" : ""
                    }`}
                >
                  <ChildCategory
                    heading="SMS Configuration"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="SMS Templete"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Manage Series Wise Bill"
                  onClick={subcateopen16}
                />

                <div
                  className={`yes-bm-sub-category ${childclassadd16 ? "show-child-cate hf3" : ""
                    }`}
                >
                  <ChildCategory
                    heading="Convert Bill"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Convert Bill List"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Delete BillS of Series"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Convert Sale Into Purchase"
                  onClick={subcateopen17}
                />

                <div className={`yes-bm-sub-category ${childclassadd17 ? "show-child-cate hf2" : ""}`} >
                  <ChildCategory
                    heading="Sale List for Send Request"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Request List for Convert Purchase"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Generate Barcode"
                  onClick={subcateopen18}
                />

                <div className={`yes-bm-sub-category ${childclassadd18 ? "show-child-cate hf2" : ""}`} >
                  <ChildCategory
                    heading="From Purchase voucher"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Shipping Detail label Print"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>
                <SideBarSubCategory
                  heading="Database Backup/Import"
                  onClick={subcateopen19}
                />

                <div className={`yes-bm-sub-category ${childclassadd19 ? "show-child-cate hf3" : ""}`} >
                  <ChildCategory
                    heading="Backup & Restore"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Import Customer & Lens Rx"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Import Product group & Lens Detail"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory
                  heading="Data Syncronization"
                  onClick={subcateopen20}
                />

                <div className={`yes-bm-sub-category ${childclassadd20 ? "show-child-cate hf2" : ""}`} >
                  <ChildCategory
                    heading="Customize Syncronization"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Replace Unused Product"
                    Link="/TableMasterList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Bulk Updation" />
                <SideBarSubCategory heading="Menu Management" />
                <SideBarSubCategory heading="Change Session" />
                <SideBarSubCategory heading="Software Update" />
                <SideBarSubCategory heading="Template Store" />
              </div>

              <SideBarCategory
                ArrowChange={additionalClassName10}
                heading="Online Sale"
                image={Ecommerce}
                function={addClassOnClick10}
              />

              <div className={`yes-bm-sub-category-main ${additionalClassName10 ? "show-sub-cate" : ""}`}>
                <SideBarSubCategory
                  heading="Product List"
                  onClick={subcateopen21}
                />

                <div className={`yes-bm-sub-category ${childclassadd21 ? "show-child-cate hf5" : ""}`}>
                  <ChildCategory
                    heading="Product List of Portal"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Product List of Request Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Footer Master"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="PinCode Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Send Notification"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Order List" />
                <SideBarSubCategory heading="Customer Care Request List" />
                <SideBarSubCategory heading="Shop Banner" />
                <SideBarSubCategory heading="PinCode Mapping" />
                <SideBarSubCategory
                  heading="Token Master"
                  onClick={subcateopen22}
                />

                <div className={`yes-bm-sub-category ${childclassadd22 ? "show-child-cate hf5" : ""}`}>
                  <ChildCategory
                    heading="Add Token"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="Token List"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Footer Master"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                  <ChildCategory
                    heading="PinCode Master"
                    Link="/TableMasterList"
                    Link2="/"
                  />

                  <ChildCategory
                    heading="Send Notification"
                    Link="/TableGroupList"
                    Link2="/"
                  />
                </div>

                <SideBarSubCategory heading="Wallet Master" />
                <SideBarSubCategory heading="Checkout Setting" />
                <SideBarSubCategory heading="Term & Conditions" />
                {/* <SideBarSubCategory heading="Product Lens Assign" /> */}
                <SideBarSubCategory heading="Associate Product List" />
                <SideBarSubCategory heading="Bulk Price Update" />
                <SideBarSubCategory heading="Send Notification" />
                <SideBarSubCategory heading="Customer Cart Product List" />
                {/* <SideBarSubCategory heading="Home Eye Checkup List" /> */}
              </div>

              {/* <SideBarCategory
                ArrowChange={additionalClassName11}
                heading="PayRoll"
                image={PayRoll}
                function={addClassOnClick11}
              /> */}
              <div className={`yes-bm-sub-category-main ${additionalClassName11 ? "show-sub-cate" : ""}`}>
                <SideBarSubCategory
                  heading="Leave Master"
                  Link="/LeaveMasterList"
                />
                <SideBarSubCategory
                  heading="Professional Master"
                  Link="/ProfessionalMasterList"
                />
                <SideBarSubCategory
                  heading="Salary Master"
                  Link="/SalaryMaster"
                />
                <SideBarSubCategory
                  heading="Shift Master"
                  Link="/ShiftMasterList"
                />
                <SideBarSubCategory heading="Attendance" Link="/Attendance" />
                <SideBarSubCategory
                  heading="Department Master"
                  Link="/DepartmentMaster"
                />
                <SideBarSubCategory
                  heading="Designation Master"
                  Link="/DesignationMaster"
                />
                <SideBarSubCategory
                  heading="Grade Master List"
                  Link="/GradeMasterList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarNew;
