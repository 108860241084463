import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";

function PayInPayOut() {

    //******************{Pay In and Pay Out modal box}******************/ /
    const [accountName, setAccountName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [showModalPay, setShowModalPay] = useState(false);

    const handleAccountNameChange = (event) => {
        setAccountName(event.target.value);
    };

    const handleMobileNoChange = (event) => {
        setMobileNo(event.target.value);
    };

    const handleButtonClick = () => {
        if (accountName.trim() !== '') {
            setShowModalPay(true);
            setIsReceiveModalVisible(false);
            setIsGiveModalVisible(false);
        } else {
            console.error('Account Name is required.');
        }
    };

    const handleResetClick = () => {
        setAccountName('');
        setMobileNo('');
        setShowModalPay(false);
        setIsReceiveModalVisible(false);
        setIsGiveModalVisible(false);
    };

    /**************{attribute code}********************/

    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

  /**************{Pay In and Pay Out Modal Box}********************/
  
    const [isReceiveModalVisible, setIsReceiveModalVisible] = useState(false);
    const [isGiveModalVisible, setIsGiveModalVisible] = useState(false);
  
    const openModalPayIn = () => {
      setIsReceiveModalVisible(true);
      setIsGiveModalVisible(false);
      setShowModalPay(false);
    };
  
    const openModalPayOut = () => {
      setIsGiveModalVisible(true);
      setIsReceiveModalVisible(false);
      setShowModalPay(false);
    };
  
    return (
        <div className="" >
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Pay In/Pay Out</h2>
                </div >

                {/* ********************************{Buttob}********************************* */}
                <div className="panel-head-button">
                    <div className="col_1">
                        <div>
                            <div className="button-m5">
                                <button id="SaveBtnMain"
                                    className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    Submit
                                    <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i>
                                    {isHovered && (
                                        <div className="hover-textSave">
                                            <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                            Alt + Enter
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="button-m5">
                            <button className="save-button-main">Reset
                                <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="search_panel_hd1">
                        <div className="search_panel_left">
                            <div className="col-sm-n1">
                                <div className="group_head">
                                    <input placeholder="Account Name" type="text" className="bill_panel_input svl_input" value={accountName}
                                        onChange={handleAccountNameChange} />
                                </div>
                                <div className="group_head">
                                    <input placeholder="Mobile No" type="text" className="bill_panel_input svl_input" value={mobileNo}
                                        onChange={handleMobileNoChange} />
                                </div>
                            </div>
                        </div>
                        <div className="search_panel_right">
                            <div className="search_panel_f1" >
                                <div className="button-mp1">
                                    <button className="table_search_btn" onClick={handleButtonClick}>
                                        <i className="fa-solid fa-brands fa-get-pocket im-icon-6"></i>
                                    </button>
                                </div>
                                <div className="button-mp2" >
                                    <button className="table_reset_btn" onClick={handleResetClick}>
                                        <i className="fa-solid fa-rotate im-icon-6"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="search_panel_right">
                            <div className="search_panel_f1" >
                                <div className="button-m5">
                                    <button className="Recipe-button-main" onClick={openModalPayIn}>Receive ₹
                                        <i id="step-2" className="fa-solid fa-plus im-icon-2"></i>
                                    </button>
                                </div>
                                <div className="button-m5">
                                    <button className="Recipe-button-main" onClick={openModalPayOut}>Give ₹
                                        <i id="step-2" className="fa-solid fa-minus im-icon-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                            {isModalOpen && (
                                <div className="modal" >
                                    <div className="modal-content_xls">
                                        <div className="modal-title">
                                            <span className="close" onClick={hideModal}>
                                                &times;
                                            </span>
                                        </div>
                                        <div className="tab-content-1">
                                            <div className="company-info-top-right control_flex_m1 item_scroll">
                                                <XLS />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    {showModalPay && (
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <div className="flex-wrap-payin">
                                    <div className="orderBoxDiv PayIn-Box">
                                        <div className="Flex-Center-Pay">
                                            <label>₹ 3000.00</label>
                                        </div>
                                        <div className="Flex-Center-Pay">
                                            <label className="fs-121">20, 09-01-2024</label>
                                        </div>
                                        <div className="Flex-Center-Pay">
                                            <label className="fs-121">Cash</label>
                                        </div>
                                    </div>
                                    <div className="orderBoxDiv PayIn-Box">
                                        <div className="Flex-Center-Pay">
                                            <label>₹ 3000.00</label>
                                        </div>
                                        <div className="Flex-Center-Pay">
                                            <label className="fs-121">20, 09-01-2024</label>
                                        </div>
                                        <div className="Flex-Center-Pay">
                                            <label className="fs-121">Upi</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isReceiveModalVisible  &&(
                    <div className="company-info-top-right control_flex_m1">
                    <div className="control_radius">
                        <div className="company_info-text-im depart_top PayIn-Head-Text">Pay In</div>
                        <div className="company_control">
                            <div className="stock-im-dep1 PayIn-margin">
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Party Name</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Pay Amt</label>
                                    </div>
                                </div>

                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <select className="modify_im_input mode_category1" type="text" required >
                                            <option>Cash</option>
                                            <option>Card</option>
                                            <option>Paytm</option>
                                            <option>Phonepe</option>
                                            <option>Upi</option>
                                        </select>
                                        <label className="modify_p_text2">Pay By</label>
                                    </div>
                                </div>
                            </div>
                            <div className="stock-im-dep1">
                                {/* <div className="company_info-text-im">Controls Details</div> */}
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Balance Amt</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="date" required />
                                        <label className="modify_lbl_text3">Pay Date</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Remarks</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    )}

                    {isGiveModalVisible  &&(
                    <div className="company-info-top-right control_flex_m1">
                    <div className="control_radius">
                        <div className="company_info-text-im depart_top PayIn-Head-Text">Pay Out</div>
                        <div className="company_control">
                            <div className="stock-im-dep1 PayIn-margin">
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Party Name</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Pay Amt</label>
                                    </div>
                                </div>

                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <select className="modify_im_input mode_category1" type="text" required >
                                            <option>Cash</option>
                                            <option>Card</option>
                                            <option>Paytm</option>
                                            <option>Phonepe</option>
                                            <option>Upi</option>
                                        </select>
                                        <label className="modify_p_text2">Pay By</label>
                                    </div>
                                </div>
                            </div>
                            <div className="stock-im-dep1">
                                {/* <div className="company_info-text-im">Controls Details</div> */}
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Balance Amt</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="date" required />
                                        <label className="modify_lbl_text3">Pay Date</label>
                                    </div>
                                </div>
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label className="modify_p_text2">Remarks</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    )}
                </div>
            </div>

            {/******************************************* For Mobile View ***********************************/}
            <div className="wrapper" style={{ display: "none" }}>
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>

        </div >


    );
}

export default PayInPayOut;
