import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";

function LensRemarkMaster() {

    useState(() => {
        document.title = 'Remark Master';
    }, []);

    const [inputs, setInputs] = useState({
        input1: '',
        input2: '',
        input3: '',
    });

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };


    return (
        <div>
            <div>
                {
                    [].map((item) => {
                        return (
                            <div
                                key={item}
                                id={`step-${item}`}
                                style={{
                                    border: "1px solid white",
                                    width: "100px",
                                    height: "100px",
                                    background: "#0c1d2b",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>

            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Remark Master</h2>
                </div >

                {/* *******************Save Button Start****************** */}


                {/* *******************Top Buttons****************** */}
                < MainButton />

                {/* *******************Modal Box Open****************** */}
                {/* *******************Save Button End****************** */}

            </div>
            <div>
                < DateTime />
            </div>
            < PageTabs />
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                        <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox"  />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">

                                            <div className="input-box-Im ">

                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                        ref={input1Ref}
                                                        type="text" required>
                                                        <option>Remark 1</option>
                                                        <option>Remark 2</option>
                                                        <option>Remark 3</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Types</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                        ref={input2Ref}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Remark</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}


export default LensRemarkMaster;
