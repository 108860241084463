import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import TableButton from "../../Props/TableButton";
//import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';

const MyDataTable = (props) => {

    const handleExportToPDF = () => {
        // const content = document.getElementById('pdfContent'); // Replace 'pdfContent' with the actual ID or class of the container

        // html2pdf(content, {
        //     margin: 10,
        //     filename: 'your-exported-file.pdf',
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: { scale: 2 },
        //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        // });
    };
   
    const handlePrint = () => {
        window.print();
      };

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);


    useEffect(() => {
        // Define your columns
        const columnsData = [
            { name: 'Name', selector: 'name', sortable: true },
            { name: 'Position', selector: 'position', sortable: true },
            { name: 'Tech', selector: 'Tech', sortable: true },
            { name: 'Age', selector: 'age', sortable: true },
            { name: 'Start date', selector: 'startDate', sortable: true },
            {
                name: 'Actions',
                className: 'TableDelClass CentreSide',
                cell: (row) => (
                    <div className="CentreSide">
                        <i className="fa-regular fa-pen-to-square TableEditIcon"></i>
                        <i className="fa-regular fa-trash-can TableDelIcon" //onClick={() => handleDelete(row.id)}
                        ></i>
                    </div>
                ),
            },
        ];

        // Generate sample records
        const sampleData = Array.from({ length: 20000 }, (_, index) => ({
            id: index + 1,
            name: `Person ${index + 1}`,
            position: `Position ${index + 1}`,
            Tech: `Tech ${index + 1}`,
            age: Math.floor(Math.random() * 40) + 20,
            startDate: '2022-01-01',
            salary: `$${Math.floor(Math.random() * 90000) + 50000}`,
        }));

        setColumns(columnsData);
        setData(sampleData);
    }, []);  // Add handleDelete to the dependency array

    // const handleDelete = useCallback((id) => {
    //     const updatedData = data.filter(item => item.id !== id);
    //     setData(updatedData);
    // }, [data]);


    //   const handleInputChange = (column, value) => {
    //       // Implement your search logic here
    //       // For example, filter the data based on the input value

    //       const filteredData = data.filter((item) =>
    //           String(item[column.selector]).toLowerCase().includes(value.toLowerCase())
    //       );

    //       setData(filteredData);
    //   };

    const handleExportToExcel = () => {
        // Create a worksheet
        const sheet = XLSX.utils.json_to_sheet(data);
      
        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
      
        // Save the Excel file
        XLSX.writeFile(workbook, 'your-exported-file.xlsx');
      };

      const handleExportToExcel2 = () => {
        // Slice the data to include only the first 20 rows
        const slicedData = data.slice(0, 20);
      
        // Create a worksheet
        const sheet = XLSX.utils.json_to_sheet(slicedData);
      
        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
      
        // Save the Excel file
        XLSX.writeFile(workbook, 'your-exported-file.xlsx');
      };

    return (

        <div>
            <div className="" >
                < PageTabs />
                <div className="nav-1" >
                    <div className="panel-heading">
                        <h2 className="panel-title">Account Category Master List</h2>
                        <TableButton handleExportToPDF={handleExportToPDF} />
                        <button onClick={handleExportToExcel}> Excel All</button>
                        <button onClick={handleExportToExcel2}> Excel</button>
                        <button onClick={handleExportToPDF}>Export to PDF</button>
                        <button onClick={handlePrint}>Export to Print</button>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="search_panel_hd1">
                                <div className="search_panel_left">
                                    <div className="col-sm-n1">
                                        <div className="group">
                                            <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                            <input placeholder="Search" type="search" className="search_panel_input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="search_panel_right">

                                </div>
                            </div>
                            <div className="col-sm-12" id="pdfContent">
                                <div className="table-resposive" >
                                    {columns.map((column) => (
                                        <div key={column.selector}>
                                            {/* <div className='tableOP'>

                                                <input
                                                    placeholder={`Search ${column.name}`}
                                                    onChange={(e) => handleInputChange(column, e.target.value)}
                                                />
                                            </div> */}
                                        </div>
                                    ))}
                                    <DataTable columns={columns} data={data} pagination persistTableHead />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="container">
                        <div className=" content-row-1">
                            <div className="class_control">
                                <div className="balance-1">
                                    <div className="p-11">
                                        <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                    </div>
                                    <div className="p-12">
                                        <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                    </div>
                                </div>
                                <div className="balance-1">
                                    <div className="p-11">

                                        <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                    </div>
                                    <div className="p-12">
                                        <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                    </div>
                                </div>
                                <div className="balance-1">
                                    <div className="p-11">
                                        <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                    </div>
                                    <div className="p-12">
                                        <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                    </div>
                                </div>
                            </div>
                            <div className="class_control_2">
                                <div className="parti_1">
                                    <div className="sub_parti_1">
                                        <p className="parti-p-1">Parties</p>
                                    </div>
                                </div>
                                <div className="parti_1">
                                    <div className="sub_parti_1">
                                        <p className="parti-p-1">Trasaction</p>
                                    </div>
                                </div>
                                <div className="parti_1">
                                    <div className="sub_parti_1">
                                        <p className="parti-p-1">Items</p>
                                    </div>
                                </div>
                            </div>
                            <div className="class_control_4">
                                <div className="col_ms_1">
                                    <p className="p_13" >Recent History</p>
                                    <div className="con_input_1">
                                        <input type="text" className="input_box_2" placeholder="Search..." />
                                    </div>
                                </div>

                                <div className="col_sm_11">
                                    <div className="col_sm_21">
                                        <div className="col_sm_12">
                                            <div className="con_name_1">
                                                <p className="con_p_3">Akash kumar</p>
                                            </div>
                                            <div className="con_name_1">
                                                <p className="con_p_1">Sale</p>
                                            </div>
                                            <div className="con_name_1">
                                                <p className="con_p_2">Total</p>
                                            </div>
                                            <div className="con_name_1">
                                                <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col_sm_13">
                                        <div className="col_sm_12">
                                            <div className="con_total_3">
                                                <p className="con_p_3">Balance</p>
                                            </div>
                                            <div className="con_total_3">
                                                <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col_sm_14">
                                        <div className="col_sm_12">
                                            <div className="con_sale_2">
                                                <p className="con_p_3">Date</p>
                                            </div>
                                            <div className="con_sale_2">
                                                <p>#1</p>
                                            </div>
                                            <div className="con_name_1 con_name_7">
                                                <i className="fa-solid fa-print"></i>
                                                <i className="fa-solid fa-share"></i>
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div>

                            </div>

                        </div>
                    </div>
                </div>

            </div >

        </div>
    );
};

export default MyDataTable;
