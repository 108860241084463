import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryIncentive = {

    insertDeliveryIncentive: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/DeliveryIncentive`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                incentiveName: data.incentiveName,
                incentiveNameId: data.incentiveNameId,
                incentivePoint: +(data.incentivePoint), //+ is for float or double value
                deliveryIncentiveInTimeSlot: Number(data.deliveryIncentiveInTimeSlot),
                incentiveType: data.incentiveType,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getDeliveryIncentive: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/GetDeliveryIncentives`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get All ***************************
    getDeliveryIncentiveFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/GetDeliveryIncentives`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },


      // ************************* Get All ***************************
      GetIncentiveNames: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/GetIncentiveNames`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },



    // ************************* Delete ****************************
    deleteDeliveryIncentive: async (deliveryIncentiveId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive?deliveryIncentiveId=${deliveryIncentiveId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getDeliveryIncentiveById: async (deliveryIncentiveId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/DeliveryIncentive?deliveryIncentiveId=${deliveryIncentiveId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateDeliveryIncentive: async (data) => {
        console.log(data)
        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive?deliveryIncentiveId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                incentiveName: data.incentiveName,
                incentiveNameId: data.incentiveNameId,
                incentivePoint: +(data.incentivePoint),  //+ is for float or double value;
                deliveryIncentiveInTimeSlot: Number(data.deliveryIncentiveInTimeSlot),
                incentiveType: data.incentiveType,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Incentive Names ***************************
    getDeliveryIncentiveNames: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/GetIncentiveNames`;
            const response = await axios.post(apiUrl, {
                filter: [
                    {
                        "fieldName": "string",
                        "operatorName": "string",
                        "compareValue": "string"
                    },
                ],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Create Incentive Names ***************************
    insertIncentiveNames: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/CreateIncentiveNames`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                name: data.name,
                description: data.description,
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // *************************** Update Incentive Master ***************************
    updateIncentiveNames: async (data) => {
        console.log(data)
        try {
            const apiUrl = `${WebApiUrl}/api/DeliveryIncentive/UpdateIncentiveNames?incentiveId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                description: data.description,
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default DeliveryIncentive;
